<div class="layout-content layout-content-noscroll">
    <p-messages class="custom-message"></p-messages>

    <!-- breadcrumb container -->
    <div class="p-formgrid p-grid">
          <div class=" p-col-12 p-p-0">
              <div class="ui-fluid p-formgrid p-grid p-ai-center">
                  <div class="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-pl-0 p-text-left">
                      <div class=" p-fluid p-field  p-mb-0  ">
                          <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb"
                  (onItemClick)="homeBreadcrumbClicked($event)">
                </p-breadcrumb>
                      </div>
                  </div>
              </div>
          </div>
    </div>
    
    <!-- form for calendar, reset, export filter -->
    <form [formGroup]="IRFRealizationGridForm" autocomplete="off" novalidate>
      <div class="p-formgrid p-grid">
        <div class=" p-col-12 p-p-0">
          <div class="ui-fluid p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-between p-ai-end p-d-flex p-flex-column p-flex-md-row  p-p-0 p-mb-2">
              <div class="p-col-3 p-pl-0 p-d-flex p-jc-start">
                <!-- DOWNLOAD EXCEL <div
                  class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 left p-px-0">
                  <div  class="p-fluid p-d-flex p-flex-column" style="width : 30rem;">
                    <div class="p-d-flex">
                        <label class="fin_year"> Financial Year : </label>
                        <p-dropdown placeholder="Select"
                            class="p-w-100 p-pl-1"
                            style="width : 10rem;"
                            formControlName="financialYear"
                            [options]="financialYearList" 
                            [showClear]="true">
                        </p-dropdown>
                        <div style="position: relative;" class=" p-mx-2">
                          <button pButton type="button" class="p-button-secondary p-mr-2" [disabled]="gridLoader || (IRFRealizationGridForm?.get('financialYear')?.value == null)" label="Download" (click)="exportExcel(true)"></button>
                        </div>
                      </div>
                    </div>
              </div>   -->
              </div>
              <div class="p-col-3 p-pl-0 p-d-flex p-jc-start p-ai-center">
              </div>
              <div class="p-col-6 p-p-0 p-d-flex p-jc-end p-mt-0 p-mr-0">

                <!-- global calendar -->
                <p-calendar #globalCalendar [showIcon]="true" id="global-calendar" class="p-px-2"
                  name="global-calendar" placeholder="From-To" yearRange="2000:2030"
                  formControlName="globalCalendar"
                  dataType="string" selectionMode="range" [readonlyInput]="true" rangeSeparator="to"
                  dateFormat="dd/mm/yy" [showButtonBar]="true" [hideOnDateTimeSelect]="true" dataType="string"
                  [stepHour]="1" [stepMinute]="1" [showButtonBar]="true" [minDate]="minGlobalStartTime" [maxDate]="maxGlobalEndTime"
                  (onSelect)="filterGlobalTableByDate()"
                  (onClearClick)="onClearGlobalDate()"></p-calendar>
                <div class=" p-fluid p-d-flex p-jc-end p-flex-row p-pl-0 p-pr-0">

                  <!-- enable/hide filter -->
                  <button pButton class="p-col-2 p-button-brand2 p-mr-2" icon="pi pi-filter" *ngIf="!filterDisplayRow"
                    pTooltip="Filter" tooltipPosition="bottom" (click)="toggleFilter(mainGrid)"></button>
                  <button pButton class="p-col-2 p-button-brand2 p-mr-2" icon="pi pi-filter-slash"
                    *ngIf="filterDisplayRow" pTooltip="Clear Filter" tooltipPosition="bottom"
                    (click)="toggleFilter(mainGrid)"></button>
                    
                  <!-- import excel -->
                  <div style="position: relative;" class=" p-mr-2">
                    <input #fileInput class="import-btn"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="fileDropRef" multiple="multiple"
                    (change)="fileUploaded($event)" type="file">
                    <button pButton type="button" class="p-button-secondary p-mr-2 disableImport" label="Import" (click)="fileInput.click()" [disabled]="realizationReadScope"></button>
                    <!-- <button pButton type="button" class="p-button p-button-secondary p-mr-2 disableImport" label="Import" (click)="fileInput.click()"></button> -->

                  </div>
                  <!-- export excel -->
                  <div class="p-helper-clearfix" style="text-align: left">
                    <button pButton type="button" class="p-button-primary p-mr-0"
                      label="Export" (click)="exportExcel()" style="float:right"></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-col-12 p-sm-12 p-p-0 reportingScreenGridTable">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12 p-p-0">
                  <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-p-0 p-right">
                      <div class="p-field">
                            <!-- <div class="card"> -->
                              <p-table #mainGrid [value]="IRFRealizationList" dataKey="paymentId" [paginator]="true"  styleClass="p-datatable-gridlines backoffice-cust-scroll" [scrollable]="true" scrollHeight="55vh" scrollDirection="both" [showCurrentPageReport]="true"
                              [rowsPerPageOptions]="[10, 25, 50]"              
                              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                     [rows]="10" [ngClass]="{'mt-big':filterDisplayRow,'mt-small':!filterDisplayRow}">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="agreementId" style="max-width:160px;min-width:160px;" >Agreement Id <p-sortIcon field="agreementId"></p-sortIcon></th>
                                            <th pSortableColumn="companyName" style="max-width:320px;min-width:320px;" >Company name <p-sortIcon field="companyName"></p-sortIcon></th>
                                            <th pSortableColumn="paymentId" style="max-width:160px;min-width:160px;" >Payment Id <p-sortIcon field="paymentId"></p-sortIcon></th>
                                            <th pSortableColumn="financeStatus" style="max-width:160px;min-width:160px;" >Payment status <p-sortIcon field="financeStatus"></p-sortIcon></th>
                                            <th pSortableColumn="paymentDate" style="max-width:200px;min-width:200px;">Payment created date <p-sortIcon field="paymentDate"></p-sortIcon></th>
                                            <th pSortableColumn="qcDate" style="max-width:160px;min-width:160px;">QC on <p-sortIcon field="qcDate"></p-sortIcon></th>
                                            <th pSortableColumn="irfActual" style="max-width:160px;min-width:160px;">IRF Actual <p-sortIcon field="irfActual"></p-sortIcon></th>
                                            <th pSortableColumn="grossAmount" style="max-width:160px;min-width:160px;">Gross Amount <p-sortIcon field="grossAmount"></p-sortIcon></th>
                                        </tr>
                                        <tr *ngIf="filterDisplayRow">
                                            <!-- agreement Id -->
                                            <th style="max-width:160px;min-width:160px" >
                                                <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'agreementId', 'contains')" [value]="mainGrid.filters['agreementId']?.value" placeholder="Search" class="p-column-filter">     
                                                </th>
                                            <!-- company Name -->
                                            <th style="max-width:320px;min-width:320px" >
                                                <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'companyName', 'contains')" [value]="mainGrid.filters['companyName']?.value" placeholder="Search" class="p-column-filter">     
                                            </th>

                                            <th style="max-width:160px;min-width:160px" >
                                              <!-- search by payment Id -->
                                              <input pInputText type="text" disabled (input)="mainGrid.filter($event.target.value, 'paymentId', 'contains')" [value]="mainGrid.filters['paymentId']?.value" placeholder="Search" class="p-column-filter">     
                                            </th>

                                            <th style="max-width:160px;min-width:160px" class="status-dropdown" >
                                              <!-- payment status dropdown -->
                                              <p-columnFilter field="financeStatus" matchMode="equals" [showMenu]="false" class="p-w-100">
                                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                  <p-dropdown
                                                    [options]="paymentOptionsList"
                                                    (onChange)="getIRFRealizationList()"
                                                    placeholder="Select"
                                                    formControlName="paymentStatusFilter"
                                                    appendTo='body'
                                                    optionLabel="label"
                                                  >
                                                    <ng-template let-item pTemplate="item">
                                                          {{ item.label }}
                                                    </ng-template>
                                                  </p-dropdown>
                                                </ng-template>
                                              </p-columnFilter>
                                            </th>

                                            <th style="max-width:200px;min-width:200px" >
                                              <!-- search by payment created date -->
                                              <input pInputText type="text" disabled (input)="mainGrid.filter($event.target.value, 'paymentDate', 'contains')" [value]="mainGrid.filters['paymentDate']?.value" placeholder="Search" class="p-column-filter">     
                                            </th>

                                            <th style="max-width:160px;min-width:160px" >
                                                <!-- search by qc created date -->
                                                <input pInputText type="text" disabled (input)="mainGrid.filter($event.target.value, 'qcDate', 'contains')" [value]="mainGrid.filters['qcDate']?.value" placeholder="Search" class="p-column-filter">     
                                              </th>
  
                                            <th style="max-width:160px;min-width:160px" >
                                                <!-- search by IRF actual -->
                                                <input pInputText type="text" disabled (input)="mainGrid.filter($event.target.value, 'irfActual', 'contains')" [value]="mainGrid.filters['irfActual']?.value" placeholder="Search" class="p-column-filter">     
                                              </th>

                                              <th style="max-width:160px;min-width:160px" >
                                                <!-- search by grossAmount -->
                                                <input pInputText type="text" disabled (input)="mainGrid.filter($event.target.value, 'grossAmount', 'contains')" [value]="mainGrid.filters['grossAmount']?.value" placeholder="Search" class="p-column-filter">     
                                              </th>
                                          </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-opportunity>
                                        <tr (click)="showDialogPayment(opportunity)" [pSelectableRow]="opportunity">
                                            <td style="max-width:160px" >
                                                {{opportunity?.agreementId}}
                                            </td>
                                            <td style="max-width:320px;" class="text-transform-capitalize p-text-nowrap p-text-truncate" >
                                                <div style="max-width:200rem;white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                                    <a>{{opportunity?.companyName}}</a>
                                                </div>
                                            </td>
                                            <td style="max-width:160px;" >
                                                {{opportunity?.paymentId}}
                                            </td>
                                            <td *ngIf="opportunity?.financeStatus" style="max-width:160px;">
                                                <div [ngClass]="{'dropdown-capsule' : true, 'pending-style': opportunity?.financeStatus === 'Pending', 'rejected-style': opportunity?.financeStatus === 'Rejected', 'bounced-style': opportunity?.financeStatus === 'Bounced', 'realized-style': opportunity?.financeStatus === 'Realized'}">
                                                  {{ opportunity?.financeStatus }}
                                                </div>
                                            </td>
                                            <td style="max-width:200px;">
                                                {{opportunity?.paymentDate}}
                                            </td>
                                            <td style="max-width:160px;">
                                              {{opportunity?.qcDate}}
                                          </td>
                                            <td style="max-width:160px;">
                                                {{opportunity?.irfActual}}  
                                            </td>
                                            <td style="max-width:160px;">
                                                {{opportunity?.grossAmount}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                      <tr>
                                        <td colspan="8" style="background: white;">
                                          <div class="empmess p-my-2">
                                            <backoffice-svg [name]="'empty-state-light'" class="p-mt-3"></backoffice-svg>
                                            <backoffice-svg [name]="'empty-state-dark'" class="p-mt-3"></backoffice-svg>
                                          <p>No entity found.</p>
                                          </div>
                                        </td>
                                      </tr>
                                  </ng-template>
                                </p-table>
                      </div>
  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- Back button -->
<div class="p-col-12 p-d-flex p-jc-between p-button-container p-ai-center">
  <div class="p-col-12 p-text-left p-pl-0">
      <div class="btnwrap">
          <button pButton class="p-button-brand2 p-button" type="button" (click)="onBackClick();" label="Back">
          </button>
      </div>
  </div>
</div>

<!-- error modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true" [style]="{width: '55vw', height: '20vh'}"
    [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>

<!-- Processing Popup -->
<p-dialog header="Processing" [(visible)]="displayProcessingModal" [modal]="true" [style]="{width: '31vw'}"
    [draggable]="false" [closable]="false" [resizable]="false">
    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-column p-ai-center">
        
        <backoffice-svg [name]="'processing-light'"></backoffice-svg>
        <backoffice-svg [name]="'processing-dark'"></backoffice-svg>
        <label class="p-mt-3 p-mb-3">Please wait while we are processing the data.</label>
    </div>
</p-dialog>

<!-- Info Popup -->
<p-dialog header="Import summary" [(visible)]="displayInfoModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-column p-ai-center">
        <svg width="48" height="48" class="illustrationLight" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="46" height="46" rx="23" fill="#E7F2EC" />
            <path d="M37 16L19.125 32L11 24.7273" stroke="#58A778" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            <rect x="1" y="1" width="46" height="46" rx="23" stroke="#58A778" stroke-width="2" />
        </svg>
        <svg width="48" height="48" class="illustrationDark" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37 16L19.125 32L11 24.7273" stroke="#70B38B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <rect x="1" y="1" width="46" height="46" rx="23" stroke="#70B38B" stroke-width="2"/>
        </svg>
        <label class="p-mt-3 p-mb-3"><strong>{{processedResult?.totalRecords}}</strong> records are processed successfully.</label>
        <div class="p-col-12 p-p-0 p-d-flex allocationProcessingInfo p-jc-evenly p-mb-3">
            <div class="">No change:&nbsp;<span class="reallocated">{{processedResult?.count?.unchanged}}</span></div>
            <div class="">Success:&nbsp;<span class="success-no">{{processedResult?.count?.success}}</span>
            </div>
            <div class="">Invalid:&nbsp;<span class="invalid-no">{{processedResult?.count?.invalid}}</span></div>
            <div class="">Error:&nbsp;<span class="error-no">{{processedResult?.count?.error}}</span></div>
        </div>
    </div>
</p-dialog>

<!-- Processing Popup -->
<p-dialog header="Exporting" [(visible)]="displayExtractProcessingModal" [modal]="true" [style]="{width: '31vw'}"
    [draggable]="false" [closable]="false" [resizable]="false">
    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-column p-ai-center">
        <backoffice-svg [name]="'processing-light'"></backoffice-svg>
        <backoffice-svg [name]="'processing-dark'"></backoffice-svg>
        <label class="p-mt-3 p-mb-3">Please wait while we are extracting the data.</label>
    </div>
</p-dialog>
