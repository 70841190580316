import { CUSTOM_ELEMENTS_SCHEMA ,NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SfBillingRoutingModule } from './sf-billing-routing.module';
import { SfEstimatesComponent } from './sf-estimates/sf-estimates.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { BackofficeSharedModule } from 'app/shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { GenerateBillComponent } from './generate-bill/generate-bill.component';
import { SvgModule } from 'app/backoffice-assets/svg.module';
import { SfEditGridComponent } from './sf-edit-grid/sf-edit-grid.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { SfEditCompanyDetailsComponent } from './sf-edit-company-details/sf-edit-company-details.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { AddAgreementComponent } from './add-agreement/add-agreement.component';
import { BdApprovalGridComponent } from './bd-approval-grid/bd-approval-grid.component';
import { BdApprovalDetailsComponent } from './bd-approval-details/bd-approval-details.component';
import { TableModule } from 'primeng/table';
import { SfBillTrackingComponent } from './sf-bill-tracking/sf-bill-tracking.component';
import { MessageService } from 'primeng/api';
import { SfFinanceGridComponent } from './sf-finance-grid/sf-finance-grid.component';
import { SfFinanceDetailsComponent } from './sf-finance-details/sf-finance-details.component';
import { SfBillTrackingAdvanceFilterComponent } from './sf-bill-tracking-advance-filter/sf-bill-tracking-advance-filter.component';
import { SfBillTrackingDetailsScreenComponent } from './sf-bill-tracking-details-screen/sf-bill-tracking-details-screen.component';


@NgModule({
  declarations: [
    SfEstimatesComponent,
    GenerateBillComponent,
    SfEditGridComponent,
    SfEditCompanyDetailsComponent,
    AddAgreementComponent,
    BdApprovalGridComponent,
    BdApprovalDetailsComponent,
    SfFinanceGridComponent,
    SfFinanceDetailsComponent,
    SfBillTrackingComponent,
    SfBillTrackingAdvanceFilterComponent,
    SfBillTrackingDetailsScreenComponent
  ],
  imports: [
    CommonModule,
    SfBillingRoutingModule,
    BreadcrumbModule,
    CardModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ReactiveFormsModule,
    DialogModule,
    DropdownModule,
    BackofficeSharedModule,
    ButtonModule,
    SvgModule,
    MultiSelectModule,
    PdfViewerModule,
    CheckboxModule,
    CalendarModule,
    InputTextModule,
    InputNumberModule,
    TableModule
  ],
  providers: [
    DialogModule,
    DialogService,
    MessageService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SfBillingModule { }
