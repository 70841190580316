import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BackofficeSvgComponent } from './svg/backoffice-svg.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AttachmentPreviewComponent } from './attachment-preview/attachment-preview.component';
import { CardModule } from 'primeng/card';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MenubarModule } from 'primeng/menubar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TooltipModule } from 'primeng/tooltip';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
@NgModule({
  declarations: [
    BackofficeSvgComponent,
    AttachmentPreviewComponent
  ],
  imports: [CommonModule, CardModule,
    DialogModule,
    FileUploadModule,
    ImageCropperComponent,
    AccordionModule,
    InputSwitchModule,
    StepsModule,
    ToastModule,
    PdfViewerModule,
    InputNumberModule,
    
    NgxSpinnerModule,
    OverlayPanelModule,	
    MenubarModule,
    SplitButtonModule,
    TooltipModule,
    SlideMenuModule,
    ToggleButtonModule,
  ],
  exports : [BackofficeSvgComponent, AttachmentPreviewComponent],
  providers:[
    DialogModule,
    DialogService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SvgModule { }
  