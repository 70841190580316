import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { PagesService } from 'app/pages/pages.service';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { APIService } from '../api.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'backoffice-attachment-preview-listing',
  templateUrl: './attachment-preview-listing.component.html',
  styleUrls: ['./attachment-preview-listing.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class AttachmentPreviewListingComponent implements OnInit, OnDestroy {

  ngOnUnSubscribe = new Subject();
  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;
  blobUrlsOpened = [];

  displayAttachData = [];
  urlSafe: string;

  constructor(private messageService: MessageService, private pageService: PagesService, private encryptDecryptInterceptor: EncryptDecryptInterceptor, public apiService: APIService, public pagesService : PagesService) { }

  ngOnInit(): void {
    let data = localStorage.getItem("attachmentData");
    data = this.encryptDecryptInterceptor.decrypt(data);
    if(!!data)
      this.setListItems(JSON.parse(data));
    this.blobUrlsOpened = [];
  }

  setListItems(res) {
    if(res?.length > 0) {
      this.displayAttachData = res?.map(item => ({
        id : item?.fileId,
        fileContentType : item?.fileContentType,
        fileName: item?.fileName,
        fileTypeName : item?.fileTypeName
      }));
    }
  }
  ngOnDestroy(): void {
      this.ngOnUnSubscribe.next();
      this.ngOnUnSubscribe.complete();
      // clear all object url created
      this.blobUrlsOpened.filter(item => !!item).forEach(item => {
        URL.revokeObjectURL(item);
      })
  }
  previewFile(event, item) {
    let payload = {
      id: item?.id
    }
    event.preventDefault();
    this.pagesService.findPDF(payload).subscribe((res) => {
      if (res && res?.file) {
        let ext = res?.fileName?.split('.');
        let ext1 = ext[ext.length - 1];
        let mime: any;
        let isPreviewable = false;
        if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
          mime = 'data:image/png;base64,'
          isPreviewable = true;
        } else if("pdf" === ext1?.toLowerCase() || "docx" === ext1?.toLowerCase()) {
          mime = 'data:application/pdf;base64,';
          isPreviewable = true;
        } else if("doc" === ext1?.toLowerCase()) {
          mime = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,'
        } else if ( "msg" === ext1?.toLowerCase()) {
          mime = 'data:application/vnd.ms-outlook;base64,';
        }
        this.urlSafe = `${mime}${res.file}`;

        // preview
        if(isPreviewable && this.urlSafe && mime) {
          const newTab = window.open(null, "_blank", 'width=1200,height=800');
          const blob = this._base64ToBlob(this.urlSafe, 'application/pdf');
          const finalBlobUrl = URL.createObjectURL(blob);
          this.blobUrlsOpened.push(finalBlobUrl);
          
          const fileFullName = item?.fileName?.split('.');
          const fileName = fileFullName.slice(0, -1).join('');
          const extension = fileFullName[fileFullName?.length - 1];
          
          if(mime.includes('png'))
            newTab.document.write(
              `<div style="height:100%; display: flex; justify-content: center;"><img  src="${finalBlobUrl}" />
              <a href="${finalBlobUrl}" download="${item?.fileName}" 
                    style="position: absolute; top: 10px; right: 10px; cursor: pointer;font:Inter;
                           background-color: #D6002A; color: white; padding: 8px 12px;
                           text-decoration: none; border-radius: 5px;">
                   Download
                 </a>
              </div>`
            );
            
          else  newTab.document.write(
            `<a href="${finalBlobUrl}" download="${fileName}.${extension === 'msg' ? extension : 'pdf'}" 
            style="position: absolute; top: 1.2rem; right: 3.6rem; cursor: pointer;font:Inter;
                   background-color: #D6002A; color: white; padding: 8px 12px;
                   text-decoration: none; border-radius: 5px;">
           Download
         </a><div style="height:100%; display: flex; justify-content: center;"><iframe style="height:100%; width:100%;"  src="${finalBlobUrl}" /></div>`
          );

          setTimeout(function () {
            newTab.document.title = item?.fileName;
          }, 1000);
        } else {  // download
          this.apiService.downloadFile(item, this.urlSafe);
          return true;
        }

        
      }
    }, (_error) => {
      this.showError();
    });
  }

  private showError() {
    this.messageService.add({ severity: 'error', summary: 'Something went wrong, please try again' });
    setTimeout(() => {
      this.messageService.clear();
    }, 4000);
  }
  private _base64ToBlob(base64, type = 'application/pdf') {
    const b64 = base64.split('base64,')[1];
    console.log('b64 :', b64);
    const binStr = atob(b64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }

    

}
