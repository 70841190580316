<div>
    <form [formGroup]="usermappingForm" autocomplete="off" novalidate>
        <p-messages></p-messages>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12 p-p-0">
                <div class="ui-fluid p-formgrid p-grid">
                    <div style="padding-bottom: 7px;" class="p-col-12 p-sm-12 p-md-10 p-lg-10 p-xl-10 p-pl-0 p-left">
                        <label class="leb empDetails p-mr-3"> Emp ID: <strong>
                                {{usermappingForm.value.pageData.employeeId}}
                            </strong></label>
                        <label class="leb empDetails"> Employee Name: <strong>
                                {{usermappingForm.value.pageData.employeeName}} </strong></label>
                    </div>

                    <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 p-xl-2 p-text-right">
                        <div class="btnwrap">
                            <button pButton class="p-button-secondary"
                                style="border: none !important; padding:0; margin:0; background-color: transparent!important; box-shadow: none ;"
                                [disabled]="!usermappingForm.dirty" type="button" (click)="RefreshForm();"
                                label="Refresh">
                            </button>

                        </div>
                    </div>

                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pr-0 p-pl-0 popupBodyScrollContainer"
                        style="padding-bottom:100px; overflow: visible;">
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pl-0 left">
                                <h6> User Info</h6>
                            </div>
                        </div>

                        <div class="p-formgrid p-grid p-d-flex p-ai-start">
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 left">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': usermappingForm.get('designation')?.errors?.required}">
                                        Designation <span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-dropdown id="designationid" [(ngModel)]="toClearDesignation" name="designation"
                                        placeholder="Select a Designation" [options]="designationList"
                                        [autoDisplayFirst]="false" [filter]="true" formControlName="designation"
                                        optionLabel="bd_designation" filterBy="bd_designation"
                                        [ngClass]="{'error': usermappingForm.get('designation')!.invalid && (usermappingForm.get('designation')!.dirty || usermappingForm.get('designation')!.touched)}"
                                        (onChange)="getimmediateSupervisorList(usermappingForm.value.designation)"
                                        resetFilterOnHide="true" scrollHeight="150px">
                                    </p-dropdown>
                                    <div class="invalid-feedback "
                                        *ngIf="usermappingForm.get('designation')!.invalid && (usermappingForm.get('designation')!.dirty || usermappingForm.get('designation')!.touched)">
                                        <div *ngIf="usermappingForm.get('designation')?.errors?.required">
                                            Designation is required.
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">

                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Mobile No. <span class='invalid-feedback-astrik'>*</span>
                                    </label>

                                    <p-inputMask id="mobileNo_id" name="mobileNo_name" formControlName="mobileNo"
                                        [(ngModel)]="toClearMobile" mask="9999999999" placeholder="" slotChar="">
                                    </p-inputMask>

                                    <div class="invalid-feedback"
                                        *ngIf="usermappingForm.get('mobileNo')!.invalid && (usermappingForm.get('mobileNo')!.dirty || usermappingForm.get('mobileNo')!.touched)">
                                        <div *ngIf="usermappingForm.get('mobileNo')?.errors?.required">
                                            Mobile No. is required.
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Email
                                    </label>
                                    <input type="text" id="email_id" name="email_name" formControlName="emailId"
                                        pInputText placeholder="" class="p-inputtext  " [attr.disabled]="true" />

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left p-pl-0">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Shadow Portfolio <span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-multiSelect id="shadowPortfolioEmployeeNameAndDesignationid" [(ngModel)]="toClearShadowPortfolio" scrollHeight="150px"
                                        name="shadowPortfolioEmployeeNameAndDesignation" scrollHeight="160px"
                                        [options]="shadowPortfolioEmployeeNameAndDesignationList" placeholder="Select" [filter]="true"
                                        formControlName="shadowPortfolioEmployeeNameAndDesignation" optionLabel="shadowPortfolioEmployeeNameAndDesignation"
                                        resetFilterOnHide="true" [showClear]="true" display="chip">
                                    </p-multiSelect>
                                    <div class="invalid-feedback "
                                        *ngIf="usermappingForm.get('shadowPortfolioEmployeeNameAndDesignation')!.invalid && (usermappingForm.get('shadowPortfolioEmployeeNameAndDesignation')!.dirty || usermappingForm.get('shadowPortfolioEmployeeNameAndDesignation')!.touched)">
                                        <div *ngIf="usermappingForm.get('shadowPortfolioEmployeeNameAndDesignation')?.errors?.required">
                                            Shadow Portfolio is required.
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 right p-d-flex p-ai-end signatureCard">
                                <div *ngIf="signBtn" class=" p-fluid   p-field p-w-100   ">
                                    <label>Signature</label>
                                    <p-fileUpload customUpload="true" class="p-fileupload-choose" id="fileUpload1"
                                        name="file" accept="image/*, .jpeg , .jpg, .png," maxFileSize=5000000
                                        mode="advanced" [showUploadButton]="false" [showCancelButton]="false"
                                        [disabled]="disableUpload" #fileUploadfileUpload13 class="signatureUpload"
                                        (onSelect)="onSelectfileUpload1($event)" chooseLabel="Upload Signature">
                                    </p-fileUpload>
                                </div>
                                <div *ngIf="!signBtn" class="p-fluid  p-field p-w-100">
                                    <label>Signature</label>
                                    <p-card class="p-element p-component" id="p_card">
                                        <div
                                            class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-jc-between p-flex-wrap">
                                            <div class="p-col-11 p-d-flex p-ai-center p-pl-0">
                                                <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center">
                                                    <img [src]="urlSafe" class="p-mr-2" width="28px" height="28px"
                                                        alt="image" />
                                                    <a class="limit-text-length" (click)="showSignaturePreview()">
                                                        <div
                                                            class="p-col-12 p-d-flex p-flex-column p-flex-wrap p-ai-start p-pl-0">
                                                            <span
                                                                class="limit-text-length attachmentName cursor-pointer signatureLabel p-ml-0">
                                                                {{signatureResult?.fileName}}
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="p-col-1 p-d-flex p-jc-end p-pr-0 p-pl-0">
                                                <i class="pi pi-trash cursor-pointer attachmentIcon" aria-hidden="true"
                                                    (click)="onDeletePopup()"></i>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                            </div>

                        </div>


                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pl-0">

                                <h6> Superior Info</h6>

                            </div>
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 left">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': usermappingForm.get('immediateSupervisor')?.errors?.required}">
                                        Immediate Supervisor <span class='invalid-feedback-astrik'>*</span>
                                    </label>

                                    <p-dropdown id="immediateSupervisorid" (onChange)="onselect()"
                                        name="immediateSupervisor" placeholder="Select a Immediate Supervisor"
                                        [(ngModel)]="currectimmediateSupervisor" [options]="immediateSupervisorList"
                                        [filter]="true" [autoDisplayFirst]="false" formControlName="immediateSupervisor"
                                        [ngClass]="{'error': usermappingForm.get('immediateSupervisor')!.invalid && (usermappingForm.get('immediateSupervisor')!.dirty || usermappingForm.get('immediateSupervisor')!.touched)}"
                                        optionLabel="immediateSupervisor" resetFilterOnHide="true" filterBy="immediateSupervisor">
                                    </p-dropdown>

                                    <div class="invalid-feedback"
                                        *ngIf="usermappingForm.get('immediateSupervisor')!.invalid && (usermappingForm.get('immediateSupervisor')!.dirty || usermappingForm.get('immediateSupervisor')!.touched)">
                                        <div *ngIf="usermappingForm.get('immediateSupervisor')?.errors?.required">
                                            Immediate Supervisor is required.
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Immediate Supervisor BD
                                    </label>
                                    <input type="text" id="imeediateSupervisorLevelName_id" [value]="immediateSupBD"
                                        name="imeediateSupervisorLevelName_name"
                                        formControlName="imeediateSupervisorLevelName" pInputText placeholder=""
                                        class="p-inputtext  " [attr.disabled]="true" />

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Director Name
                                    </label>
                                    <input type="text" id="directorName_id" [value]="Director" name="directorName_name"
                                        formControlName="directorName" pInputText placeholder="" class="p-inputtext  "
                                        [attr.disabled]="true" />

                                </div>

                            </div>
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        HR Designation
                                    </label>
                                    <input type="text" id="hrDesignation_id" name="hrDesignation_name"
                                        formControlName="hrDesignation" pInputText placeholder="" class="p-inputtext  "
                                        [attr.disabled]="true" />

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Ops Spoc<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-multiSelect id="OpsSpocid" scrollHeight="130px"
                                        name="OpsSpoc" scrollHeight="160px"
                                        [options]="OpsSpocList" placeholder="Select OpsSpoc" [filter]="true"
                                        formControlName="OpsSpoc" optionLabel="employeeName"
                                        resetFilterOnHide="true" [showClear]="true" display="chip" [autoDisplayFirst]="false">
                                    </p-multiSelect>
                                    <div class="invalid-feedback"
                                        *ngIf="usermappingForm.get('OpsSpoc')!.invalid && (usermappingForm.get('OpsSpoc')!.dirty || usermappingForm.get('OpsSpoc')!.touched)">
                                        <div *ngIf="usermappingForm.get('OpsSpoc')?.errors?.required">
                                            Immediate Supervisor is required.
                                        </div>
                                    </div>
                                    <!-- <p-dropdown id="OpsSpocid" name="OpsSpoc" placeholder="Select OpsSpoc"
                                        [options]="OpsSpocList" optionLabel="employeeName" [autoDisplayFirst]="false"
                                        [filter]="true" formControlName="OpsSpoc" [showClear]="true"
                                        scrollHeight="130px">
                                    </p-dropdown> -->

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Team<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-dropdown id="teamid" [(ngModel)]="toClareTeam" name="team" placeholder="Select"
                                        [options]="teamList" [autoDisplayFirst]="false" formControlName="team"
                                        optionLabel="value"
                                        [ngClass]="{'error': usermappingForm.get('team')!.invalid && (usermappingForm.get('team')!.dirty || usermappingForm.get('team')!.touched)}">
                                    </p-dropdown>
                                    <div class="invalid-feedback"
                                        *ngIf="usermappingForm.get('team')!.invalid && (usermappingForm.get('team')!.dirty || usermappingForm.get('team')!.touched)">
                                        <div *ngIf="usermappingForm.get('team')?.errors?.required">
                                            Team is required.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 left">
                                <div class=" p-fluid   p-field ">
                                    <label
                                        [ngClass]="{'field-required': usermappingForm.get('employeeNameAndDesignation')?.errors?.required}">
                                        PA Approver<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-dropdown id="employeeNameAndDesignationid" [(ngModel)]="toClearApprover"
                                        name="employeeNameAndDesignation" placeholder="Select" scrollHeight="100px"
                                        [options]="employeeNameAndDesignationList" [filter]="true" filterBy="paEmployeeNameAndDesignation"
                                        [ngClass]="{'error': usermappingForm.get('employeeNameAndDesignation')!.invalid && (usermappingForm.get('employeeNameAndDesignation')!.dirty || usermappingForm.get('employeeNameAndDesignation')!.touched)}"
                                        [autoDisplayFirst]="false" formControlName="employeeNameAndDesignation"
                                        optionLabel="paEmployeeNameAndDesignation" resetFilterOnHide="true">
                                    </p-dropdown>
                                    <div class="invalid-feedback "
                                        *ngIf="usermappingForm.get('employeeNameAndDesignation')!.invalid && (usermappingForm.get('employeeNameAndDesignation')!.dirty || usermappingForm.get('employeeNameAndDesignation')!.touched)">
                                        <div
                                            *ngIf="usermappingForm.get('employeeNameAndDesignation')?.errors?.required">
                                            PA Approver is required.
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 left p-p-0 popupFooterBtnContainer">
                        <div
                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-end p-ai-center p-left popupFooterBtnContent p-pr-4 p-pt-2 p-mb-3">
                            <div class="btnwrap">
                                <button pButton class="p-button-brand2" type="button"
                                    (click)="onCcprimebutton(usermappingForm);" label="Cancel">
                                </button>
                            </div>
                            <div class="btnwrap">
                                <button pButton class="p-button-primary p-mr-0"
                                    [disabled]="!usermappingForm.valid || loaderFlag" type="submit"
                                    (click)="onCcprimebutton1(usermappingForm);" label="Save">
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="no_data_div" *ngIf="loaderFlag">
            <div class="app-loading" id="loader">
                <div class="loadbg"></div>
                <div class="bdload">
                    <div class="spinner">
                        <div class="rect1"></div>
                        <div class="rect2"></div>
                        <div class="rect3"></div>
                        <div class="rect4"></div>
                        <div class="rect5"></div>
                        <span>Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <br>
</div>
<p-dialog header="Crop Image" class="cropImageModal" [(visible)]="displayModal" [modal]="true"
    [style]="{width: '60vw', height: '70vh'}" [draggable]="false" [resizable]="false" [closable]="true">
    <image-cropper styleClass="imageCropper" [imageChangedEvent]="imgChangeEvt"
        [imageFile]="imageChangedEventfileUpload1" [maintainAspectRatio]="false" [resizeToWidth]="256" format="png"
        (imageCropped)="imageCroppedfileUpload1($event)">
    </image-cropper>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="onCancelCrop()" label="Cancel" class="p-button-brand2"></button>
        <button pButton type="button" label="Upload" class="p-button-primary" (click)="onCroppedUploadBtn()"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Preview" [(visible)]="displayPreview" class="signPreview" [modal]="true"
    [style]="{width: '60vw', height: '60vh'}" [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-d-flex p-jc-center">
        <img [src]="urlSafe" height="100%" style="max-height: 54vh;" alt="image" />
    </div>
</p-dialog>
<p-dialog header="Delete Signature" name="deletePopup" [(visible)]="deletePopUp" [modal]="true" class="alertPopup"
    [closable]="true">
    <p class="p-text-left">Are you sure you want to delete Signature?</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="No" (click)="deletePopUp=false" class="p-button-secondary "></button>
        <p-button type="button" (click)="onDeleteSignature()" label="Yes" class="p-button-primary"></p-button>
    </ng-template>
</p-dialog>
<p-dialog header="Alert" [(visible)]="changeDesigAlert" [modal]="true" class="alertPopup" [closable]="true"
    [draggable]="false">
    <p class="p-text-left">Are you sure you want to change the designation?</p>
    <p class="p-text-left">This will also delete the signature.</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="No" (click)="onSelectNo()" class="p-button-secondary"></button>
        <p-button type="button" (click)="onDeleteSignature()" label="Yes" class="p-button-primary"></p-button>
    </ng-template>
</p-dialog>