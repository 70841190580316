import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

import { VERSION } from 'app/app.constants';
import { LANGUAGES } from 'app/core/language/language.constants';
import { AccountService } from 'app/core/auth/account.service';
import { LoginService } from 'app/core/login/login.service';
import { ProfileService } from 'app/layouts/profiles/profile.service';
@Component({
  selector: 'jhi-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  inProduction?: boolean;
  isNavbarCollapsed = true;
  languages = LANGUAGES;
  swaggerEnabled?: boolean;
  version: string;
  userName: string | null = null;
  shortName: string | null = null;
  items: any= [];
  userItems: any = [];
  checked1 = false;
  lightLogo = true;
  webView: boolean= true;

  constructor(
    private loginService: LoginService,
    private sessionStorage: SessionStorageService,
    private accountService: AccountService,
    private profileService: ProfileService,
    private router: Router,
  ) {
    this.version = VERSION ? (VERSION.toLowerCase().startsWith('v') ? VERSION : 'v' + VERSION) : '';
  }

  @HostListener('window:resize', ['$event'])
  ngOnInit(): void {
    this.items=[
      {
        label: "Home",
        visible:true,
        routerLink: "/",
        
      },
          {
            label: "NexEra",
            visible:true,
            items: [
              {
    label: "Meetings",
    visible:true,
    routerLink: "/pages/meetings",
    },
    {
    label: "Opportunity List",
    visible:true,
    routerLink: "/pages/opportunitylist",
    },
    {
    label: "Create Entity",
    visible:true,
    routerLink: "/pages/createentity",
    },
    {
    label: "Meeting List",
    visible:true,
    routerLink: "/pages/meetinglist",
    },
    {
    label: "Create New Opportunity",
    visible:true,
    routerLink: "/pages/createnewopportunity",
    },
    {
    label: "View Entity",
    visible:true,
    routerLink: "/pages/viewentity",
    },
    {
    label: "Search Entity",
    visible:true,
    routerLink: "/searchentity",
    },
    {
    label: "Create Meeting",
    visible:true,
    routerLink: "/pages/createmeeting",
    },
    {
    label: "View Entity Details",
    visible:true,
    routerLink: "/pages/viewentitydetails",
    },

          ]
          },
      {
        label: "View Opportunity Detail",
        visible:true,
        routerLink: "/pages/viewopportunitydetail",
        
      },
      
    ]

    this.userItems=[
  {label: 'Sign Out', command: () => {
      this.logout();
  }}]
    localStorage.setItem('theme', 'light');
    this.accountService.identity().subscribe(account => {
      if (account) {
        this.userName = account.firstName + ' ' +account.lastName;
        this.shortName = account.firstName.charAt(0) + account.lastName.charAt(0);
      }
    });
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.swaggerEnabled = profileInfo.swaggerEnabled;
    });
    this.onResize();
  }

  onResize() {
  if(window.innerWidth<=960) { 
       this.webView=false;
       this.items.push(...this.userItems)
      }
      else {
        this.webView=true;
      } 
  }

  changeLanguage(languageKey: string): void {
    this.sessionStorage.store('locale', languageKey);
  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  login(): void {
    this.loginService.login();
  }

  logout(): void {
    this.collapseNavbar();
    this.loginService.logout();
    this.router.navigate(['']);
  }

  toggleNavbar(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  getImageUrl(): string {
    return this.isAuthenticated() ? this.accountService.getImageUrl() : '';
  }
  switchTheme(): void {
    if (this.checked1) {
      const themer = document.getElementById('theme');
      if (themer) {
        themer.classList.add('dark');
        themer.classList.remove('light');
      }
      localStorage.setItem('theme', 'dark');
      this.lightLogo = false;
    } else {        
      const themer = document.getElementById('theme');
      if (themer) {
        themer.classList.remove('dark');
        themer.classList.add('light');
      }
      localStorage.setItem('theme', 'light');
      this.lightLogo = true;
    }    
  }
}
