<div class="p-col-12 p-px-1" id="center">
    <p class="p-mb-4">There are few details are missing in {{errorMessage}}. <br> 
            Please enter in order to save the reporting.</p>
    <div class="btnwrap p-d-flex p-jc-end p-mr-1">
        <button pButton id="save" class="p-button-primary p-button" (click)="onClose();" type="button"  label="Ok">
        </button>
    </div>
</div>

                
