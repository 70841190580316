import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { RealizationScopeAuthService } from 'app/core/auth/realization-scope-auth.service'; // ASK
import { IRFRealizationGridComponent } from './irf-realization-grid/irf-realization-grid.component';

const routes: Routes = [
    {
        path: 'listing',
        component: IRFRealizationGridComponent,
        data: {
            authorities: [],
            pageTitle: 'NexEra 2.0'
        },
        canActivate: [UserRouteAccessService, RealizationScopeAuthService]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class IRFRealizationRoutingModule {}