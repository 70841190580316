import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'item-list-details',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class QCItemListComponent implements OnInit {

  listItems;
  dateForm : FormGroup;
  isApproveAllEnabled = true;
  disabled;

  @Input('form') set dateDetailsForm(formVal : FormGroup) {
    this.dateForm = formVal;
    this.listItems = (<FormGroup>formVal.get('list')).controls;
    console.log("date details :: ", this.listItems);
    if(this.disabled !== undefined) {
      this.setApproveAll();
    }
  };

  @Input('disabled') set isDisabled(value: boolean) {
    this.disabled = value;
    if(this.listItems?.length > 0) {
      this.setApproveAll();
    }
  };

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  patchForm(formval) {
    let key = formval.key;
    let arr : Array <any> = this.getDateFormList.value;
    arr = arr.map(item => {
      if(item?.key === key) {
        item = formval;
      }
      return item;
    });
    this.dateForm.patchValue({'list' : arr});

  }

  get getDateFormList() {
    return this.dateForm.get('list');
  }

  approveAll() {
    (<any>(<FormGroup>this.getDateFormList)?.controls).forEach((item : FormControl) => {
      let value = item.value;
      if(value?.isApprove !== undefined) {
        item.patchValue({isApprove : 'Approved'});
        item.get('comment').removeValidators(Validators.required);
      }
    });
    // this.getDateFormList.patchValue({list : val});
    // console.log("form after approve all ::", this.getDateFormList.value);
    // this.cd.detectChanges();
    // this.listItems = (<FormGroup>this.getDateFormList).controls;
    // this.listItems = [...this.listItems];
  }

  setApproveAll() {
    if(this.disabled) { // all fields disabled
      // check if each field have approve value
      this.listItems.forEach(item => {
        if(item.isApprove === null || item.isApprove === "Rejected") {
          this.isApproveAllEnabled = true;
          return;
        }
      });
      this.isApproveAllEnabled = false;
    }
  }

  trackChange(index, item) {
      const val = item?.value || item;
      return val.key + "" + val.isApprove;
  }
}
