import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SfBillingService } from '../sf-billing.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddAgreementComponent } from '../add-agreement/add-agreement.component';
import { AttachmentModelComponent } from 'app/pages/attachment-model/attachment-model.component';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';

@Component({
  selector: 'backoffice-sf-edit-company-details',
  templateUrl: './sf-edit-company-details.component.html',
  styleUrls: ['./sf-edit-company-details.component.scss'],
  providers: [DialogService, MessageService]
})
export class SfEditCompanyDetailsComponent implements OnInit, OnDestroy {

  homeiconapplicationBreadcrumb = { icon: 'pi pi-home', routerLink: ['/backoffice/agreement-archive/byclient'] };
  applicationBreadcrumbList = [];

  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;

  apiFail: boolean = false;
  zoomValue: number = 1;
  rotate: number = 0
  displayPreview: boolean = false;
  dataLoader: boolean = false;
  isPdf: boolean = true;
  previewFailed: boolean = false;
  urlSafe: any;
  canvasRotation: number = 0;
  
  billId: any;
  productMasterList: any = [];
  productMasterMap: any = {};
  instrumentMasterList: any = [];
  instrumentMasterMap: any = {};
  billStatusList: any = [];
  deleteAgreementList: any = [];

  totalSfAmt: Number = 0
  firstAgreement: any;
  formError: boolean = false;
  attachToDelete: any;
  attachMissing: boolean = false;
  rejectedAttach: any = [];
  empId: any;
  sendTo: any;
  uploadedDoc: any;
  rejectedReason: any;
  rejectedComment: any;
  deleteAttach: boolean = false;
  deleteAgreementPopup: boolean = false;
  deleteAllAgreement: boolean = true;
  deleteAllAgreePopup: boolean = false;
  unmappedAgreement: any = [];
  dummyAgreement: any = [];
  apiCallList: any = [];
  billAlreadySent: boolean = false;
  addAgreementDialog: DynamicDialogRef = new DynamicDialogRef();
  attachmentDialog: DynamicDialogRef = new DynamicDialogRef();
  scopeList: any;
  readScope: boolean = false;
  remark;


  agreementFormList = this.fb.group({
    agreements: this.fb.array([], Validators.required),
    remarkValue:['' as string]
  });

  get agreements(){
    return this.agreementFormList.get('agreements') as FormArray;
  }

  constructor(private messageService: MessageService, private fb: FormBuilder, private route: ActivatedRoute, private sfBillingService: SfBillingService, private dialogService: DialogService, private encDec: EncryptDecryptInterceptor, private router: Router) { }

  ngOnInit(): void {
    this.empId = this.encDec.decrypt(localStorage.getItem('empid'));
    this.route.paramMap.subscribe(params => {
      this.billId = params.get('id');
    });

    this.scopeList = this.encDec.decrypt(localStorage.getItem('scopes'));
    let scope: any = this.scopeList.split(",");
    if(scope.indexOf('BILLING_EDIT_CREATE') == -1){
      this.readScope = true;
    } else {
      this.readScope = false;
    }

    this.productMaster();
    this.instrumentMaster();
    this.combineMaster();
    setTimeout(() => {
      this.getBillDetails();
      this.getUnmappedAgreements();
      this.getDummyAgreements();
    }, 500);
  }

  getBillDetails() {
    let paylaod = {
      "companyName": null, 
      "financialYear": null, 
      "sfBillId": this.billId, 
      "billStatus": null
    }

    this.apiCallList.push(this.sfBillingService.sfBillDetails(paylaod).subscribe((res: any[]) => {
      if(res.length > 0){
        this.firstAgreement = res[0];
        this.agreementFormList.controls.remarkValue.patchValue(this.firstAgreement?.remark);
        this.applicationBreadcrumbList = [
          { label: `Edit SF Bills`, routerLink: ['/sf-billing/edit-sf-grid'] },
          { label: this.firstAgreement?.companyName, routerLink: [] },
        ];
        // 148	SF Bill Status	Rejected By BD
        // 150	SF Bill Status	Rejected By Finance
        if(this.firstAgreement?.billStatus == 148 || this.firstAgreement?.billStatus == 150){
          this.rejectedReason = this.billStatusList.find(b => b.id == this.firstAgreement?.rejectedReasonId)?.value;
          this.rejectedComment = this.firstAgreement?.rejectedComment;
        }
        this.getAttachment();
        res.forEach(el => {
          let form = this.fb.group({
            id: el.id,
            agreementId: el.agreementId,
            isSelected: false,
            caseType: el.caseType,
            agreementType: el.sfMandateValidity,
            initiationType: el.initiationType,
            productName: this.productMasterList.find(p => p.id == el.productId)?.product_name,
            instrument: this.instrumentMasterList.find(i => i.id == el.instrumentId)?.name,
            productId: el.productId,
            instrumentId: el.instrumentId,
            qcDate: el.qcDate,
            reportingDate: el.reportingDate,
            rcmDate: el.rcmDate,
            rrDate: el.rrDate,
            mandateQtm: el.applicableAmount,
            sfActualPercent: el.sfActualPercentage,
            sfMethodAmt: el.sfActualFee,
            revisedSfActual: el.revisedSf,
            feeRule: el.feeRule,
            sfMaxAmt: el.sfMaxAmount?.toFixed(2),
            sfMinAmt: el.sfMinAmount?.toFixed(2),
            billedDays: el.billedDays,
            sfStartDate: el.sfStartDate == null ? [null, [Validators.required]]:[new Date(el.sfStartDate), [Validators.required]],
            sfEndDate: [new Date(el.sfEndDate), [Validators.required]],
            sfAmt: [el.sfAmount, [Validators.required]],
            outstandingQtm: [el.outStandingAmount, [Validators.required]],
            billDetails: [el.billDetails, [Validators.required, Validators.maxLength(150)]],
            isDateGreater: null,
            rptCrm: el.rptInCrm,
            rptCc: el.rptInCc,
            remark: el.remark
          });
          this.markFieldDirty(form);
          this.agreements.push(form);
        });
        this.calculateSfAmount();
      }
    }, (_err) => {
      this.apiFail = true;
    }));
  }

  getUnmappedAgreements() {
    this.apiCallList.push(this.sfBillingService.sfBillUnmappedAgreements(this.billId).subscribe((res: any) => {
      if (res && res.length > 0) {
        this.unmappedAgreement = res;
      }
    }, (err) => {
      this.apiFail = true;
    }));
  }
  
  getDummyAgreements() {
    this.apiCallList.push(this.sfBillingService.sfBillDummyAgreements(this.billId).subscribe((res: any) => {
      if (res && res.length > 0) {
        this.dummyAgreement = res;
      }
    }, (err) => {
      this.apiFail = true;
    }));
  }

  
  deleteClicked(agreementId, event, index){
    let form = this.agreements.at(index);
    if(agreementId != undefined && agreementId != null){
      if(event.checked){
        this.deleteAgreementList.push(agreementId);
        this.makeFieldNonMandatory(form);
      } else {
        let index = this.deleteAgreementList.findIndex(x => x == agreementId);
        if(index != -1){
          this.deleteAgreementList.splice(index, 1);
          this.makeFieldMandatory(form);
        }
      }
    }
  }

  calculateSfAmount(){
    let total = 0;
    this.agreements.value?.forEach(el => {
      total += Number(el.sfAmt);
    });
    this.totalSfAmt = total;
  }

  compareDates(data, i){
    let billDays = (Math.round(data?.sfEndDate?.getTime() - data?.sfStartDate?.getTime())/(1000 * 3600 * 24) + 1);
    this.agreements.at(i).get('billedDays').setValue(billDays);
    if(data?.sfStartDate?.getTime() >= data?.sfEndDate?.getTime()){
      this.agreements.at(i).get('isDateGreater').setValue(true);
    } else {
      this.agreements.at(i).get('isDateGreater').setValue(false);
    }
  }

  onAddAgreement(value) {
    this.addAgreementDialog = this.dialogService.open(AddAgreementComponent, {
      contentStyle: { "height": "auto", "maxHeight": "80vh", "overflow": "hidden" },
      data: {
        agreementList: this.unmappedAgreement, 
        dummyAgreement: this.dummyAgreement, 
        firstAgreement: this.firstAgreement, 
        aggType: value,
        productMaster: this.productMasterMap,
        instrumentMaster: this.instrumentMasterMap
      },
      width: '90%',
      header: this.firstAgreement?.companyName
    });
    this.addAgreementDialog.onClose.subscribe(response => {
      if(response && response?.length > 0){
        response.forEach(el => {
          let form = this.fb.group({
            id: el.id,
            agreementId: el.agreementId,
            isSelected: false,
            caseType: el.caseType,
            agreementType: el.sfMandateValidity,
            initiationType: el.initiationType,
            productName: this.productMasterList.find(p => p.id == el.productId)?.product_name,
            instrument: this.instrumentMasterList.find(i => i.id == el.instrumentId)?.name,
            productId: el.productId,
            instrumentId: el.instrumentId,
            qcDate: el.qcDate,
            reportingDate: el.reportingDate,
            rcmDate: el.rcmDate,
            rrDate: el.rrDate,
            mandateQtm: el.outStandingAmount,
            sfActualPercent: el.sfActualPercentage,
            sfMethodAmt: el.sfActualFee,
            revisedSfActual: el.revisedSf,
            feeRule: el.feeRule,
            sfMaxAmt: el.sfMaxAmount?.toFixed(2),
            sfMinAmt: el.sfMinAmount?.toFixed(2),
            billedDays: null,
            sfStartDate: [null, [Validators.required]],
            sfEndDate: [null, [Validators.required]],
            sfAmt: [null, [Validators.required]],
            outstandingQtm: [el.outStandingAmount, [Validators.required]],
            billDetails: [el.billDetails, [Validators.required]],
            isDateGreater: null,
            rptCrm: null,
            rptCc: null,
            costCenterId: el.costCenterId
          });
          this.markFieldDirty(form)
          this.agreements.push(form);
          setTimeout(() => {
            this.messageService.clear();
          }, 3000);
          this.unmappedAgreement.forEach(un => {
            if(el.agreementId == un.agreementId){
              un.isAdded = true;
            }
          });
          this.dummyAgreement.forEach(d => {
            if(el.agreementId == d.agreementId){
              d.isAdded = true;
            }
          });
        });
        this.messageService.add({ severity: 'success', detail: 'Agreement added successfully' });
      }
    }, (err) => {
      this.apiFail = true;
    });
  }
  runValidations(sendTo){
    // 149	SF Bill Status	Sent to Finance
    // 147	SF Bill Status	Sent to BD
    let isDateGreater: boolean = false;
    this.agreements.controls.forEach((c) => {
      if(!c.get('isSelected').value){
        this.deleteAllAgreement = false;
      }
      if(c.get('isDateGreater').value){
        isDateGreater = true;
      }
      c.get('sfStartDate').markAsDirty();
      c.get('sfEndDate').markAsDirty();
      c.get('sfAmt').markAsDirty();
      c.get('outstandingQtm').markAsDirty();
      c.get('billDetails').markAsDirty();
    });

    if(this.deleteAllAgreement){
      this.deleteAllAgreePopup = true;
      return;
    }
    
    this.sendTo = sendTo; 
    this.formError = this.agreementFormList.status == 'INVALID';
    if(this.formError || isDateGreater){
      return;
    }

    if(this.uploadedDoc == undefined || this.uploadedDoc == null){
      this.attachMissing = true;
    }
    
    if(this.formError || this.attachMissing || isDateGreater){
      return;
    }

    if(this.deleteAgreementList?.length > 0){
      this.deleteAgreementPopup = true;
      return;
    }

    this.createPayload();
  }

  createPayload(){
    let data = this.agreements.value.map((a) => ({
      agreementId: a.agreementId,
      sfStartDate: a.sfStartDate,
      sfEndDate: a.sfEndDate,
      billedDays: a.billedDays,
      sfAmount: a.sfAmt,
      outStandingAmount: a.outstandingQtm,
      billDetails: a.billDetails,
      isDeleted: a.isSelected ? 'Y' : 'N',
      caseType: a.caseType,
      sfMandateValidity: a.agreementType,
      initiationType: a.initiationType,
      productId: a.productId,
      instrumentId: a.instrumentId,
      qcDate: a.qcDate,
      reportingDate: a.reportingDate,
      rrDate: a.rrDate,
      rcmDate: a.rcmDate,
      applicableAmount: a.mandateQtm,
      sfActualPercentage: a.sfActualPercent,
      sfActualFee: a.sfMethodAmt, 
      feeRule: a.feeRule,
      sfMaxAmount: a.sfMaxAmt,
      sfMinAmount: a.sfMinAmt,
      revisedSf: a.revisedSfActual,
      rptInCrm: a.rptCrm,
      rptInCc: a.rptCc,
      costCenterId: a.costCenterId,
      remark:this.agreementFormList.controls.remarkValue.value
    }));

    let paylaod = {
      "sfBillId": this.firstAgreement?.sfBillId,
      "sfBillAgreements": data,
      "requestedBy": this.empId,
      "billStatusId": this.sendTo == 'bd' ? 147 : 149
    }

    console.log("remarkk: ",this.agreementFormList.controls.remarkValue);
    console.log("Payloaddd: ",paylaod);

    this.sfBillingService.saveSfBill(paylaod).subscribe((res: any) => {
      this.deleteAgreementPopup = false;
      if(this.sendTo == 'bd'){
        this.router.navigate(['/sf-billing/edit-sf-grid'], {state: {msg: 'sent successfully to bd'}});
      } else {
        this.router.navigate(['/sf-billing/edit-sf-grid'], {state: {msg: 'sent successfully to fin'}});
      }
    }, (err) => {
      if(err?.status == 400){
        this.billAlreadySent = true;
      } else {
        this.apiFail = true;
      }
    });
  }

  getAttachment(){
    let paylaod = {
      oppId: this.firstAgreement?.sfBillId,
      moduleName: "SfBilling"
    }
    this.apiCallList.push(this.sfBillingService.getAttachmentByModule(paylaod).subscribe((res) => {
      console.log("Attachment details ",res);
      if(res?.length > 0){
        let doc = res?.filter((r) => {
          return r.fileTypeid == 1;
        });
        this.uploadedDoc = doc[0];

        // 148	SF Bill Status	Rejected By BD
        // 150	SF Bill Status	Rejected By Finance
        // 2 bd attach rejected
        // 3 finance attach rejected
        res?.forEach((r) => {
          if(this.firstAgreement?.billStatus == 148 && r.fileTypeid == 2){
            this.rejectedAttach.push(r)
          }
        });
      }
    }));

    if(this.firstAgreement?.billStatus == 150){
      this.getFinanceAttachment();
    }
  }

  getFinanceAttachment(){
    let paylaod;
    if(this.firstAgreement?.billStatus == 150){
      paylaod = {
        oppId: this.firstAgreement?.attachmentFlag,
        moduleName: "SfBilling"
      }
      
      this.apiCallList.push(this.sfBillingService.getAttachmentByModule(paylaod).subscribe((res) => {
        console.log("Attachment details ",res);
        if(res?.length > 0){
          // 150	SF Bill Status	Rejected By Finance
          // 3 finance attach rejected
          res?.forEach((r) => {
            if(this.firstAgreement?.billStatus == 150 && r.fileTypeid == 3){
              this.rejectedAttach.push(r)
            }
          });
        }
      }));
    }
  }

  productMaster(){
    this.apiCallList.push(this.sfBillingService.productList().subscribe((res: any) => {
      if (res && res.length > 0) {
        this.productMasterList = res;
        this.productMasterMap = res?.reduce((acc, item) => {
          acc[item.id] = item.product_name;
          return acc;
        }, {});
      }
    }));
  }

  instrumentMaster(){
    this.apiCallList.push(this.sfBillingService.instrumentList().subscribe((res: any) => {
      if (res && res.length > 0) {
        this.instrumentMasterList = res;
        this.instrumentMasterMap = res?.reduce((acc, item) => {
          acc[item.id] = item.name;
          return acc;
        }, {});
      }
    }));
  }

  combineMaster(){
    this.apiCallList.push(this.sfBillingService.combineMasterList().subscribe((res: any) => {
      if (res && res.length > 0) {
        this.billStatusList = res?.filter((r) => {
          return r.name == "SF Bill Rejected Reason";
        });


        if(this.firstAgreement &&  (this.firstAgreement?.billStatus == 148 || this.firstAgreement?.billStatus == 150)){
          this.rejectedReason = this.billStatusList.find(b => b.id == this.firstAgreement?.rejectedReasonId)?.value;
          this.rejectedComment = this.firstAgreement?.rejectedComment;
        }
      }
    }));
  }

  markFieldDirty(form){
    form.get('sfStartDate').markAsDirty();
    form.get('sfEndDate').markAsDirty();
    form.get('sfAmt').markAsDirty();
    form.get('outstandingQtm').markAsDirty();
    form.get('billDetails').markAsDirty();
  }

  makeFieldMandatory(form){
    form.get('sfStartDate').addValidators([Validators.required]);
    form.get('sfEndDate').addValidators([Validators.required]);
    form.get('sfAmt').addValidators([Validators.required]);
    form.get('outstandingQtm').addValidators([Validators.required]);
    form.get('billDetails').addValidators([Validators.required]);
    this.markFieldDirty(form);
    this.updateValueandValidity(form);
  }
  
  makeFieldNonMandatory(form){
    form.get('sfStartDate').removeValidators([Validators.required]);
    form.get('sfEndDate').removeValidators([Validators.required]);
    form.get('sfAmt').removeValidators([Validators.required]);
    form.get('outstandingQtm').removeValidators([Validators.required]);
    form.get('billDetails').removeValidators([Validators.required]);
    this.updateValueandValidity(form);
  }

  updateValueandValidity(form){
    form.get('sfStartDate').updateValueAndValidity();
    form.get('sfEndDate').updateValueAndValidity();
    form.get('sfAmt').updateValueAndValidity();
    form.get('outstandingQtm').updateValueAndValidity();
    form.get('billDetails').updateValueAndValidity();
  }

  addAttachment() {
    this.attachmentDialog = this.dialogService.open(AttachmentModelComponent, {
      contentStyle: { "height": "auto", "maxHeight": "550px", "overflow": "visible" },
      header: 'Upload Attachment',
      closable: true,
      data: { hideAttachType: true, type: '.msg' },
      styleClass: 'sm',
    });
    this.attachmentDialog.onClose.subscribe(response => {
      if(response?.length > 0){
        this.uploadedDoc = response[0];
        let payload = {
          userId: this.empId,
          moduleName: "SfBilling",
          moduleId: this.firstAgreement?.sfBillId,
          fileName: this.uploadedDoc?.fileName,
          file: this.uploadedDoc?.file,
          fileContentType: this.uploadedDoc?.fileContentType,
          fileSize: this.uploadedDoc?.fileSize,
          fileTypeid: 1,
          fileTypeName: "SF Billing Edit",
          fileId: null
        }
        this.apiCallList.push(this.sfBillingService.uploadSingleAttachment(payload).subscribe((res) => {
          if(res?.statusCode == 200){
            this.uploadedDoc.fileId = res?.fileId;
            this.messageService.add({ severity: 'success', detail: 'File uploaded successfully' });
            setTimeout(() => {
              this.messageService.clear();
            }, 3000);
          }
        }, (err) => {
          this.apiFail = true;
        }));
      }
    });
  }

  showAttachmentPreview(item) {
    this.zoomValue = 1;
    this.rotate = 0;
    if (item?.fileId) {
      this.dataLoader = true;
      let payload = {
        id: item.fileId
      }
      this.apiCallList.push(this.sfBillingService.getBase64(payload).subscribe((res) => {
        if (res && res?.file) {
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          let isPreviewable = true;
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else if ( "msg" === ext1?.toLowerCase()) {
            mime = 'data:application/vnd.ms-outlook;base64,';
            isPreviewable = false;
          } else if(["xlsx", "xls"].includes(ext1?.toLowerCase())) {
            mime = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
            isPreviewable = false;
          } else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.urlSafe = `${mime}${res.file}`
          if (!this.isPdf) {
            let el = document.getElementById('imagePrev');
            setTimeout(() => {
              el.style.transform = 'rotate(0deg) scale(1)';
            }, 100);
          }
          this.displayPreview = isPreviewable;
          if(!isPreviewable) {
            this.sfBillingService.downloadFile(res, this.urlSafe);
          }          
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
        this.dataLoader = false;
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      }));
    }
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    if(el){
      el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
    }
  }

  rotateRight() {
    this.canvasRotation++;
  }

  backBtnClicked(){
    this.router.navigate(['/sf-billing/edit-sf-grid']);
  }

  deleteAttachPopup(item) {
    this.deleteAttach = true;
    this.attachToDelete = item;
  }

  deleteAttachment(){
    this.apiCallList.push(this.sfBillingService.deleteAttachmentById(this.attachToDelete?.fileId).subscribe((res) => {
      if(res?.status == 'success'){
        this.deleteAttach = false;
        this.uploadedDoc = null;
        this.messageService.add({ severity: 'success', detail: 'File deleted successfully' });
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
      }
    }, (err) => {
      this.apiFail = true;
    }));
  }

  ngOnDestroy(): void {
    this.apiCallList.forEach((a) => {
      a.unsubscribe();
    })
  }

}
