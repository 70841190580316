import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SfEstimatesComponent } from './sf-estimates/sf-estimates.component';
import { GenerateBillComponent } from './generate-bill/generate-bill.component';
import { SfEditGridComponent } from './sf-edit-grid/sf-edit-grid.component';
import { SfEditCompanyDetailsComponent } from './sf-edit-company-details/sf-edit-company-details.component';
import { BdApprovalGridComponent } from './bd-approval-grid/bd-approval-grid.component';
import { BdApprovalDetailsComponent } from './bd-approval-details/bd-approval-details.component';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { GenerateBillGuard } from 'app/core/auth/generate-bill.service';
import { SfEstimateGuard } from 'app/core/auth/sf-estimate.service';
import { EditSfBillGuard } from 'app/core/auth/edit-sf-bill.service';
import { BdApprovalGuard } from 'app/core/auth/bd-approval.service';
import { SfFinanceGridComponent } from './sf-finance-grid/sf-finance-grid.component';
import { SfFinanceDetailsComponent } from './sf-finance-details/sf-finance-details.component';
import { SfBillTrackingComponent } from './sf-bill-tracking/sf-bill-tracking.component';
import { FinanceApprovalGuard } from 'app/core/auth/finance-approval.guard';
import { BillTrackingGuard } from 'app/core/auth/bill-tracking.guard';

const routes: Routes = [
  {
    path: 'sf-estimates',
    component: SfEstimatesComponent,
     data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, SfEstimateGuard]
  },{
    path: 'generate-bill',
    component: GenerateBillComponent,
     data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, GenerateBillGuard]
  },{
    path: 'edit-sf-grid',
    component: SfEditGridComponent,
    data: {
      authorities: [],
      pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, EditSfBillGuard]
  },{
    path: 'edit-sf-company-details/:id',
    component: SfEditCompanyDetailsComponent,
    data: {
      authorities: [],
      pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, EditSfBillGuard]
  },{
    path: 'bd-approval-grid',
    component: BdApprovalGridComponent,
    data: {
      authorities: [],
      pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, BdApprovalGuard]
  },{
    path: 'bd-approval-details/:id',
    component: BdApprovalDetailsComponent,
    data: {
      authorities: [],
      pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, BdApprovalGuard]
  },
  {
    path: 'sf-finance-grid',
    component: SfFinanceGridComponent,
    data: {
      authorities: [],
      pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, FinanceApprovalGuard]
  },
  {
    path: 'sf-bill-tracking',
    component: SfBillTrackingComponent,
    data: {
      authorities: [],
      pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, BillTrackingGuard]
  },
  {
    path: 'sf-finance-details/:id',
    component: SfFinanceDetailsComponent,
    data: {
      authorities: [],
      pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, FinanceApprovalGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SfBillingRoutingModule { }
