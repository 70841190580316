import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { PagesService } from '../pages.service';
import { SfRealizationScreenDetailsComponent } from '../sf-realization-screen-details/sf-realization-screen-details.component';

@Component({
  selector: 'backoffice-sf-realization-grid',
  templateUrl: './sf-realization-grid.component.html',
  styleUrls: ['./sf-realization-grid.component.scss'],
  providers: [DialogService, MessageService]
})
export class SfRealizationGridComponent implements OnInit {
  @ViewChildren('mainGrid') mainGrid;


  readonly DEFAULT_SELECTED_STATUS_ID = 1;
  readonly fileName = 'SF Realization screen data';
  
  realizationReadScope: boolean = false;
 
  displayProcessingModal: boolean = false;
  displayExtractProcessingModal : boolean = false;
  displayInfoModal: boolean = false;
  processedResult = {
      totalRecords: 0,
      count: {
        processed : 0,
        invalid : 0,
        error : 0,
        success: 0,
        unchanged : 0
      }
    };

    sfRealizationList:any = [];
    ccprimebreadcrumbList = [
      { label: `SF Realization Listing`, routerLink: ['/pages/realization'] },
    ];
    apiFail = false;
    homeiconccprimebreadcrumb = { icon: `pi pi-home` };
    scopeList: any;
    sfRealizationGridForm = this.fb.group({
      globalCalendar: [null as any, []],
      paymentStatusFilter: ['Pending', []],
      otaStatusFilter: [null],
      companyNameSearchValue : [null, []],
      financialYear : [null as any, []],
      paymentModeFilter:[null,[]]
    });
    filterDisplayRow: boolean = true;
    gridLoader: boolean = false;
    userId;
    initData;
    queryEntityId = null;
    
    // date related variables

    minGlobalStartTime = new Date();
    maxGlobalEndTime = new Date();

    globalStartTime = null;
    globalEndTime = null;

    initiatedOnDates = [];
    paymentDates = [];
    bankNameList = [];
    
    @ViewChild('globalCalendar') globalCalendar: any;

    // end of date related variables

    // modal related variables
    dynamicTableDialogEntityNAMERef: DynamicDialogRef = new DynamicDialogRef;
  
    // auto complete company name related variables
    showCross: boolean = false;
    showEntityLoader: boolean = false;
    emptyMsgVal: string = 'No Company Found';
    emptyMsgFlag: boolean = false;
    companyNameList = [];

    // fin year dropdown related variables
    financialYearList: any = [];

    // loader for only primeng table on filter of 'payment status'
    loadingStatus = false;
    
    paymentDataDialog: DynamicDialogRef = new DynamicDialogRef;
    
  
  constructor(private _location: Location, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private pagesService: PagesService, private messageService: MessageService, private encyDyc: EncryptDecryptInterceptor, private cd: ChangeDetectorRef, private encryptDecryptInterceptor: EncryptDecryptInterceptor, private dialogService: DialogService) {
    this.minGlobalStartTime.setMonth(this.maxGlobalEndTime.getMonth() - 36);
  }

  ngOnInit() {
    this.userId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));
    // this.scopeList = this.encyDyc.decrypt(localStorage.getItem('scopes'));
    let date = new Date();
    let prevYear = date.getFullYear();
    let month = date.getMonth();
    if(month >= 1){
      prevYear += 1;
    }
    this.scopeList = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    let scope: any = this.scopeList.split(",");
    if(scope.indexOf('SF_REALIZATION_CREATE') == -1){
      this.realizationReadScope = true;
    } else {
      this.realizationReadScope = false;
    }
    
    let startYear = prevYear + 1;
    for (let i = 0; i < 3; i++) {
      startYear = startYear - 1;
      prevYear = prevYear - 1;
      this.financialYearList.push(prevYear + '-' + startYear);
    }
    this.getSFRealizationList();
  }

  homeBreadcrumbClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}/#/nexera/dashboard/home`;
    }
  }


  // method to toggle filter icon, and reset table on filter clear
  toggleFilter(table: Table) {
    this.filterDisplayRow = !this.filterDisplayRow;
    this.cd.detectChanges();
    if (this.filterDisplayRow === false) {
      table?.reset();

      if (this.mainGrid?.first?.filters) { // reset all filters
        Object.values(this.mainGrid.first.filters).map(item => {
          (<any>item).value = null;
          return item;
        });
      }
      
      this.sfRealizationGridForm.patchValue({paymentStatusFilter: null});
      this.sfRealizationGridForm.patchValue({otaStatusFilter: null});
      this.sfRealizationGridForm.patchValue({globalCalendar: null});
      this.sfRealizationGridForm.patchValue({companyNameSearchValue: null});
      this.sfRealizationGridForm.patchValue({financialYear: null});
      this.queryEntityId = null; 
      this.globalStartTime = null;
      this.globalEndTime = null;
      
      this.getSFRealizationList();
      this.getMultiBankNamesAndInitiatedDates();
    }
  }

  onClearGlobalDate() {
    if (this.globalStartTime && this.globalEndTime) {
      this.getSFRealizationList();
    }
    this.globalEndTime = undefined;
    this.globalStartTime = undefined;
    this.sfRealizationGridForm.patchValue({ globalCalendar: null });
  }

    exportExcel(isDownload ? : boolean) {
      // export data, if financial year selected
      if(isDownload && this.sfRealizationGridForm.get("financialYear").value != null) {
        this.displayExtractProcessingModal = true;
        let yearRange = this.formatYear();
      
        this.pagesService.sfRealizationExport({ 'financialYear': yearRange}, this.fileName).subscribe({
            next : (res) => {
              if(!res) {
                this.messageService.add({severity: 'info', summary: 'Data not received'});
              } else {
                this.messageService.add({severity: 'success', summary: 'Data exported successfully'});
              }
              this.displayExtractProcessingModal = false;
              setTimeout(() => {
                this.messageService.clear();
              }, 4000);
            },
            error : () => {
              this.messageService.add({ severity: 'error', summary: 'Something went wrong! Please try again' });
              this.displayExtractProcessingModal = false;
              setTimeout(() => {
                this.messageService.clear();
              }, 4000);
            }
          });
        return;
      }

      // else export data showmn on screen
      let excelIds = [];
      // get oppr Id from filtered data on screen
      excelIds = this.mainGrid?.first?.filteredValue?.map((data) => {
        return data.paymentId;
      });
      if(excelIds?.length === 0 && this.mainGrid?.first?.filters) {
        // excelIds can be empty -> if 
        // - no filters prevail on screen OR 
        // - filters exist, but no data matches them
        let filtersAdded = Object.values(this.mainGrid.first.filters).find((item: any) => item.value != null ? (Array.isArray(item.value) ? item.value.length > 0 : !!(item?.value)) : false );
        
        if(!filtersAdded) { // no filters were added, hence export whole data
          excelIds = this.mainGrid?.first?.value?.map((data) => {
            return data.paymentId;
          });
        }
      }
      if(!excelIds && this.mainGrid?.first?.value) { // in case filteredValue is null, and filters are applied
        excelIds = this.mainGrid.first.value.map((data) => {
          return data?.paymentId;
        });
      }
      this.displayExtractProcessingModal = true;
      this.pagesService.sfRealizationExport({ 'paymentIdList': excelIds}, this.fileName).subscribe({
          next : () => {
            this.messageService.add({severity: 'success', summary: 'Data exported successfully'});
            this.displayExtractProcessingModal = false;
            setTimeout(() => {
              this.messageService.clear();
            }, 4000);
          },
          error : () => {
            this.messageService.add({ severity: 'error', summary: 'Something went wrong! Please try again' });
            this.displayExtractProcessingModal = false;
            setTimeout(() => {
              this.messageService.clear();
            }, 4000);
          }
        });
  }
    getSFRealizationList(startDate? : string, endDate?: string, finYear?: string) {
      this.gridLoader = true;
      this.initiatedOnDates = [];
      this.paymentDates = [];
      this.bankNameList = [];
      
      this.pagesService.getSFRealizationGridList(startDate, endDate, finYear).subscribe({
        next : (opportunities : Array<any>) => {
          this.setPaymentList(opportunities);
          this.gridLoader = false;
          this.apiFail = false;
        },
        error: (err) => {
          this.gridLoader = false;
          this.apiFail = true;
        }
      })
    }

    filterGlobalTableByDate(){
      const startTime = this.sfRealizationGridForm.value.globalCalendar[0]?.split("/").reverse().join("-");
      const endTime = this.sfRealizationGridForm.value.globalCalendar[1]?.split("/").reverse().join("-");
      if(this.globalEndTime) {
        this.globalStartTime = startTime;
        this.globalEndTime = undefined;
      } else {
        this.globalStartTime = startTime;
        this.globalEndTime = endTime;  
      }
      if(this.globalStartTime && this.globalEndTime) {
        this.globalCalendar.overlayVisible = false;
        this.sfRealizationGridForm.patchValue({companyNameSearchValue: null});
        this.queryEntityId = null;
        this.showCross = false;
        this.showEntityLoader = false;
        this.getSFRealizationList(this.globalStartTime, this.globalEndTime);
      }
    }

    onBackClick() {
      this._location.back();
    }


    async fileUploaded(event) {
      let file: File = event.target.files[0];
      this.displayProcessingModal = true;
      
      let excelBase64 = await this.pagesService.convertToBase64(file);
      excelBase64 = (<string>excelBase64).split('base64,')[1];

      let userId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));
      
      this.pagesService.importRealizationExcel({excelBase64, userId}).subscribe({
        next : (res) => {
          this.displayProcessingModal = false;
          if(res) {
            this.processedResult = {
              totalRecords: res.totalCase,
              count: {
                processed : res.successCase,
                invalid : res.invalidCase,
                error : res.errorCase,
                success : res.successCase,
                unchanged : res.noChange
              }
            };
            this.displayInfoModal = true;
            if(res.excelBase64) {
              this.pagesService.downloadExcel(res.excelBase64, 'SF realization data');  // TODO - finalize file Name
            }
          } else {
            this.messageService.add({severity: 'info', summary: 'Data not received'});
            setTimeout(() => {
              this.messageService.clear();
            }, 4000);
          }
          event.target.value = '';
        }, 
        error : () => {
          this.displayProcessingModal = false;
          this.apiFail = true;
          event.target.value = '';
        }
      });
    }

    getMultiBankNamesAndInitiatedDates() {
      let initiatedonDateSet = new Set();
      this.initiatedOnDates = [];

      let paymentDateSet = new Set();
      this.paymentDates = [];

      let bankNameSet = new Set();
      this.bankNameList = [];
      
      if(this.sfRealizationList) {
        this.sfRealizationList.forEach(opportunity => {
          const initiatedOn = opportunity?.initiatedOn;
          initiatedonDateSet.add(initiatedOn);

          const paymentDate = opportunity?.paymentDate;
          paymentDateSet.add(paymentDate);

          bankNameSet.add(opportunity?.bank);
        });

        this.initiatedOnDates =  Array.from(initiatedonDateSet).map(date => {
          return {label : date || '', value: date || null};
        });

        this.paymentDates = Array.from(paymentDateSet).map(date => {
          return {label : date || '', value: date || null};
        });

        this.bankNameList = Array.from(bankNameSet).map(bankName => {
          return {label : bankName || '', value: bankName || null};
        });
      }
    }

    // company name search related region

    clearCompanySearch(table : Table){
      this.showCross = false;
      this.onClearSearch();
      this.sfRealizationGridForm.patchValue({companyNameSearchValue: null});
      this.queryEntityId = null;
    }

    onClearSearch(): void {
      this.getSFRealizationList();
    }

    onChangeCompanySelect(query, queryEntityId?): void {
      if(query.value?.entityId || queryEntityId) {
        this.queryEntityId = query.value?.entityId || queryEntityId;
        this.gridLoader = true;
        this.pagesService.getCompanyByEntityId(this.queryEntityId).subscribe({
          next : (opportunities : Array<any>) => {
            this.setPaymentList(opportunities);
            this.gridLoader = false;
            this.apiFail = false;
            this.sfRealizationGridForm.patchValue({globalCalendar: null});
            this.globalStartTime = null;
            this.globalEndTime = null;
          },
          error : (err) => {
            this.gridLoader = false;
            this.apiFail = true;
          }
        })
      }
      this.showCross = true;
    }

    loadCompanyList(event: any): any {
        if(event.query.length >= 3){
          this.showEntityLoader = true;
          this.pagesService.getCompanySearchResults(event.query).subscribe(
            (results: any) => {
              const responseData = results;
              this.showEntityLoader = false;
              this.companyNameList = [];
              if (responseData && responseData.length > 0) {
                this.companyNameList = responseData;
              } else {
                this.companyNameList = [];
                this.emptyMsgFlag = true;
              }
            }, (_err) => {
              this.companyNameList = []
              this.showEntityLoader = false;
              this.emptyMsgFlag = true;
            });
        } else {
          this.companyNameList = [];
        }
  }

  // set final grid list data
  setPaymentList(opportunities: Array<any>) {
    if (opportunities) {
      this.sfRealizationList = opportunities
        .map(opportunity => {
          
          return { 
            ...opportunity ,
            initiatedOn : opportunity?.initiatedOn ? this.updateDateFormat(opportunity?.initiatedOn) : null,
            finStatus: opportunity.finStatus || 'Pending',
            otaStatus: opportunity.otaStatus || '',
            clearingDate : opportunity.clearingDate ? this.updateDateFormat(opportunity.clearingDate) : null,
            paymentDate : opportunity.paymentDate ?  this.updateDateFormat(opportunity.paymentDate) : null
          }
        }
        );
        if(this.sfRealizationGridForm.get('paymentStatusFilter').value !== null)  {  // 2 - default table filter on load of screen
          this.mainGrid.first.filter(this.sfRealizationGridForm.get('paymentStatusFilter').value, 'finStatus', 'equals');
        }
        if(this.sfRealizationGridForm.get('otaStatusFilter').value !== null && this.sfRealizationGridForm.get('otaStatusFilter').value!='')  {  // 3 - default table filter on load of screen
          this.mainGrid.first.filter(this.sfRealizationGridForm.get('otaStatusFilter').value, 'otaStatus', 'equals');
        }
        if(!this.initData)
          this.initData = this.sfRealizationList;
        this.getMultiBankNamesAndInitiatedDates();
      }
    }

    financialYearSelected() {
      let yearRange = this.formatYear();
      this.showCross = false;
      this.getSFRealizationList(null, null, yearRange);
      this.sfRealizationGridForm.patchValue({globalCalendar: null});
      this.sfRealizationGridForm.patchValue({companyNameSearchValue: null}); 
      this.queryEntityId = null; 
      this.globalStartTime = null;
      this.globalEndTime = null;
    }
  

  showDialogPayment(opportunityData: any) {
    this.paymentDataDialog = this.dialogService.open(SfRealizationScreenDetailsComponent, {
      contentStyle: { "max-height": "80vh", "overflow": "auto" },
      closeOnEscape: false,
      closable: true,
      data: opportunityData,
      width: '96%',
      header: opportunityData.companyName
    });

    this.paymentDataDialog.onClose.subscribe((response: any) => {
      if (response != null && response != '') {
        this.messageService.add({severity: 'success', summary: response });
        if(this.globalStartTime && this.globalEndTime)
          this.getSFRealizationList(this.globalStartTime && this.globalEndTime);
        else if(this.queryEntityId)
          this.onChangeCompanySelect(null, this.queryEntityId);
        else  this.getSFRealizationList();

        setTimeout(() => {
          this.messageService.clear();
        }, 4000);
      }
    });
  }

  updateDateFormat(date : string) {
    return moment.utc(date).format('DD-MM-YYYY');
  }

  formatYear() : string {  // 2023-2024 => 23-24
    return this.sfRealizationGridForm.get('financialYear').value.split("-")
    .map((item : string) => item.slice(2)).join("-");
  }
}
