<div>
    <form [formGroup]="ViewMappedUserForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12 p-p-0">

                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-p-0 p-d-flex p-ai-center p-mb-2">
                        <label class="leb empDetails p-mr-3"> Emp ID: <strong> {{empId}} </strong></label>
                        <label class="leb empDetails"> Employee Name: <strong> {{empName}} </strong></label>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 popupBodyScrollContainer">
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0">

                                <h6> User Info</h6>

                            </div>
                        </div>
                        <div class="p-formgrid p-grid p-d-flex p-ai-start">                           
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Designation
                                    </label>
                                    <p-dropdown id="designationid" name="bd_designation"
                                            placeholder="Select a Designation" [options]="designationList"
                                            [autoDisplayFirst]="false" [filter]="true" [disabled]="true"
                                            formControlName="bd_designation" optionLabel="bd_designation">
                                        </p-dropdown>
                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Mobile No.
                                    </label>
                                    <input type="text" id="mobileNo_id" name="mobileNo_name" formControlName="mobileNo"
                                        pInputText placeholder="" class="p-inputtext  " [attr.disabled]="true" />
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Email
                                    </label>
                                    <input type="text" id="email" name="email" formControlName="email" pInputText
                                        placeholder="" class="p-inputtext  " [attr.disabled]="true" />

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left p-pl-0">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Shadow Portfolio
                                    </label>
                                    <p-multiSelect display="chip"
                                        [options]="shadowPortfolioEmployeeNameAndDesignationList"
                                        optionLabel = "shadowPortfolioEmployeeNameAndDesignation"
                                        formControlName = "name"
                                        [disabled]="true">
                                    </p-multiSelect>
                                    <!-- <p-dropdown id="selectid" name="shadowPortfolioEmployeeNameAndDesignation"
                                            placeholder="Select a Shadow Portfolio" [options]="shadowPortoflioList"
                                            [filter]="true" [autoDisplayFirst]="false" [disabled]="true"
                                            formControlName="shadowPortfolioEmployeeNameAndDesignation"
                                            optionLabel="shadowPortfolioEmployeeNameAndDesignation">
                                        </p-dropdown> -->
                                </div>

                            </div> 
                            <div *ngIf="viewSign" class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined right p-d-flex p-ai-end signatureCard">
                                    <div class="p-fluid  p-field p-w-100">   
                                        <label>Signature</label>                                     
                                            <p-card class="p-element p-component" id="p_card">
                                                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-jc-between p-flex-wrap">
                                                            <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center">
                                                                <img [src]="urlSafe" class="p-mr-2" width="28px" height="28px" alt="img"/>
                                                                <a (click)="showSignaturePreview()">
                                                                    <div class="p-col-12 p-d-flex p-flex-column p-flex-wrap p-ai-start p-pl-0"
                                                                        style="text-align:left;">
                                                                        <div class="limit-text-length attachmentName cursor-pointer signatureLabel p-ml-0">
                                                                            {{fileName}}
                                                                        </div>                                                                
                                                                    </div>
                                                                </a>
                                                            </div>
                                                    </div>
                                                </p-card>
                                    </div>
                                </div>                          
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0">

                                <h6> Superior Info</h6>

                            </div>
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left p-pl-0">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Immediate Supervisor
                                    </label>
                                    <p-dropdown id="immediateSupervisorid" name="immediateSupervisor"
                                            placeholder="Select a Immediate Supervisor"
                                            [options]="immediateSupervisorList" [filter]="true"
                                            [autoDisplayFirst]="false" [disabled]="true"
                                            formControlName="immediateSupervisor">
                                        </p-dropdown>
                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Immediate Supervisor BD
                                    </label>
                                    <input type="text" id="imeediateSupervisorLevelName_id"
                                        name="imeediateSupervisorLevelName_name"
                                        formControlName="imeediateSupervisorLevelName" pInputText placeholder=""
                                        class="p-inputtext  " [attr.disabled]="true" />

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Director Name
                                    </label>
                                    <input type="text" id="directorName_id" name="directorName_name"
                                        formControlName="directorName" pInputText placeholder="" class="p-inputtext  "
                                        [attr.disabled]="true" />

                                </div>

                            </div>
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left p-pl-0">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        HR Designation
                                    </label>
                                    <input type="text" id="hrDesignation_id" name="hrDesignation_name"
                                        formControlName="hrDesignation" pInputText placeholder="" class="p-inputtext  "
                                        [attr.disabled]="true" />

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Ops Spoc
                                    </label>
                                    <p-multiSelect display="chip"
                                        [options]="OpsSpocList"
                                        optionLabel = "employeeName"
                                        formControlName = "opsList"
                                        [disabled]="true">
                                    </p-multiSelect>
                                    <!-- <p-dropdown id="opSpocid" name="opSpoc" placeholder="Select a Ops Spoc"
                                            [options]="OpsSpocList" [autoDisplayFirst]="false"  optionLabel="employeeName" [filter]="true"
                                            [disabled]="true" formControlName="opSpoc">
                                        </p-dropdown> -->
                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Team
                                    </label>
                                    <p-dropdown id="teamNameid" name="teamName" placeholder="Select a Team"
                                            [options]="teamNameList" [autoDisplayFirst]="false" [disabled]="true"
                                            formControlName="teamName" optionLabel="value">
                                        </p-dropdown>
                                </div>

                            </div>
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left p-pl-0">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                       PA Approver
                                    </label>
                                    <p-dropdown id="select1id" name="paEmployeeNameAndDesignation"
                                            placeholder="Select a Approver" [options]="paApproverList" [filter]="true"
                                            [autoDisplayFirst]="false" [disabled]="true"
                                            formControlName="paEmployeeNameAndDesignation"
                                            optionLabel="paEmployeeNameAndDesignation">
                                        </p-dropdown>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-9 p-lg-9 p-xl-9 p-offset-undefined p-left">
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0 popupFooterBtnContainer">
                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-end p-ai-center p-offset-undefined p-left popupFooterBtnContent p-pr-4 p-pt-2 p-mb-3">
                            <div class="btnwrap">
                                <button pButton class="p-button-brand2 p-ml-0" type="button"  (click)="onCcprimebutton(ViewMappedUserForm);" label="Cancel">
                                </button>
                            </div>
                            <div class="btnwrap">
                                <button pButton class="p-button-primary p-button p-mr-0" type="button"
                                [disabled]="!updateScope"
                                    (click)="onCcprimebutton1(ViewMappedUserForm);" label="Edit">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <br>
</div>
<p-dialog header="Preview" [(visible)]="displayPreview" class="signPreview" [modal]="true" [style]="{width: '60vw', height: '50vh'}"
[draggable]="false" [resizable]="false">
    <div class="p-col-12 p-d-flex p-jc-center">
        <img alt="image" [src]="urlSafe" height="100%" style="max-height: 54vh;" />
    </div>
</p-dialog>