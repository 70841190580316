<div class="layout-content p-pt-5 p-mt-2">
    <p-messages class="custom-message"></p-messages>
    <div class="p-formgrid p-grid breadcrumb p-m-0">
        <div class=" p-col-12">
            <div class="ui-fluid p-formgrid p-grid p-ai-center p-m-0">
                <div class="p-col-10 p-lg-9 p-xl-8  p-text-left">
                    <div class=" p-fluid   p-field   p-mb-0 ">
                        <p-breadcrumb id="breadcrumbList_id" name="applicationBreadcrumb_name" class="" (onItemClick)="homeClicked($event)"
                            [model]="breadcrumbList" [home]="homeicon">
                        </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form>
        <div class="p-formgrid p-grid formFieldContainer">
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0">
                    <div class="p-col-12">
                        <h1 class="p-mb-3 p-medium display-2">QC Control Form</h1>
                    </div>
                    <div class="p-col-12">
                        <h1 class="p-medium display-3">Mandate Specific</h1>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-8 p-d-flex">
                            <label for="company name">Company Name: </label>
                            <h1 class="p-medium display-4" id="company name">{{dataList[0]?.companyName}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Date: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="date" value="Yes" [formControl]="dataObj['Date']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="date" value="No" [formControl]="dataObj['Date']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Date']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Date']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Ref No: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="refNo" value="Yes" [formControl]="dataObj['Ref No']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="refNo" value="No" [formControl]="dataObj['Ref No']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Ref No']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Ref No']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Name of Company: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="NameComp" value="Yes"
                                        [formControl]="dataObj['Name of Company']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="NameComp" value="No"
                                        [formControl]="dataObj['Name of Company']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Name of Company']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Name of Company']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Product / Instrument: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="prodInst" value="Yes"
                                        [formControl]="dataObj['Product / Instrument']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="prodInst" value="No"
                                        [formControl]="dataObj['Product / Instrument']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Product / Instrument']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Product / Instrument']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Quantum: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="quantum" value="Yes" [formControl]="dataObj['Quantum']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="quantum" value="No" [formControl]="dataObj['Quantum']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Quantum']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Quantum']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">IRF Amount: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="irfAmount" value="Yes"
                                        [formControl]="dataObj['IRF Amount']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="irfAmount" value="No"
                                        [formControl]="dataObj['IRF Amount']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['IRF Amount']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['IRF Amount']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Surveillance Fees: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="survFees" value="Yes"
                                        [formControl]="dataObj['Surveillance Fees']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="survFees" value="No"
                                        [formControl]="dataObj['Surveillance Fees']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Surveillance Fees']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Surveillance Fees']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Pre-Application Form: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="preAppForm" value="Yes"
                                        [formControl]="dataObj['Pre-Application Form']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="preAppForm" value="No"
                                        [formControl]="dataObj['Pre-Application Form']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Pre-Application Form']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Pre-Application Form']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Faxed Mandate: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="faxMandate" value="Yes"
                                        [formControl]="dataObj['Faxed Mandate']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="faxMandate" value="No"
                                        [formControl]="dataObj['Faxed Mandate']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Faxed Mandate']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Faxed Mandate']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Original Mandate: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="originalMandate" value="Yes"
                                        [formControl]="dataObj['Original Mandate']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="originalMandate" value="No"
                                        [formControl]="dataObj['Original Mandate']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Original Mandate']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Original Mandate']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Retainer Name: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="retainerName" value="Yes"
                                        [formControl]="dataObj['Retainer Name']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="retainerName" value="No"
                                        [formControl]="dataObj['Retainer Name']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Retainer Name']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Retainer Name']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Manager Name: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="managerName" value="Yes"
                                        [formControl]="dataObj['Manager Name']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="managerName" value="No"
                                        [formControl]="dataObj['Manager Name']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Manager Name']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Manager Name']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Withdrawal Case: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="withdrawalCase" value="Yes"
                                        [formControl]="dataObj['Withdrawal Clause']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="withdrawalCase" value="No"
                                        [formControl]="dataObj['Withdrawal Clause']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Withdrawal Clause']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Withdrawal Clause']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Signature: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="signature" value="Yes"
                                        [formControl]="dataObj['Signatures']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="signature" value="No"
                                        [formControl]="dataObj['Signatures']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Signatures']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Signatures']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Attachment - Fee Letter / NDA: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="attachmentFL" value="Yes"
                                        [formControl]="dataObj['Attachments - Fee Letter / NDA']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="attachmentFL" value="No"
                                        [formControl]="dataObj['Attachments - Fee Letter / NDA']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Attachments - Fee Letter / NDA']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Attachments - Fee Letter / NDA']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Travel / OPE: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="travelOpe" value="Yes"
                                        [formControl]="dataObj['Travel / OPE']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="travelOpe" value="No"
                                        [formControl]="dataObj['Travel / OPE']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Travel / OPE']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Travel / OPE']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Changes On Agreement: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="changesAgreement" value="Yes"
                                        [formControl]="dataObj['Changes On Agreement']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="changesAgreement" value="No"
                                        [formControl]="dataObj['Changes On Agreement']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Changes On Agreement']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Changes On Agreement']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Cost Center: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="costCenter" value="Yes"
                                        [formControl]="dataObj['Cost Center']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="costCenter" value="No"
                                        [formControl]="dataObj['Cost Center']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Cost Center']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Cost Center']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Current address is same as CRM address: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="currentAddCrm" value="Yes"
                                        [formControl]="dataObj['Current address is same as CRM address']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="currentAddCrm" value="No"
                                        [formControl]="dataObj['Current address is same as CRM address']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Current address is same as CRM address']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Current address is same as CRM address']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Registered Mobile number is correctly entered: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="regMobCorrect" value="Yes"
                                        [formControl]="dataObj['Registered Mobile number is correctly entered']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="regMobCorrect" value="No"
                                        [formControl]="dataObj['Registered Mobile number is correctly entered']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Registered Mobile number is correctly entered']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Registered Mobile number is correctly entered']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Registered email id is correctly entered: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="regEmailCorrect" value="Yes"
                                        [formControl]="dataObj['Registered email id is correctly entered']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="regEmailCorrect" value="No"
                                        [formControl]="dataObj['Registered email id is correctly entered']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Registered email id is correctly entered']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Registered email id is correctly entered']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Authorized signature of the client present in consent form: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="authSignPresent" value="Yes"
                                        [formControl]="dataObj['Authorized signature of the client present in consent form']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="authSignPresent" value="No"
                                        [formControl]="dataObj['Authorized signature of the client present in consent form']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Authorized signature of the client present in consent form']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Authorized signature of the client present in consent form']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Is Credit Risk Authorized: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="creditRiskAuth" value="Yes"
                                        [formControl]="dataObj['Is Credit Risk Authorized']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="creditRiskAuth" value="No"
                                        [formControl]="dataObj['Is Credit Risk Authorized']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Is Credit Risk Authorized']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Is Credit Risk Authorized']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Team Lead: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="teamLead" value="Yes"
                                        [formControl]="dataObj['TeamLead']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="teamLead" value="No"
                                        [formControl]="dataObj['TeamLead']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['TeamLead']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['TeamLead']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">RPT Status: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="rptStatus" value="Yes"
                                        [formControl]="dataObj['RPT Status']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="rptStatus" value="No"
                                        [formControl]="dataObj['RPT Status']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['RPT Status']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['RPT Status']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Tax Approval Attachment: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="taxApprovalAttach" value="Yes"
                                        [formControl]="dataObj['Tax Approval Attachment']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="taxApprovalAttach" value="No"
                                        [formControl]="dataObj['Tax Approval Attachment']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Tax Approval Attachment']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Tax Approval Attachment']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Compliance Approval Attachment: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="complianceApprovalAttach" value="Yes"
                                        [formControl]="dataObj['Compliance Approval Attachment']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="complianceApprovalAttach" value="No"
                                        [formControl]="dataObj['Compliance Approval Attachment']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Compliance Approval Attachment']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Compliance Approval Attachment']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">PAN Number: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="panNumber" value="Yes"
                                        [formControl]="dataObj['PAN Number']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="panNumber" value="No"
                                        [formControl]="dataObj['PAN Number']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['PAN Number']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['PAN Number']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Approval for Changes in Agreement BD (D/SD): </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="approvalChangeAgree" value="Yes"
                                        [formControl]="dataObj['Approval for changes in Agreement BD (D/SD)']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="approvalChangeAgree" value="No"
                                        [formControl]="dataObj['Approval for changes in Agreement BD (D/SD)']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Approval for changes in Agreement BD (D/SD)']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Approval for changes in Agreement BD (D/SD)']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">GST Number: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="gstNumber" value="Yes"
                                        [formControl]="dataObj['GST Number']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="gstNumber" value="No"
                                        [formControl]="dataObj['GST Number']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['GST Number']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['GST Number']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">GST Category: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="gstCategory" value="Yes"
                                        [formControl]="dataObj['GST Category']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="gstCategory" value="No"
                                        [formControl]="dataObj['GST Category']?.isAccepted" disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['GST Category']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['GST Category']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Client State Code: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="clientStateCode" value="Yes"
                                        [formControl]="dataObj['Client State Code']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="clientStateCode" value="No"
                                        [formControl]="dataObj['Client State Code']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Client State Code']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Client State Code']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Email: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="email" value="Yes" [formControl]="dataObj['Email']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="email" value="No" [formControl]="dataObj['Email']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Email']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Email']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Normal/Third party validation: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="normalThirdVal" value="Yes"
                                        [formControl]="dataObj['Normal/Third party validation']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="normalThirdVal" value="No"
                                        [formControl]="dataObj['Normal/Third party validation']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Normal/Third party validation']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Normal/Third party validation']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-6 p-d-flex p-ai-center">
                            <label class="p-mb-0">Nexera Case Id: </label>
                            <div class="p-d-flex">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="nexCaseId" value="Yes"
                                        [formControl]="dataObj['Nexera Case Id']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Accept</label>
                                </div>
                            </div>
                            <div class="p-d-flex p-ml-2">
                                <div class="radio_container  field-radiobutton">
                                    <p-radioButton name="nexCaseId" value="No"
                                        [formControl]="dataObj['Nexera Case Id']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                    <label for="">Reject</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>QC Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Nexera Case Id']?.qcComments}}</h1>
                        </div>
                        <div class="p-md-3 p-d-flex">
                            <label>BD Comments: </label>
                            <h1 class="p-medium display-4">{{dataObj['Nexera Case Id']?.bdComments}}</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">GST TDS Account Number: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="gstTdsNum" value="Yes" [formControl]="dataObj['GST TDS Account Number']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="gstTdsNum" value="No" [formControl]="dataObj['GST TDS Account Number']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['GST TDS Account Number']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['GST TDS Account Number']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">Master Service: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="masterService" value="Yes" [formControl]="dataObj['Master Service']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="masterService" value="No" [formControl]="dataObj['Master Service']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Master Service']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Master Service']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">Sub Service: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="subService" value="Yes" [formControl]="dataObj['Sub Service']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="subService" value="No" [formControl]="dataObj['Sub Service']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Sub Service']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Sub Service']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">Ratable Unit: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="ratableUnit" value="Yes" [formControl]="dataObj['Ratable Unit']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="ratableUnit" value="No" [formControl]="dataObj['Ratable Unit']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Ratable Unit']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Ratable Unit']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">Common Scheme Name: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="commonSchemeName" value="Yes" [formControl]="dataObj['Common Scheme Name']?.isAccepted" 
                                        disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="commonSchemeName" value="No" [formControl]="dataObj['Common Scheme Name']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Common Scheme Name']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Common Scheme Name']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">AMC Code: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="amcCode" value="Yes" [formControl]="dataObj['AMC Code']?.isAccepted" 
                                            disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="amcCode" value="No" [formControl]="dataObj['AMC Code']?.isAccepted" 
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['AMC Code']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['AMC Code']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">AMC Name: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="amcName" value="Yes" [formControl]="dataObj['Amc Name']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="amcName" value="No" [formControl]="dataObj['Amc Name']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Amc Name']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Amc Name']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">Mutual FundParent Id: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="mutualFundParent" value="Yes" [formControl]="dataObj['Mutual Fund Parent Id']?.isAccepted" 
                                        disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="mutualFundParent" value="No" [formControl]="dataObj['Mutual Fund Parent Id']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Mutual Fund Parent Id']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Mutual Fund Parent Id']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">Invoice Before Payment: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="ibp" value="Yes" [formControl]="dataObj['Invoice Before Payment']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="ibp" value="No" [formControl]="dataObj['Invoice Before Payment']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Invoice Before Payment']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Invoice Before Payment']?.bdComments}}</h1>
                            </div>
                        </div>
                </p-card>
            </div>
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0">
                    <div class="p-col-12">
                        <h1 class="p-medium display-3">Fee Specific</h1>
                    </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">Gross Payment: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="feeGrossPay" value="Yes" [formControl]="dataObj['Gross Payment']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="feeGrossPay" value="No" [formControl]="dataObj['Gross Payment']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Gross Payment']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Gross Payment']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">Deposit Slips: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="feeDepositSlip" value="Yes" [formControl]="dataObj['Deposit Slips']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="feeDepositSlip" value="No" [formControl]="dataObj['Deposit Slips']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Deposit Slips']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Deposit Slips']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">Cheque Attachment: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="chequeAttachment" value="Yes" [formControl]="dataObj['Cheque Attachments']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="chequeAttachment" value="No" [formControl]="dataObj['Cheque Attachments']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Cheque Attachments']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Cheque Attachments']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">Client Classification Defined: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="clientClassDefined" value="Yes" [formControl]="dataObj['Client Classification Defined']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="clientClassDefined" value="No" [formControl]="dataObj['Client Classification Defined']?.isAccepted"
                                        disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Client Classification Defined']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Client Classification Defined']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-6 p-d-flex p-ai-center">
                                <label class="p-mb-0">Standard Fee Validated: </label>
                                <div class="p-d-flex">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="standardFeeVali" value="Yes" [formControl]="dataObj['Standard Fee Validated']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Accept</label>
                                    </div>
                                </div>
                                <div class="p-d-flex p-ml-2">
                                    <div class="radio_container  field-radiobutton">
                                        <p-radioButton name="standardFeeVali" value="No" [formControl]="dataObj['Standard Fee Validated']?.isAccepted"
                                            disabled="true"></p-radioButton>
                                        <label for="">Reject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Standard Fee Validated']?.qcComments}}</h1>
                            </div>
                            <div class="p-md-3 p-d-flex">
                                <label>BD Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Standard Fee Validated']?.bdComments}}</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-p-0">
                            <div class="p-md-12 p-d-flex">
                                <label>Any Other QC Comments: </label>
                                <h1 class="p-medium display-4">{{dataObj['Any Other QC Comments']?.qcComments}} </h1>
                            </div>
                        </div>
                </p-card>
            </div>
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0">
                    <div class="p-col-12 p-d-flex p-p-0">
                        <div class="p-md-4 p-d-flex">
                            <label class="p-mb-0">Checked On: </label>
                            <h1 class="p-medium display-4">{{dataList[0]?.checkedOn | date:'dd-MMM-yyyy'}}</h1>
                        </div>
                        <div class="p-md-4 p-d-flex">
                            <label>Sender: </label>
                            <h1 class="p-medium display-4">{{dataList[0]?.empFullname}} </h1>
                        </div>
                        <div class="p-md-4 p-d-flex">
                            <label>Reciever: </label>
                            <h1 class="p-medium display-4">{{dataList[0]?.receiverFullname}}</h1>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </form>
</div>
<div class="p-grid p-ml-0 p-mr-0 p-col-12 p-jc-between p-button-container">
    <div class="p-col-5 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-pl-0 left">
      <div class="btnwrap">
        <button pButton class="p-button-brand2" type="button"
          (click)="onBackClick()" label="Back">
        </button>
      </div>
    </div>
  </div>

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>