import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MappeduserService {
  public change: EventEmitter<any> = new EventEmitter();
  public messageChanged: EventEmitter<any> = new EventEmitter();

  public setdata(value) {

    this.change.emit(value);
  }
  public setdatamessage(datamessage:string,msgSeverity:string) {
    let data = {datamessage, msgSeverity}

    this.messageChanged.emit(data);
  }

  constructor() { }
}
