import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { QcListingComponent } from './qc-listing/qc-listing.component';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { QcScopeGuard } from 'app/core/auth/qc-scope.guard';
import { QcAdditionaldocListingComponent } from './qc-additionaldoc-listing/qc-additionaldoc-listing.component';
import { AttachmentPreviewListingComponent } from './attachment-preview-listing/attachment-preview-listing.component';

const routes: Routes = [
    {
        path: 'listing',
        component: QcListingComponent,
        data: {
            authorities: [],
            pageTitle: 'NexEra 2.0'
        },
        canActivate: [UserRouteAccessService, QcScopeGuard]
    },
    {
        path: 'listing/updated-doc',
        component: QcAdditionaldocListingComponent,
        data: {
            authorities: [],
            pageTitle: 'NexEra 2.0'
        },
        canActivate: [UserRouteAccessService, QcScopeGuard]
    },
    {
        path: 'listing/attachment-list-preview',
        component: AttachmentPreviewListingComponent,
        data: {
            authorities: [],
            pageTitle: 'NexEra 2.0'
        }
        ,
        canActivate: [UserRouteAccessService]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class QualityCheckRoutingModule {}