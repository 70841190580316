import { Component, OnInit } from '@angular/core';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { PagesService } from 'app/pages/pages.service';
@Component({
  selector: 'backoffice-portfolio-details',
  templateUrl: './portfolio-details.component.html',
  styleUrls: ['./portfolio-details.component.scss']
})
export class PortfolioDetailsComponent implements OnInit {

  portfolio_details: any;
  agreement_details: any;
  portfolioDetailsList: any[];
  apiFail: boolean =false;

  applicationBreadcrumbList = [];

  fetchedURLAgreementId;
  fetchedURLCompanyCode;


  homeiconccprimebreadcrumb = { icon: `pi pi-home`, url: `${DOMAIN_ROUTE}#/nexera/dashboard/viewportfoliodetails` };
  
  portFolioDetailsForm = this.fb.group({

  });


  constructor(private pagesService: PagesService,
    private router: Router,private fb: FormBuilder, protected activatedRoute: ActivatedRoute, private route: ActivatedRoute) 
  {
    
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.fetchedURLAgreementId = params.get('agreementId');
      this.fetchedURLCompanyCode = params.get('companyCode');
    });
    
    this.applicationBreadcrumbList = [
      { label: `Archival Data Listing`, routerLink: ['/agreement-archive/byclient']},
      { label: `Agreement Details`, routerLink: ['/agreement-archive/agreementdetails',this.fetchedURLAgreementId] },
      { label: `Portfolio Details`, routerLink: ['/agreement-archive/viewportfoliodetails',this.fetchedURLAgreementId,this.fetchedURLCompanyCode]},
    ];

    this.getAgreementDetail();
    this.getPortfolioManagerList();
  }
  getPortfolioManagerList(): void {
      this.pagesService.getPortfolioManagerList(this.fetchedURLCompanyCode,this.fetchedURLAgreementId).subscribe(results => {
        this.portfolio_details =results;
          this.apiFail = false;
        }, (_error) => {
          this.apiFail = true;
        });
    }


  homeBreadcrumbClicked(event): void {
    if(event.item?.icon == 'pi pi-home'){
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  onBackClick(): void {
    this.router.navigate(['/agreement-archive/agreementdetails',this.fetchedURLAgreementId])
  }

  getAgreementDetail(): void {
    this.pagesService.getAgreementDetail(this.fetchedURLAgreementId).subscribe(results => {
      this.agreement_details = results;
      console.log("AgreementDetails...", this.agreement_details)
      this.apiFail = false;
    }, (_error) => {
      this.apiFail = true;
    });
  }
}
