import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReportingScreenDetailsComponent } from '../ReportingScreenDetails/ReportingScreenDetails.component';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { ReportingScreenComponent } from '../reporting-screen/reporting-screen.component';

@Component({
  selector: 'jhi-reportingscreengrid',
  templateUrl: './ReportingScreenGrid.component.html',
  styleUrls: ['./ReportingScreenGrid.component.scss'],
  providers: [DialogService, MessageService]
})
export class ReportingScreenGridComponent implements OnInit, AfterViewInit {
  @ViewChildren('businessWonDataGridid') businessWonDataGridid;
  excelIds: any[] = []
  ReportingScreenGridForm = this.fb.group({
    oppIdFilter: [null, []],
    entityNameFilter: [null, []],
    nexeraUserFilter: [null, []],
    bdManagerFilter: [null, []],
    opportinutyFilter: [null, []],
    bdSpocFilter: [null, []],
    dateFilter: [null, []],
    ageingFilter: [null, []],
    productFilter: [null, []],
    EntityDetailstabview: [0],
    gridCalender: [null as any, []],
    businessWonDataGrid: [null as any, []],
    table: [null as any, []]
  });
  @ViewChild('calender') calender;
  loadingStatus = false; 
  rangeDate: any;
  filterDisplay: boolean = true;
  filterDisplayRow: boolean = true;
  pageloadvalues: any;
  ccprimebreadcrumbList = [
    { label: `Reporting Screen`, routerLink: ['/pages/reportingscreengrid'] },
  ];
  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  gridDataList: any[] = [];
  businessWonDataGridList: any[] = [];
  businessWonDataGridcols: any[] = [{ field: "entityName", header: "EntityNAME" },
  { field: "nexeraUser", header: "NEXERAUSER" },
  { field: "bdManager", header: "BDMANAGER" },
  { field: "bdSpoc", header: "BDSPOC" },
  { field: "opportunityStatus", header: "OPPORTUNITYSTATUS" },
  { field: "product", header: "PRODUCT" },
  ];
  tableList: any[] = [];
  tablecols: any[] = [{ field: "entityname", header: "ENTITYNAME" },
  { field: "nexeraUser", header: "NEXERA USER" },
  { field: "bdManager", header: "BD MANAGER" },
  { field: "bdSpoc", header: "BD SPOC" },
  { field: "opportunityStatus", header: "OPPORTUNITY STATUS" },
  { field: "product", header: "PRODUCT" },
  ];
  dynamicAggridDialogEntityNAMERef: DynamicDialogRef = new DynamicDialogRef;
  dynamicAggridDialogNEXERAUSERRef: DynamicDialogRef = new DynamicDialogRef;
  dynamicAggridDialogBDMANAGERRef: DynamicDialogRef = new DynamicDialogRef;
  dynamicAggridDialogBDSPOCRef: DynamicDialogRef = new DynamicDialogRef;
  dynamicAggridDialogOPPORTUNITYSTATUSRef: DynamicDialogRef = new DynamicDialogRef;
  dynamicAggridDialogPRODUCTRef: DynamicDialogRef = new DynamicDialogRef;
  dynamicAggridDialogENTITYNAMERef: DynamicDialogRef = new DynamicDialogRef;
  dynamicTableDialogEntityNAMERef: DynamicDialogRef = new DynamicDialogRef;
  dynamicTableDialogNEXERAUSERRef: DynamicDialogRef = new DynamicDialogRef;
  dynamicTableDialogBDMANAGERRef: DynamicDialogRef = new DynamicDialogRef;
  dynamicTableDialogBDSPOCRef: DynamicDialogRef = new DynamicDialogRef;
  dynamicTableDialogOPPORTUNITYSTATUSRef: DynamicDialogRef = new DynamicDialogRef;
  dynamicTableDialogPRODUCTRef: DynamicDialogRef = new DynamicDialogRef;
  dynamicTableDialogENTITYNAMERef: DynamicDialogRef = new DynamicDialogRef;
  opportunityIdList: any[] = [];
  entityNameList: any[] = [];
  nameList: any[] = [];
  oppIdList: any[] = [];
  productList: any[] = [];
  productList1: any[] = [];
  nexeraUserList: any[] = [];
  nexeraUserList1: any[] = [];
  uniqbdManagerList: any[] = [];
  bdManagerList: any[] = [];
  bdSpocList: any[] = [];
  opportunityList: any[] = [];
  opportunityStatusList: any[] = [];
  uniqbdSpocList: any[] = [];
  dateList: any[] = [];
  uniqDateList: any[] = [];
  ageingList: any[] = [];
  uniqAgeingList: any[] = [];
  maxDate: Date;
  minDate: Date;
  startDate: any;
  endDate: any;
  isFirstDate = true;
  excelData = {};
  businessWonCount: any = 0;
  tempGridData: any;
  dateTime = new Date();
  userRole: any;
  scopeList: any;
  dataLoader: boolean = false;
  pageLoaded: boolean = false;
  selectedOpportunityStatus: any = [];
  dataFiltered: boolean = false;
  gridLoader: boolean = true;
  firstLoad: boolean = false;
  apiFail: boolean = false;
  disableFilter: boolean = false;
  employeeName: any;
  departmentName: any;
  @ViewChild('oppStatusMultiSelect') oppStatusMultiSelect: any;
  @ViewChild('oppIds') oppIds: any;
  @ViewChild('oppName') oppName: any;
  @ViewChild('oppUser') oppUser: any;
  @ViewChild('oppBd') oppBd: any;

  constructor(private renderer: Renderer2, private router: Router, private _location: Location, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private pagesService: PagesService, private dialogService: DialogService, private messageService: MessageService, private encyDyc: EncryptDecryptInterceptor, private cd: ChangeDetectorRef, private encryptDecryptInterceptor : EncryptDecryptInterceptor) {
    this.dateTime.setDate(this.dateTime.getDate());
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.setPageProperties(JSON.parse(data));
    }
    this.maxDate = new Date();
    this.minDate = new Date();
    this.minDate.setMonth(this.maxDate.getMonth() - 3);
    this.startDate = this.minDate.getFullYear() + "-" + (this.minDate.getMonth() + 1) + "-" + this.minDate.getDate();
    this.endDate = this.maxDate.getFullYear() + "-" + (this.maxDate.getMonth() + 1) + "-" + this.maxDate.getDate();
  }
  ngOnInit() {
    this.renderer.removeClass(document.body, 'overflow-scroll');
    this.userRole = this.encyDyc.decrypt(localStorage.getItem('role'));
    this.scopeList = this.encyDyc.decrypt(localStorage.getItem('scopes'));
    this.employeeName = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empName'));
    this.getbusinessWonDataGridList(this.startDate, this.endDate);
    this.ReportingScreenGridForm.get('opportinutyFilter').valueChanges.subscribe((data) => {
    });

  }

  ngAfterViewInit(): void {
    this.oppStatusMultiSelect.onChange.emit({originalEvent:null, value:[{name:'Business Won'}], itemValue: {name:'Business Won'}});
    this.cd.detectChanges();
    this.patchBdSpoc();
  }

  patchBdSpoc(){
    if(this.departmentName == "DEP36"){
      this.disableFilter = true;
      this.oppBd?.onChange.emit({originalEvent:null, value:[{name:this.employeeName}], itemValue: {name:this.employeeName}});
      this.cd.detectChanges();
    }
  }

  setPageProperties(props: any): void {
    const pageData = {};
    const calendarControls = ["gridCalender"];
    Object.keys(props).forEach((key) => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    })
    this.ReportingScreenGridForm.patchValue(pageData)
  }
  showFilter() {
    this.filterDisplayRow = true;
    this.filterDisplay = true;
    setTimeout(() => {
      this.patchBdSpoc();
    }, 500);
  }
  clear(table: Table) {
    table.reset();
    this.cd.detectChanges();
    if(this.businessWonDataGridid?.first?.filters) { // reset all filters
      Object.values(this.businessWonDataGridid.first.filters).map(item => {
        (<any>item).value = null;
        return item;
      });
    }this.filterDisplayRow = false;
    this.filterDisplay = false;
    this.excelIds = [];
    this.businessWonDataGridid.first.value.filter((data) => {
      this.excelIds.push(data.opportunityId)
    });
    this.excelData = { 'opportunityIdList': this.excelIds, 'isCOAUser': 'false' };
    this.getbusinessWonDataGridList(this.startDate, this.endDate);
  }
  handleChangeEntityDetailstabview(e: any): void {
    this.ReportingScreenGridForm.patchValue({ "EntityDetailstabview": e.index });
  }

  exportExcel() {
    this.excelIds = []
    this.businessWonDataGridid?.first?.filteredValue?.filter((data) => {
      this.excelIds.push(data.opportunityId);
    });
    if (this.excelIds.length == 0) {
      this.gridDataList.forEach((data) => {
        this.excelIds.push(data.opportunityId);
      });
    }
    let isCOA = '';
    let fileName = '';
    let scope: any = this.scopeList.split(",")

    if ((scope.indexOf('COA_EDIT') != -1) || (scope.indexOf('COA_READ') != -1)) {
      isCOA = 'True'
      fileName = 'COA Screen Data'
    }

    if ((scope.indexOf('REPORTING_CREATE') != -1) || (scope.indexOf('REPORTING_UPDATE') != -1)
      || (scope.indexOf('REPORTING_READ') != -1)) {
      isCOA = 'False'
      fileName = 'Reporting Screen Data'
    }

    this.excelData = { 'opportunityIdList': this.excelIds, 'isCOAUser': isCOA };
    let src;
    this.pagesService.sendExcelIds(this.excelData).subscribe((res) => {
      src = `data:application/vnd.ms-excel;base64,${res.base64}`;
      const link = document.createElement('a');
      link.href = src;
      link.download = fileName
      link.click();
    });
  }
  getbusinessWonDataGridList(sDate?: any, eDate?: any): void {
    let typeList = ["IRF", "IRF-A"];
    let payload = {
      startDate: sDate, 
      endDate: eDate,
      types: typeList,
      department: null,
      bdSpocUserId: this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid')),
      userRole: this.userRole
    }

    this.pagesService.getbusinessWonDataGridDataReportingScreenGrid(payload).subscribe(results => {
      this.dataLoader = false;
      this.gridLoader = false;
      this.gridDataList = results["reports"];
      const responseData = results["reports"];
      if (responseData && responseData.length > 0) {
        this.gridDataList = responseData;
        if(this.gridDataList?.length > 0) {

          // BLOCK - START - to add multi-select items in filter for each column
          let filterAttributes = {
            opportunityIdList : new Set<any>(), 
            opprIdList : new Set<any>(), 
            entityNameList : new Set<any>(),
            entityNamebyList : new Set<any>(),
            productList : new Set<any>(),
            prodList1 : new Set<any>(),
            nexeraUserList : new Set<any>(),
            nexeraUserList1 : new Set<any>(),
            bdManagerList : new Set<any>(),
            uniqbdManagerList : new Set<any>(),
            bdSpocList : new Set<any>(),
            uniqbdSpocList : new Set<any>(),
            opportunityList : new Set<any>(),
            opportunityStatusList : new Set<any>(),
            dateList : new Set<any>(),
            uniqDateList : new Set<any>(),
            ageingList : new Set<any>(),
            uniqAgeingList : new Set<any>()
          };


          this.gridDataList.forEach(gridItem => {
            filterAttributes.opportunityIdList.add(gridItem?.opportunityId);
            gridItem['oppIdByList'] = {name : gridItem?.opportunityId};
            
            filterAttributes.entityNameList.add(gridItem?.entityName);
            gridItem['entityNamebyList'] = {name : gridItem?.entityName};
            
            filterAttributes.productList.add(gridItem?.productName);
            gridItem['productbyList'] = {name : gridItem?.productName};
            
            filterAttributes.nexeraUserList.add(gridItem?.nexeraUser);
            gridItem['nexeraUserbyList'] = {name : gridItem?.nexeraUser};
            
            filterAttributes.bdManagerList.add(gridItem?.bdManager);
            gridItem['bdManagerbyList'] = {name : gridItem?.bdManager};
            filterAttributes.bdSpocList.add(gridItem?.bdSpoc);
            gridItem['bdSpocbyList'] = {name : gridItem?.bdSpoc};
            
            filterAttributes.opportunityList.add(gridItem?.opportunityStatus);
            gridItem['opportunitybyList'] = {name : gridItem?.opportunityStatus};

            const date = 
            filterAttributes.dateList.add(gridItem?.businessWonDate);
            gridItem['datebyList'] = {name : gridItem?.businessWonDate};
            
            filterAttributes.ageingList.add(gridItem?.ageing);
            gridItem['ageingbyList'] = {name : gridItem?.ageing};
          });

          this.oppIdList = this._convertToReportingItemForm(filterAttributes.opportunityIdList);
          
          this.nameList = this._convertToReportingItemForm(filterAttributes.entityNameList);

          this.productList1 = this._convertToReportingItemForm(filterAttributes.productList);

          this.nexeraUserList1 = this._convertToReportingItemForm(filterAttributes.nexeraUserList);

          this.uniqbdManagerList = this._convertToReportingItemForm(filterAttributes.bdManagerList);

          this.uniqbdSpocList = this._convertToReportingItemForm(filterAttributes.bdSpocList);

          this.opportunityStatusList = this._convertToReportingItemForm(filterAttributes.opportunityList);

          this.uniqDateList = this._convertToReportingItemForm(filterAttributes.dateList);

          this.uniqAgeingList = this._convertToReportingItemForm(filterAttributes.ageingList);
        }
        // BLOCK - END

        let wonIndex = this.opportunityStatusList.findIndex(d => d.name === 'Business Won')
        if(wonIndex == -1){
          this.opportunityStatusList.push({name: 'Business Won'});
        }

        this.gridDataList.forEach(data => {
          data.businessWonDate = data?.businessWonDate.split('-').join('-');
        });
      } else {
        this.gridDataList = [];
      }
      this.businessWonCount = 0;
      this.getBusinessWonListCount();
      this.firstLoad = true;
      this.apiFail = false;
    }, (_error) => {
      this.gridDataList = [];
      this.dataLoader = false;
      this.apiFail = true;
    });

  }

  onEntityNAMEdialog(rowData: any): void {
    let scopes = this.encyDyc.decrypt(localStorage.getItem('scopes'));
    let component;
    if ((scopes.indexOf('REPORTING_CREATE') != -1) || (scopes.indexOf('REPORTING_UPDATE') != -1)
      || (scopes.indexOf('REPORTING_READ') != -1)) {
      component = ReportingScreenDetailsComponent
    } else if ((scopes.indexOf('COA_EDIT') != -1) || (scopes.indexOf('COA_READ') != -1)) {
      component = ReportingScreenComponent
    }
    this.dynamicTableDialogEntityNAMERef = this.dialogService.open(component, {
      contentStyle: { "height": "76vh", "overflow": "hidden" },
      closeOnEscape: false,
      closable: true,
      data: rowData,
      width: '96%',
      header: rowData.entityName
    });
    this.dynamicTableDialogEntityNAMERef.onClose.subscribe((response: any) => {
      if (response?.status?.toLowerCase() == "success") {
        this.messageService.add({ severity: 'success', detail: 'Data updated successfully' });

      } else if (response?.status?.toLowerCase() == "error") {
        this.messageService.add({ severity: 'error', detail: 'Error while updating data,try again! ' });
      }
      setTimeout(() => {
        this.messageService.clear();
      }, 4000);
      this.ReportingScreenGridForm.get('businessWonDataGrid')?.setValue(response);
      this.getbusinessWonDataGridList(this.startDate, this.endDate);
    });
  }

  onENTITYNAMEdialog(rowData: any): void {
    this.dynamicTableDialogENTITYNAMERef = this.dialogService.open(ReportingScreenDetailsComponent, {
      contentStyle: { "height": "80vh", "overflow": "hidden" },
      data: rowData,
      width: '86%',
      header: 'Entity Details'
    });
    this.dynamicTableDialogENTITYNAMERef.onClose.subscribe((response: any) => {
      this.ReportingScreenGridForm.get('table')?.setValue(response);
    });
  }
  onReportingGridBackBtn(): void {
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  datePicker(): any {
    if (this.isFirstDate === true) {
      this.startDate = this.ReportingScreenGridForm.value.gridCalender[0].split("/").reverse().join("-");
      this.isFirstDate = false;
    }
    else {
      this.endDate = this.ReportingScreenGridForm.value.gridCalender[1].split("/").reverse().join("-");
      this.isFirstDate = true;
      this.getbusinessWonDataGridList(this.startDate, this.endDate);
      this.oppStatusMultiSelect.onChange.emit({originalEvent:null, value:null, itemValue: null});
      this.oppIds.onChange.emit({originalEvent:null, value:null, itemValue: null});
      this.oppName.onChange.emit({originalEvent:null, value:null, itemValue: null});
      this.oppUser.onChange.emit({originalEvent:null, value:null, itemValue: null});
      this.oppBd?.onChange.emit({originalEvent:null, value:null, itemValue: null});
      this.patchBdSpoc();
    }
    if (this.rangeDate[1]) {
      this.calender.overlayVisible = false;
    }
  }

  onClearClick() {
    this.dataLoader = true;
    this.dateTime.setDate(this.dateTime.getDate());
    this.maxDate = new Date();
    this.minDate = new Date();
    this.minDate.setMonth(this.maxDate.getMonth() - 3);
    this.startDate = this.minDate.getFullYear() + "-" + (this.minDate.getMonth() + 1) + "-" + this.minDate.getDate();
    this.endDate = this.maxDate.getFullYear() + "-" + (this.maxDate.getMonth() + 1) + "-" + this.maxDate.getDate();
    this.getbusinessWonDataGridList(this.startDate, this.endDate);
  }

  getBusinessWonListCount() {
    let len = this.gridDataList.length;
    for (let opportunityCount = 0; opportunityCount < len; opportunityCount++) {
      if (this.gridDataList[opportunityCount].opportunityStatus == "Business Won") {
        this.businessWonCount++;
      }
    }
  }
  homeBreadcrumbClicked(event) {
    if(event.item?.icon == 'pi pi-home'){
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  generateExcel(){
    this.excelIds = []
    this.businessWonDataGridid?.first?.filteredValue?.filter((data) => {
      this.excelIds.push(data.opportunityId);
    });
    if (this.excelIds.length == 0) {
      this.gridDataList.forEach((data) => {
        this.excelIds.push(data.opportunityId);
      });
    }
    let excelType = "IRFReportingExcel";
    let excelTypeName = "Reporting Screen Data";
    let empId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));
    this.pagesService.generateExcel(excelType,excelTypeName,this.excelIds,empId).subscribe((res) => {
      this.messageService.add({ severity: 'success', detail: 'Excel Generated successfully' });
      setTimeout(() => {
        this.messageService.clear();
      }, 4000);
    });
  }

  private _convertToReportingItemForm(attributeSet: Set<any>) {
    return Array.from(attributeSet)?.map(item => ({'name' : item}));
  }
}
