<div *ngIf="listItems?.length > 1" class="p-d-flex p-jc-end p-mr-1 p-mb-2">
    <button pButton 
        class="p-button-secondary p-button-sm"
        label="Approve All"
        [disabled]="!isApproveAllEnabled"
        (click)="approveAll()">
    </button>
                                
</div>
<ng-container *ngFor="let item of listItems; let i = index; trackBy: trackChange">
    <qc-list-item [disabled]="disabled" [data]="item" [indexFirst]="i === 0" [indexLast]="i === (listItems?.length - 1)"></qc-list-item>
</ng-container>
<!-- for future, non list items -->
