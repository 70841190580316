import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BACKOFFICE_SERVER_API_URL } from 'app/app.constants';
import { Observable } from 'rxjs';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';

@Injectable({
  providedIn: 'root'
})
export class OfflineBillsService {

  constructor( private http: HttpClient, public encDec: EncryptDecryptInterceptor) { }

  getBillFormDetails(id): Observable<any>{
    const paylaod = this.encDec.encryptWith(id);
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/findLogByBillId?billId=${paylaod}`)
  }

  getOfflineBillDetails(payload:any):Observable<any>{
    return this.http.post(BACKOFFICE_SERVER_API_URL+`api/v1/irfarchivalscreen/getByOfflineBillByCompany`,payload);
  }
}
