import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor(private keycloakservice: KeycloakService) {}

  login(): void {
     this.keycloakservice.login();
  }

  logout(): void {
    this.keycloakservice.logout();
  }
}
