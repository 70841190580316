<div class="layout-content">
    <p-messages class="custom-message"></p-messages>
    <div class="p-formgrid p-grid breadcrumb p-m-0">
        <div class=" p-col-12">
            <div class="ui-fluid p-formgrid p-grid p-ai-center p-m-0">
                <div class="p-col-10 p-lg-9 p-xl-8  p-text-left">
                    <div class=" p-fluid   p-field   p-mb-0 ">
                        <p-breadcrumb id="applicationBreadcrumb_id" name="applicationBreadcrumb_name" class=""
                            [model]="applicationBreadcrumbList" [home]="homeiconapplicationBreadcrumb">
                        </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="visible-card-container">
        <!-- Agreement Details -->
        <div class="p-col-12 p-p-0">
            <p-card class="p-p-0">
                <div class="ui-fluid p-formgrid p-grid entityDetailsHeader p-mx-0">
                    <div class="p-col-12 p-md-6 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">Company Name:</label>
                            <span class="desc">{{firstAgreement?.companyName}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">Company Code:</label>
                            <span class="desc">{{firstAgreement?.companyCode}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">Financial Year:</label>
                            <span class="desc">{{firstAgreement?.financialYear}}</span>
                        </div>
                    </div>


                    <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">Assignment Family:</label>
                            <span class="desc">{{firstAgreement?.assignmentFamily}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">SF Bill ID:</label>
                            <span class="desc">{{firstAgreement?.sfBillId}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">Entity Status:</label>
                            <span class="desc">{{firstAgreement?.entityStatus}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">Company Level Client Type:</label>
                            <span class="desc">{{firstAgreement?.clientType}}</span>
                        </div>
                    </div>


                    <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">Total SF Amount:</label>
                            <span class="desc">{{totalSfAmt}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">RPT In CC:</label>
                            <span class="desc">{{firstAgreement?.rptInCc}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">RPT In CRM:</label>
                            <span class="desc">{{firstAgreement?.rptInCrm}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">BD Owner Name:</label>
                            <span class="desc">{{firstAgreement?.bdOwnerName}}</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2">Cap Classification:</label>
                            <span class="desc">{{firstAgreement?.feecapClassification == 'N'?'Non Fee Cap':'Fee
                                Cap'}}</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left"
                        *ngIf="this.firstAgreement?.billStatus == 148 || this.firstAgreement?.billStatus == 150">
                        <div class="p-d-flex"> <label class="p-mr-2">Rejected Reason:</label>
                            <span class="desc">{{rejectedReason}}</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left"
                        *ngIf="this.firstAgreement?.billStatus == 148 || this.firstAgreement?.billStatus == 150">
                        <div class="p-d-flex"> <label class="p-mr-2">Attachment:</label>
                            <span class="desc"><a class="truncated"
                                    (click)="showAttachmentPreview(rejectedAttach[0])">{{rejectedAttach[0]?.fileName}}</a></span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-12 p-mt-2 p-px-0 p-text-left"
                        *ngIf="this.firstAgreement?.billStatus == 148 || this.firstAgreement?.billStatus == 150">
                        <div class="p-d-flex"> <label class="p-mr-2">Rejected Comment:</label>
                            <span class="textwrap desc">{{rejectedComment}}</span>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
    </div>
    <form [formGroup]="agreementFormList">
        <div class="p-formgrid p-grid formFieldContainer">
            <div formArrayName="agreements">
                <!-- Agreement Id -->
                <div class="p-col-12 p-mb-4 p-px-0" *ngFor="let data of agreements.controls; let i = index">
                    <p-card class="p-p-0 agreement-cards" [formGroupName]="i">
                        <ng-template pTemplate="header">
                            <div class="p-card-title">
                                <div class="title">
                                    <svg width="32" height="32" class="p-mr-1" viewBox="0 0 32 32" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#EBF3FE" />
                                        <path
                                            d="M22 8V25L20.0029 24L18.0057 25L16 24L14.0029 25L12.0057 24L10 25V8C10 7.73478 10.0903 7.48043 10.2511 7.29289C10.4118 7.10536 10.6298 7 10.8571 7H21.1429C21.3702 7 21.5882 7.10536 21.7489 7.29289C21.9097 7.48043 22 7.73478 22 8Z"
                                            stroke="#0E6AF2" stroke-width="0.9" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M20 17.6565H12" stroke="#0E6AF2" stroke-width="0.7"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M20 20.0073H12" stroke="#0E6AF2" stroke-width="0.7"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M14 10.4224H18" stroke="#0E6AF2" stroke-width="0.8"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M14 9H18" stroke="#0E6AF2" stroke-width="0.8" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M15.2273 9C15.6974 9 16.1483 9.17865 16.4808 9.49664C16.8132 9.81464 17 10.2459 17 10.6957C17 11.1454 16.8132 11.5767 16.4808 11.8947C16.1483 12.2127 15.6974 12.3913 15.2273 12.3913H14L17 15"
                                            stroke="#0E6AF2" stroke-width="0.8" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    Agreement Id: {{data.value.agreementId}}
                                </div>
                                <div class="agreement-delete-option">
                                    <div class="p-mb-0 p-field-checkbox">
                                        <p-checkbox binary="true" label="Delete Agreement" formControlName="isSelected"
                                            (onChange)="deleteClicked(data.value?.agreementId, $event, i)"
                                            [disabled]="readScope">
                                        </p-checkbox>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <div class="ui-fluid p-formgrid p-grid entityDetailsHeader p-m-0">
                            <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">Case Type:</label>
                                    <span class="desc">{{data.value.caseType}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">Agreement Type:</label>
                                    <span class="desc">{{data.value.agreementType}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">Initiation Type:</label>
                                    <span class="desc">{{data.value.initiationType}}</span>
                                </div>
                            </div>

                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">Product Name:</label>
                                    <span class="desc">{{data.value.productName}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">Instrument:</label>
                                    <span class="desc">{{data.value.instrument}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">QC Date:</label>
                                    <span class="desc">{{data.value.qcDate | date:'dd-MM-yyyy'}}</span>
                                </div>
                            </div>

                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">Reporting Date:</label>
                                    <span class="desc">{{data.value.reportingDate | date:'dd-MM-yyyy'}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">RCM Date:</label>
                                    <span class="desc">{{data.value.rcmDate | date:'dd-MM-yyyy'}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">RR Date:</label>
                                    <span class="desc">{{data.value.rrDate | date:'dd-MM-yyyy'}}</span>
                                </div>
                            </div>

                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">Mandate Qtm (Applicable) as per
                                        Nexera:</label>
                                    <span class="desc">{{data.value.mandateQtm}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">SF Actual Percentage:</label>
                                    <span class="desc">{{data.value.sfActualPercent}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">SF Method Amount:</label>
                                    <span class="desc">{{data.value.sfMethodAmt}}</span>
                                </div>
                            </div>

                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">Revised SF Actual:</label>
                                    <span class="desc">{{data.value.revisedSfActual}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">Fee Rule:</label>
                                    <span class="desc">{{data.value.feeRule}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">SF Maximum Amount:</label>
                                    <span class="desc">{{data.value.sfMaxAmt}}</span>
                                </div>
                            </div>

                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">SF Minimum Amount:</label>
                                    <span class="desc">{{data.value.sfMinAmt}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-2 label_light">Billed Days:</label>
                                    <span class="desc">{{data.value.billedDays}}</span>
                                </div>
                            </div>

                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-fluid p-field">
                                    <label>
                                        SF Start Date<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <div class="p-fluid p-field p-mb-0">
                                        <p-calendar class="p-input-width" [showIcon]="true" id="sfStartDate_id"
                                            (onSelect)="compareDates(data.value, i)" name="sfStartDate_name"
                                            dateFormat="dd-M-yy" formControlName="sfStartDate" showButtonBar="true"
                                            placeholder="Select Start Date" inputId="sfStartDateInput"
                                            [disabled]="readScope"
                                            [ngClass]="{error: (agreements.controls[i].get('sfStartDate')?.dirty && agreements.controls[i].get('sfStartDate')?.errors?.required && agreements.controls[i].get('sfStartDate')?.invalid)}"></p-calendar>
                                        <div class='invalid-feedback' *ngIf="data.value.isDateGreater">SF Start date
                                            should be
                                            smaller than SF End date
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="(agreements.controls[i].get('sfStartDate')?.dirty && agreements.controls[i].get('sfStartDate')?.errors?.required && agreements.controls[i].get('sfStartDate')?.invalid)">
                                            SF Start Date is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-fluid p-field">
                                    <label>
                                        SF End Date<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <div class="p-fluid p-field p-mb-0">
                                        <p-calendar class="p-input-width" [showIcon]="true" id="sfEndDateid"
                                            (onSelect)="compareDates(data.value, i)" name="sfEndDatename"
                                            dateFormat="dd-M-yy" formControlName="sfEndDate"
                                            placeholder="Select End Date" showButtonBar="true" inputId="sfEndDateInput"
                                            [disabled]="readScope"
                                            [ngClass]="{error: (agreements.controls[i].get('sfEndDate')?.dirty && agreements.controls[i].get('sfEndDate')?.errors?.required && agreements.controls[i].get('sfEndDate')?.invalid)}"></p-calendar>
                                        <div class='invalid-feedback' *ngIf="data.value.isDateGreater">SF Start date
                                            should be
                                            smaller than SF End date</div>
                                        <div class="invalid-feedback"
                                            *ngIf="(agreements.controls[i].get('sfEndDate')?.dirty && agreements.controls[i].get('sfEndDate')?.errors?.required && agreements.controls[i].get('sfEndDate')?.invalid)">
                                            SF End Date is required.
                                        </div>
                                    </div>
                                    <span></span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-fluid p-field">
                                    <label class="p-mr-2 label_light">SF Amount<span
                                            class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <div class="p-fluid p-field p-mb-0">
                                        <p-inputNumber class="p-input-width" id="sfAmount_id" name="sfAmount_name"
                                            mode="decimal" [minFractionDigits]="2" formControlName="sfAmt"
                                            [maxFractionDigits]="2" placeholder="Enter SF Amount"
                                            (onBlur)="calculateSfAmount()" [disabled]="readScope"
                                            [ngClass]="{error: (agreements.controls[i].get('sfAmt')?.dirty && (agreements.controls[i].get('sfAmt')?.errors?.required || agreements.controls[i].get('sfAmt')?.invalid))}"></p-inputNumber>
                                        <div class="invalid-feedback"
                                            *ngIf="(agreements.controls[i].get('sfAmt')?.dirty && (agreements.controls[i].get('sfAmt')?.errors?.required || agreements.controls[i].get('sfAmt')?.invalid))">
                                            SF Amount is required.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-fluid p-field">
                                    <label class="p-mr-2 label_light">Outstanding Quantum as per ROW<span
                                            class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <div class="p-fluid p-field p-mb-0">
                                        <p-inputNumber class="p-input-width" id="outstandingAmount_id"
                                            name="outstandingAmount_name" mode="decimal"
                                            formControlName="outstandingQtm" [minFractionDigits]="2"
                                            [maxFractionDigits]="2" placeholder="Enter Outstanding Amount"
                                            [disabled]="readScope"
                                            [ngClass]="{error: (agreements.controls[i].get('outstandingQtm')?.dirty && agreements.controls[i].get('outstandingQtm')?.errors?.required && agreements.controls[i].get('outstandingQtm')?.invalid)}"></p-inputNumber>
                                        <div class="invalid-feedback"
                                            *ngIf="(agreements.controls[i].get('outstandingQtm')?.dirty && agreements.controls[i].get('outstandingQtm')?.errors?.required && agreements.controls[i].get('outstandingQtm')?.invalid)">
                                            Outstanding Quantum as per ROW is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                                <div class="p-fluid p-field">
                                    <label class="p-mr-2 label_light">Bill Details<span
                                            class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <div class="p-fluid p-field p-mb-0">
                                        <input class="p-input-width" type="text" pInputText id="billDetailsDesc_id"
                                            name="billDetailsDesc_name" formControlName="billDetails"
                                            placeholder="Enter Bill Details Description" [readonly]="readScope"
                                            [ngClass]="{error: (agreements.controls[i].get('billDetails')?.dirty && agreements.controls[i].get('billDetails')?.errors?.required && agreements.controls[i].get('billDetails')?.invalid)}"
                                            maxlength="150" />
                                        <div class="invalid-feedback"
                                            *ngIf="(agreements.controls[i].get('billDetails')?.dirty && agreements.controls[i].get('billDetails')?.errors?.required && agreements.controls[i].get('billDetails')?.invalid)">
                                            Bill Details is required.
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="(agreements.controls[i].get('billDetails')?.dirty && agreements.controls[i].get('billDetails')?.errors?.maxlength && agreements.controls[i].get('billDetails')?.invalid) ">
                                            Maximum length of 150 characters exceeded.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>




            <!-- <div formArrayName="selectedAgreementCards">
                <div *ngFor="let card of agreementCards; let i = index" [formGroupName]="i">
                  <p-checkbox
                        binary="true"
                        formControlName="isSelected"
                        label="Delete Agreement" >
                    </p-checkbox>
                  <label>{{ card.name }}</label>
                  <input type="date" formControlName="sfStartDate">
                  <input type="date" formControlName="sfEndDate">
                  <input type="number" formControlName="sfAmount">
                  <input type="number" formControlName="outstandingAmount">
                  <input type="text" formControlName="description">
                </div>
            </div> -->



            <!-- Other Attachment (NDA & Fee letter) -->
            <div class="p-col-12 p-mb-4 p-px-0">
                <p-card class="p-p-0">
                    <ng-template pTemplate="header">
                        <div class="p-d-flex p-ai-center">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="32" rx="16" fill="#F9F5FF" />
                                <path
                                    d="M23.8666 15.2081L16.2082 22.8664C15.27 23.8046 13.9976 24.3317 12.6707 24.3317C11.3439 24.3317 10.0715 23.8046 9.13325 22.8664C8.19505 21.9282 7.66797 20.6558 7.66797 19.3289C7.66797 18.0021 8.19505 16.7296 9.13325 15.7914L16.7916 8.1331C17.4171 7.50763 18.2654 7.15625 19.1499 7.15625C20.0345 7.15625 20.8828 7.50763 21.5082 8.1331C22.1337 8.75857 22.4851 9.60689 22.4851 10.4914C22.4851 11.376 22.1337 12.2243 21.5082 12.8498L13.8416 20.5081C13.5288 20.8208 13.1047 20.9965 12.6624 20.9965C12.2201 20.9965 11.796 20.8208 11.4832 20.5081C11.1705 20.1954 10.9948 19.7712 10.9948 19.3289C10.9948 18.8867 11.1705 18.4625 11.4832 18.1498L18.5582 11.0831"
                                    stroke="#8B3DFF" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <h6 class="p-mb-0 p-ml-2">Attachment</h6>
                        </div>
                    </ng-template>
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader p-m-0 p-pl-0">
                        <div class="p-col-4 p-pl-0">
                            <button pButton class="p-button-secondary p-button-sm" [disabled]="uploadedDoc" label="Add"
                                (click)="addAttachment()" [disabled]="readScope">
                            </button>
                        </div>
                        <div class="p-col-12 p-mt-2 p-pl-0" *ngIf="!uploadedDoc">
                            <p>Note: Attachment is mandatory when the user changes value for SF Start Date, SF End Date,
                                SF Amount, Outstanding Quantum as Per ROW </p>
                        </div>

                    </div>
                    <div class="ui-fluid p-formgrid p-grid p-m-0 p-pl-0">
                        <div class="p-col-3 p-md-3 p-p-0 p-mt-2">
                            <p-card class="attachmentCard" *ngIf="uploadedDoc">
                                <div class="p-col-12 p-md-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                    <div class="p-col-2" style="padding-right: 9px;">
                                        <img class="uploadedThumbnail"
                                            *ngIf="uploadedDoc.fileContentType == 'application/pdf'" [src]="pdfThumb"
                                            alt="">
                                        <img class="uploadedThumbnail" [src]="imgThumb"
                                            *ngIf="uploadedDoc.fileContentType == 'image/png' || uploadedDoc.fileContentType == 'image/jpg' || uploadedDoc.fileContentType == 'image/jpeg'"
                                            alt="">
                                        <img class="uploadedThumbnail" [src]="wordThumb"
                                            *ngIf="uploadedDoc.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || uploadedDoc.fileContentType == 'application/msword' "
                                            alt="">
                                        <img class="uploadedThumbnail" *ngIf="uploadedDoc.fileContentType == ''"
                                            [src]="mailThumb" alt="">
                                    </div>
                                    <div class="p-col-10 p-md-10 p-pr-0 p-pl-2">
                                        <div class="p-d-flex p-ai-center p-jc-between">
                                            <a [ngClass]="{'attachmentName' : uploadedDoc.fileId}"
                                                class="limit-text-length cursor-pointer"
                                                (click)="showAttachmentPreview(uploadedDoc)">
                                                {{uploadedDoc.fileName}}
                                            </a>
                                            <span class="">
                                                <i class="pi pi-trash cursor-pointer attachmentIcon" *ngIf="!readScope"
                                                    aria-hidden="true" (click)="deleteAttachPopup(uploadedDoc)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </p-card>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                        <div class="p-fluid p-field">
                            <label class="p-mr-2 label_light">Remark</label>
                            <div class="p-fluid p-field p-mb-0">
                                <input class="p-input-width" type="text" pInputText id="remark" name="remark" pattern="^([a-zA-Z0-9.-/-]+\s)*[a-zA-Z0-9.-/-]+$"
                                    formControlName="remarkValue" maxlength="500" />
                                    <div class="invalid-feedback"
                                            *ngIf="(agreementFormList.get('remarkValue')?.dirty && agreementFormList.get('remarkValue')?.invalid)">
                                            Only AlphaNumeric Characters Are Allowed.
                                        </div>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </form>
</div>

<div class="p-col-12 p-d-flex p-jc-between p-button-container p-ai-center">
    <div class="p-col-12 p-d-flex p-text-left p-jc-between p-pl-0">
        <div class="btnwrap">
            <button pButton class="p-button-brand2 p-button" type="button" label="Back" (click)="backBtnClicked()">
            </button>
        </div>

        <div class="btnwrap">
            <button pButton class="p-button-secondary p-button" type="button" (click)="onAddAgreement('dummy')"
                [disabled]="readScope" label="Add Dummy Agreement">
            </button>
            <button pButton class="p-button-secondary p-button p-ml-2" type="button"
                (click)="onAddAgreement('agreement')" [disabled]="readScope" label="Add Agreement">
            </button>
            <button pButton class="p-button-secondary p-button p-ml-2" type="button" (click)="runValidations('bd')"
                [disabled]="readScope" label="Save & Send To BD">
            </button>
            <button pButton class="p-button-primary p-button p-ml-2" type="button" (click)="runValidations('fin')"
                [disabled]="readScope" label="Save & Send To Fin">
            </button>
        </div>

    </div>
</div>

<p-dialog header="Preview" [(visible)]="displayPreview" class="docPreview previewModal" [modal]="true"
    [style]="{width: '95vw', height: '100vh'}" [draggable]="false" [resizable]="false">
    <div class="no_data_div tableLoader" *ngIf="dataLoader">
        <div class="app-loading " id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label>Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <pdf-viewer id="pdf_viewer" *ngIf="isPdf" [src]="urlSafe" [rotation]="rotate" [original-size]="false"
        [show-all]="true" [fit-to-page]="false" [zoom]="zoomValue" [zoom-scale]="'page-width'" [stick-to-page]="false"
        [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="width: 100%; height:100%;">
    </pdf-viewer>
    <div class="imagePreviewContainer p-d-flex p-jc-center p-ai-center">
        <img *ngIf="!isPdf" src="{{urlSafe}}" id="imagePrev" alt="Image">
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
        <!-- <button pButton type="button" label="Download" (click)="downloadFile()" class="p-button-secondary"></button> -->
    </ng-template>
</p-dialog>

<!-- error modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup errorModal" [(visible)]="formError" [modal]="true"
    [style]="{width: '45vw', height: '30vh'}" [draggable]="false" [resizable]="false">
    <p>There are few details missing. Please fill them to proceed further.</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Ok" (click)="formError=false" class="p-button-primary p-mx-0"></button>
    </ng-template>
</p-dialog>

<!-- attachment error modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup errorModal" [(visible)]="attachMissing" [modal]="true"
    [style]="{width: '35vw', height: '26vh'}" [draggable]="false" [resizable]="false">
    <p>Please upload attachment.</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Ok" (click)="attachMissing=false" class="p-button-primary p-mx-0"></button>
    </ng-template>
</p-dialog>

<!-- form error modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: '22vh'}" [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>

<!-- delete attachment modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup errorModal" [(visible)]="deleteAttach" [modal]="true"
    [style]="{width: '45vw', height: '30vh'}" [draggable]="false" [resizable]="false">
    <p>Are you sure you want to permanently delete the attachment?</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="No" (click)="deleteAttach=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Yes" (click)="deleteAttachment()" class="p-button-primary p-mr-0"></button>
    </ng-template>
</p-dialog>

<!-- delete agreement modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup errorModal" [(visible)]="deleteAgreementPopup"
    [modal]="true" [style]="{width: '45vw', height: '30vh'}" [draggable]="false" [resizable]="false">
    <p>Are you sure you want to delete agreement details?</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="No" (click)="deleteAgreementPopup=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Yes" (click)="createPayload()" class="p-button-primary p-mr-0"></button>
    </ng-template>
</p-dialog>

<!-- delete all agreement modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup errorModal" [(visible)]="deleteAllAgreePopup"
    [modal]="true" [style]="{width: '45vw', height: '30vh'}" [draggable]="false" [resizable]="false">
    <p>You cannot delete all agreements!</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Ok" (click)="deleteAllAgreePopup=false"
            class="p-button-primary p-mx-0"></button>
    </ng-template>
</p-dialog>

<!-- bill already actioned modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup errorModal" [(visible)]="billAlreadySent" [modal]="true"
    [style]="{width: '45vw', height: '30vh'}" [draggable]="false" [resizable]="false">
    <p>Bill has already been actioned!</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Ok" (click)="billAlreadySent=false"
            class="p-button-primary p-mx-0"></button>
    </ng-template>
</p-dialog>

<!-- Alert modal -->
<!-- <p-dialog header="Alert" [(visible)]="alertInfoPopUp" [modal]="true" [style]="{width: '30vw'}" [draggable]="false"
    [resizable]="false">
    <div class="p-col-12 p-px-1" id="center">
        <p class="p-mb-0">{{alertInfoMsg}}</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="closeAlertInfoPopUpBtn" class="p-button-primary p-button p-mr-0"
                (click)="closeAlertInfoPopUp()" type="button" label="Ok"> </button>
        </div>
    </ng-template>
</p-dialog> -->

<!-- Alert modal -->
<!-- <p-dialog header="Alert" [(visible)]="alertInfoDeleteAgreementPopUp" [modal]="true" [style]="{width: '30vw'}"
    [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-px-1" id="center">
        <p class="p-mb-0">{{alertInfoDeleteAgreementMsg}}</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="doNotDeleteAgreementBtn" class="p-button-secondary p-button"
                (click)="doNotDeleteAgreement()" type="button" label="No"> </button>
            <button pButton id="deleteAgreementBtn" class="p-button-primary p-button p-mr-0" (click)="deleteAgreement()"
                type="button" label="Yes"> </button>
        </div>
    </ng-template>
</p-dialog> -->