<div class="">
    <p-toast class="custom-toast"></p-toast>
    <form [formGroup]="AttachmentModelForm" #formDiv autocomplete="off" novalidate>
        <div class="p-formgrid p-grid popupAttachmentBodyScrollContainer" [ngClass]="{'fdHeight': !isEdit}">
            <div class=" p-col-12 p-px-0">
                <div class="p-formgrid p-grid p-jc-between full-height p-mx-0">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  p-left p-px-0 fixed-height">
                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-left" *ngIf="!hideAttachType">
                                <div class="p-fluid p-field">
                                    <label
                                        [ngClass]="{'field-required': AttachmentModelForm.get('fileTypeName')?.errors?.required}">
                                        Attachment Type<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <div class="p-fluid  p-field p-mb-2">
                                        <p-dropdown name="fileTypeName" scrollHeight="200px" placeholder="Select" [options]="fileTypeNameList" 
                                            [autoDisplayFirst]="false" formControlName="fileTypeName" optionLabel="type" [showClear]="true"
                                            [ngClass]="{'error':AttachmentModelForm.get('fileTypeName')!.invalid && (AttachmentModelForm.get('fileTypeName')!.dirty || AttachmentModelForm.get('fileTypeName')!.touched)}">
                                        </p-dropdown>
                                    </div>
                                    <div class="invalid-feedback"
                                        *ngIf="AttachmentModelForm.get('fileTypeName')!.invalid && (AttachmentModelForm.get('fileTypeName')!.dirty || AttachmentModelForm.get('fileTypeName')!.touched)">
                                        <div *ngIf="AttachmentModelForm.get('fileTypeName')?.errors?.required">
                                            Attachment Type is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-text-center p-mb-3 p-px-0" *ngIf="thumbSrc || imageChangedEventfileUpload">
                                <div class="p-col-12 p-text-center" *ngIf="!isPdfDoc">
                                    <image-cropper class="crop-content" [imageBase64]="imageChangedEventfileUpload"
                                        [maintainAspectRatio]="false" format="jpeg" (imageCropped)="imageCroppedfileUpload($event)">
                                    </image-cropper>
                                </div>
                                <div class="p-col-12 p-text-center p-p-0" *ngIf="isPdfDoc">
                                    <p-card>
                                        <div class="p-col-10 p-pl-0 p-pr-0 p-d-flex p-ai-center">
                                            <div class="p-col-2 p-pl-0">
                                                <img class="uploadedThumbnail" [src]="thumbSrc" alt="">
                                            </div>
                                            <div class="p-col-10 p-pl-0 p-pr-0">
                                                <a>
                                                    <div class="p-col-12 p-d-flex p-flex-column p-flex-wrap p-ai-start p-pl-0 p-pr-0" style="text-align:left;">
                                                        <div class="limit-text-length attachmentName cursor-pointer">
                                                            {{file.name}}
                                                        </div>
                                                        <label class="attachmentFileType">{{fileSize}} KB</label>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isEdit" class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  left p-p-0 p-jc-between p-d-flex">
                        <div class="custom_file_upload_crop">
                            <p-fileUpload customUpload="true" id="fileUpload1" name="file"
                                [accept]="fileTypeAccepted" maxFileSize=20000000
                                mode="advanced" [showUploadButton]="false" [showCancelButton]="false"
                                #fileUploadfileUpload1 (onSelect)="checkFileType($event)" chooseLabel="Browse"
                                uploadLabel="Upload" cancelLabel="Cancel"
                                [disabled]="!AttachmentModelForm.valid">
                            </p-fileUpload>
                        </div>
                        <button pButton class="p-button-secondary p-button" type="submit"
                            (click)="onCroppedUploadBtn()" label="Upload"
                            [disabled]="isNotUpload==false?!AttachmentModelForm.valid:isNotUpload">
                        </button>
                    </div>
                    <div *ngIf="isEdit" class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  left p-p-0">
                        <button pButton class="p-button-secondary p-button" type="submit" (click)="typeEdited()" [disabled]="!AttachmentModelForm.valid" label="Save"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="no_data_div" *ngIf="uploadLoader">
        <div class="app-loading" id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
</div>