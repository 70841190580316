import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PagesService } from 'app/pages/pages.service';

@Component({
  selector: 'backoffice-view-bill-details',
  templateUrl: './view-bill-details.component.html',
  styleUrls: ['./view-bill-details.component.scss'],
  providers: [MessageService]
})
export class ViewBillDetailsComponent implements OnInit {

  applicationBreadcrumbList = [];
  apiFail: boolean = false;
  view_bill_details: any;
  openedFile: any;
  zoomValue: number = 1;
  rotate: number = 0
  displayPreview: boolean = false;
  dataLoader: boolean = false;
  isPdf: boolean = true;
  previewFailed: boolean = false;
  urlSafe: any;
  canvasRotation: number = 0;

  fetchedURLAgreementId;
  fetchedURLIrfBillId;
  openedFileName: any;

  agreementdetailsForm = this.fb.group({

  });

  homeiconapplicationBreadcrumb = { icon: 'pi pi-home', routerLink: ['/backoffice/agreement-archive/byclient'] };

  constructor(private pagesService: PagesService, private router: Router, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.fetchedURLIrfBillId = params.get('irfBillId');
      console.log("Agreementid_billdetails", this.fetchedURLAgreementId);
      console.log("irfbillid_billdetails", this.fetchedURLIrfBillId);
    });

    this.getArchivalIrfBillDetails();

  }

  getArchivalIrfBillDetails(): void {
    this.pagesService.getArchivalIrfBillDetails(this.fetchedURLIrfBillId).subscribe(results => {
      this.view_bill_details = results;
      this.fetchedURLAgreementId = this.view_bill_details?.agreementId;
      console.log("PortfolioDetails...", this.view_bill_details);
      this.applicationBreadcrumbList = [
        { label: `Archival Data Listing`, routerLink: ['/agreement-archive/byclient'] },
        { label: `Agreement`, routerLink: ['/agreement-archive/agreementdetails', this.fetchedURLAgreementId] },
        { label: `Bill Details`, routerLink: ['/agreement-archive/viewbilldetails'] },
      ];
      console.log("this.fetchedURLAgreementId", this.fetchedURLAgreementId);
      this.apiFail = false;
    }, (_error) => {
      this.apiFail = true;
    });
  }

  showAttachmentPreview() {
    this.zoomValue = 1;
    this.rotate = 0;

    this.pagesService.getArchivalIrfBillDetails(this.fetchedURLIrfBillId).subscribe((res) => {
      this.view_bill_details = res;
      this.apiFail = false;
      // this.view_bill_details.fileName
      // this.view_bill_details.fileBase64
      this.displayPreview = true;
      let mime: any;
      mime = 'data:application/pdf;base64,'
      this.urlSafe = `${mime}${this.view_bill_details?.fileBase64}`;
      console.log("pdfurl...", this.urlSafe)
      this.dataLoader = false;


    }, (_error) => {
      this.apiFail = true;
      this.displayPreview = false;
      this.previewFailed = true;
    });

  }
  
  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
  }
  rotateLeft() {
    this.canvasRotation--;
  }

  rotateRight() {
    this.canvasRotation++;
  }

  // flipAfterRotate() {
  //   const flippedH = this.transform.flipH;
  //   const flippedV = this.transform.flipV;
  //   this.transform = {
  //     ...this.transform,
  //     flipH: flippedV,
  //     flipV: flippedH,
  //   };
  // }

  onBackClick(): void {
    this.router.navigate(['/agreement-archive/agreementdetails', this.fetchedURLAgreementId])
  }

  showDeviationFile(filePath: any,filename: any){
    this.openedFileName = filename;
    this.zoomValue = 1;
    this.rotate = 0;
    
    this.pagesService.getArchivalIrfBillPaymentDetails(this.fetchedURLIrfBillId).subscribe((res) => {
      this.apiFail = false;
      this.displayPreview = true;
      let mime: any;
      mime = 'data:application/pdf;base64,'
      this.urlSafe = `${mime}${filePath}`
      console.log("pdfurl...",this.urlSafe)
      this.dataLoader = false;
      

    },(_error) => {
      this.apiFail = true;
      this.displayPreview = false;
      this.previewFailed = true;
    });

    console.log("preview",filePath)

  }

  downloadFile() {
    let linkSource: any;
    linkSource = this.urlSafe;
    
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this.openedFileName.split('.')[0];
    downloadLink.click();
  }

}
