import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QcListingComponent } from './qc-listing/qc-listing.component';
import { QcAdditionaldocListingComponent } from './qc-additionaldoc-listing/qc-additionaldoc-listing.component';
import { QualityCheckRoutingModule } from './quality-check-routing.module';
import { QcDetailsComponent } from './qc-details/qc-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { SvgModule } from 'app/backoffice-assets/svg.module';
import { CardModule } from 'primeng/card';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogService } from 'primeng/dynamicdialog';
import { QCItemListComponent } from './qc-details/item-list/item-list.component';
import { AuditLogDetailsComponent } from './qc-details/audit-log/audit-log-details.component';
import { PaymentsDetailsComponent } from './qc-details/payment-details/payment-details.component';
import { BackofficeSharedModule } from 'app/shared/shared.module';
import {TimelineModule} from 'primeng/timeline';
import { TagModule } from 'primeng/tag';
import { AttachmentPreviewListingComponent } from './attachment-preview-listing/attachment-preview-listing.component';
import { QcListItemComponent } from './qc-details/qc-list-item/qc-list-item.component';
import { ConfirmationService } from 'primeng/api';


@NgModule({
  declarations: [
    QcListingComponent,
    QcAdditionaldocListingComponent,
    QcDetailsComponent,
    AuditLogDetailsComponent,
    PaymentsDetailsComponent,
    QCItemListComponent,
    AttachmentPreviewListingComponent, // common component attachment listing FOR 
    // -- qc-details
    // -- qc-additional-doc-details screen
    QcListItemComponent
  ],
  imports: [
    CommonModule,
    QualityCheckRoutingModule,
    ReactiveFormsModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    BackofficeSharedModule,
    DialogModule,
    SvgModule,
    CardModule,
    DialogModule,
    FileUploadModule,
    ImageCropperComponent,
    AccordionModule,
    InputSwitchModule,
    StepsModule,
    ToastModule,
    PdfViewerModule,
    TimelineModule,
    TagModule,
    InputNumberModule
  ],
  providers:[
    DialogModule,
    DialogService,
    ConfirmationService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QualityCheckModule { }
