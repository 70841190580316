<form [ngClass]="{'p-grid main p-ai-center p-m-1' : true, 'first-semi' : !!indexFirst, 'last-semi' : !!indexLast}" [formGroup]="dataForm" >
    <div class="p-col-5">
        <label class="label_light">{{dataVal?.label}}</label>
        <h1 *ngIf="dataVal?.val" class="p-medium display-4">{{dataVal?.val}}</h1>
    </div>
    <div class="p-col-3" *ngIf="!!(dataVal?.isApprove !== undefined)">
        <div class="p-d-flex">
            
                <div [ngClass]="{'p-formgroup-inline approve-radio' : true, 'active' : isApprove === 'Approved'}">
                    <div class="field-radiobutton p-radiobutton-xs">
                        <p-radioButton [disabled]="disabled" class="p-mr-1" name="isApprove" value="Approved" (onClick)="onApprove(true)" [inputId]="identity"
                        [(ngModel)]="isApprove"></p-radioButton>
                        <!-- <label [for]="category.key">{{category.name}}</label> -->
                        <label class="cursor-pointer p-ml-1 p-mb-0 label_light" [for]="identity">Approve</label>
                        
                    </div>
                </div>
            
    
                <div [ngClass]="{'p-formgroup-inline reject-radio p-ml-3' : true, 'active' : isApprove === 'Rejected'}">
                    <div class="field-radiobutton p-radiobutton-xs">
                        <p-radioButton [disabled]="disabled" class="p-mr-1 p-radioButton-sm" name="isApprove" value="Rejected" (onClick)="onApprove(false)" [inputId]="identity +'identity'"
                        [(ngModel)]="isApprove" ></p-radioButton>
                        <label class="p-ml-1 p-mb-0 cursor-pointer label_light" [for]="identity +'identity'">Reject</label>                                       
                    </div>
                </div>
        </div>
        <div class="invalid-feedback" *ngIf="dataForm.get('isApprove')?.touched && dataForm.get('isApprove')?.errors?.required && dataForm.get('isApprove')?.invalid">
           This field is required
        </div>
        
    </div>
    <div class="p-col-4 p-d-flex" *ngIf="!!(dataVal?.comment !== undefined)">
        <div class="p-col-12 p-d-flex" style="flex-direction: column;">
            <textarea  [attr.disabled]="disabled ? true : null" [ngClass]="{'error':(dataForm.get('comment')?.touched && (dataForm.get('comment')?.errors?.required || dataForm.get('comment')?.errors?.minlength) && dataForm.get('comment')?.invalid)}"
            pInputTextarea id="comment" formControlName="comment" rows="1" cols="3" maxlength="500" placeholder="Enter your comment here"></textarea>

            <div class="invalid-feedback" *ngIf="dataForm.get('comment')?.touched && dataForm.get('comment')?.errors?.required && dataForm.get('comment')?.invalid">
                Comment is required
            </div>
            <div class="invalid-feedback" *ngIf="dataForm.get('comment')?.touched && !dataForm.get('comment')?.errors?.required && dataForm.get('comment')?.errors?.minlength && dataForm.get('comment')?.invalid">
                Please enter atleast 3 characters
            </div>
        </div>
    </div>
</form>