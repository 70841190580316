import { Component, OnInit} from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
@Component({
  selector: 'backoffice-sf-push-pull-details',
  templateUrl: './sf-push-pull-details.component.html',
  styleUrls: ['./sf-push-pull-details.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class SfPushPullDetailsComponent implements OnInit {

  agreementDetails: any;
  sfBillId: any;
  apiFail: boolean = false;
  rowData: any;

  constructor(
    protected activatedRoute: ActivatedRoute,
    public config: DynamicDialogConfig, 
    private pagesService: PagesService,
    public ref: DynamicDialogRef,
  ) {
    this.rowData = this.config?.data;
  }
  ngOnInit(): void {
    this.getAgreementDetails();
  }

  onCancelBtn() {
    setTimeout(() => {
      this.ref.close();
    }, 500);
  }

  onAddBtn() {
    console.log('In Add method');
    //send rowData and close popup
    setTimeout(() => {
      this.ref.close(this.rowData);
    }, 500);
  }

  getAgreementDetails() {
    this.sfBillId = this.rowData.sfBillId;
    let payload = {
      "sfBillId": this.sfBillId,
    };
    this.pagesService.getAgreementDetails(payload).subscribe((results) => {
      this.agreementDetails = results;
    }, (_err) => {
      this.apiFail = true;
    });
  }

  formatAmount(value: number | string): string {
    const numberValue = typeof value === 'number' ? value : Number(value);
    return numberValue.toLocaleString("en-IN", { maximumFractionDigits: 0 });
  }
}