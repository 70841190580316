import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitiationGridComponent } from './irf-initiation-grid/irf-initiation-grid.component';
import { IrfInitiationDetailsComponent } from './irf-initiation-details/irf-initiation-details.component';
import { InitiationRoutingModule } from './initiation-routing.module';
import { BackofficeSharedModule } from 'app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { SvgModule } from 'app/backoffice-assets/svg.module';
import { CardModule } from 'primeng/card';
import { AlternateBillDetailsGridComponent } from './alternate-bill-details-grid/alternate-bill-details-grid.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmationService } from 'primeng/api';


@NgModule({
  declarations: [
    InitiationGridComponent,
    IrfInitiationDetailsComponent,
    AlternateBillDetailsGridComponent
  ],
  imports: [
    InitiationRoutingModule,
    CommonModule,
    BackofficeSharedModule,
    ReactiveFormsModule,
    DialogModule,
    SvgModule,
    CardModule,
    DialogModule,
    FileUploadModule,
    ImageCropperComponent,
    AccordionModule,
    InputSwitchModule,
    StepsModule,
    ToastModule,
    PdfViewerModule,
    InputNumberModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
  ],
  providers:[
    DialogModule,
    DialogService,
    ConfirmationService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InitiationModule { }
  