<div class="layout-content layout-content-noscroll">
  <p-messages class="custom-message"></p-messages>
  <div class="p-formgrid p-grid">
        <div class=" p-col-12 p-p-0">
            <div class="ui-fluid p-formgrid p-grid p-ai-center">
                <div class="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-pl-0 p-text-left">
                    <div class=" p-fluid p-field  p-mb-0  ">
                        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb"
                          (onItemClick)="homeBreadcrumbClicked($event)">
                      </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- date filter, and extract section -->
    <form [formGroup]="alternateKeyDetailsGridForm" autocomplete="off" novalidate>
      <div class="p-formgrid p-grid">
        <div class=" p-col-12 p-p-0">
          <div class="ui-fluid p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-between p-ai-center p-d-flex p-flex-column p-flex-md-row  p-p-0">
              <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-p-0 p-mb-2 p-md-mb-0 p-d-flex p-jc-start p-ai-center">
                <label class="p-text-bold p-mb-0"> {{ alternateKeyDetailsGridForm?.get('opportunityFilter').value }}</label>
                <p-badge [value]="gridDataList?.length" styleClass="businessWonCount-Badge"></p-badge>
              </div>
              <div class="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-p-0 p-d-flex p-jc-end p-mt-0 p-mr-0">
                <div class=" p-fluid p-field p-mr-2 p-col-4 p-pr-0 p-mb-2">
                  <!-- <p-calendar [disabled]="gridLoader" #globalCalendar [(ngModel)]="rangeDate" [showIcon]="true" id="gridCalender_id"
                    name="gridCalender_name" formControlName="gridCalender" placeholder="From-To" yearRange="2000:2030"
                    dataType="string" selectionMode="range" [readonlyInput]="true" rangeSeparator="to"
                    dateFormat="dd/mm/yy" [showButtonBar]="true" [hideOnDateTimeSelect]="true" dataType="string"
                    [stepHour]="1" [stepMinute]="1" (onSelect)="datePicker()" [showButtonBar]="true" [maxDate]="maxGlobalEndTime" [minDate]="minGlobalStartTime"
                    (onClearClick)="datePicker(true)" style="width:100%;"></p-calendar> -->
                </div>
                <div class=" p-fluid   p-field  p-d-flex p-jc-end p-flex-row p-pl-0 p-pr-0 p-mb-2">
                  <div  pTooltip="Global entity name search" (click)="showSearchCompanyPopUp()"  tooltipPosition="left" class="p-col-2 p-button-brand2 p-mr-2 p-d-flex p-ai-center" style="width : 36px; cursor: pointer">
                    <backoffice-svg [name]="'global-search-light'"></backoffice-svg>
                  </div>
                  
                  <button [disabled]="gridLoader" pButton class="p-col-2 p-button-brand2" icon="pi pi-filter" *ngIf="!filterDisplay"
                    pTooltip="Filter" tooltipPosition="bottom" (click)="showFilter()"></button>

                  <button [disabled]="gridLoader" pButton class="p-col-2 p-button-brand2" icon="pi pi-filter-slash"
                    *ngIf="filterDisplay" pTooltip="Clear Filter" tooltipPosition="bottom"
                    (click)="clear(mainGrid)"></button>
                    
                </div>
              </div>
            </div>
            <div class="p-col-12 p-sm-12 p-p-0 reportingScreenGridTable">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12 p-p-0">
                  <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-p-0 p-right">
                      <div class="p-field">
                        <div [ngClass]="{'content-section implementation': true}">
                          <p-table #mainGrid name="businessWonDataGrid" styleClass="p-datatable-gridlines backoffice-cust-scroll"
                            [value]="gridDataList" [paginator]="true" [showCurrentPageReport]="true" [rows]="10" sortMode="single" [scrollable]="true" scrollHeight="55vh" 
                            scrollDirection="both"
                            [rowsPerPageOptions]="[10, 25, 50]"              
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            >
                            <ng-template pTemplate="header">
                              <tr>
                                <th pSortableColumn='opportunityId' scope="col" style="max-width:160px;min-width:160px;">
                                  Opportunity ID
                                  <p-sortIcon field='opportunityId' ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                  </p-sortIcon>
                                </th>
                                <th pSortableColumn='agreementId' scope="col" style="max-width:160px;min-width:160px;">
                                  Agreement ID
                                  <p-sortIcon field='agreementId' ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                  </p-sortIcon>
                                </th>
                                <th pSortableColumn='companyName' scope="col" style="max-width:220px;min-width:220px;">
                                  Entity Name
                                  <p-sortIcon field='companyName' ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                  </p-sortIcon>
                                </th>
                                <th pSortableColumn='nexeraUser' scope="col" style="max-width:200px;min-width:200px;">
                                  Nexera User
                                  <p-sortIcon field='nexeraUser' ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                  </p-sortIcon>
                                </th>
                                <th pSortableColumn='bdManager' scope="col" style="max-width:200px;min-width:200px;">
                                  PA Approver
                                  <p-sortIcon field='bdManager' ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                  </p-sortIcon>
                                </th>
                                
                                <th pSortableColumn='opportunityStatus' scope="col" style="max-width:200px;min-width:200px;">
                                  Opportunity Status
                                  <p-sortIcon field='opportunityStatus' ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                  </p-sortIcon>
                                </th>
                                <th pSortableColumn='reportedDate' scope="col" style="max-width:200px;min-width:200px;">
                                  Date
                                  <p-sortIcon field='reportedDate' ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                  </p-sortIcon>
                                </th>
                                <th pSortableColumn='ageing' scope="col" style="max-width:150px;min-width:150px;">
                                  Ageing
                                  <p-sortIcon field='ageing' ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                  </p-sortIcon>
                                </th>
                                <th pSortableColumn='product' scope="col" style="max-width:200px;min-width:200px;">
                                  Product
                                  <p-sortIcon field='product' ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                  </p-sortIcon>
                                </th>
                                <th pSortableColumn='revenueStatus' scope="col" style="max-width:200px;min-width:200px;">
                                  Status
                                  <p-sortIcon field='revenueStatus' ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                  </p-sortIcon>
                                </th>
                              </tr>
                              <tr *ngIf="filterDisplayRow">
                                <th scope="col" class="no-bg" style="max-width:160px;min-width:160px;">
                                  <!-- <p-columnFilter class="p_columnFilter" field="oppIdByList" matchMode="in" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                      <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="oppIdList" 
                                      [showToggleAll]="false" placeholder="Any"
                                        (onChange)="filter($event.value)" [virtualScroll]="true" itemSize="40" optionLabel="name" formControlName="oppIdFilter"
                                        resetFilterOnHide="true" #oppIds>
                                        <ng-template let-rowData pTemplate="item" >
                                          <div class="">
                                            <span class="p-ml-1">{{ rowData.name }}</span>
                                          </div>
                                        </ng-template>
                                      </p-multiSelect>
                                    </ng-template>
                                  </p-columnFilter> -->
                                  <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'opportunityId', 'contains')" [value]="mainGrid.filters['opportunityId']?.value" placeholder="Search" class="p-column-filter">
                              
                              
                                </th>

                                <!-- Agreement Id -->
                                <th scope="col" class="no-bg" style="max-width:160px;min-width:160px;">
                                  <!-- <p-columnFilter class="p_columnFilter" field="aggIdByList" matchMode="in" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                      <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="agreementIdList" 
                                      [showToggleAll]="false" placeholder="Any"
                                        (onChange)="filter($event.value)" [virtualScroll]="true" itemSize="40" optionLabel="name"
                                        resetFilterOnHide="true" #oppIds>
                                        <ng-template let-rowData pTemplate="item" >
                                          <div class="">
                                            <span class="p-ml-1">{{ rowData.name }}</span>
                                          </div>
                                        </ng-template>
                                      </p-multiSelect>
                                    </ng-template>
                                  </p-columnFilter> -->
                                  <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'agreementId', 'contains')" [value]="mainGrid.filters['agreementId']?.value" placeholder="Search" class="p-column-filter">
                              
                              
                                </th>
                                
                                <!-- ENTITY NAME -->
                                <th scope="col" class="no-bg" style="max-width:220px;min-width:220px;">
                                  <!-- <p-columnFilter class="p_columnFilter" field="entityNamebyList" matchMode="in" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                      <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="nameList" placeholder="Any"
                                        (onChange)="filter($event.value)" optionLabel="name"
                                        [showToggleAll]="false" #oppName
                                        formControlName="entityNameFilter" [virtualScroll]="true" itemSize="40" resetFilterOnHide="true">
                                        <ng-template let-rowData pTemplate="item">
                                          <div class="">
                                            <span class="p-ml-1">{{ rowData.name }}</span>
                                          </div>
                                        </ng-template>
                                      </p-multiSelect>
                                    </ng-template>
                                  </p-columnFilter> -->
                                  <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'companyName', 'contains')" [value]="mainGrid.filters['companyName']?.value" placeholder="Search" class="p-column-filter">
                              
                              
                                </th>
  
                                <!-- NEXERA USER -->
                                <th scope="col" class="no-bg" style="max-width:200px;min-width:200px;">
                                  <!-- <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'nexeraUser', 'contains')" [value]="mainGrid.filters['nexeraUser']?.value" placeholder="Search" class="p-column-filter"> -->
                                  <p-columnFilter class="p_columnFilter" field="nexeraUserbyList" matchMode="in" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                      <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="nexeraUserList" [showToggleAll]="false" placeholder="Any"
                                        (onChange)="filter($event.value)" optionLabel="name" #oppUser
                                        formControlName="nexeraUserFilter" resetFilterOnHide="true">
                                        <ng-template let-rowData pTemplate="item">
                                          <div class="">
                                            <span class="p-ml-1">{{ rowData.name }}</span>
                                          </div>
                                        </ng-template>
                                      </p-multiSelect>
                                    </ng-template>
                                  </p-columnFilter>
                                </th>

                                <!-- BD MANAGER -->
                                <th scope="col" class="no-bg" style="max-width:200px;min-width:200px;">
                                  <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'bdManager', 'contains')" [value]="mainGrid.filters['bdManager']?.value" placeholder="Search" class="p-column-filter">
                              
                                  <!-- <p-columnFilter class="p_columnFilter" field="bdSpocbyList" matchMode="in" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                      <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="uniqbdSpocList" placeholder="Any"
                                        (onChange)="filter($event.value)" optionLabel="name" #oppBd [disabled]="disableFilter"
                                        formControlName="bdSpocFilter" resetFilterOnHide="true">
                                        <ng-template let-rowData pTemplate="item">
                                          <div class="">
                                            <span class="p-ml-1">{{ rowData.name }}</span>
                                          </div>
                                        </ng-template>
                                      </p-multiSelect>
                                    </ng-template>
                                  </p-columnFilter> -->
                                </th>
                              
                                <!-- BD SPOC -->
                                <!-- <th scope="col" class="no-bg" style="max-width:200px;min-width:200px;">
                                  <p-columnFilter class="p_columnFilter" field="bdSpocbyList" matchMode="in" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                      <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="uniqbdSpocList"  [showToggleAll]="false"placeholder="Any"
                                        (onChange)="filter($event.value)" optionLabel="name" #oppBd [disabled]="disableFilter"
                                        formControlName="bdSpocFilter" resetFilterOnHide="true">
                                        <ng-template let-rowData pTemplate="item">
                                          <div class="">
                                            <span class="p-ml-1">{{ rowData.name }}</span>
                                          </div>
                                        </ng-template>
                                      </p-multiSelect>
                                    </ng-template>
                                  </p-columnFilter> 
                                  
                                </th> -->

                                <!-- Opportunity status -->
                                <th scope="col" class="no-bg" style="max-width:200px;min-width:200px;">
                                  <p-columnFilter class="p_columnFilter" field="opportunityStatus" matchMode="equals" [showMenu]="false" class="p-w-100">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                      <p-dropdown [options]="opportunityStatusList" placeholder="Select"
                                        id="oppStatusMultiSelect"
                                        formControlName="opportunityFilter" [virtualScroll]="true" [panelStyle]="{ 'min-width': 'min(100vw, 10px)', 'max-height' : '20vh' }" [disabled]="true">
                                        <ng-template let-rowData pTemplate="item">
                                          <div class="">
                                            <span class="p-ml-1">{{ rowData.label }}</span>
                                          </div>
                                        </ng-template>
                                      </p-dropdown>
                                    </ng-template>
                                  </p-columnFilter>
                                </th>

                                <!-- Date -->
                                <th scope="col" class="no-bg" style="max-width:200px;min-width:200px;">
                                  <!-- <p-columnFilter class="p_columnFilter" field="datebyList" matchMode="in" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                      <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="uniqDateList" placeholder="Any"
                                        (onChange)="filter($event.value)" optionLabel="name" formControlName="dateFilter"
                                        resetFilterOnHide="true" #oppDate>
                                        <ng-template let-rowData pTemplate="item">
                                          <div class="">
                                            <span class="p-ml-1">{{ rowData.name }}</span>
                                          </div>
                                        </ng-template>
                                      </p-multiSelect>
                                    </ng-template>
                                  </p-columnFilter> -->
                                  <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'reportedDate', 'contains')" [value]="mainGrid.filters['reportedDate']?.value" placeholder="Search" class="p-column-filter">
                              
                                </th>

                                <!-- Ageing -->
                                <th scope="col" class="no-bg" style="max-width:150px;min-width:150px;">
                                  <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'ageing', 'contains')" [value]="mainGrid.filters['ageing']?.value" placeholder="Search" class="p-column-filter">
                              
                                  <!-- <p-columnFilter class="p_columnFilter" field="ageingbyList" matchMode="in" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                      <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="uniqAgeingList" placeholder="Any"
                                        (onChange)="filter($event.value)" optionLabel="name" #oppAgeing
                                        formControlName="ageingFilter" resetFilterOnHide="true">
                                        <ng-template let-rowData pTemplate="item">
                                          <div class="">
                                            <span class="p-ml-1">{{ rowData.name }}</span>
                                          </div>
                                        </ng-template>
                                      </p-multiSelect>
                                    </ng-template>
                                  </p-columnFilter> -->
                                </th>

                                <!-- product -->
                                <th scope="col" class="no-bg" style="max-width:200px;min-width:200px;">
                                  <!-- <p-columnFilter class="p_columnFilter" field="productbyList" matchMode="in" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                      <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="productList1" placeholder="Any"
                                        (onChange)="filter($event.value)" optionLabel="name" #oppProduct
                                        formControlName="productFilter" resetFilterOnHide="true">
                                        <ng-template let-rowData pTemplate="item">
                                          <div class="">
                                            <span class="p-ml-1">{{ rowData.name }}</span>
                                          </div>
                                        </ng-template>
                                      </p-multiSelect>
                                    </ng-template>
                                  </p-columnFilter> -->
                                  <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'product', 'contains')" [value]="mainGrid.filters['product']?.value" placeholder="Search" class="p-column-filter">
                                </th>

                                <!-- Status -->
                                <th scope="col" class="no-bg" style="max-width:200px;min-width:200px;">
                                  <!-- <p-columnFilter class="p_columnFilter" field="productbyList" matchMode="in" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                      <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="productList1" placeholder="Any"
                                        (onChange)="filter($event.value)" optionLabel="name" #oppProduct
                                        formControlName="productFilter" resetFilterOnHide="true">
                                        <ng-template let-rowData pTemplate="item">
                                          <div class="">
                                            <span class="p-ml-1">{{ rowData.name }}</span>
                                          </div>
                                        </ng-template>
                                      </p-multiSelect>
                                    </ng-template>
                                  </p-columnFilter> -->
                                  <input [disabled]="true" pInputText type="text" (input)="mainGrid.filter($event.target.value, 'status', 'contains')" [value]="mainGrid.filters['status']?.value" placeholder="Search" class="p-column-filter">
                                </th>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                              <tr style="cursor: pointer;" (click)="onOpportunityClick(rowData)">
                                <td style="max-width:160px;min-width:160px;">
                                  {{rowData.opportunityId}}
                                </td>
                                <td style="max-width:160px;min-width:160px;">
                                  {{rowData.agreementId}}
                                </td>
                                <td class="text-transform-capitalize p-text-nowrap p-text-truncate" style="max-width:220px;min-width:220px;">
                                  <div style="white-space: nowrap;max-width:200px; text-overflow: ellipsis; overflow: hidden;">
                                    <a (click)="getAlternateKeyDetails(rowData)">{{rowData.companyName}}</a>
                                  </div>
                                </td>
                                <td style="max-width:200px;min-width:200px;">
  
                                  {{rowData.nexeraUser}}
                                </td>
                                <td style="max-width:200px;min-width:200px;">
  
                                  {{rowData.bdManager}}
                                </td>
                                
                                <td style="max-width:200px;min-width:200px; white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:200px;">
                                  {{rowData.opportunityStatus}}
                                </td>
                                <td style="max-width:200px;min-width:200px;">
  
                                  {{rowData.reportedDate}}
                                </td>
                                <td style="max-width:150px;min-width:150px;">
  
                                  {{rowData.ageing}}
                                </td>
                                <td style="max-width:200px;min-width:200px; white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:200px;">
                                  {{rowData.product}}
                                </td>
                                <td style="max-width:200px;min-width:200px; white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:200px;">
                                  {{rowData.revenueStatus}}
                                </td>
                              </tr>
                            </ng-template>
                            
                            <ng-template pTemplate="emptymessage">
                              <tr>
                                <td colspan="10" style="background: white">

                                  <ng-container *ngIf="firstLoad">
                                    <div class="empmess" style="text-align: center; color: rgba(41, 41, 41, 0.6);">
                                      <div class="p-mb-3 p-mt-5 p-d-flex p-jc-center">
                                          <backoffice-svg [name]="'empty-state-light'"></backoffice-svg>
                                          <backoffice-svg [name]="'empty-state-dark'"></backoffice-svg>
                                        </div>
                                      <p>No data found for the selected filter criteria</p>
                                    </div>
                                  </ng-container>
                                </td>
                              </tr>
                            </ng-template>
                          </p-table>
                          <div class="no_data_div"
                            *ngIf="gridDataList.length==0 && gridLoader">
                            <div class="app-loading" id="loader">
                              <div class="loadbg"></div>
                              <div class="bdload">
                                <div class="spinner">
                                  <div class="rect1"></div>
                                  <div class="rect2"></div>
                                  <div class="rect3"></div>
                                  <div class="rect4"></div>
                                  <div class="rect5"></div>
                                  <span>Loading...</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
</div>


<div class="p-grid p-ml-0 p-mr-0 p-col-12 p-jc-between p-button-container">
  <div class="p-col-5 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-pl-0 left">
    <div class="btnwrap">
      <button pButton class="p-button-brand2" type="button"
        (click)="onInitiationBackBtn()" label="Back">
      </button>
    </div>
  </div>
</div>
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true" [style]="{width: '55vw', height: '20vh'}"
    [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>

<!-- search company name -->
<p-dialog header="Search Company" [(visible)]="searchCompanyPopUp" [modal]="true" [style]="{width: '31vw'}" [draggable]="false" [resizable]="false" [closable]="true" class="search-company">
<div class="btnwrap p-col-12 p-px-0">
  <div class="p-fluid p-w-100">
      <span class="p-float-label p-input-icon-left">
          <p-autoComplete id="search1" name="search1" placeholder="Search for Company"
              [suggestions]="companyNameList" field="companyName" [(ngModel)]="companyNameSearchValue"
              [ngClass]="{'show': showEntityLoader}" (onClear)="onClearSearch()"
              (onSelect)="onChangeCompanySelect($event)" (completeMethod)="loadCompanyList($event)"
              [showEmptyMessage]="emptyMsgFlag" [emptyMessage]="emptyMsgVal">
          </p-autoComplete>
          <i class="pi pi-search" aria-hidden="true"></i>
                                                      
        </span>
    </div>
</div>
<ng-template pTemplate="footer">
  <button [disabled]="!companyNameSearchValue" pButton (click)="reset()" label="Reset" class="p-button-info"></button>
  <button [disabled]="!companyNameSearchValue" pButton label="Apply" (click)="applyCompanySearch()" class="p-button-primary"></button>
</ng-template>
</p-dialog>

<!-- Add new branch popup -->
<p-dialog [header]="detailedViewForm?.get('title')?.value" #formDiv2 class="mPopup" [(visible)]="displayDetailedModal" [modal]="true" [style]="{width: '70vw'}"
  [draggable]="false" [resizable]="false" [closable]="true">
      <p-toast key="inside-modal" class="custom-toast"></p-toast>
    
      <div class="p-grid" [formGroup]="detailedViewForm">
          <div class="p-px-0 p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pb-0 p-text-left p-d-flex p-flex-column p-flex-md-row">
              <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 p-pr-sm-2 p-pr-0 p-pt-0 p-pb-0">
                  <div class="p-fluid p-field">
                      <label>
                          Realization Status
                      </label>
                      <div class="p-fluid  p-field p-mb-0">
                          <input [readonly]="true" id="popupBranchName" type="text" formControlName="realizationStatus" pInputText  class="p-inputtext" />
                      </div>
                  </div>
              </div>
              <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 p-pl-sm-2 p-pr-sm-2  p-pr-0 p-pt-0 p-pb-0">
                  <div class="p-fluid p-field">
                      <label>
                          Revenue Booking Date
                      </label>
                      <div class="p-fluid p-field p-mb-0">
                        <p-calendar [disabled]="true" [showIcon]="true" id="gridCalender_id"
                        name="gridCalender_name" dateFormat="dd-mm-yy" formControlName="revenueDate" placeholder="" [readonlyInput]="true" 
                        style="width:100%;"></p-calendar>
                      </div>
                  </div>
              </div>
              <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pr-0 p-pl-0 p-pr-0 p-pl-sm-2  p-pt-0 p-pb-0">
                  <div class="p-fluid p-field">
                      <label>
                          Comment<span class='invalid-feedback-astrik'>*</span>
                      </label>
                      <textarea 
                      pInputTextarea id="finComment" formControlName="comment" maxlength="1000" rows="3" cols="3" placeholder=""></textarea>
                  </div>
              </div>
          </div>
          <div class="p-px-0 p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pt-0 p-pb-0 p-text-left p-d-flex p-pt-0 p-pb-0 p-flex-column p-flex-md-row">
              <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 p-pr-sm-2 p-pr-0 p-pt-0 p-pb-0">
                  <div class="p-fluid p-field">
                      <label>
                          Action<span class='invalid-feedback-astrik'>*</span>
                      </label>
                      <div>
                        <p-checkbox
                          [disabled]="!isEditForm"
                          binary="true"
                          formControlName="action" class="p-mb-1" inputId="approve_id">
                        </p-checkbox>
                        <label class="p-ml-1 p-mt-1 p-mb-0" for="approve_id">Approve</label>
                      </div>
                  </div>
              </div>
            </div>
            <div class="p-px-0 p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pt-0 p-pb-0 p-text-left p-d-flex p-pt-0 p-pb-0 p-flex-column p-flex-md-row">
              <div [ngClass]="{'p-px-0 p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 p-pr-0 p-pt-0 p-pb-0' : fileItem}">
              
                <div class="p-fluid p-field">
                    <label>
                        Attachment<span class='invalid-feedback-astrik'>*</span>
                    </label>
                    <div class="p-fluid p-field p-mb-0">
                      <button *ngIf="!fileItem" type="button" [disabled]="!isEditForm" (click)="showAttachmentModal()" pButton class="p-button-secondary p-ml-0" label="Add">
                      </button>

                      <!-- card view for attachment -->
                      <div *ngIf="fileItem" class="card-attachment p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-wrap">
                        <div class="p-w-100 p-col-12 p-pl-0">
                            <p-card>
                                <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                        <div style="padding-right: 10px;">
                                                <img alt="" *ngIf="fileItem.fileContentType == 'image/png' || fileItem.fileContentType == 'image/jpg' || fileItem.fileContentType == 'image/jpeg'" class="cursor-pointer" (click)="previewAttachment(item)" [src]="imgThumb">
                                                <img alt="" *ngIf="fileItem.fileContentType ==='application/pdf' " (click)="previewAttachment(item)" class="cursor-pointer" [src]="pdfThumb" />
                                                <img alt="" *ngIf="fileItem.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileItem.fileContentType == 'application/msword' " (click)="previewAttachment(item)" class="cursor-pointer" [src]="wordThumb" />
                                                <img alt="mail-thumbnail" class="uploadedThumbnail"
                                                  *ngIf="fileItem.fileContentType == ''"
                                                  [src]="mailThumb"
                                                  alt="">
                                        </div>
                                        <div class="p-col-8 p-pr-0 p-pl-0 p-d-flex p-flex-column p-flex-wrap">
                                            <a class="cursor-pointer limit-text-length" [ngClass]="{'attachmentName ' : fileItem.fileId}" (click)="previewAttachment()">
                                                {{fileItem.fileName}}</a>
                                            <label class="attachmentFileType">Type:
                                                {{fileItem.fileTypeName}}</label>
                                        </div>
                                        <div class="p-col-2 p-pr-0 p-d-flex p-jc-end p-ai-center" *ngIf="isEditForm">
                                                <i (click)="onDeleteFile()" class="pi pi-trash cursor-pointer attachmentIcon" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </p-card>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <ng-template pTemplate="footer">
        <div class="p-jc-between p-d-flex">
          <button _ngcontent-xuq-c136="" pbutton="" type="button" (click)="onPreviewScreen()" pButton
            class="p-ml-0 p-element p-button-secondary p-mr-2 p-button p-component" label="Preview data"></button>
          <div>
            <button pButton type="button" (click)="detailedViewClose()" label="Cancel" class="p-button-brand2"></button>
            <button pButton type="button" [disabled]="detailedViewForm.invalid || !isEditForm" (click)="saveDetails()"
              label="Save" class="p-button-primary p-mr-0"></button>
          </div>
        </div>
      </ng-template>
</p-dialog>

<!-- attachment preview -->

<p-dialog [header]="fileItem?.fileName" [(visible)]="displayPreview"
  class="docPreview previewModal modal" [modal]="true" [style]="{width: '95vw', height: '100vh'}" [draggable]="false"
  [resizable]="false">
<attachment-preview [url]="urlSafe" [isPdf]="isPdf" [file]="fileItem"></attachment-preview>

<ng-template pTemplate="footer">
  <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
  <button pButton type="button" label="Download" class="p-button-secondary"
      (click)="downloadFile()"></button>
</ng-template>

</p-dialog>

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="previewFailed" [modal]="true"
  [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
  <p>Unable to preview attachment, Please try again.</p>
</p-dialog>

<!-- case closed dialog -->
<p-dialog header="Alert" [(visible)]="caseClosePopUp" [modal]="true" [style]="{width: '30vw'}" [draggable]="false"
    [resizable]="false">
    <div class="p-col-12 p-px-1" id="center">
        <p class="p-mb-0">This case is marked as case closed</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="applyCompanySearch()" type="button"
                label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog>