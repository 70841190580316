import { Component, OnInit, ViewChild, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Table } from 'primeng/table';
import { PagesService } from 'app/pages/pages.service';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'backoffice-by-client',
  templateUrl: './by-client.component.html',
  styleUrls: ['./by-client.component.scss']
})
export class ByClientComponent implements OnInit {
  archival_data_list: any;
  @ViewChildren('byClientMainGrid') byClientMainGrid;

  qcStatusList = [
    { label: 'Approved', value: 'Approved' },
    { label: 'Rejected - Sent To BD Manager By Approver', value: 'Rejected - Sent To BD Manager By Approver' },
    { label: 'Re-Submitted For QC', value: 'Re-Submitted For QC' },
    { label: 'Submitted To Quality Approver For Approval', value: 'Submitted To Quality Approver For Approval' },
    { label: 'Not Started', value: 'Not Started' },
  ];

  ccprimebreadcrumbList = [
    {
      label: `Archival Data Listing`, routerLink: ['/agreement-archive/byclient']
    },
  ]

  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  transformedData: any[] = [];

  //grid
  byClientGridForm = this.fb.group({
    initiationFilter: [null, []],
    globalCalendar: [null as any, []]
  });
  gridLoader: boolean = false;
  filterDisplayRow: boolean = true;

  minGlobalStartTime = new Date();
  maxGlobalEndTime = new Date();

  globalStartTime = null;
  globalEndTime = null;

  loadingStatus = false;
  apiFail = false;
  displayProcessingModal = false;
  loggedInUserEmpId: any;

  irfPullPushForm = this.fb.group({
    companyNameSearchValue: [null, []],
    globalSearchInputArchival: [null, Validators.minLength(3)],


  });
  globalSearchSuggestions: any[] = [];
  globalSearchSuggestionsCode: any[] = [];
  globalSearchInputArchival: FormControl = new FormControl(null);
  globalSearchedCompanyCode: string = null;
  apiFailStatusSelection: boolean = false;
  invoiceDataList: any[] = [];
  isExpanded: boolean = false;
  companyNameSearchValue: any;

  formattedDate: string;
  currentDate = new Date();

  fetchedAPIData: any;
  timer: any;
  openedFileName: any;

  assignmentPopup: boolean = false;
  displayPreview: boolean = false;
  assignmentDetailObj: any;
  mandateFileName: any;
  urlSafe: any;
  zoomValue: number = 1;
  rotate: number = 0;
  @ViewChild('globalCalendar') globalCalendar: any;

  constructor(private router: Router, private http: HttpClient, private fb: FormBuilder, private cd: ChangeDetectorRef, private pagesService: PagesService, private encryptDecryptInterceptor: EncryptDecryptInterceptor, private datePipe: DatePipe) {
    this.formattedDate = this.datePipe.transform(this.currentDate, "MM/dd/yyyy")
  }

  ngOnInit(): void {
    let payload = {
      lastYear: "",
      viewAll: ""
    };
    this.getArchivalData(payload);
  }

  getArchivalData(payload): void {
    this.pagesService.getArchivalData(payload).subscribe((results: any) => {
      results.forEach(el => {
        el.assignmentDetails = 'View Details'
      });
      this.archival_data_list = results;
      console.log("ArchivalDataList...",this.archival_data_list)
        this.apiFail = false;
      }, (_error) => {
        this.apiFail = true;
      });
  }

  search(event: any) {
    this.globalBalSearchDataSuggestions(event.query);

  }

  globalBalSearchDataSuggestions(searchQuery) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      let searchPayload = {
        companyName: searchQuery,
      };
      console.log(searchQuery);
      if (searchQuery == "") {
        this.getArchivalData("");
      }
      else {

        this.pagesService.getArchivalDataGlobalSearch(searchPayload).subscribe({
          next: (searchedData: any) => {
            console.log(searchedData);
            this.archival_data_list = searchedData;


          },
          error: (err) => {
          }
        });
      }
    }, 1000);

  }

  setListData(sfCaseDetails: Array<any>) {
    this.invoiceDataList = sfCaseDetails;
  }
  toggleSearch() {
    this.isExpanded = !this.isExpanded;
    if (!this.isExpanded) {
      this.companyNameSearchValue = ''; // Clear the search query when collapsing
    }
  }

  checked = false;
  viewLast12Months() {

    this.checked = !this.checked;
    if (this.checked == true) {
      let payload = {
        lastYear: this.formattedDate,
        viewAll: ""
      };
      this.getArchivalData(payload);
    } else {
      let payload = {
        lastYear: "",
        viewAll: ""
      };
      this.getArchivalData(payload);
    }
  }

  checkedAgreement = false;
  viewAllAgreements() {
    this.checkedAgreement = !this.checkedAgreement;
    if (this.checkedAgreement == true) {
      let payload = {
        lastYear: "",
        viewAll: "view"
      };
      this.getArchivalData(payload);
    } else {
      let payload = {
        lastYear: "",
        viewAll: ""
      };
      this.getArchivalData(payload);
    }
  }

  homeBreadcrumbClicked(event): void {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  onBackClick(): void {
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  goToAgreementDetails(agreementId): void {
    this.router.navigate(['/agreement-archive/agreementdetails/', agreementId]);
  }

  exportExcel() { }

  onClearGlobalDate(): void {
    if (this.globalStartTime && this.globalEndTime) {
    }
    this.globalEndTime = undefined;
    this.globalStartTime = undefined;
    this.byClientGridForm.patchValue({ globalCalendar: null });
  }

  filterGlobalTableByDate(): void {
    const startTime = this.byClientGridForm.value.globalCalendar[0]?.split("/").reverse().join("-");
    const endTime = this.byClientGridForm.value.globalCalendar[1]?.split("/").reverse().join("-");
    if (this.globalEndTime) {
      this.globalStartTime = startTime;
      this.globalEndTime = undefined;
    } else {
      this.globalStartTime = startTime;
      this.globalEndTime = endTime;
    }
    if (this.globalStartTime && this.globalEndTime) {
      this.globalCalendar.overlayVisible = false;
    }
  }

  toggleFilter(table: Table) {
    this.filterDisplayRow = !this.filterDisplayRow;
    this.cd.detectChanges();
    if (this.filterDisplayRow === false) {
      table?.reset();
      if (this.byClientMainGrid?.first?.filters) { // reset all filters
        Object.values(this.byClientMainGrid.first.filters).map(item => {
          (<any>item).value = null;
          return item;
        });
      }
      this.byClientGridForm.patchValue({ initiationFilter: null });
      this.byClientGridForm.patchValue({ globalCalendar: null });
    }
  }
  
  assignmentDetails(id){
    this.pagesService.getAssignmentData(id).subscribe((res) => {
      this.assignmentPopup = true;
      this.assignmentDetailObj = res;
      this.mandateFileName = this.assignmentDetailObj?.mandateAttachments[0]?.attachment?.fileName;
    }, (_err) => {
      this.apiFail = true;
      this.assignmentPopup = false;
    });
  }

  previewAttachment(path,name){
    this.openedFileName = name;
    this.displayPreview = true;
    let mime = 'data:application/pdf;base64,';
    this.urlSafe = `${mime}${path}`;
  }
  
  downloadFile() {
    let linkSource: any;
    linkSource = this.urlSafe;
    
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this.openedFileName.split('.')[0];
    downloadLink.click();
  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
  }

  showAgreementDetailsMulipleAttachmentPreview(filepath, filename){
    this.openedFileName = filename;
    this.apiFail = false;
    this.displayPreview = true;
    let mime: any;
    let base64: any;
    base64 = filepath
    mime = 'data:application/pdf;base64,'
    this.urlSafe = `${mime}${base64}`
    console.log("pdfurl...", this.urlSafe)
  }

}
