import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountService } from 'app/core/auth/account.service';
import { delay, take, retryWhen, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { USERMANAGEMENT_SERVER_API_URL, SERVER_API_INTEGRATION_URL, BACKOFFICE_SERVER_API_URL, NEXERA_SERVER_API_URL, FILEUPLOAD_URL, MASTER_SERVICE_URL, SERVER_API_URL} from "../app.constants";
import { BYPASS_LOADER } from 'app/blocks/interceptor/request-loader.interceptor';

@Injectable({
  providedIn: 'root'
})
export class SfBillingService {

  key: any;
  iv: any;
  empId: any;
  private userId: any;
  sharedMsgString: string="";
  constructor(private accountService: AccountService, private http: HttpClient, private encDec: EncryptDecryptInterceptor) {
    this.empId = localStorage.getItem('empid');
    this.empId = this.encDec.decrypt(this.empId);
  }

  getOverallEstimatesApi(payload:any){
    const headersList = new HttpHeaders();
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/estimation/overallEstimates`, payload, { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getFileHistoryApi(){
    const headersList = new HttpHeaders();
    return this.http.get(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/estimation/history`, { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getFileDownloadApi(payload:any){
    const headersList = new HttpHeaders();
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/estimation/estimatesFileDownload`, payload, { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getSfBillingEstimationStatus():Observable<any>{
    const headersList = new HttpHeaders();
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+'api/v1/sfbilling/estimation/status', { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getSfBillingEstimationStatusRecheck():Observable<any>{
    const headersList = new HttpHeaders();
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+'api/v1/sfbilling/estimation/status', { headers: new HttpHeaders(), context: new HttpContext().set(BYPASS_LOADER, true) });
  }

  getSfEntityStatus():Observable<any>{
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL+'api/v1/integration/findDataByCombineMaster/SF Entity Status', { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getClientType():Observable<any>{
    const headersList = new HttpHeaders();
    return this.http.get(BACKOFFICE_SERVER_API_URL+'api/v1/irfinitiation/getAllclientType', { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getAssignmentFamily():Observable<any>{
    const headersList = new HttpHeaders();
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+'api/v1/masters/assignmentfamily', { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getFinancialYear():Observable<any>{
    const headersList = new HttpHeaders();
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+'api/v1/masters/financialyear', { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }
  
  getBDOwnerNames(payload:any) :Observable<any>{
    let filterPayload = {
      nameStartChar: payload
    }
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/hrms/getBdEmployeeDetails`, filterPayload).pipe(debounceTime(300),distinctUntilChanged());
  }

  sfBillUnmappedAgreements(payload) :Observable<any>{
    const encpayload = this.encDec.encryptWith(payload);
    return this.http.get(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/edit/agreements/unmapped?sfBillId=${encpayload}`);
  }
  
  sfBillDummyAgreements(payload) :Observable<any>{
    const encpayload = this.encDec.encryptWith(payload);
    return this.http.get(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/edit/agreements/dummy?sfBillId=${encpayload}`);
  }

  sfBillDetails(paylaod) :Observable<any>{
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + 'api/v1/sfbilling/edit/getSfBillDetails', paylaod)
  }

  saveSfBill(paylaod) :Observable<any>{
    return this.http.post (USERMANAGEMENT_SERVER_API_URL + 'api/v1/sfbilling/edit', paylaod);
  }

  combineMasterList(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/CombineMasters')
  }

  productList(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/Products`);
  }

  instrumentList(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/Instrument`);
  }

  uploadSingleAttachment(payload): Observable<any> {
    return this.http.post(NEXERA_SERVER_API_URL + 'api/v1/attachment/upload', payload);
  }

  deleteAttachmentById(id): Observable<any> {
    const encPayload = this.encDec.encryptWith(id);
    return this.http.get(NEXERA_SERVER_API_URL + `api/v1/attachment/delete?fileId=${encPayload}`);
  }

  getBase64(paylaod): Observable<any> {
    return this.http.post(FILEUPLOAD_URL + 'findPdfById', paylaod);
  }

  getAttachmentByModule(paylaod): Observable<any> {
    const encOppid = this.encDec.encryptWith(paylaod.oppId);
    const encmoduleName = this.encDec.encryptWith(paylaod.moduleName);
    return this.http.get(NEXERA_SERVER_API_URL + `api/v1/attachment/by/module?opportunityId=${encOppid}&moduleName=${encmoduleName}`);
  }
  
  saveSendToBDSfEdits(payload: any): Observable<any> {
    // Make API call to save cards
    console.log(payload);
    return;
  }
  
  // getBDOwnerNames(payload:any){
  //   let filterPayload = {
  //     nameStartChar: payload
  //   }
  //   return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/hrms/getHrmsEmployeeDetails`, filterPayload);
  // }
  
  sfGenerateBillImportExcelValidationStatus(payload:any){
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/generation/validate`, payload);
  }
  
  sfGenerateBillApiImport(payload:any){
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/generation/sfBill/generation`, payload);
  }
  
  sfGenerateBillApiImportStatus(){
    return this.http.get(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/generation/import/status`);
  }
  
  sfGenerateBillApiImportStatusRecheck(){
    return this.http.get(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/generation/import/status`, { headers: new HttpHeaders(), context: new HttpContext().set(BYPASS_LOADER, true) });
  }
  
  getSFBillGenerationStatus(){
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+'api/v1/sfbilling/generation/extract/status');
  }
  
  getSFBillGenerationStatusRecheck(){
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+'api/v1/sfbilling/generation/extract/status', { headers: new HttpHeaders(), context: new HttpContext().set(BYPASS_LOADER, true) });
  }
  
  sfGenerateBillApiExport(payload:any){
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/generation/estimation/export`, payload);
  }

  sfEditAndBdApprovalGridApi(payload:any){
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/edit/filter/grid`, payload);
  }

  getCompanyNames(query:string){
    let payload= {companyName: query}
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/edit/filter/companyName`, payload);
  }

  getSfBillId(id:string){
    let payload= {sfBillId: id}
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/edit/filter/sfBillId`,payload);
  }

  getBdApprovalDetails(id:string){
    let payload= {"companyName": null, "financialYear": null, "sfBillId": id, "billStatus": null}
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/edit/getSfBillDetails`, payload);
  }

  getRejectedReasonDropdown(){
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/SF Bill Rejected Reason`);
  }

  getBillStatusMaster(){
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/SF%20Bill%20Status`);
  }

  getProductMaster(){
    return this.http.get(MASTER_SERVICE_URL + `api/masters/getSingleMasterData/Products`);
  }

  getInstrumentMaster(){
    return this.http.get(MASTER_SERVICE_URL + `api/masters/getSingleMasterData/Instrument`);
  }

  onRejectBdApproval(payload){
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/bdapproval/submitBdAction`, payload);
  }

  onApproveBdApproval(payload){
    // let payload= {"companyName": null, "financialYear": null, "sfBillId": id, "billStatus": null}
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/bdapproval/getBillStatusApproved`, payload);
  }
  
  singleFileUpload(payload): Observable<any> {
    return this.http.post(NEXERA_SERVER_API_URL + 'api/v1/attachment/upload', payload);
  }
  
  getExportBdApproval(payload): Observable<any> {
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + 'api/v1/sfbilling/bdapproval/export', payload);
  }
  
  getAttachmentsByOpportunityIdAndModule(oppId,modName): Observable<any>{
    const oppIdEncrypted = this.encDec.encryptWith(oppId);
    const modNameEncrypted = this.encDec.encryptWith(modName);
    return this.http.get(NEXERA_SERVER_API_URL + `api/v1/attachment/by/module?opportunityId=${oppIdEncrypted}&moduleName=${modNameEncrypted}`);
  }

  getLotNumOnFinancialYear(fy){
    console.log("Financial year for lot num",fy);
    const encpayload = this.encDec.encryptWith(fy);
    console.log("encrypted financial year",encpayload)
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+`api/v1/sfbilling/track/filter/lots?financialYear=${encpayload}`);
  }

  
  sfBillTrackingGridApi(payload:any){
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/track/listing`, payload);
  }

  getAgreementsBySfBillId(payload){
    console.log("Financial year for lot num",payload);
    const encpayload = this.encDec.encryptWith(payload);
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+`api/v1/sfbilling/track/agreements?sfBillId=${encpayload}`);
  }

  sfBillTrackingupdateStatus(payload:any){
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/track/updateStatus`, payload);
  }

  downloadFile(item: any, urlSafe) {
    const link = document.createElement('a');
    link.href = urlSafe;
    link.download = item?.fileName;
    link.click();
  }

  updateFinanceStatus(payload){
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbill/finance/update`, payload);
  }

  getFinanceExport(payload): Observable<any>{
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + 'api/v1/sfbill/finance/extract',payload);
  }

  convertToBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  importFinanceData(payload): Observable<any>{
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + 'api/v1/sfbill/finance/import',payload);
  }

  // On behalf user story
  getUserList(payload){
    return this.http.post(BACKOFFICE_SERVER_API_URL+ `api/v1/backoffice/hiearachy/getUserListByStartWith`,payload);
  }

  getOnBehalfOfList(permission: any): Observable<any> {
    const headersList = new HttpHeaders();
    var empId = this.encDec.encryptWith(this.empId);
    permission = this.encDec.encryptWith(permission);
    return this.http.get(
      BACKOFFICE_SERVER_API_URL+ `api/v1/backoffice/hiearachy/getUserPermissionList/` + permission + `/` + empId,
      { headers: headersList }
    );
  }

  // getOnBehalfOfUserData(onBehalfOfEmpId: any): Observable<any> {
  //   const headersList = new HttpHeaders();
  //   onBehalfOfEmpId = this.encDec.encryptWith(onBehalfOfEmpId);
  //   return this.http.get(SERVER_API_URL + `/api/v1/entity/findAllByUser/` + onBehalfOfEmpId, { headers: headersList, params: { startDate: "", endDate: "" }  });
  // }

  getUserIdonBehalfOf(onBehalfOfUserId: any): any {
    let userId: any;
    if (onBehalfOfUserId != null || onBehalfOfUserId != undefined) {
      userId = onBehalfOfUserId;
    }
    else {
      userId = this.empId;
  
    }
    return userId;
  }

  // gettable2DataViewEntity(userId?: any, startDate?: any, endDate?: any): Observable<any> {
  //   const headersList = new HttpHeaders();
  //   userId = this.encDec.encryptWith(userId);
  //     return this.http.get(SERVER_API_URL + `/api/v1/entity/findAllByUser/` + userId, { headers: headersList, params: { startDate: startDate, endDate: endDate } });  
  // }

  // getOnBehalfUserDetails(payload:any){
  //   const headersList = new HttpHeaders();
  //   return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/edit/filter/grid`, payload, { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  // }
  getFeeCapFinancialYear(){
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+'api/v1/masters/feecap/financialyear');
  }

  getFeeCapCompanyDetails(payload){
    const encpayload = this.encDec.encryptWith(payload);
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+`api/v1/sfbilling/feecap/filter/companyName?companyName=${encpayload}`);
  }

  getAgreementListByCompanyCode(payload){
    const encpayload = this.encDec.encryptWith(payload);
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+`api/v1/sfbilling/feecap/agreement/details?companyCode=${encpayload}`);
  }

  generateFeeCapBill(payload){
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + 'api/v1/sfbilling/feecap/generate',payload);
  }
}
