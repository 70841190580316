import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { InitiationGridComponent } from './irf-initiation-grid/irf-initiation-grid.component';
import { AlternateBillDetailsGridComponent as AlternateBillDetailsGridComponent } from './alternate-bill-details-grid/alternate-bill-details-grid.component';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { AlternateBillingScopeAuth } from 'app/core/auth/alternate-billing-scope-auth.service';
import { ReportingScopeAuthService } from 'app/core/auth/reporting-scope-auth.service';

const routes: Routes = [
    {
        path: 'irf',
        component: InitiationGridComponent,
        data: {
            authorities: [],
            pageTitle: 'NexEra 2.0'
        },
        canActivate: [UserRouteAccessService, ReportingScopeAuthService]
    },
    {
        path: 'alternate-bill-details',
        component: AlternateBillDetailsGridComponent,
        data: {
            authorities: [],
            pageTitle: 'NexEra 2.0'
        },
        // canActivate: [UserRouteAccessService, AlternateBillingScopeAuth]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class InitiationRoutingModule {}