import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AllocationScopeAuthService {

  constructor(private encryptDecryptInterceptor : EncryptDecryptInterceptor, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    let scopes = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    if (scopes.indexOf('IRF_ALLOCATION_READ') != -1 || scopes.indexOf('IRF_ALLOCATION_UPDATE') != -1 ||
    scopes.indexOf('SF_ALLOCATION_READ') != -1 || scopes.indexOf('SF_ALLOCATION_UPDATE') != -1) {
      return true;
    } else {
      this.router.navigate(['/empty']);
      return false;
    }
  }
}
