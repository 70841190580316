<div *ngIf="listItems?.length > 0" class="p-d-flex p-jc-end p-mr-1 p-mb-2">
    <button pButton 
        class="p-button-secondary p-button-sm"
        label="Approve All"
        [disabled]="!isApproveAllEnabled"
        (click)="approveAllPayments()">
    </button>
                                
</div>
<ng-container *ngIf="listItems?.length > 0">
    <ng-container *ngFor="let item of listItems;let i=index; trackBy: trackChange">
        <!-- payment item -->
        <form class="main-payment p-ai-center p-m-1" [formGroup]="item" >
            <h1 class="p-medium display-4 p-px-3">Payment {{i + 1}}</h1>
            <div class="p-grid p-mx-2">
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Payment Type : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.paymentType}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Payment Mode : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.paymentMode}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">NEFT / Cheque No : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.chequeNo}}</h1>
                </div>
                
            </div>

            <div class="p-grid p-mx-2">
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Bank : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.bank}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Payment Date : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.paymentDate}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">TDS Type : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.tdsType}}</h1>
                </div>
            </div>

            <div class="p-grid p-mx-2">
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">TDS Amount : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.tdsAmount}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Amount Collected : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.amount}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Gross Amount : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.grossAmount}}</h1>
                </div>
            </div>

            <!-- payment approve/rej button -->
            <div class="p-grid p-mx-2 p-my-2">
                <div class="p-col-4">
                        <div class="p-d-flex">
                            <div [ngClass]="{'p-formgroup-inline approve-radio' : true, 'active' :  item.get('paymentApprove' + i).value === 'Approved'}">
                                <div class="field-radiobutton p-radiobutton-xs">
                                    <p-radioButton  [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1" [formControlName]="'paymentApprove' + i" value="Approved" (onClick)="onApprove(true, i, 'paymentApprove' + i, 'paymentComment')" [inputId]="i"
                                    ></p-radioButton>
                                    <label class="cursor-pointer p-ml-1 p-mb-0 label_light" [for]="i">Approve</label>
                                    
                                </div>
                            </div>
                        
                
                            <div [ngClass]="{'p-formgroup-inline reject-radio p-ml-3' : true, 'active' :  item.get('paymentApprove' + i).value === 'Rejected'}">
                                <div class="field-radiobutton p-radiobutton-xs">
                                    <p-radioButton  [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1 p-radioButton-sm" [formControlName]="'paymentApprove' + i" value="Rejected" (onClick)="onApprove(false, i, 'paymentApprove' + i, 'paymentComment')" [inputId]="i +'identity'"
                                     ></p-radioButton>
                                    <label class="p-ml-1 p-mb-0 cursor-pointer label_light" [for]="i +'identity'">Reject</label>                                       
                                </div>
                            </div>
                        </div>
                        <div class="invalid-feedback" *ngIf="item.get('paymentApprove' + i)?.touched && item.get('paymentApprove' + i)?.errors?.required && item.get('paymentApprove' + i)?.invalid">
                           This field is required
                        </div>
                </div>


                <div class="p-col-8 p-d-flex">
                    <div class="p-col-12 p-p-0">
                        <textarea [attr.disabled]="!!item.get('isDisabled')?.value?true:null" [ngClass]="{'error':(item.get('paymentComment')?.touched && (item.get('paymentComment')?.errors?.required || item.get('paymentComment')?.errors?.minlength) && item.get('paymentComment')?.invalid)}"
                        pInputTextarea id="comment"  maxlength="500" formControlName="paymentComment" rows="1" cols="3" placeholder="Enter your comment here"></textarea>
                        
                
                        <div class="invalid-feedback" *ngIf="item.get('paymentComment')?.touched && item.get('paymentComment')?.errors?.required && item.get('paymentComment')?.invalid">
                            Comment is required
                        </div>

                        <div class="invalid-feedback" *ngIf="item.get('paymentComment')?.touched && !item.get('paymentComment')?.errors?.required && item.get('paymentComment')?.errors?.minlength && item.get('paymentComment')?.invalid">
                            Please enter atleast 3 characters
                        </div>
                    </div>
                </div>
                
            </div>

            <!-- divider -->
            <div style="border-top: 1px solid #cfcdcd;" class="p-mx-3 p-my-3"></div>

            <!-- cheque attachment -->
            <div class="p-grid p-mx-2 p-ai-center">
                <div class="p-col-5">
                    <label class="label_light">Cheque Attachment : </label>
                </div>
                <div class="p-col-3">
                        <div class="p-d-flex">
                            <div [ngClass]="{'p-formgroup-inline approve-radio' : true, 'active' : item.get('chequeApprove' + i).value === 'Approved'}">
                                <div class="field-radiobutton p-radiobutton-xs">
                                    <p-radioButton  [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1" [formControlName]="'chequeApprove' + i" value="Approved" (onClick)="onApprove(true, i, 'chequeApprove' + i, 'chequeAttachmentComment')" [inputId]="i + 'cheque'"
                                    ></p-radioButton>
                                    <label class="cursor-pointer p-ml-1 p-mb-0 label_light" [for]="i + 'cheque'">Approve</label>
                                    
                                </div>
                            </div>
                        
                
                            <div [ngClass]="{'p-formgroup-inline reject-radio p-ml-3' : true, 'active' : item.get('chequeApprove' + i)?.value === 'Rejected'}">
                                <div class="field-radiobutton p-radiobutton-xs">
                                    <p-radioButton  [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1 p-radioButton-sm" [formControlName]="'chequeApprove' + i" value="Rejected" (onClick)="onApprove(false, i, 'chequeApprove' + i, 'chequeAttachmentComment')" [inputId]="i +'chequeReject'"
                                     ></p-radioButton>
                                    <label class="p-ml-1 p-mb-0 cursor-pointer label_light" [for]="i +'chequeReject'">Reject</label>                                       
                                </div>
                            </div>
                        </div>

                        <div class="invalid-feedback" *ngIf="item.get('chequeApprove' + i)?.touched && item.get('chequeApprove' + i)?.errors?.required && item.get('chequeApprove' + i)?.invalid">
                           This field is required
                        </div>
                </div>


                <div class="p-col-4 p-d-flex">
                    <div class="p-col-12 p-p-0">
                        <textarea  [attr.disabled]="!!item.get('isDisabled')?.value ? true : null" [ngClass]="{'error':(item.get('chequeAttachmentComment')?.touched && (item.get('chequeAttachmentComment')?.errors?.required || item.get('chequeAttachmentComment')?.errors?.minlength) && item.get('chequeAttachmentComment')?.invalid)}"
                        pInputTextarea id="comment" formControlName="chequeAttachmentComment" rows="1" cols="3" placeholder="Enter your comment here" maxlength="500"></textarea>
                        
                
                        <div class="invalid-feedback" *ngIf="item.get('chequeAttachmentComment')?.touched && item.get('chequeAttachmentComment')?.errors?.required && item.get('chequeAttachmentComment')?.invalid">
                            Comment is required
                        </div>

                        <div class="invalid-feedback" *ngIf="item.get('chequeAttachmentComment')?.touched && !item.get('chequeAttachmentComment')?.errors?.required && item.get('chequeAttachmentComment')?.errors?.minlength && item.get('chequeAttachmentComment')?.invalid">
                            Please enter atleast 3 characters
                        </div>
                    </div>
                </div>
                
            </div>

            <!-- deposit slip -->
            <div class="p-grid p-mx-2 p-ai-center p-my-1">
                <div class="p-col-5">
                    <label class="label_light">Deposit Slip : </label>
                </div>
                <div class="p-col-3">
                        <div class="p-d-flex">
                            <div [ngClass]="{'p-formgroup-inline approve-radio' : true, 'active' : item.get('depositApprove' + i).value === 'Approved'}">
                                <div class="field-radiobutton p-radiobutton-xs">
                                    <p-radioButton  [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1" [formControlName]="'depositApprove' + i" value="Approved" (onClick)="onApprove(true, i, 'depositApprove' + i, 'depositSlipComment')" [inputId]="i + 'deposit'"
                                    ></p-radioButton>
                                    <label class="cursor-pointer p-ml-1 p-mb-0 label_light" [for]="i + 'deposit'">Approve</label>
                                    
                                </div>
                            </div>
                        
                
                            <div [ngClass]="{'p-formgroup-inline reject-radio p-ml-3' : true, 'active' : item.get('depositApprove' + i)?.value === 'Rejected'}">
                                <div class="field-radiobutton p-radiobutton-xs">
                                    <p-radioButton  [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1 p-radioButton-sm" [formControlName]="'depositApprove' + i" value="Rejected" (onClick)="onApprove(false, i, 'depositApprove' + i, 'depositSlipComment')" [inputId]="i + 'depositRej'"
                                     ></p-radioButton>
                                    <label class="p-ml-1 p-mb-0 cursor-pointer label_light" [for]="i + 'depositRej'">Reject</label>                                       
                                </div>
                            </div>
                        </div>
                        <div class="invalid-feedback" *ngIf="item.get('depositApprove' + i)?.touched && item.get('depositApprove' + i)?.errors?.required && item.get('depositApprove' + i)?.invalid">
                           This field is required
                        </div>
                </div>


                <div class="p-col-4 p-d-flex">
                    <div class="p-col-12 p-p-0">
                        <textarea  [attr.disabled]="!!item.get('isDisabled')?.value ? true : null" [ngClass]="{'error':(item.get('depositSlipComment')?.touched && (item.get('depositSlipComment')?.errors?.required || item.get('depositSlipComment')?.errors?.minlength) && item.get('depositSlipComment')?.invalid)}"
                        pInputTextarea id="comment" formControlName="depositSlipComment" rows="1" cols="3" placeholder="Enter your comment here" maxlength="500"></textarea>
                        
                
                        <div class="invalid-feedback" *ngIf="item.get('depositSlipComment')?.touched && item.get('depositSlipComment')?.errors?.required && item.get('depositSlipComment')?.invalid">
                            Comment is required
                        </div>

                        <div class="invalid-feedback" *ngIf="item.get('depositSlipComment')?.touched && !item.get('depositSlipComment')?.errors?.required && item.get('depositSlipComment')?.errors?.minlength && item.get('depositSlipComment')?.invalid">
                            Please enter atleast 3 characters
                        </div>
                    </div>
                </div>
                
            </div>


        </form>
    </ng-container>
</ng-container>
<!-- payment-item -->
<ng-template #paymentItemTemplate let-ctx>
    
</ng-template>
