// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application



import { environment } from 'src/environments/environment.prod';
// import { environment } from 'src/environments/enviroment.dev';

export const VERSION = "0.0.1-SNAPSHOT";
export const DEBUG_INFO_ENABLED = Boolean("development");
export const SERVER_API_URL = environment.NEXERA_SERVICE;
export const BUILD_TIMESTAMP = "";
export let KIBANA_UI_SERVICE_API_URL = environment.KIBANA_UI_SERVICE_API_URL;
export let KEY_CLOAK_REALM_NAME = environment.KEY_CLOAK_REALM_NAME;
export let KEY_CLOAK_URL = environment.KEY_CLOAK_URL;
export let KEY_CLOAK_CLIENT_ID = environment.KEY_CLOAK_CLIENT_ID;
export let BACKOFFICE_SERVER_API_URL = environment.BACKOFFICE_SERVER_API_URL;
export let MASTER_SERVICE_URL = environment.MASTER_SERVICE_URL;
export let DOMAIN = environment.DOMAIN;
export let DOMAIN_ROUTE = environment.DOMAIN_ROUTE;
export let profile = environment.profile;
export let IMAGE_PATH = environment.IMAGE_PATH;
export let SERVER_API_INTEGRATION_URL = environment.SERVER_API_INTEGRATION_URL; 
export const REPORTING_SERVER_API_URL = environment.REPORTING_SERVER_URL;
export const REPORTING_SERVER_API_URL_Entity = environment.NEXERA_ENTITY_URL;
export const REPORTING_SERVER_API_URL_Allocation = environment.BACKOFFICE_ALLOCATION_URL;
export const REPORTING_SERVER_API_URL_CRMIntegaration = environment.BACKOFFICE_CRM_URL;
export const USERMANAGEMENT_SERVER_API_URL = environment.USER_MANAGEMENT_SERVER_API_URL;
export const NEXERA_SERVER_API_URL = environment.NEXERA_SERVICE;
export const NEXERA_SERVER_API_URL_OPPORTUNITY = environment.OPPORTUNITY_SERVER_URL;
export const FILEUPLOAD_URL = environment.FILEUPLOAD_URL;
export const SFINITIATION_URL  = environment.SFINITIATION_URL;
export const SFREALIZATION_URL = environment.SFREALIZATION_URL;
export const IRFREALIZATION_URL = environment.IRFREALIZATION_URL;

export const SFPUSHGRID_URL = environment.SFPUSHGRID_URL;
export const IRFPUSHGRID_URL = environment.IRFPUSHGRID_URL;



