import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.scss'],
  providers: [DialogService, MessageService]
})
export class AttachmentPreviewComponent implements OnInit {

  zoomValue: number = 1;
  rotate: number = 0;
  readonly pdfMime = 'data:application/pdf;base64,';
  canvasRotation: number = 0;
  
  
  constructor() { }

  ngOnInit(): void {
    console.log("called AP");
    this.zoomValue = 1;
    this.rotate = 0;
  }

  @Input('url') url: string;
  @Input('isPdf') isPdf: boolean;
  @Input('file') fileItem: any;

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    if(el)
      el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
  }

}
