import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {InputSwitchModule} from 'primeng/inputswitch';
import {HttpClientModule} from '@angular/common/http';
import {DialogModule} from 'primeng/dialog';
import {AccordionModule} from 'primeng/accordion';
import { ReportingCOAComponent } from './reporting-coa/reporting-coa.component';
import { ReportingScreenComponent } from './reporting-screen.component';
import { BackofficeSharedModule } from '../../shared/shared.module';
@NgModule({
    declarations: [
        ReportingCOAComponent,
        ReportingScreenComponent,
    ],
    imports: [
        ReactiveFormsModule,
        HttpClientModule,
        AccordionModule,
        InputSwitchModule,
        DialogModule,
        BackofficeSharedModule
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReportingScreenModule { }
