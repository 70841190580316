import { Location } from '@angular/common';
import { Component, OnInit, ViewChildren, ViewChild, Renderer2, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { PagesService } from '../../pages/pages.service';
import { DialogService } from 'primeng/dynamicdialog';
import { SfBillingService } from '../sf-billing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'backoffice-sf-edit-grid',
  templateUrl: './sf-edit-grid.component.html',
  styleUrls: ['./sf-edit-grid.component.scss'],
  providers: [DialogService, MessageService]
})
export class SfEditGridComponent implements OnInit, AfterViewInit {
  @ViewChildren('mainGrid') mainGrid;

  ccprimebreadcrumbList = [
    { label: `Edit SF Bills`, routerLink: ['/sf-billing/edit-sf-grid'] },
  ];

  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  loadingStatus = false; 
  showLoader: boolean = false;
  searchedEditList: any[] = [];
  gridLoader: boolean = false;
  apiFail: boolean = false;
  disableSearchBtn: boolean = true;

  financialYear;
  selectedCompanyName;
  sfBillIdField;

  companyNameList;
  sfBillIdFieldList;

  financialYearListUnfiltered;
  financialYearList;

  defaultValues = {
    financialYear: '2024-25'
  }
  filterSubjectCompanyName = new Subject<string>();
  filterSubjectSfBillId = new Subject<string>();
  excelIds: any[] = [];

  bdApprovedMsg: string="";
  infoMsg: any;
  billStatusMasterData:any;

  constructor(private _location: Location, private fb: FormBuilder, private dialogService: DialogService,private renderer: Renderer2,private cd: ChangeDetectorRef, private sfBillingService: SfBillingService, private router: Router, private pagesService: PagesService, private route: ActivatedRoute, private messageService: MessageService ) {
    const navigation = this.router.getCurrentNavigation();
    this.infoMsg = navigation?.extras?.state?.msg;
  }

  ngOnInit(): void {
    this.getDropdownValuesApis();
    this.financialYear = this.defaultValues.financialYear;
    // this.searchList();
  }

  ngAfterViewInit(): void {
    if(this.infoMsg == 'sent successfully to bd'){
      this.messageService.add({ severity: 'success', summary: 'SF bill details updated & sent to BD successfully'});
    } 
    if(this.infoMsg == 'sent successfully to fin'){
      this.messageService.add({ severity: 'success', summary: 'SF bill details updated & sent to Finanace successfully'});
    }
    setTimeout(() => {
      this.messageService.clear();
    }, 3000);
  }

  getDropdownValuesApis(){
    //Financial Year
    this.sfBillingService.getFeeCapFinancialYear().subscribe({
      next: (data:any)=>{
        this.financialYearListUnfiltered = data;
        const transformedDataFY = this.financialYearListUnfiltered.map(item => { return {id: item.id, label: item.financialYearDescription, value: item.financialYearDescription};});
        this.financialYearList = transformedDataFY;

        console.log("FinancialYear data",data);
      },
      error: (err:any)=>{
        console.log("FinancialYear err",err);
      }
    });
  }

  homeBreadcrumbClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}/#/nexera/dashboard/home`;
    }
  }

  onBackClick(): void {
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  onInputFieldChange(){
    console.log("on change", this.financialYear);
    console.log("on change", this.selectedCompanyName);
    console.log("on change", this.sfBillIdField);
    
    if(this.financialYear){
      if(this.selectedCompanyName || this.sfBillIdField){
        this.disableSearchBtn = false;
      }else{
        this.disableSearchBtn = true;
      }
    }else{
      this.disableSearchBtn = true;
    }
  }

  searchList(){
    this.gridLoader = true;
    this.apiFail = false;
    let selectedCompanyCode;
    let selectedSfBillId;

    if(this.selectedCompanyName == undefined || this.selectedCompanyName == null || this.selectedCompanyName === ""){
      selectedCompanyCode = null;
    }else{
      selectedCompanyCode = this.selectedCompanyName.companyCode;
    }
    
    if(this.sfBillIdField == undefined || this.sfBillIdField == null || this.sfBillIdField === ""){
      selectedSfBillId = null;
    }else{
      selectedSfBillId = this.sfBillIdField.sfBillId;
    }

    console.log("company name :", selectedCompanyCode);
    console.log("sf bill id :", selectedSfBillId);

    let payload = {
      "companyName": selectedCompanyCode,
      "financialYear": this.financialYear,
      "sfBillId":selectedSfBillId,
      "billStatus":[146,148,150],
      "bdOwnerId": null
    }
    this.sfBillingService.sfEditAndBdApprovalGridApi(payload).subscribe({
      next: (sfCaseDetails: Array<any>) => {
        this.searchedEditList = sfCaseDetails;
        this.gridLoader = false;
        this.apiFail = false;
        this.onInputFieldChange();
        this.fetchDataFromMaster();
      },
      error: (err) => {
        this.gridLoader = false;
        this.apiFail = true;
      }
    })
  }

  resetFilters(){
    // this.financialYear = this.defaultValues.financialYear;
    // this.selectedCompanyName = null;
    // this.sfBillIdField = null;
  }

  showErrorMsg(data:any){
    if(data?.errorStatus != null && data?.errorStatus != '' && data?.errorStatus != undefined){
      let invoiceDetailsId = "tooltipOpp_"+data.sfBillId;
      document.getElementById(invoiceDetailsId)?.setAttribute('data-tooltip', data?.errorStatus);
      this.renderer.addClass(document.body, 'overflow-scroll');
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
    
  }
  
  showCompanyDetailsPage(opp:any){
    console.log("clicked company details", opp.sfBillId);
    this.router.navigate(['/sf-billing/edit-sf-company-details',opp.sfBillId])
  }

  onCompanyNameFilter(e){
    console.log("company name filter",e);
    console.log("company name filter",e.filter);
    
    const query: string = e.filter;
    if (query?.length >= 4) {
      console.log("company name filter",query);
      this.fetchedCompanyFilterDatafromAPI(query);
    }else if(query?.length <= 3){
      this.companyNameList = [];
    }
  }

  fetchedCompanyFilterDatafromAPI(filterString:string){
    this.sfBillingService.getCompanyNames(filterString).subscribe((data:any)=>{
      console.log("onfilter 1",data);
      this.companyNameList = data;
      // if(this.selectedCompanyName==null || this.selectedCompanyName.length==0){
      // }else{
      //   this.companyNameList = [...this.selectedCompanyName, ...data];
      //   this.companyNameList = this.companyNameList.map(data=> data.companyName);
      //   console.log("onfilter 2",this.companyNameList);
      // }
      console.log("onfilter 3",this.companyNameList);
    })
  }

  onSfBillIdFilter(e){
    console.log("company name filter",e);
    console.log("company name filter",e.filter);
    
    const query: string = e.filter;
    if(query != null){
      this.sfBillingService.getSfBillId(query).subscribe((data:any)=>{
        // this.sfBillIdFieldList = data.map(id => ({sfBillId: id}));
        this.sfBillIdFieldList = data;
        console.log("data sfbillid",this.sfBillIdFieldList);
        
      })
    }else{
      this.sfBillIdFieldList = [];
    }
    
  }

  resetFinancialYearDropdown(){
    console.log("onclear financial year 1", this.financialYear);
    
    // if (this.financialYear)
    this.financialYear = this.defaultValues.financialYear;
    console.log("onclear financial year 2", this.financialYear);
  }
  
  fetchDataFromMaster(){
    this.sfBillingService.getBillStatusMaster().subscribe((data:any)=> {
      this.billStatusMasterData = data;
      this.combineBillStatusMasterAndBdCase();
    });
  }
  
  combineBillStatusMasterAndBdCase(){
    if(this.searchedEditList){
      const details = this.searchedEditList;
      let productDetails = this.billStatusMasterData;
      details.forEach((item) => {
        const matchedBillStatus = productDetails.find((mitem)=> mitem.id === item.billStatus);
        if(matchedBillStatus){
          item.billStatusName = matchedBillStatus.property;
        }
      });
    }
  }
  
  companyNameOnClear(){
    this.companyNameList = []
  }

  sfBillIDOnClear(){
    this.sfBillIdFieldList = []
  }

}
