import { Routes } from '@angular/router';
import { ByClientComponent } from './by-client/by-client.component';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { AgreementDetailsComponent } from './agreement-details/agreement-details.component';
import { ViewBillDetailsComponent } from './view-bill-details/view-bill-details.component';
import { ViewBillPaymentDetailsComponent } from './view-bill-payment-details/view-bill-payment-details.component';
import { QcFormComponent } from './qc-form/qc-form.component';
import { PortfolioDetailsComponent } from './portfolio-details/portfolio-details.component';

export const AGREEMENTARCHIVE_ROUTE: Routes = [
  {
    path: 'byclient',
    component: ByClientComponent,
     data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
  }, 
  {
    path: 'agreementdetails/:agreementId',
    component: AgreementDetailsComponent,
     data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
  }, 
  {
    path: 'viewbilldetails/:irfBillId',
    component: ViewBillDetailsComponent,
     data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
  },
  {
    path: 'viewbillpaymentdetails/:irfBillId',
    component: ViewBillPaymentDetailsComponent,
     data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
  },
  {
    path: 'qcform/:id',
    component: QcFormComponent,
    data: {
      authorities: [],
      pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'viewportfoliodetails/:companyCode/:agreementId',
    component: PortfolioDetailsComponent,
     data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
  }
  
];