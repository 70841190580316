import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { SfBillingService } from '../sf-billing.service';
import { PagesService } from 'app/pages/pages.service';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MultiSelect } from 'primeng/multiselect';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddAgreementComponent } from '../add-agreement/add-agreement.component';

@Component({
  selector: 'backoffice-generate-bill',
  templateUrl: './generate-bill.component.html',
  styleUrls: ['./generate-bill.component.scss'],
  providers: [MessageService]
})
export class GenerateBillComponent implements OnInit, AfterViewInit {

  @ViewChild('bdOwnerNameDropdown') 
  bdOwnerNameDropdown: MultiSelect;

  
  financialYear;
  entityStatus;
  clientType;
  assignmentFamily;
  bdOwnerName =[];
  empID = this.encDec.decrypt(localStorage.getItem('empid'));

  ccprimebreadcrumbList = [
    {
      label: `Generate Bill`, routerLink: ['/sf-billing/generate-bill']
    },
  ]
  homeiconccprimebreadcrumb = { icon: `pi pi-home` };

  financialYearListUnfiltered;
  financialYearList;
  entityStatusList;
  clientTypeNameAPIList;
  clientTypeList;
  assignmentFamilyListUnfiltered;
  assignmentFamilyList;
  feeCapFinancialYearList = [];

  defaultValues = {
    financialYear: '2023-24',
    entityStatus: 'Cooperative',
    clientType: 'All',
    assignmentFamily: 'All'
  }

  bdOwnerNameList :any[]=[];
  
  sfEstimatesFilterForm : FormGroup;

  displayProcessingModal: boolean = false;
  viewImportSummarySuccessModal: boolean = false;
  viewImportSummaryModal: boolean = false;
  viewErrorImportSummaryModal: boolean = false;
  disableFields: boolean = false;
  showBillEstimatesListApi: boolean = false;
  clearAllDisabled: boolean = true;
  showGeneratedBillStatus: boolean = false;
  noRecordsFoundImportSummaryModal: boolean = false;
  disableExport: boolean = false;

  exportStatusCheck:string;
  importStatusCheck:string;
  estimationImportEstimatesSubscription: Subscription;
  estimationStatusSubscription: Subscription;

  filterSubject = new Subject<string>();
  filterValue: string = '';
  errorMessageOnImport: string;
  processedResult: any;

  timeOutCheckExport: any;
  timeOutCheckImport: any;
  scopeList: any;
  readScope: boolean = false;
  selectAll: boolean = true;

  showFeeCapFilter = false;

  companyList = [];
  companyNameList: any;
  selectedCompanyName: any;
  disableSearchBtn: boolean = true;
  addAgreementDialog: DynamicDialogRef = new DynamicDialogRef();
  productMasterList: any = [];
  productMasterMap: any = {};
  instrumentMasterList: any = [];
  instrumentMasterMap: any = {};
  unmappedAgreement: any = [];
  feeCapClearAllDisabled: boolean = true;
  dummyAgreement: any = [];
  


  constructor(private fb: FormBuilder,private cdr: ChangeDetectorRef, private router: Router, private httpClient: HttpClient, private messageService: MessageService, private sfBillingService: SfBillingService, private encryptDecryptInterceptor: EncryptDecryptInterceptor,private encDec: EncryptDecryptInterceptor, private eref:ElementRef,private dialogService: DialogService
    ) { 
      this.filterSubject.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        switchMap((filter: string) => this.sfBillingService.getBDOwnerNames(filter))
      ).subscribe((data: any[]) => {
        this.bdOwnerNameList = data;
      });
  }
  
  ngOnInit(): void {
    this.scopeList = this.encDec.decrypt(localStorage.getItem('scopes'));
    let scope: any = this.scopeList.split(",");
    if(scope.indexOf('BILLING_GENERATE_BILL_CREATE') == -1){
      this.readScope = true;
    } else {
      this.readScope = false;
    }
    
    console.log(this.sfEstimatesFilterForm);
    // this.fetchBDOwnerNames();
    this.getDropdownDataApi();
    this.billGenerationExportStatusApiCheck();
    this.financialYear = "2024-25";
    this.productMaster();
    this.instrumentMaster();
    this.getFeeCapFinancialYear();
    this.entityStatus = "Cooperative";
    this.clientType = "All";
  }
  
  ngAfterViewInit(): void {
    
    // const input = this.bdOwnerNameDropdown.el.nativeElement('.p-multiselect-filter');
    // if(input){
    //   input.addEventListener('paste',(pasteEvent:ClipboardEvent)=>{
    //     setTimeout(()=>{
    //       const pastedText = input.value;
    //       this.onFilterOptions(pastedText);
    //     },0)
    //   })
    // }
  }
  
  onFilter(event: any) {
    console.log("filter event",event);
    
    const query: string = event.filter;
    if (query?.length >= 3){
      this.onFilterOptions(query);
    }
  }

  onFilterOptions(query){
    this.sfBillingService.getBDOwnerNames(query).subscribe((data:any)=>{
        console.log("this.bdOwnerName",this.bdOwnerName);
        if(this.bdOwnerNameList?.length==0 ){
          this.bdOwnerNameListUpdate([...data]);
        }else{
          this.bdOwnerNameListUpdate([...this.bdOwnerName,...data]);
          // this.mergedList(this.bdOwnerName,data);
          // this.bdOwnerName.forEach((selectedValue: any)=>{
          //   if(this.bdOwnerNameList[selectedValue.employeeId]){
          //     this.bdOwnerNameListUpdate([...this.bdOwnerName, ...data]);
          //   }else{
          //   }
          // })
        }
    });
  }
  mergedList(obj1,obj2){
    const result = {...obj1};
    for (let employeeId in obj2){
      if(obj2.hasOwnProperty(employeeId)){
        if(!result.hasOwnProperty(employeeId)){
          result[employeeId] = obj2[employeeId];
        }
      }
    }
    console.log(result);
    this.bdOwnerNameListUpdate(result);
  }

  bdOwnerNameListUpdate(newOptions:any[]){
    this.bdOwnerNameList = newOptions;
    if(this.bdOwnerNameDropdown.filterValue){
    //   this.bdOwnerNameDropdown.filterValue = ""+this.bdOwnerNameDropdown.filterValue;
      setTimeout(() => {
        const inputel = this.bdOwnerNameDropdown.el.nativeElement.querySelector('input');
        if(inputel){
          inputel.click();
        }
        this.bdOwnerNameDropdown.filterValue = this.bdOwnerNameDropdown.filterValue;
        // this.bdOwnerNameDropdown.el.nativeElement.filter();
      } );
    }

  }

  ngOnChanges():void {
  }

  selectOnChangeForBdOwner(e){
    console.log("onselect",e);
    if(this.bdOwnerName.length > 0){
      this.clearAllDisabled = false;
    }else{
      this.clearAllDisabled = true;
    }
  }

  billGenerationExportStatusApiCheck(){
    this.sfBillingService.getSFBillGenerationStatus().subscribe((data: any) =>{
        this.exportStatusCheck = data.batchStatus;
        if(this.exportStatusCheck === "Inprogress"){
          this.messageService.add({ severity: 'warn', detail: 'Estimation is in progress.' });
          this.disableFields = true;
          this.checkStatusOnInterval();
        }else if(this.exportStatusCheck === "Error"){
          this.messageService.clear();
          this.messageService.add({ severity: 'warn', detail: 'Estimation Failed.' });
          this.disableFields = false;
          setTimeout(() => {
            this.messageService.clear();
          }, 3000);
        }else{
          this.messageService.clear();
          this.disableFields = false;
        }
      },(err:any) => {
        console.log("estimation status error", err);
      }
    );
  }  
  

  checkStatusOnInterval(){
    this.timeOutCheckExport = setTimeout(() => {
      this.estimationStatusSubscription = this.sfBillingService.getSFBillGenerationStatusRecheck().subscribe({
        next: (data:any)=>{
          this.exportStatusCheck = data.batchStatus;
          if(this.exportStatusCheck === "Inprogress"){
            this.checkStatusOnInterval();
          }else if(this.exportStatusCheck === "Error"){
            this.messageService.clear();
            this.messageService.add({ severity: 'warn', detail: 'Estimation Failed.' });
            this.disableFields = false;
            setTimeout(() => {
              this.messageService.clear();
            }, 3000);
            this.estimationStatusSubscription.unsubscribe();
          }else{
            this.messageService.clear();
            this.disableFields = false;
            this.messageService.add({ severity: 'success', detail: 'Estimation completed.' });
            setTimeout(() => {
              this.messageService.clear();
            }, 3000);
            this.estimationStatusSubscription.unsubscribe();
          }
        },error: (err:any)=>{

        }
      })
    }, 3000);
  }

  getDropdownDataApi(){
    //EntityStatus
    this.sfBillingService.getSfEntityStatus().subscribe({
      next: (data :any)=>{
        this.entityStatusList = data
      },
      error: (err:any)=>{
        console.log(err);
      }
    });
    //clientType
    this.sfBillingService.getClientType().subscribe({
      next: (data :any)=>{
        this.clientTypeNameAPIList = data.SUCCESS;
        const transformedData = [];
        const uniqueLabels = [];

        transformedData.push({label: 'All', value: 'All'});
        this.clientTypeNameAPIList.forEach(item => {
          let label, value;
          if(item.clientTypeName.startsWith('LCG')){
            label='LCG';
            value='LCG'
          }else{
            label =item.clientTypeName;
            value =item.clientTypeName
          }
          //removing duplicates of LCG type
          if(!uniqueLabels[label]){
            transformedData.push({label,value})
            uniqueLabels[label] = true;
          }
        });
        this.clientTypeList = transformedData;
      },
      error: (err:any)=>{
        console.log(err);
        
      }
    });
    //Assignment Family
    this.sfBillingService.getAssignmentFamily().subscribe({
      next: (data:any)=>{
        this.assignmentFamilyListUnfiltered = data;
        const transformedData = this.assignmentFamilyListUnfiltered.map(item => {return {label: item.name, value: item.id};});
        this.assignmentFamilyList = transformedData;
        this.assignmentFamily = this.assignmentFamilyList;
        console.log("AssignemntFamily data",this.assignmentFamilyList);
      },
      error: (err:any)=>{
        console.log("AssignemntFamily err",err);
      }
    });
    //Financial Year
    this.sfBillingService.getFinancialYear().subscribe({
      next: (data:any)=>{
        this.financialYearListUnfiltered = data;
        const transformedDataFY = this.financialYearListUnfiltered.map(item => { return {id: item.id, label: item.financialYearDescription, value: item.financialYearDescription};});
        this.financialYearList = transformedDataFY;
        console.log("FinancialYear data",data);
      },
      error: (err:any)=>{
        console.log("FinancialYear err",err);
      }
    });
  }

  homeBreadcrumbClicked(event): void {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }
  
  exportProcess(){
    this.messageService.clear();
    this.disableFields = true;
    let assignmentFamilySelected;

    if(this.assignmentFamily === undefined || this.assignmentFamily.length == 0){
      this.disableExport = true;
    }else if(this.assignmentFamily.length == this.assignmentFamilyList.length){
      assignmentFamilySelected = [0]
    }else{
      assignmentFamilySelected = this.assignmentFamily.map(item => item.value);
    }

    let selectedBDOwnerID
    let selectedBDOwnerName
    console.log("bd owneers", this.bdOwnerName);
    if(this.bdOwnerName === undefined || this.bdOwnerName.length == 0){
      selectedBDOwnerID = ["All"]
      selectedBDOwnerName = ["All"]
    }else{
      selectedBDOwnerID = this.bdOwnerName.map((data)=> data.employeeId)
      selectedBDOwnerName = this.bdOwnerName.map((data)=> data.employeeName)
    }

    let payload = {
      "financialYear": this.financialYear,
      "entityStatus": [this.entityStatus],
      "clientType": [this.clientType],
      "assignmentFamily": assignmentFamilySelected,
      "bdOwnerIds": selectedBDOwnerID,
      "bdOwnerNames": selectedBDOwnerName,
      "loggedInUserId": this.empID
    }
    console.log("payload",payload);
      
    if(this.disableExport != true){
      this.sfBillingService.sfGenerateBillApiExport(payload).subscribe((data:any) => {
        console.log("exported data",data); 
        if(data.EstimationStatus === "Estimation is already in progress by other user"){
          this.messageService.clear();
          this.messageService.add({ severity: 'warn', detail: data.EstimationStatus });
          this.disableFields = true;
          setTimeout(() => {
            this.messageService.clear();
            this.billGenerationExportStatusApiCheck();
            // this.exportProcess();
          }, 3000);
        }else if(data.EstimationStatus == undefined){
          this.billGenerationExportStatusApiCheck();
        }
      },
      (error: any)=>{
        console.log("exported error",error);       
      })
    }
  }

  selectOnChange(){
    console.log("check vals", this.assignmentFamily);
    console.log("bd owneers", this.bdOwnerName);
    
    if(this.financialYear != this.defaultValues.financialYear || this.entityStatus != this.defaultValues.entityStatus || this.clientType != this.defaultValues.clientType || this.assignmentFamily[0].label !== "All" || this.assignmentFamily.length > 1){
      this.clearAllDisabled = false;
      this.feeCapClearAllDisabled = false;
    }else{
      this.clearAllDisabled = true;
      this.feeCapClearAllDisabled = true;
    }

    if(this.assignmentFamily.length < this.assignmentFamilyList.length && this.assignmentFamily.length != 0){
      this.selectAll = false;
    }else{
      this.selectAll = true;
    }
  }

  changeAssignmentFamilyToEmpty(){
    this.assignmentFamily = [];
  }

  handleChipRemoval(event: any) {
    let index = this.assignmentFamily.findIndex(item => item.label === event.label);
    if (index !== -1) {
      this.assignmentFamily.splice(index, 1);
    }
    console.log("check chip vals", this.assignmentFamily);
    if(this.assignmentFamily.length == 0){
      this.disableExport = true;
    }
    
  }

  resetForm(){
    this.financialYear = this.financialYearListUnfiltered[1].financialYearDescription;
    this.entityStatus = 'Cooperative';
    this.clientType = 'All';
    this.assignmentFamily = this.assignmentFamilyList;
    this.bdOwnerNameDropdown.value = [];
    this.bdOwnerNameDropdown.filterValue = "";
    this.bdOwnerNameDropdown._filteredOptions = [];
    this.bdOwnerName = [];
    this.bdOwnerNameList = [];
    this.clearAllDisabled = true;
  }

  showModalBillEstimatesList(){
    this.showBillEstimatesListApi = true;
  }
  convertToBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  isExcelFile;
  
  async fileUploaded(event) {
    let file: File = event.target.files[0];
    this.displayProcessingModal = false;
    
    let excelBase64 = await this.convertToBase64(file);
    excelBase64 = (<string>excelBase64).split('base64,')[1];

    let $mimes = ['application/vnd.ms-excel','text/plain','text/csv','text/tsv'];
    if(file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      let payload = {
          "excelBase64": excelBase64,
          "requesterUserId": this.empID
      }
  
      this.sfBillingService.sfGenerateBillImportExcelValidationStatus(payload).subscribe((res) => {
          this.displayProcessingModal = false;
          if(res) {
            console.log("import summary",res);
            this.processedResult = res;
            console.log("generate bill",this.processedResult);

          } else {
            this.messageService.add({severity: 'info', summary: 'Data not received'});
            setTimeout(() => {
              this.messageService.clear();
            }, 4000);
          }
          if(this.processedResult?.validEntries == 0){
            this.noRecordsFoundImportSummaryModal = true;
          }else{
            this.viewImportSummarySuccessModal = true;
          }
          event.target.value = '';
        },(err:any) => {
          this.displayProcessingModal = false;
          event.target.value = '';
          let decodederr = this.encDec.decrypt(err.error.data);
          const errorDecoded = JSON.parse(decodederr);
          if(errorDecoded.body === "Estimation is already in progress by some other user"){
            this.messageService.clear();
            this.messageService.add({ severity: 'warn', detail: errorDecoded.body });
            this.disableFields = true;
            setTimeout(() => {
              this.messageService.clear();
              this.disableFields = false;
              // this.billGeneratedImportStatus();
            }, 3000);
            // this.billGeneratedImportStatus();
            console.log(errorDecoded.body);
          }else if(errorDecoded.body === "SF bill generation is already in progress by some other user"){
            this.messageService.clear();
            this.messageService.add({ severity: 'warn', detail: errorDecoded.body });
            this.disableFields = true;
            setTimeout(() => {
              this.messageService.clear();
              this.disableFields = false;
              // this.billGeneratedImportStatus();
            }, 3000);
            // this.billGeneratedImportStatus();
            console.log(errorDecoded.body);
          }else if(errorDecoded.statusCodeValue == 400){
            this.errorMessageOnImport = errorDecoded.body;
            this.viewImportSummaryModal = true;
          }
        }
      );
    }else{
      this.errorMessageOnImport = "Selected File Type is not allowed";
      this.viewImportSummaryModal = true;
    }

    
  }

  closeOnErrorImportModal(){
    this.viewImportSummaryModal = false;
  }

  generateBillMethod(booleanCheck: string){
    this.viewImportSummarySuccessModal = false;
    //generateBillCode
    // if(booleanCheck === "Yes"){
      let payload = {
        sfBillGenerationFlag: booleanCheck,     
        loggedInUserId: this.empID,     
        financialYear: this.financialYear
      }
      this.sfBillingService.sfGenerateBillApiImport(payload).subscribe((data:any)=>{
        if(booleanCheck === "Yes"){
          // this.showGeneratedBillStatus = true;
          this.billGeneratedImportStatus();
        }
      },(err:any)=>{
        console.log("Generated Bill Error Status",err);
        
      })
    // }else if(booleanCheck === "No"){}
  }

  billGeneratedImportStatus(){
    this.sfBillingService.sfGenerateBillApiImportStatus().subscribe((data:any)=>{
      console.log("Import status check",data);
      this.importStatusCheck = data.batchStatus;
        if(this.importStatusCheck === "Inprogress"){
          this.messageService.add({ severity: 'warn', detail: 'Bill Generation is in progress.' });
          this.disableFields = true;
          this.checkStatusOnIntervalImport();
        }else if(data.batchStatus === "Error"){
          this.importStatusCheck = data.batchStatus;
          this.messageService.clear();
          this.messageService.add({ severity: 'warn', detail: 'Bill Generation Failed.' });
          this.disableFields = false;
          setTimeout(() => {
            this.messageService.clear();
          }, 3000);
        }else{
          this.messageService.clear();
          this.disableFields = false;
        }
    },(err:any)=>{
      console.log("Import status check error",err);
    })
  }

  checkStatusOnIntervalImport(){
    this.timeOutCheckImport = setTimeout(() => {
      this.estimationImportEstimatesSubscription = this.sfBillingService.sfGenerateBillApiImportStatusRecheck().subscribe((data:any)=>{
        this.importStatusCheck = data.batchStatus;
        if(this.importStatusCheck === "Inprogress"){
          this.checkStatusOnIntervalImport();
        }else if(this.importStatusCheck === "Error"){
          this.messageService.clear();
          this.messageService.add({ severity: 'warn', detail: 'Estimation Failed.' });
          this.disableFields = false;
          setTimeout(() => {
            this.messageService.clear();
          }, 3000);
          this.estimationImportEstimatesSubscription.unsubscribe();
        }else{
          this.messageService.clear();
          this.disableFields = false;
          this.messageService.add({ severity: 'success', detail: 'Bill Generation completed.' });
          setTimeout(() => {
            this.messageService.clear();
          }, 3000);
          this.estimationImportEstimatesSubscription.unsubscribe();
        }
      },(err:any)=>{
        console.log("Import status check error",err);
      });
    }, 3000);
  }

  closeViewErrorImportSummary(){
    this.viewErrorImportSummaryModal = false;
  }

  onDropdownClose() {
    // Handle the close event here
    if(this.bdOwnerName === undefined || this.bdOwnerName.length <= 0){
      this.bdOwnerNameList = null;
    }
  }

  ngOnDestroy() {
    if(this.timeOutCheckExport){
      clearTimeout(this.timeOutCheckExport);
      this.estimationStatusSubscription.unsubscribe();
    }
    if(this.timeOutCheckImport){
      clearTimeout(this.timeOutCheckImport);
      this.estimationImportEstimatesSubscription.unsubscribe();
    }
  }

  closeNoRecordsFoundImportSummaryModal(){
    this.noRecordsFoundImportSummaryModal = false;
  }

  showFeeCap(event){
    this.showFeeCapFilter = event.checked;
    this.resetForm();
    this.clearFeeCapData();
    // if(this.showFeeCapFilter){
    //   this.getFeeCapFinancialYear();
    // }
  }

  getFeeCapFinancialYear(){
    this.sfBillingService.getFeeCapFinancialYear().subscribe((data:any)=>{
      // console.log("FinancialYear Data:",data);
      // let financialyYearFeeCapData = data;
      this.feeCapFinancialYearList = data?.map(item => { return {id: item.id, label: item.financialYearDescription, value: item.financialYearDescription};});
    });
  }

  onCompanyNameFilter(e) {
    console.log("company name filter", e);
    console.log("company name filter", e.filter);

    const query: string = e.filter;
    if (query?.length >= 4) {
      console.log("company name filter", query);
      this.fetchedCompanyFilterDatafromAPI(query);
    } else if (query?.length <= 3) {
      this.companyNameList = [];
    }
  }

  fetchedCompanyFilterDatafromAPI(filterString: string) {
    this.sfBillingService.getFeeCapCompanyDetails(filterString).subscribe((data: any) => {
      this.companyNameList = data;
    })
  }

  onInputFieldChange() {
    console.log("on change", this.financialYear);
    console.log("on change", this.selectedCompanyName);

    if (this.financialYear == null) {
      this.disableSearchBtn = true;
      this.feeCapClearAllDisabled = true;
    } else {
      this.disableSearchBtn = false;
      this.feeCapClearAllDisabled = false;
    }
  }

  showFeeCapCompanyData(){
    this.disableSearchBtn = true;
    this.companyList = [];
    let data = {
      "companyCode":this.selectedCompanyName.companyCode,
      "companyName":this.selectedCompanyName.companyName,
      "bdOwnerName":this.selectedCompanyName.bdOwnerName
    }
    this.companyList.push(data);
  }

  showAgreementsByCompanyCode(companyDetails){
    console.log("Company Code for agreement id is: ",companyDetails);
    // get the agreement details
    let companyCode = companyDetails?.companyCode;
    this.sfBillingService.getAgreementListByCompanyCode(companyCode).subscribe((data:any)=>{
      this.unmappedAgreement = data;
      this.showAgreementPopUP(companyDetails);
    });
    
  }

  showAgreementPopUP(companyDetails){
    this.addAgreementDialog = this.dialogService.open(AddAgreementComponent, {
      contentStyle: { "height": "80vh", "overflow": "hidden" },
      data: {
        agreementList: this.unmappedAgreement, 
        dummyAgreement: this.dummyAgreement, 
        firstAgreement: companyDetails, 
        aggType: "generate",
        productMaster: this.productMasterMap,
        instrumentMaster: this.instrumentMasterMap
      },
      width: '90%',
      header: companyDetails?.companyName
    });
    this.addAgreementDialog.onClose.subscribe(response => {
      if(response && response?.length > 0){
        const agreementIdList = response?.map(obj =>obj.agreementId);

        console.log("response: ",response);

        console.log("Agreement Id List: ",agreementIdList);
        let payload = {

          "financialYear": this.financialYear,
          "companyCode": companyDetails?.companyCode,
          "userId": this.empID,
          "agreementId": agreementIdList
        }

        console.log("Add agreement payload: ",payload);
        this.generateFeeCapBills(payload);
      }
    });
  }

  productMaster(){
    this.sfBillingService.productList().subscribe((res: any) => {
      if (res && res.length > 0) {
        this.productMasterList = res;
        this.productMasterMap = res?.reduce((acc, item) => {
          acc[item.id] = item.product_name;
          return acc;
        }, {});
      }
    });
  }

  instrumentMaster(){
    this.sfBillingService.instrumentList().subscribe((res: any) => {
      if (res && res.length > 0) {
        this.instrumentMasterList = res;
        this.instrumentMasterMap = res?.reduce((acc, item) => {
          acc[item.id] = item.name;
          return acc;
        }, {});
      }
    });
  }

  generateFeeCapBills(payload){
    this.sfBillingService.generateFeeCapBill(payload).subscribe((res: any)=>{
      this.clearFeeCapData();
      this.disableFields = true;
      if(res?.FunctionStatus == "Success" && res?.ProcessedCaseCount > 0){
        this.disableFields = false;
        this.messageService.add({ severity: 'success', detail: 'Fee Cap Bill Generated Successfully.'});
      }

      if(res?.FunctionStatus == "Success" && res?.ProcessedCaseCount == 0){
        this.disableFields = false;
        this.messageService.add({ severity: 'error', detail: 'Fee Cap Bill Not Generated.'});
      }
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    }, (err: any) => {
      let decodederr = this.encDec.decrypt(err.error.data);
      const errorDecoded = JSON.parse(decodederr);
      if (errorDecoded.statusCodeValue == 400) {
        this.errorMessageOnImport = errorDecoded.body;
        this.viewImportSummaryModal = true;
      }
    });
  }

  clearFeeCapData(){
    this.feeCapClearAllDisabled = true;
    this.financialYear = this.financialYearListUnfiltered[1].financialYearDescription;
    this.selectedCompanyName = null;
    this.companyList = null;
    this.companyNameList = null;
  }
  

  onSelectAllChange(e){
    console.log("before",this.assignmentFamily);
    if(e.checked){
      this.assignmentFamily = this.assignmentFamilyList;
      this.clearAllDisabled = true;
      this.selectAll = true;
    }else{
      this.changeAssignmentFamilyToEmpty();
      this.clearAllDisabled = false;
      this.selectAll = false;
    }
  }
}
