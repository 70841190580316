import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'qc-list-item',
  templateUrl: './qc-list-item.component.html',
  styleUrls: ['./qc-list-item.component.scss']
})
export class QcListItemComponent implements OnInit, AfterViewInit {

  // {
  //   key : 'string',
  //   label : 'string,
  //   val: 'string',
  //   isApprove : undefined | null | (Approved/Rejected),
  //   comment : undefined | null | 'random string'
  //   disable : true | false | undefined
  // }
  dataVal;
  dataForm : FormControl;
  comment;
  isApprove;
  invalidValForm;
  identity;
  isDisable;
  disabled = false;

  @Input('data') set data(itemData : FormControl) {
    this.dataForm = itemData;
    this.dataVal = itemData.value;
    this.isApprove = this.dataVal.isApprove;
    this.comment = this.dataVal.comment;
    this.identity = this.dataVal.key;
    if(this.dataVal?.disable !== undefined) { // more priority than 'disabled' setter
      this.disabled = !!this.dataVal?.disable;
      this.isDisable = !!this.dataVal?.disable;
    }
  }

  @Input('disabled') set dataDisabled(value : boolean) {
    if(this.isDisable === undefined)
      this.disabled = value;
  };

  @Input('indexFirst') indexFirst;
  @Input('indexLast') indexLast;
  
  constructor() { }

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    if(this.disabled) {
      this.dataForm.get('isApprove')?.clearValidators();
      this.dataForm.get('isApprove')?.updateValueAndValidity();
    } 
    if(!this.disabled && this.dataVal['isApprove'] === "Rejected" && this.dataVal['comment'] != null) {
      this.dataForm.get('comment').addValidators([Validators.required, Validators.minLength(3)]);
      this.dataForm.get('comment')?.updateValueAndValidity();
    } 
  }

  onApprove(value) {
    this.dataVal['isApprove'] = value ? 'Approved' : 'Rejected';
    
    this.isApprove = this.dataVal.isApprove;
    
    this.dataForm.patchValue({isApprove : this.dataVal.isApprove});
    if(!value)
      this.dataForm.get('comment').addValidators([Validators.required, Validators.minLength(3)]);
    else this.dataForm.get('comment').removeValidators([Validators.required, Validators.minLength(3)]);
  }


}
