import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { PagesService } from 'app/pages/pages.service';

@Component({
  selector: 'backoffice-qc-form',
  templateUrl: './qc-form.component.html',
  styleUrls: ['./qc-form.component.scss']
})
export class QcFormComponent implements OnInit {

  breadcrumbList = [];
  homeicon = { icon: `pi pi-home` };
  qcFormId: any;
  agreementId: any;

  constructor(private route: ActivatedRoute, private pageService: PagesService) { }
  dataList: any = [];
  dataObj: any = {};
  apiFail: boolean = false;

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.qcFormId = params.get('id');
    });

    this.pageService.getQcFormDetails(this.qcFormId).subscribe((res) => {
      this.dataList = res;
      if(res?.length > 0){
        this.dataList.forEach(data => {
          this.agreementId = data.agreementId
          this.breadcrumbList = [
            { label: `Archival Data Listing`, routerLink: ['/agreement-archive/byclient'] },
            { label: `Agreement Details`, routerLink: ['/agreement-archive/agreementdetails', this.agreementId] },
            { label: `QC Details`, routerLink: ['/agreement-archive/qcform', this.qcFormId] }
          ];
          let obj =  {
            isAccepted: new FormControl(data.isAccepted),
            qcComments: data.qcComments,
            bdComments: data.bdComments
          } 
          this.dataObj[data.checkName?.trim()] = obj;
        });
      }
    }, (_err) => {
      this.apiFail = true;
    });
    
  }

  onBackClick(){
    history.back();
  }

  homeClicked(event) {
    if(event.item?.icon == 'pi pi-home'){
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }
}
