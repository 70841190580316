import { Component, OnInit } from '@angular/core';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { OfflineBillsService } from '../offline-bills.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'backoffice-offline-bills-form',
  templateUrl: './offline-bills-form.component.html',
  styleUrls: ['./offline-bills-form.component.scss']
})
export class OfflineBillsFormComponent implements OnInit {

  breadcrumbList = [
    { label: `Offline Bills`, routerLink: ['/offline-bills/grid'] },
    { label: `Offline Bills Details`, routerLink: [] }
  ];
  homeicon = { icon: `pi pi-home` };

  billDetails: any = {};
  attachmentDetails: any = [];
  urlSafe: string;
  displayPreview: boolean = false;
  zoomValue: number = 1;
  rotate: number = 0;
  billId: any;

  constructor(private billService: OfflineBillsService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.billId = params.get('irfBillId');
    });
    this.billService.getBillFormDetails(this.billId).subscribe((res) => {
      this.billDetails = res;
      this.attachmentDetails = res?.offlineBillMandateAttachment;
    });
  }

  preivewAttachment(item){
    this.displayPreview = true;
    let mime = 'data:application/pdf;base64,';
    this.urlSafe = `${mime}${item?.mandateAttachment?.filePath}`;
  }

  homeClicked(event) {
    if(event.item?.icon == 'pi pi-home'){
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
  }

  backBtn(){
    history.back();
  }

}
