<div class="entityDetailsModal">
    <p-toast class="custom-toast"></p-toast>
    <div class="p-formgrid p-grid">
        <div class="p-col-12 p-p-0">
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-col-12 p-p-0">
                    <div class="ui-fluid p-formgrid p-grid">
                        <div class="p-col-12 p-p-0">
                            <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                                <div class="p-col-12 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light">Opportunity Name:</label>
                                        <h1 class="p-medium display-4 p-mb-0">SF: {{OpportunityGridDetails?.entityName}}</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light">Date:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{createdDate}}</h1>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light">Company Code:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{opportunityDetails?.entityCompanyCode}}</h1>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light">Bill ID:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{OpportunityGridDetails?.billId}}</h1>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light">Invoice Generated:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{billIdFinalised ? 'Yes' : 'No'}}</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light">Financial Year:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{sfBilldata?.financialYear}}</h1>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light">Fee Type:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{feeType}}</h1>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light">Fin Risk :</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{opportunityDetails?.entityId?.finRisk}}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="ui-fluid p-formgrid p-grid">
                        <div class="p-col-12 verticalTabContainer p-p-0">
                            <p-tabView [(activeIndex)]="activeIndex" (onChange)="tabChange($event)"
                                styleClass="p-tabview-vertical">
                                <p-tabPanel>
                                    <ng-template pTemplate="header">
                                        <span [ngClass]="{'errorTab': errorOpportunityDetails}">Opportunity
                                            Details</span>
                                    </ng-template>
                                    <form [formGroup]="opportunityDetailsForm">
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class="p-col-12 p-mt-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                        <h1 class="p-medium display-3">Fee Details</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Original Billed Value
                                                            </label>
                                                            <input type="text" pInputText placeholder="Enter Original Billed Value"
                                                                formControlName="originalBilledValue" [readonly]="true"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                SF Actual Fee
                                                            </label>
                                                            <input type="text" pInputText placeholder="Enter SF Actual Fee"
                                                                formControlName="sfActualFee" [readonly]="true"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                SF Discount/Premium
                                                            </label>
                                                            <input type="text" pInputText placeholder=" Enter SF Discount/Premium"
                                                                formControlName="sfDiscountPremium" [readonly]="true"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                SF Discount/Premium %
                                                            </label>
                                                            <input type="text" pInputText placeholder="Enter SF Discount/Premium %"
                                                                formControlName="sfDiscountPremiumPercent"
                                                                [readonly]="true" class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                SF Discount Reason
                                                            </label>
                                                            <p-dropdown id="sfDiscountReasonid" name="sfDiscountReason"
                                                                placeholder="Select a SF Discount Reason"
                                                                [options]="irfDiscountReasonList" [filter]="true"
                                                                resetFilterOnHide="true" optionLabel="name"
                                                                optionValue="name" [autoDisplayFirst]="false"
                                                                formControlName="sfDiscountReason"
                                                                [disabled]="disabledDropdown">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-mt-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                        <h1 class="p-medium display-3">RPT Details</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                RPT in CC
                                                            </label>
                                                            <input [readonly]="true" type="text"
                                                                formControlName="rptInCC" pInputText
                                                                class="p-inputtext" placeholder="Enter RPT in CC" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                RPT in CRM
                                                            </label>
                                                            <input [readonly]="true" type="text"
                                                                formControlName="rptInCrm" pInputText
                                                                class="p-inputtext" placeholder="Enter RPT in CRM"/>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                RPT Status Overall
                                                            </label>
                                                            <input [readonly]="true" type="text" formControlName="rptoverall"
                                                                pInputText class="p-inputtext" placeholder="Enter RPT Status Overall" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-mt-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                        <h1 class="p-medium display-3">Payment Details</h1>
                                                        <span *ngIf="paymentDetailsMissing"
                                                            style="color: red; font-size: 12px;">Few details are missing
                                                            in payment details</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="p-col-12 paymentAccordion">
                                                    <p-accordion id="p_accordion" (onOpen)="currentPayment($event)" collapseIcon="pi pi-minus">
                                                        <p-accordionTab class="p-accordiantab"
                                                            *ngFor="let paymentData of payments.controls; let i = index"
                                                            header="Payment Mode {{i+1}}">
                                                            <form [formGroup]="paymentData">
                                                                <div class="p-col-12 p-d-flex" *ngIf="addnPaymentCase">
                                                                    <label>Balance Amount :</label>
                                                                    <span class="text_brand_color"
                                                                        style="font-weight:bold;">&#8377;
                                                                        {{balanceAmount}}</span>
                                                                </div>
                                                                <div class="p-fluid p-grid">
                                                                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex">
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Realization Status</label>
                                                                                <input pInputText type="text"
                                                                                    formControlName="paymentStatus"
                                                                                    [ngClass]="{green: paymentData.value.paymentStatus == 'Realized', red: paymentData.value.paymentStatus == 'Rejected' || paymentData.value.paymentStatus == 'Bounced'}"
                                                                                    [readonly]="true" placeholder="Enter Realization Status" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Payment Mode</label>
                                                                                <p-dropdown
                                                                                    placeholder="Select a Payment"
                                                                                    formControlName="paymentMode"
                                                                                    [disabled]="disabledDropdown"
                                                                                    [options]="paymentModeList"
                                                                                    [filter]="true"
                                                                                    resetFilterOnHide="true"
                                                                                    optionLabel="property"
                                                                                    optionValue="property"
                                                                                    [autoDisplayFirst]="false">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>NEFT/Cheque No</label>
                                                                                <input pInputText type="text"
                                                                                    maxlength="{{ isCheque ? 6 : 100 }}"
                                                                                    (keypress)="numberOnlyCheque($event)"
                                                                                    class="textUppercase"
                                                                                    formControlName="chequeNo"
                                                                                    [ngClass]="{error:(paymentData.get('chequeNo')?.errors?.required && paymentData.get('chequeNo').invalid && saveBtnClicked) && (paymentData.get('chequeNo').dirty || paymentData.get('chequeNo').touched)}"
                                                                                    disabled
                                                                                    placeholder="Enter NEFT/Cheque No" />
                                                                                <div class="invalid-feedback"
                                                                                    *ngIf="(paymentData.get('paymentType').value != 'No Payment' && (paymentData.get('chequeNo').value == '' || paymentData.get('chequeNo').value == null))">
                                                                                    Please enter correct value for field
                                                                                    “NEFT/cheque no".
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-fluid p-grid p-mt-3">
                                                                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex">
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Bank</label>
                                                                                <p-dropdown placeholder="Select a Bank"
                                                                                    formControlName="bank"
                                                                                    [options]="bankList"
                                                                                    [disabled]="!isOpportunityWonRecd || isOpportunityReportInvoice"
                                                                                    [filter]="true"
                                                                                    resetFilterOnHide="true"
                                                                                    optionLabel="name"
                                                                                    optionValue="name"
                                                                                    [ngClass]="{error:(paymentData.get('bank')?.errors?.required && paymentData.get('bank').invalid && saveBtnClicked) && (paymentData.get('bank').dirty || paymentData.get('bank').touched)}"
                                                                                    [autoDisplayFirst]="false">
                                                                                </p-dropdown>
                                                                                <div class="invalid-feedback"
                                                                                    *ngIf="(paymentData.get('bank')?.errors?.required && paymentData.get('bank').invalid && saveBtnClicked) && (paymentData.get('bank').dirty || paymentData.get('bank').touched)">
                                                                                    Please select a bank.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Payment Date </label>
                                                                                <p-calendar dateFormat="dd-mm-yy"
                                                                                    id="dateCalendar_id"
                                                                                    placeholder="Select Payment Date"
                                                                                    formControlName="paymentDat"
                                                                                    [hideOnDateTimeSelect]="true"
                                                                                    [showIcon]="true"
                                                                                    yearRange="2000:2030"
                                                                                    [ngClass]="{error: (paymentData.get('paymentDat')?.invalid && paymentData.get('paymentDat')?.errors?.required) && (paymentData.get('paymentDat').dirty || paymentData.get('paymentDat').touched)}"
                                                                                    [disabled]="!isOpportunityWonRecd || isOpportunityReportInvoice">
                                                                                </p-calendar>
                                                                                <div class="invalid-feedback"
                                                                                    *ngIf="(paymentData.get('paymentDat')?.errors?.required && paymentData.get('paymentDat').invalid) && (paymentData.get('paymentDat').dirty || paymentData.get('paymentDat').touched)">
                                                                                    Payment Date is required.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Payment Type</label>
                                                                                <p-dropdown
                                                                                    placeholder="Select a Payment Type"
                                                                                    formControlName="paymentType"
                                                                                    [options]="paymentTypeList"
                                                                                    [filter]="true"
                                                                                    resetFilterOnHide="true"
                                                                                    optionLabel="property"
                                                                                    optionValue="property"
                                                                                    [autoDisplayFirst]="false"
                                                                                    [disabled]="disabledDropdown">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-fluid p-grid p-mt-3">
                                                                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex">
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>TDS Type</label>
                                                                                <p-dropdown placeholder="Select TDS Type"
                                                                                    [options]="tdsTypeList"
                                                                                    [autoDisplayFirst]="false"
                                                                                    formControlName="tdsType"
                                                                                    optionLabel="property"
                                                                                    optionValue="property"
                                                                                    [disabled]="disableTds"
                                                                                    (onChange)="gstTypeValidate()"
                                                                                    [ngClass]="{error: (paymentData.value.paymentType != 'No Payment') && (paymentData.get('tdsType')?.invalid && paymentData.get('tdsType')?.errors?.required)}">
                                                                                </p-dropdown>
                                                                                <div class="invalid-feedback"
                                                                                    *ngIf="(paymentData.value.paymentType != 'No Payment') && (paymentData.get('tdsType')?.errors?.required && paymentData.get('tdsType').invalid)">
                                                                                    TDS Type is required.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>TDS Amount</label>
                                                                                <input type="text" pInputText
                                                                                    placeholder="Enter TDS Amount"
                                                                                    (keypress)="numberOnly($event)"
                                                                                    formControlName="tdsAmount" [readonly]="true"
                                                                                    (keyup)="onManualTDS($event)"
                                                                                    [ngClass]="{error: (paymentData.get('tdsAmount')?.invalid && paymentData.get('tdsAmount')?.errors?.required) || (paymentData.value.tdsType == 'Manual TDS' && paymentData.value.tdsAmount == '0')}"
                                                                                    class="p-inputtext" />
                                                                                <div class="invalid-feedback"
                                                                                    *ngIf="(paymentData.get('tdsAmount')?.errors?.required && paymentData.get('tdsAmount').invalid) || (paymentData.value.tdsType == 'Manual TDS' && paymentData.value.tdsAmount == '0')">
                                                                                    TDS Amount is required.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Amount Collected</label>
                                                                                <input type="text" pInputText
                                                                                    placeholder="Enter Amount Collected" pInputText
                                                                                    inputmode="numeric"
                                                                                    (keypress)="numberOnly($event)"
                                                                                    (keyup)="onChangeGrossCalculation()"
                                                                                    formControlName="amount"
                                                                                    [maxLength]="15"
                                                                                    [ngClass]="{error: (paymentData.get('amount')?.invalid && paymentData.get('amount')?.errors?.required) && (paymentData.get('amount').dirty || paymentData.get('amount').touched)}"
                                                                                    [readonly]="true" />
                                                                                <div class="invalid-feedback"
                                                                                    *ngIf="paymentData.get('amount')?.errors?.required">
                                                                                    Amount Collected is required.
                                                                                </div>
                                                                                <div class="invalid-feedback"
                                                                                    *ngIf="paymentData.get('amount').errors?.pattern">
                                                                                    Enter Numeric Value
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-fluid p-grid p-mt-3">
                                                                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex">
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Gross Amount</label>
                                                                                <input type="text" pInputText
                                                                                    placeholder="Enter Gross Amount"
                                                                                    formControlName="grossAmount"
                                                                                    [readonly]="true"
                                                                                    [ngClass]="{error: (grossAmountError)}"
                                                                                    class="p-inputtext" />
                                                                                <div class="invalid-feedback"
                                                                                    *ngIf="grossAmountError">
                                                                                    Gross Amount should be less than
                                                                                    {{paymentData.value.paymentType ==
                                                                                    'Full Payment' ? grossError : '' }}
                                                                                    Balance Amount.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-fluid p-grid p-mt-3">
                                                                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex">
                                                                        <div class="p-col-8 p-d-flex p-jc-between">
                                                                            <div class="p-fluid p-field">
                                                                                <div
                                                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-text-left">
                                                                                    <label>Initiate Payment:</label>
                                                                                    <div *ngFor="let item of autoInitiateList"
                                                                                        class="p-radioButton-sm field-radiobutton p-d-flex p-ai-center p-pr-2">
                                                                                        <p-radioButton
                                                                                            formControlName="isInitiated_{{i}}"
                                                                                            [value]="item.value"
                                                                                            inline="inline"></p-radioButton>
                                                                                        <label
                                                                                            [for]="item.key">{{item.label}}</label>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="invalid-feedback p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-text-left"
                                                                                    *ngIf="(paymentData.get('isInitiated_'+i)?.errors?.required && paymentData.get('isInitiated_'+i).value == null && paymentData.get('isInitiated_'+i)?.dirty)">
                                                                                    Initiate Payment is required.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </p-accordionTab>
                                                    </p-accordion>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-mt-1">
                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                    <h1 class="p-medium display-3">3rd Party Details</h1>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                3rd Party Payment Approval
                                                            </label>
                                                            <p-dropdown id="PartyApprovalid" name="PartyApproval"
                                                                placeholder="Select" formControlName="partyApproval"
                                                                [options]="partyApprovalList" resetFilterOnHide="true"
                                                                optionLabel="name" optionValue="name"
                                                                [disabled]="isOpporutnityOtherStatus || isOpportunityReportInvoice || isOpportunityReportOnly"
                                                                [autoDisplayFirst]="false"
                                                                (onChange)="partyApprovalChanged($event)">
                                                            </p-dropdown>
                                                        </div>
                                                        <!-- <div class="invalid-feedback"
                                                            *ngIf="(opportunityDetailsForm.get('partyApproval')?.errors?.required || opportunityDetailsForm.get('partyApproval')?.errors?.touched) && !partyApprovalSelected">
                                                            3rd Party Payment Approval is required.
                                                        </div> -->
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>3rd Party Approval Comment<span
                                                                    class='invalid-feedback-astrik'
                                                                    *ngIf="partyCommentM">*</span>
                                                            </label>
                                                            <textarea pInputTextarea name="actionCommentApproval_name" rows="3"
                                                            [readonly]="isOpporutnityOtherStatus || isOpportunityReportInvoice || isOpportunityReportOnly"
                                                                placeholder="Enter 3rd Party Approval Comment" maxlength="200" id="thirdPartyComment"
                                                                formControlName="partyApprovalComment"
                                                                [ngClass]="{error:(partyCommentM && opportunityDetailsForm.get('partyApprovalComment')?.errors?.required) && (opportunityDetailsForm.get('partyApprovalComment').invalid || opportunityDetailsForm.get('partyApprovalComment').dirty || opportunityDetailsForm.get('partyApprovalComment').touched)}">
                                                            </textarea>
                                                        </div>
                                                        <div class="invalid-feedback"
                                                            *ngIf="(partyCommentM && opportunityDetailsForm.get('partyApprovalComment')?.errors?.required) && (opportunityDetailsForm.get('partyApprovalComment').invalid || opportunityDetailsForm.get('partyApprovalComment').dirty || opportunityDetailsForm.get('partyApprovalComment').touched)">
                                                            3rd Party Approval Comment is required.
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field p-d-flex p-flex-column">
                                                            <label>
                                                                Comment
                                                            </label>
                                                            <textarea pInputTextarea name="actionComment_name" rows="3"
                                                            disabled placeholder="Enter Comment" maxlength="255"
                                                                formControlName="comment"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </p-tabPanel>
                                <p-tabPanel>
                                    <ng-template pTemplate="header">
                                        <span [ngClass]="{'errorTab': errorApprovals}">Approvals</span>
                                    </ng-template>
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-col-12 p-mb-3">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                    <div class="p-d-flex">
                                                        <p-inputSwitch id="ibf" (onChange)="ibpSwitched()"
                                                            [(ngModel)]="ibPayment" class="inputSwitch"
                                                            [ngClass]="{error:ibpError}"
                                                            [disabled]="isOpporutnityOtherStatus || isOpportunityReportInvoice || isOpportunityReportOnly">
                                                        </p-inputSwitch>
                                                        <label class="p-ml-2"> Invoice Before Payment
                                                            <span class='invalid-feedback-astrik'
                                                                *ngIf="ibpMandatory">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="invalid-feedback" *ngIf="ibpError">
                                                        Invoice Before Payment Required
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                    <div class="p-d-flex">
                                                        <p-inputSwitch id="contactChange" disabled
                                                            [(ngModel)]="contactChange">
                                                        </p-inputSwitch>
                                                        <label class="p-ml-2"> Contact Change </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel>
                                    <ng-template pTemplate="header">
                                        <span [ngClass]="{'errorTab': errorAttachments}">Attachments</span>
                                    </ng-template>
                                    <div class="p-fluid p-formgrid p-grid attachmentSectionReporting">
                                        <div class=" p-field p-col-12 p-px-3 horizontalTabView">
                                            <p-tabView [(activeIndex)]="activeAttachmentIndex">
                                                <p-tabPanel header="Uploaded Document">
                                                    <div class="p-fluid p-formgrid p-grid">
                                                        <div class=" p-col-12 p-pl-0 p-pr-0">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-left p-pl-0 p-pr-0">
                                                                    <div class="p-field p-d-flex p-flex-wrap"
                                                                        style="justify-content: center;"
                                                                        *ngIf="uploadedDocuments?.length==0">
                                                                        <div class="p-mt-3 p-mb-3"
                                                                            style="text-align: center; color: rgba(41, 41, 41, 0.6);">
                                                                            <div class="p-mb-1 p-d-flex p-jc-center">
                                                                                <svg width="204" height="124"
                                                                                    class="illustrationLight"
                                                                                    viewBox="0 0 204 124" fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                                                                        fill="#EDEFF6" />
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984"
                                                                                        fill="white" />
                                                                                    <path
                                                                                        d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM50.1996 28.4313L51.4063 28.2832L50.1996 28.4313ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z"
                                                                                        fill="#9DC0FB" />
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711"
                                                                                        fill="#EBF3FE" />
                                                                                    <path
                                                                                        d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z"
                                                                                        fill="white" stroke="#76A7F9"
                                                                                        stroke-width="2.43137" />
                                                                                    <path
                                                                                        d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353"
                                                                                        stroke="#76A7F9"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path d="M86.3735 21.8823H117.981"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path d="M86.3735 80.2354H117.981"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path d="M86.3735 36.4707H138.648"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path d="M86.3735 51.0586H138.648"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path d="M86.3735 65.647H138.648"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path
                                                                                        d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z"
                                                                                        fill="#F5F9FF" stroke="#76A7F9"
                                                                                        stroke-width="1.45882" />
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44"
                                                                                        fill="white" />
                                                                                    <path
                                                                                        d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z"
                                                                                        stroke="#76A7F9"
                                                                                        stroke-width="1.45882"
                                                                                        stroke-linecap="round" />
                                                                                    <path
                                                                                        d="M152.335 102.501L156.943 107.169"
                                                                                        stroke="#76A7F9"
                                                                                        stroke-width="1.45882" />
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z"
                                                                                        fill="#EBF3FE" stroke="#76A7F9"
                                                                                        stroke-width="1.45882" />
                                                                                    <path
                                                                                        d="M158.991 107.688L164.623 113.393"
                                                                                        stroke="white"
                                                                                        stroke-width="1.31692"
                                                                                        stroke-linecap="round" />
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z"
                                                                                        fill="#EBF3FE" />
                                                                                    <path
                                                                                        d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="1.45882"
                                                                                        stroke-linecap="round" />
                                                                                </svg>

                                                                                <svg width="204" height="124"
                                                                                    class="illustrationDark"
                                                                                    viewBox="0 0 204 124" fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                                                                        fill="#2C2C2C" />
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984"
                                                                                        fill="#141414" />
                                                                                    <path
                                                                                        d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z"
                                                                                        fill="#9DC0FB" />
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711"
                                                                                        fill="#233655" />
                                                                                    <path
                                                                                        d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z"
                                                                                        fill="#141414" stroke="#76A7F9"
                                                                                        stroke-width="2.43137" />
                                                                                    <path
                                                                                        d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353"
                                                                                        stroke="#76A7F9"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path d="M86.3735 21.8823H117.981"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path d="M86.3735 80.2354H117.981"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path d="M86.3735 36.4707H138.648"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path d="M86.3735 51.0586H138.648"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path d="M86.3735 65.647H138.648"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="2.43137"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round" />
                                                                                    <path
                                                                                        d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z"
                                                                                        fill="#233655" stroke="#76A7F9"
                                                                                        stroke-width="1.45882" />
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44"
                                                                                        fill="#141414" />
                                                                                    <path
                                                                                        d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z"
                                                                                        stroke="#76A7F9"
                                                                                        stroke-width="1.45882"
                                                                                        stroke-linecap="round" />
                                                                                    <path
                                                                                        d="M152.335 102.501L156.943 107.169"
                                                                                        stroke="#76A7F9"
                                                                                        stroke-width="1.45882" />
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z"
                                                                                        fill="#233655" stroke="#76A7F9"
                                                                                        stroke-width="1.45882" />
                                                                                    <path
                                                                                        d="M158.991 107.688L164.623 113.393"
                                                                                        stroke="#141414"
                                                                                        stroke-width="1.31692"
                                                                                        stroke-linecap="round" />
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z"
                                                                                        fill="#233655" />
                                                                                    <path
                                                                                        d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738"
                                                                                        stroke="#9DC0FB"
                                                                                        stroke-width="1.45882"
                                                                                        stroke-linecap="round" />
                                                                                </svg>
                                                                            </div>
                                                                            <p class="p-mt-3 p-mb-0">No document
                                                                                available</p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="p-field p-d-flex p-flex-wrap p-col-12 displayEditTypeModal p-p-0">
                                                                        <div class="uploadedDocumentAttachment p-col-12 p-md-4 p-lg-4 p-flex-wrap"
                                                                            *ngFor="let item of uploadedDocuments; let i = index">
                                                                            <p-card class="attachmentCard">
                                                                                <div
                                                                                    class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-jc-between p-flex-wrap">
                                                                                    <div
                                                                                        class="p-col-10 p-d-flex p-pl-0 p-pr-0 p-ai-center">
                                                                                        <div
                                                                                            style="padding-right: 9px;">
                                                                                            <img class="uploadedThumbnail"
                                                                                                *ngIf="item.fileContentType == 'application/pdf'"
                                                                                                [src]="pdfThumb" alt="">
                                                                                            <img class="uploadedThumbnail"
                                                                                                [src]="imgThumb"
                                                                                                *ngIf="item.fileContentType == 'image/png' || item.fileContentType == 'image/jpg' || item.fileContentType == 'image/jpeg'"
                                                                                                alt="">
                                                                                            <img class="uploadedThumbnail"
                                                                                                [src]="wordThumb"
                                                                                                *ngIf="item.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || item.fileContentType == 'application/msword' "
                                                                                                alt="">
                                                                                            <img class="uploadedThumbnail"
                                                                                                *ngIf="item.fileContentType == ''"
                                                                                                [src]="mailThumb"
                                                                                                alt="">
                                                                                        </div>
                                                                                        <div
                                                                                            class="p-col-10 p-pl-0 p-pr-0">
                                                                                            <a
                                                                                                (click)="showAttachmentPreview(item)">
                                                                                                <div class="p-col-12 p-d-flex p-flex-column p-flex-wrap p-ai-start p-pl-0 contentContainer"
                                                                                                    style="text-align:left;">
                                                                                                    <div
                                                                                                        class="limit-text-length attachmentName cursor-pointer">
                                                                                                        {{item.fileName}}
                                                                                                    </div>
                                                                                                    <label
                                                                                                        class="attachmentFileType">Type:{{item.fileTypeName}}</label>
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="p-col-2 p-d-flex p-jc-end p-pr-0 attachmentAction">
                                                                                        <svg width="16" height="16"
                                                                                            viewBox="0 0 16 16"
                                                                                            (click)="showEditTypeDialog(item)"
                                                                                            class="cursor-pointer attachmentIconSvg"
                                                                                            *ngIf="isOpportunityWonRecd"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                                            <path
                                                                                                d="M7.33594 2.66699H2.66927C2.31565 2.66699 1.97651 2.80747 1.72646 3.05752C1.47641 3.30756 1.33594 3.6467 1.33594 4.00033V13.3337C1.33594 13.6873 1.47641 14.0264 1.72646 14.2765C1.97651 14.5265 2.31565 14.667 2.66927 14.667H12.0026C12.3562 14.667 12.6954 14.5265 12.9454 14.2765C13.1955 14.0264 13.3359 13.6873 13.3359 13.3337V8.66699"
                                                                                                stroke="#3E5B8C"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round" />
                                                                                            <path
                                                                                                d="M12.3359 1.66714C12.6012 1.40193 12.9609 1.25293 13.3359 1.25293C13.711 1.25293 14.0707 1.40193 14.3359 1.66714C14.6012 1.93236 14.7502 2.29207 14.7502 2.66714C14.7502 3.04222 14.6012 3.40193 14.3359 3.66714L8.0026 10.0005L5.33594 10.6671L6.0026 8.00048L12.3359 1.66714Z"
                                                                                                stroke="#3E5B8C"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round" />
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                            </p-card>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p-tabPanel>
                                                <p-tabPanel header="Document To Be Uploaded">
                                                    <div
                                                        class="p-grid p-d-flex p-flex-wrap p-col-12 p-p-0 documentToBeUploaded">
                                                        <div class="p-col-12 p-md-4 p-lg-4 attachmentCard attachmentContainer p-flex-wrap"
                                                            *ngFor="let attach of attachmentTypeList; let i = index"
                                                            [ngClass]="{'attachmentError': saveBtnClicked && attach.is_mandatory == 'M' && attach.file == '' && isAttachmentM == true}">
                                                            <p-card
                                                                [ngClass]="{'error' : saveBtnClicked && attach.is_mandatory == 'M' && attach.file == '' && isAttachmentM == true}">
                                                                <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center"
                                                                    *ngIf="!attach.file">
                                                                    <div class="p-col-7 p-pl-0">
                                                                        <label
                                                                            class="attachmentType lableToBeUploaded">{{attach.type}}
                                                                            <span class='invalid-feedback-astrik'
                                                                                *ngIf="attach.is_mandatory == 'M'">*</span>
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        class="p-col-5 p-pl-0 p-d-flex p-jc-end p-pr-0">
                                                                        <p-fileUpload customUpload="true"
                                                                            id="fileUpload1" name="file"
                                                                            accept="image/*, .pdf, .jpeg , .jpg, .png, .doc, .docx, .msg"
                                                                            maxFileSize=20000000 mode="advanced"
                                                                            [showUploadButton]="false"
                                                                            [showCancelButton]="false"
                                                                            #fileUploadfileUpload13
                                                                            (onSelect)="onFileUpload($event, attach.type, attach.code, attach.id)"
                                                                            chooseLabel="Choose File"
                                                                            [disabled]="attach.isDisabled || reportingReadScope">
                                                                        </p-fileUpload>
                                                                    </div>
                                                                </div>
                                                                <div class="p-col-12 p-px-0 p-d-flex p-ai-center p-flex-wrap"
                                                                    *ngIf="attach.file">
                                                                    <div
                                                                        class="p-col-10 p-px-0 p-d-flex p-ai-center p-flex-nowrap">
                                                                        <div class="p-col-2 p-p-0">
                                                                            <img [src]="attach.thumbNail" alt="">
                                                                        </div>
                                                                        <div
                                                                            class="p-col-10 p-p-1 p-d-flex p-flex-column p-flex-wrap">
                                                                            <div
                                                                                class="attachmentName limit-text-length">
                                                                                {{attach.fileName}} </div>
                                                                            <label class="attachmentFileType">Type:
                                                                                {{attach.type}}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-2 p-pr-0 p-d-flex p-jc-end">
                                                                        <i class="pi pi-trash cursor-pointer attachmentIcon"
                                                                            aria-hidden="true"
                                                                            (click)="onDeletePopup(attach.type, attach.code, attach.deleteId)"></i>
                                                                    </div>
                                                                </div>
                                                            </p-card>
                                                            <div class="required invalid-feedback p-d-flex p-ai-center"
                                                                *ngIf="saveBtnClicked && attach.is_mandatory == 'M' && attach.file == '' && isAttachmentM == true">
                                                                <i class="pi pi-exclamation-circle p-mr-1"
                                                                    aria-hidden="true"></i>Attachment Required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p-tabPanel>
                                            </p-tabView>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel>
                                    <ng-template pTemplate="header"><span
                                            [ngClass]="{'errorTab': errorContactDetails}">Contact Details</span>
                                    </ng-template>
                                    <form [formGroup]="contactDetailsform">
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div>
                                                            <h1 class="p-medium display-3">Basic Info</h1>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-d-flex p-jc-end">
                                                        <div class="btnwrap">
                                                            <button pButton
                                                                [disabled]="isOpportunityReportInvoice || isOpporutnityOtherStatus || reportingReadScope || addnPaymentReportOnly"
                                                                style="height: 28px"
                                                                class="p-button-secondary p-button-sm" type="submit"
                                                                label="Get CRM Data" (click)="getCrmData()"> </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Salutation</label>
                                                            <p-dropdown placeholder="Select"
                                                                [disabled]="disabledDropdown"
                                                                formControlName="salutation" [options]="salutationList"
                                                                resetFilterOnHide="true" optionLabel="name"
                                                                optionValue="name" [autoDisplayFirst]="false">
                                                            </p-dropdown>
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.salutation; else salutation">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.salutation?.toLowerCase() == crmSingleContact?.salutation?.toLowerCase()}">
                                                                    {{crmSingleContact?.salutation}}
                                                                </div>
                                                            </ng-container>
                                                            <ng-template #salutation>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>First Name</label>
                                                            <input type="text" pInputText placeholder="Enter First Name"
                                                                formControlName="firstName" [readonly]="true"
                                                                class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.firstName; else firstname">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.firstName?.toLowerCase() == crmSingleContact?.firstName?.toLowerCase()}">
                                                                    {{crmSingleContact?.firstName}}</div>
                                                            </ng-container>
                                                            <ng-template #firstname>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>Last Name</label>
                                                            <input type="text" pInputText placeholder="Enter Last Name"
                                                                formControlName="lastName" [readonly]="true"
                                                                class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.lastName; else lastname">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.lastName?.toLowerCase() == crmSingleContact?.lastName?.toLowerCase()}">
                                                                    {{crmSingleContact?.lastName}}</div>
                                                            </ng-container>
                                                            <ng-template #lastname>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Designation</label>
                                                            <p-dropdown name="reasonselect" placeholder="Select"
                                                                [disabled]="disabledDropdown"
                                                                [options]="designationList" resetFilterOnHide="true"
                                                                optionLabel="name" optionValue="name"
                                                                [autoDisplayFirst]="false"
                                                                formControlName="designation">
                                                            </p-dropdown>
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.designation; else designation">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.designation?.toLowerCase() == crmSingleContact?.designation?.toLowerCase()}">
                                                                    {{crmSingleContact?.designation}}</div>
                                                            </ng-container>
                                                            <ng-template #designation>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>Mobile No</label>
                                                            <input type="text" pInputText placeholder="Enter Mobile No"
                                                                formControlName="mobileNo" [readonly]="true"
                                                                class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.mobileNum; else mobile">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.mobileNo == crmSingleContact?.mobileNum}">
                                                                    {{crmSingleContact?.mobileNum}}</div>
                                                            </ng-container>
                                                            <ng-template #mobile>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>Landline No</label>
                                                            <input type="text" pInputText placeholder="Enter Landline No"
                                                                formControlName="landlineNo" [readonly]="true"
                                                                class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.phoneNum; else landline">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.landlineNo == crmSingleContact?.phoneNum}">
                                                                    {{crmSingleContact?.phoneNum}}</div>
                                                            </ng-container>
                                                            <ng-template #landline>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Email 1</label>
                                                            <input type="text" pInputText placeholder="Enter Email 1"
                                                                formControlName="email1" [readonly]="true" class="p-inputtext" />
                                                            <ng-container *ngIf="crmEmail1; else email_1">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': email1 == crmEmail1}">
                                                                    {{crmEmail1}}</div>
                                                            </ng-container>
                                                            <ng-template #email_1>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Email 2</label>
                                                            <input type="text" pInputText placeholder="Enter Email 2"
                                                                formControlName="email2" [readonly]="true" class="p-inputtext" />
                                                            <ng-container *ngIf="crmEmail2; else email_2">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': email2 == crmEmail2}">
                                                                    {{crmEmail2}}</div>
                                                            </ng-container>
                                                            <ng-template #email_2>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Email 3</label>
                                                            <input type="text" pInputText placeholder="Enter Email 3"
                                                                formControlName="email3" [readonly]="true" class="p-inputtext" />
                                                            <ng-container *ngIf="crmEmail3; else email_3">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': email3 == crmEmail3}">
                                                                    {{crmEmail3}}</div>
                                                            </ng-container>
                                                            <ng-template #email_3>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-mt-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                        <h1 class="p-medium display-3">GST &amp; PAN Details</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>GST Type</label>
                                                            <p-dropdown name="reasonselect" placeholder="Select"
                                                                [disabled]="disabledDropdown" [options]="gstTypeList"
                                                                resetFilterOnHide="true" optionLabel="property"
                                                                optionValue="property" [autoDisplayFirst]="false"
                                                                formControlName="gstType">
                                                            </p-dropdown>
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.gstType; else gstType">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.gstType?.toLowerCase() == crmSingleContact?.gstType?.toLowerCase()}">
                                                                    {{crmSingleContact?.gstType}}</div>
                                                            </ng-container>
                                                            <ng-template #gstType>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>GST No</label>
                                                            <input type="text" pInputText placeholder="Enter GST No"
                                                                formControlName="gstNo" [readonly]="true" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.gstNumber; else gstno">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.gstNo?.toLowerCase() == crmSingleContact?.gstNumber?.toLowerCase()}">
                                                                    {{crmSingleContact?.gstNumber}}</div>
                                                            </ng-container>
                                                            <ng-template #gstno>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label> PAN Number </label>
                                                            <input type="text" pInputText placeholder="Enter PAN Number"
                                                                formControlName="panNo" [readonly]="true" class="p-inputtext" />
                                                            <ng-container *ngIf="crmSingleContact?.pan; else panno">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.panNo?.toLowerCase() == crmSingleContact?.pan?.toLowerCase()}">
                                                                    {{crmSingleContact?.pan}}</div>
                                                            </ng-container>
                                                            <ng-template #panno>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-mt-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                        <h1 class="p-medium display-3">Address Details</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Pin Code</label>
                                                            <input type="text" pInputText placeholder="Enter Pin Code"
                                                                formControlName="pincode" [readonly]="true"
                                                                class="p-inputtext" />
                                                            <ng-container *ngIf="crmSingleContact?.pin; else pin">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.pincode == crmSingleContact?.pin}">
                                                                    {{crmSingleContact?.pin}}</div>
                                                            </ng-container>
                                                            <ng-template #pin>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>City</label>
                                                            <input type="text" pInputText placeholder="Enter City"
                                                                formControlName="city" [readonly]="true" class="p-inputtext" />
                                                            <ng-container *ngIf="crmSingleContact?.city; else city">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.city?.toLowerCase() == crmSingleContact?.city?.toLowerCase()}">
                                                                    {{crmSingleContact?.city}}</div>
                                                            </ng-container>
                                                            <ng-template #city>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>State</label>
                                                            <input type="text" pInputText placeholder="Enter State"
                                                                formControlName="state" [readonly]="true" class="p-inputtext" />
                                                            <ng-container *ngIf="crmSingleContact?.state; else state">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.state?.toLowerCase() == crmSingleContact?.state?.toLowerCase()}">
                                                                    {{crmSingleContact?.state}}</div>
                                                            </ng-container>
                                                            <ng-template #state>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Address Line 1</label>
                                                            <input type="text" pInputText placeholder="Enter Address Line 1"
                                                                formControlName="address1" [readonly]="true"
                                                                class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.address1; else address1">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.address1?.toLowerCase() == crmSingleContact?.address1?.toLowerCase()}">
                                                                    {{crmSingleContact?.address1}}</div>
                                                            </ng-container>
                                                            <ng-template #address1>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>Address Line 2</label>
                                                            <input type="text" pInputText placeholder="Enter Address Line 2"
                                                                formControlName="address2" [readonly]="true"
                                                                class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.address2; else address2">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.address2?.toLowerCase() == crmSingleContact?.address2?.toLowerCase()}">
                                                                    {{crmSingleContact?.address2}}</div>
                                                            </ng-container>
                                                            <ng-template #address2>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>Address Line 3</label>
                                                            <input type="text" pInputText placeholder="Enter Address Line 3"
                                                                formControlName="address3" [readonly]="true"
                                                                class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.address3; else address3">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.address3?.toLowerCase() == crmSingleContact?.address3?.toLowerCase()}">
                                                                    {{crmSingleContact?.address3}}</div>
                                                            </ng-container>
                                                            <ng-template #address3>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Address Line 4</label>
                                                            <input type="text" pInputText placeholder="Enter Address Line 4"
                                                                formControlName="address4" [readonly]="true"
                                                                class="p-inputtext6" />
                                                            <ng-container
                                                                *ngIf="crmSingleContact?.address4; else address4">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.address4?.toLowerCase() == crmSingleContact?.address4?.toLowerCase()}">
                                                                    {{crmSingleContact?.address4}}</div>
                                                            </ng-container>
                                                            <ng-template #address4>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-mb-0 p-mt-5">
                                                            <div
                                                                class="p-fluid p-field p-d-flex p-flex-row p-ai-center p-mb-0">
                                                                <p-checkbox binary="true"
                                                                    formControlName="contactVerified"
                                                                    [disabled]="isOpportunityReportInvoice || isOpporutnityOtherStatus || disableCheckBox"
                                                                    (onChange)="contactVerified($event)"
                                                                    [(ngModel)]="contactChecked"
                                                                    inputId="binary"></p-checkbox>
                                                                <label class="p-ml-2 p-mt-1">Contact details checked &
                                                                    verified <span
                                                                        class='invalid-feedback-astrik'>*</span></label>
                                                            </div>
                                                            <div class="invalid-feedback"
                                                                *ngIf="!contactChecked && saveBtnClicked">
                                                                This field is mandatory.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </p-tabPanel>
                                <p-tabPanel>
                                    <ng-template pTemplate="header">
                                        <span [ngClass]="{'errorTab': errorAction}">Action</span>
                                    </ng-template>
                                    <form [formGroup]="actionForm">
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class=" p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class="p-fluid p-field">
                                                            <label>Business Action
                                                                <span class='invalid-feedback-astrik'>*</span>
                                                            </label>
                                                            <div class="businessAction"
                                                                *ngIf="!(addnPaymentCase && billIdFinalised) || !(addnPaymentCase && billIdFinalised && actionForm.value.businessAction != 'REPORT_AND_INVOICE')">
                                                                <p-dropdown name="reasonselect"
                                                                    formControlName="businessAction"
                                                                    placeholder="Select a Reason" [options]="baList"
                                                                    resetFilterOnHide="true" optionLabel="name"
                                                                    optionValue="code" [autoDisplayFirst]="false"
                                                                    [disabled]="isOpporutnityOtherStatus || isOpportunityReportInvoice"
                                                                    (onChange)="businessActionChanged($event)"
                                                                    id="businessAction"
                                                                    [ngClass]="{error:isBAaction || ((actionForm.get('businessAction')?.errors?.required && actionForm.get('businessAction').invalid) && (actionForm.get('businessAction').dirty || actionForm.get('businessAction').touched))}">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="p-fluid p-field"
                                                                *ngIf="addnPaymentCase && billIdFinalised && actionForm.value.businessAction != 'REPORT_AND_INVOICE'">
                                                                <input pInputText value="{{tempStatus}}" disabled />
                                                            </div>
                                                            <div class="invalid-feedback"
                                                                *ngIf="isBAaction || ((actionForm.get('businessAction')?.errors?.required && actionForm.get('businessAction').invalid) && (actionForm.get('businessAction').dirty || actionForm.get('businessAction').touched))">
                                                                Business Action is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left"
                                                        *ngIf="isActionReasonM">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Reason<span *ngIf="isActionReasonM"
                                                                    class='invalid-feedback-astrik'>*</span>
                                                            </label>
                                                            <div  class="reasonMultiSelectDropdown" *ngIf="baValue == 'REPORT_ONLY'">
                                                                <p-multiSelect [options]="actionReasonList"
                                                                    optionLabel="name" optionValue="name"
                                                                    id="actionReason"
                                                                    [disabled]="isOpporutnityOtherStatus || isOpportunityReportInvoice  || (addnPaymentCase && billIdFinalised)"
                                                                    [filter]="true" formControlName="actionReason"
                                                                    placeholder="Select a Reason"
                                                                    (onChange)="reasonSelected($event)"
                                                                    [ngClass]="{error:(actionReasonError && this.actionForm?.value?.actionReason == null) || ((actionForm.get('actionReason')?.errors?.required && actionForm.get('actionReason').invalid) && (actionForm.get('actionReason').dirty || actionForm.get('actionReason').touched))}"></p-multiSelect>
                                                            </div>
                                                            <div *ngIf="baValue == 'REJECT'">
                                                                <p-dropdown name="reasonselect"
                                                                    placeholder="Select a Reason"
                                                                    [options]="actionReasonList" [filter]="true"
                                                                    resetFilterOnHide="true" optionLabel="name"
                                                                    optionValue="name"
                                                                    [disabled]="isOpporutnityOtherStatus"
                                                                    [autoDisplayFirst]="false"
                                                                    formControlName="actionReason"
                                                                    (onChange)="reasonSelected($event)"
                                                                    [ngClass]="{error:(actionReasonError && this.actionForm?.value?.actionReason == null) || ((actionForm.get('actionReason')?.errors?.required && actionForm.get('actionReason').invalid) && (actionForm.get('actionReason').dirty || actionForm.get('actionReason').touched))}">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="invalid-feedback"
                                                                *ngIf="(actionReasonError && this.actionForm?.value?.actionReason == null) || (actionForm.get('actionReason')?.errors?.required && (actionForm.get('actionReason').dirty || actionForm.get('actionReason').touched))">
                                                                Reason is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field p-d-flex p-flex-column">
                                                            <div class="p-d-flex">
                                                                <label>
                                                                    Comment <span *ngIf="isActionCommentM"
                                                                        class='invalid-feedback-astrik'>*</span>
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <textarea pInputTextarea name="actionComment" rows="3"
                                                                    placeholder="Enter Comment" [maxlength]="commentLength"
                                                                    id="actionComment" formControlName="actionComment"
                                                                    [readonly]="isOpporutnityOtherStatus || isOpportunityReportInvoice"
                                                                    [disabled]="isOpporutnityOtherStatus || isOpportunityReportInvoice"
                                                                    [ngClass]="{error:(isActionCommentError && actionForm.get('actionComment')?.errors?.required) && (actionForm.get('actionComment').invalid || actionForm.get('actionComment').dirty || actionForm.get('actionComment').touched)}"></textarea>
                                                                <div class="invalid-feedback"
                                                                    *ngIf="isActionCommentError && (isActionCommentError && actionForm.get('actionComment')?.errors?.required) && (actionForm.get('actionComment').invalid || actionForm.get('actionComment').dirty || actionForm.get('actionComment').touched)">
                                                                    Comment is required.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </p-tabPanel>
                            </p-tabView>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 popupFooterBtnContainer">
                        <div
                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-end p-ai-center popupFooterBtnContent p-pr-3 p-mt-2 p-mb-2">
                            <div class="btnwrap">
                                <button pButton class="p-button-brand2 p-button" type="button"
                                    (click)="closeReportingPopup()" label="Cancel"></button>
                            </div>
                            <div class="btnwrap">
                                <button pButton class="p-button-primary p-button p-mr-0" type="submit"
                                    [disabled]="((isOpporutnityOtherStatus || isOpportunityReportInvoice || reportingReadScope || disabledOnApiFail) && !addnPaymentCase)"
                                    (click)="saveReporting()" label="Save"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="no_data_div" *ngIf="uploadLoader">
        <div class="app-loading" id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>

        </div>
    </div>
</div>
<p-dialog header="Crop Image" [(visible)]="displayModal" (onHide)="uploadLoader=false;" [modal]="true"
    [style]="{width: '60vw', height: '80vh'}" [draggable]="true" [resizable]="false" maskStyleClass="negativemask">
    <div class="p-col-12 p-text-center popupAttachmentBodyScrollContainer">
        <image-cropper styleClass="imageCropper" class="crop-content" [imageChangedEvent]="imgChangeEvt"
            [canvasRotation]="canvasRotation" [imageFile]="imageChangedEventfileUpload1" [maintainAspectRatio]="false"
            format="jpeg" [imageQuality]="75" (imageCropped)="imageCroppedfileUpload($event)" id="image_cropper">
        </image-cropper>
        <i class="pi pi-replay p-mr-3 p-pt-2" style="cursor:pointer;" (click)="rotateLeft()" aria-hidden="true"></i>
        <i class="pi pi-refresh p-pt-2" style="cursor:pointer;" (click)="rotateRight()" aria-hidden="true"></i>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="displayModal=false" label="Cancel" class="p-button-brand2"></button>
        <button pButton type="button" label="Upload" class="p-button-primary" (click)="onImgUpload()"></button>
    </ng-template>
</p-dialog>


<p-dialog header="Change Attachment Type" [(visible)]="displayEditTypeModal"
    class="displayEditTypeModal attachmentTypeChange" [modal]="true" [style]="{width: '35vw', overflow: 'visible'}"
    [draggable]="false" [closable]="false" [resizable]="false">
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-px-0">
            <label>Attachment Type</label>
            <p-dropdown [options]="attachmentChangeTypeList" [(ngModel)]="updatedAttachmentCode"
                (onChange)="changed($event)" placeholder="Select Type" optionLabel="type"
                optionValue="code"></p-dropdown>
            <input type="text" pInputText class="p-inputtext p-mt-1" [(ngModel)]="attachmentComment"
                placeholder="Comment" *ngIf="showComment">
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton (click)="closeAttachmentTypeModal()" label="Cancel" class="p-button-brand2"></button>
        <button pButton label="Save" (click)="onUpdateAttachmentType()" class="p-button-primary p-mr-0"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Delete" [(visible)]="deleteFile" class="DeleteFileModal" [modal]="true" [style]="{width: '27vw'}"
    [draggable]="false" [resizable]="false">
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-p-0">
            <p>Are you sure you want to delete?</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton (click)="deleteFile=false" label="No" class="p-button-secondary"></button>
        <button pButton label="Yes" (click)="onDelete()" class="p-button-primary"></button>
    </ng-template>
</p-dialog>

<p-dialog header="CRM Contacts" [(visible)]="crmContactPopup" class="crmContact" [modal]="true"
    [style]="{width: '45vw'}" [draggable]="false" [resizable]="false">
    <ng-container *ngIf="crmContacts?.length > 0; else empty">
        <div class="p-col-12 p-p-0 p-d-flex" *ngFor="let item of crmContacts">
            <div class="field-radiobutton" style="width: 5%">
                <p-radioButton name="crmcontacts" [value]="item.contactId" [(ngModel)]="selectedCrmContact"
                    (onClick)="crmContactClicked()"></p-radioButton>
            </div>
            <p-card class="p-ml-1 p-col-12 p-p-0 p-mb-2" style="width: 93%">
                <h1 class="p-medium display-4">{{item?.firstName}} {{item?.lastName}}</h1>
                <div class="p-grid p-nogutter">
                    <div class="p-col-6">
                        <div class="p-grid p-nogutter">
                            <div class="p-col-3">
                                <label>GST No: </label>
                            </div>
                            <div class="p-col-8">
                                <span>{{item?.gstNumber}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="p-grid p-nogutter">
                            <div class="p-col-4">
                                <label> Contact ID: </label>
                            </div>
                            <div class="p-col-8">
                                <span> {{item?.contactId}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
    </ng-container>
    <ng-template #empty>No contacts available.</ng-template>
    <ng-template pTemplate="footer">
        <button pButton (click)="crmContactPopup=false" label="Cancel" class="p-button-brand2"></button>
        <button pButton (click)="crmContactSelected()" [disabled]="disabledCrmApplyBtn" label="Apply"
            class="p-button-primary"></button>
    </ng-template>
</p-dialog>
<p-dialog header="Preview" [(visible)]="displayPreview" class="docPreview previewModal" [modal]="true"
    [style]="{width: '95vw', height: '100vh'}" [draggable]="false" [resizable]="false">
    <div class="no_data_div tableLoader" *ngIf="dataLoader">
        <div class="app-loading " id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label>Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <pdf-viewer id="pdf_viewer" *ngIf="isPdf" [src]="urlSafe" [rotation]="rotate" [original-size]="false"
        [show-all]="true" [fit-to-page]="false" [zoom]="zoomValue" [zoom-scale]="'page-width'" [stick-to-page]="false"
        [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="width: 100%; height:100%;"></pdf-viewer>
    <div class="imagePreviewContainer p-d-flex p-jc-center p-ai-center">
        <img *ngIf="!isPdf" src="{{urlSafe}}" id="imagePrev" alt="Image">
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-secondary"></button>
        <button pButton type="button" label="Download" (click)="downloadFile()" class="p-button-secondary"></button>
    </ng-template>
</p-dialog>


<p-dialog header="Alert" [(visible)]="validationPopup" [modal]="true" [style]="{width: '30vw'}" [draggable]="false"
    [resizable]="false">
    <div class="p-col-12 p-px-1 p-px-0">
        <p class="p-mb-0">There are few details are missing in {{errorMessageOnSave}}. <br>
            Please enter in order to save the reporting.</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="validationPopup=false" type="button"
                label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="invoiceGenereatedPopup" [modal]="true" [style]="{width: '40vw', height: 'auto'}"
    [draggable]="false" [resizable]="false">
    <div *ngIf="!addnPaymentCase" class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-px-0">
        <p>Invoice Already Generated.<br>
            Select Report Only.</p>
    </div>
    <div *ngIf="addnPaymentCase" class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-px-0">
        <p>Invoice Already Generated.<br>
            Proceed to Save Additional Payment.</p>
    </div>
    <ng-template pTemplate="footer">
        <div *ngIf="!addnPaymentCase" class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="invoiceGenereatedPopup=false"
                type="button" label="Ok">
            </button>
        </div>
        <div *ngIf="addnPaymentCase" class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button"
                (click)="invoiceGenereatedPopup=false;proceedSave=true;" type="button" label="Ok">
            </button>
            <button pButton class="p-button-brand2 p-button" type="button" (click)="closeReportingPopup()"
                label="Cancel"></button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="contactDetailsMatchPopup" [modal]="true" [style]="{width: '40vw', height: 'auto'}"
    [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-px-0" style="overflow-y: hidden;">
        <p>Contact details are mismatch.</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="checkContact()" type="button"
                label="Check Contact">
            </button>
            <button pButton id="save" class="p-button-primary p-button" (click)="continueBilling()" type="button"
                label="Continue Billing">
            </button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="reportedAndInvoiced" [modal]="true" [style]="{width: '40vw', height: 'auto'}"
    [draggable]="false" [resizable]="false" [closable]="false">
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-px-0" style="overflow-y: hidden;">
        <p>Reported and Sent for Invoice.</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="closePopUp()" type="button" label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: 'auto'}" [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="previewFailed" [modal]="true"
    [style]="{width: '55vw', height: 'auto'}" [draggable]="false" [resizable]="false">
    <p>Unable to preview attachment, Please try again.</p>
</p-dialog>

<p-dialog header="Alert" [(visible)]="rptPopup" [modal]="true" [style]="{width: '40vw', height: 'auto'}"
    [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-px-0" style="overflow-y: hidden;">
        <p>RPT Case. Seek Approval.</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="rptPopup=false" type="button"
                label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="gstPopUp" [modal]="true" [style]="{width: '40vw', height: 'auto'}"
    [draggable]="false" [resizable]="false" [closable]="false">
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-px-0" style="overflow-y: hidden;">
        <p>Contact (GST Type) and TDS Type mismatch.</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="gstPopUp=false;contactGSTValidation();"
                type="button" label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="crmContactNotSelectedPopup" [modal]="true"
    [style]="{width: '40vw', height: 'auto'}" [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-px-0">
        <p>CRM Contact not Selected. <br>
            Please select in order to save.</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="crmContactNotSelectedPopup=false"
                type="button" label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog>