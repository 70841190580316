import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AGREEMENTARCHIVE_ROUTE } from './agreement-archive.route';
import { ByClientComponent } from './by-client/by-client.component';
import { RouterModule } from '@angular/router';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AgreementDetailsComponent } from './agreement-details/agreement-details.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CardModule } from 'primeng/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { HttpClientModule } from '@angular/common/http';

import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ViewBillDetailsComponent } from './view-bill-details/view-bill-details.component';
import { ViewBillPaymentDetailsComponent } from './view-bill-payment-details/view-bill-payment-details.component';
import { QcFormComponent } from './qc-form/qc-form.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PortfolioDetailsComponent } from './portfolio-details/portfolio-details.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';


@NgModule({
  declarations: [
    ByClientComponent,
    AgreementDetailsComponent,
    ViewBillDetailsComponent,
    ViewBillPaymentDetailsComponent,
    QcFormComponent,
    PortfolioDetailsComponent
  ],
  imports: [
    CommonModule,
    BreadcrumbModule,
    SplitButtonModule,
    CardModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ReactiveFormsModule,
    MessagesModule,
    TableModule,
    CalendarModule,
    HttpClientModule,
    InputTextModule,
    DropdownModule,
    DialogModule,
    RadioButtonModule,
    PdfViewerModule,
    CheckboxModule,
    AutoCompleteModule,
    RouterModule.forChild(AGREEMENTARCHIVE_ROUTE),
  ]
})
export class AgreementArchiveModule { }
