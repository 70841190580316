<div class="layout-content p-pt-5 p-mt-2">

    
    <p-messages class="custom-message"></p-messages>
    <div class="p-formgrid p-grid breadcrumb p-m-0">
        <div class=" p-col-12">
            <div class="ui-fluid p-formgrid p-grid p-ai-center p-m-0">
                <div class="p-col-10 p-lg-9 p-xl-8  p-text-left">
                    <div class=" p-fluid   p-field   p-mb-0 ">
                        <p-breadcrumb id="applicationBreadcrumb_id" name="applicationBreadcrumb_name" class=""
                            [model]="applicationBreadcrumbList" [home]="homeiconapplicationBreadcrumb">
                        </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form>
        <div class="p-formgrid p-grid formFieldContainer">
            <!-- Client Details -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="Client Details">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Reference Number:</label>
                                <span class="desc">{{view_bill_details?.referenceNumber}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">BD Manager:</label>
                                <span class="desc">{{view_bill_details?.bdManager}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Client:</label>
                                <span class="desc">{{view_bill_details?.client}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Client Contact:</label>
                                <span class="desc">{{view_bill_details?.clientContact}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Actual IRF Fee:</label>
                                <span class="desc">{{view_bill_details?.actualIrfFee}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Operating Cost Center:</label>
                                <span class="desc">{{view_bill_details?.operatingCostCenter}}</span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
            
            <!-- Deviation Approval -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="Deviation Approval">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Invoice Before Payment:</label>
                                <span class="desc">{{view_bill_details?.invoiceBeforePayment}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light"></label>
                                <span class="desc"></span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light"></label>
                                <span class="desc"></span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Deviation Approval Attachment:</label>
                                <!-- <span class="desc">{{view_bill_details?.filePath}}</span> -->
                                <span class="desc"><a
                                    (click)="showDeviationFile(view_bill_details?.fileBase64, view_bill_details?.fileName)">
                                    {{view_bill_details?.fileName}}
                                </a></span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
            
            <!-- Agreement Details -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="Billing information">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Bill ID:</label>
                                <span class="desc">{{view_bill_details?.billId}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Account Bill No:</label>
                                <span class="desc">{{view_bill_details?.accountBillNo}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">GST TDS Number:</label>
                                <span class="desc">{{view_bill_details?.gstTdsnumber}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">GST Number:</label>
                                <span class="desc">{{view_bill_details?.gstNumber}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Client State Code:</label>
                                <span class="desc">{{view_bill_details?.clientStateCode}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">GST Category:</label>
                                <span class="desc">{{view_bill_details?.gstCategory}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Billing Client:</label>
                                <span class="desc">{{view_bill_details?.billingClient}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Billing Contact:</label>
                                <span class="desc">{{view_bill_details?.billingContact}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Naration:</label>
                                <span class="desc">{{view_bill_details?.narration}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Billing Amount:</label>
                                <span class="desc">{{view_bill_details?.billingAmount}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light"></label>
                                <!-- <span class="desc">{{itemsIRFBillPaymentView?.}}</span> -->
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light"></label>
                                <!-- <span class="desc">{{itemsIRFBillPaymentView?.}}</span> -->
                            </div>
                        </div>
    
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Total ST/GST:</label>
                                <span class="desc">{{view_bill_details?.totalStGst}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Billing Amount with ST/GST:</label>
                                <span class="desc">{{view_bill_details?.billingAmountWithTax}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light"></label>
                                <!-- <span class="desc">{{view_bill_details?.}}</span> -->
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
            
            <!-- Agreement Details -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="History">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Revised by:</label>
                                <span class="desc">{{view_bill_details?.revisedBy}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Revision Date:</label>
                                <span class="desc">{{view_bill_details?.revisionDate}}</span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </form>
</div>
<div class="p-col-12 p-d-flex p-jc-between p-button-container p-ai-center">
    <div class="p-col-12 p-d-flex p-text-left p-jc-between p-pl-0">
        <div class="btnwrap">
            <button pButton class="p-button-brand2 p-button" type="button" (click)="onBackClick();" label="Back">
            </button>
        </div>
    </div>
</div>

<p-dialog header="Preview" [(visible)]="displayPreview" class="docPreview previewModal" [modal]="true"
    [style]="{width: '95vw', height: '100vh'}" [draggable]="false" [resizable]="false">
    <div class="no_data_div tableLoader" *ngIf="dataLoader">
        <div class="app-loading " id="loader">    
            <div class="loadbg"></div>    
            <div class="bdload">    
                <div class="spinner">    
                    <div class="rect1"></div>    
                    <div class="rect2"></div>    
                    <div class="rect3"></div>    
                    <div class="rect4"></div>    
                    <div class="rect5"></div>    
                    <span>Loading...</span>    
                </div>    
            </div> 
        </div>    
    </div>
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label>Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus" class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh" class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <pdf-viewer
      id="pdf_viewer"
      [src]="urlSafe"
      [original-size]="false"
      [show-all]="true"
      [fit-to-page]="false"
      [zoom]="zoomValue"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      style="width: 100%; height:100%;"
    ></pdf-viewer>
    <!-- <div class="imagePreviewContainer p-d-flex p-jc-center p-ai-center">
        <img *ngIf="!isPdf" src="{{urlSafe}}" id="imagePrev" alt="Image">
    </div> -->
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Download" (click)="downloadFile()" class="p-button-secondary"></button>
    </ng-template>
</p-dialog>

<!-- error modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>