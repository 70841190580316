import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'nexera-empty-route',
  template: '',
})
export class EmptyRouteComponent {

  constructor(private router: Router){
//       this.router.navigateByUrl('/nexera/dashboard/home');

    window.location.href = window.location.origin+"/#/nexera/dashboard/home";
  }


}
