import { Component, OnInit ,ViewChildren,ViewChild} from '@angular/core';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { Location } from '@angular/common';
import { SfBillTrackingAdvanceFilterComponent } from '../sf-bill-tracking-advance-filter/sf-bill-tracking-advance-filter.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { TableCheckbox } from 'primeng/table';
import { SfBillTrackingDetailsScreenComponent } from '../sf-bill-tracking-details-screen/sf-bill-tracking-details-screen.component';
import { SfBillingService } from '../sf-billing.service';
import { MessageService } from 'primeng/api';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
@Component({
  selector: 'backoffice-sf-bill-tracking',
  templateUrl: './sf-bill-tracking.component.html',
  styleUrls: ['./sf-bill-tracking.component.scss'],
  providers:[MessageService, DialogService]

})
export class SfBillTrackingComponent implements OnInit {
  @ViewChildren('mainGrid') mainGrid;
  @ViewChild('checkboxRef', { static: false }) checkboxRef!: TableCheckbox;
  ccprimebreadcrumbList = [
    { label: `SF Bill Tracking`, routerLink: ['/sf-billing/sf-bill-tracking'] },
  ];

  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  disableSearchBtn: boolean = false;
  searchedEditList: any[] = [];
  loadingStatus = false; 
  infoMsg: any;
  userId:any;
  financialYear;
  entityStatus;
  clientType;
  sfBillIdList = [];
  pageLoader: boolean = true;
  disableProcessBtn: boolean = true;
  dynamicTableDialogEntityNAMERef: DynamicDialogRef = new DynamicDialogRef;
  gridLoader: boolean;
  apiFail: boolean;
  allchecked: boolean = false;
  scopeList: any;
  readScope: boolean = false;

  constructor(private _location: Location,private dialogService: DialogService,private router: Router,private sfBillingService: SfBillingService,private encyDyc: EncryptDecryptInterceptor, private messageService: MessageService,) {
    this.userId = this.encyDyc.decrypt(localStorage.getItem('empid'));
    const navigation = this.router.getCurrentNavigation();
    this.infoMsg = navigation?.extras?.state?.msg;
  }

  ngOnInit(): void {
    this.scopeList = this.encyDyc.decrypt(localStorage.getItem('scopes'));
    let scope: any = this.scopeList.split(",");
    if(scope.indexOf('BILLING_TRACKING_CREATE') == -1){
      this.readScope = true;
    } else {
      this.readScope = false;
    }
  }

  homeBreadcrumbClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}/#/nexera/dashboard/home`;
    }
  }

  selectedAll(checked){
    if(checked){
      let data = this.sfBillIdList.filter((b) => {
        return (b.billStatus != '151' && b.feeCapClassification == 'N');
      });
      this.sfBillIdList = data;
    }
  }

  onCheckBoxChange(){
    if(this.sfBillIdList?.length > 0){
      this.allchecked = true;
    }else{
      this.allchecked = false;
    }
    
  }
 
  searchList(){
       this.dynamicTableDialogEntityNAMERef = this.dialogService.open(SfBillTrackingAdvanceFilterComponent, {
      contentStyle: { "height": "70vh", "overflow": "auto" },
      closeOnEscape: false,
      closable: false,
      header: "Search Filter",
      width: '96%',
    
    });
    this.dynamicTableDialogEntityNAMERef.onClose.subscribe(response=>{

      this.sfBillIdList =[];
      this.allchecked = false;
      if(response){
        this.searchedEditList=response;
      }
      
    })
  }
  
  onBackClick(): void {
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  selectAllRows(sfBillId,companyName){
        console.log("sfbillid",sfBillId);
        console.log("companyName",companyName);
        this.dynamicTableDialogEntityNAMERef = this.dialogService.open(SfBillTrackingDetailsScreenComponent, {
          contentStyle: {  "overflow": "auto" },
          closeOnEscape: false,
          closable: true,
          header: companyName,
          width: '96%',
          data:sfBillId,
        });
      
  }
 
  showCompanyDetailsPage(opp:any){
    console.log("clicked company details", opp.sfBillId);
    this.router.navigate(['//sf-bill-tracking-details-screen',opp.sfBillId])
  }

  SentToBD(){
    this.allchecked = false;
    console.log("grid sfbillid :",this.sfBillIdList);
     this.sfBillIdList = this.sfBillIdList.map(data => data.sfBillId);
     console.log("ALL  sfbillid push to BD:",this.sfBillIdList);
    console.log("UserId:",this.userId );
    let sfBillData = this.sfBillIdList.slice(0,500);
    this.gridLoader = true;
    let payload = {
      "billStatus": 147,
      "sfBillId":sfBillData,
      "loggedInUserId":this.userId
    }

    this.sfBillingService.sfBillTrackingupdateStatus(payload).subscribe({
      next: (sfStatusDetails: Array<any>) => {
        this.searchedEditList = sfStatusDetails;
        this.gridLoader = false;
        this.pageLoader = false;
 
        if(sfStatusDetails){
          this.messageService.add({ severity: 'success', summary: 'SF bill details sent to BD successfully'});
        }
        
        setTimeout(() => {
          this.messageService.clear();
          this.searchedEditList = [];
        }, 1000);
      
      },
      error: (err) => {
        this.allchecked = true;
        this.gridLoader = false;
        this.apiFail = true;
        this.messageService.add({ severity: 'error', summary: 'Something went wrong! Please try again' });
        setTimeout(() => {
          this.messageService.clear();
        }, 1000);
      }
    })
  }

  SentToFin(){
    this.allchecked = false;
    console.log("grid sfbillid :",this.sfBillIdList);
    this.sfBillIdList = this.sfBillIdList.map(data => data.sfBillId);
    console.log("ALL  sfbillid push to fin :",this.sfBillIdList);
    console.log("UserId:",this.userId );
    this.gridLoader = false;
    let sfBillData = this.sfBillIdList.slice(0,500);
    let payload = {
      "billStatus": 149,
      "sfBillId":sfBillData,
      "loggedInUserId":this.userId
    }

    this.sfBillingService.sfBillTrackingupdateStatus(payload).subscribe({
      next: (sfStatusDetails: Array<any>) => {
        this.searchedEditList = sfStatusDetails;
        this.gridLoader = false;
        this.pageLoader = false;
 
        if(sfStatusDetails){
          this.messageService.add({ severity: 'success', summary: 'SF bill details sent to Finance successfully'});
        }

        setTimeout(() => {
          this.messageService.clear();
          this.searchedEditList = [];
        }, 1000);
      
      },
      error: (err) => {
        this.allchecked = true;
        this.gridLoader = false;
        this.apiFail = true;
        this.messageService.add({ severity: 'error', summary: 'Something went wrong! Please try again' });
        setTimeout(() => {
          this.messageService.clear();
        }, 1000);
       
      }
    })
  }
}
