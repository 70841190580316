import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IRFRealizationGridComponent } from './irf-realization-grid/irf-realization-grid.component';
import { DropdownStyle } from './irf-realization-grid/dropdownStyle.directive';
import { IRFRealizationDetailsComponent } from './irf-realization-details/irf-realization-details.component';
import { IRFRealizationRoutingModule } from './irf-initiation-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { SvgModule } from 'app/backoffice-assets/svg.module';
import { CardModule } from 'primeng/card';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogService } from 'primeng/dynamicdialog';
import { BackofficeSharedModule } from 'app/shared/shared.module';
import {TimelineModule} from 'primeng/timeline';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';



@NgModule({
  declarations: [
    IRFRealizationGridComponent,
    IRFRealizationDetailsComponent,
    DropdownStyle
  ],
  imports: [
    CommonModule,
    IRFRealizationRoutingModule,
      CommonModule,
      ReactiveFormsModule,
      FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
      BackofficeSharedModule,
      DialogModule,
      SvgModule,
      CardModule,
      DialogModule,
      FileUploadModule,
      ImageCropperComponent,
      AccordionModule,
      InputSwitchModule,
      StepsModule,
      ToastModule,
      PdfViewerModule,
      TimelineModule,
      TagModule,
      InputNumberModule,
      DropdownModule
    ],
    providers:[
      DialogModule,
      DialogService
    ],
})
export class IrfRealizationModule { }
