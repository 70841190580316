<div class="header1">
    <div class="p-d-flex headtop">
        <img *ngIf='lightLogo' src="../../../content/images/light-logo.png" alt="" height="38" class="iconlogo">
        <img *ngIf='!lightLogo' src="../../../content/images/dark-logo.png" alt="" height="38" class="iconlogo">


        <div class="p-ml-auto">
            <button *ngIf='webView' #btn class="profileBtn" iconPos="right" pButton icon="pi pi-angle-down" label={{userName}} (click)="menu.toggle($event)"></button>
            <p-slideMenu #menu [model]="userItems" [popup]="true"></p-slideMenu>
        </div>
        <!--p-auto-->
    </div>
    <!--headtop-->
    <div>
        <p-menubar [model]='items'></p-menubar>
    </div>
</div>
<!--header1-->