import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AccountService } from 'app/core/auth/account.service';
import { BACKOFFICE_SERVER_API_URL, SERVER_API_URL, SERVER_API_INTEGRATION_URL, REPORTING_SERVER_API_URL_Allocation, REPORTING_SERVER_API_URL, REPORTING_SERVER_API_URL_Entity, REPORTING_SERVER_API_URL_CRMIntegaration, USERMANAGEMENT_SERVER_API_URL, NEXERA_SERVER_API_URL, FILEUPLOAD_URL, NEXERA_SERVER_API_URL_OPPORTUNITY, SFINITIATION_URL, SFREALIZATION_URL, SFPUSHGRID_URL, MASTER_SERVICE_URL } from 'app/app.constants';
import * as CryptoJS from 'crypto-js';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { delay, take, retryWhen, switchMap, debounceTime } from 'rxjs/operators';
import { BYPASS_LOADER } from 'app/blocks/interceptor/request-loader.interceptor';

@Injectable({
  providedIn: 'root'
})
export class QcService {

  key: any;
  iv: any;
  private userId: any;
  errorMessageOnSaveService: string;
  constructor(private accountService: AccountService, private http: HttpClient, private encDec: EncryptDecryptInterceptor) {
    this.key = CryptoJS.enc.Latin1.parse('Y3Jpc2lsQDIwMjE=');
    this.iv = CryptoJS.enc.Latin1.parse('fedcba9876543210');
    this.accountService.identity().subscribe(account => {
      if (account) {
        this.userId = account.login;
      }
    });
  }

  findAllQcData(payload:any):Observable<any>{
    const headersList = new HttpHeaders();

    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/qualityCheck/findAllQcListingDetails`, payload, {headers : headersList});
  }

  getCompanySearchResults(payload: any){
    const headersList = new HttpHeaders();
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/qualityCheck/findAllCompanyName`,payload, {headers : headersList});
  }

  getQcStatus():Observable<any>{
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/QC Status`);
  }

  findAllQcDocData(payload:any):Observable<any>{
    const headersList = new HttpHeaders();
    console.log("Decrypted Payload Data: ",this.encDec.decrypt("9tYs5gQ3zH2s/j4UEEft6TNTui4yG4KZ6sZ8/1aMcTdeBV3P0Y6R6bGYOQIyV8q6"));
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/qualityCheck/findAllQcDocListingDetails`, payload, {headers : headersList});
  }

  getQCDocCompanySearchResults(payload: any){
    const headersList = new HttpHeaders();
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/qualityCheck/findDocListingDataByCompanyName`,payload, {headers : headersList});
  }
}
