import { NgModule } from '@angular/core';
import { BackofficeSharedLibsModule } from './shared-libs.module';
import { FindLanguageFromKeyPipe } from './language/find-language-from-key.pipe';
import { AlertComponent } from './alert/alert.component';
import { AlertErrorComponent } from './alert/alert-error.component';
import { HasAnyAuthorityDirective } from './auth/has-any-authority.directive';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import {TabViewModule} from 'primeng/tabview';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DynamicDialogModule, DialogService} from 'primeng/dynamicdialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MessagesModule} from 'primeng/messages';
import {InputMaskModule} from 'primeng/inputmask';
import {BadgeModule} from 'primeng/badge';
import { ToggleButtonModule } from "primeng/togglebutton";
import {MultiSelectModule} from 'primeng/multiselect';
import {InputSwitchModule} from 'primeng/inputswitch';
import { FileUploadModule } from 'primeng/fileupload';
import { StepsModule } from 'primeng/steps';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { ImageCropperComponent } from 'ngx-image-cropper';
import {TimelineModule} from 'primeng/timeline';
@NgModule({
    imports: [
        MultiSelectModule,
        InputTextModule,
        DropdownModule,
        ButtonModule,
        TooltipModule,
        TableModule,
        CalendarModule,
        TabViewModule,
        AutoCompleteModule,
        BreadcrumbModule,
        DynamicDialogModule,
        ConfirmDialogModule,
        MessagesModule,
        InputMaskModule,
        BadgeModule,
        ToggleButtonModule,
        InputSwitchModule,
        BackofficeSharedLibsModule,
        StepsModule,
        FileUploadModule,
        RadioButtonModule,
        CheckboxModule,
        AccordionModule,
        TimelineModule,
        ToastModule,
        ImageCropperComponent
    ],
    declarations: [FindLanguageFromKeyPipe, HasAnyAuthorityDirective],
    exports: [
        MultiSelectModule,
        BackofficeSharedLibsModule,
        FindLanguageFromKeyPipe,
        // AlertComponent,
        // AlertErrorComponent,
        InputTextModule,
        DropdownModule,
        ButtonModule,
        TooltipModule,
        TableModule,
        CalendarModule,
        TabViewModule,
        AutoCompleteModule,
        BreadcrumbModule,
        DynamicDialogModule,
        ConfirmDialogModule,
        MessagesModule,
        InputMaskModule,
        BadgeModule,
        ToggleButtonModule,
        InputSwitchModule,
        HasAnyAuthorityDirective,
        StepsModule,
        FileUploadModule,
        CheckboxModule,
        RadioButtonModule,
        AccordionModule,
        ToastModule,
        ImageCropperComponent,
    ],
    providers: [
        DialogModule,
        DialogService
    ]
})
export class BackofficeSharedModule {}
