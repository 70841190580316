<div class="layout-content p-pt-5 p-mt-2">
    
    <p-messages class="custom-message"></p-messages>
    <div class="p-formgrid p-grid breadcrumb p-m-0">
        <div class=" p-col-12">
            <div class="ui-fluid p-formgrid p-grid p-ai-center p-m-0">
                <div class="p-col-10 p-lg-9 p-xl-8  p-text-left">
                    <div class=" p-fluid   p-field   p-mb-0 ">
                        <p-breadcrumb id="applicationBreadcrumb_id" name="applicationBreadcrumb_name" class=""
                            [model]="applicationBreadcrumbList" [home]="homeiconapplicationBreadcrumb">
                        </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form>
        <div class="p-formgrid p-grid formFieldContainer">
            <!-- Agreement Details -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="Agreement Details">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-12 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2">Client:</label>
                                <span class="desc">{{agreement_details?.client}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Portfolio Manager(s):</label>
                                <!-- <span class="desc">{{itemsreadAgreementInit?.}}</span> -->
                                <span class="desc"><a
                                        (click)="goToPortfolioDetails(agreement_details?.companyCode,agreement_details?.agreementId )"
                                        style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                        View Portfolio Details
                                    </a></span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-8 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Company Address:</label>
                                <div>
                                    <span class="desc">{{agreement_details?.companyAddress}}</span>
                                </div>
    
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Company Code:</label>
                                <span class="desc">{{agreement_details?.companyCode}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Nexera Case Id:</label>
                                <span class="desc">{{agreement_details?.nexeraCaseId}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Email:</label>
                                <span class="desc">{{agreement_details?.email}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">TAN:</label>
                                <span class="desc">{{agreement_details?.tan}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Risk:</label>
                                <span class="desc">{{agreement_details?.risk}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">PAN:</label>
                                <span class="desc">{{agreement_details?.pan}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Rated by Competitor:</label>
                                <span class="desc">{{agreement_details?.ratedByCompetitor}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Risk Comments:</label>
                                <span class="desc">{{agreement_details?.riskComments}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">RPT Flag:</label>
                                <span class="desc">{{agreement_details?.rptFlag}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Nature of Agreement:</label>
                                <span class="desc">{{agreement_details?.natureOfAgreement | titlecase}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">RPT Flag In CC:</label>
                                <span class="desc">{{agreement_details?.rptFlagInCC}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Mandate Option:</label>
                                <span class="desc">{{agreement_details?.mandateOption}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Fee Cap:</label>
                                <span class="desc">{{agreement_details?.feeCap}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">BD Manager:</label>
                                <span class="desc">{{agreement_details?.bDManager}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Immediate Supervisor:</label>
                                <span class="desc">{{agreement_details?.immediateSupervisor}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Team Lead:</label>
                                <span class="desc">{{agreement_details?.teamLead}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Client Contact:</label>
                                <span class="desc">{{agreement_details?.clientContact}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">GST Number:</label>
                                <span class="desc">{{agreement_details?.gstNumber}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">GST TDS Account Number:</label>
                                <span class="desc">{{agreement_details?.gstTdsAccountNumber}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Client State Code:</label>
                                <span class="desc">{{agreement_details?.clientStateCode}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">GST Category:</label>
                                <span class="desc">{{agreement_details?.gstCategory}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Product:</label>
                                <span class="desc">{{agreement_details?.product}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">CRR Product:</label>
                                <span class="desc">{{agreement_details?.crrProduct}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Cost Center:</label>
                                <span class="desc">{{agreement_details?.costCenter}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Master Service:</label>
                                <span class="desc">{{agreement_details?.masterService}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Sub Service:</label>
                                <span class="desc">{{agreement_details?.subService}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Ratable Unit:</label>
                                <span class="desc">{{agreement_details?.ratableUnit}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">AMC Code:</label>
                                <span class="desc">{{agreement_details?.amcCode}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Mutual Fund Scheme:</label>
                                <span class="desc">{{agreement_details?.mutualFundScheme}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">AMC Name:</label>
                                <span class="desc">{{agreement_details?.amcName}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Marketing Associate(If
                                    applicable):</label>
                                <span class="desc">{{agreement_details?.marketingAssociate}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Old BD Manager:</label>
                                <span class="desc">{{agreement_details?.oldBDManager}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Date Of Agreement Sent To
                                    Client:</label>
                                <span class="desc">{{agreement_details?.dateOfAgreementSentToClient}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">System Date When Submitted To
                                    Client:</label>
                                <span class="desc">{{agreement_details?.systemDateWhenSubmittedToClient}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Agreement Sent By:</label>
                                <span class="desc">{{agreement_details?.agreementSentBy}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Priority:</label>
                                <span class="desc">{{agreement_details?.priority}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Indicated Time For Execution:</label>
                                <span class="desc">{{agreement_details?.indicatedTimeForExecution}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Comments For Assignment Team:</label>
                                <span class="desc">{{agreement_details?.commentsForAssignmentTeam}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Mandate Reported Date:</label>
                                <span class="desc">{{agreement_details?.mandateReportedDate}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Crisil Signed Date:</label>
                                <span class="desc">{{agreement_details?.crisilSignedDate}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Client Signed Date:</label>
                                <span class="desc">{{agreement_details?.clientSignedDate}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Reference Number:</label>
                                <span class="desc">{{agreement_details?.referenceNumber}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Attachments:</label>
                                <div class="multiAttachmentName-container">
                                    <ng-container *ngIf="agreement_details?.agreementAttachments">
                                        <ng-container *ngFor="let agmnt of agreement_details?.agreementAttachments">
                                            <span class="desc multiAttachmentName"><a (click)="showAgreementDetailsMulipleAttachmentPreview(agmnt.attachment.filePath, agmnt.attachment.fileName)">
                                                {{agmnt.attachment.fileName}}
                                            </a>
                                            </span>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <!-- <div class="p-d-inline">
                                </div> -->
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Agreement No:</label>
                                <span class="desc">{{agreement_details?.agreementNoAndDate}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Client Type:</label>
                                <span class="desc">{{agreement_details?.clientType}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Total Facilities:</label>
                                <span class="desc">{{agreement_details?.totalFacilities}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Group Companies List As LCG A:</label>
                                <span class="desc">{{agreement_details?.groupCompaniesListAsLCGA}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">NSE500:</label>
                                <span class="desc">{{agreement_details?.nse500}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Client Classification Approval:</label>
                                <span class="desc"><a (click)="showAgreementDetailsAttachmentPreview('clientClassification')"
                                        style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                        {{agreement_details?.clientClassificationApprovalFileName}}
                                    </a></span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">QC Status:</label>
                                <span class="desc">{{agreement_details?.qcStatus}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Include In MIS:</label>
                                <span class="desc">{{agreement_details?.includeInMIS}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Agreement Status:</label>
                                <span class="desc">{{agreement_details?.agreementStatus}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">SF or IRF Log:</label>
                                <span class="desc">{{agreement_details?.sfOrIRFLog}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">FeedBack For QC Desk:</label>
                                <span class="desc">{{agreement_details?.feedBackForQCDesk}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Stage:</label>
                                <span class="desc">{{agreement_details?.stage}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">OPE Type:</label>
                                <span class="desc">{{agreement_details?.opeType}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">OPE Amount:</label>
                                <span
                                    class="desc">{{agreement_details?.opeAmount !== "null Rs" ? agreement_details?.opeAmount : "0"}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Remarks:</label>
                                <span class="desc">{{agreement_details?.remarks}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">External Associate Code:</label>
                                <span class="desc">{{agreement_details?.externalCode}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Cross Sell Type:</label>
                                <span class="desc">{{agreement_details?.crossSellType}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex p-jc-start p-ai-start"> 
                                <label class="p-mr-2 label_light">Tax Approval:</label>
                                <div class="multiAttachmentName-container">
                                    <ng-container *ngIf="agreement_details?.taxAttachment">
                                        <ng-container *ngFor="let agmnt of agreement_details?.taxAttachment">
                                            <span class="desc multiAttachmentName"><a (click)="showAgreementDetailsMulipleAttachmentPreview(agmnt.taxAttachmentBase64, agmnt.taxAttachmentFileName)">
                                                {{agmnt.taxAttachmentFileName}}
                                            </a>
                                            </span>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-8 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex p-jc-start p-ai-start"> 
                                <label class="p-mr-2 label_light">Compliance Approval:</label>
                                <div class="multiAttachmentName-container">
                                    <ng-container *ngIf="agreement_details?.complianceAttachment">
                                        <ng-container *ngFor="let agmnt of agreement_details?.complianceAttachment">
                                            <span class="desc multiAttachmentName"><a (click)="showAgreementDetailsMulipleAttachmentPreview(agmnt.complianceAttachmentBase64, agmnt.complianceAttachmentFileName)">
                                                {{agmnt.complianceAttachmentFileName}}
                                            </a>
                                            </span>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex p-jc-start p-ai-start"> 
                                <label class="p-mr-2 label_light">Authorization Letter:</label>
                                <div class="multiAttachmentName-container">
                                    <ng-container *ngIf="agreement_details?.authorizationAttachment">
                                        <ng-container *ngFor="let agmnt of agreement_details?.authorizationAttachment">
                                            <span class="desc multiAttachmentName"><a (click)="showAgreementDetailsMulipleAttachmentPreview(agmnt.authorizationLetterAttachmentBase64, agmnt.authorizationLetterAttachmentFileName)">
                                                {{agmnt.authorizationLetterAttachmentFileName}}
                                            </a>
                                            </span>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light"></label>
                                <!-- <span class="desc">{{itemsreadAgreementInit?.}}</span> -->
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light"></label>
                                <!-- <span class="desc">{{itemsreadAgreementInit?.}}</span> -->
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Date Of Making Mandate For MIS
                                    Manually:</label>
                                <span class="desc">{{agreement_details?.dateOfMarkingMandate}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-2 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Marked for MIS By:</label>
                                <span class="desc">{{agreement_details?.markedByMis}}</span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>

            <!-- Mandate Received Information -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="Mandate Received Information">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Type of Mandate:</label>
                                <span class="desc">{{agreement_details?.typeOfMandateOriginal}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Date when Original Agreement Received
                                    from Client:</label>
                                <span class="desc">{{agreement_details?.dateWhenOriginalAgreementReceivedFromClient}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">System Date:</label>
                                <span class="desc">{{agreement_details?.systemDateOriginal}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Original Agreement Received By:</label>
                                <span class="desc">{{agreement_details?.originalMandateReceivedBy}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> 
                                <label class="p-mr-2 label_light">Mandate Attachment:</label>
                                <div class="multiAttachmentName-container">
                                    <ng-container *ngIf="agreement_details?.originalAttachment">
                                        <ng-container *ngFor="let agmnt of agreement_details?.originalAttachment">
                                            <span class="desc multiAttachmentName"><a (click)="showAgreementDetailsMulipleAttachmentPreview(agmnt.originalAttachmentBase64, agmnt.originalAttachmentFileName)">
                                                {{agmnt.originalAttachmentFileName}}
                                            </a>
                                            </span>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
    
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Type of Mandate:</label>
                                <span class="desc">{{agreement_details?.typeOfMandateFaxed}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Date when Faxed Agreement Received from
                                    Client:</label>
                                <span class="desc">{{agreement_details?.dateWhenFaxedAgreementReceivedFromClient}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">System Date:</label>
                                <span class="desc">{{agreement_details?.systemDateFaxed}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Faxed Agreement Received By:</label>
                                <span class="desc">{{agreement_details?.faxedMandateReceivedBy}}</span>
                            </div>
                        </div>
    
                        <div class="p-col-12 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> 
                                <label class="p-mr-2 label_light">Mandate Attachment:</label>
                                <div class="multiAttachmentName-container">
                                    <ng-container *ngIf="agreement_details?.faxedAttachment">
                                        <ng-container *ngFor="let agmnt of agreement_details?.faxedAttachment">
                                            <span class="desc multiAttachmentName"><a (click)="showAgreementDetailsMulipleAttachmentPreview(agmnt.faxedAttachmentBase64, agmnt.faxedAttachmentFileName)">
                                                {{agmnt.faxedAttachmentFileName}}
                                            </a>
                                            </span>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </p-card>
            </div>

            <!-- Other Attachment (NDA & Fee letter) -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="Other Attachment(NDA & Fee letter)">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <!-- <div class="p-d-inline"> -->
                        <ng-container *ngIf="agreement_details?.agreementOtherAttachment">
                            <ng-container *ngFor="let agmnt of agreement_details?.agreementOtherAttachment">
                                <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                    <div class="p-d-flex"> <label class="p-mr-2 label_light">Attached On:</label>
                                        <span class="desc">{{agmnt.attachedOn}}</span>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                    <div class="p-d-flex"> <label class="p-mr-2 label_light">Other Attachment Type:</label>
                                        <span class="desc">{{agmnt.otherAttachmentType.attachmentType}}</span>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                    <div class="p-d-flex"> <label class="p-mr-2 label_light">Attachment:</label>
                                        <span class="desc"><a (click)="showAgreementDetailsMulipleAttachmentPreview(agmnt.otherAttachment.filePath, agmnt.otherAttachment.fileName)">
                                            {{agmnt.otherAttachment.fileName}}
                                        </a></span>
                                    </div>
                                </div>
                                
                            </ng-container>
                        </ng-container>
                        <!-- </div> -->
                        
                    </div>
                </p-card>
            </div>

            <!-- Case Details -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="Case Details">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Case:</label>
                                <span class="desc">{{agreement_details?.case}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Lowest Unit:</label>
                                <span class="desc">{{agreement_details?.lowestUnit}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Instrument Amount:</label>
                                <span class="desc">{{agreement_details?.instrumentAmount}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Refered By Bank:</label>
                                <span class="desc">{{agreement_details?.referredByBank}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Case Classification:</label>
                                <span class="desc">{{agreement_details?.caseClassification}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">SME - Combo Deal:</label>
                                <span class="desc">{{agreement_details?.smeComboDeal}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Outstanding Amount:</label>
                                <span class="desc">{{agreement_details?.outstandingAmount}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Applicable Instrument Amount:</label>
                                <span class="desc">{{agreement_details?.applicableInstrumentAmount}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Under Sebi:</label>
                                <span class="desc">{{agreement_details?.underSEBI}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">In Progress Amount:</label>
                                <span class="desc">{{agreement_details?.inProgressAmount}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Standard IRF:</label>
                                <span class="desc">{{agreement_details?.standardIRFFee}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Category Name:</label>
                                <span class="desc">{{agreement_details?.categoryName}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Previous Limits:</label>
                                <span class="desc">{{agreement_details?.previousLimits}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Current Limits:</label>
                                <span class="desc">{{agreement_details?.currentLimit}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Options:</label>
                                <span class="desc">{{agreement_details?.options}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Applicable Amount:</label>
                                <span class="desc">{{agreement_details?.applicableAmount}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Actual IRF:</label>
                                <span class="desc">{{agreement_details?.actualIRF}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Actual product:</label>
                                <span class="desc">{{agreement_details?.actualProductIrf}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">CRR product:</label>
                                <span class="desc">{{agreement_details?.crrProductIrf}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Difference:</label>
                                <span
                                    class="desc">{{agreement_details?.difference !== "0 Rs" ? agreement_details?.difference : "0"}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Reason:</label>
                                <span class="desc">{{agreement_details?.reason}}</span>
                            </div>
                        </div>
                        
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Other Reason:</label>
                                <span class="desc">{{agreement_details?.otherReasonIrf}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Approval Attachment:</label>
                                <span class="desc"><a (click)="showAgreementDetailsAttachmentPreview('irfAttachment')">
                                        {{agreement_details?.approvalAttachmentIrfFileName}}
                                    </a></span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Agreement Type:</label>
                                <span class="desc">{{agreement_details?.agreementType}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Agreement Type Comments:</label>
                                <span class="desc">{{agreement_details?.agreementTypeComments}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Revenue Booking:</label>
                                <span class="desc">{{agreement_details?.revenueBooking}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Revenue Booking Date:</label>
                                <span class="desc">{{agreement_details?.revenueBookingDate}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">RCM Number:</label>
                                <span class="desc">{{agreement_details?.rcmNumber}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">RCM Date:</label>
                                <span class="desc">{{agreement_details?.rcmDate}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">RCM Type:</label>
                                <span class="desc">{{agreement_details?.rcmType}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Mandate Number:</label>
                                <span class="desc">{{agreement_details?.mandateNumber}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Tagged:</label>
                                <span class="desc">{{agreement_details?.tagged}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Mandate created in LN:</label>
                                <span class="desc">{{agreement_details?.mandateCreatedInLN}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Bill Created in LN:</label>
                                <span class="desc">{{agreement_details?.billCreatedInLN}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">LN Bill Number:</label>
                                <span class="desc">{{agreement_details?.lnBillNumber}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Description:</label>
                                <span class="desc">{{agreement_details?.description}}</span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>

            <!-- Previous Agreement Case Details -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="Previous Agreement Case Details">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Instrument Amount:</label>
                                <span class="desc">{{agreement_details?.previousInstrumentAmount}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Case Classification:</label>
                                <span class="desc">{{agreement_details?.previousCaseClassification}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Under Sebi:</label>
                                <span class="desc">{{agreement_details?.previousUnderSEBI}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Standard IRF:</label>
                                <span class="desc">{{agreement_details?.previousStandardIrf}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Actual IRF:</label>
                                <span class="desc">{{agreement_details?.previousActualIrf}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Reason:</label>
                                <span class="desc">{{agreement_details?.previousReason}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Approval Attachment:</label>
                                <span class="desc"><a (click)="showAgreementDetailsAttachmentPreview('previousAgreement')">
                                        {{agreement_details?.approvalAttachmentPrevFileName}}
                                    </a></span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Agreement Type:</label>
                                <span class="desc">{{agreement_details?.previousAgreementType}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Agreement Type Comments:</label>
                                <span class="desc">{{agreement_details?.previousAgreementTypeComments}}</span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>

            <!-- SF Fee Rule Details -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="SF Fee Rule Details">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Rule based:</label>
                                <span class="desc">{{agreement_details?.ruleBased}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Rule Name:</label>
                                <span class="desc">{{agreement_details?.ruleName}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Fee Rate:</label>
                                <span class="desc">{{agreement_details?.feeRate}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Applicable On:</label>
                                <span class="desc">{{agreement_details?.applicableOn}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Min Amount(Lacs):</label>
                                <span class="desc">{{agreement_details?.minAmount}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Max Amount(Lacs):</label>
                                <span class="desc">{{agreement_details?.maxAmount}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Comments:</label>
                                <span class="desc">{{agreement_details?.comments}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Standard Agreed Amount:</label>
                                <span class="desc">{{agreement_details?.standardAgreedAmount}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Actual product:</label>
                                <span class="desc">{{agreement_details?.actualProductSf}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">CRR product:</label>
                                <span class="desc">{{agreement_details?.crrProductSf}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Fixed Amount:</label>
                                <span class="desc">{{agreement_details?.fixedAmount}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Reason:</label>
                                <span class="desc">{{agreement_details?.reason1}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Other Reason:</label>
                                <span class="desc">{{agreement_details?.otherReasonSf}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light"></label>
                                <!-- <span class="desc">{{itemsreadAgreementInit?.}}</span> -->
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Approval Attachment:</label>
                                <span class="desc"><a (click)="showAgreementDetailsAttachmentPreview('sfAttachment')">
                                        {{agreement_details?.approvalAttachmentSfFilename}}
                                    </a></span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>

            <!-- SMS Subscription -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="SMS Subscription">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Register For SMS:</label>
                                <span class="desc">{{agreement_details?.registerForSMS}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Contact Person:</label>
                                <span class="desc">{{agreement_details?.contactPerson}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Mobile Number:</label>
                                <span class="desc">{{agreement_details?.mobileNumber}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Email Id:</label>
                                <span class="desc">{{agreement_details?.emailId}}</span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>

            <!-- Portfolio Details -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="Portfolio Details">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Level 1:</label>
                                <span class="desc">{{agreement_details?.level1}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Level 2:</label>
                                <span class="desc">{{agreement_details?.level2}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Level 3:</label>
                                <span class="desc">{{agreement_details?.level3}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Level 4:</label>
                                <span class="desc">{{agreement_details?.level4}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Level 5:</label>
                                <span class="desc">{{agreement_details?.level5}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Level 6:</label>
                                <span class="desc">{{agreement_details?.level6}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Level 7:</label>
                                <span class="desc">{{agreement_details?.level7}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Level 8:</label>
                                <span class="desc">{{agreement_details?.level8}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">External Associate:</label>
                                <span class="desc">{{agreement_details?.externalAssociate}}</span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>

            <!-- Rating Status -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="Rating Status">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Rating Status:</label>
                                <span class="desc">{{agreement_details?.ratingStatus}}</span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
            
            <!-- Last row -->
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0">
                    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                        <div class="p-col-12 p-md-4 p-mt-0 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Revised By:</label>
                                <span class="desc">{{agreement_details?.revisedBy}}</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-mt-0 p-px-0 p-text-left">
                            <div class="p-d-flex"> <label class="p-mr-2 label_light">Revision Date:</label>
                                <span class="desc">{{agreement_details?.revisionDate}}</span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </form>
</div>

<div class="p-col-12 p-d-flex p-jc-between p-button-container p-ai-center">
    <div class="p-col-12 p-d-flex p-text-left p-jc-between p-pl-0">
        <div class="btnwrap">
            <button pButton class="p-button-brand2 p-button" type="button" (click)="onBackClick()" label="Back">
            </button>
        </div>
        <div class="btnwrap" *ngIf="irfBillId">
            <p-splitButton class="" label="View" [model]="viewButtonitems" styleClass="splitButtonOverlay">
            </p-splitButton>
        </div>
    </div>
</div>

<p-dialog header="Preview" [(visible)]="displayPreview" class="docPreview previewModal" [modal]="true"
    [style]="{width: '95vw', height: '100vh'}" [draggable]="false" [resizable]="false">
    <div class="no_data_div tableLoader" *ngIf="dataLoader">
        <div class="app-loading " id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label>Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <pdf-viewer id="pdf_viewer" [src]="urlSafe" [original-size]="false" [show-all]="true" [fit-to-page]="false"
        [zoom]="zoomValue" [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true"
        [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false" style="width: 100%; height:100%;">
    </pdf-viewer>
    <!-- <div class="imagePreviewContainer p-d-flex p-jc-center p-ai-center">
        <img *ngIf="!isPdf" src="{{urlSafe}}" id="imagePrev" alt="Image">
    </div> -->
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Download" (click)="downloadFile()" class="p-button-secondary"></button>
    </ng-template>
</p-dialog>

<!-- error modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>