import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';

@Component({
  selector: 'backoffice-attachment-model',
  templateUrl: './attachment-model.component.html',
  styleUrls: ['./attachment-model.component.scss'],
  providers : [MessageService]
})
export class AttachmentModelComponent implements OnInit {

  AttachmentModelForm = this.fb.group({
    fileTypeName: [null as any, [Validators.required]],
  });
  returnBase64: any; //Added
  returnUploaded: any; //Added
  fileTypeNameList: any[] = [];
  fileTypeAccepted = 'image/*, .pdf, .jpeg , .jpg, .png, .doc, .docx';
  @ViewChild('fileUploadfileUpload1', { static: false })
  fileUploadfileUpload1: any;
  croppedImagefileUpload: any = '';
  imageChangedEventfileUpload: any;
  isNotUpload = true;
  empId: any;
  payments: any = [];
  isPdfDoc: boolean = false;
  fileSize: number;
  file: any; 
  thumbSrc: any = '';
  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;

  uploadBtnClicked: boolean = false;
  saveBtnDisabled: boolean = false;
  paymentModelist: any = [];
  editValue: any = {};
  isEdit: boolean = false;
  uploadLoader: boolean = false;
  hideAttachType: boolean = false;

  @HostListener('window:popstate', ['$event'])
  onPopState(_event) {
    this.ref.close();
  }
  
  constructor(
    protected activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private encDec: EncryptDecryptInterceptor,
    private messageService: MessageService,
    private pageService : PagesService
  ) { 
    this.fileTypeNameList = this.config.data.list;
    this.hideAttachType = this.config.data.hideAttachType;
    this.fileTypeAccepted = this.fileTypeAccepted + "," + this.config.data.type;
    this.editValue = this.config.data.editValue;
    if(this.editValue){
      this.isEdit = true;
      this.AttachmentModelForm.patchValue({
        fileTypeName: {
          id: Number(this.editValue.item?.fileTypeid),
          type: this.editValue.item?.fileTypeName,
        },
      });
    }
    this.payments = this.config.data.payments;
  }

  ngOnInit(): void {
    this.empId = this.encDec.decrypt(localStorage.getItem('empid'));
    if(this.hideAttachType){
      this.AttachmentModelForm.get('fileTypeName').setValidators([]);
      this.AttachmentModelForm.get('fileTypeName').updateValueAndValidity();
    }
  }

  checkFileType(event : any) {
    // check if this file is .exe
    console.log("Inside checkFileType()");
    this.pageService.checkInvalidFileContent(event, this.onSelectfileUpload.bind(this));
  }

  onSelectfileUpload(event: any): void {
    if(!event) {
      this.messageService.add({ severity: 'error',summary:'File content is invalid'});
      return;
    }  
    this.file = event.currentFiles[0];
    let ext = this.file?.name?.split('.');
    if(ext[ext.length-1] == 'jfif'){
      this.messageService.add({ severity: 'error',summary:'Invalid File Format.'});
      return;
    }
    if(["image/svg+xml", "image/webp", "xlxs", "xls", "html", "xhtml", "txt", "gif", "application/exe", "zip", "msg"].includes(this.file.type)){
      this.messageService.add({ severity: 'error',summary:'Invalid File Format.'});
      return;
    }
    if(this.file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || this.file.type == "application/msword"){
      this.thumbSrc = this.wordThumb;
    } 
    if(this.file.type == "application/pdf" ){
      this.thumbSrc = this.pdfThumb;
    }
    if(this.file.type == "application/pdf" || this.file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || this.file.type == "application/msword"){
      this.isNotUpload = false;
      this.isPdfDoc = true;
      this.fileSize = Math.round(this.file.size / 1000);
    } else {
      this.isPdfDoc = false;
    }
    if(this.fileTypeAccepted?.includes("msg") && this.file?.name && (<string>this.file?.name).endsWith(".msg")) {
      this.thumbSrc = this.mailThumb;
      this.isPdfDoc = true;
      this.isNotUpload = false;
    }
    this.uploadLoader = true;
    for (const file of event.currentFiles) {
      if (file.type.includes('image')) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(this.fileUploadfileUpload1.files[0]);
        fileReader.onload = () => {
          this.imageChangedEventfileUpload = fileReader.result;
          this.returnBase64 = fileReader.result;
          this.uploadLoader = false;
        };
      } else {
        //Added
        const fileReader = new FileReader();
        fileReader.readAsDataURL(this.fileUploadfileUpload1.files[0]);
        fileReader.onload = () => {
          this.returnBase64 = fileReader.result;
          this.uploadLoader = false;
        };
      }
    }
  }

  imageCroppedfileUpload(event: any){
    // this.croppedImagefileUpload = event.base64;
    // this.returnBase64 = event.base64; //Added

    fetch(event.objectUrl)
       .then(response => response.blob())
       .then(blob => {
           const reader = new FileReader();
           reader.onloadend = () => {
               this.returnBase64 = reader.result;
               this.croppedImagefileUpload = this.returnBase64;
           };
           reader.readAsDataURL(blob);
    });
    this.isNotUpload = false;
  }

  typeEdited(){
    let result = {
      value: this.AttachmentModelForm.value,
      index: this.editValue.index
    };
    setTimeout(() => {
      this.ref.close(result);
    }, 1000);
  }

  onCroppedUploadBtn(){
    if(this.uploadBtnClicked){
      return;
    }
    this.uploadBtnClicked = true;
    const displayData = this.getUploadedObjDisplay();
    const result: any[] = [];
    result.push(displayData);
    if (this.returnBase64 ) {
      setTimeout(() => {
        this.ref.close(result);
      }, 1000);
    }
  }

  getUploadedObj(): any {
    return {
      userId: this.empId,
      fileSize: (this.file.size).toString(),
      fileName: this.file.name,
      file: this.returnBase64,
      fileContentType: this.file.type,
      fileTypeid: (this.AttachmentModelForm.value?.fileTypeName?.id)?.toString(),
      fileTypeName: this.AttachmentModelForm.value?.fileTypeName?.type,
      fileId: null,
      moduleName: 'Opportunity',
      moduleId: null,
    };
  }
  getUploadedObjDisplay(): any {
    let data = this.returnBase64;
    return {
      userId: this.empId,
      fileSize: (this.file.size).toString(),
      fileName: this.file.name,
      file: data,
      fileContentType: this.file.type,
      fileTypeid: (this.AttachmentModelForm.value?.fileTypeName?.id)?.toString(),
      fileTypeName: this.AttachmentModelForm.value?.fileTypeName?.type,
      fileId: null,
      moduleName: 'Opportunity',
      moduleId: null,
    };
  }

  onCancel(): void {
    setTimeout(() => {
      this.ref.close();
    }, 1000);
  }
}
