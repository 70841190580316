<div class="layout-content">
    
    <p-messages class="custom-message"></p-messages>
    <div class="p-formgrid p-grid breadcrumb p-m-0">
        <div class=" p-col-12">
            <div class="ui-fluid p-formgrid p-grid p-ai-center p-m-0">
                <div class="p-col-10 p-lg-9 p-xl-8  p-text-left">
                    <div class=" p-fluid   p-field   p-mb-0 ">
                        <p-breadcrumb id="applicationBreadcrumb_id" name="applicationBreadcrumb_name" class=""
                            [model]="applicationBreadcrumbList" [home]="homeiconapplicationBreadcrumb">
                        </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="visible-card-container">
        <!-- Agreement Details -->
        <div class="p-col-12 p-p-0">
            <p-card class="p-p-0">
                <div class="ui-fluid p-formgrid p-grid entityDetailsHeader p-mx-0">
                    <div class="p-col-12 p-md-6 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Company Name:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.companyName}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Company Code:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.companyCode}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Financial Year:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.financialYear}}</span>
                        </div>
                    </div>


                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Assignment Family:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.assignmentFamily}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">SF Bill ID:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.sfBillId}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Entity Status:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.entityStatus}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Company Level Client Type:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.clientType}}</span>
                        </div>
                    </div>


                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Total SF Amount:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.totalSfAmount}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">RPT In CC:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.rptInCc}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">RPT In CRM:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.rptInCrm}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">BD Owner Name:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.bdOwnerName}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Finance Actioned By:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.financeActionBy}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Finance Actioned Date:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.financeActionDate | date:'dd-MMM-yyyy'}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Cap Classification:</label>
                            <span class="desc">{{this.fetchedFirstBdDetails?.feecapClassification == 'N'?'Non Fee Cap':'Fee Cap'}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Attachment:</label>
                            <span class="desc"><a class="truncated"
                                (click)="showAttachmentPreview(editAttach[0])">{{editAttach[0]?.fileName}}</a></span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Remark:</label>
                            <span class="textwrap desc"><p pTooltip="{{this.fetchedFirstBdDetails?.remark}}">{{this.fetchedFirstBdDetails?.remark}}</p></span>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
    </div>
    <div style="margin-top: 3.5rem;">
        <form [formGroup]="sfEditCompanyDetailsForm" class="p-mt-4">
            <!-- <div class="p-formgrid p-grid formFieldContainer"> -->
                <!-- Agreement Id -->
                <div class="p-mb-4" *ngFor="let card of fetchedBdDetails; let i = index">
                    <p-card class="p-p-0 agreement-cards">
                        <ng-template pTemplate="header">
                            <div class="p-card-title">
                                <div class="title">
                                    <svg width="32" height="32" class="p-mr-1" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#EBF3FE"/>
                                        <path d="M22 8V25L20.0029 24L18.0057 25L16 24L14.0029 25L12.0057 24L10 25V8C10 7.73478 10.0903 7.48043 10.2511 7.29289C10.4118 7.10536 10.6298 7 10.8571 7H21.1429C21.3702 7 21.5882 7.10536 21.7489 7.29289C21.9097 7.48043 22 7.73478 22 8Z" stroke="#0E6AF2" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M20 17.6565H12" stroke="#0E6AF2" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M20 20.0073H12" stroke="#0E6AF2" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14 10.4224H18" stroke="#0E6AF2" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14 9H18" stroke="#0E6AF2" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.2273 9C15.6974 9 16.1483 9.17865 16.4808 9.49664C16.8132 9.81464 17 10.2459 17 10.6957C17 11.1454 16.8132 11.5767 16.4808 11.8947C16.1483 12.2127 15.6974 12.3913 15.2273 12.3913H14L17 15" stroke="#0E6AF2" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                        
                                    Agreement Id: {{card.agreementId}}
                                </div>
                            </div>
    
                        </ng-template>
                        <div class="ui-fluid p-formgrid p-grid entityDetailsHeader p-m-0">
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Case Type:</label>
                                    <span class="desc">{{card.caseType}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Agreement Type:</label>
                                    <span class="desc">{{card.sfMandateValidity}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Initiation Type:</label>
                                    <span class="desc">{{card.initiationType}}</span>
                                </div>
                            </div>
                        
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Product Name:</label>
                                    <span class="desc">{{card.productName}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Instrument:</label>
                                    <span class="desc">{{card.instrumentName}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">QC Date:</label>
                                    <span class="desc">{{card.qcDate | date:'dd-MMM-yyyy'}}</span>
                                </div>
                            </div>
                        
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Reporting Date:</label>
                                    <span class="desc">{{card.reportingDate | date:'dd-MMM-yyyy'}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">RCM Date:</label>
                                    <span class="desc">{{card.rcmDate | date:'dd-MMM-yyyy'}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">RR Date:</label>
                                    <span class="desc">{{card.rrDate | date:'dd-MMM-yyyy'}}</span>
                                </div>
                            </div>
                        
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Mandate Qtm (Applicable) as per Nexera:</label>
                                    <span class="desc">{{card.applicableAmount}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">SF Actual Percentage:</label>
                                    <span class="desc">{{card.sfActualPercentage}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">SF Method Amount:</label>
                                    <span class="desc">{{card.sfAmount}}</span>
                                </div>
                            </div>
                        
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Revised SF Actual:</label>
                                    <span class="desc">{{card.revisedSf}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Fee Rule:</label>
                                    <span class="desc">{{card.feeRule}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">SF Maximum Amount:</label>
                                    <span class="desc">{{card.sfMaxAmount}}</span>
                                </div>
                            </div>
                        
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">SF Minimum Amount:</label>
                                    <span class="desc">{{card.sfMinAmount}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Billed Days:</label>
                                    <span class="desc">{{card.billedDays}}</span>
                                </div>
                            </div>
    
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">SF Start Date:</label>
                                    <span class="desc">{{card.sfStartDate | date:'dd-MMM-yyyy'}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">SF End Date:</label>
                                    <span class="desc">{{card.sfEndDate | date:'dd-MMM-yyyy'}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">SF Amount:</label>
                                    <span class="desc">{{card.sfAmount}}</span>
                                </div>
                            </div>
    
                            <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Outstanding Quantum as per ROW:</label>
                                    <span class="desc">{{card.outStandingAmount}}</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-mt-3 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2 label_light">Bill Details:</label>
                                    <span class="desc">{{card.billDetails}}</span>
                                </div>
                            </div>
                        </div>
                    </p-card>
                </div>
            <!-- </div> -->
        </form>
    </div>
</div>

<div class="p-col-12 p-d-flex p-jc-between p-button-container p-ai-center">
    <div class="p-col-12 p-d-flex p-text-left p-jc-between p-pl-0">
        <div class="btnwrap">
            <button pButton class="p-button-brand2 p-button" type="button" (click)="onBackClick()" label="Back">
            </button>
        </div>
        
        <div class="btnwrap">
            <button pButton class="p-button-secondary p-button p-ml-2" type="button" (click)="onReject()" label="Reject" [disabled]="readScope"></button>
            <button pButton class="p-button-primary p-button p-ml-2" type="button" (click)="onApprove()" label="Approve" [disabled]="readScope"></button>
        </div>
        
    </div>
</div>

<p-dialog header="Preivew" [(visible)]="displayPreview"
    class="previewModal modal" [modal]="true" [style]="{width: '95vw', height: '100vh'}" [draggable]="false"
    [resizable]="false">
    <div class="no_data_div tableLoader" *ngIf="previewLoader">
        <div class="app-loading " id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label style="margin-left:0px;">Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <pdf-viewer id="pdf_viewer" *ngIf="isPdf" [src]="urlSafe" [rotation]="rotate" [original-size]="false"
        [show-all]="true" [fit-to-page]="false" [zoom]="zoomValue" [zoom-scale]="'page-width'" [stick-to-page]="false"
        [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="width: 100%; height:100%;"></pdf-viewer>
    <div class="imagePreviewContainer p-d-flex p-jc-center p-ai-center">
        <img *ngIf="!isPdf" src="{{urlSafe}}" id="imagePrev" alt="Image">
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Download" class="p-button-secondary"
            (click)="downloadFile(attachmentResponse,urlSafe)"></button>
    </ng-template>
</p-dialog> 

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="previewFailed" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Unable to preview attachment, Please try again.</p>
</p-dialog>
<!-- error modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>

<!-- Reject SF Bill Id modal -->
<p-dialog header="Reject SF Bill Id" [(visible)]="showRejectModal" [modal]="true" [style]="{width: '55vw'}" [draggable]="false"
    [resizable]="false">
    
    <div class="ui-fluid p-formgrid p-grid entityDetailsHeader p-m-0">
        <div class="p-col-12 p-md-5 p-pl-0">
            <div class=" p-fluid p-field">
                <label>
                    Rejected Reason<span class='invalid-feedback-astrik'>*</span>
                </label>
                <p-dropdown name="rejectedCommentField" id="rejectedCommentField"
                    [(ngModel)]="rejectedCommentReason" [options]="rejectedCommentList" required [showClear]="false" appendTo="body" optionValue="id" optionLabel="property" placeholder="Select Rejected Reason" (onChange)="selectOnChangeRejectPopUp()">
                </p-dropdown>
            </div>
        </div>
        <div class="p-col-12 p-md-7 p-pr-0">
            <div class="p-fluid p-field"> 
                <label class="p-mr-2 label_light">Rejected Comment<span class='invalid-feedback-astrik'>*</span></label>
                <div class="p-fluid p-field p-mb-0">
                    <input class="p-input-width" type="text" pInputText id="rejectedCommentDetails_id" name="rejectedCommentDetails_name" [(ngModel)]="rejectedCommentDetails" required placeholder="Enter Rejected Comment" maxlength="500" (input)="selectOnChangeRejectPopUp()" #rejectedCommentDetailsInput/>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-12 p-p-0">
            <div class="p-fluid p-field"> 
                <label class="p-mr-2 label_light">Attachment<span class='invalid-feedback-astrik'>*</span></label>
                
                
                <div class="p-col-8 p-md-2 p-p-0">
                    <button pButton id="openFileUploadPopUpBtn" [disabled]="disableRejectAddButton"
                        class="p-button-secondary p-button p-px-2 p-m-0" (click)="fileuploadbtnFunction()" type="button"
                        label="Add"> </button>
    
                    <p-fileUpload #fileuploadbtn [disabled]="disableRejectAddButton" customUpload="true"
                        id="fileUploadBdApp" name="file" chooseLabel="Add" class="hidden"
                        accept="image/*, .pdf, .jpeg , .jpg, .png, .doc, .docx, .msg, .xlsx, .xls" maxFileSize=20000000
                        mode="advanced" [showUploadButton]="false" [showCancelButton]="false"
                        (onSelect)="onFileUplaod($event)">
                    </p-fileUpload>
                </div>
                <div class="p-grid p-d-flex p-flex-wrap p-col-12 p-p-0 documentToBeUploaded p-m-0">
                    <div class="p-col-12 p-md-6 p-lg-6 attachmentCard attachmentContainer p-flex-wrap" *ngIf="documentList != undefined">
                        <p-card class="attachmentCard">
                            <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-jc-between p-flex-wrap">
                                <div class="p-col-12 p-px-0 p-d-flex p-ai-center p-flex-wrap">
                                    <div class="p-col-10 p-px-0 p-d-flex p-ai-center p-flex-wrap">
                                        <div class="p-px-2">
                                                <img *ngIf="!documentList?.fileContentType" [src]="documentList?.fileContentType" alt="">
                                                <ng-container *ngIf="documentList?.fileContentType">
                                                    <img class="uploadedThumbnail"
                                                        *ngIf="documentList?.fileContentType == 'application/pdf'" [src]="pdfThumb"
                                                        alt="">
                                                    <img class="uploadedThumbnail"
                                                        *ngIf="(documentList?.fileContentType == 'image/png' || documentList?.fileContentType == 'image/jpg' || documentList?.fileContentType == 'image/jpeg')"
                                                        [src]="imgThumb" alt="">
                                                    <img class="uploadedThumbnail"
                                                        *ngIf="(documentList?.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || documentList?.fileContentType == 'application/msword')"
                                                        [src]="wordThumb" alt="">
                                                    <img class="uploadedThumbnail" *ngIf="documentList?.fileContentType == ''"
                                                        [src]="mailThumb" alt="">

                                                        <svg class="uploadedThumbnail"
                                                        *ngIf="documentList?.fileContentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                                        width="24" height="32" viewBox="0 0 24 32" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_1779_24618)">
                                                            <path d="M18.8944 0H0V32H24V5.33267L18.8944 0Z"
                                                                fill="#F4F5F6" />
                                                            <path
                                                                d="M5.82828 25.2306L6.5404 26.5866L7.2702 25.2306H8.37374L7.25 27.17L8.40404 29.1094H7.30556L6.5404 27.7685L5.78788 29.1094H4.67677L5.82828 27.17L4.71717 25.2306H5.82828ZM10.0884 23.9377V29.1094H9.01263V23.9377H10.0884ZM14.1774 26.3366L13.1926 26.3973C13.1757 26.3131 13.1395 26.2373 13.084 26.17C13.0284 26.101 12.9552 26.0462 12.8643 26.0058C12.775 25.9638 12.6681 25.9427 12.5436 25.9427C12.3769 25.9427 12.2363 25.9781 12.1218 26.0488C12.0074 26.1178 11.9501 26.2104 11.9501 26.3265C11.9501 26.4191 11.9872 26.4974 12.0612 26.5614C12.1353 26.6254 12.2624 26.6767 12.4426 26.7154L13.1446 26.8568C13.5217 26.9343 13.8028 27.0589 13.988 27.2306C14.1732 27.4023 14.2658 27.6279 14.2658 27.9074C14.2658 28.1616 14.1909 28.3846 14.041 28.5765C13.8929 28.7685 13.6892 28.9183 13.4299 29.026C13.1723 29.1321 12.8752 29.1851 12.5385 29.1851C12.025 29.1851 11.616 29.0782 11.3112 28.8644C11.0082 28.6489 10.8306 28.356 10.7784 27.9856L11.8365 27.9301C11.8685 28.0866 11.9459 28.2062 12.0688 28.2887C12.1917 28.3695 12.3491 28.4099 12.541 28.4099C12.7296 28.4099 12.8811 28.3737 12.9956 28.3013C13.1117 28.2272 13.1707 28.1321 13.1723 28.0159C13.1707 27.9183 13.1294 27.8383 13.0486 27.776C12.9678 27.7121 12.8432 27.6632 12.6749 27.6296L12.0032 27.4957C11.6244 27.42 11.3424 27.2887 11.1572 27.1018C10.9737 26.9149 10.8819 26.6767 10.8819 26.3872C10.8819 26.138 10.9493 25.9233 11.084 25.7432C11.2203 25.5631 11.4114 25.4242 11.6572 25.3265C11.9047 25.2289 12.1942 25.1801 12.5259 25.1801C13.0158 25.1801 13.4013 25.2836 13.6824 25.4907C13.9653 25.6978 14.1303 25.9797 14.1774 26.3366ZM15.8422 25.2306L16.5543 26.5866L17.2841 25.2306H18.3876L17.2639 27.17L18.4179 29.1094H17.3194L16.5543 27.7685L15.8018 29.1094H14.6907L15.8422 27.17L14.7311 25.2306H15.8422Z"
                                                                fill="#A7ABB7" />
                                                            <path d="M6.44444 19.1111V8.44444H16.6984V19.1111H6.44444Z"
                                                                stroke="#298E51" stroke-width="0.888889" />
                                                            <path
                                                                d="M9 16.8839L10.8635 13.7651L9.15659 10.6641H10.5273L11.4586 12.6498C11.5447 12.8377 11.6071 12.9774 11.6353 13.0706H11.6479C11.7087 12.9196 11.7733 12.7743 11.8408 12.6321L12.8367 10.6673H14.0983L12.3477 13.7507L14.1429 16.8863H12.8004L11.7243 14.7094C11.6743 14.6158 11.6319 14.5178 11.5974 14.4163H11.5796C11.5482 14.5152 11.5064 14.6098 11.4549 14.6982L10.3469 16.8839H9Z"
                                                                fill="#298E51" />
                                                            <path d="M19.7143 2.66667H0V0H18.923L19.7143 2.66667Z"
                                                                fill="#298E51" />
                                                            <path d="M18.9438 5.33267H24L18.9438 0V5.33267Z"
                                                                fill="#D3D5DB" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1779_24618">
                                                                <rect width="24" height="32" rx="1" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <svg class="uploadedThumbnail"
                                                    *ngIf="documentList?.fileContentType == 'application/vnd.ms-excel'" width="26" height="35" viewBox="0 0 21 28" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_12_294)">
                                                            <path d="M17 0H0V28H21V5L17 0Z" fill="#F3F3F3" />
                                                            <path
                                                                d="M8.27158 22.6503L8.70296 23.4718L9.14505 22.6503H9.81354L9.13281 23.8252L9.83189 25H9.16647L8.70296 24.1877L8.2471 25H7.57403L8.27158 23.8252L7.5985 22.6503H8.27158ZM10.8522 21.8671V25H10.2006V21.8671H10.8522ZM13.3292 23.3204L12.7326 23.3571C12.7224 23.3061 12.7005 23.2602 12.6668 23.2194C12.6332 23.1776 12.5888 23.1444 12.5338 23.12C12.4797 23.0945 12.415 23.0817 12.3395 23.0817C12.2385 23.0817 12.1534 23.1031 12.084 23.146C12.0147 23.1878 11.98 23.2439 11.98 23.3142C11.98 23.3703 12.0024 23.4178 12.0473 23.4565C12.0922 23.4953 12.1692 23.5264 12.2783 23.5498L12.7036 23.6355C12.932 23.6824 13.1023 23.7579 13.2145 23.8619C13.3267 23.9659 13.3828 24.1026 13.3828 24.2719C13.3828 24.4258 13.3374 24.561 13.2466 24.6772C13.1569 24.7935 13.0335 24.8843 12.8764 24.9495C12.7204 25.0138 12.5404 25.0459 12.3364 25.0459C12.0254 25.0459 11.7776 24.9811 11.593 24.8516C11.4094 24.7211 11.3018 24.5436 11.2702 24.3193L11.9112 24.2856C11.9305 24.3805 11.9775 24.4529 12.0519 24.5028C12.1263 24.5518 12.2217 24.5763 12.338 24.5763C12.4522 24.5763 12.544 24.5543 12.6133 24.5105C12.6837 24.4656 12.7194 24.408 12.7204 24.3376C12.7194 24.2785 12.6944 24.23 12.6454 24.1923C12.5965 24.1536 12.521 24.124 12.419 24.1036L12.0121 24.0225C11.7827 23.9766 11.6119 23.8971 11.4997 23.7839C11.3885 23.6707 11.3329 23.5264 11.3329 23.351C11.3329 23.2 11.3737 23.07 11.4553 22.9609C11.5379 22.8518 11.6537 22.7676 11.8026 22.7085C11.9525 22.6493 12.1279 22.6198 12.3288 22.6198C12.6255 22.6198 12.8591 22.6825 13.0294 22.8079C13.2007 22.9333 13.3007 23.1042 13.3292 23.3204Z"
                                                                fill="#858585" />
                                                            <path d="M5.78167 7.39886H14.984V16.7122H5.78167V7.39886Z"
                                                                stroke="#298E51" stroke-width="0.797721" />
                                                            <path
                                                                d="M8.07422 14.7705L9.74655 12.0416L8.21474 9.32812H9.44485L10.2807 11.0657C10.3579 11.2301 10.4139 11.3523 10.4392 11.4338H10.4505C10.5051 11.3017 10.5631 11.1746 10.6237 11.0502L11.5174 9.33094H12.6496L11.0785 12.0289L12.6896 14.7726H11.4848L10.5191 12.8678C10.4743 12.7859 10.4362 12.7001 10.4052 12.6114H10.3892C10.3611 12.6979 10.3235 12.7806 10.2773 12.858L9.28301 14.7705H8.07422Z"
                                                                fill="#298E51" />
                                                            <path d="M17.6923 2.33333H0V0H17L17.6923 2.33333Z"
                                                                fill="#298E51" />
                                                            <path d="M17 5H21L17 0V5Z" fill="#CECECE" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_12_294">
                                                                <rect width="21" height="28" rx="1" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </ng-container>
                                        </div>
                                        <div class="p-col-9 p-p-1 p-d-flex contentContainer p-flex-column p-flex-wrap">
                                            <div class="limit-text-length attachmentName cursor-pointer">
                                                <a (click)="showAttachmentPreview(documentList)">
                                                     {{documentList?.fileName}}
                                                </a>
                                            </div>
                                            <label class="attachmentFileType" title="{{fileType}}">Type: {{documentList?.fileTypeName || fileType}}</label>
                                        </div>
                                    </div>
                                    <div class="p-col-2 p-pr-0 p-d-flex p-jc-end">
                                        <i class="pi pi-trash cursor-pointer attachmentIcon" aria-hidden="true"
                                            (click)="onDeletePopup(documentList)"></i>
                                    </div>
                                </div>
                            </div>
                        </p-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="closeAlertInfoPopUpBtn" class="p-button-secondary p-button p-ml-0 p-mr-2" (click)="closeRejectPopUp(documentList)" type="button" label="Cancel"> </button>
            <button pButton id="closeAlertInfoPopUpBtn" [disabled]="disableRejectedSaveBtn" class="p-button-primary p-button p-mr-0" (click)="rejectComment()" type="button" label="Save"> </button>
        </div>
    </ng-template>
</p-dialog>


<p-dialog header="Crop Image" [(visible)]="displayModal" (onHide)="uploadLoader=false;" [modal]="true" [style]="{width: '60vw', height: '80vh'}"
    [draggable]="true" [resizable]="false" maskStyleClass="negativemask">
    <div class="p-col-12 p-text-center popupAttachmentBodyScrollContainer">
        <image-cropper styleClass="imageCropper" class="crop-content" [imageChangedEvent]="imgChangeEvt" [imageQuality]="75"
            [canvasRotation]="canvasRotation" [imageFile]="imageBlob" [maintainAspectRatio]="false" format="jpeg"
            id="image_cropper" (imageCropped)="imageCropEvent($event)">
        </image-cropper>
        <i class="pi pi-replay p-mr-3 p-pt-2" style="cursor:pointer;" (click)="rotateLeft()" aria-hidden="true"></i>
        <i class="pi pi-refresh p-pt-2" style="cursor:pointer;" (click)="rotateRight()" aria-hidden="true"></i>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="cropCancel()" label="Cancel" class="p-button-brand2"></button>
        <button pButton type="button" label="Upload" class="p-button-primary" (click)="onImgUpload();"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Delete" [(visible)]="deleteFile" class="DeleteFileModal" [modal]="true" [style]="{width: '27vw'}"
    [draggable]="false" [resizable]="false">
    <div class="p-fluid p-grid p-m-0">
        <div class="p-col-12 p-p-0">
            <p>Are you sure you want to delete?</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton (click)="deleteFile=false" label="No" class="p-button-secondary"></button>
        <button pButton label="Yes" (click)="onDelete()" class="p-button-primary"></button>
    </ng-template>
</p-dialog>