import { Routes } from '@angular/router';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { UserhierarchylistComponent } from './userhierarchylist/userhierarchylist.component';
import { ViewMappedUserComponent } from './ViewMappedUser/ViewMappedUser.component';
import { EditMappedUserComponent } from './EditMappedUser/EditMappedUser.component';
import { UsermappingComponent } from './usermapping/usermapping.component';
import { ReportingScreenGridComponent } from './ReportingScreenGrid/ReportingScreenGrid.component';
import { AllocationComponent } from './allocation/allocation.component';
import { ReportingScopeAuthService } from '../core/auth/reporting-scope-auth.service';
import { UserHierarchyScopeAuthService } from 'app/core/auth/user-hierarchy-scope-auth.service';
import { AllocationScopeAuthService } from 'app/core/auth/allocation-scope-auth.service';
import {ReportsComponent} from './reports/reports.component';
import { ReportscopeGuard } from 'app/core/auth/reportscope.guard';
import { SfReportingScreenGridComponent } from './sf-reporting-screen-grid/sf-reporting-screen-grid.component';
import { SfInitiationGridComponent } from './sf-initiation-grid/sf-initiation-grid.component';
import { SfRealizationGridComponent } from './sf-realization-grid/sf-realization-grid.component';
import { SfPushPullGridComponent } from './sf-push-pull-grid/sf-push-pull-grid.component';
import { RealizationScopeAuthService } from 'app/core/auth/realization-scope-auth.service';
import { PreviewComponent } from './preview/preview.component';
import { IrfPushPullGridComponent } from './irf-push-pull-grid/irf-push-pull-grid.component';
    export const PAGES_ROUTE: Routes = [
{
    path: 'userhierarchylist',
    component: UserhierarchylistComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, UserHierarchyScopeAuthService]
},
{
    path: 'viewmappeduser',
    component: ViewMappedUserComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, UserHierarchyScopeAuthService]
},
{
    path: 'editmappeduser',
    component: EditMappedUserComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, UserHierarchyScopeAuthService]
},
{
    path: 'usermapping',
    component: UsermappingComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, UserHierarchyScopeAuthService]
},
{
    path: 'irf',
    component: ReportingScreenGridComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, ReportingScopeAuthService]
},
{
    path: 'sf',
    component: SfReportingScreenGridComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, ReportingScopeAuthService]
},
{
    path: 'initiation',
    component: SfInitiationGridComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, ReportingScopeAuthService]
},
{
    path: 'realization',
    component: SfRealizationGridComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, RealizationScopeAuthService]
},{
    path: 'invoices/sf',
    component: SfPushPullGridComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, RealizationScopeAuthService]
},{
    path: 'invoices/irf',
    component: IrfPushPullGridComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, RealizationScopeAuthService]
},
{
    path: 'allocation',   
    component: AllocationComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, AllocationScopeAuthService]
    },
    {
        path: 'reports',
        component: ReportsComponent,
        data: {
            authorities: [],
            pageTitle: 'NexEra 2.0'
        }
        ,
        canActivate: [UserRouteAccessService, ReportscopeGuard]
    },
    {
        path: 'preview',
        component: PreviewComponent,
        data: {
            authorities: [],
            pageTitle: 'NexEra 2.0'
        }
        ,
        canActivate: [UserRouteAccessService]
    }
];
