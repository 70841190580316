import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { EditMappedUserComponent } from '../EditMappedUser/EditMappedUser.component';
import { MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
@Component({
  selector: 'jhi-viewmappeduser',
  templateUrl: './ViewMappedUser.component.html',
  styleUrls: ['./ViewMappedUser.component.scss'],
  providers: [DialogService, MessageService]
})
export class ViewMappedUserComponent implements OnInit {
  ViewMappedUserForm = this.fb.group({
    employeeId: [null,[]],
    employeeName: [null,[]],              
    bd_designation: [null,[]],
    mobileNo: [null,[]],
    email: [null,[]],
    shadowPortfolioEmployeeNameAndDesignation: [null,[]],
    immediateSupervisor: [null,[]],
    imeediateSupervisorLevelName: [null,[]],
    directorName: [null,[]],
    hrDesignation: [null,[]],
    opSpoc: [null,[]],
    teamName: [null,[]],
    paEmployeeNameAndDesignation: [null,[]],
    textField: [null,[]],
    pageData: [null],
    name: [null,[]],
    opsList: [null, []]
  });
  pageData: any;
  pageloadvalues: any;
  htmlSrc: any;
  designationResponse: any = {};
  designationList: any[] = [];
  shadowPortoflioList: any[] =[];
  html1Src: any;
  immediateSupervisorList: any[] =[];
  opSpocList: any[] =[];
  teamNameList: any[] =[];
  paApproverList: any[] =[];
  OpsSpocList :any[] = [];
  empId:any;
  empName:any;
  dynamicTableDialogACTIONRef: DynamicDialogRef = new DynamicDialogRef;
  viewSign: boolean = false;
  signature: any;
  displayPreview: boolean;
  fileUrl: any;
  obj: any = {};
  urlSafe: any;
  fileName: any;
  createScope: boolean = false;
  readScope: boolean = false;
  updateScope: boolean = false;
  scopes: any;
  shadowPortfolioEmployeeNameAndDesignationList: any[] = [];

  constructor(private encryptDecryptInterceptor :EncryptDecryptInterceptor, private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, private pagesService: PagesService, public ref: DynamicDialogRef, public config: DynamicDialogConfig,private dialogService: DialogService,private messageService: MessageService, public sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    if (this.config.data) {
      this.ViewMappedUserForm.patchValue({"pageData":this.config.data});
      if(this.config.data.signature != null && this.config.data.signature != "" && this.config.data.signature != undefined){
        this.viewSign = true;
        this.signature = this.config.data?.signature;
        this.fileName = this.config.data?.fileName;
      }
    }
    this.htmlSrc='';
    this.getdesignationList();
    this.getOpsSpocList();
    this.html1Src='';
    this.showSmallSignPreview();
    this.scopes = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    this.createScope = this.scopes.indexOf('USER HIERERCHY_CREATE') != -1 ? true : false;
    this.readScope = this.scopes.indexOf('USER HIERERCHY_READ') != -1 ? true : false;
    this.updateScope = this.scopes.indexOf('USER HIERERCHY_UPDATE') != -1 ? true : false;
    this.shadowPortfolioEmployeeNameAndDesignationList = this.config.data.shadowPortfolioUsersList;
    this.ViewMappedUserForm.get("name").setValue(this.config.data.shadowPortfolioUsersList);
    this.ViewMappedUserForm.get("opsList").setValue(this.config.data.opSpocUsersList);
  }
    setPageProperties(props: any): void {
      this.immediateSupervisorList.push(this.ViewMappedUserForm.value.pageData.immediateSupervisor);
      this.shadowPortoflioList.push({shadowPortfolioEmployeeId: this.ViewMappedUserForm.value.pageData.shadowPortfolioEmployeeId , shadowPortfolioEmployeeNameAndDesignation: this.ViewMappedUserForm.value.pageData.shadowPortfolioEmployeeNameAndDesignation });
      this.paApproverList.push({paEmployeeId: this.ViewMappedUserForm.value.pageData.paEmployeeId, paEmployeeNameAndDesignation: this.ViewMappedUserForm.value.pageData.paEmployeeNameAndDesignation});
    
      const pageData = {
        employeeId: this.ViewMappedUserForm.value.pageData.employeeId,
        employeeName: this.ViewMappedUserForm.value.pageData.employeeName,        
        mobileNo: this.ViewMappedUserForm.value.pageData.mobileNo,
        immediateSupervisor: this.immediateSupervisorList[0],
        imeediateSupervisorLevelName: this.ViewMappedUserForm.value.pageData.imeediateSupervisorLevelName,
        directorName: this.ViewMappedUserForm.value.pageData.directorName,
        hrDesignation: this.ViewMappedUserForm.value.pageData.hrDesignation,
        bd_designation: this.designationList.find(data => data.id === this.ViewMappedUserForm.value.pageData.bdDesignationId),
        teamName: this.teamNameList.find(team=> team.value === this.ViewMappedUserForm.value.pageData.teamName)  ,
        shadowPortfolioEmployeeNameAndDesignation: this.shadowPortoflioList[0],
        paEmployeeNameAndDesignation: this.paApproverList[0],
        email: this.ViewMappedUserForm.value.pageData.emailId
      };
      
      this.empId=pageData.employeeId;
      this.empName=pageData.employeeName;
      this.ViewMappedUserForm.patchValue(pageData);
   

    }
  

  getdesignationList(): void {
       this.pagesService.getdesignationDataViewMappedUser().subscribe(results => {
      
        this.designationList = results.bdDesignationList;
        this.teamNameList = results.teamList;
        this.setPageProperties(this.config.data);
      
    });
  }
  
  onCcprimebutton($event: UntypedFormGroup): void {
    setTimeout(() => {
      this.ref.close();
  }, 1000);
  }
  onCcprimebutton1(rowData: UntypedFormGroup): void {
    this.onCcprimebutton(this.ViewMappedUserForm);
    this.dynamicTableDialogACTIONRef = this.dialogService.open(EditMappedUserComponent, {
      contentStyle: {"max-height": "500px", "overflow": "hidden"},
      header: 'Edit User Hierarchy',
      closable: true,
      data: {formData: rowData, fileName: this?.fileName, signature: this?.signature},
      // width: '70%',
      styleClass: 'reponsivePopup'
    });
    this.dynamicTableDialogACTIONRef.onClose.subscribe((response: any) =>{
        if(response) {
        this.onCcprimebutton(this.ViewMappedUserForm);
        }
    });
  }
 
  formatDate(datestring){
    let date = new Date(datestring);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return day + '-' + month + '-' + year;
  }

  getOpsSpocList(): void {
       this.pagesService.getOpsSpocListDatausermapping().subscribe(results => {
      const responseData = results;
        this.OpsSpocList = responseData;
        const pageData = {
          opSpoc : this.OpsSpocList.find(ops=>ops.employeeId == this.ViewMappedUserForm.value.pageData.opSpoc)
        }
        console.log("ops users");
        console.log(pageData);
        this.ViewMappedUserForm.patchValue(pageData);
    });
  }

  showSignaturePreview() {
    this.displayPreview = true;
    let file = this.signature;
    if(file != undefined && file != null){
      let blob = this.b64toBlob(file, 'image/png');
      const blobUrl = URL.createObjectURL(blob);
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);        
    }
  }

  showSmallSignPreview() {
    let file = this.signature;
    if(file != undefined && file != null){
      let blob = this.b64toBlob(file, 'image/png');
      const blobUrl = URL.createObjectURL(blob);
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);        
    }
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  
}
