import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IrfInitiationDetailsComponent } from '../irf-initiation-details/irf-initiation-details.component';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { PagesService } from '../../pages/pages.service';
import { Table } from 'primeng/table/table';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { switchMap } from 'rxjs/operators';
import { InitiationService } from '../initiation.service';

@Component({
  selector: 'backoffice-irf-initiation-grid',
  templateUrl: './irf-initiation-grid.component.html',
  styleUrls: ['./irf-initiation-grid.component.scss'],
  providers: [DialogService, MessageService]
})
export class InitiationGridComponent implements OnInit {

  initiationDialog: DynamicDialogRef = new DynamicDialogRef;

  // breadcrumb variables - start
  ccprimebreadcrumbList = [
    { label: `IRF Initiation Listing`, routerLink: ['/initiation/irf'] },
  ];
  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  // breadcrumb variables - end
  
  // grid related variables - start
  @ViewChildren('mainGrid') mainGrid;

  initiationForm = this.fb.group({
    entityNameFilter: [null, []],
    nexeraUserFilter: [null, []],
    bdSpocFilter: [null, []],
    opportunityFilter: [null as any, []],
    businessFilter: [null, []],
    dateFilter: [null, []],
    ageingFilter: [null, []],
    gridCalender: [null as any, []],
  });
  // grid related variables - end

  gridDataList = [];

  // golbal calendar variables
  rangeDate: any;
  dateTime = new Date();
  filterDisplay = true;
  filterDisplayRow = true;
  globalStartDate: any;
  globalEndDate: any;
  minGlobalStartTime = new Date();
  maxGlobalEndTime = new Date();
  @ViewChild('globalCalendar') globalCalendar: any;
  
  opportunityStatus = "Business Reported";
  opportunityStatusCount = 0;

  // loader variables
  firstLoad = false;
  apiFail = false;
  gridLoader = false;
  loadingStatus = false; 

  //grid dropdown filter 
  oppIdList : any;
  agreementIdList: any;
  nameList : any;
  nexeraUserList : any;
  uniqbdSpocList : any;
  opportunityStatusList : Array<any>;
  businessTypeList : Array<any> = [];
  
  // search company popup -
  // auto complete company name related variables
  searchCompanyPopUp = false;
  showCross: boolean = false;
  showEntityLoader: boolean = false;
  emptyMsgVal: string = 'No Company Found';
  emptyMsgFlag: boolean = false;
  companyNameList = [];
  companyNameSearchValue : any;

  showFailureModel: boolean = false;
  errorMessage: string = "";
  userId;
  userRole;

  constructor(private renderer: Renderer2, private router: Router, private _location: Location, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private pagesService: PagesService, private dialogService: DialogService, private messageService: MessageService, private encyDyc: EncryptDecryptInterceptor, private cd: ChangeDetectorRef, private encryptDecryptInterceptor : EncryptDecryptInterceptor, private initionService: InitiationService) {
    this.minGlobalStartTime.setMonth(this.maxGlobalEndTime.getMonth() - 36);
  }

  ngOnInit(): void {
    // opportunity status dropdown picking required status from masters
    this.userId = this.encyDyc.decrypt(localStorage.getItem('empid'));
    this.userRole = this.encyDyc.decrypt(localStorage.getItem('role'));
    this.initionService.getBusinessTypeMaster().subscribe((res: Array<any>) => {
      if(res.length > 0){
        res?.forEach((r) => {
          this.businessTypeList.push({label: r.property, value: r.property});
        });
      }
    });
    this.opportunityStatusList = [];
    this.pagesService.getOpprStatusForInitiation().pipe(switchMap((status : Array<any>) => {
      if(status?.length > 0) {
        status.forEach(item => {
          this.opportunityStatusList.push({label : item[1], value : item[1]});
        });
        this.opportunityStatusList.push({label : "Initiated", value : "Initiated"});  // Initiated status is not stored as oppr status on backend, had to hard code it for Initiation screen
        this.initiationForm.patchValue({opportunityFilter : this.opportunityStatusList[0].label});
        let payload = {
          status : this.opportunityStatusList[0].label,
          bdSpocUserId : this.userId,
          userRole : this.userRole
        };
        this.gridLoader = true;
        return this.getGridList(payload);
      }
      return null;
    })).subscribe({
      next : item => this.setGridItem(item),
      error : err => this.errorStatus(err)
    }) ;
  }


  // breadrumb related method
  homeBreadcrumbClicked(event) {
    if(event.item?.icon == 'pi pi-home'){
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  // global calendar related methods - START
  datePicker(clear? : boolean){
    if(clear) {
      let payload = {
        status : this.initiationForm.value.opportunityFilter,
        companyCode : this.companyNameSearchValue?.companyCode,
        bdSpocUserId : this.userId,
        userRole : this.userRole
      };
      this.getIrfInitationListingData(payload); 
      this.globalStartDate = undefined;
      this.globalEndDate = undefined;
      return;
    }

    this.globalStartDate = this.initiationForm.value?.gridCalender[0]?.split("/").reverse().join("-");
    
    if(this.globalEndDate != null) {
      this.globalEndDate = null;
      return;
    }
      
    this.globalEndDate = this.initiationForm.value?.gridCalender[1]?.split("/").reverse().join("-");
    
    if(!!this.globalStartDate && !!this.globalEndDate) {
      this.globalCalendar.overlayVisible = false;
      let payload = {
        status : this.initiationForm.value.opportunityFilter,
        startDate : this.globalStartDate,
        endDate : this.globalEndDate,
        companyCode : this.companyNameSearchValue?.companyCode,
        bdSpocUserId : this.userId,
        userRole : this.userRole
      };
      this.getIrfInitationListingData(payload);  
    }
  }

  // global calendar related methods - END

  // clear filter
  showFilter(){
    this.filterDisplayRow = true;
    this.filterDisplay = true;
    this.cd.detectChanges();
  }

  clear(table : Table) {
    table.reset();
    this.cd.detectChanges();
    if(this.mainGrid?.first?.filters) { // reset all filters
      Object.values(this.mainGrid.first.filters).map(item => {
        (<any>item).value = null;
        return item;
      });
    }
    this.filterDisplayRow = false;
    this.filterDisplay = false;
    this.initiationForm.get('opportunityFilter').setValue('Business Reported');
    this.initiationForm.get('gridCalender').reset();
    let payload = {
      status : 'Business Reported',
      bdSpocUserId : this.userId,
      userRole : this.userRole
    };
    this.companyNameSearchValue = undefined;
    this.globalStartDate = undefined;
    this.globalEndDate = undefined;
    this.getIrfInitationListingData(payload); 
    this.cd.detectChanges();   
  }

  exportExcel(){}

  generateExcel(){}

  onOpportunityClick(rowData) {}

  onInitiationBackBtn(){
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  getGridList(payload) {
    return this.pagesService.findAllIrfInitiation(payload);
  }

  getIrfInitationListingData(payload) {
    if(!this.loadingStatus) // show only one loader at a time
      this.gridLoader = true;
    this.getGridList(payload).subscribe( {
      next : item => this.setGridItem(item),
      error : err => this.errorStatus(err)
    });
  }

  getOpprByStatus(opportunityStatus: string) {
    let payload = {
      status : opportunityStatus,
      startDate : this.globalStartDate,
      endDate : this.globalEndDate,
      companyCode : this.companyNameSearchValue?.companyCode,
      bdSpocUserId : this.userId,
      userRole : this.userRole
    };
    if(this.firstLoad)
      this.loadingStatus = true;
    this.getIrfInitationListingData(payload);
  }

  getIrfInitiationDetails(initiationDetails : any) {
    console.log("Row clicked :: ", initiationDetails);
    this.initiationDialog = this.dialogService.open(IrfInitiationDetailsComponent, {
      contentStyle: { "height": "76vh", "overflow": "hidden" },
      closeOnEscape: false,
      closable: true,
      data: initiationDetails,
      width: '96%',
      header: initiationDetails?.name
    });

    this.initiationDialog.onClose.subscribe((closeResponse: any) => {
      console.log(closeResponse, 'ccc')
      if(typeof closeResponse != 'string' && closeResponse && closeResponse.status?.toLowerCase()=='error' && closeResponse?.showMsg) {
        console.log("Showing Error Message ccc");
        this.showFailureModel = true;
        this.errorMessage = closeResponse.message;
      } else {
        if(closeResponse?.toLowerCase() == "data saved successfully"){
          this.messageService.add({ severity: 'success', summary: 'Data save successfully'});
        }
        else if (closeResponse != undefined) {
          this.messageService.add({ severity: 'error', detail: 'Error while updating data, please try again!'});
        }
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
      }
      let payload = {
        status : this.initiationForm.value.opportunityFilter,
        startDate : this.globalStartDate,
        endDate : this.globalEndDate,
        companyCode : this.companyNameSearchValue?.companyCode,
        bdSpocUserId : this.userId,
        userRole : this.userRole
      };
      this.getIrfInitationListingData(payload); 
    });
  }

  showSearchCompanyPopUp() {
    if(!this.gridLoader && !this.loadingStatus)
      this.searchCompanyPopUp = true;
  }

  searchCompanyPopupClosed() {
    this.searchCompanyPopUp = false;
  }

  onChangeCompanySelect(event) {
    console.log("Event :: ", event);
  }

  applyCompanySearch() {
    let payload = {
      status : this.initiationForm?.value?.opportunityFilter,
      startDate : this.globalStartDate,
      endDate : this.globalEndDate,
      companyCode : this.companyNameSearchValue?.companyCode,
      bdSpocUserId : this.userId,
      userRole : this.userRole
    };
    this.getIrfInitationListingData(payload);
    this.searchCompanyPopUp = false;
  }

  loadCompanyList(event) {
    if(event?.query?.length >= 3){
      this.showEntityLoader = true;
      this.pagesService.getCompanySearchResultsIrfInitiation(event.query).subscribe(
        (results: any) => {
          const responseData = results;
          this.showEntityLoader = false;
          this.companyNameList = [];
          if (responseData && responseData.length > 0) {
            this.companyNameList = responseData.map(
              item => ({companyCode : item[0], companyName : item[1]}));
          } else {
            this.companyNameList = [];
            this.emptyMsgFlag = true;
          }
        }, (_err) => {
          this.companyNameList = []
          this.showEntityLoader = false;
          this.emptyMsgFlag = true;
          this.showCross = false;
        });
    } else {
      this.companyNameList = [];
      this.showCross = false;
    }
  }

  resetCompanySearch() {
    let payload = {
      status : this.initiationForm.value.opportunityFilter,
      startDate : this.globalStartDate,
      endDate : this.globalEndDate,
      bdSpocUserId : this.userId,
      userRole : this.userRole
    };
    this.getIrfInitationListingData(payload);
    this.companyNameSearchValue = undefined;
    this.searchCompanyPopUp = false;
  }

  // called under findAll subscription
  setGridItem(item) {
    console.log("Set item :: ", item);
    this.gridDataList = <[]>item;
    this.oppIdList = new Set();
    this.agreementIdList = new Set();
    this.nameList = new Set();
    this.nexeraUserList = new Set();
    this.uniqbdSpocList = new Set();

    this.gridDataList.forEach(item => {
      this.oppIdList.add(item.opportunityId);
      item.oppIdByList = {name : item.opportunityId};

      this.agreementIdList.add(item.agreementId);
      item.aggIdByList = {name : item.agreementId};

      this.nameList.add(item.name);
      item.entityNamebyList = {name : item.name};

      this.nexeraUserList.add(item.nexeraUser);
      item.nexeraUserbyList = {name : item.nexeraUser};
      
      this.uniqbdSpocList.add(item.bdSpoc);
      item.bdSpocbyList = {name : item.bdSpoc};

      const reportedDate = item?.reportedDate?.split("-").reverse().join("-");
      item.reportedDate = reportedDate;

      item.opportunityStatus = this.initiationForm.get("opportunityFilter").value;
      
      item.irfActualFees = item.irfActualFees ? Number(item.irfActualFees) : null;

    });

    this.oppIdList = Array.from(this.oppIdList).map(item => ({name : item}));
    this.agreementIdList = Array.from(this.agreementIdList).map(item => ({name : item}));
    this.nameList = Array.from(this.nameList).map(item => ({name : item}));
    this.nexeraUserList = Array.from(this.nexeraUserList).map(item => ({name : item}));
    this.uniqbdSpocList = Array.from(this.uniqbdSpocList).map(item => ({name : item}));

    this.gridLoader = false;
    this.loadingStatus = false;
    this.firstLoad = true;
  }

  // called on findAll error
  errorStatus(err) {
    console.error("Error while fetchin IRF initiation grid data :: " , err);
    this.gridLoader = false;
    this.loadingStatus = false;
    this.messageService.add({ severity: 'error', detail: 'Error while fetching data, try again! ' });
    setTimeout(() => {
      this.messageService.clear();
    }, 3000);
  }
}
