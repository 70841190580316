import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackofficeSharedModule } from 'app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { SvgModule } from 'app/backoffice-assets/svg.module';
import { OfflineBillsRoutingModule } from './offline-bills-routing.module';
import { OfflineBillsGridComponent } from './offline-bills-grid/offline-bills-grid.component';
import { OfflineBillsFormComponent } from './offline-bills-form/offline-bills-form.component';
import { CardModule } from 'primeng/card';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    OfflineBillsGridComponent,
    OfflineBillsFormComponent
  ],
  imports: [
    CommonModule,
    BackofficeSharedModule,
    ReactiveFormsModule,
    OfflineBillsRoutingModule,
    SvgModule,
    DialogModule,
    CardModule,
    PdfViewerModule
  ],
  providers: [
    DialogModule,
    DialogService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OfflineBillsModule { }
