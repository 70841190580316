import { AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { SfBillingService } from '../sf-billing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from 'app/pages/pages.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import * as FileSaver from 'file-saver';
import { ImageTransform } from 'ngx-image-cropper';
import { InitiationService } from 'app/initiation/initiation.service';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'backoffice-sf-finance-grid',
  templateUrl: './sf-finance-grid.component.html',
  styleUrls: ['./sf-finance-grid.component.scss'],
  providers: [DialogService, MessageService]
})
export class SfFinanceGridComponent implements OnInit, AfterViewInit {
  @ViewChild('fileuploadbtn') fileuploadbtn: FileUpload;
  @ViewChild('fileimportbtn') fileimportbtn: FileUpload;

  ccprimebreadcrumbList = [
    { label: `Finance Approval Listing`, routerLink: ['/sf-billing/sf-finance-grid'] },
  ];

  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  loadingStatus = false;
  showLoader: boolean = false;
  searchedEditList: any[] = [];
  gridLoader: boolean = false;
  apiFail: boolean = false;
  disableSearchBtn: boolean = true;

  financialYear;
  selectedCompanyName;
  sfBillIdField;

  companyNameList;
  sfBillIdFieldList;

  financialYearListUnfiltered;
  financialYearList;

  

  defaultValues = {
    financialYear: ''
  }
  filterSubjectCompanyName = new Subject<string>();
  filterSubjectSfBillId = new Subject<string>();
  excelIds: any[] = [];

  bdApprovedMsg: string = "";
  infoMsg: any;
  billStatusMasterData: any;
  userRole: any;
  userId: any;
  userName: any;
  showImportModal: any;
  rejectedCommentReason: any;
  rejectedCommentList: any;
  apiCallList: any = [];

  rejectedCommentDetails: any;
  fileItem: boolean = true;
  attachmentType = [];
  productMasterData: any;
  instrumentMasterData: any;

  //attachment
  disableRejectAddButton: boolean = false;
  openedFile: any;
  zoomValue: number = 1;
  rotate: number = 0
  displayPreview: boolean = false;
  previewFailed: boolean = false;
  urlSafe: any;
  canvasRotation: number = 0;
  documentList: any;

  //upload document
  currentFileEvent: any;
  fileType = 'SF Billing Finance Rejection';
  fileTypeId = 3;
  imgChangeEvt: any;
  imageBlob: any;
  uploadedFileName: any;
  returnBase64: any;
  displayModal: boolean = false;
  uploadLoader: boolean = false;
  croppedImageBase64: any;
  fileUploadfileUpload1: any = {
    files: []
  };
  uploadFile: any = {};
  returnUploaded: any;
  deleteFile: boolean = false;
  deleteFileData: any;
  transform: ImageTransform = {};
  uploadedFileIds: any;
  uploadedAttachmentsCallList: any;
  attachmentList: any;
  isPdf: boolean = false;
  previewLoader: boolean = false;
  currentPdf: any;
  alertInfoPopUp: boolean = false;
  disableRejectedSaveBtn: boolean = true;


  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;
  financeExcelImport: any;
  attachmentId: any;
  importedFile: any;
  errorMsg: boolean = false;
  errorCheckData: any;
  checkFail: boolean;
  approveErrorMsg: boolean = false;
  attachmentResponse: any;
  disableApproveBtn: boolean = true;
  scopeList: any;
  readScope: boolean = false;

  constructor(private fb: FormBuilder, private dialogService: DialogService, private renderer: Renderer2, private cd: ChangeDetectorRef, private sfBillingService: SfBillingService, private router: Router, private pagesService: PagesService, private route: ActivatedRoute, private messageService: MessageService, private datePipe: DatePipe, private encyDyc: EncryptDecryptInterceptor, private initiationService: InitiationService, private cdr: ChangeDetectorRef, private encryptDecryptInterceptor: EncryptDecryptInterceptor) {
    this.userRole = this.encyDyc.decrypt(localStorage.getItem('role'));
    this.userId = this.encyDyc.decrypt(localStorage.getItem('empid'));
    this.userName = this.encyDyc.decrypt(localStorage.getItem('empName'));
    const navigation = this.router.getCurrentNavigation();
    this.infoMsg = navigation?.extras?.state?.msg;
  }

  ngOnInit(): void {
    this.scopeList = this.encyDyc.decrypt(localStorage.getItem('scopes'));
    let scope: any = this.scopeList.split(",");
    if(scope.indexOf('BILLING_FINANCE_CREATE') == -1){
      this.readScope = true;
    } else {
      this.readScope = false;
    }

    const currDate = new Date();
    const currentMonth = currDate.getMonth();
    const currentFinancialYear = currentMonth >= 3 ? currDate.getFullYear() + '-' + (currDate.getFullYear() + 1).toString().substring(2) : (currDate.getFullYear()-1) + '-' + (currDate.getFullYear()).toString().substring(2);
    this.defaultValues = {
      financialYear: currentFinancialYear
    }

    this.getDropdownValuesApis();
    this.financialYear = this.defaultValues.financialYear;
    // this.searchList();
  }

  ngAfterViewInit(): void {
    if (this.infoMsg == 'finance approved') {
      this.messageService.add({ severity: 'success', summary: 'SF Bill ID Approved Successfully' });
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    }

    if (this.infoMsg == 'finance rejected') {
      this.messageService.add({ severity: 'success', summary: 'SF Bill ID Rejected Successfully' });
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    }
  }

  homeBreadcrumbClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}/#/nexera/dashboard/home`;
    }
  }

  onBackClick(): void {
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  getDropdownValuesApis() {
    //Financial Year
    this.sfBillingService.getFeeCapFinancialYear().subscribe({
      next: (data: any) => {
        this.financialYearListUnfiltered = data;
        const transformedDataFY = this.financialYearListUnfiltered.map(item => { return { id: item.id, label: item.financialYearDescription, value: item.financialYearDescription }; });
        this.financialYearList = transformedDataFY;

        console.log("FinancialYear data", data);
      },
      error: (err: any) => {
        console.log("FinancialYear err", err);
      }
    });
  }

  onInputFieldChange() {
    console.log("on change", this.financialYear);
    console.log("on change", this.selectedCompanyName);
    console.log("on change", this.sfBillIdField);

    if (this.financialYear == null) {
      this.disableSearchBtn = true;
    } else {
      this.disableSearchBtn = false;
    }
  }

  searchList() {
    this.gridLoader = true;
    this.apiFail = false;
    let selectedCompanyCode;
    let selectedSfBillId;

    if (this.selectedCompanyName == undefined || this.selectedCompanyName == null || this.selectedCompanyName === "") {
      selectedCompanyCode = null;
    } else {
      selectedCompanyCode = this.selectedCompanyName.companyCode;
    }

    if (this.sfBillIdField == undefined || this.sfBillIdField == null || this.sfBillIdField === "") {
      selectedSfBillId = null;
    } else {
      selectedSfBillId = this.sfBillIdField.sfBillId;
    }

    console.log("company name :", selectedCompanyCode);
    console.log("sf bill id :", selectedSfBillId);

    let payload = {
      "companyName": selectedCompanyCode,
      "financialYear": this.financialYear,
      "sfBillId": selectedSfBillId,
      "billStatus": [149],
      "bdOwnerId": null
    }
    this.sfBillingService.sfEditAndBdApprovalGridApi(payload).subscribe({
      next: (sfCaseDetails: Array<any>) => {
        this.searchedEditList = sfCaseDetails;
        this.gridLoader = false;
        this.apiFail = false;
        this.onInputFieldChange();
        this.fetchDataFromMaster();
      },
      error: (err) => {
        this.gridLoader = false;
        this.apiFail = true;
      }
    })
  }

  showErrorMsg(data: any) {
    if (data?.errorStatus != null && data?.errorStatus != '' && data?.errorStatus != undefined) {
      let invoiceDetailsId = "tooltipOpp_" + data.sfBillId;
      document.getElementById(invoiceDetailsId)?.setAttribute('data-tooltip', data?.errorStatus);
      this.renderer.addClass(document.body, 'overflow-scroll');
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }

  }

  showCompanyDetailsPage(opp: any) {
    console.log("clicked company details", opp.sfBillId);
    this.router.navigate(['/sf-billing/sf-finance-details', opp.sfBillId])
  }

  onCompanyNameFilter(e) {
    console.log("company name filter", e);
    console.log("company name filter", e.filter);

    const query: string = e.filter;
    if (query?.length >= 4) {
      console.log("company name filter", query);
      this.fetchedCompanyFilterDatafromAPI(query);
    } else if (query?.length <= 3) {
      this.companyNameList = [];
    }
  }

  fetchedCompanyFilterDatafromAPI(filterString: string) {
    this.sfBillingService.getCompanyNames(filterString).subscribe((data: any) => {
      this.companyNameList = data;
    })
  }

  onSfBillIdFilter(e) {
    const query: string = e.filter;
    if (query != null) {
      this.sfBillingService.getSfBillId(query).subscribe((data: any) => {
        this.sfBillIdFieldList = data;
        console.log("data sfbillid", this.sfBillIdFieldList);

      })
    } else {
      this.sfBillIdFieldList = [];
    }

  }

  resetFinancialYearDropdown() {
    console.log("onclear financial year 1", this.financialYear);

    // if (this.financialYear)
    this.financialYear = this.defaultValues.financialYear;
    console.log("onclear financial year 2", this.financialYear);
  }

  fetchDataFromMaster() {
    this.sfBillingService.getBillStatusMaster().subscribe((data: any) => {
      this.billStatusMasterData = data;
      this.combineBillStatusMasterAndBdCase();
    });
  }

  combineBillStatusMasterAndBdCase() {
    if (this.searchedEditList) {
      const details = this.searchedEditList;
      let productDetails = this.billStatusMasterData;
      details.forEach((item) => {
        const matchedBillStatus = productDetails.find((mitem) => mitem.id === item.billStatus);
        if (matchedBillStatus) {
          item.billStatusName = matchedBillStatus.property;
        }
      });
    }
  }

  companyNameOnClear() {
    this.companyNameList = []
  }

  sfBillIDOnClear() {
    this.sfBillIdFieldList = []
  }

  exportExcel() {
    let year;
    let selectedcompany;
    let selectedsfbillid;


    if (this.financialYear == undefined || this.financialYear == null || this.financialYear === "") {
      year = null
    } else {
      year = this.financialYear;
    }


    if (this.selectedCompanyName == undefined || this.selectedCompanyName == null || this.selectedCompanyName === "") {
      selectedcompany = null;
    } else {
      selectedcompany = this.selectedCompanyName.companyCode;
    }

    if (this.sfBillIdField == undefined || this.sfBillIdField == null || this.sfBillIdField === "") {
      selectedsfbillid = null;
    } else {
      selectedsfbillid = this.sfBillIdField.sfBillId;
    }


    let currentBdOwner;
    if (this.userRole === "ADMIN") {
      currentBdOwner = null;
    } else {
      currentBdOwner = this.userId;
    }

    let excelpayload = {
      "financialYear": year,
      "companyName": selectedcompany,
      "sfBillId": selectedsfbillid,
      "bdOwnerId": currentBdOwner
    };

    let date = new Date();
    let formattedDate = this.datePipe.transform(date, 'ddMMyyyy');
    let formattedTime = this.datePipe.transform(date, 'HHmmss');


    let fileName = `Pending For Finance Approval${formattedDate}${formattedTime}.xlsx`;
    this.sfBillingService.getFinanceExport(excelpayload).subscribe((res) => {
      /** src = `data:application/vnd.ms-excel;base64,${res.base64}`;
      const link = document.createElement('a');
      link.href = src;
      link.download = fileName;
      link.click(); **/
      const byteCharacters = atob(res.Base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "text/xlsx" });
      FileSaver.saveAs(blob, fileName);
    });
  }

  fetchRejectedDropdown() {
    this.apiCallList.push(this.sfBillingService.getRejectedReasonDropdown().subscribe((data: any) => {
      this.rejectedCommentList = data;

    }))
  }

  selectOnChangeRejectPopUp() {
    if (this.rejectedCommentReason && this.rejectedCommentDetails && this.documentList && this.importedFile) {
      this.disableRejectedSaveBtn = false;
      this.disableRejectAddButton = true;
    } else {
      this.disableRejectedSaveBtn = true;
    }

    if(this.importedFile && !this.rejectedCommentReason){
      this.disableApproveBtn = false;
    } else{
      this.disableApproveBtn = true;
    }
  }


  rejectComment() {
    this.showImportModal = false;
    let payload = {
      // "sfBillId": this.fetchedCompanyCode,
      "rejectedComment": this.rejectedCommentDetails,
      "rejectedReasonId": this.rejectedCommentReason,
      "billedStatus": 150,
      "bdActionedBy": this.userId,
      "actionStatus": 150
    }
    this.apiCallList.push(this.sfBillingService.updateFinanceStatus(payload).subscribe((data: any) => {
      console.log("onReject", data.StatusMsg);
      this.router.navigate(['/sf-billing/sf-finance-grid'], { state: { msg: 'finance rejected' } });
    }));
  }

  dismissMessageService() {
    this.documentList = undefined;
    this.disableRejectedSaveBtn = true;
    setTimeout(() => {
      this.messageService.clear()
    }, 3000);
  }

  onFileUplaod(event: any): void {
    console.log("event", event);

    this.canvasRotation = 0;
    this.currentFileEvent = event.currentFiles[0];
    if (this.currentFileEvent == undefined) {
      this.messageService.add({ severity: 'error', summary: 'File size cannot exceed 20 MB.' });
      this.dismissMessageService();
      return;
    }
    let ext = this.currentFileEvent?.name?.split('.');
    if (ext[ext.length - 1] == 'jfif') {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      this.dismissMessageService();
      return;
    }
    if (["image/svg+xml", "image/webp", "xlxs", "xls", "html", "xhtml", "txt", "gif", "application/exe", "zip", ".oft"].includes(this.currentFileEvent.type)) {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      this.dismissMessageService();
      return;
    }
    if (this.currentFileEvent.type === "image/svg+xml" || this.currentFileEvent.type === "image/webp" || this.currentFileEvent.type === "xlxs" || this.currentFileEvent.type === "xls" || this.currentFileEvent.type === "html" || this.currentFileEvent.type === "xhtml" || this.currentFileEvent.type === "txt" || this.currentFileEvent.type === "gif" || this.currentFileEvent.type === "application/exe" || this.currentFileEvent.type === "zip" || this.currentFileEvent.type === ".oft") {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      this.dismissMessageService();
      return;
    }
    this.imgChangeEvt = event.originalEvent;
    if (event.currentFiles[0]?.type == 'image/jpeg' || event.currentFiles[0]?.type == 'image/jpg' || event.currentFiles[0]?.type == 'image/png') {
      this.displayModal = true;
    } else {
      this.displayModal = false;
    }
    this.uploadLoader = true;
    for (const file of event.files) {
      if (file.type.includes('image')) {
        this.imageBlob = new Blob([file], { type: file.type });
        this.uploadedFileName = file.name;
      } else {
        //Added
        const fileReader = new FileReader();
        fileReader.readAsDataURL(event.currentFiles[0]);
        fileReader.onload = () => {
          this.returnBase64 = fileReader.result;
          this.onImgUpload();
        };
      }
    }


    if (event.currentFiles.length >= 1) {
      this.documentList = event.currentFiles;
      this.disableRejectAddButton = true
    }
  }

  fileuploadbtnFunction() {
    this.fileuploadbtn.choose();
  }

  fileimportbtnFunction() {
    this.fileimportbtn.choose();
  }

  onImgUpload() {
    if (this.dataURLtoFile && this.croppedImageBase64) {
      const file = this.dataURLtoFile(this.croppedImageBase64, this.uploadedFileName);
      this.fileUploadfileUpload1.files.push(file);
    }
    this.uploadFile = {};
    let date = new Date();
    let formattedDate = this.datePipe.transform(date, 'dd');
    let formattedTime = this.datePipe.transform(date, 'HHmmss');
    this.attachmentId = formattedDate + formattedTime;
    if (this.displayModal) {
      const displayData = this.getUploadedObjDisplay(this.fileType, this.attachmentId);
      console.log("displayData 1", displayData);

      this.documentList = displayData;
      this.uploadFile = displayData;
      this.uploadSingleFile(displayData);
    } else {
      this.returnUploaded = this.getUploadedObj(this.fileType, this.attachmentId);
      this.documentList = this.returnUploaded;
      console.log("displayData 2", this.returnUploaded);
      this.uploadFile = this.returnUploaded;
      this.uploadSingleFile(this.returnUploaded);
    }
    if (this.documentList.fileTypeName == this.fileType && this.documentList.id == this.fileTypeId) {
      this.documentList.fileTypeName = this.fileType;
      this.documentList.fileName = this.uploadFile.fileName;
      this.documentList.thumbNail = this.getThumbnail(this.uploadFile.fileContentType);
    }
    this.displayModal = false;
    this.selectOnChangeRejectPopUp();
  }

  uploadSingleFile(data) {
    this.uploadLoader = false;
    this.apiCallList.push(this.sfBillingService.singleFileUpload(data).subscribe((response) => {
      console.log("response", response);

      // let obj = this.documentList?.filter((d) => d?.fileName == data?.fileName);
      // obj.fileId = response?.fileId;
      if (data.fileName == this.documentList?.fileName) {
        this.uploadedFileIds = response?.fileId;
        this.cdr.detectChanges()
      }
    }, (_err) => {
      let obj = this.documentList?.find((d) => d?.fileName == data?.fileName);
      obj.file = '';
      obj.deleteId = null;
      obj.isDisabled = false;
      obj.fileName = '';
      obj.thumbNail = '';
      this.messageService.add({ severity: 'error', summary: 'something went wrong please try again!' });
    }));
  }


  getUploadedObjDisplay(fileType, attachmentId): any {
    let data = this.returnBase64;
    if (data.startsWith('data:application/pdf;base64,')) {
      data = data.replace('data:application/pdf;base64,', '');
    } else if (data.startsWith('data:image/png;base64,')) {
      data = data.replace('data:image/png;base64,', '');
    } else if (data.startsWith('data:application/doc;base64,')) {
      data = data.replace('data:application/doc;base64,', '');
    }


    return {
      userId: this.encyDyc.decrypt(localStorage.getItem('empid')),
      moduleName: 'SfBilling',
      moduleId: attachmentId,//add the generated id
      fileName: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].name,
      file: data,
      fileContentType: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].type,
      fileSize: (this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].size).toString(),
      fileTypeid: this.fileTypeId,
      fileTypeName: fileType,
      fileId: null,
    };
  }

  getUploadedObj(fileType, attachmentId): any {
    return {
      userId: this.encyDyc.decrypt(localStorage.getItem('empid')),
      moduleName: 'SfBilling',
      moduleId: attachmentId, //add generated id
      fileName: this.currentFileEvent?.name,
      file: this.returnBase64,
      fileContentType: this.currentFileEvent?.type,
      fileSize: (this.currentFileEvent?.size)?.toString(),
      fileTypeid: this.fileTypeId,
      fileTypeName: fileType,
      fileId: null,
    };
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  getThumbnail(type) {
    if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg') {
      return this.imgThumb;
    } else if (type == 'application/pdf') {
      return this.pdfThumb;
    } else if (type.startsWith('application/vnd.openxmlformats') || type.startsWith('application/msword')) {
      return this.wordThumb;
    } else {
      return this.mailThumb;
    }
  }

  dataURLtoFile(dataurl: any, filename: any): any {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  imageCropEvent(event: any): any {
    // this.croppedImageBase64 = event.base64;
    // this.returnBase64 = event.base64; //Added

    fetch(event.objectUrl)
       .then(response => response.blob())
       .then(blob => {
           const reader = new FileReader();
           reader.onloadend = () => {
               this.returnBase64 = reader.result;
               this.croppedImageBase64 = this.returnBase64;
           };
           reader.readAsDataURL(blob);
    });
  }

  onDeletePopup(item) {
    this.deleteFile = true;
    this.deleteFileData = item;
    if (this.deleteFileData.fileId == null) {
      this.deleteFileData.deleteId = this.uploadedFileIds
    } else if (this.deleteFileData.fileId != null) {
      this.deleteFileData.deleteId = item.fileId
    }
    console.log("this.deleteFileData", this.deleteFileData);
  }
  onDelete() {
    console.log("this.uploadedFileIds", this.uploadedFileIds);
    console.log("this.uploadedFileIds", this.deleteFileData?.deleteId);

    this.apiCallList.push(this.initiationService.deleteFileById(this.deleteFileData?.deleteId).subscribe((res) => {
      console.log("res", res);

      if (res?.status == "success") {
        if (this.documentList.type == this.deleteFileData.type) {
          this.documentList = null;
          this.disableRejectAddButton = false;
        }
        console.log("fetchedAttachments", this.uploadedFileIds);
        if (this.documentList != undefined) {
          this.disableRejectedSaveBtn = false;
          this.disableRejectAddButton = true;
        } else {
          this.disableRejectedSaveBtn = true;
        }
      }
    }, (_err) => {
      this.apiFail = true;
    }));
    this.deleteFile = false;
  }
  getAttachments(oppId) {
    this.apiCallList.push(this.sfBillingService.getAttachmentsByOpportunityIdAndModule(oppId, "SfBilling").subscribe((res) => {
      console.log("fetchedAttachments", res);
      const fetchedData = res;
      res = res.filter(item => item.fileTypeid == 2);
      console.log("fetchedAttachments", res);
      this.documentList = res[0];
      this.attachmentList = res;
    }));
    if (this.documentList != undefined) {
      this.disableRejectedSaveBtn = false;
      this.disableRejectAddButton = true;
    } else {
      this.disableRejectedSaveBtn = true;
    }
  }



  showAttachmentPreview(item) {
    this.openedFile = item;
    this.currentPdf = undefined;
    this.zoomValue = 1;
    this.rotate = 0;
    console.log(this.documentList);
    console.log(this.uploadedFileIds);

    if (this.uploadedFileIds) {
      this.previewLoader = true;
      let payload = {
        id: this.uploadedFileIds
      }
      this.apiCallList.push(this.sfBillingService.getBase64(payload).subscribe((res) => {
        if (res && res?.file) {
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          let isPreviewable = true;
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else if ("msg" === ext1?.toLowerCase()) {
            mime = 'data:application/vnd.ms-outlook;base64,';
            isPreviewable = false;
          }
          else if (['xls', 'xlsx'].includes(ext1?.toLowerCase())) {
            mime = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
            isPreviewable = false;
          }
          else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.attachmentResponse = res;
          this.urlSafe = `${mime}${res.file}`
          if (!this.isPdf) {
            let el = document.getElementById('imagePrev');
            setTimeout(() => {
              el.style.transform = 'rotate(0deg) scale(1)';
            }, 100);
          }
          this.displayPreview = isPreviewable;
          if (!isPreviewable) {
            this.downloadFile(res, this.urlSafe);
          }
          this.previewLoader = false;
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      }));
    }
  }
  downloadFile(item: any, urlSafe) {
    const link = document.createElement('a');
    link.href = urlSafe;
    link.download = item?.fileName;
    link.click();
  }

  cropCancel() {
    this.displayModal = false;
    this.documentList = null;
    this.disableRejectAddButton = false;
  }

  ngOnDestroy(): void {
    this.apiCallList.forEach((a) => {
      a.unsubscribe();
    })
  }

  importExcel() {
    this.fetchRejectedDropdown();
    this.showImportModal = true;
  }

  closeRejectPopUp(item) {
    if (item) {
      this.deleteFileData = item;
      if (this.deleteFileData.fileId == null) {
        this.deleteFileData.deleteId = this.uploadedFileIds
      } else if (this.deleteFileData.fileId != null) {
        this.deleteFileData.deleteId = item.fileId
      }
      this.onDelete();
    }

    this.errorMsg = false;
    this.approveErrorMsg = false;
    this.importedFile = null;
    this.rejectedCommentReason = undefined;
    this.rejectedCommentDetails = undefined;
    this.showImportModal = false;

  }

  onFinanceApprove() {
    this.errorMsg = false;
    if (!this.importedFile) {
      this.approveErrorMsg = true;
    } else {
      let payload = {
        "excelBase64": this.financeExcelImport,
        "status": 151,
        "employeeId": this.userId,
        "comment": this.rejectedCommentDetails,
        "reasonId": this.rejectedCommentReason,
        "attachmentFlag": this.attachmentId
      }

      this.apiCallList.push(this.sfBillingService.importFinanceData(payload).subscribe((data: any) => {
        console.log("onapprove", data);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/sf-billing/sf-finance-grid'], { state: { msg: 'finance approved' } }));
      }, (_error) => {
        console.log("errorrrr", _error);
        if (_error.status.toString().startsWith("40")) {
          this.errorCheckData = JSON.parse(this.encryptDecryptInterceptor.decrypt(_error?.error?.data)).body;
        } else {
          this.errorCheckData = "Failed to connect with the server, Sorry for the inconvenience";
        }
        this.checkFail = true;
      }

      ));
    }
  }

  onFinanceReject() {
    this.approveErrorMsg = false;
    if (!this.importedFile || !this.rejectedCommentDetails || !this.rejectedCommentReason || !this.documentList) {
      this.errorMsg = true;
    } else {
      let payload = {
        "excelBase64": this.financeExcelImport,
        "status": 150,
        "employeeId": this.userId,
        "comment": this.rejectedCommentDetails,
        "reasonId": this.rejectedCommentReason,
        "attachmentFlag": this.attachmentId
      }

      this.apiCallList.push(this.sfBillingService.importFinanceData(payload).subscribe((data: any) => {
        console.log("onapprove", data);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/sf-billing/sf-finance-grid'], { state: { msg: 'finance rejected' } }));
      }, (_error) => {
        console.log("errorrrr", _error);
        if (_error.status.toString().startsWith("40")) {
          this.errorCheckData = JSON.parse(this.encryptDecryptInterceptor.decrypt(_error?.error?.data)).body;
        } else {
          this.errorCheckData = "Failed to connect with the server, Sorry for the inconvenience";
        }
        this.checkFail = true;
      }

      ));
    }

  }

  clearData(item) {

    if (item) {
      this.deleteFileData = item;
      if (this.deleteFileData.fileId == null) {
        this.deleteFileData.deleteId = this.uploadedFileIds
      } else if (this.deleteFileData.fileId != null) {
        this.deleteFileData.deleteId = item.fileId
      }
      this.onDelete();
    }
    this.errorMsg = false;
    this.approveErrorMsg = false;
    this.rejectedCommentReason = undefined;
    this.rejectedCommentDetails = undefined;
    this.importedFile = null;
    this.selectOnChangeRejectPopUp();
  }

  async getExcelFile(event) {
    this.importedFile = event.currentFiles[0];
    if (this.importedFile == undefined || (this.importedFile.type != 'application/vnd.ms-excel' && this.importedFile.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      this.importedFile = null;
      this.messageService.add({ severity: 'error', summary: 'This file format is not acceptable!' });
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    } else {
      let file: File = event.files[0];
      let excelBase64 = await this.sfBillingService.convertToBase64(file);
      excelBase64 = (<string>excelBase64).split('base64,')[1];

      this.financeExcelImport = excelBase64;

      this.selectOnChangeRejectPopUp();
    }

  }

  onImportDeletePopup(item) {
    this.selectOnChangeRejectPopUp();
    this.importedFile = null;
    this.disableApproveBtn = true;
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    if(el){
      el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
    }
  }

  rotateRight() {
    this.canvasRotation++;
  }

  rotateLeft() {
    this.canvasRotation--;
  }
}
