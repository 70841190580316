<div>
    <form [formGroup]="ReportingScreenCOAForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 left">
                                <div class=" p-col-12">
                                    <div class="ui-fluid p-formgrid p-grid">
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                            <div class="   ">
                                                <h6>Opportunity Details</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" p-col-12">
                                    <div class="ui-fluid p-formgrid p-grid">
                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 left">
                                            <div class=" p-fluid   p-field    ">
                                                <label>
                                                    Business Type
                                                </label>
                                                <p-dropdown id="businessTypeid" placeholder="Select"
                                                    [options]="businessTypeList" [autoDisplayFirst]="false"
                                                    formControlName="businessType" optionLabel="property"
                                                    optionValue="property" [disabled]="true" placeholder="Select Business Type">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                            <div class=" p-fluid   p-field    ">
                                                <label>
                                                    Product
                                                </label>
                                                <div class="p-fluid  p-field">
                                                    <p-dropdown id="productselectid" name="product"
                                                        placeholder="Select a Product" [options]="productselectList"
                                                        [filter]="true" optionLabel="property"
                                                        optionValue="property" [autoDisplayFirst]="false"
                                                        formControlName="product" [disabled]="true">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                            <div class=" p-fluid   p-field    ">
                                                <label>
                                                    Instrument
                                                </label>
                                                <div class="p-fluid  p-field">
                                                    <p-dropdown id="instrumentselectid" name="instrument"
                                                        placeholder="Select Instrument" [options]="instrumentselectList"
                                                        [autoDisplayFirst]="false" formControlName="instrument"
                                                        [disabled]="true" optionValue="property" optionLabel="property">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                            <div class=" p-fluid   p-field    ">
                                                <label>
                                                    Quantum (CR)
                                                </label>
                                                <input type="text" id="quantumtextField1_id" name="quantum"
                                                    formControlName="quantum" pInputText placeholder=""
                                                    class="p-inputtext  " disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" p-col-12">
                                    <div class="ui-fluid p-formgrid p-grid">
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                            <div class="   ">
                                                <h6>Approval</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined p-mt-3 left">
                                    <div class="p-d-flex">
                                        <p-inputSwitch id="criticalDocReceivedinputSwitchid"
                                            formControlName="criticalDocReceived" name="critical_doc_received"
                                            (onChange)="docToggle($event)" [disabled]="isCriticalDocRecievedFlag">
                                        </p-inputSwitch>
                                        <label class="p-ml-2">
                                            Critical Doc Received
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0 popupFooterBtnContainer">
                            <div
                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-end p-ai-center p-offset-undefined p-left popupFooterBtnContent p-pr-4 p-pb-2">
                                <div class="btnwrap">
                                    <button pButton class="p-button-brand2 p-button" type="button" iconPos="left"
                                        (click)="onCancel()" label="Cancel">
                                    </button>
                                </div>
                                <div class="btnwrap" >
                                    <button pButton class="p-button-primary p-button" type="submit" iconPos="left"
                                        (click)="onSave();" label="Save" [disabled]="disableSaveBtn">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="no_data_div" *ngIf="onSaveLoader">
            <div class="app-loading" id="loader">
                <div class="loadbg"></div>
                <div class="bdload">
                    <div class="spinner">
                        <div class="rect1"></div>
                        <div class="rect2"></div>
                        <div class="rect3"></div>
                        <div class="rect4"></div>
                        <div class="rect5"></div>
                        <span>Loading...</span>
                    </div>
                </div>
            </div>
        </div> -->
    </form>
</div>