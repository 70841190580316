import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';

@Component({
  selector: 'backoffice-reporting-validation',
  templateUrl: './reporting-validation.component.html',
  styleUrls: ['./reporting-validation.component.scss']
})
export class ReportingValidationComponent implements OnInit {
  errorMessage: string;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,private pagesService: PagesService) { }

  ngOnInit(): void {
    this.errorMessage = this.pagesService.getMessageOnSaveError();

  }

  onClose(): void {
    this.pagesService.errorMessageOnSaveService="";
    setTimeout(() => {
      this.ref.close();
    }, 500);
  }
}
