import { Component, OnInit, RendererFactory2, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot, NavigationEnd, NavigationError, RoutesRecognized, NavigationStart } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { filter, pairwise } from "rxjs/operators";
import { AccountService } from 'app/core/auth/account.service';
import { LoadingIndicatorService } from 'app/loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';

@Component({
  selector: 'nexera-root',
  templateUrl: './main.component.html',
  styleUrls:['./main.component.scss'],
})
export class MainComponent implements OnInit {
  private renderer: Renderer2;
  loading = false;
  windowWidth: any;
  showSplash = true;
  currentUrl: any = null;
  isSplash:boolean
  isMsg:any
  scopeList: any;
  constructor(
    private accountService: AccountService,
    private titleService: Title,
    private router: Router,
    // private translateService: TranslateService,
    rootRenderer: RendererFactory2,
    private loadingIndicatorService: LoadingIndicatorService,
    private spinner: NgxSpinnerService,
    private encDec: EncryptDecryptInterceptor
  ) {
    console.log("Myron backoffice Main component");
    this.loadingIndicatorService.onLoadingChanged.subscribe((isLoading: boolean) => {
      this.loading = isLoading;
      if (this.loading) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
    this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null);
  }

  ngOnInit(): void {
    // try to log in automatically
    this.accountService.identity().subscribe();
    sessionStorage.setItem('homePageLoaded', 'true');
    this.router.events.subscribe(event => {
      sessionStorage.setItem('homePageLoaded', 'true');
      if (event instanceof NavigationEnd) {
        this.updateTitle();
      }
      if (event instanceof NavigationError && event.error.status === 404) {
        this.router.navigate(['/404']);
      }
    });

    // this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
    //   this.updateTitle();

    //   this.renderer.setAttribute(document.querySelector('html'), 'lang', langChangeEvent.lang);
    // });
    this.scopeList = localStorage.getItem('scopes');
    console.log('enc Scope: '+this.scopeList);
    this.scopeList = this.encDec.decrypt(this.scopeList);
  }
  loadSplash(){
    this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise()).subscribe((events: RoutesRecognized[]) => {
      console.log("Router Event"+ events );
      const toTitleCase = (phrase: any) => {
        return phrase.toLowerCase().split(' ').map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      };  
      this.currentUrl=toTitleCase(events[1].urlAfterRedirects.replace('/', '').replace('-', " ")); 
      console.log(this.currentUrl);
      if(this.currentUrl=="Pages/reportingscreengrid"){
        this.isSplash=true;
        this.isMsg="You can view reporting screen.";
      }
      else if(this.currentUrl=="Dashboard/home"){
        this.showSplash =false;
      }
      else{
        this.isSplash=false;
      }  
    });
    this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationStart) {
        this.viewSplash();
      }
  });
  }
  viewSplash(){
    this.windowWidth = '';
    this.showSplash =true;
    this.hideSplashScreen();
  }

  hideSplashScreen(){
    setTimeout(() => {
      this.showSplash = false;
      this.isSplash=true;
    }, 6000);
  }


  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] : '';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  private updateTitle(): void {
    let pageTitle = this.getPageTitle(this.router.routerState.snapshot.root);
    if (!pageTitle) {
      pageTitle = 'global.title';
      this.router.navigate(['nexera/dashboard/home']);
    }
    // this.translateService.get(pageTitle).subscribe(title => this.titleService.setTitle(title));
  }
}
