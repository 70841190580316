<div class=""> 
    <form [formGroup]="ReportingAttachmentForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid popupAttachmentBodyScrollContainer">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-center">
                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                <div class="p-col-12" style="height:400px">
                                </div>
                                <div class="col-md-8">
                                    <image-cropper [imageChangedEvent]="imgChangeEvt" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
                                        [resizeToWidth]="256" format="jpeg"  (imageLoaded)="imgLoad()"
                                        (cropperReady)="initCropper()" (loadImageFailed)="imgFailed()">
                                    </image-cropper>
                                </div>
                            </div>
                        </div> 
                        
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>



<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0 popupFooterBtnContainer">
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-between p-ai-center p-offset-undefined p-left popupFooterBtnContent p-pr-4 p-pt-2 p-pb-2">
        <div class="">
            <button pButton class="p-button-secondary p-button-sm p-button" type="submit"
            (click)="onCroppedUploadBtn();" label="Upload"></button>
        </div>
    </div>
</div>

