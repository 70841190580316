import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { OfflineBillsGridComponent } from './offline-bills-grid/offline-bills-grid.component';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { OfflineBillsFormComponent } from './offline-bills-form/offline-bills-form.component';

const routes: Routes = [
    {
        path: 'grid',
        // component: OfflineBillsFormComponent,
        component: OfflineBillsGridComponent,
        data: {
            authorities: [],
            pageTitle: 'NexEra 2.0'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'offlinebillform/:irfBillId',
        component: OfflineBillsFormComponent,
        // component: OfflineBillsGridComponent,
        data: {
            authorities: [],
            pageTitle: 'NexEra 2.0'
        },
        canActivate: [UserRouteAccessService]
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class OfflineBillsRoutingModule {}