const domain_nexera  = 'BASE_URL_CC' + 'nexera/service/';
export const environment = {
  

    production: false,
    BACKOFFICE_SERVER_API_URL: 'BASE_URL_CC' + 'backoffice/service/',
    USER_MANAGEMENT_SERVER_API_URL: 'BASE_URL_CC' + 'usermanagement/service/',
    SERVER_API_INTEGRATION_URL: 'BASE_URL_CC' + 'integration/service/',
    MASTER_SERVICE_URL : 'BASE_URL_CC' + 'master/service/',
    KEY_CLOAK_REALM_NAME: 'KEY_CLOAK_REALMNAME',
    KEY_CLOAK_URL: 'KEY_CLOAK_ISSUER_URI',
    DOMAIN:domain_nexera,
    DOMAIN_ROUTE: 'BASE_URL_CC',
    NEXERA_SERVICE: domain_nexera,
    KEY_CLOAK_CLIENT_ID: 'KEY_CLOAK_CLIENT_ID',
    KIBANA_UI_SERVICE_API_URL: 'http://mumchsem01:8200',
    IMAGE_PATH: 'https://customerconnect-dev.crisil.com/nexera/content',
    REPORTING_SERVER_URL: domain_nexera + 'api/v1/reporting',
    NEXERA_ENTITY_URL: domain_nexera + "api/v1/entity",
    BACKOFFICE_ALLOCATION_URL: domain_nexera + "api/v1/allocation",
    BACKOFFICE_CRM_URL: 'BASE_URL_CC' + 'integration/service/api/v1/CRMintegration',
    FILEUPLOAD_URL: 'BASE_URL_CC' + 'fileupload/service/',
    OPPORTUNITY_SERVER_URL: domain_nexera + 'api/v1/opportunity',
    SFINITIATION_URL: domain_nexera + 'api/v1/sf/initiation',
    SFREALIZATION_URL: domain_nexera + 'api/v1/sf/realization',
    IRFREALIZATION_URL : domain_nexera + 'api/v1/irf/realization',
    SFPUSHGRID_URL: domain_nexera + 'api/v1/sf/pushpull',
    IRFPUSHGRID_URL: domain_nexera + 'api/v1/irf/pushpull',
    profile: 'prod'
};