import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { PagesService } from 'app/pages/pages.service';
import { SfBillingService } from '../sf-billing.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentModelComponent } from 'app/pages/attachment-model/attachment-model.component';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { ImageTransform } from 'ngx-image-cropper';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { InitiationService } from 'app/initiation/initiation.service';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'backoffice-bd-approval-details',
  templateUrl: './bd-approval-details.component.html',
  styleUrls: ['./bd-approval-details.component.scss'],
  providers:[MessageService, DialogService]
})
export class BdApprovalDetailsComponent implements OnInit {
  @ViewChild('fileuploadbtn') fileuploadbtn: FileUpload;

  applicationBreadcrumbList = [];
  agreement_details: any;
  apiFail: boolean = false;
  dataLoader: boolean = false;

  fetchedURLAgreementId;
  fetchedBdDetails;
  fetchedFirstBdDetails;

  homeiconapplicationBreadcrumb = { icon: 'pi pi-home', routerLink: ['/backoffice/sf-billing/bd-approval-grid'] };

  openedFileName: any;

  viewButtonitems: MenuItem[];

  alertInfoPopUp: boolean = false;
  disableRejectedSaveBtn: boolean = true;

  sfEditCompanyDetailsForm : FormGroup;
  
  fetchedCompanyName:any;
  fetchedCompanyCode:any;
  rejectedCommentReason:any;
  rejectedCommentList:any;

  showRejectModal:any;

  rejectedCommentDetails:any;
  fileItem: boolean= true;
  attachmentType = [];
  userId:any;
  userRole:any;
  productMasterData:any;
  instrumentMasterData:any;
  
  //attachment
  disableRejectAddButton: boolean = false;
  openedFile: any;
  zoomValue: number = 1;
  rotate: number = 0
  displayPreview: boolean = false;
  previewFailed: boolean = false;
  urlSafe: any;
  canvasRotation: number = 0;
  documentList:any;

  //upload document
  currentFileEvent: any;
  fileType = 'SF Billing BD Rejection';
  fileTypeId = 2;
  imgChangeEvt: any;
  imageBlob: any;
  uploadedFileName: any;
  returnBase64: any;
  displayModal: boolean = false;
  uploadLoader: boolean = false;
  croppedImageBase64: any;
  fileUploadfileUpload1: any = {
    files: []
  };
  uploadFile: any = {};
  returnUploaded: any;
  deleteFile: boolean = false;
  deleteFileData: any;
  transform: ImageTransform = {};
  uploadedFileIds:any;
  uploadedAttachmentsCallList:any;
  attachmentList:any;
  isPdf: boolean = false;
  previewLoader: boolean = false;
  currentPdf:any;
  
  apiCallList: any = [];
  
  navigatedTo:string;
  checkNavigation:string;
  traceBackFilters:any;
  scopeList: any;
  readScope: boolean = false;
  editAttach: any = [];

  
  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;
  attachmentResponse: any;

  constructor(private pagesService: PagesService, private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private sfBillingService: SfBillingService, private messageService: MessageService, private dialogService: DialogService, private encyDyc: EncryptDecryptInterceptor, private initiationService: InitiationService,private cdr: ChangeDetectorRef,) {
    this.userId = this.encyDyc.decrypt(localStorage.getItem('empid'));
    this.userRole = this.encyDyc.decrypt(localStorage.getItem('role'));
    
    this.route.paramMap.subscribe(params => {
      this.fetchedCompanyCode = params.get('id');
      console.log("params",this.fetchedCompanyCode);
      this.fetchBDDetails(this.fetchedCompanyCode);
      
    });
  }
  transformedData: any[] = [];
  
  ngOnInit(): void {
    this.scopeList = this.encyDyc.decrypt(localStorage.getItem('scopes'));
    let scope: any = this.scopeList.split(",");
    if(scope.indexOf('BILLING_BD_CREATE') == -1){
      this.readScope = true;
    } else {
      this.readScope = false;
    }
    console.log("documentList",this.rejectedCommentReason, this.rejectedCommentDetails);
    this.getAttachments(this.fetchedCompanyCode);    
    
    this.navigatedTo = '/sf-billing/bd-approval-grid';
  }
  
  onBackClick(): void {
    this.router.navigate(['/sf-billing/bd-approval-grid']);
    this.checkNavigation ='/sf-billing/bd-approval-grid';
  }

  closeAlertInfoPopUp(){
    this.alertInfoPopUp = false;
  }

  closeRejectPopUp(){
    this.showRejectModal = false;
    this.rejectedCommentReason = undefined;
    this.rejectedCommentDetails = undefined;
  }

  onApprove(){
    this.sfBillingService.sharedMsgString = "";
    let payload = {
      "sfBillId": this.fetchedCompanyCode,
      "rejectedCommentReason":null,
      "rejectedReasonId":null,
      "billedStatus": 149,
      "bdActionedBy":this.userId
    }
    this.apiCallList.push(this.sfBillingService.onApproveBdApproval(payload).subscribe((data:any)=>{
      console.log("onapprove",data);
      // this.sfBillingService.sharedMsgString = data.SuccessMsg;
      this.router.navigate(['/sf-billing/bd-approval-grid'], {state: {msg: 'bd approved'}});
      // console.log("this.sfBillingService.sharedMsgString",this.sfBillingService.sharedMsgString);
    }))
  }

  onReject(){
    // this.selectOnChangeRejectPopUp();
    this.fetchRejectedDropdown();
    this.getAttachments(this.fetchedCompanyCode);
    this.showRejectModal = true;
  }

  fetchBDDetails(codeSfId){
    this.apiCallList.push(this.sfBillingService.getBdApprovalDetails(codeSfId).subscribe((data:any)=>{
      this.fetchedBdDetails = data;
      this.fetchedFirstBdDetails = data[0];
      this.apiFail = false;
      this.getAttachment();
      this.applicationBreadcrumbList = [
        { label: `BD Approval Listing`, routerLink: ['/sf-billing/bd-approval-grid'] },
        { label: this.fetchedFirstBdDetails?.companyName, routerLink: ['/sf-billing/bd-approval-details',this.fetchedFirstBdDetails?.companyCode] },
      ];
      this.fetchDataFromMaster();
    }, (err: any)=>{
      this.apiFail = true
    }));
  }

  fetchRejectedDropdown(){
    this.apiCallList.push(this.sfBillingService.getRejectedReasonDropdown().subscribe((data:any)=> {
      this.rejectedCommentList = data;
      
    }))
  }

  fetchDataFromMaster(){
    this.apiCallList.push(this.sfBillingService.getProductMaster().subscribe((data:any)=> {
      this.productMasterData = data;
      this.combineProductMasterAndBdCase();
    }));
    this.apiCallList.push(this.sfBillingService.getInstrumentMaster().subscribe((data:any)=> {
      this.instrumentMasterData = data
      this.combineInstrumentMasterAndBdCase();
    }));
  }

  combineProductMasterAndBdCase(){
    if(this.fetchedBdDetails){
      const details = this.fetchedBdDetails;
      let productDetails = this.productMasterData;
      details.forEach((item) => {
        const matchedProduct = productDetails.find((mitem)=> mitem.id === item.productId);
        if(matchedProduct){
          item.productName = matchedProduct.product_name;
        }
      });
    }
  }

  combineInstrumentMasterAndBdCase(){
    if(this.fetchedBdDetails){
      const details = this.fetchedBdDetails;
      let instrumentDetails = this.instrumentMasterData;
      details.forEach((item) => {
        const matchedInstrument = instrumentDetails.find((mitem)=> mitem.id === item.instrumentId);
        if(matchedInstrument){
          item.instrumentName = matchedInstrument.name;
        }
      });
    }
  }

  selectOnChangeRejectPopUp(){
    console.log("this.documentList",this.documentList, this.rejectedCommentDetails, this.rejectedCommentReason);
    
    if(this.rejectedCommentReason != undefined && this.rejectedCommentDetails != undefined && this.documentList != undefined && this.rejectedCommentDetails != null && this.rejectedCommentDetails != ""){
      this.disableRejectedSaveBtn = false;
      if(this.documentList != undefined){
        this.disableRejectAddButton = true;
      }else{
        this.disableRejectAddButton = false;
      }
    }else{
      if(this.documentList != undefined){
        this.disableRejectAddButton = true;
      }else{
        this.disableRejectAddButton = false;
      }
      this.disableRejectedSaveBtn = true;
    }
  }

  rejectComment(){
    this.showRejectModal = false;
    let payload = {
      "sfBillId": this.fetchedCompanyCode,
      "rejectedComment": this.rejectedCommentDetails,
      "rejectedReasonId": this.rejectedCommentReason,
      "billedStatus": 148,
      "bdActionedBy": this.userId
    }
    this.apiCallList.push(this.sfBillingService.onRejectBdApproval(payload).subscribe((data:any)=>{
      console.log("onReject",data.StatusMsg);
      this.router.navigate(['/sf-billing/bd-approval-grid'], {state: {msg: 'bd rejected'}});
      // this.sfBillingService.sharedMsgString = data.StatusMsg;
      // console.log("this.sfBillingService.sharedMsgString",this.sfBillingService.sharedMsgString);
      
    }));
  }
  
  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
  }
  rotateLeft() {
    this.canvasRotation--;
    //this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    //this.flipAfterRotate();
  }

  dismissMessageService(){
    this.documentList = undefined;
    this.disableRejectedSaveBtn = true;
    setTimeout(() => {
      this.messageService.clear()
    }, 3000);
  }

  onFileUplaod(event: any): void {
    console.log("event",event);

    this.canvasRotation = 0;
    this.currentFileEvent = event.currentFiles[0];
    if (event.files[0].type === "image/svg+xml" || event.files[0].type === "image/webp" || event.files[0].type.includes("html") ||event.files[0].type=== "xhtml"  ||event.files[0].type=== "csv" || event.files[0].type=== "txt" || event.files[0].type=== "gif" || event.files[0].type === "application/exe" || event.files[0].type === "zip" || event.files[0].type=== ".oft" || event.files[0].type=== "application/x-msdownload") {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      this.dismissMessageService();
      return;
    }
    if (this.currentFileEvent == undefined) {
      this.messageService.add({ severity: 'error', summary: 'File size cannot exceed 20 MB.' });
      this.dismissMessageService();
      return;
    }
    let ext = this.currentFileEvent?.name?.split('.');
    if (ext[ext.length - 1] == 'jfif') {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      this.dismissMessageService();
      return;
    }
    this.imgChangeEvt = event.originalEvent;
    if (event.currentFiles[0]?.type == 'image/jpeg' || event.currentFiles[0]?.type == 'image/jpg' || event.currentFiles[0]?.type == 'image/png') {
      this.displayModal = true;
    } else {
      this.displayModal = false;
    }
    this.uploadLoader = true;
    for (const file of event.files) {
      if (file.type.includes('image')) {
        this.imageBlob = new Blob([file], { type: file.type });
        this.uploadedFileName = file.name;
      } else {
        //Added
        const fileReader = new FileReader();
        fileReader.readAsDataURL(event.currentFiles[0]);
        fileReader.onload = () => {
          this.returnBase64 = fileReader.result;
          this.onImgUpload();
        };
      }
    }
    
    
    if(event.currentFiles.length >= 1){
      this.documentList = event.currentFiles;
      this.disableRejectAddButton = true
    }
  }

  fileuploadbtnFunction(){
    this.fileuploadbtn.choose();
  }

  onImgUpload() {
    if (this.dataURLtoFile && this.croppedImageBase64) {
      const file = this.dataURLtoFile(this.croppedImageBase64, this.uploadedFileName);
      this.fileUploadfileUpload1.files.push(file);
    }
    this.uploadFile = {};
    if (this.displayModal) {
      const displayData = this.getUploadedObjDisplay(this.fileType);
      console.log("displayData 1",displayData);
      
      this.documentList = displayData;
      // this.attachmentResult.push(displayData);
      this.uploadFile = displayData;
      this.uploadSingleFile(displayData);
    } else {
      this.returnUploaded = this.getUploadedObj(this.fileType);
      this.documentList = this.returnUploaded;
      console.log("displayData 2",this.returnUploaded);
      // this.attachmentResult.push(this.returnUploaded);
      this.uploadFile = this.returnUploaded;
      this.uploadSingleFile(this.returnUploaded);
    }
    

    // this.documentList.filter((d) => {
    //   console.log("displayData uploaded document",d);
      
    if (this.documentList.fileName.includes('.msg')) {
      this.documentList.fileTypeName = this.fileType;
      this.documentList.fileName = this.uploadFile.fileName;
      this.documentList.thumbNail = this.getThumbnail(this.uploadFile.fileContentType);
    }
    // });
    this.displayModal = false;
    this.selectOnChangeRejectPopUp();
  }
  
  uploadSingleFile(data){
    this.uploadLoader = false;
    this.apiCallList.push(this.sfBillingService.singleFileUpload(data).subscribe((response) => {
      console.log("response",response);
      
      // let obj = this.documentList?.filter((d) => d?.fileName == data?.fileName);
      // obj.fileId = response?.fileId;
      if(data.fileName == this.documentList?.fileName){
        this.uploadedFileIds = response?.fileId;
        this.cdr.detectChanges()
      }
      this.getAttachments(this.fetchedCompanyCode);
    }, (_err) => {
      let obj = this.documentList?.find((d) => d?.fileName == data?.fileName);
      obj.file = '';
      obj.deleteId = null;
      obj.isDisabled = false;
      obj.fileName = '';
      obj.thumbNail = '';
      this.messageService.add({ severity: 'error', summary: 'something went wrong please try again!' });
    }));
  }

  
  getUploadedObjDisplay(fileType): any {
    let data = this.returnBase64;
    if (data.startsWith('data:application/pdf;base64,')) {
      data = data.replace('data:application/pdf;base64,', '');
    } else if (data.startsWith('data:image/png;base64,')) {
      data = data.replace('data:image/png;base64,', '');
    } else if (data.startsWith('data:application/doc;base64,')) {
      data = data.replace('data:application/doc;base64,', '');
    }

    return {
      userId: this.encyDyc.decrypt(localStorage.getItem('empid')),
      moduleName: 'SfBilling',
      moduleId: this.fetchedCompanyCode,
      fileName: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].name,
      file: data,
      fileContentType: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].type,
      fileSize: (this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].size).toString(),
      fileTypeid: this.fileTypeId,
      fileTypeName: fileType,
      fileId: null,
    };
  }

  getUploadedObj(fileType): any {
    return {
      userId: this.encyDyc.decrypt(localStorage.getItem('empid')),
      moduleName: 'SfBilling',
      moduleId: this.fetchedCompanyCode,
      fileName: this.currentFileEvent?.name,
      file: this.returnBase64,
      fileContentType: this.currentFileEvent?.type,
      fileSize: (this.currentFileEvent?.size)?.toString(),
      fileTypeid: this.fileTypeId,
      fileTypeName: fileType,
      fileId: null,
    };
  }
  
  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  getThumbnail(type) {
    if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg') {
      return this.imgThumb;
    } else if (type == 'application/pdf') {
      return this.pdfThumb;
    } else if (type.startsWith('application/vnd.openxmlformats') || type.startsWith('application/msword')) {
      return this.wordThumb;
    } else {
      return this.mailThumb;
    }
  }

  dataURLtoFile(dataurl: any, filename: any): any {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  imageCropEvent(event: any): any {
    // this.croppedImageBase64 = event.base64;
    // this.returnBase64 = event.base64; //Added

    fetch(event.objectUrl)
       .then(response => response.blob())
       .then(blob => {
           const reader = new FileReader();
           reader.onloadend = () => {
               this.returnBase64 = reader.result;
               this.croppedImageBase64 = this.returnBase64;
           };
           reader.readAsDataURL(blob);
    });
  }

  onDeletePopup(item) {
    this.deleteFile = true;
    this.deleteFileData = item;
    if(this.deleteFileData.fileId == null){
      this.deleteFileData.deleteId = this.uploadedFileIds
    }else if(this.deleteFileData.fileId != null){
      this.deleteFileData.deleteId = item.fileId
    }
    console.log("this.deleteFileData",this.deleteFileData);
  }
  onDelete() {
    console.log("this.uploadedFileIds",this.uploadedFileIds);
    console.log("this.uploadedFileIds",this.deleteFileData?.deleteId);
    
    this.apiCallList.push(this.initiationService.deleteFileById(this.deleteFileData?.deleteId).subscribe((res) => {
      console.log("res",res);
      
      if(res?.status == "success"){
        if (this.documentList.type == this.deleteFileData.type) {
          this.documentList = null;
          this.disableRejectAddButton = false;
        }
        console.log("fetchedAttachments",this.uploadedFileIds);
        this.selectOnChangeRejectPopUp();
        // if(this.documentList != undefined){
        //   this.disableRejectedSaveBtn = false;
        //   this.disableRejectAddButton = true;
        // }else{
        //   this.disableRejectedSaveBtn = true;
        // }
        // console.log("this.documentList",this.documentList);
        // let index = this.uploadedFileIds.findIndex((r) => r == this.deleteFileData?.deleteId);
        // this.uploadedFileIds.splice(index, 1);
      }
    }, (_err) => {
      this.apiFail = true;
    }));
    this.deleteFile = false;
  }
  getAttachments(oppId) {
    this.apiCallList.push(this.sfBillingService.getAttachmentsByOpportunityIdAndModule(oppId, "SfBilling").subscribe((res) => {
      console.log("fetchedAttachments",res);
      const fetchedData = res;
      res = res.filter(item => item.fileTypeid == 2);
      console.log("fetchedAttachments",res);
      this.documentList = res[0];
      if(this.documentList?.fileName.includes('.msg')){
        this.documentList.thumbNail = this.mailThumb;
      }
      // else if()
      this.attachmentList = res;
    }));
    this.selectOnChangeRejectPopUp();
    // if(this.documentList != undefined){
    //   this.disableRejectedSaveBtn = false;
    //   this.disableRejectAddButton = true;
    // }else{
    //   this.disableRejectedSaveBtn = true;
    // }
  }
  
  showAttachmentPreview(item) {
    this.openedFile = item;
    this.currentPdf = undefined;
    // let ext = item.fileName?.split('.')[1];
    // if (ext.includes('msg')) {
    //   return;
    // }
    // this.getAttachments(this.fetchedCompanyCode);
    this.zoomValue = 1;
    this.rotate = 0;
    console.log(this.documentList);
    console.log(this.uploadedFileIds);
    
    if (item?.fileId) {
      this.previewLoader = true;
      let payload = {
        id: item.fileId
      }
      this.apiCallList.push(this.sfBillingService.getBase64(payload).subscribe((res) => {
        if (res && res?.file) {
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          let isPreviewable = true;
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else if ( "msg" === ext1?.toLowerCase()) {
            mime = 'data:application/vnd.ms-outlook;base64,';
            isPreviewable = false;
          } 
          else if ( ['xls', 'xlsx'].includes(ext1?.toLowerCase())) {
            mime = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
            isPreviewable = false;
          } 
          else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.attachmentResponse = res;
          this.urlSafe = `${mime}${res.file}`
          if (!this.isPdf) {
            let el = document.getElementById('imagePrev');
            setTimeout(() => {
              el.style.transform = 'rotate(0deg) scale(1)';
            }, 100);
          }
          this.displayPreview = isPreviewable;
          if(!isPreviewable) {
            this.downloadFile(res, this.urlSafe);
          }
          this.previewLoader = false;
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      }));
    }
  }
  downloadFile(item: any, urlSafe) {
    const link = document.createElement('a');
    link.href = urlSafe;
    link.download = item?.fileName;
    link.click();
  }

  cropCancel(){
    this.displayModal=false;
    this.documentList = null;
    this.disableRejectAddButton = false;
  }

  
  checkNavigationStrings():boolean{
    return this.navigatedTo.includes(this.checkNavigation);
  }

  ngOnDestroy(): void {
    this.apiCallList.forEach((a) => {
      a.unsubscribe();
    })
    console.log("this.navigatedTo",this.navigatedTo);
    if(!this.checkNavigationStrings()){
      this.traceBackFilters = null;
      localStorage.setItem('bdApprovalFilters', this.encyDyc.encrypt(JSON.stringify(this.traceBackFilters)));
      this.navigatedTo = '';
    }else{
      this.navigatedTo = '';
    }
  }

  getAttachment(){
    let paylaod = {
      oppId: this.fetchedFirstBdDetails?.sfBillId,
      moduleName: "SfBilling"
    }
   
    this.apiCallList.push(this.sfBillingService.getAttachmentByModule(paylaod).subscribe((res) => {
      console.log("Attachment details ",res);
      if(res?.length > 0){
        // 148	SF Bill Status	Rejected By BD
        // 1 SF Billing Edit Attachment
        res?.forEach((r) => {
          if(this.fetchedFirstBdDetails?.billStatus == 147 && r.fileTypeid == 1){
            this.editAttach.push(r)
          }
        });
      }
    }));
  }
}
