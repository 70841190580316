import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'backoffice-no-permission-to-access',
  templateUrl: './no-permission-to-access.component.html',
  styleUrls: ['./no-permission-to-access.component.scss']
})
export class NoPermissionToAccessComponent implements OnInit {

  constructor(private router: Router,public ref: DynamicDialogRef) { }

  ngOnInit(): void {
  }

  onCcprimebutton(){
    
    setTimeout(() => {
      this.ref.close();
    }, 500);
  }
}
