import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { validateAllFormFields } from 'app/shared/util/reactiveform-util';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { MappeduserService } from '../mappeduser.service';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'jhi-usermapping',
  templateUrl: './usermapping.component.html',
  styleUrls: ['./usermapping.component.scss'],
  providers: [MessageService]
})
export class UsermappingComponent implements OnInit {
  usermappingForm = this.fb.group({
    employeeId: [null, []],
    employeeName: [null, []],
    designation: [null as any, [Validators.required]],
    dateOfJoining: [null, []],
    departmentId: [null, []],
    departmentName: [null, []],
    mobileNo: [null, [Validators.required]],
    emailId: [null as any, []],
    shadowPortfolioEmployeeNameAndDesignation: [null, [Validators.required]],
    immediateSupervisor: [null as any, [Validators.required]],
    imeediateSupervisorLevelName: [null, []],
    directorName: [null, []],
    hrDesignation: [null, []],
    OpsSpoc: [null, [Validators.required]],
    team: [null as any, []],
    employeeNameAndDesignation: [null as any, [Validators.required]],

    pageData: [null],
  });
  pageData: any;
  pageloadvalues: any;
  htmlSrc: any;
  designationResponse: any = {};
  designationList: any[] = [];
  shadowPortfolioEmployeeNameAndDesignationResponse: any = {};
  shadowPortfolioEmployeeNameAndDesignationList: any[] = [];
  html1Src: any;
  immediateSupervisorResponse: any = {};
  immediateSupervisorList: any[] = [];
  OpsSpocList: any[] = [];
  teamResponse: any = {};
  teamList: any[] = [];
  employeeNameAndDesignationResponse: any = {};
  employeeNameAndDesignationList: any[] = [];

  currectimmediateSupervisor: any;
  immediateSupBD: any;
  Director: any;
  timeOut = true;
  effectiveDate: any;
  currectDate: any = new Date();

  toClearDesignation: any;
  // currectDate:any;
  now = new Date();
  maxdate = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 1);

  value: boolean = true;
  disableUpload: boolean = true;
  currentFileEvent: any;
  imgChangeEvt: any = "";
  cropImgPreview: any = "";
  displayModal: boolean;
  filenameForfileUpload1: any;
  fileToRemovefileUpload1: any;
  imageChangedEventfileUpload1: any;
  returnBase64: any;
  croppedImagefileUpload1: any = '';
  fileUploadfileUpload1: any = {
    files: []
  };
  signatureResult: any;
  returnUploaded: any;
  signBtn: boolean = true;
  deletePopUp: boolean = false;
  signature: any;
  displayPreview: boolean;
  fileUrl: any;
  obj: any = {};
  urlSafe: any;
  changeDesigAlert: boolean;
  loaderFlag: boolean;
  tempDesigId: any;

  private setData() {

    this.mappeduserService.setdata(this.value);
    this.value = false;

  }

  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private pagesService: PagesService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private messageService: MessageService, private mappeduserService: MappeduserService, private encryptDecryptInterceptor: EncryptDecryptInterceptor, public sanitizer: DomSanitizer) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.setPageProperties(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    if (this.config.data) {
      this.usermappingForm.patchValue({ "pageData": this.config.data });
      this.setPageProperties(this.config.data);

    }
    this.htmlSrc = '';
    this.getdesignationList();
    this.getshadowPortfolioEmployeeNameAndDesignationList(this.usermappingForm.value.employeeId);
    this.html1Src = '';
    this.getteamList();
    this.getOpsSpocList();

  }

  toClearMobile: any;
  toClearShadowPortfolio: any;
  toClareTeam: any;
  toClearApprover: any;
  toRefreshDate: any;

  RefreshForm() {
    this.toClearDesignation = '';
    this.toClearMobile = '';
    this.currectimmediateSupervisor = '';
    this.toClearShadowPortfolio = '';
    this.toClareTeam = '';
    this.toClearApprover = '';
    this.immediateSupBD = '';
    this.Director = '';
    this.toRefreshDate = this.effectiveDate;
    this.signatureResult = null;
    this.signBtn = true;
    this.disableUpload = true;
  }

  onselect() {
    this.immediateSupervisorList.forEach(element => {
      if (this.currectimmediateSupervisor.immediateSupervisor == element.immediateSupervisor) {
        this.immediateSupBD = element.imeediateSupervisorLevelName;
        this.Director = element.directorName;
        this.usermappingForm.value.immediateSupervisor = this.currectimmediateSupervisor.immediateSupervisor;
        this.usermappingForm.value.imeediateSupervisorLevelName = this.immediateSupBD;
        this.usermappingForm.value.directorName = this.Director;
      }
    });
  }

  setPageProperties(props: any): void {
    const pageData = {};
    const calendarControls = ["effectiveFrom"];
    Object.keys(props).forEach((key) => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
        this.toRefreshDate = this.effectiveDate = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    })
    this.usermappingForm.patchValue(pageData)

  }
  getdesignationList(): void {
    this.pagesService.getdesignationDatausermapping().subscribe(results => {
      const responseData = results.bdDesignationList;
      this.designationList = responseData;
    });
  }
  getshadowPortfolioEmployeeNameAndDesignationList(empid): void {
    this.pagesService.getshadowPortfolioEmployeeNameAndDesignationDatausermapping(empid).subscribe(results => {
      const responseData = results;
      this.shadowPortfolioEmployeeNameAndDesignationList = responseData;
    });
  }

  getOpsSpocList(): void {
    this.pagesService.getOpsSpocListDatausermapping().subscribe(results => {
      const responseData = results;
      this.OpsSpocList = responseData;
      this.sortInAscendingOrder(this.OpsSpocList);
    });
  }

  //OpSpocList
  getimmediateSupervisorList(bdDesignation): void {
    this.pagesService.getimmediateSupervisorDatausermapping(bdDesignation.id, bdDesignation.level_id, this.usermappingForm.value.employeeId).subscribe(results => {
      const responseData = results;
      this.immediateSupervisorList = responseData;
    });
    if (bdDesignation.bd_designation == "Director" || bdDesignation.bd_designation == "Senior Director") {
      this.tempDesigId = bdDesignation.id;
      this.disableUpload = false;
    } else {
      if ((bdDesignation != 6 && bdDesignation != 7) && this.signatureResult != null) {
        this.changeDesigAlert = true;
      } else if ((bdDesignation != 6 && bdDesignation != 7) && this.signatureResult == null) {
        this.disableUpload = true;
        this.signBtn = true;
      }
    }
    this.getemployeeNameAndDesignationList(bdDesignation.id, this.usermappingForm.value.employeeId);
  }



  getimeediateSupervisorLevelNameList(): void {
    this.pagesService.getimeediateSupervisorLevelNameDatausermapping().subscribe(results => {
      return;
    });
  }
  getteamList(): void {
    this.pagesService.getteamDatausermapping().subscribe(results => {
      const responseData = results.teamList;
      this.teamList = responseData;
    });
  }
  getemployeeNameAndDesignationList(levelid, employeeid): void {
    this.pagesService.getemployeeNameAndDesignationDatausermapping(levelid, employeeid).subscribe(results => {
      const responseData = results;
      this.employeeNameAndDesignationList = responseData;
    });
  }
  onCcprimebutton2($event: FormGroup): void {
    $event.reset();
  }
  onCcprimebutton($event: FormGroup): void {
    setTimeout(() => {
      this.ref.close();
    }, 50);
  }

  onCcprimebutton1($event: FormGroup): void {
    this.loaderFlag = true;
    $event.updateValueAndValidity();
    if (!$event.valid) {
      validateAllFormFields($event);
      this.loaderFlag = false;
      return;
    }
    const reqBody = {};
    reqBody['employeeDetails'] =
      [{
        "employeeId": this.usermappingForm.value.employeeId,
        "employeeName": this.usermappingForm.value.employeeName,
        "effectiveFrom": "01-Sep-2022",
        "level_name": this.usermappingForm.value.designation.level_name,
        "levelNo": this.usermappingForm.value.designation.level_no,
        "bdDesignationId": this.usermappingForm.value.designation.id,
        "bd_designation": this.usermappingForm.value.designation.bd_designation,
        "hrDesignation": this.usermappingForm.value.hrDesignation,
        "dateOfJoining": this.usermappingForm.value.dateOfJoining,
        "departmentId": this.usermappingForm.value.departmentId,
        "departmentName": this.usermappingForm.value.departmentName,
        "level": this.usermappingForm.value.designation.level_id,
        "immediateSupervisorEmpId": this.usermappingForm.value.immediateSupervisor.immediateSupervisorEmpId,
        "immediateSupervisor": this.usermappingForm.value.immediateSupervisor.immediateSupervisor,
        "immediateSupervisorBdDesignation": this.usermappingForm.value.immediateSupervisor.immediateSupervisorBdDesignation,
        "immediateSupervisorBdDesignationId": this.usermappingForm.value.immediateSupervisor.immediateSupervisorBdDesignationId,
        "immediateSupervisorLevelId": this.usermappingForm.value.immediateSupervisor.immediateSupervisorLevelId,
        "imeediateSupervisorLevelName": this.usermappingForm.value.immediateSupervisor.imeediateSupervisorLevelName,
        "directorName": this.usermappingForm.value.immediateSupervisor.directorName,
        "teamId": (this.usermappingForm.value.team == undefined) ? (null) : (this.usermappingForm.value.team.id),
        "teamName": (this.usermappingForm.value.team == undefined) ? (null) : (this.usermappingForm.value.team.value),
        "mobileNo": this.usermappingForm.value.mobileNo,
        "finalMapping": this.usermappingForm.value.immediateSupervisor.finalMapping,
        "userType": "N",
        "shadowPortfolioUsersList": this.usermappingForm.value.shadowPortfolioEmployeeNameAndDesignation,
        "opSpocUsersList": this.usermappingForm.value.OpsSpoc,
        "paEmployeeId": (this.usermappingForm.value.employeeNameAndDesignation == undefined) ? (null) : (this.usermappingForm.value.employeeNameAndDesignation.paEmployeeId),
        "paEmployeeNameAndDesignation": (this.usermappingForm.value.employeeNameAndDesignation == undefined) ? (null) : (this.usermappingForm.value.employeeNameAndDesignation.paEmployeeNameAndDesignation),
        "emailId": this.usermappingForm.value.emailId.trim(),
        "signature": (this.signatureResult != null || this.signatureResult != undefined) ? this.signatureResult.file : null,
        "fileName": (this.signatureResult != null || this.signatureResult != undefined) ? this.signatureResult.fileName : null,
        "createdBy": this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'))
      }];
    const formData = reqBody;
    const formValues = $event.value;
    this.pagesService.onccprimebutton1Submitusermapping(formData, formValues).subscribe(results => {
      this.onCcprimebutton($event);
      this.loaderFlag = false;
      if (results.status == "success") {
        this.messageService.add({ severity: 'success', detail: 'User Hierarchy Added successfully' });
      }
      else {
        this.messageService.add({ severity: 'error', detail: 'Something went wrong, Please try again!' });
      }

      window.scrollTo(0, 0);

      this.setData();
      this.router.navigate(["pages/userhierarchylist"]);

      setTimeout(() => {
        this.messageService.clear();
        this.timeOut = false;
      }, 4000);
      $event.reset();
    },
      (error) => {
        this.loaderFlag = false;
        this.messageService.clear();
        this.timeOut = true;
        this.onCcprimebutton($event);
        let stringObj = this.encryptDecryptInterceptor.decrypt(error.error.data);
        const obj = JSON.parse(stringObj);
        //  if(obj.message == undefined || obj.message != null)
        //   this.messageService.add({ severity: 'error', detail: obj.message });
        // else
        if (obj.message.includes("Not possible to update All 3 Levels Occupied for Employee ID")) {
          this.messageService.add({ severity: 'error', detail: obj.message });
        } else {
          this.messageService.add({ severity: 'error', detail: 'Something went wrong, Please try again!' });
        }


        window.scrollTo(0, 0);
        setTimeout(() => {
          this.messageService.clear();
          this.timeOut = false;
        }, 4000);
      });
    this.timeOut = true;
  }

  sortInAscendingOrder(data: any) {
    data.sort((a, b) => {
      if (a.employeeName.toLowerCase() < b.employeeName.toLowerCase())
        return -1;
      if (a.employeeName.toLowerCase() > b.employeeName.toLowerCase())
        return 1;
      return 0;
    })
  }

  uploadFile: any = {};
  previewFile: any = {};

  onSelectfileUpload1(event: any): void {
    this.currentFileEvent = event;
    if (this.currentFileEvent.files[0].size > 5000000) {
      this.messageService.add({ severity: 'error', summary: 'File size cannot exceed 5 MB.' });
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.timeOut = false;
        this.messageService.clear();
      }, 5000);
      return;
    }
    let type = this.currentFileEvent.files[0].type;
    if (type.includes('image/svg+xml') || type.includes('xlxs') || type.includes('xls') ||
      type.includes('html') || type.includes('xhtml') || type.includes('txt') || type.includes('gif') ||
      type.includes('zip') || type.includes('pdf') || type.includes('doc') || type.includes('docx')) {
      this.messageService.add({ severity: 'error', summary: ' Invalid File Format.' });
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.timeOut = false;
        this.messageService.clear();
      }, 5000);
      return;
    }
    this.imgChangeEvt = event.originalEvent;
    if (event.currentFiles[0].type == 'image/jpeg' || event.currentFiles[0].type == 'image/jpg' || event.currentFiles[0].type == 'image/png' || event.currentFiles[0].type == 'image/webp') {
      this.displayModal = true;
    } else {
      this.displayModal = false;
    }
    if (event.files[0].type.includes('image')) {
      this.imageChangedEventfileUpload1 = new Blob([event.files[0]], { type: event.files[0].type });
      this.filenameForfileUpload1 = event.files[0].name;
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(event.currentFiles[0]);
      fileReader.onload = () => {
        this.returnBase64 = fileReader.result;
        this.onCroppedUploadBtn();
      };
    }
  }

  dataURLtoFile(dataurl: any, filename: any): any {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  getUploadedObjDisplay(): any {
    let data = this.returnBase64;
    if (data.startsWith('data:application/pdf;base64,')) {
      data = data.replace('data:application/pdf;base64,', '');
    } else if (data.startsWith('data:image/png;base64,')) {
      data = data.replace('data:image/png;base64,', '');
    } else if (data.startsWith('data:application/doc;base64,')) {
      data = data.replace('data:application/doc;base64,', '');
    }
    return {
      fileName: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].name,
      file: data
    };
  }

  getUploadedObj(): any {
    return {
      fileName: this.currentFileEvent.name,
      file: this.returnBase64
    };

  }

  imageCroppedfileUpload1(event: any): any {
    // this.croppedImagefileUpload1 = event.base64;
    // this.returnBase64 = event.base64; //Added

    fetch(event.objectUrl)
       .then(response => response.blob())
       .then(blob => {
           const reader = new FileReader();
           reader.onloadend = () => {
               this.returnBase64 = reader.result;
               this.croppedImagefileUpload1 = this.returnBase64;
           };
           reader.readAsDataURL(blob);
    });
  }

  onCroppedUploadBtn(): void {
    if (this.dataURLtoFile && this.croppedImagefileUpload1) {
      const file = this.dataURLtoFile(this.croppedImagefileUpload1, this.filenameForfileUpload1);
      this.fileUploadfileUpload1.files.push(file);
    }
    this.uploadFile = {};
    if (this.displayModal) {
      const displayData = this.getUploadedObjDisplay();
      this.signatureResult = displayData;
      this.uploadFile = displayData;
    } else {
      this.returnUploaded = this.getUploadedObj();
      this.signatureResult.push(this.returnUploaded);
      this.uploadFile = this.returnUploaded;
    }
    this.displayModal = false;
    this.signBtn = false;
    this.showSmallSignPreview();
  }

  showSignaturePreview() {
    this.displayPreview = true;
    let file = this.signatureResult.file;
    let blob = this.b64toBlob(file, 'image/png');
    const blobUrl = URL.createObjectURL(blob);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
  }

  showSmallSignPreview() {
    let file = this.signatureResult.file;
    let blob = this.b64toBlob(file, 'image/png');
    const blobUrl = URL.createObjectURL(blob);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  onDeletePopup() {
    this.deletePopUp = true;
  }

  onDeleteSignature() {
    this.deletePopUp = false;
    this.changeDesigAlert = false;
    this.signatureResult = null;
    if (this.usermappingForm.value.designation.id == 6 || this.usermappingForm.value.designation.id == 7) {
      this.disableUpload = false;
      this.signBtn = true;
    } else {
      this.signBtn = true;
      this.disableUpload = true;
    }
  }

  onSelectNo() {
    let directorName;
    this.changeDesigAlert = false;
    if (this.tempDesigId == 6) {
      directorName = { designation: this.designationList.find(data => data.id == '6') };
    }
    if (this.tempDesigId == 7) {
      directorName = { designation: this.designationList.find(data => data.id == '7') };
    }
    this.usermappingForm.patchValue(directorName);
  }

  onCancelCrop() {
    this.displayModal = false;
    this.filenameForfileUpload1 = '';
  }

}
