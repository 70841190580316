import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { Table } from 'primeng/table/table';
import { OfflineBillsService } from '../offline-bills.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'backoffice-offline-bills-grid',
  templateUrl: './offline-bills-grid.component.html',
  styleUrls: ['./offline-bills-grid.component.scss']
})
export class OfflineBillsGridComponent implements OnInit {

  breadcrumbList = [
    { label: `Offline Bills`, routerLink: [] },
  ];

  homeicon = { icon: `pi pi-home` };

  searchCompanyPopUp: boolean = false;
  filterDisplay: boolean = true;
  loadingStatus: boolean = false; 
  apiFail: boolean = false; 
  showFailureModel: boolean = false; 
  filterDisplayRow: boolean = true;
  emptyMsgFlag: boolean = false;
  showEntityLoader: boolean = false;
  gridLoader: boolean = false;

  companyNameSearchValue : any;
  globalStartDate: any;
  globalEndDate: any;

  emptyMsgVal: string = 'No Company Found';

  statusList: any[] = [{name:"Pending"},{name:"Approved"},{name:"Rejected"}];

  gridDataList = [];
  companyNameList = [];

  minGlobalStartTime = new Date();
  maxGlobalEndTime = new Date();

  billStatus:any;

  billForm = this.fb.group({
    bdManagerFilter:[null,[]],
    costCenterFilter:[null,[]],
    billTypeFilter:[null,[]],
    statusFilter:[null as any,[]]
  });

  bdManagerList:any;
  costCenterList:any;
  billTypeList:any;

  @ViewChild('globalCalendar') globalCalendar: any;

  // grid related variables - start
  @ViewChildren('mainGrid') mainGrid;
  
  constructor(private fb: FormBuilder,private cd: ChangeDetectorRef,private billService: OfflineBillsService,private router: Router) { }

  ngOnInit(): void {
    this.billStatus = "Pending";
    let billStatusFilter = {name:"Pending"};
    this.billForm.patchValue({statusFilter:"Pending"});
    this.getGridDetails(this.billStatus);
  }

  datePicker(clear?){

  }

  loadCompanyList(event) {

  }

  showSearchCompanyPopUp() {
    console.log(this.gridLoader, this.loadingStatus, 'ssss')
    if(!this.gridLoader && !this.loadingStatus){
      this.searchCompanyPopUp = true;
    }
  }

  clearFilter(table : Table) {
    table.reset();
    this.cd.detectChanges();
    
    
    if(this.mainGrid?.first?.filters) { // reset all filters
      Object.values(this.mainGrid.first.filters).map(item => {
        (<any>item).value = null;
        return item;
      });
    }

    this.billForm.controls.bdManagerFilter.reset();
    this.billForm.controls.costCenterFilter.reset();
    this.billForm.controls.billTypeFilter.reset();

    this.filterDisplayRow = false;
    this.filterDisplay = false;
  }

  resetCompanySearch(){

  }

  applyCompanySearch(){

  }

  onClearGlobalDate(){

  }

  filterGlobalDate(){

  }

  showFilter(){
    this.filterDisplayRow = true;
    this.filterDisplay = true;
    this.cd.detectChanges();
  }

  backBtn(){
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  } 

  homeClicked(event) {
    if(event.item?.icon == 'pi pi-home'){
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  statusChange(event){
    console.log("Statussss ",event);
    this.billStatus = event;
    this.getGridDetails(event);
  }

  getGridDetails(status:any){
    let payload = {"status":status};
    this.billService.getOfflineBillDetails(payload).subscribe((res)=>{
      this.setGridDetails(res);
    });
  }

  setGridDetails(gridData){
    this.gridDataList = <[]>gridData;
    this.bdManagerList = new Set();
    this.costCenterList = new Set();
    this.billTypeList = new Set();

    this.gridDataList.forEach(item=>{
      if(item.bdManager){
        this.bdManagerList.add(item.bdManager);
        item.bdManagerList = {name:item.bdManager};
      }

      if(item.costCenter){
        this.costCenterList.add(item.costCenter);
        item.costCenterList = {name:item.costCenter};
      }

      if(item.billType){
        this.billTypeList.add(item.billType);
        item.billTypeList = {name:item.billType};
      }

      item.billingDate = this.formatDate(item.billingDate);
    });

    this.bdManagerList = Array.from(this.bdManagerList).map(item => ({name: item}));
    this.costCenterList = Array.from(this.costCenterList).map(item => ({name: item}));
    this.billTypeList = Array.from(this.billTypeList).map(item => ({name: item}));
  }

  formatDate(billingDate: string){
    return billingDate.split("-")?.reverse()?.join("-");
  }

  openBillform(rowData:any){
    this.router.navigate(['/offline-bills/offlinebillform', rowData?.offlineBillId]);
  }
}
