import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { PagesService } from '../../pages/pages.service';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { AttachmentModelComponent } from '../../pages/attachment-model/attachment-model.component';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as moment from 'moment';


@Component({
  selector: 'backoffice-alternate-bill-details-grid',
  templateUrl: './alternate-bill-details-grid.component.html',
  styleUrls: ['./alternate-bill-details-grid.component.scss'],
  providers: [DialogService, MessageService]
})
export class AlternateBillDetailsGridComponent implements OnInit {

  // grid related variables 
  // --start
  @ViewChildren('mainGrid') mainGrid;
  // --end
  
  userId: string;
  // breadcrumb variables - start
  ccprimebreadcrumbList = [
    { label: `Approval Alternate Cases`, routerLink: ['/initiation/alternate-bill-details'] },
  ];
  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  // breadcrumb variables - end

  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;

  alternateKeyDetailsGridForm = this.fb.group({
    entityNameFilter: [null, []],
    nexeraUserFilter: [null, []],
    bdSpocFilter: [null, []],
    opportunityFilter: ['Initiated', []],
    dateFilter: [null, []],
    ageingFilter: [null, []],
    gridCalender: [null, []],
  });
  // grid related variables - end

  gridDataList = [];

  // golbal calendar variables
  rangeDate: any;
  dateTime = new Date();
  filterDisplay = true;
  filterDisplayRow = true;
  globalStartDate: any;
  globalEndDate: any;
  minGlobalStartTime = new Date();
  maxGlobalEndTime = new Date();
  @ViewChild('globalCalendar') globalCalendar: any;

  opportunityStatusCount = 0;

  // loader variables
  firstLoad = false;
  apiFail = false;
  gridLoader = false;

  //grid dropdown filter 
  oppIdList: any;
  agreementIdList: any;
  nameList: any;
  nexeraUserList: any;
  uniqbdSpocList: any;
  opportunityStatusList: any = [{ label: 'Initiated', value: 'Initiated' }];

  // search company popup -
  // auto complete company name related variables
  searchCompanyPopUp = false;
  showCross: boolean = false;
  showEntityLoader: boolean = false;
  emptyMsgVal: string = 'No Company Found';
  emptyMsgFlag: boolean = false;
  companyNameList = [];
  companyNameSearchValue: any;

  // detailed view (modal) related variables
  companyHeader: string;
  displayDetailedModal = false;
  detailedViewForm = this.fb.group({
    title: [null, []],
    comment: [null, [Validators.required]],
    realizationStatus: [null, []],
    revenueDate: [null as any, []],
    action: [null as any, [Validators.required]],
    attachment: [false, [Validators.required]],
    opportunityId: [null],
    opportunityStatus: [null],
    nexraUser:[null]
  })
  isEditForm = true;
  detailedViewObj: any;

  // attachment data
  dynamicDialogdialogRef: DynamicDialogRef = new DynamicDialogRef();
  fileItem: any;
  displayPreview = false;
  previewLoader = false;
  isPdf: boolean;
  urlSafe: string;
  previewFailed: boolean;
  attachmentType = [];
  caseClosePopUp: boolean;
  alternateReadScope: boolean;


  constructor(private renderer: Renderer2, private router: Router, private _location: Location, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private pagesService: PagesService, private dialogService: DialogService, private messageService: MessageService, private encyDyc: EncryptDecryptInterceptor, private cd: ChangeDetectorRef, private encryptDecryptInterceptor: EncryptDecryptInterceptor) {
    this.minGlobalStartTime.setMonth(this.maxGlobalEndTime.getMonth() - 36);
    this.userId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));

  }

  ngOnInit(): void {
    let payload = {
      startDate: null,
      endDate: null
    };

    let scope = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    if(scope.indexOf('ALTERNATE_BILLING_CREATE') == -1){
      this.alternateReadScope = true;
      this.isEditForm = false;
    } else {
      this.alternateReadScope = false;
    }

    // get attachment dropdown
    this.pagesService.getDocList().subscribe({
      next: item => {
        if (item?.length > 0) {
          this.attachmentType = item.filter(i => i.id === 48);
        }
      },
      error: err => {
        this.messageService.add({ severity: 'error', detail: 'Error while fetching data, try again! ' });
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
      }
    })
    this.getAlternateBillingListingData(payload);
  }


  // breadrumb related method
  homeBreadcrumbClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  // global calendar related methods - END

  // clear filter
  showFilter() {
    this.filterDisplayRow = true;
    this.filterDisplay = true;
    this.cd.detectChanges();
  }

  clear(table) {
    table.reset();
    this.cd.detectChanges();
    if(this.mainGrid?.first?.filters) { // reset all filters
      Object.values(this.mainGrid.first.filters).map(item => {
        (<any>item).value = null;
        return item;
      });
    }
    this.filterDisplayRow = false;
    this.filterDisplay = false;
    this.alternateKeyDetailsGridForm.get('opportunityFilter').setValue('Business Reported');
    this.alternateKeyDetailsGridForm.get('gridCalender').reset();
    let payload = {
      startDate: null,
      endDate: null
    };
    this.companyNameSearchValue = undefined;
    this.globalStartDate = undefined;
    this.globalEndDate = undefined;
    this.getAlternateBillingListingData(payload);
    this.cd.detectChanges();
  }

  onOpportunityClick(rowData) { }

  onInitiationBackBtn() {
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  getAlternateBillingListingData(payload) {
    this.gridLoader = true;
    this.pagesService.findAllAlternateBillingData(payload).subscribe({
      next: item => {
        this.setGridItem(item);
      },
      error: err => {
        console.error("Error while fetchin IRF initiation grid data :: ", err);
        this.gridLoader = false;
        this.messageService.add({ severity: 'error', detail: 'Error while fetching data, try again! ' });
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
      }
    }
    )
  }
  private setGridItem(item: Object) {
    this.gridDataList = <[]>item;
    this.oppIdList = new Set();
    this.agreementIdList = new Set();
    this.nameList = new Set();
    this.nexeraUserList = new Set();
    this.uniqbdSpocList = new Set();

    this.gridDataList.forEach(item => {
      this.oppIdList.add(item.opportunityId);
      item.oppIdByList = { name: item.opportunityId };

      this.agreementIdList.add(item.agreementId);
      item.aggIdByList = { name: item.agreementId };

      this.nameList.add(item.name);
      item.entityNamebyList = { name: item.name };

      this.nexeraUserList.add(item.nexeraUser);
      item.nexeraUserbyList = { name: item.nexeraUser };

      this.uniqbdSpocList.add(item.bdSpoc);
      item.bdSpocbyList = { name: item.bdSpoc };

      const reportedDate = this.formatDate(item?.reportedDate);
      item.reportedDate = reportedDate;
    });

    this.oppIdList = Array.from(this.oppIdList).map(item => ({ name: item }));
    this.agreementIdList = Array.from(this.agreementIdList).map(item => ({ name: item }));
    this.nameList = Array.from(this.nameList).map(item => ({ name: item }));
    this.nexeraUserList = Array.from(this.nexeraUserList).map(item => ({ name: item }));
    this.uniqbdSpocList = Array.from(this.uniqbdSpocList).map(item => ({ name: item }));

    this.gridLoader = false;
    this.firstLoad = true;
  }

  formatDate(reportedDate: any) {
    return reportedDate?.split("T")[0]?.split("-").reverse().join("-");
  }

  // getOpprByStatus(opportunityStatus: string) {
  //   let payload = {
  //     startDate : this.globalStartDate,
  //     endDate : this.globalEndDate,
  //     companyCode : this.companyNameSearchValue?.companyCode
  //   };
  //   this.getAlternateBillingListingData(payload); 
  // }

  getAlternateKeyDetails(initiationDetails: any) {
    console.log("Row clicked :: ", initiationDetails);
    this.gridLoader = true;
    this.detailedViewForm.patchValue({
      title: initiationDetails?.companyName,
      nexraUser:initiationDetails?.nexeraUser
    });
    this.fileItem = undefined;
    if(!this.alternateReadScope)
      this.isEditForm = true;

    this.pagesService.findByIdAlternateBillingData({ opportunityId: initiationDetails?.opportunityId }).subscribe({
      next: (item: any) => {
        console.log(item?.Response);
        if (item?.Response === "Case Close" && this.isEditForm) { // need id
          this.caseClosePopUp = true;
        }
        else if (item?.Response) {

          if (typeof item?.Response == "string") {
            this.isEditForm = false;
            this.messageService.add({ severity: 'summary', detail: item?.Response });
            setTimeout(() => {
              this.messageService.clear();
            }, 3000);
            return;
          }

          this.detailedViewObj = { ...item?.Response?.irfAlternateBillDetailsVwList };
          if (this.isEditForm)
            this.isEditForm = !!(this.detailedViewObj?.revenueStatus !== "Approved");
          if (this.isEditForm) {
            this.detailedViewForm.controls['comment'].enable();
          } else
            this.detailedViewForm.controls['comment'].disable();

          const dateString = this.formatDate(this.detailedViewObj?.revenueDate);
          this.detailedViewForm.patchValue({
            revenueDate: this.detailedViewObj?.revenueDate ? new Date(
              parseInt(dateString.split('-')[2]), // Year
              parseInt(dateString.split('-')[1]) - 1, // Month (subtract 1 as months are 0-based)
              parseInt(dateString.split('-')[0]) // Day
            ) : null,
            comment: this.detailedViewObj?.revenueComments,
            realizationStatus: this.detailedViewObj?.realizationStatus,
            action: !!(this.detailedViewObj?.revenueStatus === "Approved"),
            opportunityId: initiationDetails?.opportunityId,
            opportunityStatus: initiationDetails?.opportunityStatus
          });

          if (item?.Response.fileName) {
            this.fileItem = {
              fileContentType: item?.Response.fileType,
              fileName: item?.Response.fileName,
              fileTypeName: "Alternate Billing",
              attachmentId: this.detailedViewObj?.attachmentId
            }
          }
          this.gridLoader = false;
          this.displayDetailedModal = true;
        }
      },
      error: err => {
        this.gridLoader = false;
        this.messageService.add({ severity: 'error', detail: 'Error while fetching data, try again! ' });
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
      }
    });
  }

  showAttachmentModal() {
    this.dynamicDialogdialogRef = this.dialogService.open(AttachmentModelComponent, {
      contentStyle: { 'max-height': '550px', 'overflow': 'visible' },
      header: 'Upload Attachment',
      closable: true,
      data: { list: this.attachmentType, type : '.msg' },
      styleClass: 'sm',
    });
    this.dynamicDialogdialogRef.onClose.subscribe(response => {
      console.log("res ::", response);
      if (response?.length > 0) {
        this.fileItem = response[0];
        this.detailedViewForm.patchValue({ attachment: true });
      }
    });
  }

  showSearchCompanyPopUp() {
    if (!this.gridLoader)
      this.searchCompanyPopUp = true;
  }

  searchCompanyPopupClosed() {
    this.searchCompanyPopUp = false;
  }

  onClearSearch() {

  }

  onChangeCompanySelect(event) {
    console.log("Event :: ", event);

  }

  applyCompanySearch() {
    let payload = {
      startDate: this.globalStartDate,
      endDate: this.globalEndDate,
      companyCode: this.companyNameSearchValue?.companyCode
    };
    this.getAlternateBillingListingData(payload);
    this.searchCompanyPopUp = false;
    this.caseClosePopUp = false;
  }

  loadCompanyList(event : any) {
    if (event?.query?.length >= 3) {
      this.showEntityLoader = true;
      this.pagesService.getCompanySearchResultsAlternateBilling(event.query).subscribe(
        (results: any) => {
          const responseData = results;
          this.showEntityLoader = false;
          this.companyNameList = [];
          if (responseData && responseData.length > 0) {
            this.showCross = true;
            this.companyNameList = responseData.map(
              item => ({ companyCode: item.companyCode, companyName: item.companyName }));
          } else {
            this.showCross = false;
            this.companyNameList = [];
            this.emptyMsgFlag = true;
          }
        }, (_err) => {
          this.companyNameList = []
          this.showEntityLoader = false;
          this.emptyMsgFlag = true;
          this.showCross = false;
        });
    } else {
      this.companyNameList = [];
      this.showCross = false;
    }
  }

  reset() {
    let payload = {
      startDate: this.globalStartDate,
      endDate: this.globalEndDate
    };
    this.getAlternateBillingListingData(payload);
    this.companyNameSearchValue = undefined;
    this.searchCompanyPopUp = false;
  }

  onDeleteFile() {
    this.fileItem = undefined;
    this.detailedViewForm.patchValue({ attachment: undefined });
  }

  detailedViewClose() {
    this.displayDetailedModal = false;
  }
  
  onPreviewScreen() {
    console.log("preview click working");
    let previewDtata = {
      "opportunityId": this.detailedViewForm.get('opportunityId').value,
      "opportunityStatus": this.detailedViewForm.get('opportunityStatus').value,
      "businessAction":this.detailedViewForm.get('opportunityStatus').value,
      "initiationDetails":this.detailedViewForm?.value?.nexraUser,
    }

    const encPreviewData = this.encryptDecryptInterceptor.encryptLocalObject(previewDtata);
    console.log("Enc Preview: ", encPreviewData);
    localStorage.setItem('previewData', encPreviewData);
    let previewUrl = DOMAIN_ROUTE + "#/backoffice/pages/preview";
    const newWindow = window.open(previewUrl, '_blank');
  }

  saveDetails() {
    if (!this.detailedViewForm.invalid) {
      this.gridLoader = true;
      this.isEditForm = false;
      const payload = {
        opportunityId: this.detailedViewObj?.opportunityId,
        agreementId: this.detailedViewObj?.agreementId,
        revenueDate: this.detailedViewForm.get("revenueDate").value ? this.detailedViewForm.get("revenueDate").value : moment().format('YYYY-MM-DD'),
        revenueComments: this.detailedViewForm.get("comment").value,
        revenueStatus: this.detailedViewForm.get("action").value ? "Approved" : null,
        userId: this.userId,
        fileData: [
          {
            userId: this.userId,
            fileSize: this.fileItem?.fileSize,
            fileName: this.fileItem?.fileName,
            file: this.fileItem?.file?.split('base64,')[1],
            fileContentType: this.fileItem?.fileContentType,
            fileTypeName: "Client Type Approval",
            fileId: this.fileItem?.fileId,  // ask
            fileTypeid: 48, // ask
            moduleName: "ClientType",
            moduleId: this.detailedViewObj?.opportunityId
          }
        ]
      };
      this.pagesService.saveAlternateBillingDetail(payload).pipe(switchMap((res: any) => {
        const responseStr = res?.Response;
        if (typeof responseStr === "string" && responseStr !== 'Revenue booking saved successfully..') {
          this.isEditForm = true;
          this.messageService.clear();
          this.messageService.add({  key : 'inside-modal', severity: 'warn', detail: responseStr });
          setTimeout(() => {
            this.messageService.clear();
          }, 6000);
          this.gridLoader = false;
          return of(undefined);
        } else {
          this.messageService.add({ severity: 'success', detail: 'Saved successfully! ' });
          setTimeout(() => {
            this.messageService.clear();
          }, 3000);
        }
        this.displayDetailedModal = false;
        this.gridLoader = false;
        let payload = {
          companyCode: this.companyNameSearchValue?.companyCode
        };
        return this.pagesService.findAllAlternateBillingData(payload);
      })).subscribe({
        next: (res: any) =>{ 
          if(res)
            this.setGridItem(res);
        },
        error: err => {
          this.messageService.add({ severity: 'error', detail: 'Error while saving data, try again! ' });
          setTimeout(() => {
            this.messageService.clear();
          }, 3000);
          this.displayDetailedModal = false;
          this.gridLoader = false;
        }
      });
    }
  }

  previewAttachment() {
    let ext = this.fileItem.fileName?.split('.')?.slice(-1)[0]; // get last element
    if (ext.includes('msg')) {
      return;
    }

    if (this.fileItem?.attachmentId) {
      this.displayPreview = true;
      this.previewLoader = true;
      let payload = {
        id: this.fileItem?.attachmentId
      }
      this.pagesService.findPDF(payload).subscribe((res) => {
        if (res && res?.file) {
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.urlSafe = `${mime}${res.file}`
          this.previewLoader = false;
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      });
    }
  }

  downloadFile() {
    let linkSource: any;
    if (this.fileItem?.fileContentType?.includes('image')) {
      let mime = 'data:image/png;base64';
      linkSource = `${mime},${this.urlSafe?.split(',')[1]}`;
    } else {
      linkSource = this.urlSafe;
    }
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this?.fileItem?.fileName;
    downloadLink.click();
  }

}