import { Component, OnDestroy, OnInit } from '@angular/core';
import { IRFRealizationAPIService } from '../irf-realization-api.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, Validators } from '@angular/forms';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { MessageService } from 'primeng/api';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'irf-realization-details',
  templateUrl: './irf-realization-details.component.html',
  styleUrls: ['./irf-realization-details.component.scss'],
  providers: [MessageService]
})
export class IRFRealizationDetailsComponent implements OnInit, OnDestroy {

  constructor(private realizationService: IRFRealizationAPIService, public config: DynamicDialogConfig, private fb: FormBuilder, private encDec: EncryptDecryptInterceptor, private ref: DynamicDialogRef, private messageService: MessageService) { }

  statusList: any = [];
  hierarchyList: any = [];
  attachmentList: any = [];
  apiCallList: any = [];
  scopeList: any = [];
  gridData: any;
  paymentData: any;
  approverName: any;
  otaData: any;
  empId: any;
  otaId: any;

  showOtpField: boolean = false;
  showRejectReason: boolean = false;
  showReasonChange: boolean = false;
  showComment: boolean = false;
  approverPopup: boolean = false;
  disableClearingDate: boolean = true;
  disableSendOta: boolean = true;
  disableSave: boolean = true;
  disableAll: boolean = false;
  showOtaBtn: boolean = true;
  apiFail: boolean = false;
  realizationReadScope: boolean = false;

  maxGlobalEndTime = new Date();
  minGlobalStartTime = new Date();

  openedFile: any = {}
  previewLoader: boolean = false;
  displayPreview: boolean = false;
  isPdf: boolean = false;
  previewFailed: boolean = false;
  zoomValue: number = 0;
  rotate: number = 0;
  urlSafe: any;
  canvasRotation: number = 0;
  transform: ImageTransform = {};
  

  realizationForm = this.fb.group({
    companyCode: [null, []],
    paymentMode: [null, []],
    paymentId: [null, []],
    neftChequeNo: [null, []],
    createdDate: [null as any, []],
    paymentDate: [null as any, []],
    bankName: [null, []],
    amount: [null, []],
    grossAmount: [null, []],
    paymentStatus: [null, []],
    clearingDate: [null as any, []],
    comment: [null, []],
    opsComment: [null, []],
    reasonChange: [null as any, []],
    rejectedReason: [null as any, [Validators.minLength(3), Validators.maxLength(300)]],
    ota: [null as any, []],
  });

  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;

  ngOnInit(): void {
    this.gridData = this.config?.data;
    this.scopeList = this.encDec.decrypt(localStorage.getItem('scopes'));
    let scope: any = this.scopeList.split(",");
    if(scope.indexOf('SF_REALIZATION_CREATE') == -1){
      this.realizationReadScope = true;
    } else {
      this.realizationReadScope = false;
    }
    this.empId = this.encDec.decrypt(localStorage.getItem('empid'));
    this.minGlobalStartTime.setMonth(this.maxGlobalEndTime.getMonth() - 36);
    this.apiCallList.push(this.realizationService.payStatusList().subscribe((res) => {
      this.statusList = res;
    }));
    this.getUserHierarchys();
    this.getPayDetails();
  }


  getPayDetails() {
    let payload = {
      paymentId: this.gridData?.paymentId,
      agreementId: this.gridData?.agreementId
    }
    this.apiCallList.push(this.realizationService.payDetails(payload).subscribe((res) => {
      if (res) {
        this.paymentData = res;
        this.realizationForm.patchValue({
          companyCode: res?.companyCode,
          paymentMode: res?.paymentMode,
          paymentId: res?.paymentId,
          neftChequeNo: res?.neftChequeNo,
          createdDate: res?.paymentCreateDate ? new Date(res?.paymentCreateDate) : null,
          paymentDate: res?.paymentDate ? new Date(res?.paymentDate) : null,
          bankName: res?.bankName,
          amount: res?.paymentAmount,
          grossAmount: res?.grossAmount,
          paymentStatus: res?.paymentStatusId,
          clearingDate: res?.clearingDate ? new Date(res?.clearingDate) : null,
          opsComment: res?.opsComment,
          rejectedReason: res?.rejectedReason,
          comment: res?.financeComments
        });

        if (res?.paymentStatusId == 2) {
          this.disableClearingDate = false;
        }
        if(res?.paymentStatusId == 4){
          //rejected
          this.showRejectReason = true;
        }

        this.otaData = res?.otaDetails;
        if (this.otaData) {
          this.disableSave = false;
          this.disableAll = true;
          this.showOtpField = true;
          this.showOtaBtn = false;
          this.realizationForm.get('ota').addValidators([Validators.required]);
          this.realizationForm.get('ota').updateValueAndValidity();
          if (this.otaData?.reasonForChange) {
            this.showReasonChange = true;
          }

          if(this.otaData?.unapprovedRejectedReason){
            this.showRejectReason = true;
          }

          if(this.otaData?.unapprovedFinanceComment){
            this.showComment = true;
          }
          this.realizationForm.patchValue({
            clearingDate: this.otaData?.newClearingDate ? new Date(this.otaData?.newClearingDate) : null,
            paymentStatus: this.otaData?.newPaymentStatusId,
            reasonChange: this.otaData?.reasonForChange,
            rejectedReason: this.otaData?.unapprovedRejectedReason,
            comment: this.otaData?.unapprovedFinanceComment
          });
        }

        this.getAttachments(res?.opportunityId);
      }
    }, (_err)=> {
      this.apiFail = true;
    }));
  }

  statusChanged() {
    //pending - 1
    //realized - 2
    //bounced - 3
    //rejected - 4
    this.showComment = true;

    if (this.realizationForm.value.paymentStatus == 4) {
      this.showRejectReason = true;
      this.realizationForm.get('rejectedReason').addValidators([Validators.required, Validators.minLength(3)]);
    } else {
      this.showRejectReason = false;
      this.realizationForm.get('rejectedReason').clearValidators();
    }

    if (this.realizationForm.value.paymentStatus == 2) {
      this.disableClearingDate = false;
      this.realizationForm.get('clearingDate').addValidators([Validators.required]);
    } else {
      this.disableClearingDate = true;
      this.realizationForm.get('clearingDate').reset();
      this.realizationForm.get('clearingDate').clearValidators();
    }

    let payId = this.paymentData?.paymentStatusId;
    let formPayId = this.realizationForm.value.paymentStatus;
    // need approval code logic
    if ((payId == 2 || payId == 3 || payId == 4) && (payId != formPayId)) {
      this.showReasonChange = true;
      this.disableSendOta = false;
      this.realizationForm.get('reasonChange').addValidators([Validators.required, Validators.minLength(3)]);
    } else {
      this.showReasonChange = false;
      this.disableSendOta = true;
      this.realizationForm.get('reasonChange').clearValidators();
    }

    if (payId == 1 && (payId != formPayId)) {
      this.disableSave = false;
    } else {
      this.disableSave = true;
    }

    this.realizationForm.get('reasonChange').updateValueAndValidity();
    this.realizationForm.get('rejectedReason').updateValueAndValidity();
    this.realizationForm.get('clearingDate').updateValueAndValidity();
  }

  approverPop() {
    this.realizationForm.get('reasonChange').markAsDirty();
    this.realizationForm.get('rejectedReason').markAsDirty();
    this.realizationForm.get('clearingDate').markAsDirty();
    if (this.realizationForm.invalid) {
      return;
    }
    this.approverPopup = true;
  }

  reasonChangeEntered() {
    if (this.realizationForm.value.reasonChange?.length >= 3) {
      this.disableSendOta = false;
    } else {
      this.disableSendOta = true;
    }
  }

  sendOta() {
    let payload = {
      opportunityPaymentId: this.paymentData?.paymentId,
      paymentStatusId: this.realizationForm.value?.paymentStatus,
      oldClearingDate: null,
      newClearingDate: this.realizationForm.value?.clearingDate,
      createdBy: this.empId,
      approvedBy: this.approverName,
      reason: null,
      rejectedReason: this.realizationForm.value?.rejectedReason,
      approverEmployeeId: this.approverName,
      finComment: this.realizationForm.value?.comment,
      agreementId: this.gridData?.agreementId,
      reasonForChange: this.realizationForm.value?.reasonChange
    }
    this.approverPopup = false;
    this.apiCallList.push(this.realizationService.sendOta(payload).subscribe((res) => {
      if(res?.responseMsg?.toLowerCase() == "payment already added"){
        this.messageService.add({ severity: 'error', detail: res?.responseMsg });
        this.showOtaBtn = true;
        this.disableSendOta = true;
        this.disableSave = true;
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
        return;
      }
      this.messageService.add({ severity: 'success', detail: "OTA Sent Successfully!" });
      this.showOtpField = true;
      this.disableSave = false;
      this.disableAll = true;
      this.otaId = res?.responseMsg;
      this.showOtaBtn = false;
      this.realizationForm.get('ota').addValidators([Validators.required]);
      this.realizationForm.get('ota').updateValueAndValidity();
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    }, (_err) => {
      this.messageService.add({ severity: 'error', detail: "Something went wrong, please try again!" });
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    }));
  }

  cancelOta() {
    let payload = {
      paymentId: this.paymentData?.paymentId,
      agreementId: this.gridData?.agreementId,
      otaId: this.otaData?.otaId || this.otaId
    }

    this.apiCallList.push(this.realizationService.cancelOta(payload).subscribe((res) => {
      if(res?.responseMsg == "OTA Cancelled Successfully"){
        this.disableAll = false;
        this.showOtaBtn = true;
        this.showOtpField = false;
        this.disableSave = true;
        this.showComment = false;
        if(this.realizationForm.value?.paymentStatus == 4){
          //rejected
          this.showRejectReason = true;
        }
        this.realizationForm.get('ota').clearValidators();
        this.realizationForm.get('ota').updateValueAndValidity();
        this.messageService.add({ severity: 'success', detail: "OTA cancelled successfully"});
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
      }
    },(_err) => {
      this.messageService.add({ severity: 'error', detail: "Something went wrong, please try again!"});
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    }));
  }

  validateOta() {
    if (this.realizationForm.value?.ota?.length != 6 && this.showOtpField) {
      this.realizationForm.get('ota').markAsDirty();
      return;
    }
    if(this.realizationForm.value?.paymentStatus == 2 && (this.realizationForm.value?.clearingDate == null || this.realizationForm.value?.clearingDate == undefined)){
      this.realizationForm.get('clearingDate').markAsDirty();
      return; 
    }
    if(this.realizationForm.value?.paymentStatus == 4 && (this.realizationForm.value?.rejectedReason == null || this.realizationForm.value?.rejectedReason == undefined || this.realizationForm.value?.rejectedReason.length < 3)){
      this.realizationForm.get('rejectedReason').markAsDirty();
      return;
    }
    if(this.realizationForm.value?.paymentStatus != 4){
      // not rejected
      this.realizationForm.value.rejectedReason = null;
    }

    let payload = {
      opportunityPaymentId: this.paymentData?.paymentId,
      paymentStatusId: this.realizationForm.value?.paymentStatus,
      oldClearingDate: this.paymentData?.oldClearingDate,
      newClearingDate: this.realizationForm.value?.clearingDate,
      createdBy: this.empId,
      approvedBy: this.approverName,
      reason: this.realizationForm.value?.reasonChange,
      rejectedReason: this.realizationForm.value?.rejectedReason,
      approverEmployeeId: this.approverName,
      finComment: this.realizationForm.value?.comment,
      agreementId: this.gridData?.agreementId,
      reasonForChange: this.realizationForm.value?.reasonChange,
      id: this.otaData?.otaId || this.otaId, //OTA id
      otpSent: this.realizationForm.value?.ota // OTA entered by the user
    }
    
    this.apiCallList.push(this.realizationService.validateOta(payload).subscribe((res) => {
      if (res?.responseMsg == "OTA Validated SuccessFully") {
        this.ref.close(res?.responseMsg);
      }
      if (res?.responseMsg == "Not A Match") {
        this.messageService.add({ severity: 'error', detail: "Invalid Ota" });
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
      }
    }, (_err) => {
      this.messageService.add({ severity: 'error', detail: "Something went wrong, please try again!"});
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    }));

  }

  cancelApproverPop() {
    this.approverPopup = false;
  }

  closePopup() {
    this.ref.close();
  }

  getUserHierarchys() {
    this.apiCallList.push(this.realizationService.getUserHierarchy(this.empId).subscribe((res: any) => {
      let data = JSON.parse(res.upwardHierarchyUsers);
      let list = data?.uppwardhierarchy.splice(1);
      this.hierarchyList = list.filter((l) => {
        if (!l.levelName?.toLowerCase()?.startsWith('bdo') && !l.levelName?.toLowerCase()?.startsWith('sd')) {
          return l;
        }
      });
    }));
  }

  getAttachments(oppId) {
    this.apiCallList.push(this.realizationService.getAttachmentsByOpportunityIdAndModule(oppId, "Opportunity").subscribe((res) => {
      this.attachmentList = res;
    }));
  }

  showAttachmentPreview(item) {
    this.openedFile = item;
    this.zoomValue = 1;
    this.rotate = 0;

    if (item?.fileId) {
      this.displayPreview = true;
      this.previewLoader = true;
      let payload = {
        id: item.fileId
      }
      this.apiCallList.push(this.realizationService.findPDF(payload).subscribe((res) => {
        if (res?.file) {
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          let isPreviewable = true;
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else if ( "msg" === ext1?.toLowerCase()) {
            mime = 'data:application/vnd.ms-outlook;base64,';
            isPreviewable = false;
          } else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.urlSafe = `${mime}${res.file}`
          if (!this.isPdf) {
            let el = document.getElementById('imagePrev');
            setTimeout(() => {
              el.style.transform = 'rotate(0deg) scale(1)';
            }, 100);
          }
          this.displayPreview = isPreviewable;
          if(!isPreviewable) {
            this.realizationService.downloadFile(res, this.urlSafe);
          }
          this.previewLoader = false;
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      }));
    }
  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    if(el){
      el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
    }
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  ngOnDestroy() {
    this.apiCallList.forEach((a) => {
      a.unsubscribe();
    });
  }
}
