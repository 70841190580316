<div class="ui-fluid p-formgrid p-grid p-px-1">
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-d-flex">
        <div class="p-col-2 p-sm-3 p-md-6 p-lg-6 p-xl-6 p-pl-0">
            <div class="p-d-flex"> <label class="p-mr-2 label_light">Company Code: </label>
                <h1 class="p-medium display-4">{{firstAgreement?.companyCode}}</h1>
            </div>
        </div>
        <div class="p-col-2 p-sm-2 p-md-2 p-lg-2 p-xl-2 p-text-left">
            <div class="p-d-flex"> <label class="p-mr-2 label_light">SF Bill ID: </label>
                <h1 class="p-medium display-4">{{firstAgreement?.sfBillId}}</h1>
            </div>
        </div>
    </div>

    <!-- Agreeement Table -->
    <div class="p-col-12 p-sm-12 p-p-0 p-right" style="max-width: 90vw;" *ngIf="!isDummy">
        <div class="p-field">
            <form [formGroup]="addAgreementForm" autocomplete="off" novalidate>
            <p-table #mainGrid [value]="agreementList" styleClass="p-datatable-gridlines backoffice-cust-scroll"
                [scrollable]="true" scrollHeight="55vh" selectionMode="multiple" [(selection)]="selectedAgreement" scrollDirection="both">
                <ng-template pTemplate="header">
                    <tr>
                        <th scope="col" style="max-width:2rem;min-width:2rem;">
                            <p-tableHeaderCheckbox [disabled]="disableAgreeSelectAll"></p-tableHeaderCheckbox>
                        </th>
                        <th scope="col" pSortableColumn="assignment_family" style="max-width:200px;min-width:200px;">Assignment Family
                            <p-sortIcon field="assignment_family"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="agreementId" style="max-width:200px;min-width:200px;">Agreement Id <p-sortIcon
                                field="agreementId"></p-sortIcon></th>
                        <th scope="col" pSortableColumn="productName" style="max-width:250px;min-width:250px;">Product Name <p-sortIcon
                                field="productName"></p-sortIcon></th>
                        <th scope="col" pSortableColumn="instrument" style="max-width:200px;min-width:200px;">Instrument <p-sortIcon
                                field="instrument"></p-sortIcon></th>
                        <th scope="col" pSortableColumn="applicableQtmAsPerCC" style="max-width:200px;min-width:200px;">Mandate QTM
                            (Applicable) As
                            Per NexEra<p-sortIcon field="applicableQtmAsPerCC"></p-sortIcon></th>
                        <th scope="col" pSortableColumn="reportingDate" style="max-width:200px;min-width:200px;">Mandate Reporting Date
                            <p-sortIcon field="reportingDate"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcmDate" style="max-width:200px;min-width:200px;">RCM Date <p-sortIcon
                                field="rcmDate"></p-sortIcon></th>
                    </tr>
                    <tr>
                        <th scope="col" style="max-width:2rem;min-width:2rem;">

                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <div class="p-fluid p-w-100">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-search" aria-hidden="true"></i>
                                    <input pInputText type="text"
                                        (input)="mainGrid.filter($event.target.value, 'agreementId', 'contains')"
                                        [value]="mainGrid.filters['agreementId']?.value" placeholder="Search"
                                        class="p-column-filter">
                                </span>
                            </div>
                        </th>
                        <th scope="col" style="max-width:250px;min-width:250px;">
                            <!-- <input type="text" disabled> -->
                            <p-columnFilter class="p_columnFilter" field="productName" matchMode="equals" [showMenu]="false" style="width : 100%;max-width: 100%; ">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-dropdown [options]="productNameList" placeholder="Select" formControlName="productFilter"
                                        id="productDropdown" [showClear]="true" optionLabel="name" optionValue="value" appendTo='body' (onChange)="filter($event.value)">
                                        <ng-template let-option pTemplate="item">
                                            <span>{{ option.name }}</span>
                                        </ng-template>
                                    </p-dropdown>
                                </ng-template>
                            </p-columnFilter>
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td style="max-width:2rem;min-width:2rem">
                            <p-tableCheckbox [value]="rowData" [disabled]="rowData.isAdded" [checked]="rowData.isAdded"></p-tableCheckbox>
                        </td>
                        <td class="text-transform-capitalize p-text-nowrap p-text-truncate" style="max-width:200px;min-width:200px;">
                            {{rowData.assignmentFamily}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.agreementId}}
                        </td>
                        <td style="max-width:250px;min-width:250px;">
                            {{rowData.productName}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.instrument}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.applicableQtmAsPerCC}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.reportingDate | date:'dd-MM-yyyy'}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.rcmDate | date:'dd-MM-yyyy'}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">
                            <div class="empmess" *ngIf="agreementList.length == 0 || agreementList == undefined || agreementList == null">
                                <div class="p-mt-2 p-mb-2">
                                    <svg width="143" height="120" viewBox="0 0 143 120"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_3793_6568)">
                                            <rect x="14.5" y="28" width="100" height="78" rx="4"
                                                fill="white" />
                                            <path
                                                d="M15 32C15 30.067 16.567 28.5 18.5 28.5H110.5C112.433 28.5 114 30.067 114 32V35.5H15V32Z"
                                                fill="white" />
                                            <path
                                                d="M15 32C15 30.067 16.567 28.5 18.5 28.5H110.5C112.433 28.5 114 30.067 114 32V35.5H15V32Z"
                                                stroke="#76A7F9" />
                                            <path
                                                d="M15 35.5H114V102C114 103.933 112.433 105.5 110.5 105.5H18.5C16.567 105.5 15 103.933 15 102V35.5Z"
                                                fill="#EBF3FE" />
                                            <path
                                                d="M15 35.5H114V102C114 103.933 112.433 105.5 110.5 105.5H18.5C16.567 105.5 15 103.933 15 102V35.5Z"
                                                stroke="#76A7F9" />
                                            <rect x="18.5" y="95" width="10" height="1" rx="0.5"
                                                transform="rotate(45 18.5 95)" fill="#B1CDFB" />
                                            <rect x="18.5" y="97.8284" width="6" height="1"
                                                rx="0.5" transform="rotate(45 18.5 97.8284)"
                                                fill="#B1CDFB" />
                                        </g>
                                        <rect x="15" y="28.5" width="99" height="77" rx="3.5"
                                            stroke="#76A7F9" />
                                        <g clip-path="url(#clip1_3793_6568)">
                                            <rect x="28.5" y="14" width="100" height="78" rx="4"
                                                fill="white" />
                                            <path
                                                d="M29 18C29 16.067 30.567 14.5 32.5 14.5H124.5C126.433 14.5 128 16.067 128 18V21.5H29V18Z"
                                                fill="white" />
                                            <path
                                                d="M29 18C29 16.067 30.567 14.5 32.5 14.5H124.5C126.433 14.5 128 16.067 128 18V21.5H29V18Z"
                                                stroke="#76A7F9" />
                                            <path
                                                d="M34.2162 19.4098C34.0625 19.4716 33.8981 19.5022 33.7325 19.4999C33.404 19.4953 33.0906 19.3616 32.8599 19.1277C32.6293 18.8938 32.5 18.5785 32.5 18.25C32.5 17.9215 32.6293 17.6062 32.8599 17.3723C33.0906 17.1384 33.404 17.0047 33.7325 17.0001C33.8981 16.9978 34.0625 17.0284 34.2162 17.0902C34.3699 17.152 34.5097 17.2436 34.6277 17.3599C34.7456 17.4762 34.8392 17.6148 34.9032 17.7676C34.9671 17.9204 35 18.0844 35 18.25C35 18.4156 34.9671 18.5796 34.9032 18.7324C34.8392 18.8852 34.7456 19.0238 34.6277 19.1401C34.5097 19.2564 34.3699 19.348 34.2162 19.4098Z"
                                                fill="#E7E7E7" />
                                            <path
                                                d="M39.6888 19.4098C39.5352 19.4716 39.3707 19.5022 39.2051 19.4999C38.8767 19.4953 38.5632 19.3616 38.3326 19.1277C38.102 18.8938 37.9727 18.5785 37.9727 18.25C37.9727 17.9215 38.102 17.6062 38.3326 17.3723C38.5632 17.1384 38.8767 17.0047 39.2051 17.0001C39.3707 16.9978 39.5352 17.0284 39.6888 17.0902C39.8425 17.152 39.9824 17.2436 40.1003 17.3599C40.2182 17.4762 40.3119 17.6148 40.3758 17.7676C40.4397 17.9204 40.4727 18.0844 40.4727 18.25C40.4727 18.4156 40.4397 18.5796 40.3758 18.7324C40.3119 18.8852 40.2182 19.0238 40.1003 19.1401C39.9824 19.2564 39.8425 19.348 39.6888 19.4098Z"
                                                fill="#E7E7E7" />
                                            <path
                                                d="M45.1615 19.4098C45.0078 19.4716 44.8434 19.5022 44.6778 19.4999C44.3493 19.4953 44.0359 19.3616 43.8053 19.1277C43.5746 18.8938 43.4453 18.5785 43.4453 18.25C43.4453 17.9215 43.5746 17.6062 43.8053 17.3723C44.0359 17.1384 44.3493 17.0047 44.6778 17.0001C44.8434 16.9978 45.0078 17.0284 45.1615 17.0902C45.3152 17.152 45.455 17.2436 45.573 17.3599C45.6909 17.4762 45.7846 17.6148 45.8485 17.7676C45.9124 17.9204 45.9453 18.0844 45.9453 18.25C45.9453 18.4156 45.9124 18.5796 45.8485 18.7324C45.7846 18.8852 45.6909 19.0238 45.573 19.1401C45.455 19.2564 45.3152 19.348 45.1615 19.4098Z"
                                                fill="#E7E7E7" />
                                            <path
                                                d="M29 21.5H128V88C128 89.933 126.433 91.5 124.5 91.5H32.5C30.567 91.5 29 89.933 29 88V21.5Z"
                                                stroke="#76A7F9" />
                                            <rect x="36.9" y="30.4" width="64.2" height="13.2"
                                                rx="6.6" stroke="#CECECE" stroke-width="0.8" />
                                            <path
                                                d="M44.3063 37.7979L46.5 40M44.3225 34.6496C45.1969 35.5155 45.1969 36.9194 44.3225 37.7853C43.4481 38.6512 42.0303 38.6512 41.1559 37.7853C40.2814 36.9194 40.2814 35.5155 41.1559 34.6496C42.0303 33.7835 43.4481 33.7835 44.3225 34.6496Z"
                                                stroke="#CECECE" stroke-width="0.8"
                                                stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <rect x="106.913" y="30.4133" width="13.1733"
                                                height="13.2709" rx="6.58667" stroke="#76A7F9"
                                                stroke-width="0.826667" />
                                            <path
                                                d="M114.207 37.6969L116.625 40.1241M114.225 34.2267C115.189 35.1811 115.189 36.7286 114.225 37.683C113.261 38.6374 111.698 38.6374 110.735 37.683C109.771 36.7286 109.771 35.1811 110.735 34.2267C111.698 33.2721 113.261 33.2721 114.225 34.2267Z"
                                                stroke="#76A7F9" stroke-width="0.8"
                                                stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M113.723 51.1478L110.561 41.5834C110.228 40.5744 111.319 39.6703 112.331 40.1173L121.758 44.2858C122.876 44.7795 122.688 46.3365 121.481 46.5777L117.6 47.3555L116.16 51.1906C115.745 52.2918 114.092 52.2615 113.723 51.1478Z"
                                                fill="#76A7F9" />
                                            <rect x="36.5" y="54" width="71" height="4" rx="2"
                                                fill="#E7E7E7" />
                                            <rect x="36.5" y="66" width="58" height="4" rx="2"
                                                fill="#E7E7E7" />
                                            <rect x="36.5" y="78" width="68" height="4" rx="2"
                                                fill="#E7E7E7" />
                                        </g>
                                        <rect x="29" y="14.5" width="99" height="77" rx="3.5"
                                            stroke="#76A7F9" />
                                        <defs>
                                            <clipPath id="clip0_3793_6568">
                                                <rect x="14.5" y="28" width="100" height="78"
                                                    rx="4" fill="white" />
                                            </clipPath>
                                            <clipPath id="clip1_3793_6568">
                                                <rect x="28.5" y="14" width="100" height="78"
                                                    rx="4" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </div>
                                <p class="p-mb-1">No data found for the Add Agreements.</p>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            </form>
        </div>
    </div>

    <!-- Dummy Agreement Table -->
    <div class="p-col-12 p-sm-12 p-p-0 p-right" style="max-width: 90vw;" *ngIf="isDummy">
        <div class="p-field">
            <form [formGroup]="addDummyAgreementForm" autocomplete="off" novalidate>
            <p-table #mainGrid [value]="dummyAgreementList" styleClass="p-datatable-gridlines backoffice-cust-scroll"
                [scrollable]="true" scrollHeight="55vh" selectionMode="multiple" [(selection)]="selectedAgreement" scrollDirection="both">
                <ng-template pTemplate="header">
                    <tr>
                        <th scope="col" style="max-width:2rem;min-width:2rem;">
                            <p-tableHeaderCheckbox [disabled]="disableDummyAgreeSelectAll"></p-tableHeaderCheckbox>
                        </th>
                        <th scope="col" pSortableColumn="assignment_family" style="max-width:200px;min-width:200px;">Assignment Family
                            <p-sortIcon field="assignment_family"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="agreementId" style="max-width:200px;min-width:200px;">Agreement Id <p-sortIcon
                                field="agreementId"></p-sortIcon></th>
                        <th scope="col" pSortableColumn="productName" style="max-width:250px;min-width:250px;">Product Name <p-sortIcon
                                field="productName"></p-sortIcon></th>
                        <th scope="col" pSortableColumn="instrument" style="max-width:200px;min-width:200px;">Instrument <p-sortIcon
                                field="instrument"></p-sortIcon></th>
                        <th scope="col" pSortableColumn="mandate_quantum_applicable_as_per_cc" style="max-width:200px;min-width:200px;">Mandate QTM
                            (Applicable) As
                            Per CC <p-sortIcon field="mandate_quantum_applicable_as_per_cc"></p-sortIcon></th>
                        <th scope="col" pSortableColumn="reporting_date" style="max-width:200px;min-width:200px;">reporting Date
                            <p-sortIcon field="reporting_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">RCM Date <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">RR Date <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">Case Type <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">Agreement Type <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">Initiation Type <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">QC Date <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">SF Actual Percentage <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">SF Method Amount <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">Revised Sf Actual <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">Fee Rule <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">SF Maximum Amount <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                        <th scope="col" pSortableColumn="rcm_date" style="max-width:200px;min-width:200px;">SF Minimum Amount <p-sortIcon
                            field="rcm_date"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" style="max-width:2rem;min-width:2rem;">

                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <div class="p-fluid p-w-100">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-search" aria-hidden="true"></i>
                                    <input pInputText type="text"
                                        (input)="mainGrid.filter($event.target.value, 'agreementId', 'contains')"
                                        [value]="mainGrid.filters['agreementId']?.value" placeholder="Search"
                                        class="p-column-filter">
                                </span>
                            </div>
                        </th>
                        <th scope="col" style="max-width:250px;min-width:250px;">
                            <p-columnFilter class="p_columnFilter" field="productName" matchMode="equals" [showMenu]="false" style="width : 100%;max-width:100%; ">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-dropdown [options]="dummtProductNameList" placeholder="Select" formControlName="productFilter"
                                        id="productDropdown" [showClear]="true" optionLabel="name" optionValue="value" appendTo='body' (onChange)="filter($event.value)">
                                        <ng-template let-option pTemplate="item">
                                            <span>{{ option.name }}</span>
                                        </ng-template>
                                    </p-dropdown>
                                </ng-template>
                            </p-columnFilter>
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                        <th scope="col" style="max-width:200px;min-width:200px;">
                            <input type="text" disabled style="height: 32px;">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td style="max-width:2rem;min-width:2rem;">
                            <p-tableCheckbox [value]="rowData" [disabled]="rowData.isAdded" [checked]="rowData.isAdded"></p-tableCheckbox>
                        </td>
                        <td class="text-transform-capitalize p-text-nowrap p-text-truncate" style="width:200px">
                            {{rowData.assignmentFamily}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.agreementId}}
                        </td>
                        <td style="max-width:250px;min-width:250px;">
                            {{rowData.productName}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.instrument}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.outStandingAmount}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.reportingDate | date:'dd-MM-yyyy'}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.rcmDate | date:'dd-MM-yyyy'}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.rrDate | date:'dd-MM-yyyy'}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.caseType}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.sfMandateValidity}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.initiationType}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.qcDate | date:'dd-MM-yyyy'}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.sfActualPercentage}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.sfActualFee}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.revisedSf}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.feeRule}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.sfMaxAmount}}
                        </td>
                        <td style="max-width:200px;min-width:200px;">
                            {{rowData.sfMinAmount}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="19">
                            <div class="empmess" *ngIf="dummyAgreementList.length == 0 || dummyAgreementList == undefined || dummyAgreementList == null">
                                <div class="p-mt-2 p-mb-2">
                                    <svg width="143" height="120" viewBox="0 0 143 120"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_3793_6568)">
                                            <rect x="14.5" y="28" width="100" height="78" rx="4"
                                                fill="white" />
                                            <path
                                                d="M15 32C15 30.067 16.567 28.5 18.5 28.5H110.5C112.433 28.5 114 30.067 114 32V35.5H15V32Z"
                                                fill="white" />
                                            <path
                                                d="M15 32C15 30.067 16.567 28.5 18.5 28.5H110.5C112.433 28.5 114 30.067 114 32V35.5H15V32Z"
                                                stroke="#76A7F9" />
                                            <path
                                                d="M15 35.5H114V102C114 103.933 112.433 105.5 110.5 105.5H18.5C16.567 105.5 15 103.933 15 102V35.5Z"
                                                fill="#EBF3FE" />
                                            <path
                                                d="M15 35.5H114V102C114 103.933 112.433 105.5 110.5 105.5H18.5C16.567 105.5 15 103.933 15 102V35.5Z"
                                                stroke="#76A7F9" />
                                            <rect x="18.5" y="95" width="10" height="1" rx="0.5"
                                                transform="rotate(45 18.5 95)" fill="#B1CDFB" />
                                            <rect x="18.5" y="97.8284" width="6" height="1"
                                                rx="0.5" transform="rotate(45 18.5 97.8284)"
                                                fill="#B1CDFB" />
                                        </g>
                                        <rect x="15" y="28.5" width="99" height="77" rx="3.5"
                                            stroke="#76A7F9" />
                                        <g clip-path="url(#clip1_3793_6568)">
                                            <rect x="28.5" y="14" width="100" height="78" rx="4"
                                                fill="white" />
                                            <path
                                                d="M29 18C29 16.067 30.567 14.5 32.5 14.5H124.5C126.433 14.5 128 16.067 128 18V21.5H29V18Z"
                                                fill="white" />
                                            <path
                                                d="M29 18C29 16.067 30.567 14.5 32.5 14.5H124.5C126.433 14.5 128 16.067 128 18V21.5H29V18Z"
                                                stroke="#76A7F9" />
                                            <path
                                                d="M34.2162 19.4098C34.0625 19.4716 33.8981 19.5022 33.7325 19.4999C33.404 19.4953 33.0906 19.3616 32.8599 19.1277C32.6293 18.8938 32.5 18.5785 32.5 18.25C32.5 17.9215 32.6293 17.6062 32.8599 17.3723C33.0906 17.1384 33.404 17.0047 33.7325 17.0001C33.8981 16.9978 34.0625 17.0284 34.2162 17.0902C34.3699 17.152 34.5097 17.2436 34.6277 17.3599C34.7456 17.4762 34.8392 17.6148 34.9032 17.7676C34.9671 17.9204 35 18.0844 35 18.25C35 18.4156 34.9671 18.5796 34.9032 18.7324C34.8392 18.8852 34.7456 19.0238 34.6277 19.1401C34.5097 19.2564 34.3699 19.348 34.2162 19.4098Z"
                                                fill="#E7E7E7" />
                                            <path
                                                d="M39.6888 19.4098C39.5352 19.4716 39.3707 19.5022 39.2051 19.4999C38.8767 19.4953 38.5632 19.3616 38.3326 19.1277C38.102 18.8938 37.9727 18.5785 37.9727 18.25C37.9727 17.9215 38.102 17.6062 38.3326 17.3723C38.5632 17.1384 38.8767 17.0047 39.2051 17.0001C39.3707 16.9978 39.5352 17.0284 39.6888 17.0902C39.8425 17.152 39.9824 17.2436 40.1003 17.3599C40.2182 17.4762 40.3119 17.6148 40.3758 17.7676C40.4397 17.9204 40.4727 18.0844 40.4727 18.25C40.4727 18.4156 40.4397 18.5796 40.3758 18.7324C40.3119 18.8852 40.2182 19.0238 40.1003 19.1401C39.9824 19.2564 39.8425 19.348 39.6888 19.4098Z"
                                                fill="#E7E7E7" />
                                            <path
                                                d="M45.1615 19.4098C45.0078 19.4716 44.8434 19.5022 44.6778 19.4999C44.3493 19.4953 44.0359 19.3616 43.8053 19.1277C43.5746 18.8938 43.4453 18.5785 43.4453 18.25C43.4453 17.9215 43.5746 17.6062 43.8053 17.3723C44.0359 17.1384 44.3493 17.0047 44.6778 17.0001C44.8434 16.9978 45.0078 17.0284 45.1615 17.0902C45.3152 17.152 45.455 17.2436 45.573 17.3599C45.6909 17.4762 45.7846 17.6148 45.8485 17.7676C45.9124 17.9204 45.9453 18.0844 45.9453 18.25C45.9453 18.4156 45.9124 18.5796 45.8485 18.7324C45.7846 18.8852 45.6909 19.0238 45.573 19.1401C45.455 19.2564 45.3152 19.348 45.1615 19.4098Z"
                                                fill="#E7E7E7" />
                                            <path
                                                d="M29 21.5H128V88C128 89.933 126.433 91.5 124.5 91.5H32.5C30.567 91.5 29 89.933 29 88V21.5Z"
                                                stroke="#76A7F9" />
                                            <rect x="36.9" y="30.4" width="64.2" height="13.2"
                                                rx="6.6" stroke="#CECECE" stroke-width="0.8" />
                                            <path
                                                d="M44.3063 37.7979L46.5 40M44.3225 34.6496C45.1969 35.5155 45.1969 36.9194 44.3225 37.7853C43.4481 38.6512 42.0303 38.6512 41.1559 37.7853C40.2814 36.9194 40.2814 35.5155 41.1559 34.6496C42.0303 33.7835 43.4481 33.7835 44.3225 34.6496Z"
                                                stroke="#CECECE" stroke-width="0.8"
                                                stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <rect x="106.913" y="30.4133" width="13.1733"
                                                height="13.2709" rx="6.58667" stroke="#76A7F9"
                                                stroke-width="0.826667" />
                                            <path
                                                d="M114.207 37.6969L116.625 40.1241M114.225 34.2267C115.189 35.1811 115.189 36.7286 114.225 37.683C113.261 38.6374 111.698 38.6374 110.735 37.683C109.771 36.7286 109.771 35.1811 110.735 34.2267C111.698 33.2721 113.261 33.2721 114.225 34.2267Z"
                                                stroke="#76A7F9" stroke-width="0.8"
                                                stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M113.723 51.1478L110.561 41.5834C110.228 40.5744 111.319 39.6703 112.331 40.1173L121.758 44.2858C122.876 44.7795 122.688 46.3365 121.481 46.5777L117.6 47.3555L116.16 51.1906C115.745 52.2918 114.092 52.2615 113.723 51.1478Z"
                                                fill="#76A7F9" />
                                            <rect x="36.5" y="54" width="71" height="4" rx="2"
                                                fill="#E7E7E7" />
                                            <rect x="36.5" y="66" width="58" height="4" rx="2"
                                                fill="#E7E7E7" />
                                            <rect x="36.5" y="78" width="68" height="4" rx="2"
                                                fill="#E7E7E7" />
                                        </g>
                                        <rect x="29" y="14.5" width="99" height="77" rx="3.5"
                                            stroke="#76A7F9" />
                                        <defs>
                                            <clipPath id="clip0_3793_6568">
                                                <rect x="14.5" y="28" width="100" height="78"
                                                    rx="4" fill="white" />
                                            </clipPath>
                                            <clipPath id="clip1_3793_6568">
                                                <rect x="28.5" y="14" width="100" height="78"
                                                    rx="4" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </div>
                                <p class="p-mb-1">No data found for the Dummy Agreements.</p>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            </form>
        </div>
    </div>
    
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-d-flex p-jc-end">
        <div class="p-col-6 p-sm-6 p-md-6 p-d-flex p-jc-end">
            <div class="btnwrap">
                <button pButton class="p-button-secondary p-button p-ml-2" type="button" label="Cancel" (click)="closePopUp()">
                </button>
                <button pButton class="p-button-primary p-button p-ml-2" type="button" label="{{generateBill ? 'Generate Bill':'Add'}}" (click)="addAgreement()" *ngIf="!isDummy" [disabled]="disableAddAgreement">
                </button>
                <button pButton class="p-button-primary p-button p-ml-2" type="button" label="Add Agreement" (click)="addAgreement()" *ngIf="isDummy" [disabled]="disableAddAgreement">
                </button>
            </div>
        </div>
    </div>

</div>