<p-messages class="custom-message"></p-messages>
<div class="layout-content layout-content-noscroll">
    <div class="p-formgrid p-grid p-m-0">
        <div class="p-col-12 p-p-0">
            <div class="ui-fluid p-formgrid p-grid p-ai-center p-m-0">
                <div class="p-col-10 p-lg-9 p-xl-8 p-text-left p-pl-0">
                    <div class=" p-fluid   p-field   p-mb-0 ">
                        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb"
                            (onItemClick)="homeBreadcrumbClicked($event)">
                        </p-breadcrumb>
                    </div>
                </div>
                <div class="p-col-4 ui-fluid p-formgrid p-grid p-d-flex p-jc-end p-ai-center p-m-0">
                    <label> Fee Cap:</label>
                    <p-inputSwitch id="addnPay" (onChange)="showFeeCap($event)" [disabled]="disableFields"
                        class="inputSwitch">
                    </p-inputSwitch>
                </div>
            </div>
            
        </div>
    </div>

    <form autocomplete="off" novalidate #sfGenerateForm="ngForm" (ngSubmit)="exportProcess()" class="p-m-0" *ngIf="!showFeeCapFilter">
        <div class="p-formgrid p-grid p-m-0">
            <div class="p-col-12 p-p-0">
                <p-card class="p-p-0 ">
                    <div class="ui-fluid p-formgrid p-grid p-m-0">
                        <div class="p-col-12 p-md-4 p-pl-0">
                            <!-- Financial Year -->
                            <div class=" p-fluid p-field">
                                <label>
                                    Financial Year
                                </label>
                                <p-dropdown name="financialYearField" id="financialYearField"
                                    [(ngModel)]="financialYear" [options]="financialYearList" required
                                    [disabled]="disableFields" [showClear]="false" appendTo="body" optionValue="value"
                                    optionLabel="label" (onChange)="selectOnChange()">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4">
                            <!-- Entity Status -->
                            <div class=" p-fluid p-field">
                                <label>
                                    Entity Status
                                </label>
                                <p-dropdown name="entityStatusField" id="entityStatusField" [(ngModel)]="entityStatus"
                                    [options]="entityStatusList" required [disabled]="disableFields" [showClear]="false"
                                    appendTo="body" optionValue="property" optionLabel="property"
                                    autoDisplayFirst="true" (onChange)="selectOnChange()">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-pr-0">
                            <!-- Client Type -->
                            <div class=" p-fluid p-field">
                                <label>
                                    Client Type
                                </label>
                                <p-dropdown name="clientTypeField" id="clientTypeField" [(ngModel)]="clientType"
                                    [options]="clientTypeList" required [disabled]="disableFields" [showClear]="false"
                                    appendTo="body" optionValue="value" optionLabel="label" autoDisplayFirst="true"
                                    (onChange)="selectOnChange()">
                                </p-dropdown>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-4 p-pl-0">
                            <!-- Assignment Family -->
                            <div class=" p-fluid p-field">
                                <label>
                                    Assignment Family
                                </label>
                                <p-multiSelect #multiselect display="chip" id="sfGenerateBillMultiSelectDropdownid" name="sfGenerateBillMultiSelectDropdown" [filter]="false" [options]="assignmentFamilyList" (onChange)="selectOnChange();" optionLabel="label" placeholder="Select" [disabled]="disableFields" [(ngModel)]="assignmentFamily"scrollHeight="160px" required (onSelectAllChange)="onSelectAllChange($event)" [selectAll]="selectAll">
                                    <ng-template let-all pTemplate="header">
                                        <label class="show-all-under-label">All</label>
                                    </ng-template>
                                    <ng-template let-value pTemplate="selectedItems">
                                        <div *ngIf="assignmentFamily?.length == assignmentFamilyList?.length" class="p-multiselect-token">
                                            <div class="p-multiselect-token-label">
                                                All
                                            </div>
                                            <span class="p-multiselect-token-icon pi pi-times-circle" (click)="changeAssignmentFamilyToEmpty()"></span>
                                        </div>
                                        <div *ngIf="assignmentFamily?.length != assignmentFamilyList?.length" >
                                            <div class="p-multiselect-token" *ngFor="let option of assignmentFamily">
                                                <div class="p-multiselect-token-label">{{option.label}}
                                                </div>
                                                <span class="p-multiselect-token-icon pi pi-times-circle" (click)="handleChipRemoval(option)"></span>
                                            </div>
                                        </div>
                                        <div *ngIf="assignmentFamily?.length == 0" >
                                            <div class="p-multiselect-label p-placeholder p-p-0">Select</div>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                                <div *ngIf="(sfGenerateForm.controls.sfGenerateBillMultiSelectDropdown.touched && sfGenerateForm.controls.sfGenerateBillMultiSelectDropdown.errors?.required) || assignmentFamily?.length === 0" class="invalid-feedback">
                                    Please select at least one value.
                                </div>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-4">
                            <!-- BD Owner Name -->
                            <div class=" p-fluid p-field">
                                <label>
                                    BD Owner Name
                                </label>
                                <p-multiSelect display="chip" id="bdOwnerNameid" name="bdOwnerNameDropdown" [filter]="true" [options]="bdOwnerNameList" optionLabel="employeeName" placeholder="Select" [disabled]="disableFields" [(ngModel)]="bdOwnerName"  scrollHeight="160px" (onFilter)="onFilter($event)" (onChange)="selectOnChangeForBdOwner($event);" #bdOwnerNameDropdown></p-multiSelect>
                            </div>
                        </div>
                    </div>

                    <div class="ui-fluid p-formgrid p-grid p-mx-0 p-mb-0 p-mt-4">
                        <div class="p-col-12 p-md-12 p-d-flex p-jc-end p-pr-0">
                            <button pButton class="p-button-secondary p-mr-3"
                                style="border: none !important; padding: 0; margin: 0; background-color: transparent!important; box-shadow: none ;"
                                type="button" [disabled]="clearAllDisabled || disableFields" (click)="resetForm()"
                                label="Clear All">
                            </button>
                            <button pButton class="p-button-secondary p-mr-3" type="submit" [disabled]="disableFields || assignmentFamily?.length === 0"label="Export">
                            </button>
                            <!-- import excel -->
                            <div style="position: relative;" class=" p-mr-2">
                                <input #fileInput class="import-btn"
                                    accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls"
                                    id="fileDropRef" multiple="multiple" (change)="fileUploaded($event)" type="file">
                                <button pButton type="button" class="p-button-primary p-mr-2 disableImport"
                                    label="Import" (click)="fileInput.click()" [disabled]="disableFields || readScope"></button>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </form>
    <form autocomplete="off" novalidate #sfGenerateForm="ngForm" class="p-m-0" *ngIf="showFeeCapFilter">
        <div class="p-formgrid p-grid p-m-0">
            <div class="p-col-12 p-p-0">
                <p-card class="p-p-0 ">
                    <div class="ui-fluid p-formgrid p-grid p-m-0">
                        <div class="p-col-12 p-md-4 p-pl-0">
                            <!-- Financial Year -->
                            <div class=" p-fluid p-field">
                                <label>
                                    Financial Year
                                </label>
                                <p-dropdown name="financialYearField" id="financialYearField"
                                    [(ngModel)]="financialYear" [options]="feeCapFinancialYearList" required
                                    [disabled]="disableFields" [showClear]="false" appendTo="body" optionValue="value"
                                    optionLabel="label" (onChange)="selectOnChange()">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4">
                            <!-- Company Name -->
                            <div class=" p-fluid p-field">
                                <label>
                                    Company Name
                                </label>
                                <p-dropdown  id="companyNameid" name="companyNameField" class="company-name-dropdown" [options]="companyNameList" [(ngModel)]="selectedCompanyName" [filter]="true" filterBy="companyName" placeholder="Company Name" optionLabel="companyName" (onChange)="onInputFieldChange();" (onFilter)="onCompanyNameFilter($event)" (onClear)="companyNameOnClear()" resetFilterOnHide="true">
                                    <ng-template let-option pTemplate="item">
                                        <div class="p-multiselect-representative-option">
                                            <span class="p-ml-1">{{ option?.companyName }}</span>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                       
                        <div class="p-col-12 p-md-1 p-fluid p-field p-d-flex p-jc-end p-pr-0">
                            <button pButton class="p-button-secondary p-mr-0"
                                type="button" [disabled]="feeCapClearAllDisabled || disableFields" (click)="clearFeeCapData()"
                                label="Clear All">
                            </button>
                        </div>
                        
                        <div class="p-col-12 p-md-1 p-fluid p-field p-d-flex p-jc-end p-pr-0">
                            <button pButton class="p-button-primary p-mr-0" [disabled]="disableFields || disableSearchBtn" (click)="showFeeCapCompanyData()" label="Search">
                            </button>
                        </div>

                        
                    </div>
                </p-card>
            </div>
        </div>
        <div class="p-col-8 p-sm-8 p-p-0 reportingScreenGridTable">
            <div class="p-col-12 p-sm-12 p-mt-2">
                <div class="p-field">
                    <p-table #mainGrid [value]="companyList"
                        styleClass="p-datatable-gridlines mt-small backoffice-cust-scroll">
    
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:70px;">Company Code
                                </th>
                                <th style="width:120px;">Company
                                    Name
                                </th>
                                <th style="width:100px;">
                                    BD Owner
                                </th>
                                <th style="width:70px;">Add Agreement
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-opportunity>
                            <tr>
                                <td style="width:70px;">
                                    {{opportunity?.companyCode}}
                                </td>
                                <td style="width:120px;">
                                    {{opportunity?.companyName}}
                                </td>
                                <td style="width:100px;">
                                    {{opportunity?.bdOwnerName}}
                                </td>
                                <td style="width:70px;">
                                    <button pButton class="p-button-secondary p-mr-0" label="Add Agreement" (click)="showAgreementsByCompanyCode(opportunity)" [disabled]="readScope">
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Import Summary -->
<p-dialog header="Alert" class="previewModal mConfirmationPopup" [(visible)]="noRecordsFoundImportSummaryModal" [modal]="true"
    [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
    <p class="p-m-0">No Records found for Bill Generation</p>
    <ng-template pTemplate="footer">
        <button pButton class="p-button-primary" type="button" (click)="closeNoRecordsFoundImportSummaryModal()"
            label="Ok">
        </button>
    </ng-template>
</p-dialog>

<!-- Import Summary -->
<p-dialog header="Alert" class="previewModal mConfirmationPopup" [(visible)]="viewImportSummaryModal" [modal]="true"
    [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
    <p class="p-m-0">{{errorMessageOnImport}}.</p>
    <ng-template pTemplate="footer">
        <button pButton class="p-button-primary" type="button" (click)="closeOnErrorImportModal()"
            label="Ok">
        </button>
    </ng-template>
</p-dialog>

<!-- Info Popup -->
<p-dialog header="Alert" [(visible)]="viewImportSummarySuccessModal" [modal]="true" closable="false" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-p-0">
        <label class="p-mt-1 p-mb-1">Total <strong>{{processedResult?.validEntries}}</strong> records ready for bill generation.
            <br>Do you want to Proceed with SF Bill Generation?</label>
    </div>
    <ng-template pTemplate="footer">
        <button pButton class="p-button-secondary" type="button" (click)="generateBillMethod('No')" label="No">
        </button>
        <button pButton class="p-button-primary" type="button" (click)="generateBillMethod('Yes')"
            label="Yes, Generate Bill">
        </button>
    </ng-template>
</p-dialog>

<!-- Import Error Summary -->
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="showGeneratedBillStatus"
    [modal]="true" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-column p-ai-center">
        <svg width="48" height="48" class="illustrationLight" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="46" height="46" rx="23" fill="#E7F2EC" />
            <path d="M37 16L19.125 32L11 24.7273" stroke="#58A778" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            <rect x="1" y="1" width="46" height="46" rx="23" stroke="#58A778" stroke-width="2" />
        </svg>
        <svg width="48" height="48" class="illustrationDark" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37 16L19.125 32L11 24.7273" stroke="#70B38B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <rect x="1" y="1" width="46" height="46" rx="23" stroke="#70B38B" stroke-width="2"/>
        </svg>
        <label class="p-mt-3 p-mb-3">Total <strong>{{processedResult?.validEntries}}</strong> records SF Bill generated successfully.</label>
    </div>
</p-dialog>

<!-- Processing Popup -->
<p-dialog header="Processing" [(visible)]="displayProcessingModal" [modal]="true" [style]="{width: '31vw'}"
    [draggable]="false" [closable]="false" [resizable]="false">
    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-column p-ai-center">
        <backoffice-svg [name]="'processing-light'"></backoffice-svg>
        <backoffice-svg [name]="'processing-dark'"></backoffice-svg>
        <label class="p-mt-3 p-mb-3">Please wait while we are processing the data.</label>
    </div>
</p-dialog>