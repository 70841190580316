<div class="layout-content layout-content-noscroll">
    <p-messages class="custom-message"></p-messages>
    <div class="p-formgrid p-grid">
        <div class=" p-col-12 p-p-0">
            <div class="ui-fluid p-formgrid p-grid p-ai-center">
                <div class="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-pl-0 p-text-left">
                    <div class=" p-fluid p-field  p-mb-0  ">
                        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb"
                            (onItemClick)="homeBreadcrumbClicked($event)">
                        </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form [formGroup]="qcForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12 p-p-0">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-between p-ai-center p-d-flex p-flex-column p-flex-md-row  p-p-0">
                        <!-- <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-p-0 p-mb-2 p-md-mb-0 p-d-flex p-jc-start p-ai-center">
                            <label class="p-text-bold p-mb-0"> </label>
                            <p-badge [value]="gridDataList?.length" styleClass="businessWonCount-Badge"></p-badge>
                        </div> -->
                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-d-flex p-jc-end p-mt-0 p-mr-0">
                            <div class=" p-fluid   p-field  p-d-flex p-jc-end p-flex-row p-pl-0 p-pr-0 p-mb-0 p-mr-3 p-ai-center">
                                <label class="p-mb-0 p-mt-1"> Invoice Before Payment:</label>
                                <p-inputSwitch id="addnPay"
                                    class="inputSwitch p-mt-1" (onChange)="showInvoiceBeforePaymentCases($event)">
                                </p-inputSwitch>
                            </div>
                            <div class=" p-fluid   p-field  p-d-flex p-jc-end p-flex-row p-pl-0 p-pr-0 p-mb-2">
                                <div pTooltip="Global entity name search" (click)="showSearchCompanyPopUp()" tooltipPosition="left"
                                    class="p-col-2 p-button-brand2 p-mr-3 p-d-flex p-ai-center" style="width : 36px; cursor: pointer">
                                    <backoffice-svg [name]="'global-search-light'"></backoffice-svg>
                                </div>
                        
                                <button [disabled]="gridLoader || loadingStatus" pButton class="p-col-2 p-button-brand2" icon="pi pi-filter"
                                    *ngIf="!filterDisplay" pTooltip="Filter" tooltipPosition="bottom" (click)="showFilter()"></button>
                        
                                <button [disabled]="gridLoader || loadingStatus" pButton class="p-col-2 p-button-brand2"
                                    icon="pi pi-filter-slash" *ngIf="filterDisplay" pTooltip="Clear Filter" tooltipPosition="bottom"
                                    (click)="clear(mainGrid)"></button>
                        
                            </div>
                        </div>
                    </div>

                    <div class="p-col-12 p-sm-12 p-p-0 reportingScreenGridTable">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-12 p-sm-12 p-p-0 p-right">
                                        <div class="p-field">
                                            <div [ngClass]="{'content-section implementation': true}">
                                                <p-table #mainGrid name="businessWonDataGrid" styleClass="p-datatable-gridlines backoffice-cust-scroll"
                                                    [value]="gridDataList" [paginator]="true" [showCurrentPageReport]="true" [rows]="10" sortMode="single"
                                                    [scrollable]="true" scrollHeight="55vh" scrollDirection="both" [rowsPerPageOptions]="[10, 25, 50]"
                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [loading]="loadingStatus"
                                                    (onFilter)="loadingStatus = false;">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th pSortableColumn='entityName' scope="col" style="max-width:220px;">
                                                                Entity Name
                                                                <p-sortIcon field='entityName' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>

                                                            <th pSortableColumn='agreementId' scope="col" style="max-width:160px;">
                                                                Agreement ID
                                                                <p-sortIcon field='agreementId' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>

                                                            <th pSortableColumn='initiatedDate' scope="col" style="max-width:160px;">
                                                                Initiation Date
                                                                <p-sortIcon field='initiatedDate' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>

                                                            <th pSortableColumn='clientClassification' scope="col" style="max-width:160px;">
                                                                Client Type
                                                                <p-sortIcon field='clientClassification' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>

                                                            <th pSortableColumn='caseStatus' scope="col" style="max-width:160px;">
                                                                Opportunity Status
                                                                <p-sortIcon field='caseStatus' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>

                                                            <th pSortableColumn='product' scope="col" style="max-width:160px;">
                                                                Product
                                                                <p-sortIcon field='product' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>

                                                            <th pSortableColumn='crrProduct' scope="col" style="max-width:160px;">
                                                                CRR Product
                                                                <p-sortIcon field='crrProduct' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>

                                                            <th pSortableColumn='instrument' scope="col" style="max-width:160px;">
                                                                Instrument
                                                                <p-sortIcon field='instrument' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>

                                                            <th pSortableColumn='quantum' scope="col" style="max-width:160px;">
                                                                Quantum
                                                                <p-sortIcon field='quantum' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>

                                                            <th pSortableColumn='actualIrfFee' scope="col" style="max-width:160px;">
                                                                Actual IRF Fee
                                                                <p-sortIcon field='actualIrfFee' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>

                                                            <th pSortableColumn='afterQcMandateDocStatus' scope="col" style="max-width:160px;">
                                                                Document Status
                                                                <p-sortIcon field='afterQcMandateDocStatus' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>

                                                            <th pSortableColumn='afterQcAdditionalPaymentStatus' scope="col" style="max-width:200px;min-width:200px;">
                                                                Additional Payment Status
                                                                <p-sortIcon field='afterQcAdditionalPaymentStatus' ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order">
                                                                </p-sortIcon>
                                                            </th>
                                                        </tr>
                                                        <tr *ngIf="filterDisplayRow">
                                                            <th scope="col" class="no-bg" style="max-width:220px;">
                                                                <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'entityName', 'contains')" [value]="mainGrid.filters['entityName']?.value" placeholder="Search" class="p-column-filter">
                                                            </th>

                                                            <th scope="col" class="no-bg" style="max-width:160px;">
                                                                <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'agreementId', 'contains')" [value]="mainGrid.filters['agreementId']?.value" placeholder="Search" class="p-column-filter">
                                                            </th>

                                                            <th scope="col" class="no-bg" style="max-width:160px;">
                                                                <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'initiatedDate', 'contains')" [value]="mainGrid.filters['initiatedDate']?.value" placeholder="Search" class="p-column-filter" disabled>
                                                            </th>

                                                            <th scope="col" class="no-bg" style="max-width: 160px;min-width: 160px;">
                                                                <p-columnFilter class="p_columnFilter" field="clientClassification" matchMode="equals" [showMenu]="false">
                                                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                                        <p-dropdown [options]="clientTypeList" placeholder="Select" (onChange)="filter($event.value);" id="clientClassification"
                                                                            [disabled]="disableFilter" optionLabel="name" optionValue="name" #oppBd formControlName="clientTypeFilter" [showClear]="true" [panelStyle]="{ 'min-width': 'min(100vw, 10px)', 'max-height' : 'fit-content' }">
                                                                            <ng-template let-rowData pTemplate="item">
                                                                                <div class="">
                                                                                    <span class="p-ml-1">{{ rowData.name }}</span>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-dropdown>
                                                                    </ng-template>
                                                                </p-columnFilter>
                                                            </th>

                                                            <th scope="col" class="no-bg" style="max-width: 160px;min-width: 160px;">
                                                                <p-columnFilter class="p_columnFilter" field="caseStatus" matchMode="equals" [showMenu]="false">
                                                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                                        <p-dropdown [options]="opportunityStatusList" placeholder="Select" (onChange)="opportunityStatusFilter($event.value)"
                                                                            id="caseStatus" formControlName="opportunityStatusFilter" optionLabel="name"
                                                                            optionValue="name" #oppBd [showClear]="true" [disabled]="disableFilter" [panelStyle]="{ 'min-width': 'min(100vw, 10px)', 'max-height' : 'fit-content' }">
                                                                            <ng-template let-rowData pTemplate="item">
                                                                                <div class="">
                                                                                    <span class="p-ml-1">{{ rowData.name }}</span>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-dropdown>
                                                                    </ng-template>
                                                                </p-columnFilter>
                                                            </th>

                                                            <th scope="col" class="no-bg" style="max-width: 160px;min-width: 160px;">
                                                                <p-columnFilter class="p_columnFilter" field="product" matchMode="equals" [showMenu]="false">
                                                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                                        <p-dropdown [options]="productList" placeholder="Select" (onChange)="filter($event.value)"
                                                                            id="product" formControlName="productFilter" optionLabel="name"
                                                                            optionValue="name" #oppBd [showClear]="true"
                                                                            [disabled]="disableFilter">
                                                                            <ng-template let-rowData pTemplate="item">
                                                                                <div class="">
                                                                                    <span class="p-ml-1">{{ rowData.name }}</span>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-dropdown>
                                                                    </ng-template>
                                                                </p-columnFilter>
                                                            </th>

                                                            <th scope="col" class="no-bg" style="max-width: 160px;min-width: 160px;">
                                                                <p-columnFilter class="p_columnFilter" field="crrProduct" matchMode="equals" [showMenu]="false">
                                                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                                        <p-dropdown [options]="crrProductList" placeholder="Select" (onChange)="filter($event.value)"
                                                                            id="crrProduct" formControlName="crrProductFilter" optionLabel="name"
                                                                            optionValue="name" #oppBd [showClear]="true"
                                                                            [disabled]="disableFilter">
                                                                            <ng-template let-rowData pTemplate="item">
                                                                                <div class="">
                                                                                    <span class="p-ml-1">{{ rowData.name }}</span>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-dropdown>
                                                                    </ng-template>
                                                                </p-columnFilter>
                                                            </th>

                                                            <th scope="col" class="no-bg" style="max-width: 160px;min-width: 160px;">
                                                                <input pInputText type="text" (input)="mainGrid.filter($event.target.value, 'instrument', 'contains')" [value]="mainGrid.filters['instrument']?.value" placeholder="Search" class="p-column-filter">
                                                            </th>

                                                            <th scope="col" class="no-bg" style="max-width: 160px;min-width: 160px;">
                                                                <p-columnFilter class="p_columnFilter" field="quantum" matchMode="equals" [showMenu]="false">
                                                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                                        <p-dropdown [options]="quantumList" placeholder="Select" (onChange)="filter($event.value)"
                                                                            id="quantum" formControlName="quantumFilter" optionLabel="name"
                                                                            optionValue="name" #oppBd [showClear]="true"
                                                                            [disabled]="disableFilter">
                                                                            <ng-template let-rowData pTemplate="item">
                                                                                <div class="">
                                                                                    <span class="p-ml-1">{{ rowData.name }}</span>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-dropdown>
                                                                    </ng-template>
                                                                </p-columnFilter>
                                                            </th>

                                                            <th scope="col" class="no-bg" style="max-width: 160px;min-width: 160px;">
                                                                <p-columnFilter class="p_columnFilter" field="actualIrfFee" matchMode="equals" [showMenu]="false">
                                                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                                        <p-dropdown [options]="actualIrfFeeList" placeholder="Select" (onChange)="filter($event.value)"
                                                                            id="actualIrfFee" formControlName="actualIrfFeeFilter" optionLabel="name"
                                                                            optionValue="name" #oppBd [showClear]="true"
                                                                            [disabled]="disableFilter">
                                                                            <ng-template let-rowData pTemplate="item">
                                                                                <div class="">
                                                                                    <span class="p-ml-1">{{ rowData.name }}</span>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-dropdown>
                                                                    </ng-template>
                                                                </p-columnFilter>
                                                            </th>

                                                            <th scope="col" class="no-bg" style="max-width: 160px;min-width: 160px;">
                                                                <p-columnFilter class="p_columnFilter" field="afterQcMandateDocStatus" matchMode="equals" [showMenu]="false">
                                                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                                        <p-dropdown [options]="docStatusList" placeholder="Select" (onChange)="filter($event.value)"
                                                                            id="afterQcMandateDocStatus" formControlName="bdUserFilter" optionLabel="name"
                                                                            optionValue="name" #oppBd [showClear]="true"
                                                                            [disabled]="disableFilter">
                                                                            <ng-template let-rowData pTemplate="item">
                                                                                <div class="">
                                                                                    <span class="p-ml-1">{{ rowData.name }}</span>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-dropdown>
                                                                    </ng-template>
                                                                </p-columnFilter>
                                                            </th>

                                                            <th scope="col" class="no-bg" style="max-width: 200px;min-width: 200px;">
                                                                <p-columnFilter class="p_columnFilter" field="afterQcAdditionalPaymentStatus" matchMode="equals" [showMenu]="false">
                                                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                                        <p-dropdown [options]="docPaymentList" placeholder="Select" (onChange)="filter($event.value)"
                                                                            id="afterQcAdditionalPaymentStatus" formControlName="bdManagerFilter" optionLabel="name"
                                                                            optionValue="name" #oppBd [showClear]="true"
                                                                            [disabled]="disableFilter">
                                                                            <ng-template let-rowData pTemplate="item">
                                                                                <div class="">
                                                                                    <span class="p-ml-1">{{ rowData.name }}</span>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-dropdown>
                                                                    </ng-template>
                                                                </p-columnFilter>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData>
                                                        <tr style="cursor: pointer;">
                                                            <td class="text-transform-capitalize p-text-nowrap p-text-truncate" style="max-width:220px;min-width:220px;">
                                                                <div style="white-space: nowrap;max-width:200px; text-overflow: ellipsis; overflow: hidden;">
                                                                    <a (click)="getQcDetails(rowData)">{{rowData.entityName}}</a>
                                                                </div>
                                                            </td>
                                                            <td style="max-width:160px;min-width:160px;">
                                                                {{rowData.agreementId}}
                                                            </td>
                                                            <td style="max-width:160px;min-width:160px;">
                                                                {{rowData.initiatedDate | date:'dd-MM-yyyy HH:mm'}}
                                                            </td>
                                                            <td style="max-width:160px;min-width:160px;">
                                                                <div [ngClass]="{green: rowData.clientClassification == 'LCG -B', blue: rowData.clientClassification == 'MCG', black: rowData.clientClassification == 'LCG -A'}">
                                                                    {{rowData.clientClassification}}
                                                                </div>
                                                            </td>
                                                            <td style="max-width:160px;min-width:160px;">
                                                                {{rowData.caseStatus}}
                                                            </td>
                                                            <td style="max-width:160px;min-width:160px;">
                                                                {{rowData.product}}
                                                            </td>
                                                            <td style="max-width:160px;min-width:160px;">
                                                                {{rowData.crrProduct}}
                                                            </td>
                                                            <td style="max-width:160px;min-width:160px;">
                                                                {{rowData.instrument}}
                                                            </td>
                                                            <td style="max-width:160px;min-width:160px;">
                                                                {{rowData.quantum}}
                                                            </td>
                                                            <td style="max-width:160px;min-width:160px;">
                                                                {{rowData.actualIrfFee}}
                                                            </td>
                                                            <td style="max-width:160px;min-width:160px;">
                                                                {{rowData.afterQcMandateDocStatus}}
                                                            </td>
                                                            <td style="max-width:200px;min-width:200px;">
                                                                {{rowData.afterQcAdditionalPaymentStatus}}
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="12">
                                                                <ng-container *ngIf="firstLoad">
                                                                    <div class="p-mt-6 empmess_div" style="text-align: center; color: rgba(41, 41, 41, 0.6);">
                                                                        <div class="p-mb-3 p-d-flex p-jc-center">
                                                                            <backoffice-svg [name]="'empty-state-light'"></backoffice-svg>
                                                                            <backoffice-svg [name]="'empty-state-dark'"></backoffice-svg>
                                                                        </div>
                                                                        <p>No data found for the selected filter criteria</p>
                                                                    </div>
                                                                </ng-container>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </form>
</div>
<div class="p-grid p-ml-0 p-mr-0 p-col-12 p-jc-between p-button-container">
    <div class="p-col-5 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-pl-0 left">
        <div class="btnwrap">
            <button pButton class="p-button-brand2" type="button" (click)="onQcBackBtn()" label="Back">
            </button>
        </div>
    </div>
</div>
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>
<!-- search company name -->
<p-dialog header="Search Company" [(visible)]="searchCompanyPopUp" [modal]="true" [style]="{width: '31vw'}"
    [draggable]="false" [resizable]="false" [closable]="true" class="search-company">
    <div class="btnwrap p-col-12 p-px-0">
        <div class="p-fluid p-w-100">
            <div class="inputtext-medium">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" aria-hidden="true"></i>
                    <p-autoComplete id="search1" name="search1" placeholder="Search for Company"
                        [suggestions]="companyNameList" field="entityName" optionLabel="entityName" [(ngModel)]="companyNameSearchValue"
                        [ngClass]="{'show': showEntityLoader}" (onSelect)="onChangeCompanySelect($event)"
                        (completeMethod)="loadCompanyList($event)" [showEmptyMessage]="emptyMsgFlag"
                        [emptyMessage]="emptyMsgVal">
                    </p-autoComplete>
                </span>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button [disabled]="!companyNameSearchValue" pButton (click)="resetCompanySearch()" label="Reset"
            class="p-button-info"></button>
        <button [disabled]="!companyNameSearchValue" pButton label="Apply" (click)="applyCompanySearch()"
            class="p-button-primary p-mr-0"></button>
    </ng-template>
</p-dialog>
