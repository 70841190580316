import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'backoffice-svg',
  templateUrl: './backoffice-svg.component.html',
  styleUrls: ['./backoffice-svg.component.scss']
})
export class BackofficeSvgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input('name') svgName : string;
}
