import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { reportingScreenSerive } from '../reporting-screen.service';

@Component({
  selector: 'backoffice-reporting-coa',
  templateUrl: './reporting-coa.component.html',
  styleUrls: ['./reporting-coa.component.scss'],
  providers: [MessageService]
})
export class ReportingCOAComponent implements OnInit {
  @Output() COAData = new EventEmitter<any>();
  scopeList: any;
  COAReadRoleFlag: boolean=false;
  COAWithBothRoleFlag: boolean = false;
  COAWithEditRoleFlag: boolean = false;
  COAWithReadRoleFlag: boolean = false;
  disableSaveBtn: boolean = true;
  OpportunityId: any;
  isCriticalDocRecieved: string;
  oldCriticalDocRecieved: any;
  isCriticalDocRecievedFlag: boolean;
  empId: any;
  onSaveLoader: boolean = false;
  businessTypeList: any = []
  productselectList: any = []
  instrumentselectList: any = []
  reportingData: any;

  ReportingScreenCOAForm = this.fb.group({
    businessType: [null as any, []],
    product: [null as any, []],
    instrument: [null as any, []],
    quantum: [null as any, []],
    criticalDocReceived: [null as any, []],
  });

  constructor(private fb: FormBuilder,private pagesService: reportingScreenSerive,private messageService: MessageService,private encryptDecryptInterceptor : EncryptDecryptInterceptor,public config: DynamicDialogConfig, public ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.onSaveLoader = true;
    this.OpportunityId = this.config.data.opportunityId;
    this.businessTypeList = [];
    this.productselectList = [];
    this.instrumentselectList = [];
    this.scopeList = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    this.empId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));
    this.userScopePermission();
    this.pagesService.onReportingScreenDetailsPageLoad(this.OpportunityId, true).subscribe(results => {
      results.opportunityId = this.OpportunityId;
      this.COAData.emit(results);
      this.reportingData = results;
      this.onSaveLoader = false;
      let responseData = {
        businessType: results?.businessType,
        product: results?.product,
        instrument: results?.instrument,
        quantum: results?.quantum,
        criticalDocReceived: results?.ipaCriticalDocReceived == 'Yes' ? true : false
      };
      this.oldCriticalDocRecieved = results?.ipaCriticalDocReceived;
      this.businessTypeList.push({'property': results?.businessType});
      this.productselectList.push({'property': results?.product});
      this.instrumentselectList.push({'property': results?.instrument});
      this.ReportingScreenCOAForm.patchValue(responseData);
    });
  }

  userScopePermission(): void {
    let scope: any = this.scopeList.split(",");
    if( (scope.indexOf('COA_EDIT') != -1) && (scope.indexOf('COA_READ') != -1)){
      this.COAWithBothRoleFlag = true;
      this.isCriticalDocRecievedFlag = false;
    }else if(scope.indexOf('COA_EDIT') != -1){
      this.COAWithEditRoleFlag = true;
      this.isCriticalDocRecievedFlag = false;
    } else if(scope.indexOf('COA_READ') != -1){
      this.COAWithReadRoleFlag = true;
      this.disableSaveBtn = true;
      this.isCriticalDocRecievedFlag = true;
    } else {
      this.COAWithBothRoleFlag = false;
      this.COAWithReadRoleFlag = false;
      this.COAWithEditRoleFlag = false;
    }
  }

  docToggle(event){
    this.disableSaveBtn = false;
    if(event.checked){
      this.isCriticalDocRecieved = 'Yes'
    } else {
      this.isCriticalDocRecieved = 'No'
    }

    if (this.isCriticalDocRecieved == this.oldCriticalDocRecieved) {
      this.disableSaveBtn = true;
    }
  }

  onSave(){
    let data = {
      "opportunityId": this.OpportunityId,
      "ipaCriticalDocReceived": this.isCriticalDocRecieved,
      "userId": this.empId
    }
    this.onSaveLoader = true;
    this.disableSaveBtn = false;
    this.pagesService.onSaveBtnSubmitCreateReportingData(data).subscribe((res) => {
      this.onSaveLoader = false;
      this.onCancel(res);
    }, (error) => {
      this.onSaveLoader = false;
      this.onCancel(error);
    });
  }

  onCancel(res?){
    this.ref.close(res);
  }
}
 