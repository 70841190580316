import { DatePipe, Location } from '@angular/common';
import { Component, OnInit, ViewChildren, AfterViewInit, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators  } from '@angular/forms';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { Table, TableCheckbox } from 'primeng/table';
import { PagesService } from '../pages.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { QcDetailsComponent } from 'app/quality-check/qc-details/qc-details.component';
import * as FileSaver from 'file-saver';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'backoffice-irf-push-pull-grid',
  templateUrl: './irf-push-pull-grid.component.html',
  styleUrls: ['./irf-push-pull-grid.component.scss'],
  providers: [DialogService, MessageService]
})
export class IrfPushPullGridComponent implements OnInit, AfterViewInit {
  @ViewChildren('mainGrid') mainGrid;
  @ViewChild('checkboxRef', { static: false }) checkboxRef!: TableCheckbox;
  @ViewChild('globalCalendar') globalCalendar: any;

  detailsPopUp: DynamicDialogRef = new DynamicDialogRef;
  ccprimebreadcrumbList = [
    { label: `IRF Invoices Listing`, routerLink: ['/pages/invoices/irf'] },
  ];

  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  loadingStatus = false; 
  pendingCasesCount: any = 0;
  pendingInvoiceCount: any = 0;
  pendingCNCount: any = 0;
  casesSelected: any = 0;
  filterDisplay: boolean = true;
  showLoader: boolean = false;
  invoiceDataList: any[] = [];

  clientTypeList = [];
  clientTypeList2 = [
    {
      label:"CRISIL_ADV_MH",
      value:"CRISIL_ADV_MH"
    },{
      label:"CRISIL_ADV_MH_CM",
      value:"CRISIL_ADV_MH_CM"
    },{
      label:"CRISIL_RR",
      value:"CRISIL_RR"
    },{
      label:"CRISIL_RR_CM",
      value:"CRISIL_RR_CM"
    },{
      label:"CRL_ADV_MH_NR 1",
      value:"CRL_ADV_MH_NR 1"
    },{
      label:"CRL_CM_ADV_MH_NR",
      value:"CRL_CM_ADV_MH_NR"
    },{
      label:"CRL_RR_NR",
      value:"CRL_RR_NR"
    },{
      label:"CRL_RR_CM_NR",
      value:"CRL_RR_CM_NR"
    }
  ];
  noOfAgreementsList = [];
  invoiceStatusList = [];
  invoiceStatusList2 = [
    {
      label:"Pending",
      value:"Pending"
    },{
      label:"WIP",
      value:"WIP"
    },{
      label:"Processed",
      value:"Processed"
    },{
      label:"Error",
      value:"Error"
    }
  ];
  selectedStatus:any;
  transcationTypeList = [
    {
      label:"INVOICE",
      value:"INVOICE"
    },{
      label:"CM",
      value:"CM"
    },{
      label:"REVISION",
      value:"REVISION"
    }
  ];
  finRiskTypeList = [
    {
      label:"High Risk",
      value:"High Risk"
    },{
      label:"Medium Risk",
      value:"Medium Risk"
    },{
      label:"Low Risk",
      value:"Low Risk"
    }
  ];
  invoiceNoList = [];
  originalBilledAmountList = [];
  proposedBilledAmountList = [];
  amountRealizedList = [];
  cnNoList = [];
  errorStatusList = [];
  irfBillIdList = [];
  revisionReasons = [];
  isChecked: boolean = true;

  irfPullPushForm = this.fb.group({
    status: ['Pending', []],
    globalCalendar: [null as any, []],
    globalSearchInput: [null, Validators.minLength(3)],
    reprocessComment: [null, [Validators.maxLength(200), Validators.minLength(5)]]
  });
  
  disableProcessBtn: boolean = true;
  irfBillDataList = [];
  selectIRFBillIdList = [];
  gridLoader: boolean = false;
  apiFail: boolean = false;
  queryirfBillId: any;
  showCross: boolean = false;
  showEntityLoader: boolean = false;
  companyNameList: any[];
  emptyMsgFlag: boolean = false;
  emptyMsgVal: string = 'No Company Found';
  minGlobalStartTime = new Date();
  maxGlobalEndTime = new Date();

  globalStartTime = null;
  globalEndTime = null;
  apiFailStatusSelection: boolean = false;
  datefilterSelected:boolean = true;

  //status grid
  customOptions  = [
    { label: 'View Case Count', value: 'option1', disabled: true }
  ];
  selectedOption: any;

  statusCountRawData: [] = [];
  statusCountTransformedData: {[key: string]:{[key: string]: number | null}} = {};
  testStatuscount = null;

  statusCount;

  //global expandable search
  searchCompanyPopUp = false;
  companyNameSearchValue : any;
  globalSearchSuggestions: any[] = [];
  globalSearchSuggestionsCode: any[] = [];
  globalSearchInput:FormControl = new FormControl(null);
  globalSearchedCompanyCode:string = null;

  //editable&selectable row
  editing: boolean;
  reprocessComments: FormControl = new FormControl('', [Validators.maxLength(200), Validators.minLength(5)]);

  //attachment mandate
  mandate:any;
  openedFile: any;
  zoomValue: number = 1;
  displayPreview: boolean;
  dataLoader: boolean = false;
  apiList: any = [];
  urlSafe: any;
  previewFailed: boolean = false;

  //process check for adv, rr, cm and revision
  checkInvoiceNumber;
  statusCheckForINVNo:boolean = false;
  statusCheckForINVNoShowModal:boolean = false;
  statusCheckForINVNoMsg:string;
  checkStatusForList:any;

  //scope check
  checkScope:boolean = false;

  //maximum checkbox selections popup
  showMaximumSelectionPopup:boolean = false;

  //errorcases
  showErrorCaseCommentInputModal:boolean = false;
  errorCaseComment = "";

  constructor(private _location: Location, private fb: FormBuilder, private pagesService: PagesService, private dialogService: DialogService,private renderer: Renderer2,private cd: ChangeDetectorRef,private encDec: EncryptDecryptInterceptor, private messageService: MessageService, private datepipe: DatePipe) { }
  ngAfterViewInit(): void {
    if (this.irfPullPushForm.get('status').value !== null) {  // 2 - default table filter on load of screen
      this.mainGrid.first.filter(this.irfPullPushForm.get('status').value, 'status', 'equals');
    }
  }

  ngOnInit(): void {
    

    this.getPushGridList();
    
    let month = this.minGlobalStartTime.getMonth();
    let year = this.minGlobalStartTime.getFullYear();
    let prevMonth = month;
    let prevYear = year - 1;
    console.log("start", this.minGlobalStartTime, prevMonth, prevYear);
    this.minGlobalStartTime.setMonth(prevMonth);
    this.minGlobalStartTime.setFullYear(prevYear);

    let scopes: string = this.encDec.decrypt(localStorage.getItem('scopes'));
    console.log("scopes", scopes);
    if(scopes.includes("SF_REALIZATION_CREATE")){
      this.checkScope = true;
    }else{
      this.checkScope = false;
    }
    console.log("scopes", this.checkScope);
    this.fetchProcessedCases();
  }

  homeBreadcrumbClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}/#/nexera/dashboard/home`;
    }
  }

  showFilter(table: Table) {
    this.filteredInvoicedateData = undefined;
    this.filterDisplay = !this.filterDisplay;
    this.cd.detectChanges();
    if (this.filterDisplay === false) {
      table?.reset();

      if (this.mainGrid?.first?.filters) { // reset all filters
        Object.values(this.mainGrid.first.filters).map(item => {
          (<any>item).value = null;
          return item;
        });
      }
      this.irfPullPushForm.patchValue({status:"Pending"});
      // this.irfPullPushForm.patchValue({companyNameSearchValue:null});
      this.getPushGridList();
    }
    
    this.unSelectHeaderCheckbox();
  }

  onBackClick() {
    this._location.back();
  }

  setListData(sfCaseDetails: Array<any>) {
    
    this.invoiceDataList = sfCaseDetails;
  }
  addSfBillData(irfBillData: any, isChecked: boolean) {
    let sfBillStatus = irfBillData.status;
    if (sfBillStatus == 'Pending' || sfBillStatus == 'Error' || sfBillStatus == 'WIP') {
      const index = this.irfBillIdList.indexOf(irfBillData);
      if (index === -1) {

          // irfBilld is not in the selectedIds array, add it
          if(isChecked){
            this.irfBillIdList.push(irfBillData);
            this.casesSelected += 1;
            
          }else if(!isChecked){
            this.irfBillIdList.splice(index, 1);
            if(this.casesSelected > 0){
              this.casesSelected -= 1;
            }
          }
        
          if(irfBillData){
            let querySelectorData = "#checkboxRef_" + irfBillData?.invoiceNumber + " " + `input[type="checkbox"]`
            let checkboxEle = <HTMLInputElement>document.querySelector(querySelectorData);
  
            if (checkboxEle) {
              let selectAllCheckbox = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box') as HTMLElement;
              let selectAllCheckboxIcon = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box span');
  
              if (checkboxEle.checked == true) {
                selectAllCheckbox.click();
              }
            }
          }
        // }else{
        // }
      } else {
        // irfBilld is already in the selectedIds array, remove it
        if(!isChecked){
          this.irfBillIdList.splice(index, 1);
          if(this.casesSelected > 0){
            this.casesSelected -= 1;
          }
        }
      }

      if (this.irfBillIdList.length > 0) {
        this.disableProcessBtn = false;
      } else {
        this.disableProcessBtn = true;
      }
    }
    
    if(irfBillData.status === "Error"){
      let rowComment = document.getElementById("selectedComment"+irfBillData.id) as HTMLTextAreaElement;
      console.log(rowComment.disabled);
      if(rowComment.disabled == false){
        rowComment.disabled = true;
      }else
      rowComment.disabled=false
      
    }
  }

  trackByFn(index: number, item: any): any {
    
    return item.id; // Use a unique identifier for tracking
  }
  
  
  check(e,index){
    console.log(e,index);
    
    // let rowComment = document.getElementById("selectedComment"+index.id) as HTMLTextAreaElement;
    // rowComment.value = rowComment.value;
    
  }
  
  processInvoice() {
    this.disableProcessBtn = true;
    let processCreateInvoiceData = [];
    let processCreditMemoData = [];
    let irffInvoiceSet = new Set();
    irffInvoiceSet.add(this.invoiceDataList?.map(data_ => 
      ({
        invNumber: data_?.invoiceNumber,
        id:data_?.id,
        updatedComment: data_?.reprocessComment
      })
    ));
    this.gridLoader = true;
    console.log(irffInvoiceSet);
    
    
    this.selectIRFBillIdList = this.irfBillIdList;
    this.irfBillIdList.forEach(data => {
      let dynamicId = "selectedComment"+data.id
      let rowId = document.getElementById(dynamicId) as HTMLTextAreaElement;
      if(rowId?.value)
      rowId.innerHTML = rowId?.value;
      
      this.checkInvoiceNumber = data.invoiceNumber;
      const transationType = data.transationType;

      if(data.customerTransactionType.includes('RR')){
        this.checkFormat(data);
      }else{
        this.statusCheckForINVNo = true;
      }

      if(this.statusCheckForINVNo){
        if(data.status === "Pending" || data.status === "WIP"){
          if(transationType === "CM"){
            let invoiceData = {
              "selectedInvoiceId": data.invoiceNumber,
              "reprocessComment": rowId?.value || null
            }
            processCreditMemoData.push(invoiceData);
          } else{
            let invoiceData = {
              "selectedInvoiceId": data.invoiceNumber,
              "reprocessComment": rowId?.value || null
            }
            processCreateInvoiceData.push(invoiceData);
          }
          this.statusCheckForINVNo = false;
        }else if(data.status === "Error" || rowId?.value != null){
          if(rowId?.value === ""){
            if(this.showErrorCaseCommentInputModal === true){
              this.showErrorCaseCommentInputModal = true;
            }else{
              this.showErrorCaseCommentInputModal = !this.showErrorCaseCommentInputModal;
            }
            this.errorCaseComment = "Re-process comment is mandatory to process Error cases.";
            this.disableProcessBtn = false;
            this.gridLoader = false;
          }else if(rowId?.value.length < 5){
            if(this.showErrorCaseCommentInputModal === true){
              this.showErrorCaseCommentInputModal = true;
            }else{
              this.showErrorCaseCommentInputModal = !this.showErrorCaseCommentInputModal;
            }
            this.errorCaseComment = "Re-process comment needs to be minimum 5 charaters to process Error cases.";
            this.disableProcessBtn = false; 
            this.gridLoader = false;
          }else{
            if(transationType === "CM"){
              let invoiceData = {
                "selectedInvoiceId": data.invoiceNumber,
                "reprocessComment": rowId?.value || null
              }
              processCreditMemoData.push(invoiceData);
            } else{
              let invoiceData = {
                "selectedInvoiceId": data.invoiceNumber,
                "reprocessComment": rowId?.value || null
              }
              processCreateInvoiceData.push(invoiceData);
            }
            this.statusCheckForINVNo = false;
          }
        }
      }
      
    }); 

    if(processCreateInvoiceData?.length>0){
      this.pagesService.pushInvoiceIrfPushPull(processCreateInvoiceData, "createinvoice").subscribe((results: any) => {
        console.log(results);
        
        this.getPushGridList(null,null,this.irfPullPushForm?.value?.status,null);
        this.createInvoiceDataList(this.selectIRFBillIdList);
        
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Data Processed'});
        this.irfBillDataList = [];
        this.irfBillIdList = [];
        this.casesSelected = 0;
        setTimeout(() => {
          this.getPushGridList();
          this.getCountStatusGrid();
          this.messageService.clear();
          this.gridLoader = false;
          this.disableProcessBtn = true;
          this.unSelectHeaderCheckbox();
        }, 4000);
      },(_error) => {
        this.gridLoader = false;
        this.apiFail = true;
      });
      
    }

    if(processCreditMemoData?.length>0){
      this.pagesService.pushInvoiceIrfPushPull(processCreditMemoData, "creditmemo").subscribe((results: any) => {
        console.log(results);
        this.getPushGridList(null,null,this.irfPullPushForm?.value?.status,null);
        this.createInvoiceDataList(this.selectIRFBillIdList);
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Data Processed'});
        this.irfBillDataList = [];
        this.irfBillIdList = [];
        this.casesSelected = 0;
        setTimeout(() => {
          this.getPushGridList();
          this.getCountStatusGrid();
          this.messageService.clear();
          this.gridLoader = false;
          this.disableProcessBtn = true;
          this.unSelectHeaderCheckbox();
        }, 4000);
      },(_error) => {
        this.gridLoader = false;
        this.apiFail = true;
      });
    }
    this.fetchProcessedCases();
  }

  checkFormat(selectedItem: any) {
    let fetchedValue;
    fetchedValue = this.checkStatusForList.find((invoice) => invoice.irfBillId === selectedItem.irfBillId && invoice.transationType === selectedItem.transationType);
    if(fetchedValue != undefined && fetchedValue.customerTransactionType.includes("ADV")){
      this.statusCheckForINVNo = true; 
      this.unSelectHeaderCheckbox();
    }else{
      this.statusCheckForINVNo = false;
      this.disableProcessBtn = false;
      this.unSelectHeaderCheckbox();
      this.statusCheckForINVNoShowModal = true;
      this.statusCheckForINVNoMsg = "Agreement ID "+selectedItem.agreementId+" cannot be pushed, as CRISIL_ADV_MH is still not processed.";
    }
  }

  selectAllRows() {
    if (this.irfBillDataList != null && this.irfBillDataList.length != 0 && this.irfBillDataList != undefined) {
      this.irfBillIdList = this.irfBillDataList;
      this.casesSelected = this.irfBillIdList.length;
    } else {
      this.irfBillDataList = [];
      this.irfBillIdList = [];
      this.casesSelected = 0;
    }
    if (this.irfBillIdList != null && this.irfBillIdList.length != 0 && this.irfBillIdList != undefined) {
      this.disableProcessBtn = false;
    } else {
      this.disableProcessBtn = true;
    }
  }

  unSelectHeaderCheckbox() {
    
    this.invoiceDataList.forEach(data => {
      let querySelectorData = "#checkboxRef_" + data?.invoiceNumber + " " + `input[type="checkbox"]`
      let checkboxEle = <HTMLInputElement>document.querySelector(querySelectorData);

      if (checkboxEle) {
        let selectAllCheckbox = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box') as HTMLElement;
        if (checkboxEle.checked == true) {
          selectAllCheckbox.click();
        }
      }
    });
    this.irfBillDataList = [];
    this.irfBillIdList = [];
    this.casesSelected = 0;
    let querySelectorData = document.getElementsByClassName("mainheaderCheckbox");
    for(let i =0 ; i< querySelectorData.length; i++){
      let selectAllCheckbox = querySelectorData[i].querySelector('.p-checkbox-box') as HTMLElement;
      if(selectAllCheckbox.getAttribute("aria-checked") === "true"){
        selectAllCheckbox.click();
      }
    }
    
  }

  getPushGridList(startDate?: string, endDate?: string, status?: string, companyCode?: string) {
    status = this.irfPullPushForm?.value?.status;

    if (status !== null || (startDate !== null && endDate !== null && status !== null)) {
      if (status !== null) {
        this.apiFailStatusSelection = false;
        this.gridLoader = true;
        this.pagesService.getIrfPushPullGridListData(startDate, endDate, status, companyCode).subscribe({
          next: (irfCaseDetails: Array<any>) => {
            this.setListData(irfCaseDetails);
            this.casesSelected = 0;
          },
          complete: () => {
            this.gridLoader = false;
            this.apiFail = false;
            this.getCountStatusGrid();            
            
          },
          error: (err) => {
            this.gridLoader = false;
            this.apiFail = true;
          }
        })
      }

      // return this.http.get(this.apiUrl, { params: params });
    } else {
      if(status === null || (startDate === null && endDate === null && status === null)){
        this.apiFailStatusSelection = false;
      }else{
        this.apiFailStatusSelection = true;
        this.onClearGlobalDate();
      }
    }
  }

  onClearSearch(): void {
    this.getPushGridList();
  }

  filterGlobalTableByDate() {
    const startTime = this.irfPullPushForm?.value?.globalCalendar[0]?.split("/").reverse().join("-");
    const endTime = this.irfPullPushForm?.value?.globalCalendar[1]?.split("/").reverse().join("-");
    if (this.globalEndTime) {
      this.globalStartTime = startTime;
      this.globalEndTime = undefined;
      this.datefilterSelected = true;
      
    } else {
      this.globalStartTime = startTime;
      this.globalEndTime = endTime;
      if(this.globalEndTime){
        this.datefilterSelected = false;
      }else{
        this.datefilterSelected = true;
      }
      
    }
    if (this.globalStartTime && this.globalEndTime) {
      this.globalCalendar.overlayVisible = false;
      // this.irfPullPushForm.patchValue({ companyNameSearchValue: null });
      this.queryirfBillId = null;
      this.showCross = false;
      this.showEntityLoader = false;
      this.getPushGridList(this.globalStartTime, this.globalEndTime, this.irfPullPushForm?.value?.status);
    }
  }

  onClearGlobalDate() {
    this.globalEndTime = undefined;
    this.globalStartTime = undefined;
    this.irfPullPushForm.patchValue({ globalCalendar: null });
    if (this.globalStartTime && this.globalEndTime) {
      this.getPushGridList(this.globalStartTime,this.globalEndTime);
    }
    this.datefilterSelected = true;
  }

  openDetailsPopup(rowData: any) {
    this.detailsPopUp = this.dialogService.open(QcDetailsComponent, {
      contentStyle: { "height": "100vh", "overflow": "hidden", "padding-top" : "0" },
      closeOnEscape: false,
      closable: true,
      data: rowData,
      width: '90%',
      header: rowData.companyName
    });
    this.detailsPopUp.onClose.subscribe((response: any) => {
      let selectedCase = response;

      if (selectedCase) {

        let querySelectorData = "#checkboxRef_" + selectedCase?.invoiceNumber + " " + `input[type="checkbox"]`
        let checkboxEle = <HTMLInputElement>document.querySelector(querySelectorData);
        if (checkboxEle) {
          let selectAllCheckbox = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box') as HTMLElement;
          let selectAllCheckboxIcon = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box span');

          if (checkboxEle.checked == false) {
            selectAllCheckbox.click();
          }
        }
      }
    });
  }

  createInvoiceDataList(selectIRFBillIdList:any[]){
    this.invoiceDataList.forEach((invoice) => {
      let index = selectIRFBillIdList.findIndex(s => s.invoiceNumber == invoice.invoiceNumber);
      if(index != -1){
        invoice.status = "WIP";
      }
    });
    this.setListData(this.invoiceDataList);
    if (this.irfPullPushForm.get('status').value !== null) {  // 2 - default table filter on load of screen
      this.mainGrid.first.filter(this.irfPullPushForm.get('status').value, 'status', 'equals');
    }
  }

  showErrorMsg(data:any){
    if(data?.errorStatus != null && data?.errorStatus != '' && data?.errorStatus != undefined){
      let invoiceDetailsId = "tooltipOpp_"+data.invoiceNumber;
      document.getElementById(invoiceDetailsId)?.setAttribute('data-tooltip', data?.errorStatus);
      this.renderer.addClass(document.body, 'overflow-scroll');
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
  }

  exportExcel() {
    let excelpayload = {
      startDate : this.globalStartTime,
      endDate : this.globalEndTime,
    };
    let fileName = 'IRF_Push_Pull_List.csv';
    this.pagesService.getExportIrfPushPull(excelpayload).subscribe((res) => {
      /** src = `data:application/vnd.ms-excel;base64,${res.base64}`;
      const link = document.createElement('a');
      link.href = src;
      link.download = fileName;
      link.click(); **/
      const byteCharacters = atob(res.base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "text/csv" });
      FileSaver.saveAs(blob, fileName);
    });
  }

  checkStatus(e){
    this.selectedStatus = e;
    this.disableProcessBtn = true;
    this.casesSelected = 0;
    console.log(this.companyNameSearchValue);
    
    if(this.companyNameSearchValue !== undefined){
      this.applyCompanySearch()
    }else {
      this.getPushGridList(this.globalStartTime, this.globalEndTime, this.selectedStatus);
    }
  }

  getCountStatusGrid(){
    this.pagesService.getStatusCounts().subscribe((results: any) => {
      this.statusCount = results;
      this.statusCountRawData = results;
      if(this.statusCountRawData){
        this.statusCountRawData.forEach((item:any) => {
          const customerTransactionType = item.customerTransactionType?.replace(/\s/g,'');
          const status = item.status;
          const count = item.count;
          console.log("This.statustransformeddata",customerTransactionType,status,count );
          if(!this.statusCountTransformedData[customerTransactionType]){
            this.statusCountTransformedData[customerTransactionType] = {};
          }
          this.statusCountTransformedData[customerTransactionType][status] = count;
          this.testStatuscount += 1 ;
        });
        console.log("This.statustransformeddata", this.statusCountTransformedData);
      }
    });
    
  }

  openMandate(opID){
    this.pagesService.getIrfMandateAttachments('37282').subscribe({
      next:(data:any)=>{
        this.mandate = data;
      },
      error:(err)=>{
      }
    })
  }

  isPdf: boolean = true;
  showAttachmentPreview(item) {
    this.openedFile = item;
    let ext = item.fileName?.split('.')[1];
    if (ext.includes('msg')) {
      return;
    }
    this.zoomValue = 1;
    this.rotate = 0;
    // if (item?.fileId) {
    if (item?.fileTypeid) {
      this.displayPreview = true;
      this.dataLoader = true;
      if (item && item?.file) {
        let ext = item?.fileName?.split('.');
        let ext1 = ext[ext.length - 1];
        let mime: any;
        if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
          this.isPdf = false;
          mime = 'data:image/png;base64,'
        } else {
          this.isPdf = true;
          mime = 'data:application/pdf;base64,'
        }
        this.urlSafe = `${mime}${item.file}`
        if (!this.isPdf) {
          let el = document.getElementById('imagePrev');
          setTimeout(() => {
            el.style.transform = 'rotate(0deg) scale(1)';
          }, 100);
        }
        this.dataLoader = false;
      } else {
        this.displayPreview = false;
        this.previewFailed = true;
      }
    } else {
      let tempArray: any = [];
      if (item.fileContentType == "application/pdf") {
        this.displayPreview = true;
        this.dataLoader = true;
        let data = '';
        if (item.file.startsWith('data:application/pdf;base64')) {
          data = item.file;
        } else {
          data = `data:application/pdf;base64,${item.file}`;
        }
        this.urlSafe = data;
        this.isPdf = true;
        this.dataLoader = false;
      } else {
        let obj: any = {};
        if (item.fileContentType.startsWith('application/vnd') || item.fileContentType == 'application/msword') {
          obj = {
            fileType: 'doc',
            fileName: item.fileName,
            attachmentType: item.fileTypeName,
            fileData: item.file.split(',')[1]
          }
        } else {
          obj = {
            fileType: item.fileContentType.split('/')[1],
            fileName: item.fileName,
            attachmentType: item.fileTypeName,
            fileData: item.file
          }
        }
        tempArray.push(obj);
        this.displayPreview = true;
        this.dataLoader = true;
      }
    }
  }
  processFile(opID) {
    let fetchedMandate;
      this.pagesService.getIrfMandateAttachments(opID).subscribe((res) => {
        // this.pagesService.getIrfMandateAttachments('37295').subscribe((res) => {
          fetchedMandate =res;
        if(fetchedMandate.length != 0){
          //conditionig to always check for filetypeid for 41 first 'Original Mandate'
          const fileType41 = res.find(fileType => fileType.fileTypeid === '41');
  
          if (fileType41) {
            this.showAttachmentPreview(fileType41);
          } else {
            // Perform action for fileTypeid '40' if '41' is not present
            const fileType40 = res.find(fileType => fileType.fileTypeid === '40');
            
            if (fileType40) {
              this.showAttachmentPreview(fileType40);
            } else {
            }
          }
        }else{
          this.messageService.add({severity: 'warn', summary: 'There are no Mandate Attachments available'});
        setTimeout(() => {
          this.messageService.clear();
        }, 4000); 
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      })
  }

  rotate: number = 0
  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
  }

  downloadFile() {
    let linkSource: any;
    if (this.openedFile?.fileContentType?.includes('image')) {
      let mime = this.openedFile?.file?.split(',')[0];
      linkSource = `${mime},${this.urlSafe?.split(',')[1]}`;
    } else {
      linkSource = this.urlSafe;
    }
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this?.openedFile?.fileName.split('.')[0];
    downloadLink.click();
  }

  onTextChange(row: any) {
    // Perform action when text changes
  }

  onBlur(row: any) {
    // Perform action when the textarea loses focus
  }
  
  showSearchCompanyPopUp() {
    if(!this.gridLoader && !this.loadingStatus)
      this.searchCompanyPopUp = true;
  }

  searchCompanyPopupClosed() {
    this.searchCompanyPopUp = false;
  }

  applyCompanySearch() {
    this.pagesService.getIrfPushPullGridListData(this.globalStartTime, this.globalEndTime, null, this.companyNameSearchValue?.companyCode).subscribe({
      next: (irfCaseDetails: Array<any>) => {
    
        this.setListData(irfCaseDetails);
        this.gridLoader = false;
        this.apiFail = false;            
      },
      error: (err) => {
        this.gridLoader = false;
        this.apiFail = true;
      }
    })
    this.searchCompanyPopUp = false;
  }


  onChangeCompanySelect(e:any): void {
    console.log("event",e);
    this.showCross = true;
  }

  loadCompanyList(event: any): any {
    if (event.query.length >= 3) {
      this.showEntityLoader = true;
      let payload = {
        "companyName": event.query
      }
      this.pagesService.getGlobalSearchCompanyResults(payload).subscribe(
        (results: any) => {
          const responseData = results;
          this.showEntityLoader = false;
          this.companyNameList = [];
          if (responseData && responseData.length > 0) {
            this.companyNameList = responseData;
          } else {
            this.companyNameList = [];
            this.emptyMsgFlag = true;
          }
        }, (_err) => {
          this.companyNameList = []
          this.showEntityLoader = false;
          this.emptyMsgFlag = true;
        });
    } else {
      this.companyNameList = [];
    }
  }

  resetCompanySearch() {
    this.getPushGridList(this.globalStartTime, this.globalEndTime, "Pending", null);
    this.companyNameSearchValue = undefined;
    this.searchCompanyPopUp = false;
  }

  filteredInvoicedateData: any[];
  checkdateFilter(event, field){
    console.log("filteredDate",event,field);
    const filteredValue = event;
    const formatfilteredValue = this.datepipe.transform(filteredValue, 'yyyy-MM-dd');
    
    this.filteredInvoicedateData = this.invoiceDataList.filter(item => {
      if(formatfilteredValue){
        const date = new Date(item.invoiceDate);
        return date.toISOString().includes(formatfilteredValue);
      }
      return true;
    })
    console.log("filteredDate", this.filteredInvoicedateData);
    
  }

  onClearColumnFilterDate(){
    this.filteredInvoicedateData = undefined;
    let status = this.irfPullPushForm?.value?.status;
    this.getPushGridList(this.globalStartTime, this.globalEndTime, status, null);
  }

  hideMaximumSelectionPopup(){
    this.showMaximumSelectionPopup = !this.showMaximumSelectionPopup;
  }

  hideErrorCaseCommentInputModal(){
    this.showErrorCaseCommentInputModal = !this.showErrorCaseCommentInputModal;
  }

  fetchProcessedCases(){
    if(this.companyNameSearchValue?.companyCode != null)
    this.pagesService.getIrfPushPullGridListData(null, null, null, this.companyNameSearchValue?.companyCode).subscribe((irfCaseDetails: Array<any>) => {
      this.checkStatusForList = irfCaseDetails;
    });
    else
    this.pagesService.getIrfPushPullGridListData(null, null, "Processed", null).subscribe((irfCaseDetails: Array<any>) => {
      this.checkStatusForList = irfCaseDetails;
    });
  }
}
