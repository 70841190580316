import { HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BACKOFFICE_SERVER_API_URL, NEXERA_SERVER_API_URL, SERVER_API_INTEGRATION_URL, IRFREALIZATION_URL, SFREALIZATION_URL } from 'app/app.constants';
import { PagesService } from 'app/pages/pages.service';
import { BYPASS_LOADER } from 'app/blocks/interceptor/request-loader.interceptor';
import { Observable, of } from 'rxjs';
import * as FileSaver from 'file-saver';
import { switchMap } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class IRFRealizationAPIService extends PagesService {
  
    payStatusList(): Observable<any> {
        return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/PaymentFinStatus');
    }

    payDetails(payload): Observable<any> {
        return this.http.post(IRFREALIZATION_URL + `/findByPaymentId`, payload);
    }

    getUserHierarchy(payload): Observable<any> {
        const encPayload = this.encDec.encryptWith(payload);
        return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/findByEmployeeId?employeeId=${encPayload}`);
    }

    sendOta(payload): Observable<any> {
       return this.http.post(SFREALIZATION_URL + '/otp/generate', payload);
    }

    cancelOta(payload): Observable<any> {
        return this.http.post(IRFREALIZATION_URL + '/ota/cancel', payload);
    }

    validateOta(payload): Observable<any> {
        return this.http.post(IRFREALIZATION_URL + '/ota/validate', payload);
    }


    getIRFRealizationGridList(startDate: String, endDate: String, status: Number){
        let payload = {
          startDate : startDate || null, endDate : endDate || null, statusId : status || null
        };
        return this.http.post(IRFREALIZATION_URL + '/findAll', payload);
    }

    importIrfRealizationExcel(payload: any): Observable<any> {
        return this.http.post(IRFREALIZATION_URL + '/import', payload, { headers: new HttpHeaders(), context: new HttpContext().set(BYPASS_LOADER, true) });
    }

    irfRealizationExport(payload: any, fileName: string): Observable<any> {
      console.log(payload, 'pppp')
        const headersList = new HttpHeaders();
        return this.exportIrfExcelUtil(
            IRFREALIZATION_URL + '/exportIrfRealization',
            payload,
            { headers: headersList, context: new HttpContext().set(BYPASS_LOADER, true) },
            fileName
        );
    }

    exportIrfExcelUtil(url : string, payload : any, headers, fileName : string) {
        return this.http.post(url, payload, headers).pipe(switchMap((res: any) => {
          let isFileDownloadable = this.downloadIrfExcel(res.base64, fileName);
          return of(isFileDownloadable || undefined);
        }));
      }

    downloadIrfExcel(base64: string, fileName: string) {
        if(base64 == null) {
          return false;
        }
    
        const byteCharacters = atob(base64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "text/csv" });
          FileSaver.saveAs(blob, fileName);
        return true;
      }
}


