<div class="entityDetailsModal">
    <p-messages></p-messages>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                <div class="ui-fluid p-formgrid p-grid p-mb-2 entityDetailsHeader">
                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left">
                                        <div class="p-d-flex"> <label class="p-mr-2 label_light">Opportunity ID
                                            :</label><h6>{{reportingData?.opportunityId}}</h6>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left">
                                        <div class="p-d-flex"> <label class="p-mr-2 label_light">Opportunity Status
                                            :</label><h6>{{reportingData?.opportunityStatus}}</h6>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left">
                                        <div class="p-d-flex"> <label class="p-mr-2 label_light">Date:</label>
                                            <h6>{{reportingData?.lastMeetingDate}}</h6>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left">
                                        <div class="p-d-flex"> <label class="p-mr-2 label_light">CRM Code
                                            :</label><h6>{{reportingData?.crmCompanyCode}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <backoffice-reporting-coa (COAData)="dataFetched($event)"></backoffice-reporting-coa>
                        <ng-container *ngIf="COARoleFlag">
                        </ng-container> 
                    </div>
                </div>
            </div>
        </div>
</div>
