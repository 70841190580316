<header>

    <nav class="navbar navbar-expand mb-1 mt-0">
        <div class="container-fluid">
            <a class="navbar-brand logo sub-logo" routerLink="/" (click)="collapseNavbar()">
                <span class="logo-img"></span>
                <span jhiTranslate="global.title" class="navbar-title">nexera</span>
            </a>
            <div class="navbar-collapse collapse" id="navbarResponsive" [ngbCollapse]="isNavbarCollapsed" [ngSwitch]="isAuthenticated()">
                <ul class="navbar-nav ml-auto">
                    <li ngbDropdown class="nav-item dropdown pointer theme-switch-wrapper" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <input type="checkbox" (change)="switchTheme($event)" class="checkbox" id="checkbox" />
                        <label for="checkbox" class="label mt-2 mb-0">
                            <i class="fas fa-moon" aria-hidden="true"></i>
                            <i class="fas fa-sun" aria-hidden="true"></i>
                            <div class="ball"></div>
                        </label>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a class="nav-link" routerLink="/" (click)="collapseNavbar()">
                            <span>
                                <span>Home</span>
                            </span>
                        </a>
                    </li>
                    <li ngbDropdown class="nav-item dropdown pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="NexEra-menu">
                            <span>
                                <span>
                                    NexEra
                                </span>
                            </span>
                        </a>
                        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="NexEra-menu">
                            <li>
                                <a class="dropdown-item" routerLink="/pages/meetings" routerLinkActive="active" (click)="collapseNavbar()" id="Meetings">
                                    <span>Meetings</span>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/pages/opportunitylist" routerLinkActive="active" (click)="collapseNavbar()" id="OpportunityList">
                                    <span>Opportunity List</span>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/pages/createentity" routerLinkActive="active" (click)="collapseNavbar()" id="CreateEntity">
                                    <span>Create Entity</span>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/pages/meetinglist" routerLinkActive="active" (click)="collapseNavbar()" id="meetingList">
                                    <span>Meeting List</span>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/pages/createnewopportunity" routerLinkActive="active" (click)="collapseNavbar()" id="CreateNewOpportunity">
                                    <span>Create New Opportunity</span>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/pages/viewentity" routerLinkActive="active" (click)="collapseNavbar()" id="ViewEntity">
                                    <span>View Entity</span>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/searchentity" routerLinkActive="active" (click)="collapseNavbar()" id="SearchEntity">
                                    <span>Search Entity</span>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/pages/createmeeting" routerLinkActive="active" (click)="collapseNavbar()" id="CreateMeeting">
                                    <span>Create Meeting</span>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/pages/viewentitydetails" routerLinkActive="active" (click)="collapseNavbar()" id="viewentityDetails">
                                    <span>View Entity Details</span>
                                </a>
                            </li>

                        </ul>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a class="nav-link" routerLink="/pages/viewopportunitydetail" (click)="collapseNavbar()">
                            <span>
                                <span>View Opportunity Detail</span>
                            </span>
                        </a>
                    </li>
                    <li ngbDropdown class="nav-item dropdown pointer" display="dynamic" *ngIf="languages && languages.length > 1">
                        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="languagesnavBarDropdown">
                            <span>
                                <fa-icon icon="flag"></fa-icon>
                                <span jhiTranslate="global.menu.language">Language</span>
                            </span>
                        </a>
                        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="languagesnavBarDropdown">
                            <li *ngFor="let language of languages">
                                <a class="dropdown-item" [jhiActiveMenu]="language" href="javascript:void(0);" (click)="changeLanguage(language);collapseNavbar();">{{ language | findLanguageFromKey }}</a>
                            </li>
                        </ul>
                    </li>
                    <li ngbDropdown class="nav-item dropdown pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                            <span>
                                <span>
                                    {{userName}}
                                </span>
                            </span>
                        </a>
                        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="account-menu">
                            <li *ngSwitchCase="true">
                                <a class="dropdown-item" (click)="logout()" id="logout">
                                    <span>Sign out</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>