import { ChangeDetectorRef, Component, OnInit, ViewChildren } from '@angular/core';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { QcDetailsComponent } from '../qc-details/qc-details.component';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { FormBuilder } from '@angular/forms';
import { Table } from 'primeng/table/table';
import { QcService } from '../qc.service';

@Component({
  selector: 'backoffice-qc-additionaldoc-listing',
  templateUrl: './qc-additionaldoc-listing.component.html',
  styleUrls: ['./qc-additionaldoc-listing.component.scss'],
  providers: [DialogService, MessageService]
})
export class QcAdditionaldocListingComponent implements OnInit {

  qcDetailsDialog: DynamicDialogRef = new DynamicDialogRef;

  // breadcrumb variables - start
  ccprimebreadcrumbList = [
    { label: `Updated Documents`, routerLink: ['/qc/listing/updated-doc'] },
  ];
  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  // breadcrumb variables - end

  // grid related variables - start
  @ViewChildren('mainGrid') mainGrid;

  qcForm = this.fb.group({
    entityNameFilter: [null, []],
    nexeraUserFilter: [null, []],
    qcStatus: [null, []],
    clientTypeFilter:[null,[]],
    opportunityStatusFilter: [null as any,[]],
    businessTypeFilter:[null,[]],
    productFilter:[null,[]],
    crrProductFilter:[null,[]],
    quantumFilter:[null,[]],
    applicableAmountFilter:[null,[]],
    actualIrfFeeFilter:[null,[]],
    bdUserFilter:[null,[]],
    bdManagerFilter:[null,[]],
    opsInitiatorFilter:[null,[]]
  });
  // grid related variables - end

  // loader variables
  firstLoad = false;
  apiFail = false;
  gridLoader = false;
  loadingStatus = false; 

  // grid related variables - end

  gridDataList:any[] = [];
  clientTypeList: any;
  qcStatusList: Array<any>;
  opportunityStatusList: any[] = [{name:"Initiated"},{name:"Case Closed"}];
  businessTypeList: any;
  productList: any;
  crrProductList:any;
  quantumList:any;
  applicableAmountList:any;
  actualIrfFeeList:any;
  bdUserList:any;
  bdManagerList:any;
  opsInitiatorList:any;
  docStatusList:any;
  docPaymentList:any;


  // search company popup -
  // auto complete company name related variables
  searchCompanyPopUp = false;
  showCross: boolean = false;
  showEntityLoader: boolean = false;
  emptyMsgVal: string = 'No Company Found';
  emptyMsgFlag: boolean = false;
  companyNameList = [];
  companyNameSearchValue : any;
  filterDisplay = true;
  filterDisplayRow = true;
  invoiceBeforePaymentChecked:boolean;
  qcStatus:any;
  opportunityStatus:any;
  priorityForQc:any;

  showFailureModel: boolean = false;
  errorMessage: string = "";
  companyName:any = null;
  invoiceBeforePayment = "No";

  constructor(private dialogService: DialogService, private messageService: MessageService, private encyDyc: EncryptDecryptInterceptor, private fb: FormBuilder,private cd: ChangeDetectorRef,private qcService:QcService) { }

  ngOnInit(): void {
    this.opportunityStatus = "Initiated";
    let opportunityStatus = {name:"Initiated"};
    this.qcForm.patchValue({opportunityStatusFilter:opportunityStatus.name});
    this.getGridList(this.invoiceBeforePayment,this.opportunityStatus,this.companyName);
  }

  // breadrumb related method
  homeBreadcrumbClicked(event) {
    if(event.item?.icon == 'pi pi-home'){
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  showSearchCompanyPopUp() {
    if(!this.gridLoader && !this.loadingStatus)
      this.searchCompanyPopUp = true;
  }

  searchCompanyPopupClosed() {
    this.searchCompanyPopUp = false;
  }

  onChangeCompanySelect(event) {
    console.log("Event :: ", event);
    
  }

  resetCompanySearch() {
    this.companyName = null;
    this.getGridList(this.invoiceBeforePayment,this.opportunityStatus,this.companyName);
    this.companyNameSearchValue = undefined;
    this.searchCompanyPopUp = false;
  }

  applyCompanySearch() {

    console.log("company name search data... ",this.companyNameSearchValue);
    this.companyName = this.companyNameSearchValue?.entityName;
    this.getGridList(this.invoiceBeforePayment,this.opportunityStatus,this.companyName);
    this.searchCompanyPopUp = false;
  }

  onQcBackBtn(){
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  getQcDetails(qcDetails : any) {
    this.qcDetailsDialog = this.dialogService.open(QcDetailsComponent, {
      contentStyle: { "height": "100vh", "overflow": "hidden", "padding-top" : "0" },
      closeOnEscape: false,
      closable: true,
      data: {
        additionalScreen: true,
        highPriorityChecked :false, 
        qcDetails : {...qcDetails, qcStatus : "QC Approved"}
      },
      width: '96%',
      header: qcDetails.entityName,
    });

    this.qcDetailsDialog.onClose.subscribe((closeResponse: any) => {
      console.log("closeRes :: ", closeResponse);
      if(closeResponse == "error") {
        this.messageService.add({ severity: 'error', detail: 'Unable to save data, please try again!'});
      } else if(closeResponse?.msg == "success"){
          // refresh grid on succession
          this.messageService.add({ severity: 'success', summary: `Case is ${closeResponse?.caseStatus || "saved"} successfully`});
          this.getGridList(this.invoiceBeforePayment,this.opportunityStatus,this.companyName);
      }
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    
    });
  }

  setGridItem(item) {

    this.gridDataList = <[]>item;
    this.clientTypeList = new Set();
    this.productList = new Set();
    this.crrProductList = new Set();
    this.actualIrfFeeList = new Set();
    this.docStatusList = new Set();
    this.docPaymentList = new Set();
    this.quantumList = new Set();

    this.gridDataList.forEach(item => {
      if(item.clientClassification){
        this.clientTypeList.add(item.clientClassification);
        item.clientTypeByList = { name: item.clientClassification };
      }
      
      if(item.product){
        this.productList.add(item.product);
        item.productByList = { name: item.product };
      }
      
      if(item.crrProduct){
        this.crrProductList.add(item.crrProduct);
        item.crrProductByList = { name: item.crrProduct };  
      }
      
      if(item.quantum){
        this.quantumList.add(item.quantum);
        item.quantumByList = { name: item.quantum };
      }
      
      if(item.actualIrfFee){
        this.actualIrfFeeList.add(item.actualIrfFee);
        item.actualIrfFeeByList = { name: item.actualIrfFee };
      }
      
      if(item.afterQcMandateDocStatus){
        this.docStatusList.add(item.afterQcMandateDocStatus);
        item.docStatusList = { name: item.afterQcMandateDocStatus };
      }
      
      if(item.afterQcAdditionalPaymentStatus){
        this.docPaymentList.add(item.afterQcAdditionalPaymentStatus);
        item.docPaymentList = { name: item.afterQcAdditionalPaymentStatus };
      }
      
    });

    this.clientTypeList = Array.from(this.clientTypeList).map(item => ({name: item}));
    this.productList = Array.from(this.productList).map(item => ({name: item}));
    this.crrProductList = Array.from(this.crrProductList).map(item => ({name: item}));
    this.quantumList = Array.from(this.quantumList).map(item => ({name: item}));
    this.actualIrfFeeList = Array.from(this.actualIrfFeeList).map(item => ({name: item}));
    this.docStatusList = Array.from(this.docStatusList).map(item => ({name: item}));
    this.docPaymentList = Array.from(this.docPaymentList).map(item => ({name: item}));

    this.gridLoader = false;
    this.loadingStatus = false;
    this.firstLoad = true;
  }

  clear(table : Table) {
    table.reset();
    this.cd.detectChanges();
    if(this.mainGrid?.first?.filters) { // reset all filters
      Object.values(this.mainGrid.first.filters).map(item => {
        (<any>item).value = null;
        return item;
      });
    }
    this.qcForm.reset();
    this.filterDisplayRow = false;
    this.filterDisplay = false;
    this.companyNameSearchValue = undefined;
    this.companyName = null;
    this.getGridList(this.invoiceBeforePayment,null,null);
    this.cd.detectChanges();   
  }

  showFilter(){
    this.filterDisplayRow = true;
    this.filterDisplay = true;
    this.opportunityStatus = "Initiated";
    let opportunityStatus = {name:"Initiated"};
    this.qcForm.patchValue({opportunityStatusFilter:opportunityStatus.name});
    this.getGridList(this.invoiceBeforePayment,this.opportunityStatus,this.companyName);
    this.cd.detectChanges();
  }

  showInvoiceBeforePaymentCases(event){
    this.invoiceBeforePaymentChecked = event.checked;
    if(this.invoiceBeforePaymentChecked){
      this.invoiceBeforePayment = "Yes";
      this.getGridList(this.invoiceBeforePayment,this.opportunityStatus,this.companyName);
    }

    if(!this.invoiceBeforePaymentChecked){
      this.invoiceBeforePayment = "No";
      this.getGridList(this.invoiceBeforePayment,this.opportunityStatus,this.companyName);
    }
  }

  getGridList(inoviceBeforePayment:any,oppStatus:any,companyName:any) {
    if(this.companyName){
      let payload = {"invoiceBeforePayment":inoviceBeforePayment,"oppStatus":oppStatus,"companyName":companyName};
      this.qcService.getQCDocCompanySearchResults(payload).subscribe((res)=>{
        this.setGridItem(res);  
      });
    } else{
      let payload = {"invoiceBeforePayment":inoviceBeforePayment,"oppStatus":oppStatus};
      this.qcService.findAllQcDocData(payload).subscribe((res)=>{
        console.log("Doc Listing Response is: ", res);
        this.setGridItem(res);
      });
    }
  }

  opportunityStatusFilter(event){
    this.opportunityStatus = event;
    this.getGridList(this.invoiceBeforePayment,this.opportunityStatus,this.companyName);
    console.log("Opporuntiy Filter Selected: ",event);
  }

  loadCompanyList(event){
    if(event?.query?.length >= 4){
      let payload = {"inoviceBeforePayment":"No","oppStatus":null,"companyName":event?.query};
      this.qcService.getQCDocCompanySearchResults(payload).subscribe((results:any)=>{
        const responseData = results;
        if (responseData && responseData.length > 0) {
          this.companyNameList = responseData;
        } else {
          this.companyNameList = [];
        }
      });
    }
  }

}
