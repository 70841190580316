<div class="border" *ngFor="let data of this.segregatedData ">
    <p-timeline [value]="data" layout="horizontal" align="top">
        <ng-template pTemplate="marker" let-event>
            <span class="custom-timeline-maker basic">
            </span>
        </ng-template>
        <ng-template pTemplate="content" let-event>
            <div class="qc-status-container" *ngIf="event.qcStatus">
                <label>QC Status:
                    <p-tag styleClass="p-mr-2 p-regular p-tag-md" severity="{{
                        event.qcStatus === 134 ? 'danger' :
                        event.qcStatus === 136 ? 'success':
                        event.qcStatus === 133 ? 'warning':
                        event.qcStatus === 135 ? 'primary': ''
                        }}" value="{{
                            event.qcStatus === 133 ? 'QC Pending' :
                            event.qcStatus === 134 ? 'QC Rejected' :
                            event.qcStatus === 136 ? 'QC Approved':
                            event.qcStatus === 135 ? 'QC Resubmitted': ''
                            }}" [rounded]="true">
                    </p-tag>
                </label>
            </div>
            <div class="qc-agreement-container" *ngIf="event.agreementId">
                <p class="p-mb-1 para-md">Actioned By: {{event.employeeName}}
                </p>
                <p class="p-mb-1 para-md">Time: {{event.actionedOn | date:'dd-MMM-yyyy, HH:mm' }}
                </p>
            </div>
        </ng-template>
    </p-timeline>
</div>
