import { Component, OnInit } from '@angular/core';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { reportingScreenSerive } from './reporting-screen.service';

@Component({
  selector: 'backoffice-reporting-screen',
  templateUrl: './reporting-screen.component.html',
  styleUrls: ['./reporting-screen.component.scss']
})
export class ReportingScreenComponent implements OnInit {
  COA_UserRole: any;
  COA_UserScope: string;
  scopeList: any;
  COARoleFlag: boolean=false;
  reportingData: any;
  OpportunityId: any;
  
  constructor(private encryptDecryptInterceptor : EncryptDecryptInterceptor, private pageService: reportingScreenSerive, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.OpportunityId = this.config.data.opportunityId;
    this.COA_UserRole = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('role'));
    this.COA_UserScope = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    this.scopeList = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    this.userScopePermission();
  }

  dataFetched(event){
    this.reportingData = event;
  }

  userScopePermission(): void {
    let scope: any = this.scopeList.split(",")
    if( (scope.indexOf('COA_EDIT') != -1) || (scope.indexOf('COA_READ') != -1)){
      this.COARoleFlag=true;
    }
  }

}
