import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { AccountService } from 'app/core/auth/account.service';
import { REPORTING_SERVER_API_URL, SERVER_API_URL, SERVER_API_INTEGRATION_URL, REPORTING_SERVER_API_URL_Entity, NEXERA_SERVER_API_URL } from 'app/app.constants';
import * as CryptoJS from 'crypto-js';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
@Injectable({ providedIn: 'root' })
export class reportingScreenSerive {
  key:any;
  iv:any;
    private userId : any;
    errorMessageOnSaveService: string;
    constructor(private accountService: AccountService,private http: HttpClient, private encDec : EncryptDecryptInterceptor) {
      this.key = CryptoJS.enc.Latin1.parse('Y3Jpc2lsQDIwMjE=');
    this.iv  = CryptoJS.enc.Latin1.parse('fedcba9876543210');
      this.accountService.identity().subscribe(account => {
        if (account) {
          this.userId = account.login;
        }
      });
    }    

    //Reporting

    getbusinessWonDataGridDataReportingScreenGrid(startDate?: any, endDate?: any): Observable<any> {
        const headersList = new HttpHeaders();
    return this.http.get( REPORTING_SERVER_API_URL + `/findAll`, {params: {startDate:startDate,endDate:endDate},headers: headersList});
    }
    gettableDataReportingScreenGrid(startDate?: any, endDate?: any): Observable<any> {
      const headersList = new HttpHeaders();
    return this.http.get(REPORTING_SERVER_API_URL +`/findAll`, {params: {startDate:startDate,endDate:endDate},headers: headersList});
    }
    onReportingScreenDetailsPageLoad(OpportunityId: any, isCOAUser: boolean): Observable<any> {
      const headersList = new HttpHeaders();
      return this.http.get(REPORTING_SERVER_API_URL+`/findById`, {params:{id:OpportunityId, isCOAUser: isCOAUser},headers: headersList});
    }
    getsalutationselectDataReportingScreenDetails(): Observable<any> {
      const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL+`api/v1/integration/getSingleMasterData/Salutation`, {headers: headersList});
    }
     
    getdesignationselectDataReportingScreenDetails(): Observable<any> {
      const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL+`api/v1/integration/getSingleMasterData/Designation`, {headers: headersList});
    }
    
    getGSTtypeselectDataReportingScreenDetails(): Observable<any> {
      const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL+`api/v1/integration/findDataByCombineMaster/GSTType`, {headers: headersList});
    }

    getpincodeDataReportingScreenDetails(data?: any): Observable<any> {
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findStateAndCityByPinCode/`+ data.pinCodetextField, {
        headers: headersList,
      });
    }

    getExistingpinCodetextFieldDataReportingScreenDetails(data?:any): Observable<any> {
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findStateAndCityByPinCode/${data.pinCodetextField}`, {
        headers: headersList,
      });
    }
    
    getpaymentModeDataReportingScreenDetails(): Observable<any> {
      const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/PaymentMode`, {headers: headersList});
    }
    
    getbankDataReportingScreenDetails(): Observable<any> {
      const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/Bank`, {headers: headersList});
    }
    
    getpaymenttypeDataReportingScreenDetails(): Observable<any> {
      const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL+`api/v1/integration/findDataByCombineMaster/PaymentType`, {headers: headersList});
    }

    getentityTypeDataCreateEntity(): Observable<any> {
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/CompanyType`, { headers: headersList });
  
    }

    getCompanyType(): Observable<any> {
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL+`api/v1/integration/findAllDTO`, { headers: headersList });
    }
    getbusinessTypeDataEditOpportunity(): Observable<any> {
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/BusinessType`, { headers: headersList });
    }

    getBusinessActionDataReportingScreenDetails():Observable<any> {
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/BusinessAction`, { headers: headersList });
    }
    getCostCenterDataReportingScreenDetails():Observable<any>{
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/CostCenter`, { headers: headersList });
   
    }
    getProductDataReportingScreenDetails():Observable<any>{
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/Products`, { headers: headersList });
    }

    getTrypartyDataReportingScreenDetails():Observable<any>{
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/TriPartyMandate`, { headers: headersList });
    }

    getReasonDataReportingScreenDetails():Observable<any>{
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/DiscountReason`, { headers: headersList });
    }

    getBusinessReportedReasonDataReportingScreenDetails():Observable<any>{
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/ReportedReason`, { headers: headersList });
    }

    getBusinessRejectedReasonDataReportingScreenDetails():Observable<any>{
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/RejectedReason`, { headers: headersList });
   
    }

    // getPaymentDetailsListDataReportingScreenDetails(id:any):Observable<any>{
    //   const headersList = new HttpHeaders();
    //   return this.http.get(REPORTING_SERVER_API_URL+`/findById`, {params:{id:id}, headers: headersList });
    // }

    onSaveBtnSubmitCreateReportingData(data: any): Observable<any> {
      return this.http.post(REPORTING_SERVER_API_URL+`/updateCOA`,data);
    }


    getAttachmentTypeMasterData():Observable<any>{
      const headersList = new HttpHeaders();
      return this.http.get(SERVER_API_INTEGRATION_URL+`api/v1/integration/getSingleMasterData/OpportunityAttachmentType`, { headers: headersList });
    }
    
    combineAllPDF(data: any): Observable<any> {
      return this.http.post(NEXERA_SERVER_API_URL + 'api/v1/file/mergefiles',data);
    }

    setMessageOnSaveError(msg){
      this.errorMessageOnSaveService = msg;
    }
    getMessageOnSaveError(){
      const res = this.errorMessageOnSaveService.startsWith(",");
      if(res){
        this.errorMessageOnSaveService = this.errorMessageOnSaveService.slice(1);
      }
      return this.errorMessageOnSaveService;
    }

    getDocList(): Observable<any>{
      return this.http.get(SERVER_API_INTEGRATION_URL+'api/v1/integration/getSingleMasterData/ReportingAttachmentType');
    }

    sendExcelIds(ids):Observable<any>{
      const headersList = new HttpHeaders();
      return this.http.post(SERVER_API_URL+`/api/v1/reporting/exportToExcel`, ids, { headers: headersList });
    }
    getStatusCompanyPresent(companyCode){
      const headersList = new HttpHeaders();
      return this.http.get(REPORTING_SERVER_API_URL_Entity + `/findByCRMCompanyCode`, {params:{companyCode:companyCode}, headers: headersList });
    }
    
}
