import { Directive, OnInit, ElementRef, Renderer2, Input, HostListener, ChangeDetectorRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[floatElement]',
})
export class FloatElementDirective implements OnInit, AfterViewInit {
  @Input() floatElement?: Element;
  parentDiv : Element;
  paymentDiv : Element;
  paymentDivWidth = 0;
  changes : any;
  applyChanges = false;
  isParentScrollable = false;

  constructor(private currElementRef: ElementRef, private renderer: Renderer2, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    //this creates the wrapping div
    const divElementForParent = this.renderer.createElement('div');
    const divElementForPayment = this.renderer.createElement('div');
    
    divElementForPayment.setAttribute("style", "margin-top : 0px;");
    
    const el = this.currElementRef.nativeElement; //this is the element to wrap
    const parent = el.parentNode; //this is the parent containing el
    this.renderer.insertBefore(parent, divElementForParent, el); //here we place static-div before el

    this.renderer.appendChild(divElementForPayment, el); //here we place el in div
    this.renderer.appendChild(divElementForParent, divElementForPayment);
    
    this.parentDiv = divElementForParent;
    this.paymentDiv = divElementForPayment;

    this.renderer.setStyle(this.parentDiv, 'min-height', `${this.currElementRef.nativeElement.clientHeight}px`);

  }

  ngAfterViewInit() {
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        if(this.currElementRef?.nativeElement?.children[0]?.children?.length === 3) { // calendar panel opened
          this.applyChanges = true;
          this.updateCalendarOverlay(true);
        } else {
          this.updateCalendarOverlay(false);
        }
      });
    });

    this.changes.observe(this.currElementRef?.nativeElement, {
      childList: true,
      subtree : true
    });

    this.paymentDivWidth = 0;
  }
  
  updateCalendarOverlay(isPanelOpen : boolean){
    if(this.applyChanges) {
      this.isParentScrollable = !!(this.floatElement?.clientHeight < this.floatElement?.scrollHeight);
      if(this.paymentDivWidth === 0) {
        // set initial styles on parent render
        this.paymentDivWidth = this.paymentDiv?.clientWidth;
        this.renderer.setStyle(this.paymentDiv, 'width', `${this.paymentDivWidth}px`);
        this.renderer.setStyle(this.parentDiv, 'min-height', `${this.currElementRef.nativeElement.offsetHeight}px`);      
        this.renderer.setStyle(this.parentDiv, 'position', `static`);
        this.paymentDiv.classList.add('payment-dropdown');
      }
      
      if(isPanelOpen) {
        // set some styles on panel open
        this.renderer.setStyle(this.paymentDiv, 'margin-top', `-${(<any>this.floatElement).scrollTop}px`);
        this.parentDiv.classList.add('calendarOpen');
        this.renderer.setStyle(this.floatElement, 'overflow-y', 'hidden');
        if(this.isParentScrollable)
          this.renderer.setStyle(this.floatElement, 'margin-right' , '10px');
        
      } else {  
        // reset previously applied styles
        this.parentDiv.classList.remove('calendarOpen');
        if(this.isParentScrollable)
          this.renderer.setStyle(this.floatElement, 'margin-right' , '0px');
        this.renderer.setStyle(this.paymentDiv, 'margin-top', `0px`);
        this.renderer.setStyle(this.floatElement, 'overflow-y', 'auto');
      }
    }
  }
}
