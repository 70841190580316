<div class="layout-content layout-content-noscroll p-pt-0 height100" >
    <form autocomplete="off" novalidate #sfForm="ngForm" class="height100">
        <div class="p-formgrid p-grid p-m-0 height100">
            <div class="p-col-12 p-p-0 p-d-flex p-jc-between p-flex-column">
               
                
                <div class="ui-fluid p-formgrid p-grid p-mx-0 p-mt-0 p-mb-5 ">

                    <div class="p-col-12 p-md-4 p-pl-0">
                        <!-- Financial Year -->
                        <div class=" p-fluid p-field">
                            <label>
                                Financial Year
                            </label>
                            <p-dropdown name="financialYearField" id="financialYearField"
                                [(ngModel)]="financialYear" [options]="financialYearList" required
                                [disabled]="disableFields" [showClear]="false" appendTo="body" optionValue="value"
                                optionLabel="label" (onChange)="selectOnChange()">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4">
                        <!-- Entity Status -->
                        <div class=" p-fluid p-field">
                            <label>
                                Entity Status
                            </label>
                            <p-dropdown name="entityStatusField" id="entityStatusField"[(ngModel)]="entityStatus"
                                    [options]="entityStatusList" required [disabled]="disableFields" [showClear]="false"
                                    appendTo="body" optionValue="property" optionLabel="property"
                                    autoDisplayFirst="true" (onChange)="selectOnChange()">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 ">
                        <!-- Client Type -->
                        <div class=" p-fluid p-field">
                            <label>
                                Client Type
                            </label>
                            <p-dropdown name="clientTypeField" id="clientTypeField" [(ngModel)]="clientType"
                            [options]="clientTypeList" required [disabled]="disableFields" [showClear]="false"
                            appendTo="body" optionValue="value" optionLabel="label" autoDisplayFirst="true"
                            (onChange)="selectOnChange()">
                            </p-dropdown>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-4 p-pl-0">
                        <!-- Assignment Family -->
                        <div class=" p-fluid p-field">
                            <label>
                                Assignment Family
                            </label>
                            <p-multiSelect #multiselect display="chip" id="sfGenerateBillMultiSelectDropdownid" name="sfGenerateBillMultiSelectDropdown" [filter]="false" [options]="assignmentFamilyList" (onChange)="selectOnChange();" optionLabel="label" placeholder="Select" [disabled]="disableFields" [(ngModel)]="assignmentFamily"scrollHeight="160px" required (onSelectAllChange)="onSelectAllChange($event)" [selectAll]="selectAll">
                                <ng-template let-all pTemplate="header">
                                    <label class="show-all-under-label">All</label>
                                </ng-template>
                                <ng-template let-value pTemplate="selectedItems">
                                    <div *ngIf="assignmentFamily?.length == assignmentFamilyList?.length" class="p-multiselect-token">
                                        <div class="p-multiselect-token-label">
                                            All
                                        </div>
                                        <span class="p-multiselect-token-icon pi pi-times-circle" (click)="changeAssignmentFamilyToEmpty()"></span>
                                    </div>
                                    <div *ngIf="assignmentFamily?.length != assignmentFamilyList?.length" >
                                        <div class="p-multiselect-token" *ngFor="let option of assignmentFamily">
                                            <div class="p-multiselect-token-label">{{option.label}}
                                            </div>
                                            <span class="p-multiselect-token-icon pi pi-times-circle" (click)="handleChipRemoval(option)"></span>
                                        </div>
                                    </div>
                                    <div *ngIf="assignmentFamily?.length == 0" >
                                        <div class="p-multiselect-label p-placeholder p-p-0">Select</div>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                            <div *ngIf="(sfForm.controls.sfGenerateBillMultiSelectDropdown.touched && sfForm.controls.sfGenerateBillMultiSelectDropdown.errors?.required) || assignmentFamily?.length === 0" class="invalid-feedback">
                                Please select at least one value.
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4">
                        <!-- Status -->
                        <div class=" p-fluid p-field">
                            <label>
                                Status
                            </label>
                            <p-dropdown name="sfBillStatusField" id="sfBillStatusField" [showClear]="false" [(ngModel)]="sfBillStatus"
                              [options]="sfBillStatusList"  appendTo="body" optionValue="id" optionLabel="property" autoDisplayFirst="true" (onChange)="selectOnChange()">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 ">
                        <!-- Lot Name -->
                        <div class=" p-fluid p-field">
                            <label>
                                Lot Name
                            </label>
                           
                        
                            <p-dropdown name="lotNumField" id="lotNumField" placeholder="Select"
                             [(ngModel)]="lotNumber"  [autoDisplayFirst]="false"
                            [showClear]="false" appendTo="body" [options]="lotNumberList"
                            (onChange)="selectOnChange()" >
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-pl-0">
                        <!-- BD Owner Name -->
                        <div class=" p-fluid p-field">
                            <label>
                                BD Owner Name
                            </label>
                            <p-multiSelect emptyFilterMessage="Type minimum 3 characters to load BD Owner Names" display="chip" id="bdOwnerNameid" name="bdOwnerNameDropdown" [filter]="true" [options]="bdOwnerNameList" optionLabel="employeeName" scrollHeight="100px" placeholder="Select" [disabled]="disableFields" [(ngModel)]="bdOwnerName" resetFilterOnHide="true"  (onFilter)="onFilter($event)" (onChange)="selectOnChangeForBdOwner($event);" ></p-multiSelect>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 ">
                    <div
                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-between p-ai-center  p-md-4 p-px-0 p-mb-2">
                        <div class="p-pl-0 p-ml-0">
                            <button type="button" (click)="onCancel()"
                                label="Preview Details" class="p-button-brand2 p-button p-ml-0">
                                <span class="p-button-label">Cancel</span>
                            </button>
                        </div>

                        <div>
                            <div class="btnwrap">
                                <button pButton class="p-button-brand2 p-button p-mr-2" type="button" pButton class="p-button-secondary p-mr-3"
                                style="border: none !important; padding: 0; margin: 0; background-color: transparent!important; box-shadow: none ;"
                                type="button" [disabled]="clearAllDisabled" (click)="resetForm()"
                                label="Clear All">
                                </button>
                            </div>
                            <div class="btnwrap">
                                <button pButton class="p-button-primary p-button p-mr-0" type="submit"
                                    (click)="onApply()" label="Apply">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Processing Popup -->
<p-dialog header="Processing" [modal]="true" [style]="{width: '31vw'}" [draggable]="false" [closable]="false"
    [resizable]="false">
    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-column p-ai-center">
        <backoffice-svg [name]="'processing-light'"></backoffice-svg>
        <backoffice-svg [name]="'processing-dark'"></backoffice-svg>
        <label class="p-mt-3 p-mb-3">Please wait while we are processing the data.</label>
    </div>
</p-dialog>