import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';



@Component({
  selector: 'jhi-reportingattachment',
  templateUrl: './ReportingAttachment.component.html',
  styleUrls: ['./ReportingAttachment.component.scss'],
  providers: [DialogService],
})
export class ReportingAttachmentComponent implements OnInit {
  ReportingAttachmentForm = this.fb.group({
    fileTypeName: [null as any, [Validators.required]],
    fileUpload1: [null, []],
    pageData: [null],
    dialog: [null as any],
  });
  storeSelection: any;
  pageData: any;
  pageloadvalues: any;
  returnBase64: any; //Added
  returnUploaded: any; //Added
  imgChangeEvt: any = "";
  cropImgPreview: any = "";
  @ViewChild('fileUploadfileUpload1', { static: false })
  fileUploadfileUpload1: any;
  filenameForfileUpload1: any;
  fileToRemovefileUpload1: any;
  croppedImagefileUpload1: any = '';
  imageChangedEventfileUpload1: any;
  aspectRatiofileUpload1: any;
  empId: any;
  isEdit = false;
  editItem: any[] = [];
  dynamicDialogdialogRef: DynamicDialogRef = new DynamicDialogRef();
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private pagesService: PagesService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ReportingAttachmentForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    if (this.config.data) {
      this.ReportingAttachmentForm.patchValue({ pageData: this.config.data });
      this.ReportingAttachmentForm.patchValue(this.config.data);
    }
    this.aspectRatiofileUpload1 = 4 / 3;
  }

  onSelectfileUpload1(event: any): void {
    this.isEdit = false;
    for (const file of event.files) {
      if (file.type.includes('image')) {
        this.imageChangedEventfileUpload1 = new Blob([file], { type: file.type });
        this.fileToRemovefileUpload1 = this.fileUploadfileUpload1.files.length - 1;
        this.filenameForfileUpload1 = file.name;
        this.showDialogdialog();
      } else {
        //Added
        const fileReader = new FileReader();
        fileReader.readAsDataURL(this.fileUploadfileUpload1.files[0]);
        fileReader.onload = () => {
          this.returnBase64 = fileReader.result;
        };
      }
    }
  }
  showDialogdialog(): void {
    this.dynamicDialogdialogRef = this.dialogService.open(ReportingAttachmentComponent, {
      // contentStyle: { 'max-height': '500px', overflow: 'visible' },
      header: 'Crop Image',
      closable: true,
  
      data: this.ReportingAttachmentForm.value,
      styleClass: 'sm',
    });
    this.dynamicDialogdialogRef.onClose.subscribe(response => {
      this.ReportingAttachmentForm.get('dialog')?.setValue(response);
    });
  }

  imageCroppedfileUpload1(event: any): any {
    this.croppedImagefileUpload1 = event.base64;
    this.returnBase64 = event.base64; //Added
  }
  getUploadedObj(): any {
    if (this.isEdit) {
      this.editItem[0].fileTypeid = this.ReportingAttachmentForm.value.fileTypeName.id;
      this.editItem[0].fileTypeName = this.ReportingAttachmentForm.value.fileTypeName.type;
      return this.editItem[0];
    } else {
      return {
        userId: this.empId,
        fileSize: (this.fileUploadfileUpload1.files[0].size).toString(),
        fileName: this.fileUploadfileUpload1.files[0].name,
        file: this.returnBase64,
        fileContentType: this.fileUploadfileUpload1.files[0].type,
        fileTypeid: (this.ReportingAttachmentForm.value.fileTypeName.id).toString(),
        fileTypeName: this.ReportingAttachmentForm.value.fileTypeName.type,
      };
    }
  }
  getUploadedObjDisplay(): any {
      let data = this.returnBase64;
      if (data.startsWith('data:application/pdf;base64,')) {
        data = data.replace('data:application/pdf;base64,', '');
      } else if (data.startsWith('data:image/png;base64,')) {
        data = data.replace('data:image/png;base64,', '');
      }
      
      return {
        userId: this.empId,
        fileSize: (this.fileUploadfileUpload1.files[0].size).toString(),
        fileName: this.fileUploadfileUpload1.files[0].name,
        file: data,
        fileContentType: this.fileUploadfileUpload1.files[0].type,
        fileTypeid: (this.ReportingAttachmentForm.value.fileTypeName.id).toString(),
        fileTypeName: this.ReportingAttachmentForm.value.fileTypeName.type,
      };
    
  }
  dataURLtoFile(dataurl: any, filename: any): any {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  onCroppedUploadBtn(): void {
    if (this.dataURLtoFile && this.croppedImagefileUpload1) {
      const file = this.dataURLtoFile(this.croppedImagefileUpload1, this.filenameForfileUpload1);
      this.fileUploadfileUpload1.files.splice(this.fileToRemovefileUpload1, 1);
      this.fileUploadfileUpload1.files.push(file);
    }
    // Added
    this.returnUploaded = this.getUploadedObj();
  }
  onChangeBtn(): void {
    this.ReportingAttachmentForm.patchValue(this.storeSelection);
  }
  onCancel(): void {
    setTimeout(() => {
      this.ref.close();
    }, 1000);
  }

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }
}

