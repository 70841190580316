import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { PagesService } from 'app/pages/pages.service';

@Component({
  selector: 'backoffice-view-bill-payment-details',
  templateUrl: './view-bill-payment-details.component.html',
  styleUrls: ['./view-bill-payment-details.component.scss'],
  providers: [MessageService]
})
export class ViewBillPaymentDetailsComponent implements OnInit {

  applicationBreadcrumbList = [];
  apiFail: boolean =false;
  payment_bill_details: any;
  urlSafe: any;
  outstandingAmount: any = [];

  displayPreview: boolean = false;
  dataLoader: boolean = false;
  previewFailed: boolean = false;

  openedFileName: any;

  fetchedURLAgreementId;
  fetchedURLIrfBillId;
  agreementdetailsForm = this.fb.group({
    agreementdetilheading: <any>[''],
    mandatereceivedinformationheading: <any>[''],
    casedetailsheading: <any>[''],
    sffeeruledetailsheading: <any>[''],
    smssubscriptionheading: <any>[''],
    portfoliodetailsheading: <any>[''],
    ratingstatusheading: <any>[''],
    ccprimesplitbutton_64: <any>[''],
  });

  homeiconapplicationBreadcrumb = {icon: 'pi pi-home', routerLink: ['/backoffice/agreement-archive/byclient']};

  //splitbutton
  viewButtonitems: MenuItem[];

  constructor(private pagesService: PagesService,private router: Router, protected activatedRoute: ActivatedRoute, private fb: FormBuilder,private route: ActivatedRoute) 
  {
    
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.fetchedURLAgreementId = params.get('agreementId');
      this.fetchedURLIrfBillId = params.get('irfBillId');
      console.log("params3..",this.fetchedURLIrfBillId);
    });


    this.getArchivalIrfBillPaymentDetails();
  }

  getArchivalIrfBillPaymentDetails(): void {
    this.pagesService.getArchivalIrfBillPaymentDetails(this.fetchedURLIrfBillId).subscribe(results => {
      this.payment_bill_details = results;
      this.fetchedURLAgreementId = this.payment_bill_details?.agreementId;
      this.outstandingAmount = [{
        irfAmount: this.payment_bill_details?.irfAmount,
        invoiceStGst: this.payment_bill_details?.invoiceStGst,
        invoiceTotal: this.payment_bill_details?.invoiceTotal,
        billAmount: this.payment_bill_details?.billAmount,
        collectionStGst: this.payment_bill_details?.collectionStGst,
        tdsLess: this.payment_bill_details?.tdsLess,
        collectionTotal: this.payment_bill_details?.collectionTotal,
        outstandingBillAmount: this.payment_bill_details?.outstandingBillAmount,
        outstandingStGst: this.payment_bill_details?.outstandingStGst,
        outstandingTotal: this.payment_bill_details?.outstandingTotal
      }];
      this.applicationBreadcrumbList = [
        { label: `Archival Data Listing`, routerLink: ['/agreement-archive/byclient']},
        { label: `Agreement`, routerLink: ['/agreement-archive/agreementdetails',this.fetchedURLAgreementId]},
        { label: `Bill Payment Details`, routerLink: ['/agreement-archive/viewbillpaymentdetails']},
      ];
        this.apiFail = false;
      }, (_error) => {
        this.apiFail = true;
      });
  }

  showPaymentDetailsAttachmentPreview(fileName: any,filePath: any){
    // this.zoomValue = 1;
    this.openedFileName = fileName;
    // this.rotate = 0;
    
    this.pagesService.getArchivalIrfBillPaymentDetails(this.fetchedURLIrfBillId).subscribe((res) => {
      this.payment_bill_details =res;
      this.apiFail = false;
      this.payment_bill_details.originalAttachmentFileName
      this.payment_bill_details.originalAttachmentBase64
      this.displayPreview = true;
        // if(this.agreement_details1 && this.agreement_details1?.originalAttachmentBase64){
          let mime: any;
          //this.isPdf = true;
          mime = 'data:application/pdf;base64,'
          this.urlSafe = `${mime}${filePath}`
          //this.previewFailed = true;
          this.dataLoader = false;
      

    },(_error) => {
      this.apiFail = true;
      this.displayPreview = false;
      this.previewFailed = true;
    });
  }
  
  downloadFile() {
    let linkSource: any;
    linkSource = this.urlSafe;
    
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this.openedFileName.split('.')[0];
    downloadLink.click();
  }

  goToPortfolioDetails(companyCode): void{
    console.log("companyCode",companyCode);
  }
  
  onBackClick(): void {
    this.router.navigate(['/agreement-archive/agreementdetails',this.fetchedURLAgreementId])
  }

  mandateAttachment(): void{}

}

