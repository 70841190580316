<div class="entityDetailsModal">
    <p-toast class="custom-toast"></p-toast>
    <div class="p-col-12 p-p-0">
        <div class="header-section">
            <div class="p-d-flex p-jc-between">
                <div class="p-col-12 p-p-0 p-d-flex p-jc-between">
                    
                    <div class="p-d-flex"> 
                        <label class="p-mr-2 label_light">QC Status:</label>
                        <h1 class="p-medium display-4">{{qcStatus}}</h1>
                    </div>
                
                
                    <div class="p-d-flex"> <label class="p-mr-2 label_light">Initiation Date:</label>
                        <h1 class="p-medium display-4">{{initiatedDate}}</h1>
                    </div>
                
                
                    <div class="p-d-flex"> <label class="p-mr-2 label_light">CRM Code:</label>
                        <h1 class="p-medium display-4">{{crmCode}}</h1>
                    </div>
                    
                    <div class="p-d-flex"> <label class="p-mr-2 label_light">BD User:</label>
                        <h1 class="p-medium display-4">{{BDUser}}</h1>
                    </div>
                    
                    <div class="p-pl-0">
                        <div class="p-text-right extra-action-container p-d-flex"> 
                            <span class="addLabel" (click)="openAttachmentList()"><a
                                class="cursor-pointer p-mr-1">View Attachment</a></span>
                                <backoffice-svg [name]="'new-link-icon'"></backoffice-svg>
                        </div>
                    </div>
                    
                    <!-- Blinking circle for high priority cases -->
                    <div *ngIf="highPriority" class="high-priority">
                        <span style="color: white; font-size: 20px; font-weight: bold;"></span>
                    </div>
                </div>

            </div>
    
        </div>
    <div class="ui-fluid p-formgrid p-grid">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 verticalTabContainer p-p-0">
            <p-tabView [(activeIndex)]="activeIndex" styleClass="p-tabview-vertical">
                <!-- Date details tab -->
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': submittedData && globalForm.get('dateDetails')?.invalid}">Date
                            Details</span>
                    </ng-template>
                    <item-list-details *ngIf="sendData" [disabled]="disableAllFields" [form]="globalForm.get('dateDetails')"></item-list-details>    
                </p-tabPanel>

                <!-- Mandate details tab -->
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': submittedData && globalForm.get('mandateDetails')?.invalid}">Mandate
                            Details</span>
                    </ng-template>
                    <item-list-details *ngIf="sendData" [disabled]="disableAllFields" [form]="globalForm.get('mandateDetails')"></item-list-details>    
                </p-tabPanel>

                <!-- Tri Party -->
                <p-tabPanel [disabled]="disableTriPartyTab">
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': submittedData && globalForm.get('triPartyDetails')?.invalid}">Tri-Party
                            Details</span>
                    </ng-template>
                    <item-list-details *ngIf="sendData" [disabled]="disableAllFields" [form]="globalForm.get('triPartyDetails')"></item-list-details>    
                </p-tabPanel>

                <!-- Product details -->
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': submittedData && globalForm.get('productDetails')?.invalid}">Product
                            Details</span>
                    </ng-template>
                    <item-list-details *ngIf="sendData" [disabled]="disableAllFields" [form]="globalForm.get('productDetails')"></item-list-details>    
                </p-tabPanel>

                <!-- Payment details -->
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': submittedData && globalForm.get('paymentDetails')?.invalid}">Payment
                            Details</span>
                    </ng-template>
                    <qc-payment-details *ngIf="sendData"[form]="globalForm.get('paymentDetails')" [disabled]="disableAllFields"></qc-payment-details>    
                </p-tabPanel>

                <!-- Cost center -->
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': submittedData && globalForm.get('costCenterDetails')?.invalid}">Cost Center</span>
                    </ng-template>
                    <item-list-details *ngIf="sendData" [disabled]="disableAllFields" [form]="globalForm.get('costCenterDetails')"></item-list-details>    
                </p-tabPanel>

                <!-- Portfolio details -->
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': submittedData && globalForm.get('portfolioDetails')?.invalid}">Portfolio
                            Details</span>
                    </ng-template>
                    <div class="p-m-1 p-d-flex p-ai-center">
                        <h1 class="p-medium display-4">{{userHierarchy}}</h1>
                    </div>
                    <item-list-details *ngIf="sendData" [disabled]="disableAllFields" [form]="globalForm.get('portfolioDetails')"></item-list-details>    
                </p-tabPanel>

                <!-- Combo product -->
                <p-tabPanel [disabled]="disableComboTab">
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': submittedData && globalForm.get('comboProductDetails')?.invalid}">Combo product</span>
                    </ng-template>
                    <item-list-details *ngIf="sendData" [disabled]="disableAllFields" [form]="globalForm.get('comboProductDetails')"></item-list-details>    
                </p-tabPanel>

                <!-- Fee cap details -->
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': submittedData && globalForm.get('feeCapDetails')?.invalid}">Fee Cap
                            Details</span>
                    </ng-template>
                    <div class="p-m-1 p-d-flex p-jc-end">
                        <a (click)="navigateToFeeCap()">Fee Cap Master</a>
                    </div>
                    <item-list-details *ngIf="sendData" [disabled]="disableAllFields" [form]="globalForm.get('feeCapDetails')"></item-list-details>    
                </p-tabPanel>

                <!-- Audit log tab -->
                <p-tabPanel>
                    <ng-template pTemplate="header"><span>Audit Log
                            </span>
                    </ng-template>
                    <qc-audit-log-details [agreementId]="agreementId" *ngIf="sendData"></qc-audit-log-details>    
                </p-tabPanel>


            </p-tabView>

       
        </div>
    </div>
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 popupFooterBtnContainer">
        <div
        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-between p-ai-center popupFooterBtnContent">
      
        <div>
            <button _ngcontent-xuq-c136="" pbutton="" type="button" (click)="onPreviewScreen()" label="Preview Details"class="p-element p-button-secondary p-button p-component p-ml-0">
                <span class="p-button-label">Preview Data</span>
                </button>
        </div>

        <div>
            <div class="btnwrap">
                <button pButton class="p-button-brand2 p-button" type="button" label="Cancel"
                    (click)="closeDetailPopup()">
                </button>
            </div>
            <div class="btnwrap">
                <button [disabled]="disableSave" pButton class="p-button-primary p-button p-mr-0" (click)="finalSaveQCDetails()" type="submit"
                    label="Save">
                </button>
            </div>
        </div>

    </div>
    </div>
</div>

<!-- Alert popup -->

<p-dialog header="Alert" [(visible)]="showAlertPopUp" [modal]="true" [style]="{width: '30vw'}" [draggable]="false"
    [resizable]="false">
    <div class="p-col-12 p-px-1" id="center">
        <p class="p-mb-0">{{alertFinalSavePopUpmsg}}<br>
            Please enter in order to save.</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="showAlertPopUp=false" type="button"
                label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog>

<!-- API fail modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>

<!-- save confirm -->
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="showSaveAlertPopUp" [modal]="true"
    [style]="{width: '25vw', height: '35vh'}" [draggable]="false" [resizable]="false">
    <p class="p-mb-0 p-pb-0">This case will be marked as <strong>{{alertMsg}}</strong>.</p>
    <p>Are you sure, you want to save this case?</p>

    <ng-template pTemplate="footer">
        <button pButton (click)="showSaveAlertPopUp = false;" label="No" class="p-button-brand2"></button>
        <button pButton (click)="confirmSaveData()" [disabled]="disabledCrmApplyBtn" label="Yes"
            class="p-button-primary"></button>
    </ng-template>
</p-dialog>
