import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { validateAllFormFields } from 'app/shared/util/reactiveform-util';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MappeduserService } from '../mappeduser.service';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'jhi-editmappeduser',
  templateUrl: './EditMappedUser.component.html',
  styleUrls: ['./EditMappedUser.component.scss'],
  providers: [MessageService],
})


export class EditMappedUserComponent implements OnInit {
  EditMappedUserForm = this.fb.group({
    employeeId: [null, []],
    employeeName: [null, []],
    bd_designation: [null as any, []],
    mobileNo: [null, [Validators.required]],
    emailId: [null, []],
    shadowPortfolioEmployeeId: [null, []],
    shadowPortfolioEmployeeNameAndDesignation: [null as any, []],
    immediateSupervisor: [null as any, [Validators.required]],
    imeediateSupervisorLevelName: [null, []],
    immediateSupervisorEmpId: [null, []],
    directorName: [null, []],
    hrDesignation: [null, []],
    opSpoc: [null as any, []],
    teamName: [null as any, [Validators.required]],
    paEmployeeId: [null, []],
    paEmployeeNameAndDesignation: [null as any, [Validators.required]],
    bdDesignationId: [null, []],
    shadowList: [null,[Validators.required]],
    opsList: [null,[Validators.required]],
    level_id: [null as any,[Validators.required]]
  });
  pageloadvalues: any;
  htmlSrc: any;
  designationResponse: any = {};
  designationList: any[] = [];
  shadowPortfolioEmployeeNameAndDesignationResponse: any = {};
  shadowPortfolioEmployeeNameAndDesignationList: any[] = [];
  html1Src: any;
  immediateSupervisorResponse: any = {};
  immediateSupervisorList: any[] = [];
  opSpocList: any[] = [];
  designation1Response: any = {};
  teamNameList: any[] = [];
  paEmployeeNameAndDesignationResponse: any = {};
  paEmployeeNameAndDesignationList: any[] = [];
  empId: any;
  empName: any;
  timeOut = true;
  shadowPortfolioList: any[] = [];
  name: any[] = [];
  dataStatusMessage: string = '';
  dataStatusMessageSeverity: string = '';

  //  childDataPass:boolean=true;

  //  @Output() hierarchyRefreshEvent = new EventEmitter<boolean>();
  now = new Date();
  maxdate = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 1);
  saveButtonEnabled: boolean = false;
  viewSign: boolean = false;
  signature: any;
  displayPreview: boolean;
  fileUrl: any;
  obj: any = {};
  urlSafe: any;
  deletePopUp: boolean = false;
  fileName: any;
  signBtn: boolean;
  disableUpload: boolean;
  changeDesigAlert: boolean;
  directorName: string;
  currentFileEvent: any;  
  imgChangeEvt: any = "";
  cropImgPreview: any = "";
  displayModal: boolean;
  filenameForfileUpload1: any;
  fileToRemovefileUpload1: any;
  imageChangedEventfileUpload1: any;
  returnBase64: any;
  croppedImagefileUpload1: any = '';
  fileUploadfileUpload1: any = {
    files: []
  };
  signatureResult: any;
  returnUploaded: any;
  uploadFile: any = {};
  signDeleted: boolean;
  bd_designation1:any={};
  loaderFlag : boolean;
  oldOppSpoc: any;
  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private pagesService: PagesService, private messageService: MessageService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private mappeduserService: MappeduserService, private encryptDecryptInterceptor: EncryptDecryptInterceptor, public sanitizer: DomSanitizer) {
  }
  ngOnInit(): void {
    this.saveButtonEnabled = false;
    this.signature = this.config.data?.signature;
    this.fileName = this.config.data?.fileName;
    this.EditMappedUserForm.get("shadowList").setValue(this.config.data.formData.value.pageData.shadowPortfolioUsersList);
    this.EditMappedUserForm.get("opsList").setValue(this.config.data.formData.value.pageData.opSpocUsersList)
    if (this.config.data.formData) {
      this.oldOppSpoc = this.config.data.formData?.value?.opSpoc?.employeeId;
       this.bd_designation1 = {
        bd_designation: this.config.data.formData.value.bd_designation.bd_designation,
        designation_name: this.config.data.formData.value.bd_designation.designation_name,
        id: this.config.data.formData.value.pageData.bdDesignationId,
        level_id: this.config.data.formData.value.pageData.level,
        level_name: this.config.data.formData.value.pageData.level_name,
        level_no: this.config.data.formData.value.pageData.levelNo,
      };
     
      this.config.data.formData.value.pageData.bd_designation = this.bd_designation1;
      this.EditMappedUserForm.patchValue(this.config.data.formData.value.pageData);
      

      if(this.config.data.formData.value.pageData.bd_designation.bd_designation == "Director" || this.config.data.formData.value.pageData.bd_designation.bd_designation == "Senior Director"){
        if(this.config.data?.signature != null){
          this.signBtn = false;
          this.viewSign = true;
          let file = this.signature;
          let blob = this.b64toBlob(file, 'image/png');
          const blobUrl = URL.createObjectURL(blob);
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl); 
        }else{
          this.disableUpload = false;
          this.signBtn = true;
        }
      }else{
        this.signBtn = true;
        this.viewSign = false;
        this.disableUpload = true;
      }      
      this.setPageProperties(this.config.data.formData.value);
    }
    this.htmlSrc = '';
    this.getdesignationList();
    this.getshadowPortfolioEmployeeNameAndDesignationList(this.EditMappedUserForm.value.employeeId);
    this.html1Src = '';
    
    this.getpaEmployeeNameAndDesignationList(this.config.data.formData.value.pageData.bdDesignationId, this.EditMappedUserForm.value.employeeId);

    this.getOpsSpocList();

    this.getimmediateSupervisorList(this.config.data.formData.value.pageData.bdDesignationId,this.config.data.formData.value?.pageData.level, this.EditMappedUserForm.value.employeeId);
  }
  setPageProperties(props: any): void {


    const pageData = {
      employeeId: this.EditMappedUserForm.value.employeeId,
      employeeName: this.EditMappedUserForm.value.employeeName,
      mobileNo: this.EditMappedUserForm.value.mobileNo,
      imeediateSupervisorLevelName: this.EditMappedUserForm.value.imeediateSupervisorLevelName,
      directorName: this.EditMappedUserForm.value.directorName,
      hrDesignation: this.EditMappedUserForm.value.hrDesignation,
    };
    this.empId = pageData.employeeId;
    this.empName = pageData.employeeName;
    this.EditMappedUserForm.patchValue(pageData)
  }

  value: boolean = true;
  private setData() {

    this.mappeduserService.setdata(this.value);
    this.value = false;

  }
  private setDataMessage(value,severity) {

    this.mappeduserService.setdatamessage(value,severity);
    this.dataStatusMessage = '';

  }

  getdesignationList(): void {
    this.pagesService.getdesignationDataEditMappedUser().subscribe(results => {
      this.designationList = results.bdDesignationList;
      this.teamNameList = results.teamList;
      this.designationList.forEach((des) => {
        if(des.id == this.EditMappedUserForm.value.bdDesignationId){
          des.level_id = this.bd_designation1.level_id;
          des.level_name = this.bd_designation1.level_name;
          des.level_no = this.bd_designation1.level_no;
        }
      });
      const obj = {
        bd_designation: this.bd_designation1,
        teamName: this.teamNameList.find(data => data.value === this.EditMappedUserForm.value.teamName)
      }
      this.EditMappedUserForm.patchValue(obj);
    });
  }
  getshadowPortfolioEmployeeNameAndDesignationList(empid): void {

    this.pagesService.getshadowPortfolioEmployeeNameAndDesignationDataEditMappedUser(empid).subscribe(results => {
      const responseData = results;
      this.shadowPortfolioEmployeeNameAndDesignationList = responseData;
      const shadowportfoliouser = {
        shadowPortfolioEmployeeNameAndDesignation: this.shadowPortfolioEmployeeNameAndDesignationList.find(data => data.shadowPortfolioEmployeeId === this.EditMappedUserForm.value.shadowPortfolioEmployeeId)
      }
      this.EditMappedUserForm.patchValue(shadowportfoliouser);

    });
  }
  getimmediateSupervisorList(bdDesignation, levelid, employeeId): void {
    this.pagesService.getimmediateSupervisorDataEditMappedUser(bdDesignation, levelid, employeeId).subscribe(results => {
      const responseData = results;
      this.immediateSupervisorList = responseData;
      const selectedImmediateSupervisor = {
        immediateSupervisor: this.immediateSupervisorList.find(data => data.immediateSupervisorEmpId === this.EditMappedUserForm.value.immediateSupervisorEmpId)
      }
      
      this.EditMappedUserForm.patchValue(selectedImmediateSupervisor);
      const obj = {
        level_id: levelid
      }
      this.EditMappedUserForm.patchValue(obj);
    });
  }

  onChangeDesignation(bdDesignation){
    this.verifyFormData();
    if((bdDesignation != 6 && bdDesignation != 7)&& this.signatureResult?.file != null){
      this.changeDesigAlert = true;
    }
    if(this.config.data.formData.value.bd_designation.id == 6 || this.config.data.formData.value.bd_designation.id == 7){
      if((bdDesignation != 6 && bdDesignation != 7) && this.signature != null){
        this.changeDesigAlert = true;
      }
      if((bdDesignation != 6 && bdDesignation != 7) && this.signature == null){
        this.disableUpload = true;
        this.signBtn = true;
      }else{
        this.disableUpload = false;
      }
    }else if(bdDesignation == 6 || bdDesignation == 7){
      this.signBtn = true;
      this.disableUpload = false;
    }
    else{
      this.signBtn = true;
      this.disableUpload = true;
    }
  }

  getdesignation1List(): void {
    this.pagesService.getdesignation1DataEditMappedUser().subscribe(results => {
      const responseData = results.teamList;
      this.teamNameList = responseData;
    });
  }
  getpaEmployeeNameAndDesignationList(levlid, employeeId): void {
    this.pagesService.getpaEmployeeNameAndDesignationDataEditMappedUser(levlid, employeeId).subscribe(results => {
      const responseData = results;
      this.paEmployeeNameAndDesignationList = responseData;
      const paapprover = {
        paEmployeeNameAndDesignation: this.paEmployeeNameAndDesignationList.find(data => data.paEmployeeId === this.EditMappedUserForm.value.paEmployeeId)
      }
      this.EditMappedUserForm.patchValue(paapprover);
    });
  }

  getOpsSpocList(): void {
    this.pagesService.getOpsSpocListDatausermapping().subscribe(results => {
      const responseData = results;
      this.opSpocList = responseData;
      this.sortInAscendingOrder(this.opSpocList);
      const pageData = {
        opSpoc: this.opSpocList.find(ops => ops.employeeId == this.EditMappedUserForm.value.opSpoc)
      }
      this.EditMappedUserForm.patchValue(pageData);
    });
  }
  onCcprimebutton2($event: FormGroup): void {
  }
  onCcprimebutton($event: FormGroup): void {

    setTimeout(() => {
      this.ref.close();
    }, 50);

  }

  onCcprimebutton1($event: FormGroup): void {
    $event.updateValueAndValidity();
    this.loaderFlag = true;
    if (!$event.valid) {
      validateAllFormFields($event);
      this.loaderFlag = false;
      return;
    }

    const reqBody = {};
    reqBody['employeeDetails'] = [{
      "employeeId": this.EditMappedUserForm.value.employeeId,
      "employeeName": this.EditMappedUserForm.value.employeeName,
      "effectiveFrom": "01-Sep-2022",
      "level_name": this.EditMappedUserForm.value.bd_designation.level_name,
      "levelNo": this.EditMappedUserForm.value.bd_designation.level_no,
      "bdDesignationId": this.EditMappedUserForm.value.bd_designation.id,
      "bd_designation": this.EditMappedUserForm.value.bd_designation.bd_designation,
      "hrDesignation": this.EditMappedUserForm.value.hrDesignation,
      "level": this.EditMappedUserForm.value.bd_designation.level_id,
      "immediateSupervisorEmpId": this.EditMappedUserForm.value.immediateSupervisor.immediateSupervisorEmpId,
      "immediateSupervisor": this.EditMappedUserForm.value.immediateSupervisor.immediateSupervisor,
      "immediateSupervisorBdDesignation": this.EditMappedUserForm.value.immediateSupervisor.immediateSupervisorBdDesignation,
      "immediateSupervisorBdDesignationId": this.EditMappedUserForm.value.immediateSupervisor.immediateSupervisorBdDesignationId,
      "immediateSupervisorLevelId": this.EditMappedUserForm.value.immediateSupervisor.immediateSupervisorLevelId,
      "imeediateSupervisorLevelName": this.EditMappedUserForm.value.immediateSupervisor.imeediateSupervisorLevelName,
      "directorName": this.EditMappedUserForm.value.directorName,
      "teamId": this.EditMappedUserForm.value.teamName.id,
      "teamName": this.EditMappedUserForm.value.teamName.value,
      "mobileNo": this.EditMappedUserForm.value.mobileNo,
      // "opSpoc": (this.EditMappedUserForm.value.opSpoc == undefined) ? (null) : (this.EditMappedUserForm.value.opSpoc.employeeId),
      // "opsSpocName": (this.EditMappedUserForm.value.opSpoc == undefined) ? (null) : (this.EditMappedUserForm.value.opSpoc.employeeName),
      "finalMapping": this.EditMappedUserForm.value.immediateSupervisor.finalMapping,
      "userType": "E",
      // "shadowPortfolioEmployeeId": (this.EditMappedUserForm.value.shadowPortfolioEmployeeNameAndDesignation == undefined) ? (null) : (this.EditMappedUserForm.value.shadowPortfolioEmployeeNameAndDesignation.shadowPortfolioEmployeeId),
      // "shadowPortfolioEmployeeNameAndDesignation": (this.EditMappedUserForm.value.shadowPortfolioEmployeeNameAndDesignation == undefined) ? (null) : (this.EditMappedUserForm.value.shadowPortfolioEmployeeNameAndDesignation.shadowPortfolioEmployeeNameAndDesignation),
      "shadowPortfolioUsersList": this.EditMappedUserForm.value.shadowList,
      "opSpocUsersList": this.EditMappedUserForm.value.opsList,
      "paEmployeeId": (this.EditMappedUserForm.value.paEmployeeNameAndDesignation == undefined) ? (null) : (this.EditMappedUserForm.value.paEmployeeNameAndDesignation.paEmployeeId),
      "paEmployeeNameAndDesignation": (this.EditMappedUserForm.value.paEmployeeNameAndDesignation == undefined) ? (null) : (this.EditMappedUserForm.value.paEmployeeNameAndDesignation.paEmployeeNameAndDesignation),
      "emailId": this.EditMappedUserForm.value.emailId,
      "signature": (this.signatureResult != null || this.signatureResult != undefined) ? this.signatureResult.file : this?.signature,
      "fileName": (this.signatureResult != null || this.signatureResult != undefined) ? this.signatureResult.fileName : this?.fileName,
      "createdBy": this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'))
    }]
    const formData = reqBody;
    this.pagesService.onccprimebutton1SubmitEditMappedUser(formData).subscribe(results => {
      this.onCcprimebutton($event);
      this.loaderFlag = false;
      if (results.status == "success") {
        this.dataStatusMessage = 'User Hierarchy Updated successfully';
        this.dataStatusMessageSeverity = 'success';
        // this.messageService.add({ severity: 'success', detail: 'User Hierarchy Updated successfully' });
        if(this.oldOppSpoc != this.EditMappedUserForm.value?.opSpoc?.employeeId){
          let payload = {
            "updatedOpsUserName": this.EditMappedUserForm.value?.opSpoc?.employeeName,
            "updatedOpsUserId": this.EditMappedUserForm.value?.opSpoc?.employeeId,
            "nexeraUserId": this.empId
          }
          this.pagesService.updateOpsUser(payload).subscribe((_res) =>{});
        }
      }
      window.scrollTo(0, 0);
      this.setData();
      this.setDataMessage(this.dataStatusMessage, this.dataStatusMessageSeverity);
      this.router.navigate(["pages/userhierarchylist"]);

      setTimeout(() => {
        this.messageService.clear();
        this.timeOut = false;
      }, 4000);
    }, (error) => {
      this.loaderFlag = false;
      this.onCcprimebutton($event);
      this.messageService.clear();
      this.timeOut = true;

      let stringObj = this.encryptDecryptInterceptor.decrypt(error.error.data);
      const obj = JSON.parse(stringObj);
      if (obj.message.includes("Not possible to update All 3 Levels Occupied for Employee ID")){
        this.dataStatusMessage = obj.message;
        // this.messageService.add({ severity: 'error', detail: obj.message });
      }else{
        this.dataStatusMessage = 'Something went wrong, Please try again!';
        // this.messageService.add({ severity: 'error', detail: 'Something went wrong, Please try again!' });
      }
      this.dataStatusMessageSeverity = 'error'
      this.setDataMessage(this.dataStatusMessage, this.dataStatusMessageSeverity);
        

      window.scrollTo(0, 0);
      setTimeout(() => {
        this.messageService.clear();
        this.timeOut = false;
      }, 4000);
    });
    this.timeOut = true;
  }

  setImmediateSupervisor() {

    const immediatesupdetails = {
      imeediateSupervisorLevelName: this.EditMappedUserForm.value.immediateSupervisor.imeediateSupervisorLevelName,
      directorName: this.EditMappedUserForm.value.immediateSupervisor.directorName
    }
    this.EditMappedUserForm.patchValue(immediatesupdetails);
    this.verifyFormData();
  }

  formatDate(datestring) {
    let date = new Date(datestring);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return day + '-' + month + '-' + year;
  }

  sortInAscendingOrder(data: any) {
    data.sort((a, b) => {
      if (a.employeeName.toLowerCase() < b.employeeName.toLowerCase())
        return -1;
      if (a.employeeName.toLowerCase() > b.employeeName.toLowerCase())
        return 1;
      return 0;
    })
  }
  verifyFormData() {
    if (this.config.data.formData) {
      const presentDesignation = this.config.data.formData.value.pageData.bd_designation.bd_designation;
      const presentMobile = this.config.data.formData.value.pageData.mobileNo;
      const presentPortfolio = this.config.data.formData.value.pageData.shadowPortfolioUsersList;
      const presentImmediateSupervisor = this.config.data.formData.value.pageData.immediateSupervisor;
      const presentTeam = this.config.data.formData.value.pageData.teamName;
      const presentOps = this.config.data.formData.value.pageData.opSpocUsersList;
      const presentPA = this.config.data.formData.value.pageData.paEmployeeNameAndDesignation;
      const designationn = this.EditMappedUserForm.value.bd_designation == undefined?null:this.EditMappedUserForm.value.bd_designation.bd_designation;
      const PA = this.EditMappedUserForm.value.paEmployeeNameAndDesignation?.paEmployeeNameAndDesignation == undefined?this.EditMappedUserForm.value.paEmployeeNameAndDesignation:this.EditMappedUserForm.value.paEmployeeNameAndDesignation.paEmployeeNameAndDesignation;
      const shadowPorfolio = this.EditMappedUserForm.value.shadowPortfolioEmployeeNameAndDesignation == undefined?null:this.EditMappedUserForm.value.shadowPortfolioEmployeeNameAndDesignation.shadowList;
      const ops = this.EditMappedUserForm.value.opSpoc == undefined?null:this.EditMappedUserForm.value.opsList;
      const immediateSupervisorr = this.EditMappedUserForm.value.immediateSupervisor == undefined?null:this.EditMappedUserForm.value.immediateSupervisor.immediateSupervisor;
      const teamNamee = this.EditMappedUserForm.value.teamName == undefined?null:this.EditMappedUserForm.value.teamName.value;
      const signature = this.signature ? this.signature : this.signatureResult?.file;
      const presentSignature = this.config.data?.signature == undefined?null:this.config.data?.signature;
      if(designationn != null && this.EditMappedUserForm.value.mobileNo != null && immediateSupervisorr !== null && teamNamee != null && PA != null){
        if ( designationn == presentDesignation && this.EditMappedUserForm.value.mobileNo == presentMobile
          && shadowPorfolio == presentPortfolio && immediateSupervisorr.includes(presentImmediateSupervisor) 
          && ops == presentOps && teamNamee == presentTeam && PA == presentPA && (signature == presentSignature || ( (designationn == "Director" || designationn == "Senior Director") && this.signDeleted == false))){
            this.saveButtonEnabled = false;
          }else{
            this.saveButtonEnabled = true;
          }
      }else{
        this.saveButtonEnabled = false;
      }
    }
  }

  onSelectfileUpload1(event: any): void {
    this.currentFileEvent = event;
    if(this.currentFileEvent.files[0].size > 5000000){
      this.messageService.add({ severity: 'error',summary:'File size cannot exceed 5 MB.'});
      window.scrollTo(0, 0);
        setTimeout(() => {
          this.timeOut = false;
          this.messageService.clear();
        }, 5000);
      return;
    }
    let type = this.currentFileEvent.files[0].type;
    if(type.includes('image/svg+xml') || type.includes('xlxs')|| type.includes('xls')|| 
    type.includes('html')|| type.includes('xhtml')|| type.includes('txt')|| type.includes('gif')|| 
    type.includes('zip')|| type.includes('pdf')|| type.includes('doc')|| type.includes('docx')){
      this.messageService.add({ severity: 'error', summary: ' Invalid File Format.' });
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.timeOut = false;
        this.messageService.clear();
      }, 5000);
      return;
    }
    this.imgChangeEvt = event.originalEvent;
    if (event.currentFiles[0].type == 'image/jpeg' || event.currentFiles[0].type == 'image/jpg' || event.currentFiles[0].type == 'image/png' || event.currentFiles[0].type == 'image/webp') {
      this.displayModal = true;
    } else {
      this.displayModal = false;
    }
      if (event.files[0].type.includes('image')) {
        this.imageChangedEventfileUpload1 = new Blob([event.files[0]], { type: event.files[0].type });
        this.filenameForfileUpload1 = event.files[0].name;
      } else {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(event.currentFiles[0]);
        fileReader.onload = () => {
          this.returnBase64 = fileReader.result;
          this.onCroppedUploadBtn();
        };
      }
  }

  dataURLtoFile(dataurl: any, filename: any): any {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  getUploadedObjDisplay(): any {
    let data = this.returnBase64;
    if (data.startsWith('data:application/pdf;base64,')) {
      data = data.replace('data:application/pdf;base64,', '');
    } else if (data.startsWith('data:image/png;base64,')) {
      data = data.replace('data:image/png;base64,', '');
    } else if (data.startsWith('data:application/doc;base64,')) {
      data = data.replace('data:application/doc;base64,', '');
    }

    return {
      fileName: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].name,
      file: data
    };
  }

  getUploadedObj(): any {
    return {
      fileName: this.currentFileEvent.name,
      file: this.returnBase64
    };

  }

  imageCroppedfileUpload1(event: any): any {
    // this.croppedImagefileUpload1 = event.base64;
    // this.returnBase64 = event.base64; //Added

    fetch(event.objectUrl)
       .then(response => response.blob())
       .then(blob => {
           const reader = new FileReader();
           reader.onloadend = () => {
               this.returnBase64 = reader.result;
               this.croppedImagefileUpload1 = this.returnBase64;
           };
           reader.readAsDataURL(blob);
    });
  }

  onCroppedUploadBtn(): void {
    if (this.dataURLtoFile && this.croppedImagefileUpload1) {
      const file = this.dataURLtoFile(this.croppedImagefileUpload1, this.filenameForfileUpload1);
      this.fileUploadfileUpload1.files.push(file);
    }
    this.uploadFile = {};
    if (this.displayModal) {
      const displayData = this.getUploadedObjDisplay();
      this.signatureResult = displayData;
      this.uploadFile = displayData;
    } else {
      this.returnUploaded = this.getUploadedObj();
      this.signatureResult.push(this.returnUploaded);
      this.uploadFile = this.returnUploaded;
    }
    this.displayModal = false;
    this.signBtn = false;
    this.viewSign = true;
    this.showSmallSignPreview();
    this.verifyFormData();
  }
  
  showSignaturePreview() {
    this.displayPreview = true;
    let file = this.signature == null ? this.signatureResult?.file : this.signature;
    let blob = this.b64toBlob(file, 'image/png');
    const blobUrl = URL.createObjectURL(blob);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);        
  }

  showSmallSignPreview() {
    let file = this.signature == null ? this.signatureResult?.file : this.signature;
    let blob = this.b64toBlob(file, 'image/png');
    const blobUrl = URL.createObjectURL(blob);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);        
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  onDeletePopup(){
    this.deletePopUp = true;
  }

  onDeleteSignature(){
    this.viewSign = false;
    this.deletePopUp = false;
    this.changeDesigAlert = false;
    if(this.EditMappedUserForm.value.bd_designation.id == 6 || this.EditMappedUserForm.value.bd_designation.id == 7){
      this.disableUpload = false;
      this.signBtn = true;
    }else{
      this.signBtn = true;
      this.disableUpload = true;
    }    
    this.signature = null;
    this.fileName = null;
    if(this.signatureResult){
      this.signatureResult.file = null;
      this.signatureResult.fileName = null;
    }
    this.signDeleted = true;
    this.verifyFormData();
  }

  onSelectNo(){
    this.changeDesigAlert = false;
    const directorName = { bd_designation: this.designationList.find(data => data.id === this.EditMappedUserForm.value.bdDesignationId)};
    this.EditMappedUserForm.patchValue(directorName);
    if(directorName.bd_designation.id == 6 || directorName.bd_designation.id == 7){
      this.signBtn = false;
      this.viewSign = true;
    }else{
      this.viewSign = false;
      this.signBtn = true;
      this.disableUpload = true;
    }   
    this.verifyFormData();
  }
  
}
