
<div class="entityDetailsModal">   
    <p-messages class="custom-message"></p-messages>  
    <div class="p-formgrid p-grid p-m-0"> 
        <div class="p-col-12 p-p-0 fixed-height">
            <div class="p-formgrid p-grid p-m-0">
                <!-- Agreement Details -->
                <div class="p-col-12 p-pt-1 p-px-1 p-mb-3">
                    <p-card class="p-p-0">
                        <div class="ui-fluid p-formgrid p-grid entityDetailsHeader p-mx-0" >
                        
                            <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">Company Code:</label>
                                    <h1 class="p-medium display-4"> {{searchedEditList[0].companyCode}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">Financial Year:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0].financialYear}}</h1>
                                </div>
                            </div>
        
        
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">Assignment Family:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0].assignmentFamily}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">SF Bill ID:</label>
                                    <h1 class="p-medium display-4">{{this.sfBillId}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">Entity Status:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0].entityStatus}}</h1>
                                </div>
                            </div>
                        
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">Total SF Amount:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0].totalSfAmount}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">RPT In CC:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0].rptInCc}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">RPT In CRM:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0].rptInCrm}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">Lot Name:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0].lotNumber}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">Company Level Client Type:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0].clientType}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">BD Owner Name:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0].bdOwnerName}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">Invoice Number:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0].invoiceNumber}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex"> <label class="p-mr-1">Invoice genrated Date:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0].invoiceGeneratedDate | date:'dd-MM-yyyy'}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Cap Classification:</label>
                                    <h1 class="p-medium display-4">{{searchedEditList[0]?.feecapClassification}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Attachment:</label>
                                    <span class="p-medium display-4"><a class="truncated"
                                        (click)="showAttachmentPreview(editAttach[0])">{{editAttach[0]?.fileName}}</a></span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-mt-2 p-px-0 p-text-left">
                                <div class="p-d-flex p-jc-start p-ai-start"> <label class="p-mr-2">Remark:</label>
                                    <h1 class="textwrap p-medium display-4">{{searchedEditList[0]?.remark}}</h1>
                                </div>
                            </div>
                        
                        
                        </div>
                    </p-card>
                
                </div> 
                <div class="p-col-12 p-sm-12 p-p-0">
                    <p-table #mainGrid [value]="searchedEditList"  [(selection)]="searchedEditList"
                        styleClass="p-datatable-gridlines mt-small backoffice-cust-scroll"
                        [scrollable]="true" scrollHeight="400px" scrollDirection="both"
                        [rows]="50" [paginator]="true"
                        [loading]="loadingStatus" (onFilter)="loadingStatus = false;" sortMode="multiple" [multiSortMeta]="[{field: 'sfBillId', order: 1}]">
        
                        <ng-template pTemplate="header">
                            <tr>
                            
                                <th pSortableColumn="agreementId" style="max-width:160px;min-width:160px;">Agreement 
                                    Id <p-sortIcon field="agreementId"></p-sortIcon>
                                </th>
                                <th pSortableColumn="caseType" style="max-width:160px;min-width:160px;">Case
                                    Type <p-sortIcon field="caseType"></p-sortIcon>
                                </th>
                                <th pSortableColumn="sfMandateValidity" style="max-width:310px;min-width:310px;">
                                    Agreement Type <p-sortIcon field="sfMandateValidity">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="initiationType" style="max-width:160px;min-width:160px;">Initiation Type
                                    <p-sortIcon field="initiationType"></p-sortIcon>
                                </th>
                                <th pSortableColumn="comboCase" style="max-width:160px;min-width:160px;">combo Case
                                    <p-sortIcon field="comboCase"></p-sortIcon>
                                </th>
                                <th pSortableColumn="product" style="max-width:160px;min-width:160px;">
                                    Product Name <p-sortIcon field="product">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="instrument" style="max-width:310px;min-width:310px;">Instrument
                                    Name <p-sortIcon field="instrument"></p-sortIcon>
                                </th>
                                <th pSortableColumn="qcDate" style="max-width:160px;min-width:160px;">Qc
                                    Date <p-sortIcon field="qcDate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="reportingDate" style="max-width:160px;min-width:160px;">Reporting Date
                                    <p-sortIcon field="reportingDate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="rrDate" style="max-width:160px;min-width:160px;">RR Date
                                    <p-sortIcon field="rrDate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="rcmDate" style="max-width:160px;min-width:160px;">RCM Date
                                    <p-sortIcon field="rcmDate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="applicableAmount" style="max-width:310px;min-width:310px;">Mandate Qtm (Applicable) as per Nexera
                                    <p-sortIcon field="applicableAmount"></p-sortIcon>
                                </th>
                                <th pSortableColumn="sfActualPercentage" style="max-width:190px;min-width:190px;">SF Actual Percentage
                                    <p-sortIcon field="sfActualPercentage"></p-sortIcon>
                                </th>
                                <th pSortableColumn="sfMethodAmount" style="max-width:190px;min-width:190px;">SF Method Amount
                                    <p-sortIcon field="sfMethodAmount"></p-sortIcon>
                                </th>
                                <th pSortableColumn="revisedSf" style="max-width:190px;min-width:190px;">Revised SF Actual
                                    <p-sortIcon field="revisedSf"></p-sortIcon>
                                </th>
                                <th pSortableColumn="feeRule" style="max-width:160px;min-width:160px;">Fee Rule
                                    <p-sortIcon field="feeRule"></p-sortIcon>
                                </th>
                                <th pSortableColumn="sfMaxAmount" style="max-width:190px;min-width:190px;">SF Maximum Amt
                                    <p-sortIcon field="sfMaxAmount"></p-sortIcon>
                                </th>
                                <th pSortableColumn="sfMinAmount" style="max-width:190px;min-width:190px;">SF Minimum Amt
                                    <p-sortIcon field="sfMinAmount"></p-sortIcon>
                                </th>
                                <th pSortableColumn="sfStartDate" style="max-width:190px;min-width:190px;">SF Start Date
                                    <p-sortIcon field="sfStartDate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="sfEndDate" style="max-width:190px;min-width:190px;">SF End Date
                                    <p-sortIcon field="sfEndDate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="billedDays" style="max-width:160px;min-width:160px;">Billed Days
                                    <p-sortIcon field="billedDays"></p-sortIcon>
                                </th>
                                <th pSortableColumn="sfAmount" style="max-width:160px;min-width:160px;">SF Amount
                                    <p-sortIcon field="sfAmount"></p-sortIcon>
                                </th>
                                <th pSortableColumn="outStandingAmount" style="max-width:190px;min-width:190px;">Outstanding Amount
                                    <p-sortIcon field="outStandingAmount"></p-sortIcon>
                                </th>
                                <th pSortableColumn="billDetails" style="max-width:400px;min-width:400px;">Bill Details
                                    <p-sortIcon field="billDetails"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-opportunity>
                            <tr [pSelectableRow]="opportunity">
                                <td style="max-width:160px;min-width:160px;">
                                    {{opportunity?.agreementId}}
                                </td>
                                <td 
                                    style="max-width:160px;min-width:160px;">
                                    {{opportunity?.caseType}}
                                </td>
                                <td style="max-width:310px;min-width:310px;">
                                    {{opportunity?.sfMandateValidity}}
                                </td>
                                <td style="max-width:160px;min-width:160px;">
                                    {{opportunity?.initiationType}}
                                </td>
                                <td style="max-width:160px;min-width:160px;">
                                    {{opportunity?.comboCase}}
                                </td>
                                <td style="max-width:160px;min-width:160px;">
                                    {{opportunity?.product}}
                                </td>
                                <td style="max-width:310px;min-width:310px;">
                                    {{opportunity?.instrument}}
                                </td>
                                <td style="max-width:160px;min-width:160px;">
                                    {{opportunity?.qcDate | date:'dd-MM-yyyy'}}
                                </td>
                                <td style="max-width:160px;min-width:160px;">
                                    {{opportunity?.reportingDate | date:'dd-MM-yyyy'}}
                                </td>
                                <td style="max-width:160px;min-width:160px;">
                                    {{opportunity?.rrDate | date:'dd-MM-yyyy'}}
                                </td>
                                <td style="max-width:160px;min-width:160px;">
                                    {{opportunity?.rcmDate | date:'dd-MM-yyyy'}}
                                </td>
                                <td style="max-width:310px;min-width:310px;">
                                    {{opportunity?.applicableAmount}}
                                </td>
                                <td style="max-width:190px;min-width:190px;">
                                    {{opportunity?.sfActualPercentage}}
                                </td>
                                <td style="max-width:190px;min-width:190px;">
                                    {{opportunity?.sfAmount}}
                                </td>
                                <td style="max-width:190px;min-width:190px;">
                                    {{opportunity?.revisedSf}}
                                </td>
                                <td style="max-width:160px;min-width:160px;">
                                    {{opportunity?.feeRule}}
                                </td>
                                <td style="max-width:190px;min-width:190px;">
                                    {{opportunity?.sfMaxAmount}}
                                </td>
                                <td style="max-width:190px;min-width:190px;">
                                    {{opportunity?.sfMinAmount}}
                                </td>
        
                                <td style="max-width:190px;min-width:190px;">
                                    {{opportunity?.sfStartDate | date:'dd-MM-yyyy'}}
                                </td>
                                <td style="max-width:190px;min-width:190px;">
                                    {{opportunity?.sfEndDate | date:'dd-MM-yyyy'}}
                                </td>
                                <td style="max-width:160px;min-width:160px;">
                                    {{opportunity?.billedDays}}
                                </td>
                                <td style="max-width:160px;min-width:160px;">
                                    {{opportunity?.sfAmount}}
                                </td>
                                <td style="max-width:190px;min-width:190px;">
                                    {{opportunity?.outStandingAmount}}
                                </td>
                                <td style="max-width:400px;min-width:400px;">
                                    {{opportunity?.billDetails}}
                                </td>
        
                            </tr>
                        </ng-template>
        
                    </p-table>
                </div>
            </div>
        </div>


        
        <div class="p-col-12 p-d-flex p-jc-between p-button-container p-ai-center">
            <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-jc-end p-mb-2">
                <div class="btnwrap">
                    <button pButton class="p-button-brand2 p-button" type="button" (click)="onCancel();" label="Cancel">
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Preivew" [(visible)]="displayPreview"
    class="previewModal modal" [modal]="true" [style]="{width: '95vw', height: '100vh'}" [draggable]="false"
    [resizable]="false">
    <div class="no_data_div tableLoader" *ngIf="previewLoader">
        <div class="app-loading " id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label style="margin-left:0px;">Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <pdf-viewer id="pdf_viewer" *ngIf="isPdf" [src]="urlSafe" [rotation]="rotate" [original-size]="false"
        [show-all]="true" [fit-to-page]="false" [zoom]="zoomValue" [zoom-scale]="'page-width'" [stick-to-page]="false"
        [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="width: 100%; height:100%;"></pdf-viewer>
    <div class="imagePreviewContainer p-d-flex p-jc-center p-ai-center">
        <img *ngIf="!isPdf" src="{{urlSafe}}" id="imagePrev" alt="Image">
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Download" class="p-button-secondary"
            (click)="downloadFile(attachmentResponse,urlSafe)"></button>
    </ng-template>
</p-dialog> 

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="previewFailed" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Unable to preview attachment, Please try again.</p>
</p-dialog>