import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'qc-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentsDetailsComponent implements OnInit {

  listItems;
  paymentForm : FormGroup;
  isApproveAllEnabled = true;

  constructor(private fb : FormBuilder) { }
  
  @Input('form') set paymentsDetailsForm(formVal : FormGroup) {
    this.paymentForm = formVal;
    this.listItems = (<FormGroup>formVal.get('list')).controls;
    console.log("list payment items :: ", this.listItems);
  } 
  
  @Input('disabled') disabled;

  ngOnInit(): void {
    this.setApproveAll();
  }

  approveAllPayments() {
    this.listItems.forEach((item, index) => {
      item.patchValue({['paymentApprove' + index] : 'Approved'});
      item.patchValue({['chequeApprove' + index] : 'Approved'});
      item.patchValue({['depositApprove' + index] : 'Approved'});
      
      item.get('paymentComment').removeValidators([Validators.required, Validators.minLength(3)]);
      item.get('chequeAttachmentComment').removeValidators([Validators.required, Validators.minLength(3)]);
      item.get('depositSlipComment').removeValidators([Validators.required, Validators.minLength(3)]);
      
      
      item.get('paymentComment').updateValueAndValidity();
      item.get('chequeAttachmentComment').updateValueAndValidity();
      item.get('depositSlipComment').updateValueAndValidity();
      
    });
  }

  setApproveAll() {
    // if(this.disabled) { // all fields disabled
      // check if atleast 1 field is enabled, i.e. isDisable must be false for same
      this.isApproveAllEnabled = this.listItems.some(item => !item?.value?.isDisabled);
      this.listItems.forEach((item, index) => {
        // console.log("-->", item.get(`chequeApprove${index}`), "-->", item.get(`chequeApprove${index}`).value);
        if(item.get(`chequeApprove${index}`).value === "Rejected")
          item.get('chequeAttachmentComment').addValidators([Validators.required, Validators.minLength(3)]);
        if(item.get(`depositApprove${index}`).value === "Rejected")
          item.get('depositSlipComment').addValidators([Validators.required, Validators.minLength(3)]);
        if(item.get(`paymentApprove${index}`).value === "Rejected")
          item.get('paymentComment').addValidators([Validators.required, Validators.minLength(3)]);
      })
    // }
  }
  onApprove(value, index, radioButtonkey, commentKey) {
    
    if(!value) {
      this.listItems[index].get(commentKey).addValidators([Validators.required, Validators.minLength(3)]);  
    }
    else {
      this.listItems[index].get(commentKey).removeValidators([Validators.required, Validators.minLength(3)]);
    }
    this.listItems[index].get(commentKey).updateValueAndValidity();
  }

  trackChange(index, item) {
    return item?.value?.data?.id
}
}
