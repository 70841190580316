<div class="layout-content layout-content-strict">
    
    <p-messages class="custom-message"></p-messages>
    <div class="p-formgrid p-grid p-m-0">
        <div class=" p-col-12">
            <div class="ui-fluid p-formgrid p-grid p-ai-center p-m-0">
                <div class="p-col-10 p-lg-9 p-xl-8  p-text-left">
                    <div class=" p-fluid   p-field   p-mb-0 ">
                        <p-breadcrumb [model]="breadcrumbList" [home]="homeicon" (onItemClick)="homeClicked($event)">
                        </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <form>
        <div class="p-formgrid p-grid formFieldContainer p-mt-0">
            <div class="p-col-12 p-mb-4 p-mt-0 p-px-1">
                <p-card class="p-p-0">
                    <div class="p-col-12 p-p-0">
                        <h1 class="p-mb-3 p-medium display-3">Offline Bill Form</h1>
                    </div>
                    <div class="p-col-12 p-p-0">
                        <h1 class="p-medium display-4">Client Details</h1>
                    </div>
                    <div class="p-col-12 p-d-flex p-jc-start p-ai-start p-p-0">
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="client_name">Client Name: </label>
                            <span class="desc" id="client_name">{{billDetails?.clientName}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="contact_name">Contact Name: </label>
                            <span class="desc" id="contact_name">{{billDetails?.contactName}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="rpt_flag">RPT Flag: </label>
                            <span class="desc" id="rpt_flag">{{billDetails?.rptFlag}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-p-0">
                        <div class="p-col-12 p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="rpt_flag_cc">RPT Flag In CC: </label>
                            <span class="desc" id="rpt_flag_cc">{{billDetails?.rptFlagCc}}</span>
                        </div>
                    </div>
                </p-card>
            </div>
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="CRISIL Details">
                    <div class="p-col-12 p-d-flex p-jc-start p-ai-start p-p-0">
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="bd_manager">BD Manager: </label>
                            <span class="desc" id="bd_manager">{{billDetails?.bdManager}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="cost_center">Cost Center: </label>
                            <span class="desc" id="cost_center">{{billDetails?.costCenter}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="operating_department">Operating Department: </label>
                            <span class="desc" id="operating_department">{{billDetails?.operatingDepartment}}</span>
                        </div>
                    </div>
                </p-card>
            </div>
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="Bill Details">
                    <div class="p-col-12 p-d-flex p-jc-start p-ai-start p-p-0">
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="status">Status: </label>
                            <span class="desc" id="status">{{billDetails?.status}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="bill_type">Bill Type: </label>
                            <span class="desc" id="bill_type">{{billDetails?.billType}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="bill_date">Bill Date: </label>
                            <span class="desc" id="bill_date">{{billDetails?.billDate}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-jc-start p-ai-start p-p-0">
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="due_date">Due Date: </label>
                            <span class="desc" id="due_date">{{billDetails?.dueDate}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="currency">Currency: </label>
                            <span class="desc" id="currency">{{billDetails?.currency}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="financial_year">Financial Year: </label>
                            <span class="desc" id="financial_year">{{billDetails?.financialYear}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-jc-start p-ai-start p-p-0">
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="amount_billed">Amount Billed: </label>
                            <span class="desc" id="amount_billed">{{billDetails?.amountBilled}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="bill_approver">Bill Approver: </label>
                            <span class="desc" id="bill_approver">{{billDetails?.billApprover}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="narration">Narration: </label>
                            <span class="desc" id="narration">{{billDetails?.narration}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-jc-start p-ai-start p-p-0">
                        <div class="p-d-flex">
                            <label class="p-mr-2 label_light">Attachment: </label>
                            <div>
                                <a *ngFor="let item of attachmentDetails" class="p-d-block" (click)="preivewAttachment(item)">{{item?.mandateAttachment?.fileName}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-jc-start p-ai-start p-p-0">
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="approver_comment">Approvers Comments: </label>
                            <span class="desc" id="approver_comment">{{billDetails?.approverComments}}</span>
                        </div>
                        <div class="p-md-6 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="non_blr">Non-BLR Mandate: </label>
                            <div>
                                <span class="desc" id="non_blr" class="p-d-block" *ngFor="let item of billDetails?.mandatesListNonBLRForRead">{{item}}</span>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
            <div class="p-col-12 p-mb-4 p-px-1">
                <p-card class="p-p-0" header="History">
                    <div class="p-col-12 p-d-flex p-jc-start p-ai-start p-p-0">
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="requested_on">Requested On: </label>
                            <span class="desc" id="requested_on">{{billDetails?.requestDate | date:'dd-MM-yyyy'}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="requested_by">Requested By: </label>
                            <span class="desc" id="requested_by">{{billDetails?.requesetedBy}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="bill_amount">Bill Amount (Rs): </label>
                            <span class="desc" id="bill_amount">{{billDetails?.amountBilled}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-jc-start p-ai-start p-p-0">
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="acted on">Acted On: </label>
                            <span class="desc" id="acted on">{{billDetails?.actionDate | date:'dd-MM-yyyy'}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="acted_by">Acted By: </label>
                            <span class="desc" id="acted_by">{{billDetails?.billApprover}}</span>
                        </div>
                        <div class="p-md-4 p-px-0 p-d-flex p-jc-start p-ai-start">
                            <label class="p-mr-2 label_light" for="action">Action: </label>
                            <span class="desc" id="action">{{billDetails?.action}}</span>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </form>
</div>
<div class="p-grid p-ml-0 p-mr-0 p-col-12 p-jc-between p-button-container">
    <div class="p-col-5 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-pl-0 left">
        <div class="btnwrap">
            <button pButton class="p-button-brand2" type="button" (click)="backBtn()" label="Back">
            </button>
        </div>
    </div>
</div>

<p-dialog header="Preivew" [(visible)]="displayPreview"
    class="previewModal modal" [modal]="true" [style]="{width: '95vw', height: '100vh'}" [draggable]="false"
    [resizable]="false">
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label class="p-mr-2 label_light">Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <pdf-viewer id="pdf_viewer" [src]="urlSafe" [rotation]="rotate" [original-size]="false"
        [show-all]="true" [fit-to-page]="false" [zoom]="zoomValue" [zoom-scale]="'page-width'" [stick-to-page]="false"
        [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="width: 100%; height:100%;"></pdf-viewer>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
    </ng-template>
</p-dialog>
