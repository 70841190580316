import { Component, Input, OnInit } from '@angular/core';
import { PagesService } from 'app/pages/pages.service';
import {TimelineModule} from 'primeng/timeline';

@Component({
  selector: 'qc-audit-log-details',
  templateUrl: './audit-log-details.component.html',
  styleUrls: ['./audit-log-details.component.scss']
})
export class AuditLogDetailsComponent implements OnInit {
  events3: any;
  segregatedData = [];
  
  @Input() agreementId;
// 136-QC Approved
// 135-Resubmitted for QC
// 134-QC Rejected
// 133-QC Pending

  constructor(private pagesService: PagesService){}
  ngOnInit():void {

      this.pagesService.getAuditLog(this.agreementId).subscribe ((data) => {
        this.events3 = data;
      //   // Handle the API response here
        console.log("Audit log Data...",data);

        for (let i = 0; i < this.events3.length; i++) {
          const item = data[i];
       
          const idPart = {
              "id": item.id,
              "qcStatus": item.qcStatus
          };
       
          const otherPart = {
              "agreementId": item.agreementId,
              "actionedBy": item.actionedBy,
              "actionedOn": item.actionedOn,
              "employeeName": item.employeeName
          };
       
          this.segregatedData.push([idPart, otherPart]);
      }
       
      console.log("Final_Data--",this.segregatedData);
      });
      
  }

}




  

