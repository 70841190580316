<div class="layout-content p-pt-5 p-mt-2">
    <p-messages class="custom-message"></p-messages>
    <div class="p-formgrid p-grid breadcrumb p-m-0">
        <div class=" p-col-12">
            <div class="ui-fluid p-formgrid p-grid p-ai-center p-m-0">
                <div class="p-col-10 p-lg-9 p-xl-8  p-text-left">
                    <div class=" p-fluid   p-field   p-mb-0 ">
                        <p-breadcrumb id="applicationBreadcrumb_id" name="applicationBreadcrumb_name" class=""
                            [model]="applicationBreadcrumbList" [home]="homeiconapplicationBreadcrumb">
                        </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form>
        <div class="p-formgrid p-grid formFieldContainer">
          <!-- Agreement Details -->
          <div class="p-col-12 p-mb-4 p-px-1">
              <p-card class="p-p-0" header="IRF Bill Details">
                  <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">IRF Bill No:</label>
                              <span class="desc">{{payment_bill_details?.irfBillNo}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Bill Amount Before Tax:</label>
                              <span class="desc">{{payment_bill_details?.billAmountBeforeTax}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Total Taxes:</label>
                              <span class="desc">{{payment_bill_details?.totalTaxes}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Bill Amount After Tax:</label>
                              <span class="desc">{{payment_bill_details?.billAmountAfterTaxes}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Client Name:</label>
                              <span class="desc">{{payment_bill_details?.clientName}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Billing Contact Name:</label>
                              <span class="desc">{{payment_bill_details?.billingContactName}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Currency:</label>
                              <span class="desc">{{payment_bill_details?.currency}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Product:</label>
                              <span class="desc">{{payment_bill_details?.product}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Current Limit:</label>
                              <span class="desc">{{payment_bill_details?.currentLimit}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Instrument:</label>
                              <span class="desc">{{payment_bill_details?.instrumentName}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Instrument Amount:</label>
                              <span class="desc">{{payment_bill_details?.instrumentAmount}}</span>
                          </div>
                      </div>
                  </div>
              </p-card>
          </div>

          <!-- Calculation of Outstanding Amount -->
          <div class="p-col-12 p-mb-4 p-px-1">
              <p-card class="p-p-0" header="Calculation of Outstanding Amount">
                  <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                      <div class="p-col-12 p-md-12 p-mt-3 p-px-0 p-text-left">
                        <p-table [value]="payment_bill_details?.archivalMultiplePaymentDTOList" class="p-datatable-gridlines">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th colspan="3" style="text-align: center;">Invoice Amount</th>
                                    <th colspan="4" style="text-align: center;">Collection</th>
                                    <th colspan="3" style="text-align: center;">Amount outstanding</th>
                                </tr>
                                <tr>
                                    <!-- Invoice Amount -->
                                    <th>IRF Amount</th>
                                    <th>ST/GST</th>
                                    <th>Total</th>
                                    
                                    <!-- Collection -->
                                    <th>Bill Amount</th>
                                    <th>ST/GST</th>
                                    <th>TDS(Less)</th>
                                    <th>Total</th>
                                    
                                    <!-- Amount outstanding -->
                                    <th>Bill Amount</th>
                                    <th>ST/GST</th>
                                    <th>Total</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product>
                                <tr>
                                    <td>{{payment_bill_details?.irfAmount}}</td>
                                    <td>{{payment_bill_details?.invoiceStGst}}</td>
                                    <td>{{payment_bill_details?.invoiceTotal}}</td>
                                    <td>{{payment_bill_details?.billAmount}}</td>
                                    <td>{{payment_bill_details?.collectionStGst}}</td>
                                    <td>{{payment_bill_details?.tdsLess}}</td>
                                    <td>{{payment_bill_details?.collectionTotal}}</td>
                                    <td>{{payment_bill_details?.outstandingBillAmount}}</td>
                                    <td>{{payment_bill_details?.outstandingStGst}}</td>
                                    <td>{{payment_bill_details?.outstandingTotal}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                      </div>
                  </div>
              </p-card>
          </div>

          <!-- Payment Information -->
          <div class="p-col-12 p-mb-4 p-px-1">
              <p-card class="p-p-0" header="Payment Information">
                  <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                    <div class="p-col-12 p-md-12 p-mt-3 p-px-0 p-text-left">
                        <div class="p-d-flex"> <label class="p-mr-2 label_light">Total Amount:</label>
                            <span class="desc">{{payment_bill_details?.totalAmount}}</span>
                        </div>
                    </div>
                      <div class="p-col-12 p-md-12 p-mt-3 p-px-0 p-text-left">
                          <p-table [value]="payment_bill_details?.archivalMultiplePaymentDTOList">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="max-width: 150px;min-width: 150px;">Status</th>
                                    <th style="max-width: 150px;min-width: 150px;">Rejected Comments</th>
                                    <th style="max-width: 150px;min-width: 150px;">Mode of Payment</th>
                                    <th style="max-width: 150px;min-width: 150px;">Cheque/DD No</th>
                                    <th style="max-width: 150px;min-width: 150px;">Cheque/DD Date</th>
                                    <th style="max-width: 150px;min-width: 150px;">Gross Amount</th>
                                    <th style="max-width: 150px;min-width: 150px;">Amount</th>
                                    <th style="max-width: 250px;min-width: 250px;">Cheque Received Date</th>
                                    <th style="max-width: 150px;min-width: 150px;">Date Of Deposit</th>
                                    <th style="max-width: 150px;min-width: 150px;">Bank Name</th>
                                    <th style="max-width: 150px;min-width: 150px;">Branch Name</th>
                                    <th style="max-width: 150px;min-width: 150px;">System Date</th>
                                    <th style="max-width: 300px;min-width: 300px;">Comment</th>
                                    <th style="max-width: 250px;min-width: 250px;">Scanned Cheque / DD</th>
                                    <th style="max-width: 150px;min-width: 150px;">Type of Upload</th>
                                    <th style="max-width: 250px;min-width: 250px;">Deposit Slip /Approval Confirmation</th>
                                    <th style="max-width: 150px;min-width: 150px;">Attachment</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-payment>
                                <tr>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.status}}</td>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.rejectionComments}}</td>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.modeOfPayment}}</td>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.chequeNo}}</td>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.chequeDate}}</td>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.grossAmount}}</td>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.amount}}</td>
                                    <td style="max-width: 250px;min-width: 250px;">{{payment.chequeRecievedDate}}</td>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.dateOfDeposit}}</td>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.bankName}}</td>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.bankBranch}}</td>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.systemDate}}</td>
                                    <td style="max-width: 300px;min-width: 300px;">{{payment.comment}}</td>
                                    <td style="max-width: 250px;min-width: 250px;">
                                        <a (click)="showPaymentDetailsAttachmentPreview(payment?.scannedChequeFileName,payment?.scannedChequeFileBase64)">{{payment.scannedChequeFileName}}</a>
                                    </td>
                                    <td style="max-width: 150px;min-width: 150px;">{{payment.typeOfUpload}}</td>
                                    <td style="max-width: 250px;min-width: 250px;">
                                        <a (click)="showPaymentDetailsAttachmentPreview(payment?.depositSlipFileName,payment?.depositSlipFileBase64)">{{payment.depositSlipFileName}}</a>
                                    </td>
                                    <td style="max-width: 150px;min-width: 150px;">
                                        <a (click)="showPaymentDetailsAttachmentPreview(payment?.attachmentFileName,payment?.attachmentFilePath)">{{payment.attachmentFileName}}</a>
                                    </td>
    
                                </tr>
                            </ng-template>
                        </p-table>
                      </div>
                  </div>
              </p-card>
          </div>

          <!-- TDS Information -->
          <div class="p-col-12 p-mb-4 p-px-1">
              <p-card class="p-p-0" header="TDS Information">
                  <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Enter TDS Amount:</label>
                              <span class="desc">{{payment_bill_details?.tdsAmount}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Attach TDS Certificate:</label>
                              <span class="desc">{{payment_bill_details?.tdsCertificateFilePath}}</span>
                          </div>
                      </div>
                  </div>
              </p-card>
          </div>

          <!-- History -->
          <div class="p-col-12 p-mb-4 p-px-1">
              <p-card class="p-p-0" header="History">
                  <div class="ui-fluid p-formgrid p-grid entityDetailsHeader">
                      <div class="p-col-12 p-md-4 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Revised By:</label>
                              <span class="desc">{{payment_bill_details?.revisedBy}}</span>
                          </div>
                      </div>
                      <div class="p-col-12 p-md-8 p-mt-3 p-px-0 p-text-left">
                          <div class="p-d-flex"> <label class="p-mr-2 label_light">Revision Date:</label>
                              <span class="desc">{{payment_bill_details?.revisedOn | date:'dd/MM/yyyy'}}</span>
                          </div>
                      </div>
                  </div>
              </p-card>
          </div>
      </div>
  </form>
</div>

<div class="p-col-12 p-d-flex p-jc-between p-button-container p-ai-center">
  <div class="p-col-12 p-d-flex p-text-left p-jc-between p-pl-0">
      <div class="btnwrap">
          <button pButton class="p-button-brand2 p-button" type="button" (click)="onBackClick();" label="Back">
          </button>
      </div>
  </div>
</div>

<p-dialog header="Preview" [(visible)]="displayPreview" class="docPreview previewModal" [modal]="true"
    [style]="{width: '95vw', height: '100vh'}" [draggable]="false" [resizable]="false">
    <div class="no_data_div tableLoader" *ngIf="dataLoader">
        <div class="app-loading " id="loader">    
            <div class="loadbg"></div>    
            <div class="bdload">    
                <div class="spinner">    
                    <div class="rect1"></div>    
                    <div class="rect2"></div>    
                    <div class="rect3"></div>    
                    <div class="rect4"></div>    
                    <div class="rect5"></div>    
                    <span>Loading...</span>    
                </div>    
            </div> 
        </div>    
    </div>
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label>Zoom : </label>
        <!-- <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus" class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh" class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button> -->
    </div>
    <pdf-viewer
      id="pdf_viewer"
      [src]="urlSafe"
      [original-size]="false"
      [show-all]="true"
      [fit-to-page]="false"
      
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      style="width: 100%; height:100%;"
    ></pdf-viewer>
    <!-- <div class="imagePreviewContainer p-d-flex p-jc-center p-ai-center">
        <img *ngIf="!isPdf" src="{{urlSafe}}" id="imagePrev" alt="Image">
    </div> -->
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Download" (click)="downloadFile()" class="p-button-secondary"></button>
    </ng-template>
</p-dialog>

<!-- error modal -->
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>