import { DatePipe, Location } from '@angular/common';
import { Component, OnInit, ViewChildren, ViewChild, Renderer2, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { PagesService } from '../../pages/pages.service';
import { DialogService } from 'primeng/dynamicdialog';
import { SfBillingService } from '../sf-billing.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { MessageService } from 'primeng/api';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { AppLoaderService } from 'app/shared/util/app-loader.service';
import { Table } from 'primeng/table';
@Component({
  selector: 'backoffice-bd-approval-grid',
  templateUrl: './bd-approval-grid.component.html',
  styleUrls: ['./bd-approval-grid.component.scss'],
  providers:[MessageService, DialogService]
})
export class BdApprovalGridComponent implements OnInit, AfterViewInit {
  @ViewChildren('mainGrid') mainGrid;
  @ViewChild('table2id', {static: false}) public table: Table;
  // ngForm = this.fb.group({
  //   financialYearField: [null as any || null, []],
  //   companyNameField: [null, []],
  //   onbahalfof: [null, []],
  //   sfBillIdField: [null as any || null, []],
  //   // primeNgCalendar: new FormControl([], []),
  // });
  ccprimebreadcrumbList = [
    { label: `BD Approval Listing`, routerLink: ['/sf-billing/bd-approval-grid'] },
  ];

  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  loadingStatus = false; 
  showLoader: boolean = false;
  searchedEditList: any[] = [];
  gridLoader: boolean = false;
  apiFail: boolean = false;
  disableSearchBtn: boolean = false;

  financialYear;
  selectedCompanyName;
  sfBillIdField;

  companyNameList;
  sfBillIdFieldList;

  financialYearListUnfiltered;
  financialYearList;

  defaultValues = {
    financialYear: '2024-25'
  }
  filterSubjectCompanyName = new Subject<string>();
  filterSubjectSfBillId = new Subject<string>();
  excelIds: any[] = [];
  userRole: any;
  userId: any;
// On Behalf new fild
onBehalfOfSearchList:any[] =[];
showOnBehalfLoader: boolean = false;
permission: any;
onBehalfOfList: any[] = [];
emptyMsgFlag: boolean = false;
columnVisible = true;
table2List: any[] = [];
loggedInUserData: any[] = [];
onBehalfofEmployeeName: any;
tableVisible = false;
dataLoader: boolean = false;
isCreatedBySelected: boolean = false;
showOnBehalfOfCross: boolean = false;
dynamicUserId1: any;
timeOut = false;
table2LoadAtOnce: any[] = [];
refreshDisabled: boolean = false;
onBehalfFilter: any;
loadingtable2 = false;
filtersApplied = false;
dropdownAcess = false;
onBehalfEmployeeId :any;
  bdApprovedMsg: string="";
  billStatusMasterData:any;
  onbahalfof;
  scopeList: any;
  dropdownAcess1 = false;
  emptyMsgVal: string = 'No User Found.';

  infoMsg: any;
  userName:any;
  routerSubscription : Subscription;
  navigatedTo: string = '';
  traceBackFilters:any;
  checkNavigation:string = "/bd-approval-details";
  currentBdOwner: null;

  constructor(private _location: Location, private fb: FormBuilder, private dialogService: DialogService,private renderer: Renderer2,private cd: ChangeDetectorRef, private sfBillingService: SfBillingService, private router: Router, private datePipe: DatePipe, private route: ActivatedRoute, private messageService: MessageService, private encyDyc: EncryptDecryptInterceptor, private loaderService: AppLoaderService ) {
    
    this.userRole = this.encyDyc.decrypt(localStorage.getItem('role'));
    this.userId = this.encyDyc.decrypt(localStorage.getItem('empid'));
    this.userName =this.encyDyc.decrypt(localStorage.getItem('empName'));
    const navigation = this.router.getCurrentNavigation();
    this.infoMsg = navigation?.extras?.state?.msg;

    this.loaderService.showPageLoader();
    // console.log("this.sfBillingService.sharedMsgString",this.sfBillingService.sharedMsgString);
    // this.messageService.add({ severity: 'success', detail: this.sfBillingService.sharedMsgString });
    console.log("userRole",this.userRole)
  }
  
  ngAfterViewInit(): void {
    console.log("bdApprovalFilters",JSON.parse(this.encyDyc.decrypt(localStorage.getItem('bdApprovalFilters'))));
    if(this.infoMsg === 'bd approved'){
      this.messageService.add({ severity: 'success', summary: 'SF Bill Id approved successfully'});
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    }else if(this.infoMsg === 'bd rejected'){
      this.messageService.add({ severity: 'success', summary: 'SF Bill Id rejected successfully'});
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    }
    
  }
  
  ngOnInit(): void {
    this.permission = this.encyDyc.decrypt(localStorage.getItem('permission'));
    this.getOnBehalfOfList();
    this.userScopePermission();
    this.scopeList = this.encyDyc.decrypt(localStorage.getItem('scopes'));
    console.log("scopeList",this.scopeList);
    console.log("this.userRole");
    console.log("this.userRole",this.userRole);
    console.log("this.userRole",this.userRole);
    setTimeout(() => {
        this.traceBackFilters = JSON.parse(this.encyDyc.decrypt(localStorage.getItem('bdApprovalFilters')));
        if(this.traceBackFilters != null){
        if(this.traceBackFilters?.financialYear){
          this.financialYear = this.traceBackFilters.financialYear;
        }
        if(this.traceBackFilters?.companyFetchedName){
          this.selectedCompanyName = this.traceBackFilters.companyFetchedName;
          this.companyNameList = [this.traceBackFilters.companyFetchedName];
        }
        if(this.traceBackFilters?.fetchedSfBillId){
          this.sfBillIdField = this.traceBackFilters.fetchedSfBillId;
          this.sfBillIdFieldList = [this.traceBackFilters.fetchedSfBillId];
        }
        console.log("this.traceBackFilters",this.traceBackFilters);
        this.loaderService.hidePageLoader();
        this.cd.detectChanges();
        this.searchList();
      }
    }, 500);

    //initialized the company name api and sf bill id api to show options under the fields
    this.getDropdownValuesApis();
    this.financialYear = this.defaultValues.financialYear;
    // this.searchList();
    console.log('filters', this.financialYear);
    console.log('filters', this.selectedCompanyName);
    console.log('filters', this.sfBillIdField);
    
    this.loaderService.hidePageLoader();
  }
  
  userScopePermission(): void {
    // let scope: any = this.scopeList.split(",")

    // if (scope.indexOf('ENTITY_READ') != -1) {
    //   this.pageVisible = true;
    // }
    // if (scope.indexOf('ENTITY_CREATE') != -1) {
    //   this.addButton = true;
    //   this.onCreateScope = false;
    // }
    console.log("permission",this.permission);
    if (this.permission == 'PORTFOLIO') {
      this.dropdownAcess1 = true;
    }
  }

  getDropdownValuesApis(){
    //Financial Year
    this.sfBillingService.getFeeCapFinancialYear().subscribe({
      next: (data:any)=>{
        this.financialYearListUnfiltered = data;
        const transformedDataFY = this.financialYearListUnfiltered.map(item => { return {id: item.id, label: item.financialYearDescription, value: item.financialYearDescription};});
        this.financialYearList = transformedDataFY;

        console.log("FinancialYear data",data);
      },
      error: (err:any)=>{
        console.log("FinancialYear err",err);
      }
    });
  }

  homeBreadcrumbClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}/#/nexera/dashboard/home`;
    }
  }

  onBackClick(): void {
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  onInputFieldChange(){
    console.log("on change financial year", this.financialYear);
    console.log("on change financial year", this.selectedCompanyName);
    console.log("on change financial year", this.sfBillIdField);
    console.log("on Behalf filed", this.onbahalfof);
    
    if(this.financialYear == null){
      this.disableSearchBtn = true;
    }else{
      this.disableSearchBtn = false;
    }
    //   if(this.selectedCompanyName || this.sfBillIdField){
    //   }else{
    //     this.disableSearchBtn = true;
    //   }
    // }else{
    //   this.disableSearchBtn = true;
    // }
  }

  searchList(){
    this.gridLoader = true;
    this.apiFail = false;
    let selectedCompanyCode;
    let selectedSfBillId;
    //  this.onBehalfClear();
    if(this.selectedCompanyName == undefined || this.selectedCompanyName == null || this.selectedCompanyName === ""){
      selectedCompanyCode = null;
    }else{
      selectedCompanyCode = this.selectedCompanyName.companyCode;
    }
    
    if(this.sfBillIdField == undefined || this.sfBillIdField == null || this.sfBillIdField === ""){
      selectedSfBillId = null;
    }else{
      selectedSfBillId = this.sfBillIdField.sfBillId;
    }
    // if(this.onbahalfof == undefined || this.onbahalfof == null || this.onbahalfof === ""){
    //   this.onbahalfof = null;
    // }else{
    //   this.onbahalfof = this.onbahalfof;
    // }
    console.log("onbahalfof name :", this.onbahalfof);
    console.log("sf bill id :", selectedSfBillId);

    let currentBdOwner;

    if(this.userRole === "ADMIN" || this.userRole === "IT"){
      currentBdOwner = null;
      if(this.onbahalfof){
        currentBdOwner = this.onbahalfof?.shadowPortfolioEmployeeId;
      }else{
        currentBdOwner = null;
      }
    }else
    {
      currentBdOwner = this.userId;
      if(this.onbahalfof){
        currentBdOwner = this.onbahalfof?.shadowPortfolioEmployeeId;
      }else{
        currentBdOwner = this.userId;
      }
    }
    // this.currentBdOwner = this.onBehalfEmployeeId;
    // currentBdOwner = this.onbahalfof;
    console.log("this.onbehalfof1", this.onbahalfof);
    console.log("this.onbehalfof2", currentBdOwner);
    console.log("this.onbehalfof3", currentBdOwner?.shadowPortfolioEmployeeId);
    
    let payload = {
      "companyName": selectedCompanyCode,
      "financialYear": this.financialYear,
      "sfBillId":selectedSfBillId,
      "billStatus":[147],
      "bdOwnerId": currentBdOwner 
    }
    console.log("payload",payload);
    
    this.sfBillingService.sfEditAndBdApprovalGridApi(payload).subscribe({
      next: (sfCaseDetails: Array<any>) => {
        this.searchedEditList = sfCaseDetails;


        // if(this.userRole !== "ADMIN"){
        //   this.searchedEditList = this.searchedEditList.filter(item => item.bdOwner == this.userName)
        // }

        
        this.gridLoader = false;
        this.apiFail = false;
        this.resetFilters();
        this.fetchDataFromMaster();
      },
      error: (err) => {
        this.gridLoader = false;
        this.apiFail = true;
      }
    })
  }

  fetchDataFromMaster(){
    this.sfBillingService.getBillStatusMaster().subscribe((data:any)=> {
      this.billStatusMasterData = data;
      this.combineBillStatusMasterAndBdCase();
    });
  }
  
  combineBillStatusMasterAndBdCase(){
    if(this.searchedEditList){
      const details = this.searchedEditList;
      let productDetails = this.billStatusMasterData;
      details.forEach((item) => {
        const matchedBillStatus = productDetails.find((mitem)=> mitem.id === item.billStatus);
        if(matchedBillStatus){
          item.billStatusName = matchedBillStatus.property;
        }
      });
    }
  }

  resetFilters(){
    // this.financialYear = this.defaultValues.financialYear;
    // this.selectedCompanyName = null;
    // this.sfBillIdField = null;
  }

  showErrorMsg(data:any){
    if(data?.errorStatus != null && data?.errorStatus != '' && data?.errorStatus != undefined){
      let invoiceDetailsId = "tooltipOpp_"+data.sfBillId;
      document.getElementById(invoiceDetailsId)?.setAttribute('data-tooltip', data?.errorStatus);
      this.renderer.addClass(document.body, 'overflow-scroll');
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
    
  }
  
  showCompanyDetailsPage(opp:any){
    console.log("clicked company details", opp);
    this.router.navigate(['/sf-billing/bd-approval-details',opp.sfBillId])
    
    // localStorage.setItem('bdApprovalFilters', this.encyDyc.encrypt(JSON.stringify(filters)));
    // this.routerSubscription = this.router.events.subscribe(events => {
    //   if(events instanceof NavigationEnd){
    //     // this.navigatedTo = events.url;
    //   }
    // })
    this.navigatedTo = '/sf-billing/bd-approval-details/'+opp.sfBillId;
  }


  onCompanyNameFilter(e){
    console.log("company name filter",e);
    console.log("company name filter",e.filter);
    
    const query: string = e.filter;
    if (query?.length >= 4) {
      console.log("company name filter",query);
      this.fetchedCompanyNameDatafromAPI(query);
    }else if(query?.length <= 3){
      this.companyNameList = [];
    }
  }

  fetchedCompanyNameDatafromAPI(filterString:string){
    this.sfBillingService.getCompanyNames(filterString).subscribe((data:any)=>{
      console.log("onfilter 1",data);
      this.companyNameList = data;
      // if(this.selectedCompanyName==null || this.selectedCompanyName.length==0){
      // }else{
      //   this.companyNameList = [...this.selectedCompanyName, ...data];
      //   this.companyNameList = this.companyNameList.map(data=> data.companyName);
      //   console.log("onfilter 2",this.companyNameList);
      // }
      console.log("onfilter 3",this.companyNameList);
    })
  }

  onSfBillIdFilter(e){
    console.log("company name filter",e);
    console.log("company name filter",e.filter);
    
    const query: string = e.filter;
    if(query != null){
      this.sfBillingService.getSfBillId(query).subscribe((data:any)=>{
        // this.sfBillIdFieldList = data.map(id => ({sfBillId: id}));
        this.sfBillIdFieldList = data;
        console.log("data sfbillid",this.sfBillIdFieldList);
        
      })
    }else{
      this.sfBillIdFieldList = [];
    }
    
  }

  resetForm(){
    console.log("onclear financial year 1", this.financialYear);
    
    // if (this.financialYear)
    this.financialYear = this.financialYearList[1].label;
    console.log("onclear financial year 2", this.financialYear);
  }

  exportExcel() {
    let year;
    let selectedcompany;
    let selectedsfbillid;


    if(this.financialYear == undefined || this.financialYear == null || this.financialYear === ""){
      year = null
    }else{
      year = this.financialYear;
    }


    if(this.selectedCompanyName == undefined || this.selectedCompanyName == null || this.selectedCompanyName === ""){
      selectedcompany = null;
    }else{
      selectedcompany = this.selectedCompanyName.companyCode;
    }
    
    if(this.sfBillIdField == undefined || this.sfBillIdField == null || this.sfBillIdField === ""){
      selectedsfbillid = null;
    }else{
      selectedsfbillid = this.sfBillIdField.sfBillId;
    }


    // let currentBdOwner;
    // if(this.userRole === "ADMIN" || this.userRole === "IT"){
    //   currentBdOwner = null;
    // }else{
    //   currentBdOwner = this.userId;
    // }

    
    let currentBdOwner;
    if(this.userRole === "ADMIN" || this.userRole === "IT"){
      currentBdOwner = null;
      if(this.onbahalfof){
        currentBdOwner = this.onbahalfof?.shadowPortfolioEmployeeId;
      }else{
        currentBdOwner = null;
      }
    }else
    {
      currentBdOwner = this.userId;
      if(this.onbahalfof){
        currentBdOwner = this.onbahalfof?.shadowPortfolioEmployeeId;
      }else{
        currentBdOwner = this.userId;
      }
    }
    // currentBdOwner = this.onbahalfof;
    console.log("this.onbehalfof1", this.onbahalfof);
    console.log("this.onbehalfof2", currentBdOwner);
    console.log("this.onbehalfof3", currentBdOwner?.shadowPortfolioEmployeeId);

    let excelpayload = {
      "financialYear":year,
      "companyName":selectedcompany ,
      "sfBillId": selectedsfbillid,
      "bdOwnerId": currentBdOwner
    };

    let date = new Date();
    let formattedDate = this.datePipe.transform(date, 'ddMMyyyy');
    let formattedTime = this.datePipe.transform(date, 'HHmmss');
    
    
    let fileName = `Pending For BD Approval${formattedDate}${formattedTime}.xlsx`;
    this.sfBillingService.getExportBdApproval(excelpayload).subscribe((res) => {
      /** src = `data:application/vnd.ms-excel;base64,${res.base64}`;
      const link = document.createElement('a');
      link.href = src;
      link.download = fileName;
      link.click(); **/
      const byteCharacters = atob(res.Base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "text/xlsx" });
      FileSaver.saveAs(blob, fileName);
    });
  }

  companyNameOnClear(){
    this.companyNameList = []
  }

  sfBillIDOnClear(){
    this.sfBillIdFieldList = []
  }

  onBehalfClear(){
    console.log("onBehalfOfSearchList clear called");
    // this.onSelectOnbeahalfOfEmployee(event);
    this.onBehalfOfSearchList=[];
    this.onbahalfof = undefined;
    console.log("onBehalfOfSearchList after clear",this.onBehalfOfSearchList);
    this.showOnBehalfOfCross = true;
    this.currentBdOwner=null;
  }
  resetFinancialYearDropdown(){
    console.log("onclear financial year 1", this.financialYear);
    
    // if (this.financialYear)
    this.financialYear = this.defaultValues.financialYear;
    console.log("onclear financial year 2", this.financialYear);
  }

  checkNavigationStrings():boolean{
    return this.navigatedTo.includes(this.checkNavigation);
  }

  ngOnDestroy() {
    console.log("this.navigatedTo",this.navigatedTo);
    if(this.checkNavigationStrings()){
      this.traceBackFilters = {
        financialYear: this.financialYear,
        companyFetchedName: this.selectedCompanyName,
        fetchedSfBillId: this.sfBillIdField
      }
      localStorage.setItem('bdApprovalFilters', this.encyDyc.encrypt(JSON.stringify(this.traceBackFilters)));
      this.navigatedTo = '';
    }else{
      this.traceBackFilters = null;
      localStorage.setItem('bdApprovalFilters', this.encyDyc.encrypt(JSON.stringify(this.traceBackFilters)));
      this.navigatedTo = '';
    }
  }
  // On Behalf chnages
  loadOnBehalfOfSuggesstions(event){
    if(event.query.length >= 4){
      this.showOnBehalfLoader = true;
      
      // this.empId = 
      var empId = this.encyDyc.decrypt(localStorage.getItem('empid'));
      

      let payload = {
        "shadowEmployeeId": empId, "nameStartChar": event.query, "permission": this.permission
      }

      this.sfBillingService.getUserList(payload).subscribe((results: any) => {
        if (results) {
          this.showOnBehalfLoader = false;

          this.onBehalfOfSearchList = [];
          const responseData = results;
          if (responseData && responseData.length > 0) {
            this.onBehalfOfSearchList = responseData;
            console.log("onBehalfOfSearchList Response data :",this.onBehalfOfSearchList);
            this.onBehalfOfSearchList.forEach((response, index) => {
              if (response.shadowPortfolioEmployeeId == this.encyDyc.decrypt(localStorage.getItem('empid'))) {
                console.log("response.shadowPortfolioEmployeeId",response.shadowPortfolioEmployeeId);
                this.onBehalfOfList.splice(index, 1);
              }
            });
          } else {
            this.onBehalfOfSearchList = [];
            this.emptyMsgFlag = true;
          }
        }
      },(_err) => {
        this.onBehalfOfSearchList = []
        this.showOnBehalfLoader = false;
        this.emptyMsgFlag = true;
      });
    }
  }

  getOnBehalfOfList(): void {
    this.sfBillingService.getOnBehalfOfList(this.permission).subscribe(results => {
      const responseData = results;
      this.onBehalfOfList = responseData;
      console.log("getOnBehalfOfList() Method data :",this.onBehalfOfList);
      this.onBehalfOfList.forEach((response, index) => {
        if (response.shadowPortfolioEmployeeId == this.encyDyc.decrypt(localStorage.getItem('empid'))) {
          this.onBehalfOfList.splice(index, 1);
        }
      })
    });
  }
  // onClearsearch1(): void {
  //   this.columnVisible = true;
  //   if(!this.ngForm.controls?.onbahalfof?.value)
  //     this.table2List = this.loggedInUserData;  // refresh table, if no shadowPortfolio selectedl
  //   else {
  //     this.table.filterGlobal('', 'contains');
  //   }
  //   // this.ViewEntityForm.controls.onbahalfof.reset();
  //   this.onBehalfofEmployeeName = null;
  //   this.ngForm.controls.hiearachy.reset();
  //   if (this.table2List && this.table2List.length > 0) {
  //     this.tableVisible = false;
  //   } else {
  //     this.tableVisible = true;
  //   }
  // }

  onSelectOnbeahalfOfEmployee(event: any): void {
    this.dataLoader = true;
    this.isCreatedBySelected = false;
    // this.ngForm.controls.hiearachy.reset();
    this.columnVisible = true;
    // this.ngForm.controls.search1.reset();
    this.table?.filterGlobal('', 'contains');
    console.log("onSelectOnbeahalfOfEmployee()  called",event);
    if (event?.value.shadowPortfolioEmployeeId == null) {
      this.showOnBehalfOfCross = false;
       this.dataLoader = false;
      this.dynamicUserId1 = null;
      console.log("onselectOnBehalf if staement executed",event?.value.shadowPortfolioEmployeeId);
      this.gettable2List("","");
    } else {
      this.showOnBehalfOfCross = true;
      let empId = event?.value.shadowPortfolioEmployeeId;
      this.onbahalfof=event?.value;
      console.log("onselectOnBehalf else staement executed",this.onbahalfof);
      this.onBehalfofEmployeeName = event?.value.shadowPortfolioEmployeeNameAndDesignation;
      this.dynamicUserId1 = empId;
      this.loadtable2Lazy(event);
    }
  }
  gettable2List(sDate?: any, eDate?: any): void {
    this.dataLoader = true;
    let userId: any;
    userId = this.sfBillingService.getUserIdonBehalfOf(this.dynamicUserId1);
     }
  loadtable2Lazy(event: any): any {
    //simulate remote connection with a timeout
    this.loadingtable2 = true;
    setTimeout(() => {
      let loadedRows = null;

      if (this.filtersApplied) {
        loadedRows = this.table2List.slice(event.first, event.first + event.rows);
        this.loadingtable2 = false;
      } else {
        loadedRows = this.table2LoadAtOnce.slice(event.first, event.first + event.rows);
        this.loadingtable2 = false;
      }

      //populate page of virtual cars
      Array.prototype.splice.apply(this.table2List, [event.first, event.rows, ...loadedRows]);

      //trigger change detection
      this.table2List = [...this.table2List];
      this.loadingtable2 = false;
    }, 1000);
  }
  // clearOnBehalfOfSearch(){
  //   this.ngForm?.controls?.onbahalfof?.reset();
  //   this.showOnBehalfOfCross = false;
  //   // this.onClearsearch1();
  //   let filters = {
  //     // entityName: this.nameFilter,/
  //     // createdBy: this.createdbyFilter,
  //     onBehalf: null, 
  //     // date: this.dateFilter
  //   }
  //   localStorage.setItem('entityFilters', this.encyDyc.encrypt(JSON.stringify(filters)));
  // }
  
}
