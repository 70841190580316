import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, RadioControlValueAccessor, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { ImageTransform } from 'ngx-image-cropper';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
@Component({
  selector: 'backoffice-sf-reporting-screen-details',
  templateUrl: './sf-reporting-screen-details.component.html',
  styleUrls: ['./sf-reporting-screen-details.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class SfReportingScreenDetailsComponent implements OnInit {

  rptoverallStatus: any;
  rptPopup: boolean = false;
  activeIndex: number = 0;
  activeAttachmentIndex: number = 0;
  contactChecked: boolean = false;
  ibPayment: boolean = false;
  contactChange: boolean = false;
  disabledDropdown: boolean = true;
  onSaveLoader: boolean = true;
  paymentDetailsMissing: boolean = false;
  payment1added: boolean = false;
  payment2added: boolean = false;
  payment3added: boolean = false;
  isOpportunityWonRecd: boolean = false;
  isOpportunityReportInvoice: boolean = false;
  isOpportunityReportOnly: boolean = false;
  isOpportunityRejected: boolean = false;
  isOpporutnityOtherStatus: boolean = false;
  partyCommentM: boolean = false;
  isFirstNoPayment: boolean = false;
  isSecondNoPayment: boolean = false;
  isThirdNoPayment: boolean = false;
  isPartyApprovalMissing: boolean = false;
  ibpMandatory: boolean = false;
  ibpError: boolean = false;
  saveBtnClicked: boolean = false;
  displayEditTypeModal: boolean = false;
  feeType: string = '';
  OpportunityId: any;
  OpportunityGridDetails: any;
  updatedAttachmentType: any;
  idealFeeSfExpected: number;
  opportunityDetails: any = {};
  feeDetails: any = {};
  rptStatus: any = {};
  sfBilldata: any = {};
  contactDetails: any = {};
  editingAttachment: any = {};
  paymentDetails: any = [];
  paymentDetailsData:any = [];
  tdsTypeList: any = [];
  bankList: any = [];
  paymentTypeList: any = [];
  paymentModeList: any = [];
  salutationList: any = [];
  designationList: any = [];
  gstTypeList: any = [];
  irfDiscountReasonList: any = [];
  paymentValidationList: any = [];
  uploadedDocuments: any[] = [];
  attachmentTypeList: any = [];
  attachmentTypeMaster: any = [];
  attachmentChangeTypeList: any = [];
  cityList: any = [];
  partyApprovalList = [{name: "Yes"}, {name: "No"}];
  canvasRotation: number = 0;
  currentFileEvent: any;
  zoomValue: number = 1;
  rotate:number = 0
  displayModal: boolean = false;
  imageChangedEventfileUpload1: any;
  filenameForfileUpload1: any;
  returnBase64: any;
  imgChangeEvt: any;
  transform: ImageTransform = {};
  croppedImagefileUpload1: any = '';
  fileUploadfileUpload1: any = {
    files: []
  };
  uploadFile: any = {};
  previewFile: any = {}
  attachmentResult: any[] = [];
  baList: any[] = [];
  reasonList: any[] = [];
  actionReasonList: any[] = [];
  empId: string;
  fileType: any;
  fileCode: any;
  imageSrc: any;
  returnUploaded: any;
  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;
  isAttachmentM: boolean = true;
  deleteFileData: any = {};
  deleteFile: boolean = false;
  openedFile: any;
  displayPreview: boolean = false;
  dataLoader: boolean = false;
  isPdf: boolean = true;
  validationPopup: boolean = false;
  urlSafe: any;
  submitedAttachmentType: any;
  SelectedAttachmentType: any;
  errorMessageOnSave: string = "";
  errorOpportunityDetails: boolean = false;
  errorContactDetails: boolean = false;
  errorAttachments: boolean = false;
  errorAction: boolean = false;
  errorApprovals: boolean = false;
  dynamicDialogdialogRef: DynamicDialogRef = new DynamicDialogRef;
  reportingValidationRef: DynamicDialogRef = new DynamicDialogRef;
  isBAaction: boolean = false;
  billIdFinalised: boolean = false;
  invoiceGenereatedPopup: boolean = false;
  contactDetailsMatch: boolean = false;
  partyApprovalSelected: boolean = true;
  contactDetailsMatchPopup: boolean = false;
  continueBillingClicked: boolean = false;
  // partyNotSelected:boolean = false;
  partyComment:boolean = false;
  isActionCommentM: boolean = false;
  isActionCommentError: boolean = false;
  isActionReasonM: boolean = false;
  reportedAndInvoiced: boolean = false;
  actionReasonError: boolean = false;
  crmContactPopup: boolean = false;
  actionReasons: any = [];
  fileTypeId: any;
  crmContacts: any = [];
  crmSingleContact: any = {};
  selectedCrmContact: any;
  email1: any;
  email2: any;
  email3: any;
  originalContactId: any;
  crmContactId: any;
  previewFailed: boolean = false;
  apiFail: boolean = false;
  invoiceApiFailed: boolean = false;
  disabledOnApiFail: boolean = false;
  userId: any;
  userName: string = '';
  attachmentComment: any = '';
  actionAndReasons: any = [];
  actionDetails: any = {};
  reportingReasons: any = [];
  baValue: string = '';
  ogAttachmentType: any;
  pushedOther: any;
  updatedAttachmentCode: any;
  baMap: any = {
    'Reject' : 'REJECT',
    'Report & Invoice' : 'REPORT_AND_INVOICE',
    'Report Only' : 'REPORT_ONLY'
  }
  openedPaymentData: FormArray;
  isCheque: boolean;

  autoInitiateList: any[] = [{ "label": "Yes", "value": "Yes" }, { "label": "No", "value": "No" }];
  autoInitiateValid: boolean = false;
  autoInitiateSelectedValue: any = null;

  opportunityDetailsForm = this.fb.group({
    originalBilledValue: [null as any, []],
    sfActualFee: [null as any, []],
    sfDiscountPremium: [null as any, []],
    sfDiscountPremiumPercent: [null as any, []],
    sfDiscountReason: [null, []],
    paymentMode1: [null, []],
    neftCheque1: [null, [Validators.required, Validators.minLength(1)]],
    bank1: [null, [Validators.required]],
    paymentDate1: [null, [Validators.required]],
    paymentType1: [null, []],
    tdsType1: [null, []],
    tdsAmount1: [null, []],
    amountCollected1: [null, []],
    grossAmount1: [null, []],
    paymentMode2: [null, []],
    neftCheque2: [null, [Validators.required, Validators.minLength(1)]],
    bank2: [null, [Validators.required]],
    paymentDate2: [null, [Validators.required]],
    paymentType2: [null, []],
    tdsType2: [null, []],
    tdsAmount2: [null, []],
    amountCollected2: [null, []],
    grossAmount2: [null, []],
    paymentMode3: [null, []],
    neftCheque3: [null, [Validators.required, Validators.minLength(1)]],
    bank3: [null, [Validators.required]],
    paymentDate3: [null, [Validators.required]],
    paymentType3: [null, []],
    tdsType3: [null, []],
    tdsAmount3: [null, []],
    amountCollected3: [null, []],
    grossAmount3: [null, []],
    partyApproval: [null, []],
    partyApprovalComment: [null, []],
    comment: [null, []],
    autoInitiate: [null, []],
    rptInCC: [null, []],
    rptInCrm: [null, []],
    rptInCCAfterReporting: [null, []],
    rptInCrmAfterReporting: [null, []],
    rptoverall: [null as any, []]

  });

  contactDetailsform = this.fb.group({
    salutation: [null as any, []],
    firstName: [null as any, []],
    lastName: [null as any, []],
    designation: [null as any, []],
    mobileNo: [null as any, []],
    landlineNo: [null as any, []],
    email1: [null as any, []],
    email2: [null as any, []],
    email3: [null as any, []],
    gstType: [null as any, []],
    gstNo: [null as any, []],
    panNo: [null as any, []],
    address1: [null as any, []],
    address2: [null as any, []],
    address3: [null as any, []],
    address4: [null as any, []],
    state: [null as any, []],
    city: [null as any, []],
    pincode: [null as any, []],
    contactVerified: [null as any, []]
  });

  actionForm = this.fb.group({
    businessAction: [null, [Validators.required]],
    actionReason: [null as any, []],
    actionComment: [null, []]
  });

  paymentForm = this.fb.group({
    payments: this.fb.array([]),
  });
  showComment: boolean;
  crmEmail1: any;
  crmEmail2: any;
  crmEmail3: any;
  disableCheckBox: boolean = false;
  createdDate: any;
  response: any;
  scopeList: any;
  reportingReadScope: boolean = false;
  commentLength: string = '150';
  paymentIndex: any;
  addnPaymentCase: boolean;
  addnPaymentIds: any = [];
  parseSfActualFees: number;
  onTdsType: boolean = true;
  netAmount: number;
  gstAmountonTdsType: number;
  grossAmount: number;
  balanceAmount: number;
  tdsAmountCalculated: number;
  sumOfGrossAmount : number;
  addnPaymentError : boolean = false;
  grossAmountError: boolean = false;
  grossError: string;
  addnPaymentReportOnly: boolean;
  ogTdsTypeList: any = [];
  skipValidation : boolean = false;
  proceedSave: boolean = false;
  gstPopUp: boolean = false;
  tempTdsAmount: number;
  tempStatus: string;
  disableTds: boolean = true;
  uploadedFileIds: any = [];
  //realisation
  payStatus: any = {};
  uploadLoader: boolean = false;
  crmContactNotSelectedPopup: boolean = false;
  isRptApprovalRecd: string = "No";

  constructor(
    private fb: FormBuilder, 
    public config: DynamicDialogConfig, 
    private pageService: PagesService,
    private messageService: MessageService,
    private encryptDecryptInterceptor : EncryptDecryptInterceptor,
    private dialogService: DialogService,
    private ref: DynamicDialogRef
  ) { }

  get payments(){
    return this.paymentForm.controls['payments'] as FormArray
  }
  
  ngOnInit(): void {
    this.onSaveLoader = true;
    this.OpportunityGridDetails = this.config?.data;
    this.OpportunityId = this.config.data?.opportunityId;
    this.isOpportunityWonRecd = this.config.data?.opportunityStatus == "Won - Payment Recd" ? true : false;
    this.isOpportunityReportInvoice = this.config.data?.opportunityStatus == "Report & Invoice" ? true : false;
    this.isOpportunityRejected = this.config.data?.opportunityStatus == "Reject" ? true : false;
    this.isOpportunityReportOnly = this.config.data?.opportunityStatus == "Report Only" ? true : false;
    this.disableCheckBox = this.isOpportunityReportOnly == true ? true : false;
    this.addnPaymentCase = this.config.data?.addnPaymentCase;
    if(this.addnPaymentCase && this.isOpportunityReportOnly){
      this.addnPaymentReportOnly = true;
    }
    if(this.isOpportunityWonRecd || this.isOpportunityReportInvoice || this.isOpportunityReportOnly){
      this.isOpporutnityOtherStatus = false;
    } else {
      this.isOpporutnityOtherStatus = true;
    }
    this.getAllMasters();
    setTimeout(() => {
      this.getOpportunityDetails(this.OpportunityId);
    }, 600);   
        
    this.userId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));
    this.userName = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empName'));
    this.scopeList = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    let scope: any = this.scopeList.split(",");
    if(scope.indexOf('REPORTING_CREATE') == -1 && scope.indexOf('REPORTING_UPDATE') == -1){
      this.reportingReadScope = true;
    } else {
      this.reportingReadScope = false;
    }

    let ele = document.getElementsByClassName("p-dialog-header-close-icon");
    ele[0].addEventListener("click", () => {
      this.deleteMultipleFile();
    });
  }

  getAllMasters(){
    this.getTdsTypeList();
    this.getbankList();
    this.getPaymentTypeList();
    this.getpaymentModeList();
    this.getDiscountReasonList();
    this.getSalutationList();
    this.getDesignationList();
    this.getGSTtypeList();
    this.getBusinessActionList();
    this.getReasonList();
    this.getCityList();
    this.getPaymentStatus();
  }

  saveReporting(){
    if(this.invoiceApiFailed && this.actionForm.value.businessAction == "REPORT_AND_INVOICE"){
      this.apiFail = true;
      this.disabledOnApiFail = true;
      return;
    }
    this.runValidations();
  }

  runValidations(){
    //oppotunity validations
    this.errorMessageOnSave = '';
    this.payments.controls.forEach((payment,i) =>{
      let temp = "isInitiated_"+i;
      if(payment.get(temp).value == null && (this.actionForm.value.businessAction == "REPORT_ONLY" || this.actionForm.value.businessAction == "REPORT_AND_INVOICE")){
        payment.get(temp).markAsDirty();
      }    
    });
    if(this.actionForm.value.businessAction == null || this.actionForm.value.businessAction == undefined){
      document.getElementById('businessAction').classList.add("ng-dirty");
      this.isBAaction = true;
      this.errorAction = true;
      this.errorMessageOnSave = this.errorMessageOnSave + "Action, ";
      this.showValidationPopup();
      return;
    } else {
      if(this.actionForm.value.businessAction == "REJECT"){

        this.deleteMultipleFile();
        if((this.actionReasons == null || this.actionReasons == '' || this.actionReasons == undefined || this.actionReasons.length == 0 ||
        this.actionForm.value.actionComment == null || this.actionForm.value.actionComment == '' || this.actionForm.value.actionComment == undefined) && this.saveBtnClicked){
          this.isActionCommentError = true;
          this.errorAction = true;
          this.actionReasonError = true;
          this.errorMessageOnSave = this.errorMessageOnSave + "Action, ";
          this.showValidationPopup();
          return;
        } else {
          this.errorAction = false;
          this.isActionCommentError = false;
          this.actionReasonError = false;
          this.errorMessageOnSave = '';
        }
      }
      if(this.actionForm.value.businessAction == "REPORT_ONLY"){
        if(this.actionReasons.length == 0 && this.saveBtnClicked && !this.skipValidation && !this.addnPaymentCase){
          this.errorAction = true;
          this.actionReasonError = true;
          this.errorMessageOnSave = this.errorMessageOnSave + "Action, ";
          this.showValidationPopup();
          return;
        } else {
          this.errorAction = false;
          this.actionReasonError = false;
          this.errorMessageOnSave = '';
          if(!this.contactChecked){
            this.errorContactDetails = true;
            if(!this.errorMessageOnSave.includes('Contact Details')){
              this.errorMessageOnSave = this.errorMessageOnSave + "Contact Details, ";
            }
          } else {
            this.errorContactDetails = false;
            this.errorMessageOnSave = this.errorMessageOnSave + " ";
          }
          this.attachmentValidation();
          this.opportunityValidation();
          this.approvalValidation();
        }
      } 
      document.getElementById('businessAction')?.classList?.remove("ng-dirty");
      this.errorAction = false;
      this.isBAaction = false;
      if(this.actionForm.value.businessAction == "REPORT_AND_INVOICE" && this.billIdFinalised && !this.proceedSave){
        this.invoiceGenereatedPopup = true;
        return;
      } else {
        this.invoiceGenereatedPopup = false;
        if(this.actionForm.value.businessAction == "REPORT_AND_INVOICE"){
          if(this.contactDetailsMatch){
            this.contactDetailsMatchPopup = false;
            this.errorContactDetails = false;
          } else {
            if(!this.continueBillingClicked && !this.addnPaymentCase){
              this.contactDetailsMatchPopup = true;
              this.errorContactDetails = true;
              return;
            } else {
              this.attachmentValidation();
              this.opportunityValidation();
              this.approvalValidation();
              this.contactValidation();
              if(!this.contactChecked){
                this.errorContactDetails = true;
                if(!this.errorMessageOnSave.includes('Contact Details')){
                  this.errorMessageOnSave = this.errorMessageOnSave + "Contact Details, ";
                }
              } else {
                this.errorContactDetails = false;
                this.errorMessageOnSave = this.errorMessageOnSave + " ";
              }
            }
          }
        }
      }
    }
    //final popup
    let payload = this.createPayload();
    let fileObj = payload?.uploadedDocument.find(u => u.fileTypeid == 16);
    if(fileObj){
      fileObj.file = null;
    }
    payload.rptApprovalDocDetails = fileObj;
    if((this.errorOpportunityDetails || this.errorAttachments || this.errorApprovals || this.errorContactDetails) && this.actionForm.value.businessAction != "REJECT"){
      this.saveBtnClicked = true;
      this.showValidationPopup();
    } else {
      if(this.addnPaymentCase && this.billIdFinalised && !this.proceedSave){
        this.invoiceGenereatedPopup = true;
        return;
      }
      if(!this.crmContactId && this.actionForm.value.businessAction != "REJECT"){
        this.crmContactNotSelectedPopup = true;
        this.errorContactDetails = true;
        return;
      }
      this.saveBtnClicked = true;
      this.onSaveLoader = true;
      this.pageService.updateSfReporting(payload).subscribe((res) => {
        this.onSaveLoader = false;
        this.response = res;
        this.uploadedFileIds = [];
        if(this.actionForm.value.businessAction == "REPORT_AND_INVOICE" && this.baValue != ''){
          this.reportedAndInvoiced = true;
        } else {
          this.closeReportingPopup(res);
        }
      }, (err) => {
        this.onSaveLoader = false;
        this.deleteMultipleFile();
        this.closeReportingPopup(err);
      });
    }
    
  }

  createPayload(){
    let payload: any = {
      isAlreadyCRMEntity: "Yes",
      opportunityStatus: this.opportunityDetails?.opportunityStatus,
      businessWonDate: this.opportunityDetails?.businessWonDate,
      tempContactChanged: this.contactChange == true ? 'Yes' : 'No',
      userId: this.userId,
      isRptApprovalReceived: this.isRptApprovalRecd,
      entityId: {},
      rptApprovalDocDetails: null,
      opportunityDetails: {
        feeDetails: {},
        paymentDetails: []
      },
      reportingDetails: {},
      uploadedDocument: [],
      documentToBeUploaded: [],
      rptStatus:{}
    };
    payload.entityId = {
      id: this.opportunityDetails?.entityId?.id,
      entityType: this.OpportunityGridDetails?.opportunityType,
      entityTypeId: this.opportunityDetails?.entityTypeId,
      name: this.OpportunityGridDetails?.entityName,
      companyCode: this.opportunityDetails?.entityCompanyCode,
    }
    payload.rptStatus ={
      rptInCC: this.rptStatus?.rptInCC,
      rptInCrm: this.rptStatus?.rptInCrm
    }

    this.paymentDetailsData = [];
    this.payments.controls.forEach((payment,i) =>{
      let temp = "isInitiated_"+i;
      let form = {
        id: payment.value.id,
        bank: payment.value.bank,
        bankId: payment.value.bankId,
        paymentMode: payment.value.paymentMode,
        chequeNo: payment.value.chequeNo,
        paymentDat: payment.value.paymentDat,
        paymentType: payment.value.paymentType,
        tdsType: payment.value.tdsType,
        tdsAmount: payment.value.tdsAmount,
        amount: payment.value.amount,
        grossAmount: payment.value.grossAmount,
        isInitiated: payment.get(temp).value
      };
      this.paymentDetailsData.push(form);      
    });

    this.paymentDetailsData.forEach(element => {
      if(element.paymentType != "No Payment"){
        const formattedDate = this.formatDate(element.paymentDat);
        element.paymentDat = formattedDate;
      }    
    });

    payload.opportunityDetails = {
      id: this.opportunityDetails?.opportunityDetails?.id,
      crmId: this.crmContactId,
      thirdPartyApproval: this.opportunityDetailsForm.value?.partyApproval,
      thirdPartyApprovalComment: this.opportunityDetailsForm.value?.partyApprovalComment,
      isSFContactChanged: this.contactChange == true ? 'Yes' : 'No',
      contactChecked: this.contactChecked ? 'Yes' : 'No',
      feeDetails: {
        ...this.feeDetails,
        feetype: this.feeType
      },
      paymentDetails: this.paymentDetailsData,
    }

    let reason: any = [];
    if(this.actionForm?.value?.businessAction == "REJECT" || this.actionForm?.value?.businessAction == "REPORT_ONLY"){
      let isArr = Array.isArray(this.actionForm?.value?.actionReason);
      this.reportingReasons = [];
      if(isArr){
        this.actionForm?.value?.actionReason.forEach((a) => {
          let data = this.actionReasonList.find(r => r.name == a);
          this.reportingReasons.push({reasonId: data.id, reason: data.name});
          reason.push(data.name);
        });
      } else {
        this.actionReasonList.forEach((a) => {
          if(a.name == this.actionForm?.value?.actionReason){
            this.reportingReasons.push({reasonId: a.id,  reason: a.name });
            reason.push(a.name);
          }
        });
      }
    }
    payload.reportingDetails = {
      businessAction: (this.addnPaymentCase && this.billIdFinalised) ? 'Report Only' : this.baList.filter(b => b.code == this.actionForm?.value?.businessAction)[0]?.name,
      businessActionId: (this.addnPaymentCase && this.billIdFinalised) ? 2 : this.baList.filter(b => b.code == this.actionForm?.value?.businessAction)[0]?.id,
      reason: JSON.stringify(reason),
      comment: this.actionForm?.value?.actionComment,
      reportingReasons: this.reportingReasons,
      caseActionedBy: this.userId,
      caseActionerName: this.userName,
      mandateReportedDate: new Date(),
      invoiceBeforePayment: this.ibPayment == true ? 'true' : 'false'
    }
    if(this.actionForm.value.businessAction == "REPORT_ONLY" && !this.billIdFinalised){
      payload.reportingDetails = {
        businessAction: 'Report Only',
        businessActionId: 2,
        reason: JSON.stringify(reason),
        comment: this.actionForm?.value?.actionComment,
        reportingReasons: this.reportingReasons,
        caseActionedBy: this.userId,
        caseActionerName: this.userName,
        mandateReportedDate: new Date(),
        invoiceBeforePayment: this.ibPayment == true ? 'true' : 'false'
      }
    }

    let uploaded:any = [];
    this.uploadedDocuments?.forEach((a) => {
      uploaded.push({
        fileId: a.fileId,
        fileTypeName: a.fileTypeName,
        fileTypeid: a. fileTypeid,
        moduleId: a.moduleId,
        moduleName: a.moduleName,
        userId: a.userId
      });
    });
    
    let documents = [...uploaded, ...this.attachmentResult];
    documents.forEach((d) => {
      d.file = null;
    });

    payload.uploadedDocument = documents;
    payload.documentToBeUploaded = [];
    return payload;
  }

  attachmentValidation(){
    let tempArray = [];
    this.attachmentTypeList.forEach((d) => {
      if (d.file == "" && d.is_mandatory == 'M') {
        tempArray.push(d);
      }
    });
    if(tempArray.length>0){
      this.errorAttachments = true;
      this.errorMessageOnSave = this.errorMessageOnSave + "Attachment, ";
    } else {
      this.errorMessageOnSave = this.errorMessageOnSave + "";
      this.errorAttachments = false;
    }
  }

  contactValidation(){
    if(this.contactDetailsform?.value?.salutation?.toLowerCase() != this.crmSingleContact?.salutation?.toLowerCase() || 
      this.contactDetailsform?.value?.firstName?.toLowerCase() != this.crmSingleContact?.firstName?.toLowerCase() ||
      this.contactDetailsform?.value?.lastName?.toLowerCase() != this.crmSingleContact?.lastName?.toLowerCase() || 
      this.contactDetailsform?.value?.designation?.toLowerCase() != this.crmSingleContact?.designation?.toLowerCase() ||
      this.contactDetailsform?.value?.mobileNo != this.crmSingleContact?.mobileNum ||
      this.contactDetailsform?.value?.email1?.toLowerCase() != this.crmEmail1?.toLowerCase() ||
      this.contactDetailsform?.value?.gstType?.toLowerCase() != this.crmSingleContact?.gstType?.toLowerCase() ||
      this.contactDetailsform?.value?.gstNo?.toLowerCase() != this.crmSingleContact?.gstNumber?.toLowerCase() ||
      this.contactDetailsform?.value?.panNo?.toLowerCase() != this.crmSingleContact?.panNumber?.toLowerCase() ||
      this.contactDetailsform?.value?.pincode != this.crmSingleContact?.pin ||
      this.contactDetailsform?.value?.city?.toLowerCase() != this.crmSingleContact?.city?.toLowerCase() ||
      this.contactDetailsform?.value?.state?.toLowerCase() != this.crmSingleContact?.state?.toLowerCase() ||
      this.contactDetailsform?.value?.address1?.toLowerCase() != this.crmSingleContact?.address1?.toLowerCase() ||
      this.contactDetailsform?.value?.address2?.toLowerCase() != this.crmSingleContact?.address2?.toLowerCase() ||
      this.contactDetailsform?.value?.address3?.toLowerCase() != this.crmSingleContact?.address3?.toLowerCase() ||
      this.contactDetailsform?.value?.address4?.toLowerCase() != this.crmSingleContact?.address4?.toLowerCase()
      ){
        this.contactDetailsMatch = false;
        if(!this.errorMessageOnSave.includes('Contact Details') && !this.addnPaymentCase){
          this.errorMessageOnSave = this.errorMessageOnSave + "Contact Details, ";
        }
      } else {
        this.contactDetailsMatch = true;
      }
  }

  opportunityValidation(){
    this.paymentValidationList = this.readyPaymentList();
    if(this.opportunityDetailsForm?.value?.partyApproval == 'Yes'){
      if(this.opportunityDetailsForm?.value?.partyApprovalComment == null || this.opportunityDetailsForm?.value?.partyApprovalComment == "" || this.opportunityDetailsForm?.value?.partyApprovalComment == undefined){
        this.isPartyApprovalMissing = true;
      } else {
        this.isPartyApprovalMissing = false;
      }
    } else if(this.opportunityDetailsForm?.value?.partyApproval == 'No'){
      this.isPartyApprovalMissing = false;
    } else {
      this.isPartyApprovalMissing = true;
      this.partyApprovalSelected = false;
    }
    
    if(this.payments.invalid){
      this.paymentDetailsMissing = true;
    } else {
      this.paymentDetailsMissing = false;
    }
    
    for (let i = 0; i < this.paymentValidationList.length; i++) {
      if(this.paymentValidationList[i].paymentType !== 'No Payment' && (!this.paymentValidationList[i].cheque || 
        !this.paymentValidationList[i].bank || !this.paymentValidationList[i].date || !this.paymentValidationList[i].amount 
        || !this.paymentValidationList[i].isInitiated || !this.paymentValidationList[i].tdsType || (this.paymentValidationList[i].tdsAmount == '' || this.paymentValidationList[i].tdsAmount == null))
      ){
        this.paymentDetailsMissing = true;
        break;
      } else {
        this.paymentDetailsMissing = false;
      }
    }
    if(this.balanceAmount < 0){
      this.grossAmountError = true;
      this.paymentDetailsMissing = true;
      this.payments.controls.forEach(payment =>{
          payment.get('grossAmount').markAsDirty();
      });
    }

    let partyCommentValue = this.opportunityDetailsForm.value.partyApprovalComment;
    if(this.opportunityDetailsForm.value.partyApproval == 'Yes' && (partyCommentValue == '' || partyCommentValue == undefined || partyCommentValue == null)){
      this.partyComment = true;
      document.getElementById('thirdPartyComment').classList.add("ng-dirty");
    } else {
      this.partyComment = false;
      document.getElementById('thirdPartyComment').classList.remove("ng-dirty");
    }

    if(this.paymentDetailsMissing || this.partyComment || this.grossAmountError){
      this.errorOpportunityDetails = true;
      this.errorMessageOnSave = this.errorMessageOnSave + "Opportunity Details, ";
    } else {
      this.errorOpportunityDetails = false;
    }
  }

  showValidationPopup(){
    this.validationPopup = true;
  }

  approvalValidation(){
    if(this.ibpMandatory && !this.ibPayment){
      this.ibpError = true;
    } else {
      this.ibpError = false;
    }
    if(this.actionForm.value.businessAction != "REJECT"){
      if(this.ibpMandatory && !this.ibPayment){
        this.errorApprovals = true;
        this.errorMessageOnSave = this.errorMessageOnSave + "Approval, ";
      } else {
        this.errorApprovals = false;
      }
    }
  }

  closeReportingPopup(event?){
    this.deleteMultipleFile();
    this.ref.close(event);
  }

  getOpportunityDetails(oppId){
    this.pageService.onReportingScreenDetailsPageLoad(oppId, false).subscribe((res) => {
      this.opportunityDetails = res;
      this.rptStatus= res?.rptStatus;
      this.feeDetails = res.opportunityDetails?.feeDetails;
      const paymentDetails = res.opportunityDetails?.paymentDetails;
      if (paymentDetails && paymentDetails.length > 0) {
        const sortedPaymentDetails = paymentDetails.sort((a, b) => a.id - b.id);
        this.paymentDetails = sortedPaymentDetails.map(payment => ({ ...payment }));
      }
      this.paymentDetails.forEach(element => {
        if(this.addnPaymentCase && element.isInitiated == null){
          this.addnPaymentIds.push(element.id);
        }
      });      
      this.sfBilldata = res?.sfbilldata;
      this.idealFeeSfExpected = parseInt(this.feeDetails?.idealFeeSfExpected);
      this.contactDetails = res.entityContacts;
      this.actionDetails = this.opportunityDetails?.reportingDetails;
      let tempDate: string = res?.opportunityDetails?.createdDate?.split('T')[0];
      this.createdDate = tempDate?.split('-')?.reverse()?.join('-');
      if(this.opportunityDetails?.opportunityStatus == 'Reject'){
        this.baValue = 'REJECT';
        this.isActionReasonM = true;
        this.actionReasonList = this.reasonList.filter((r) => {
          return r.action_id == this.baValue;
        });
      }
      if(this.opportunityDetails?.opportunityStatus == 'Report Only'){
        this.baValue = 'REPORT_ONLY';
        this.isActionReasonM = true;
        this.actionReasonList = this.reasonList.filter((r) => {
          return r.action_id == this.baValue;
        });
      }
      this.email1 = res?.entityContacts?.email[0]?.toLowerCase();
      this.email2 = res?.entityContacts?.email[1]?.toLowerCase();
      this.email3 = res?.entityContacts?.email[2]?.toLowerCase();
      if(this.opportunityDetails?.opportunityStatus == "Won - Payment Recd" || this.opportunityDetails?.opportunityStatus == 'Reject'){
        this.originalContactId = res?.sfbilldata?.crmContactId;
        this.crmContactId = res?.sfbilldata?.crmContactId;
      } else {
        this.originalContactId = res?.opportunityDetails?.crmId;
        this.crmContactId = res?.opportunityDetails?.crmId;
      }
      let crmContactId = {contactId: this.originalContactId}
      this.pageService.getCrmCotactById(crmContactId).subscribe((resp) => {
        this.crmSingleContact = resp;
        if(this.crmSingleContact != null || this.crmSingleContact != undefined){
          let emails = this.crmSingleContact?.email?.split(',')
          let email = Array.isArray(emails); 
          if(email){
            this.crmEmail1 = emails[0]?.toLowerCase();
            this.crmEmail2 = emails[1]?.toLowerCase();
            this.crmEmail3 = emails[2]?.toLowerCase();
          } else {
            this.crmEmail1 = emails[0]?.toLowerCase();
          }
        }
      });
      this.uploadedDocuments = res.attachments;
      this.patchOpportunityDetails();
      this.patchContactdetialsDetails();

      this.getRptOverallStatus(this.rptStatus);

      if(this.opportunityDetails?.reportingDetails?.businessAction != null || this.opportunityDetails?.reportingDetails?.businessAction != undefined){
        this.patchActionDetails();
      }

      if(this.idealFeeSfExpected > this.feeDetails?.sfActualFees){
        this.feeType = 'Discount';
      } else if(this.idealFeeSfExpected < this.feeDetails?.sfActualFees){
        this.feeType = 'Upward';
      } else {
        this.feeType = 'Payment Match';
      }
      
      if(this.paymentDetails.length == 1 && this.paymentDetails[0]?.paymentType == 'No Payment'){
        this.ibpMandatory = true;
      } else {
        this.ibpMandatory = false;
      }
	  
      let payload = {"sfBillId":this.sfBilldata?.sfBillId};
      this.pageService.getBillFinalised(payload).subscribe((response) => {
        this.billIdFinalised = response?.isInvoiceGenerated == 'Yes' ? true : false;
        this.invoiceApiFailed = false;
        //check if invoice is generated and additional payment is present
        if(this.addnPaymentCase && this.billIdFinalised){
          this.skipValidation = true;
          this.tempStatus = 'Report Only';
        }
      }, (_err) =>{
        this.invoiceApiFailed = true;
      });

      //patch approval detials
      this.ibPayment = this.actionDetails?.invoiceBeforePayment == 'true' ? true : false;
      this.contactChange = this.opportunityDetails?.opportunityDetails?.isSFContactChanged == 'YES' ? true : false;
      this.getAttachmentList();
    }, (_err) => {
      this.apiFail = true;
      this.disabledOnApiFail = true;
      this.onSaveLoader = false;
    });
  }

  closePopUp(){
    this.reportedAndInvoiced = false;
    this.closeReportingPopup(this.response);
  }

  filterAttachmentDropDownList(){
    for (let i = 0; i < this.uploadedDocuments?.length; i++) {
      let index = this.attachmentTypeList.findIndex(x => x.type.toUpperCase() == this.uploadedDocuments[i].fileTypeName.toUpperCase());
      if (index !== -1) {
        this.attachmentTypeList.splice(index, 1);
      }
    }
  }

  patchOpportunityDetails(){
    this.opportunityDetailsForm.patchValue({
      originalBilledValue: parseFloat(this.feeDetails?.idealFeeSfExpected).toFixed(2),
      sfActualFee: parseFloat(this.feeDetails?.sfActualFees).toFixed(2),
      sfDiscountPremium: parseFloat(this.feeDetails?.sfDiscount).toFixed(2),
      sfDiscountPremiumPercent: parseFloat(this.feeDetails?.sfDiscountPercentage).toFixed(2),
      sfDiscountReason: this.feeDetails?.sfDiscountReason,
      comment: this.paymentDetails[0]?.comment,
      partyApproval: this.opportunityDetails?.opportunityDetails?.thirdPartyApproval,
      partyApprovalComment: this.opportunityDetails?.opportunityDetails?.thirdPartyApprovalComment,
    });

    this.paymentDetails.forEach((p,i) => {
      let temp: any = "isInitiated_"+i;
      let control: any = new FormControl(p.isAutoInitiated, [Validators.required]);
      let form = this.fb.group({
        id: p.id,
        paymentStatus: this.payStatus[p.paymentStatusId],
        bank: new FormControl(p.bank, [Validators.required]),
        bankId: p.bankId,
        paymentMode: p.paymentMode,
        chequeNo: new FormControl(p.chequeNo, [Validators.required]),
        paymentDat: new FormControl(p.paymentDat, [Validators.required]),
        paymentType: p.paymentType,
        tdsType: new FormControl(p.tdsType, [Validators.required]),
        tdsAmount: new FormControl(p.tdsAmount, [Validators.required]),
        amount: new FormControl(p.amount, [Validators.pattern("^[0-9]+$")]),        
        grossAmount: new FormControl(p.grossAmount, [Validators.pattern("^[0-9]+$")]),
      });
      form.addControl(temp, control);
      this.payments.push(form);
    });

    this.payments.controls.forEach((control,i)=>{
      let temp = "isInitiated_"+i;
      if(control.get(temp).value == null && (this.isOpportunityWonRecd || this.isOpportunityReportInvoice || this.isOpportunityReportOnly)){
        control.get(temp).enable();
      } else {
        control.get(temp).disable();
      }
      

      if(control.get(temp).value == null && control.value.paymentType == "No Payment"){
        control.get('chequeNo').disable();
        control.get('bank').disable();
        control.get('paymentDat').disable();
        control.get('chequeNo').reset();
        control.get('bank').reset();
        control.get('paymentDat').reset();
        if(control.get(temp).value == null){
          control.get(temp).patchValue("No");
        }
        control.get(temp).disable();
      }
    });
    if(this.paymentDetails.length >= 1){
      this.payment1added = true;

      this.opportunityDetailsForm.patchValue({
        paymentMode1: this.paymentDetails[0]?.paymentMode,
        neftCheque1: this.paymentDetails[0]?.chequeNo?.toUpperCase(),
        bank1: this.paymentDetails[0]?.bankId,
        paymentDate1: this.paymentDetails[0]?.paymentDat,
        paymentType1: this.paymentDetails[0]?.paymentType,
        tdsType1: this.paymentDetails[0]?.tdsType,
        tdsAmount1: this.paymentDetails[0]?.tdsAmount,
        amountCollected1: this.paymentDetails[0]?.amount,
        grossAmount1: this.paymentDetails[0]?.grossAmount,
      });
    } 

    if(this.paymentDetails.length >= 2){
      this.payment2added = true;
      this.opportunityDetailsForm.patchValue({
        paymentMode2: this.paymentDetails[1]?.paymentMode,
        neftCheque2: this.paymentDetails[1]?.chequeNo?.toUpperCase(),
        bank2: this.paymentDetails[1]?.bankId,
        paymentDate2: this.paymentDetails[1]?.paymentDat,
        paymentType2: this.paymentDetails[1]?.paymentType,
        tdsType2: this.paymentDetails[1]?.tdsType,
        tdsAmount2: this.paymentDetails[1]?.tdsAmount,
        amountCollected2: this.paymentDetails[1]?.amount,
        grossAmount2: this.paymentDetails[1]?.grossAmount,
      });
    }
    
    if(this.paymentDetails.length == 3){
      this.payment3added = true;
      this.opportunityDetailsForm.patchValue({
        paymentMode3: this.paymentDetails[2]?.paymentMode,
        neftCheque3: this.paymentDetails[2]?.chequeNo?.toUpperCase(),
        bank3: this.paymentDetails[2]?.bankId,
        paymentDate3: this.paymentDetails[2]?.paymentDat,
        paymentType3: this.paymentDetails[2]?.paymentType,
        tdsType3: this.paymentDetails[2]?.tdsType,
        tdsAmount3: this.paymentDetails[2]?.tdsAmount,
        amountCollected3: this.paymentDetails[2]?.amount,
        grossAmount3: this.paymentDetails[2]?.grossAmount,
      });
    }

    if (this.ibPayment && this.isOpportunityWonRecd) {
      this.attachmentTypeList.forEach(element => {
        if (element.type == 'Invoice before payment Approval') {
          element.is_mandatory = 'M';
        }
      });
    } else {
      this.attachmentTypeList.forEach(element => {
        if (element.type == 'Invoice before payment Approval') {
          element.is_mandatory = 'O';
        }
      });
    }
  }

  patchContactdetialsDetails(){
    this.contactDetailsform.patchValue({
      salutation: this.contactDetails?.salutation,
      firstName: this.contactDetails?.firstName,
      lastName: this.contactDetails?.lastName,
      designation: this.contactDetails?.designation,
      mobileNo: this.contactDetails?.mobileNumber,
      landlineNo: this.contactDetails?.landlineNumber,
      email1: this.contactDetails?.email[0],
      email2: this.contactDetails?.email[1],
      email3: this.contactDetails?.email[2],
      gstType: this.contactDetails?.gstType,
      gstNo: this.contactDetails?.gstNumber?.toUpperCase(),
      panNo: this.contactDetails?.panNumber?.toUpperCase(),
      pincode: this.contactDetails?.pincode,
      city: this.contactDetails?.city,
      state: this.contactDetails?.state,
      address1: this.contactDetails?.addressLine1,
      address2: this.contactDetails?.addressLine2,
      address3: this.contactDetails?.addressLine3,
      address4: this.contactDetails?.addressLine4,
    });
    this.contactChecked = this.opportunityDetails?.opportunityDetails?.contactChecked == 'Yes' ? true : false;
  }

  patchActionDetails(){
    let reason: any;
    if(this.baValue == 'REJECT'){
      reason = JSON.parse(this.actionDetails?.reason)[0];
    }
    this.actionForm.patchValue({
      businessAction: this.baMap[this.actionDetails?.businessAction],
      actionReason: reason,
      actionComment: this.actionDetails?.comment
    }); 
    if(!this.addnPaymentCase && this.actionForm.value.businessAction == "REPORT_ONLY" && !this.billIdFinalised){
      this.actionForm.value.businessAction = null;
    }
  }

  checkContact(){
    this.contactDetailsMatchPopup = false;
    this.activeIndex = 3
  }

  continueBilling(){
    this.continueBillingClicked = true;
    this.contactDetailsMatchPopup = false;
  }

  reasonSelected(event){
    this.actionReasons = event.value;
    this.actionReasonError = false;
    this.errorAction = false;
  }

  showAttachmentPreview(item){
    this.openedFile = item;
    this.zoomValue = 1;
    this.rotate = 0;

    if(item?.fileId){
      this.displayPreview = true;
      this.dataLoader = true;
      let payload = {
        id: item.fileId
      }
      this.pageService.findPDF(payload).subscribe((res) => {
        if(res && res?.file){
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          let isPreviewable = true;
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else if ( "msg" === ext1?.toLowerCase()) {
            mime = 'data:application/vnd.ms-outlook;base64,';
            isPreviewable = false;
          } else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.urlSafe = `${mime}${res.file}`
          if (!this.isPdf) {
            let el = document.getElementById('imagePrev');
            setTimeout(() => {
              el.style.transform = 'rotate(0deg) scale(1)';
            }, 100);
          }
          this.displayPreview = isPreviewable;
          if(!isPreviewable) {
            this.pageService.downloadFile(res, this.urlSafe);
          }
          this.dataLoader = false;
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      });
    } else {
      let tempArray: any = [];
      if(item.fileContentType == "application/pdf"){
        this.displayPreview = true;
        this.dataLoader = true;
        let data = '';
        if(item?.file?.startsWith('data:application/pdf;base64')){
          data = item?.file;
        } else {
          data = `data:application/pdf;base64,${item.file}`;
        }
        this.urlSafe = data;
        this.dataLoader = false;
      } else {
        let obj: any = {};
        if (item.fileContentType.startsWith('application/vnd') || item.fileContentType == 'application/msword') {
          obj = {
            fileType: 'doc',
            fileName: item.fileName,
            attachmentType: item.fileTypeName,
            fileData: item.file.split(',')[1]
          }
        } else {
          obj = {
            fileType: item.fileContentType.split('/')[1],
            fileName: item.fileName,
            attachmentType: item.fileTypeName,
            fileData: item.file
          }
        }
        tempArray.push(obj);
        this.displayPreview = true;
        this.dataLoader = true;
        this.pageService.combineAllPDF(tempArray).subscribe((res) => {
          if(res && res?.fileData){
            this.urlSafe = `data:application/pdf;base64,${res.fileData}`
            this.dataLoader = false;
            this.previewFailed = false;
          } else {
            this.displayPreview = false;
            this.previewFailed = true;
          }
        }, (_error) => {
          this.displayPreview = false;
          this.previewFailed = true;
        });
      }
    }
  }

  
  showEditTypeDialog(item){
    if(item?.code.includes("Others")){
      this.showComment = true;
      this.attachmentComment = item?.miscComment;
    } else {
      this.showComment = false;
    }
    this.displayEditTypeModal = true;
    this.SelectedAttachmentType = item;
    this.ogAttachmentType = item;
    let index = this.attachmentChangeTypeList.findIndex(i => i.code == this.SelectedAttachmentType?.code);
    if(index == -1){
      this.attachmentChangeTypeList.unshift({type:item.fileTypeName, id:item.fileTypeid, code: item.code});
    }
    this.attachmentComment = item?.miscComment;
    this.updatedAttachmentType = item.fileTypeName;
    this.updatedAttachmentCode = item.code;
  }
  
  closeAttachmentTypeModal(){
    this.displayEditTypeModal = false;
    if(this.SelectedAttachmentType){
      let index = this.attachmentChangeTypeList.findIndex(item => item.code == this.SelectedAttachmentType?.code);
      this.attachmentChangeTypeList.splice(index, 1);
    }
  }

  changed(event){
    let data = this.attachmentTypeList.find(a =>  a.code == this.updatedAttachmentCode);
    this.attachmentComment = '';
    if(data?.type == 'Others'){
      this.showComment = true;
    } else {
      this.showComment = false;
    }
  }

  onUpdateAttachmentType(){
    this.attachmentChangeTypeList.forEach((a) =>{
      if(a.code == this.updatedAttachmentCode){
        this.submitedAttachmentType = {
          type: a.type,
          id: a.id,
          code: a.code
        }
      }
    });
    let index1 = this.attachmentChangeTypeList.findIndex(a => a.code == this.submitedAttachmentType?.code)
    if(index1 != -1){
      this.attachmentChangeTypeList.splice(index1, 1);
    }

    let index = this.uploadedDocuments.findIndex(x => x.code === this.SelectedAttachmentType.code);
    let oldFile = this.uploadedDocuments[index];
    this.removeAddDocList(oldFile, this.submitedAttachmentType);

    this.uploadedDocuments[index].fileTypeName = this.submitedAttachmentType.type;
    this.uploadedDocuments[index].fileTypeid = this.submitedAttachmentType.id;
    this.uploadedDocuments[index].code = this.submitedAttachmentType.code;
    this.uploadedDocuments[index].miscComment = this.attachmentComment;
    this.attachmentComment = '';
    this.updatedAttachmentType = null;
    this.updatedAttachmentCode = null;
    this.closeAttachmentTypeModal();
  }

  //when user changes mandate attachment type from uploaded document remove new from documnet to
  // be uploaded and add the old type
  removeAddDocList(oldType: any, newType: any){
    let index = this.attachmentTypeList.findIndex((file) => file.code == newType.code);
    this.attachmentTypeList.splice(index, 1);
    if(["cheque", "deposit slip", "gst na declaration", "business card", "gst certificate", "pan copy", "others", "visiting card","rpt approval"].includes(oldType.fileTypeName?.toLowerCase())){
      let index1 = this.attachmentTypeList.findIndex((file) => file.code == oldType.code);
      if(index1 == -1){
        this.attachmentTypeList.unshift({
          file: "",
          fileName: "",
          id: parseInt(oldType.fileTypeid),
          isDisabled: false,
          is_mandatory: "O",
          thumbNail: "",
          type: oldType.fileTypeName,
          code: oldType.code
        });
      }
      this.attachmentChangeTypeList.push({id:parseInt(oldType.fileTypeid), type:oldType.fileTypeName, code:oldType.code})
    }
  } 

  readyPaymentList(){
    let paymentValidationList = [];
    if (this.payments instanceof FormArray) {
      this.payments.controls.forEach((element,i) => {
        let temp= "isInitiated_"+i;
        let obj = {
          cheque: element.value.chequeNo,
          bank: element.value.bank,
          date: element.value.paymentDat,
          paymentType: element.value.paymentType,
          amount: element.value.amount,
          isInitiated: element.get(temp).value,
          tdsType: element.get('tdsType').value,
          tdsAmount: element.value.tdsAmount
        };
        paymentValidationList.push(obj);
      });
    }
    return paymentValidationList;
  }

  partyApprovalChanged(event){
    this.partyApprovalSelected = true;
    if(event.value == 'Yes'){
      this.partyCommentM = true;
      this.opportunityDetailsForm.controls.partyApprovalComment.setValidators([Validators.required, Validators.minLength(1)]);
      this.opportunityDetailsForm.controls.partyApprovalComment.updateValueAndValidity();
      this.attachmentTypeList.forEach((a) => {
        if(a.type == '3rd Party approval Attachment'){
          a.is_mandatory = 'M';
        }
      });
    } else {
      this.partyCommentM = false;
      this.opportunityDetailsForm.controls.partyApprovalComment.clearValidators();
      this.opportunityDetailsForm.controls.partyApprovalComment.updateValueAndValidity();
      this.attachmentTypeList.forEach((a) => {
        if(a.type == '3rd Party approval Attachment'){
          a.is_mandatory = 'O';
        }
      });
    }
  }

  ibpSwitched(){
    if(this.saveBtnClicked && this.ibpMandatory){
      if(this.ibPayment){
        this.ibpError = false;
      } else {
        this.ibpError = true;
      }
    }
    if (this.ibPayment) {
      this.attachmentTypeList.forEach(element => {
        if (element.type == 'Invoice before payment Approval') {
          element.is_mandatory = 'M';
        }
      });
    } else {
      this.attachmentTypeList.forEach(element => {
        if (element.type == 'Invoice before payment Approval') {
          element.is_mandatory = 'O';
        }
      });
    }
  }

  tabChange(event){
    this.activeIndex = event.index;
  }

  contactVerified(event){
    this.contactChecked = event.checked;
  }

  disabledCrmApplyBtn: boolean = true;
  crmContactClicked(){
    this.disabledCrmApplyBtn = false;
  }

  crmContactSelected(){
    this.crmContacts.forEach((c) => {
      if(c.contactId == this.selectedCrmContact){
        this.crmSingleContact = c;
      }
    });
    this.crmContactPopup = false;
    if(this.crmSingleContact != null || this.crmSingleContact != undefined){
      let emails = this.crmSingleContact?.email?.split(',')
      let email = Array.isArray(emails);
      if(emails != undefined && emails != null && emails != ''){
        if(email){
          this.crmEmail1 = emails[0]?.toLowerCase();
          this.crmEmail2 = emails[1]?.toLowerCase();
          this.crmEmail3 = emails[2]?.toLowerCase();
        } else {
          this.crmEmail1 = emails[0]?.toLowerCase();
        }
      }
    }
    this.disableCheckBox = false;
    this.contactChecked = false;
    this.crmContactId = this.crmSingleContact?.contactId;
  
    if(this.originalContactId == this.crmSingleContact?.contactId){
      if(this.isOpportunityWonRecd){
        this.contactChange = false;
      }
    } else {
      if(this.isOpportunityWonRecd || this.isOpportunityReportOnly){
        this.contactChange = true;
      }
    }
    this.getCityList();
  }

  getCrmData(){
    let payload = {
      "companyCode": this.opportunityDetails?.entityCompanyCode
    }
    this.onSaveLoader = true;
    this.pageService.getAllContacts(payload).subscribe((res) => {
      this.crmContacts = res;
      this.onSaveLoader = false;
      this.crmContactPopup = true;
    });
  }

  businessActionChanged(event){
    this.baValue = event.value;
    if(event.value == null || event.value == undefined){
      this.isBAaction = true;
    } else {
      this.isBAaction = false;
    }
    this.actionForm.controls.actionComment.reset();
    this.actionForm.controls.actionReason.reset();
    this.isActionReasonM = false;
    this.disabledOnApiFail = false;
    this.actionReasonError = false;
    this.isActionCommentM = false;
    this.isActionCommentError = false;
    this.actionForm.controls.actionReason.clearValidators();
    this.actionForm.controls.actionComment.clearValidators();
    if(event.value == "REPORT_ONLY" || event.value == "REJECT"){
      this.actionForm.controls.actionReason.setValidators([Validators.required]);
      this.actionForm.controls.actionReason.updateValueAndValidity();
      this.isActionReasonM = true;
    }

    if(event.value == "REPORT_ONLY" || event.value == "REPORT_AND_INVOICE"){
      this.addnPaymentReportOnly = this.addnPaymentCase ? true : false;
      this.commentLength = '150';    
    }
    if(event.value == "REPORT_ONLY" || event.value == "REPORT_AND_INVOICE"){
      if(this.rptoverallStatus=='Yes'&& ((this.attachmentResult== null && !this.attachmentResult.some(d => d?.fileTypeName=='RPT Approval'))
       || (this.uploadedDocuments== null || !this.uploadedDocuments.some(d => d?.fileTypeName.toLowerCase() === 'rpt approval')))){
        this.rptPopup=true;
        this.attachmentTypeList.forEach(element => {
          if (element.type == 'RPT Approval') {
            element.is_mandatory = 'M';
          }
        });
      }
    }


    if(this.addnPaymentCase && event.value == "REPORT_AND_INVOICE"){
      this.addnPaymentReportOnly = false;
    }
    
    if(event.value == "REJECT"){
      this.actionForm.controls.actionComment.setValidators([Validators.required, Validators.minLength(1)]);
      this.actionForm.controls.actionComment.updateValueAndValidity();
      this.isActionCommentM = true;
      this.commentLength = '255';
    }

    this.actionReasonList = this.reasonList.filter((r) => {
      return r.action_id == event.value;
    });
  }

  
  onFileUpload(event: any, type: any, code: any, id: any){
    this.canvasRotation = 0;
    this.currentFileEvent = event.currentFiles[0];
    if(this.currentFileEvent == undefined){
      this.messageService.add({ severity: 'error',summary:'File size cannot exceed 20 MB.'});
      return;
    }
    let ext = this.currentFileEvent?.name?.split('.');
    if(ext[ext.length-1] == 'jfif'){
      this.messageService.add({ severity: 'error',summary:'Invalid File Format.'});
      return;
    }
    if(["image/svg+xml", "image/webp", "xlxs", "xls", "html", "xhtml", "txt", "gif", "application/exe", "zip", ".oft"].includes(this.currentFileEvent.type)){
      this.messageService.add({ severity: 'error',summary:'Invalid File Format.'});
      return;
    }
    this.fileType = type;
    this.fileCode = code;
    this.fileTypeId = id;
    this.imgChangeEvt = event.originalEvent;
    if (event.currentFiles[0]?.type == 'image/jpeg' || event.currentFiles[0]?.type == 'image/jpg' || event.currentFiles[0]?.type == 'image/png') {
      this.displayModal = true;
    } else {
      this.displayModal = false;
    }
    this.uploadLoader = true;
    for (const file of event.files) {
      if (file.type.includes('image')) {
        this.imageChangedEventfileUpload1 = new Blob([file], { type: file.type });
        this.filenameForfileUpload1 = file.name;
      } else {
        //Added
        const fileReader = new FileReader();
        fileReader.readAsDataURL(event.currentFiles[0]);
        fileReader.onload = () => {
          this.returnBase64 = fileReader.result;
          this.onImgUpload();
        };
      }
    } 
  }

  downloadFile(){
    let linkSource: any;
    if(this.openedFile?.fileContentType?.includes('image')){
      let mime = 'data:image/png;base64';
      linkSource = `${mime},${this.urlSafe?.split(',')[1]}`;
    } else {
      linkSource = this.urlSafe;
    }
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this?.openedFile?.fileName.split('.')[0];
    downloadLink.click(); 
  }

  imageCroppedfileUpload(event: any): any {
    // this.croppedImagefileUpload1 = event.base64;
    // this.returnBase64 = event.base64; //Added

    fetch(event.objectUrl)
       .then(response => response.blob())
       .then(blob => {
           const reader = new FileReader();
           reader.onloadend = () => {
               this.returnBase64 = reader.result;
               this.croppedImagefileUpload1 = this.returnBase64;
           };
           reader.readAsDataURL(blob);
    });
  }

  onImgUpload(){
    if (this.croppedImagefileUpload1) {
      const file = this.dataURLtoFile(this.croppedImagefileUpload1, this.filenameForfileUpload1);
      this.fileUploadfileUpload1.files.push(file);
    }
    this.uploadFile = {};
    if (this.displayModal) {
      const displayData = this.getUploadedObjDisplay(this.fileType);
      this.attachmentResult.push(displayData);
      this.uploadSingleFile(displayData);
      this.uploadFile = displayData;  
    } else {
      this.returnUploaded = this.getUploadedObj(this.fileType);
      this.attachmentResult.push(this.returnUploaded);
      this.uploadSingleFile(this.returnUploaded);
      this.uploadFile = this.returnUploaded;
    }
    if(this.uploadFile?.fileTypeid == 16){
      this.isRptApprovalRecd = 'Yes';
    }
    this.attachmentTypeList.filter((d) => {
      if (d.type == this.fileType && d.id == this.fileTypeId) {
        d.file = this.fileType;
        d.fileName = this.uploadFile.fileName;
        d.thumbNail = this.getThumbnail(this.uploadFile.fileContentType);
      }
    });

    this.displayModal = false;
  }

  rotateFile(x){
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }
  
  zoom(x){
    if (x === -1) {
      if(this.zoomValue > 0.1){
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage(){
    let el = document.getElementById('imagePrev');
    el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  getUploadedObjDisplay(fileType): any {
    let data = this.returnBase64;
    if (data.startsWith('data:application/pdf;base64,')) {
      data = data.replace('data:application/pdf;base64,', '');
    } else if (data.startsWith('data:image/png;base64,')) {
      data = data.replace('data:image/png;base64,', '');
    } else if (data.startsWith('data:application/doc;base64,')) {
      data = data.replace('data:application/doc;base64,', '');
    }

    return {
      userId: this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid')),
      moduleName: 'Opportunity',
      moduleId: this.OpportunityId,
      fileName: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].name,
      file: data,
      fileContentType: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].type,
      fileSize: (this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].size).toString(),
      fileTypeid: this.fileTypeId,
      fileTypeName: fileType,
      fileId: null,
    };
  }

  getUploadedObj(fileType): any {
    return {
      userId: this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid')),
      moduleName: 'Opportunity',
      moduleId: this.OpportunityId,
      fileName: this.currentFileEvent.name,
      file: this.returnBase64,
      fileContentType: this.currentFileEvent.type,
      fileSize: (this.currentFileEvent.size).toString(),
      fileTypeid: this.fileTypeId,
      fileTypeName: fileType,
      fileId: null,
    };
  }

  getThumbnail(type) {
    if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg') {
     return this.imgThumb;
    } else if (type == 'application/pdf') {
      return this.pdfThumb;
    } else if(type.startsWith('application/vnd.openxmlformats') || type.startsWith('application/msword')){
      return this.wordThumb;
    } else {
      return this.mailThumb;
    }
  }

  onDelete() {
    this.pageService.deleteFileById(this.deleteFileData?.deleteId).subscribe((res) => {
      if(res?.status == "success"){
        this.attachmentTypeList.forEach((d) => {
          if (d.type == this.deleteFileData?.item && d.code == this.deleteFileData?.code) {
            d.file = '';
            d.fileName = '';
            d.thumbNail = '';
          }
        });
        let index = this.uploadedFileIds.findIndex((r) => r == this.deleteFileData?.deleteId);
        this.uploadedFileIds.splice(index, 1);
        this.attachmentResult.splice(index, 1);
        if(this.deleteFileData?.item == "RPT Approval"){
          this.isRptApprovalRecd = 'No';
        }
      }
    }, (_err) => {
      this.apiFail = true;
    });
    this.deleteFile = false;
  }
  
  onDeletePopup(item, code, deleteId) {
    this.deleteFile = true;
    this.deleteFileData = {item: item, code: code, deleteId: deleteId};
  }

  getTdsTypeList(): void {
    this.pageService.gettdsTypeDataViewOpportunityDetail().subscribe(results => {
      this.tdsTypeList = results;
      this.ogTdsTypeList = results;       
    });
  }

  getbankList(): void {
    this.pageService.getbankDataReportingScreenDetails().subscribe((results: any) => {
      this.bankList = results.sort((a,b)=> a.id-b.id);
    });
  }
  
  getPaymentTypeList(): void {
    this.pageService.getpaymenttypeDataReportingScreenDetails().subscribe(results => {
      this.paymentTypeList = results;
    });
  }

  getpaymentModeList(): void {
    this.pageService.getpaymentModeDataReportingScreenDetails().subscribe(results => {
      this.paymentModeList = results;
    });
  }

  getSalutationList(): void {
    this.pageService.getsalutationselectDataReportingScreenDetails().subscribe(results => {
      this.salutationList = results;
    });
  }

  getDiscountReasonList(): void {
    this.pageService.getReasonDataReportingScreenDetails().subscribe(results => {
      this.irfDiscountReasonList = results;
    });
  }

  getCityList(){
    // this.crmSingleContact.pin
    let data = {
      pinCodetextField: 444601
    }
    this.pageService.getpincodeDataReportingScreenDetails(data).subscribe((results: any) => {
      this.cityList = results;
    });
  }

  getDesignationList(): void {
    this.pageService.getdesignationselectDataReportingScreenDetails().subscribe(results => {
      this.designationList = results;
    });
  }

  getGSTtypeList(): void {
    this.pageService.getGSTtypeselectDataReportingScreenDetails().subscribe(results => {
      this.gstTypeList = results;
    });
  }

  
  getBusinessActionList(){
    this.pageService.getSFBusinessAction().subscribe(results => {
      this.baList = results;
      if(this.isOpportunityReportOnly){
        this.isActionReasonM = false;
        let temp = [];
        temp = this.baList.find(b => b.code == 'REPORT_AND_INVOICE');
        this.baList = [];
        this.baList.push(temp);
      }
    });
  }
  
  getReasonList(){
    this.pageService.getSFBusinessActionReasons().subscribe(results => {
      this.reasonList = results;
    });
  }
  
  getAttachmentList() {
    this.pageService.getSFAttachmentTypeMasterData().subscribe(results => {
      this.attachmentTypeMaster = results;
      let count = 0;
      
      let disable = !this.isOpportunityWonRecd;
      results.forEach(r => { 
        if(r.name == 'Others') {
          this.attachmentTypeList.push({
            "type": r.name,
            "is_mandatory": r.is_mandatory,
            "id": r.id,
            "file": '',
            "fileName": '',
            "thumbNail": '',
            "isDisabled": disable,
            "code": `${r.name}_${++count}`
          });
        } else if(r.name == 'RPT Approval'){
          this.attachmentTypeList.push({
            "type": r.name,
            "is_mandatory": r.is_mandatory,
            "id": r.id,
            "file": '',
            "fileName": '',
            "thumbNail": '',
            "isDisabled": false,
            "code": r.name?.toUpperCase()?.split(' ')?.join('_')
          });
        }
      else {
          this.attachmentTypeList.push({
            "type": r.name,
            "is_mandatory": r.is_mandatory,
            "id": r.id,
            "file": '',
            "fileName": '',
            "thumbNail": '',
            "isDisabled": disable,
            "code": r.name?.toUpperCase()?.split(' ')?.join('_')
          });
        }
      });
      count = 0;
      this.attachmentTypeList.forEach((a) => {
        if(["Cheque", "Deposit Slip", "GST Certificate", "Business Card", "GST NA Declaration", "PAN Copy", "Visiting Card"].includes(a.type)){
          this.attachmentChangeTypeList.push({
            "id": a.id,
            "type": a.type,
            "code":a.type?.toUpperCase()?.split(' ')?.join('_')
          });
        } else if(a.type == 'Others'){
          this.attachmentChangeTypeList.push({
            "id": a.id,
            "type": a.type,
            "code": `${a.type}_${++count}` 
          });
        } 
      });
      this.uploadedDocuments?.forEach((u) => {
        let index = this.attachmentChangeTypeList.findIndex(a => a.type.toUpperCase() == u.fileTypeName.toUpperCase());
        if(index != -1){
          this.attachmentChangeTypeList.splice(index, 1);
        }
      });
      this.uploadedDocuments?.forEach((u) => {
        let index = this.attachmentTypeList.findIndex(x => x.type.toUpperCase() == u.fileTypeName.toUpperCase());
        if (index != -1) {
          this.attachmentTypeList.splice(index, 1);
        }
      });
      count = 0;
      this.uploadedDocuments?.forEach((u) => {
        if(u.fileTypeName == "Others"){
          u.code = `${u.fileTypeName}_${++count}`
        } else {
          u.code = u.fileTypeName?.toUpperCase()?.split(' ')?.join('_');
        }
      });
      this.onSaveLoader = false;
      if (!this.rptoverallStatus || this.rptoverallStatus=='No') {
        this.attachmentTypeList.forEach(element => {
          if (element.type == 'RPT Approval') {
            element.is_mandatory = 'O';
          }
        });
      } 
    });
  }

  numberOnlyCheque(event) {
    let text = event.key
    let paymentMode = this.openedPaymentData.value.paymentMode;
    if (paymentMode && paymentMode == "Cheque") {
      this.isCheque = true;
      return /^[0-9]*$/.test(text);
    } else {
      this.isCheque = false;
      return /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/.test(text);
    }
  }

  dataURLtoFile(dataurl: any, filename: any): any {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  currentPayment(event){
    const paymentIndex = event.index;
    this.paymentIndex = paymentIndex;
    const currentPaymentData = this.payments.at(paymentIndex) as FormArray;
    let sum = 0;
    let fullPaymentBounced:any;
    let fullPaymentRealised:any;
    let partPaymentBounced:any;
    for (var i = 0; i < this.payments.value.length; i++) {
      if(this.payments.value[i].paymentStatus != "Bounced" && this.payments.value[i].paymentStatus != "Rejected"){
        sum += this.payments.value[i].grossAmount;
      }
    }
    this.sumOfGrossAmount = sum;
    this.balanceAmount = this.opportunityDetailsForm.value.sfActualFee - this.sumOfGrossAmount;

    this.openedPaymentData = currentPaymentData;
    if(currentPaymentData.value.paymentType == "No Payment"){
      ['chequeNo', 'paymentDat', 'bank'].forEach(controlName => {
        const control = currentPaymentData.get(controlName);
        if(control) control.disable();
      });
    }
    if(currentPaymentData.value.paymentType != "No Payment" && !this.addnPaymentCase && this.OpportunityGridDetails.opportunityStatus == "Won - Payment Recd"){
      ['chequeNo'].forEach(controlName => {
        const control = currentPaymentData.get(controlName);
        if(control) control.enable();
      });
    }

    if(this.addnPaymentCase && this.addnPaymentIds.length > 0){
      this.addnPaymentIds.forEach(element => {
        if(currentPaymentData.value.id == element && currentPaymentData.value.paymentType != "No Payment"){
          ['chequeNo', 'paymentDat', 'bank','amount','tdsType'].forEach(controlName => {
            const control = currentPaymentData.get(controlName);
            if (control) {
              control.enable();         
              
            }
          });
        }
        if(currentPaymentData.value.id == element && currentPaymentData.value.tdsType == "Manual TDS"){
          ['tdsAmount'].forEach(controlName => {
            const control = currentPaymentData.get(controlName);
            if (control) {
              control.enable();                  
            }
          });
        }
      });
      if(currentPaymentData.value.paymentType == "Part Payment"){
        this.tdsTypeList = [{property:"No TDS"}]
      }else{
        this.tdsTypeList = this.ogTdsTypeList;
      }

      for (var i = 0; i < this.payments.value.length; i++) {
        if(this.payments.value[i].paymentStatus == "Pending" || this.payments.value[i].paymentStatus == "Realized"){
          fullPaymentRealised = true;
        }
      }
      for(var i = 0; i < this.payments.value.length; i++){ 
        if ((this.payments.value[this.paymentIndex].paymentStatus === 'Bounced' || this.payments.value[this.paymentIndex].paymentStatus === 'Rejected') && this.payments.value[this.paymentIndex].paymentType === 'Full Payment') {
          const tdsTypeControl = currentPaymentData.get('tdsType') as FormArray;
          tdsTypeControl.disable();
        } else if ((this.payments.value[this.paymentIndex].paymentStatus === 'Bounced' || this.payments.value[this.paymentIndex].paymentStatus === 'Rejected') && this.payments.value[this.paymentIndex].paymentType === 'Part Payment' && fullPaymentRealised) {
          const tdsTypeControl = currentPaymentData.get('tdsType') as FormArray;
          tdsTypeControl.disable();
        }         
      }      
    }    
  }  

  formatDate(date: Date): string {
    if(typeof date != "string"){
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      return `${day}-${month}-${year}`;
    }else{
      return date;
    } 
  }

  gstTypeValidate(): void {
    let gstType = this.opportunityDetails?.entityContacts?.gstType;
    const currentPaymentData = this.payments.at(this.paymentIndex);
    this.parseSfActualFees = Number(this.opportunityDetails?.opportunityDetails?.feeDetails?.sfActualFees);

    if (currentPaymentData.value.tdsType != null || currentPaymentData.value.tdsType != undefined) {
      const tdsType = currentPaymentData.value.tdsType;
      const paymentType = currentPaymentData.value.paymentType;

      if (tdsType === "Manual TDS") {
        currentPaymentData.get('tdsAmount').enable();
        currentPaymentData.get('tdsAmount').reset();
        this.payments.at(this.paymentIndex).get('tdsAmount').patchValue(0);
      } else if (tdsType === "No TDS") {
        const tdsAmount = 0;
        currentPaymentData.get('tdsAmount').disable();
        currentPaymentData.value.tdsAmount = tdsAmount;

        if (gstType === "GST Normal" || gstType === "GST NA") {
          const gstAmount = Math.round(this.parseSfActualFees * 0.18);
          this.netAmount = Math.round(this.parseSfActualFees + gstAmount);
        } else {
          this.netAmount = this.parseSfActualFees;
        }

        this.payments.at(this.paymentIndex).get('tdsAmount').patchValue(tdsAmount);
        this.tempTdsAmount = tdsAmount;
      } else {
        const tdsAmountCalculated = (tdsType === "TDS on Gross + Tax")
          ? Math.round((this.parseSfActualFees + Math.round(this.parseSfActualFees * 0.18)) * 0.10)
          : Math.round(this.parseSfActualFees * 0.10);
      
        const tdsAmount = (paymentType === 'Full Payment') ? tdsAmountCalculated : 0;
      
        currentPaymentData.get('tdsAmount').disable();
        currentPaymentData.value.tdsAmount = tdsAmount;

        if (gstType === "GST Normal" || gstType === "GST NA") {
          const gstAmount = Math.round(this.parseSfActualFees * 0.18);
          this.netAmount = Math.round(this.parseSfActualFees + gstAmount);
        } else {
          this.netAmount = Math.round(this.parseSfActualFees);
        }

        this.payments.at(this.paymentIndex).get('tdsAmount').patchValue(tdsAmount);
        this.tempTdsAmount = tdsAmount;
      }
    }
    this.onChangeGrossCalculation(currentPaymentData.value.amount);
    this.paymentValidation();
  }

  onChangeGrossCalculation(amountcollected?: any){
    const currentPaymentData = this.payments.at(this.paymentIndex) as FormArray;
    if(amountcollected == undefined){
      amountcollected = Number(currentPaymentData.value.amount);
    }
    if (amountcollected > 0) {
      if(currentPaymentData?.value?.tdsAmount == undefined){
        currentPaymentData.value.tdsAmount = this.tempTdsAmount;
      }
      let gstType = this.opportunityDetails?.entityContacts?.gstType;
      if (gstType == "GST Normal" || gstType == "GST NA") {
        this.grossAmount = ((Math.round(parseInt(amountcollected))) + parseInt(currentPaymentData.value.tdsAmount)) / 1.18;
        this.grossAmount = parseFloat(this.grossAmount.toFixed(0));
        currentPaymentData.get('grossAmount').patchValue(this.grossAmount);
      } else {
        this.grossAmount = (Math.round(parseInt(amountcollected))) + parseInt(currentPaymentData.value.tdsAmount);
        this.grossAmount = parseFloat(this.grossAmount.toFixed(0));
        currentPaymentData.get('grossAmount').patchValue(this.grossAmount);
      }
    }
    if (amountcollected === 0 || amountcollected === null || amountcollected === '') {
      let grossAmount = 0;
      currentPaymentData.get('grossAmount').patchValue(grossAmount);  
    }
    this.paymentValidation();  
  }

  paymentValidation(){
    if(this.addnPaymentCase){
      var sum = 0;
      for (var i = 0; i < this.payments.value.length; i++) {
        if(this.payments.value[i].paymentStatus != "Bounced" && this.payments.value[i].paymentStatusId != "Rejected"){
          sum += this.payments.value[i].grossAmount;
        }
      }
      this.sumOfGrossAmount = sum;
      this.balanceAmount = this.opportunityDetailsForm.value.sfActualFee - this.sumOfGrossAmount;

      this.grossError = 'or equal to ';
      const currentPaymentData = this.payments.at(this.paymentIndex);

      if(currentPaymentData?.value.paymentType == "Part Payment" && currentPaymentData.value.grossAmount >= Number(this.opportunityDetailsForm.value.sfActualFee)){
        this.grossAmountError = true;
        currentPaymentData.get('grossAmount').markAsDirty();
      }
      else if(currentPaymentData?.value.paymentType == "Full Payment" && currentPaymentData.value.grossAmount > Number(this.opportunityDetailsForm.value.sfActualFee)){
        this.grossAmountError = true;
        currentPaymentData?.get('grossAmount').markAsDirty();
      }else if ((this.sumOfGrossAmount > Number(this.opportunityDetailsForm.value.sfActualFee))) {                
          this.grossAmountError = true;
          currentPaymentData?.get('grossAmount').markAsDirty();
      }else{
        this.grossAmountError = false;
      }
    }      
  }

  onManualTDS(event: any):void{
    const currentPaymentData = this.payments.at(this.paymentIndex) as FormArray;
    this.onChangeGrossCalculation(currentPaymentData.value.amount);
    currentPaymentData.get('tdsAmount').setValidators([Validators.required]);
  }

  numberOnly(event){
    let text = event.key
    return /^[0-9]*$/.test(text);
  }

  contactGSTValidation(){
    const currentPaymentData = this.payments.at(this.paymentIndex) as FormArray;
    currentPaymentData.get('tdsType').reset();
    currentPaymentData.get('tdsAmount').reset();
    currentPaymentData.get('tdsAmount').patchValue(0);  
    currentPaymentData.get('grossAmount').patchValue(0);
  }

  getPaymentStatus(){
    this.pageService.getPaymentStatus().subscribe(result => {
      let obj = result?.reduce((acc, obj) => {
        acc[obj.id] = obj.name;
        return acc; 
      }, {});
      this.payStatus = obj
    });
  }

  getRptOverallStatus(rptStatus){
     let rptOverall= (rptStatus.rptInCC=='Yes'|| rptStatus.rptInCrm =='Yes')?'Yes': 'No';
    this.opportunityDetailsForm.patchValue({
      rptInCC: rptStatus?.rptInCC,
      rptInCrm: rptStatus?.rptInCrm,
      rptoverall: rptOverall
    });

    this.rptoverallStatus=rptOverall;
  }

  deleteMultipleFile(){
    this.pageService.deleteMultipleFiles(this.uploadedFileIds).subscribe((res) => {
    });
  }

  uploadSingleFile(data){
    this.uploadLoader = false;
    this.pageService.singleFileUpload(data).subscribe((response) => {
      let obj = this.attachmentTypeList?.find((d) => d?.id == data?.fileTypeid);
      let obj1 = this.attachmentResult?.find((d) => d?.fileTypeid == data?.fileTypeid);
      obj.deleteId = response?.fileId;
      obj1.fileId = response?.fileId;
      this.uploadedFileIds.push(response?.fileId);
    }, (_err) => {
      let obj = this.attachmentTypeList?.find((d) => d?.id == data?.fileTypeid);
      obj.file = '';
      obj.fileName = '';
      obj.thumbNail = '';
      this.messageService.add({ severity: 'error', summary: 'something went wrong please try again!' });
    });
  }
}
