import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { errorRoute } from './layouts/error/error.route';
import { navbarRoute } from './layouts/navbar/navbar.route';
import { headerRoute } from './layouts/header/header.route';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
const LAYOUT_ROUTES = [navbarRoute, headerRoute, ...errorRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
       
        {
          path: 'agreement-archive',
          loadChildren: () => import('./agreement-archive/agreement-archive.module').then(m => m.AgreementArchiveModule) 
        },
        {
          path: 'offline-bills',
          loadChildren: () => import('./offline-bills/offline-bills.module').then(m => m.OfflineBillsModule) 
        },
         {
          path: 'pages',
          loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
        },
        {
          path: 'initiation',
          loadChildren: () => import('./initiation/initiation.module').then(m => m.InitiationModule)
        },
        {
          path: 'qc',
          loadChildren: () => import('./quality-check/quality-check.module').then(m => m.QualityCheckModule)
        },
        {
          path: 'irf-realization',
          loadChildren: () => import('./irf-realization/irf-realization.module').then(m => m.IrfRealizationModule)
        },
        {
          path: 'sf-billing',
          loadChildren: () => import('./sf-billing/sf-billing.module').then(m => m.SfBillingModule)
        },
    
        // { path: '**', component: EmptyRouteComponent },
        { path: 'empty', component: EmptyRouteComponent },
        { path: '**', component: EmptyRouteComponent , pathMatch: "full"},

               ...LAYOUT_ROUTES
      ],
      { useHash: true }
    )
  ],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }]
})
export class BackofficeAppRoutingModule {}
