
    <div>
        <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
            <label style="margin-left:0px;">Zoom : </label>
            <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
            <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
                class="p-button-brand2 p-ml-1"></button>
            <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
                class="p-button-brand2 p-ml-1"></button>
            <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
        </div>

        <pdf-viewer id="pdf_viewer" *ngIf="isPdf" [src]="url" [rotation]="rotate" [original-size]="false"
        [show-all]="true" [fit-to-page]="false" [zoom]="zoomValue" [zoom-scale]="'page-width'" [stick-to-page]="false"
        [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="display: block; min-height: 20rem"></pdf-viewer>

        <div class="imagePreviewContainer p-d-flex p-jc-center p-ai-center">
            <img *ngIf="!isPdf" src="{{url}}" id="imagePrev" alt="Image">
        </div>
    </div>
    