<div>
    <form [formGroup]="EditMappedUserForm" autocomplete="off" novalidate>
        <p-messages></p-messages>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12  p-p-0">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-p-0 p-d-flex p-ai-center p-mb-2">
                        <div class="p-col-12 p-sm-12 p-md-10 p-lg-10 p-xl-10  p-text-left">
                            <label class="leb empDetails p-mr-3"> Emp ID: <strong> {{empId}} </strong></label>
                            <label class="leb empDetails"> Employee Name: <strong> {{empName}} </strong></label>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 p-xl-2 p-text-right">
                            <div class="btnwrap">
                                <button pButton class="p-button-secondary"
                                    style="border: none !important; padding: 0; margin: 0; background-color: transparent!important; box-shadow: none ;"
                                    [disabled]="!EditMappedUserForm.dirty && !signDeleted" type="button"
                                    (click)="ngOnInit();" label="Refresh">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  left popupBodyScrollContainer"
                        style="padding-bottom:130px;">
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  left p-pl-0">

                                <h6> User Info</h6>

                            </div>
                        </div>

                        <div class="p-formgrid p-grid p-d-flex p-ai-start">                         
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Designation<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-dropdown id="designationid" name="designation"
                                            placeholder="Select a Designation" [options]="designationList"
                                            [autoDisplayFirst]="false" [filter]="true" formControlName="bd_designation"
                                            optionLabel="bd_designation" filterBy="bd_designation"
                                            (onChange)="getimmediateSupervisorList(EditMappedUserForm.value.bd_designation.id,EditMappedUserForm.value.bd_designation.level_id,EditMappedUserForm.value.employeeId); onChangeDesignation(EditMappedUserForm.value.bd_designation.id);"
                                            resetFilterOnHide="true" scrollHeight="150px">
                                    </p-dropdown>
                                    <div class="invalid-feedback"
                                        *ngIf="EditMappedUserForm.get('bd_designation')!.invalid && (EditMappedUserForm.get('bd_designation')!.dirty || EditMappedUserForm.get('bd_designation')!.touched)">
                                        <div *ngIf="EditMappedUserForm.get('bd_designation')?.errors?.required">
                                            Designation is required.
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Mobile No.<span class='invalid-feedback-astrik'>*</span>
                                    </label> 

                                    <p-inputMask id="mobileNo_id" name="mobileNo_name" formControlName="mobileNo"
                                        (onComplete)="verifyFormData();" mask="9999999999" placeholder="" slotChar="">
                                    </p-inputMask>

                                    <div class="invalid-feedback" *ngIf="EditMappedUserForm.get('mobileNo')!.invalid && (EditMappedUserForm.get('mobileNo')!.dirty || EditMappedUserForm.get('mobileNo')!.touched)">
                                        <div *ngIf="EditMappedUserForm.get('mobileNo')?.errors?.required">
                                            Mobile No. is required.
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Email
                                    </label>
                                    <input type="text" id="emailid" name="emailId" formControlName="emailId" pInputText
                                        placeholder="" class="p-inputtext  " [attr.disabled]="true" />

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left p-pl-0">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Shadow Portfolio<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-multiSelect display="chip" id="shadowPortfolioEmployeeNameAndDesignationid"
                                        name="shadowPortfolioEmployeeNameAndDesignation" [filter]="true"
                                        [options]="shadowPortfolioEmployeeNameAndDesignationList" (onChange)="verifyFormData();"
                                        optionLabel="shadowPortfolioEmployeeNameAndDesignation"
                                        formControlName="shadowList" resetFilterOnHide="true" scrollHeight="150px">
                                    </p-multiSelect>
                                    <div class="invalid-feedback"
                                        *ngIf="EditMappedUserForm.get('shadowList')!.invalid && (EditMappedUserForm.get('shadowList')!.dirty || EditMappedUserForm.get('shadowList')!.touched)">
                                        <div *ngIf="EditMappedUserForm.get('shadowList')?.errors?.required">
                                            Shadow Portfolio is required.
                                        </div>
                                    </div>
                                    <!-- <p-dropdown id="shadowPortfolioEmployeeNameAndDesignationid"
                                            name="shadowPortfolioEmployeeNameAndDesignation"
                                            placeholder="Select a Shadow Portfolio"
                                            [options]="shadowPortfolioEmployeeNameAndDesignationList" [filter]="true"
                                            [autoDisplayFirst]="false"
                                            formControlName="shadowPortfolioEmployeeNameAndDesignation"
                                            (onChange)="verifyFormData();"
                                            optionLabel="shadowPortfolioEmployeeNameAndDesignation" [showClear]="true"
                                            resetFilterOnHide="true" scrollHeight="150px">
                                    </p-dropdown> -->
                                </div>
                            </div>    
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 right p-d-flex p-ai-end signatureCard">
                                    <div *ngIf="!signBtn && viewSign" class=" p-fluid   p-field p-w-100   ">
                                        <label>Signature</label>
                                        <p-card id="p-cardId" class="p-element p-component">
                                                <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-jc-between p-flex-wrap">
                                                    <div class="p-col-11 p-d-flex p-ai-center p-pl-0">
                                                        <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center">
                                                            <img [src]="urlSafe" class="p-mr-2" width="28px" height="28px" alt="img"/>
                                                            <a class="limit-text-length" (click)="showSignaturePreview()">
                                                                <div class="p-col-12 p-d-flex p-flex-column p-flex-wrap p-ai-start p-pl-0">
                                                                        <span class="limit-text-length attachmentName cursor-pointer signatureLabel p-ml-0">
                                                                            <ng-container *ngIf="fileName; else other ">{{fileName}}
                                                                            </ng-container>
                                                                            <ng-container>
                                                                                <ng-template #other>{{signatureResult?.fileName}}
                                                                                </ng-template>
                                                                            </ng-container>
                                                                        </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-1 p-d-flex p-jc-end p-pr-0 p-pl-0">
                                                        <i class="pi pi-trash cursor-pointer attachmentIcon" aria-hidden="true"
                                                            (click)="onDeletePopup()"></i>
                                                    </div>
                                                </div>
                                            </p-card>
                                    </div>
                                    <div *ngIf="signBtn && !viewSign" class=" p-fluid p-field p-w-100">
                                        <label>Signature</label>
                                        <p-fileUpload customUpload="true" class="p-fileupload-choose" id="fileUpload1"
                                                name="file" accept="image/*, .jpeg , .jpg, .png," maxFileSize=5000000
                                                mode="advanced" [showUploadButton]="false" [showCancelButton]="false"
                                                [disabled]="disableUpload" #fileUploadfileUpload13
                                                (onSelect)="onSelectfileUpload1($event)" chooseLabel="Upload Signature">
                                        </p-fileUpload>
                                    </div>
                                </div>                    
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  left p-pl-0">

                                <h6> Superior Info</h6>

                            </div>
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left p-pl-0">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Immediate Supervisor<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-dropdown id="immediateSupervisorid" name="immediateSupervisor"
                                            placeholder="Select a Immediate Supervisor"
                                            [options]="immediateSupervisorList" (onChange)="setImmediateSupervisor()"
                                            [filter]="true" [autoDisplayFirst]="false" filterBy="immediateSupervisor"
                                            formControlName="immediateSupervisor" optionLabel="immediateSupervisor"
                                            resetFilterOnHide="true">
                                    </p-dropdown>
                                    <div class="invalid-feedback"
                                        *ngIf="EditMappedUserForm.get('immediateSupervisor')!.invalid && (EditMappedUserForm.get('immediateSupervisor')!.dirty || EditMappedUserForm.get('immediateSupervisor')!.touched)">
                                        <div *ngIf="EditMappedUserForm.get('immediateSupervisor')?.errors?.required">
                                            Immediate Supervisor is required.
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Immediate Supervisor BD
                                    </label>
                                    <input type="text" id="imeediateSupervisorLevelName_id"
                                        name="imeediateSupervisorLevelName_name"
                                        formControlName="imeediateSupervisorLevelName" pInputText placeholder=""
                                        class="p-inputtext  " [attr.disabled]="true" />

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Director Name
                                    </label>
                                    <input type="text" id="directorName_id" name="directorName_name"
                                        formControlName="directorName" pInputText placeholder="" class="p-inputtext  "
                                        [attr.disabled]="true" />

                                </div>

                            </div>
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left p-pl-0">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        HR Designation
                                    </label>
                                    <input type="text" id="hrDesignation_id" name="hrDesignation_name"
                                        formControlName="hrDesignation" pInputText placeholder="" class="p-inputtext  "
                                        [attr.disabled]="true" />

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Ops Spoc<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-multiSelect display="chip" id="opSpocid" name="opSpoc"
                                        [options]="opSpocList" placeholder="Select a Ops Spoc"
                                        optionLabel = "employeeName" resetFilterOnHide="true"
                                        formControlName = "opsList" scrollHeight="130px"
                                        [filter]="true" (onChange)="verifyFormData();">
                                    </p-multiSelect>
                                    <div class="invalid-feedback"
                                        *ngIf="EditMappedUserForm.get('opsList')!.invalid && (EditMappedUserForm.get('opsList')!.dirty || EditMappedUserForm.get('opsList')!.touched)">
                                        <div *ngIf="EditMappedUserForm.get('opsList')?.errors?.required">
                                            Ops Spoc is required.
                                        </div>
                                    </div>
                                    <!-- <p-dropdown id="opSpocid" name="opSpoc" placeholder="Select a Ops Spoc"
                                            [options]="opSpocList" optionLabel="employeeName" [filter]="true"
                                            [autoDisplayFirst]="false" formControlName="opSpoc" [showClear]="true"
                                            (onChange)="verifyFormData();" scrollHeight="130px">
                                    </p-dropdown> -->
                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Team<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-dropdown id="designation1id" name="designation1" placeholder="Select a Team"
                                            [options]="teamNameList" [autoDisplayFirst]="false"
                                            formControlName="teamName" optionLabel="value"
                                            (onChange)="verifyFormData();" [ngClass]="{'error': EditMappedUserForm.get('teamName')!.invalid && (EditMappedUserForm.get('teamName')!.dirty || EditMappedUserForm.get('teamName')!.touched)}">
                                    </p-dropdown>
                                    <div class="invalid-feedback"
                                        *ngIf="EditMappedUserForm.get('teamName')!.invalid && (EditMappedUserForm.get('teamName')!.dirty || EditMappedUserForm.get('teamName')!.touched)">
                                        <div *ngIf="EditMappedUserForm.get('teamName')?.errors?.required">
                                            Team is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left p-pl-0">
                                <div class=" p-fluid   p-field">
                                    <label
                                    [ngClass]="{'field-required': EditMappedUserForm.get('paEmployeeNameAndDesignation')?.errors?.required}">
                                    PA Approver<span class='invalid-feedback-astrik'>*</span>
                                </label> 
                                    <p-dropdown id="paEmployeeNameAndDesignationid" filterBy="paEmployeeNameAndDesignation"
                                            name="paEmployeeNameAndDesignation" placeholder="Select a Approver"
                                            [options]="paEmployeeNameAndDesignationList" [filter]="true" scrollHeight="100px"
                                            [autoDisplayFirst]="false" formControlName="paEmployeeNameAndDesignation"
                                            [ngClass]="{'error': EditMappedUserForm.get('paEmployeeNameAndDesignation')!.invalid && (EditMappedUserForm.get('paEmployeeNameAndDesignation')!.dirty || EditMappedUserForm.get('paEmployeeNameAndDesignation')!.touched)}"
                                            optionLabel="paEmployeeNameAndDesignation" resetFilterOnHide="true" (onChange)="verifyFormData();">
                                        </p-dropdown>
                                    <div class="invalid-feedback "
                                    *ngIf="EditMappedUserForm.get('paEmployeeNameAndDesignation')!.invalid && (EditMappedUserForm.get('paEmployeeNameAndDesignation')!.dirty || EditMappedUserForm.get('paEmployeeNameAndDesignation')!.touched)">
                                    <div *ngIf="EditMappedUserForm.get('paEmployeeNameAndDesignation')?.errors?.required">
                                        PA Approver is required.
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-9 p-lg-9 p-xl-9  p-left">
                    </div>
                    <div
                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  left p-p-0 popupFooterBtnContainer">
                        <div
                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-end p-ai-center  p-left popupFooterBtnContent p-pr-4 p-pt-2 p-mb-3">
                            <div class="btnwrap">
                                <button pButton class="p-button-brand2" type="button" (click)="onCcprimebutton(EditMappedUserForm);" label="Cancel">
                                </button>
                            </div>
                            <div class="btnwrap">
                                <button pButton class="p-button-primary p-mr-0" type="submit"
                                    [disabled]="!saveButtonEnabled || !EditMappedUserForm.valid || loaderFlag" 
                                    (click)="onCcprimebutton1(EditMappedUserForm);" label="Save">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="no_data_div" *ngIf="loaderFlag">
            <div class="app-loading" id="loader">
                <div class="loadbg"></div>
                <div class="bdload">
                    <div class="spinner">
                        <div class="rect1"></div>
                        <div class="rect2"></div>
                        <div class="rect3"></div>
                        <div class="rect4"></div>
                        <div class="rect5"></div>
                        <span>Loading...</span>
                    </div>
                </div>
            </div>
        </div> -->

    </form>
    <br>
</div>
<p-dialog header="Crop Image" [(visible)]="displayModal" class="cropImageModal" [modal]="true"
    [style]="{width: '60vw', height: '70vh'}" [draggable]="false" [resizable]="false" [closable]="false">
    <image-cropper styleClass="imageCropper" [imageChangedEvent]="imgChangeEvt"
        [imageFile]="imageChangedEventfileUpload1" [maintainAspectRatio]="false" [resizeToWidth]="256" format="png"
        (imageCropped)="imageCroppedfileUpload1($event)">
    </image-cropper>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="displayModal=false" label="Cancel" class="p-button-brand2"></button>
        <button pButton type="button" label="Upload" class="p-button-primary" (click)="onCroppedUploadBtn()"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Preview" [(visible)]="displayPreview" class="signPreview" [modal]="true"
    [style]="{width: '60vw', height: '50vh'}" [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-d-flex p-jc-center">
        <img alt="image" [src]="urlSafe" height="100%" style="max-height: 54vh;" />
    </div>
</p-dialog>
<p-dialog header="Delete Signature" name="deletePopup" [(visible)]="deletePopUp" [modal]="true" class="alertPopup" [closable]="true"
    [draggable]="false">
    <p class="p-text-left">Are you sure you want to delete Signature?</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="No" (click)="deletePopUp=false" class="p-button-secondary "></button>
        <p-button type="button" (click)="onDeleteSignature()" label="Yes" class="p-button-primary"></p-button>
    </ng-template>
</p-dialog>
<p-dialog header="Alert" [(visible)]="changeDesigAlert" [modal]="true" class="alertPopup" [closable]="false"
    [draggable]="false" [style]="{width: '40vw'}">
    <p class="p-mb-4">Are you sure you want to change the designation? <br>
     This will also delete the signature.</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="No" (click)="onSelectNo()" class="p-button-secondary"></button>
        <p-button type="button" (click)="onDeleteSignature()" label="Yes" class="p-button-primary"></p-button>
    </ng-template>
</p-dialog>