import { ChangeDetectorRef, Component, DoCheck, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table, TableCheckbox } from 'primeng/table';

@Component({
    selector: 'backoffice-add-agreement',
    templateUrl: './add-agreement.component.html',
    styleUrls: ['./add-agreement.component.scss']
})
export class AddAgreementComponent implements OnInit,OnChanges,DoCheck {

    @ViewChild('checkboxRef', { static: false }) checkboxRef!: TableCheckbox;

    invoiceDataList: any = [];
    selectedAgreement: any = [];
    productNameList: any;
    dummtProductNameList: any;
    agreementList: any = [];
    dummyAgreementList: any = [];
    productMasterMap: any;
    instrumentMasterMap: any;
    firstAgreement: any;
    isDummy: boolean = false;
    disableAgreeSelectAll: boolean = false;
    disableDummyAgreeSelectAll: boolean = false;
    disableAddAgreement: boolean = false;
    generateBill: boolean = false;
    addAgreementForm = this.fb.group({
        productFilter: [null, []],
    });

    addDummyAgreementForm = this.fb.group({
        productFilter: [null, []],
    });

    
    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private cd: ChangeDetectorRef,private fb: FormBuilder) {
        this.agreementList = this.config?.data?.agreementList;
        this.dummyAgreementList = this.config?.data?.dummyAgreement;
        this.firstAgreement = this.config?.data?.firstAgreement;
        this.productMasterMap = this.config?.data?.productMaster;
        this.instrumentMasterMap = this.config?.data?.instrumentMaster;
        this.isDummy = this.config?.data?.aggType == 'dummy';
        this.generateBill = this.config?.data?.aggType == 'generate';
    }

    ngOnChanges(): void {
        this.onRowSelect();
    }

    ngDoCheck(): void {
        this.onRowSelect();
    }

    ngOnInit(): void {
        this.productNameList = new Set();
        this.dummtProductNameList = new Set();
        this.agreementList.forEach((p: any) => {
            p.productName = this.productMasterMap[p.productId]
            p.instrument = this.instrumentMasterMap[p.instrumentId]
            this.productNameList.add(p.productName);
            if(p.isAdded){
                this.disableAgreeSelectAll = true;
            }
        });
        
        this.dummyAgreementList.forEach((p: any) => {
            p.productName = this.productMasterMap[p.productId]
            p.instrument = this.instrumentMasterMap[p.instrumentId]
            this.dummtProductNameList.add(p.productName);
            if(p.isAdded){
                this.disableDummyAgreeSelectAll = true;
            }
        });
        
        this.productNameList = Array.from(this.productNameList).map(item => ({ name: item, value: item }));
        this.dummtProductNameList = Array.from(this.dummtProductNameList).map(item => ({ name: item, value: item }));
    }
    
    private onRowSelect(){
        console.log("this.selectedAgreement.lenght",this.selectedAgreement.length);
        if(this.selectedAgreement.length > 0){
            this.disableAddAgreement = false;
        }else{
            this.disableAddAgreement = true;
        }
        this.cd.detectChanges();
    }

    closePopUp() {
        this.ref.close();
    }

    addAgreement() {
        this.ref.close(this.selectedAgreement);
    }
}
