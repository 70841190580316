import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SfBillingService } from '../sf-billing.service';
import { ImageTransform } from 'ngx-image-cropper';
import { DOMAIN_ROUTE } from 'app/app.constants';
@Component({
  selector: 'backoffice-sf-bill-tracking-details-screen',
  templateUrl: './sf-bill-tracking-details-screen.component.html',
  styleUrls: ['./sf-bill-tracking-details-screen.component.scss'],
  providers: [DialogService]
})
export class SfBillTrackingDetailsScreenComponent implements OnInit {

  constructor(public ref: DynamicDialogRef,private sfBillingService: SfBillingService,public config: DynamicDialogConfig) { }

  sfBillId: any;
  searchedEditList: any[] = [];
  loadingStatus = false; 


    //attachment
    disableRejectAddButton: boolean = false;
    openedFile: any;
    zoomValue: number = 1;
    rotate: number = 0
    displayPreview: boolean = false;
    previewFailed: boolean = false;
    urlSafe: any;
    canvasRotation: number = 0;
    documentList: any;
    
    //upload document
    currentFileEvent: any;
    fileType = 'SF Billing Finance Rejection';
    fileTypeId = 3;
    imgChangeEvt: any;
    imageBlob: any;
    uploadedFileName: any;
    returnBase64: any;
    displayModal: boolean = false;
    uploadLoader: boolean = false;
    croppedImageBase64: any;
    fileUploadfileUpload1: any = {
      files: []
    };
    uploadFile: any = {};
    returnUploaded: any;
    deleteFile: boolean = false;
    deleteFileData: any;
    transform: ImageTransform = {};
    uploadedFileIds: any;
    uploadedAttachmentsCallList: any;
    attachmentList: any;
    isPdf: boolean = false;
    previewLoader: boolean = false;
    currentPdf: any;
    scopeList: any;
    readScope: boolean = false;
  
    apiCallList: any = [];
  
  
    pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
    wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
    imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
    mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;
    attachmentResponse: any;
    attachmentId: string;
  editAttach: any = [];

  ngOnInit(): void {

    this.sfBillId = this.config?.data;
    console.log("sf bill id on details screen ",this.sfBillId);
    console.log("this.config ",this.config);
    this.getAgreementDetails(this.sfBillId);
    console.log("sf bill id on details screen ",this.sfBillId);
  }
  onCancel(): void {
    this.ref.close();
    
  }
 
  getAgreementDetails(sfBillId:any){
    console.log("sf-bill-tracking-details-screen-component-sf-bill-id",sfBillId);
    this.sfBillingService.getAgreementsBySfBillId(sfBillId).subscribe({
      next: (sfCaseDetails: Array<any>) => {
        
        this.searchedEditList = sfCaseDetails;

        this.getAttachment();
        // this.gridLoader = false;
        // this.apiFail = false;
        // this.ref.close(sfCaseDetails);
        // this.onInputFieldChange();
        console.log("searchedEditList",this.searchedEditList);
      },
      error: (err) => {
        // this.gridLoader = false;
        // this.apiFail = true;
      }
    })
  }

  // getAgreementsBySfBillId
  // onSfBillIdClick(): void {
  //   console.log("Advanced filter method called");
  //   this.gridLoader = true;
  //   this.apiFail = false;
  //   let selectedCompanyCode;
  //   let payload = {
      
  //     "financialYear": this.financialYear,
  //     "entityStatus": this.entityStatus,
  //     "clientType":this.clientType,
  //     "assignmentFamily":null,
  //     "billStatus": null,
  //     "lotNumber":null,
  //     "bdOwnerIds":null
  //   }
    
  //   this.sfBillingService.getAgreementsBySfBillId(payload).subscribe({
  //     next: (sfCaseDetails: Array<any>) => {
  //       this.searchedEditList = sfCaseDetails;
  //       this.gridLoader = false;
  //       this.apiFail = false;
  //       this.ref.close(sfCaseDetails);
  //       // this.onInputFieldChange();
  //     },
  //     error: (err) => {
  //       this.gridLoader = false;
  //       this.apiFail = true;
  //     }
  //   })
    
  // }
  
  getAttachment(){
    let paylaod = {
      oppId: this.searchedEditList[0]?.sfBillId,
      moduleName: "SfBilling"
    }
   
    this.sfBillingService.getAttachmentByModule(paylaod).subscribe((res) => {
      console.log("Attachment details ",res);
      if(res?.length > 0){
        // 148	SF Bill Status	Rejected By BD
        // 1 SF Billing Edit Attachment
        res?.forEach((r) => {
          if(r.fileTypeid == 1){
            this.editAttach.push(r)
          }
        });
      }
    });
  }
  

  showAttachmentPreview(item) {
    this.openedFile = item;
    this.currentPdf = undefined;
    // let ext = item.fileName?.split('.')[1];
    // if (ext.includes('msg')) {
    //   return;
    // }
    // this.getAttachments(this.fetchedCompanyCode);
    this.zoomValue = 1;
    this.rotate = 0;
    console.log(this.documentList);
    console.log(this.uploadedFileIds);
    
    if (item?.fileId) {
      this.previewLoader = true;
      let payload = {
        id: item.fileId
      }
      this.apiCallList.push(this.sfBillingService.getBase64(payload).subscribe((res) => {
        if (res && res?.file) {
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          let isPreviewable = true;
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else if ( "msg" === ext1?.toLowerCase()) {
            mime = 'data:application/vnd.ms-outlook;base64,';
            isPreviewable = false;
          } 
          else if ( ['xls', 'xlsx'].includes(ext1?.toLowerCase())) {
            mime = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
            isPreviewable = false;
          } 
          else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.attachmentResponse = res;
          this.urlSafe = `${mime}${res.file}`
          if (!this.isPdf) {
            let el = document.getElementById('imagePrev');
            setTimeout(() => {
              el.style.transform = 'rotate(0deg) scale(1)';
            }, 100);
          }
          this.displayPreview = isPreviewable;
          if(!isPreviewable) {
            this.downloadFile(res, this.urlSafe);
          }
          this.previewLoader = false;
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      }));
    }
  }
  downloadFile(item: any, urlSafe) {
    const link = document.createElement('a');
    link.href = urlSafe;
    link.download = item?.fileName;
    link.click();
  }

  cropCancel() {
    this.displayModal = false;
    this.documentList = null;
    this.disableRejectAddButton = false;
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    if(el){
      el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
    }
  }

  rotateRight() {
    this.canvasRotation++;
  }

  rotateLeft() {
    this.canvasRotation--;
    //this.flipAfterRotate();
  }
}
